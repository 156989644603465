import React, { useState } from 'react';
import { Customer, Invoice, UniversalService } from '@alliance-disposal/transport-types';
import { EllipsisVerticalIcon, StarIcon } from '@heroicons/react/24/solid';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Dialog from '../../../../components/Dialog';
import { MenuButton, MenuItem } from '../../../../components/Menu';

type Props = {
  order: UniversalService.ServiceOrder | null;
  customer: Customer.AllianceCustomerTransport | null;
  payables: Invoice.PayableTransport[];
  onCancel: () => void;
  ordersMatch: boolean;
  onSave: () => void;
  onAddPayment: (haulerID?: string) => void;
  onSaveClose: () => void;
  onCreateNewCard: () => void;
  isLoading: boolean;
  view: 'invoice' | 'bill';
  onChangeView: (value: 'invoice' | 'bill') => void;
  onNeedsAttention: () => void;
};

const TopBar = ({
  order: serviceOrder,

  customer,
  onCancel,
  ordersMatch,
  onSave,
  onAddPayment,
  onSaveClose,
  onCreateNewCard,
  isLoading,
  view,
  onChangeView,
  onNeedsAttention,
  payables,
}: Props) => {
  const [showHaulerBillSelect, setShowHaulerBillSelect] = useState(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    onChangeView(value as 'invoice' | 'bill');
  };

  const primaryContact: Customer.AllianceCustomerContactTransport | undefined = (() => {
    if (!customer) return undefined;
    return customer.contacts.find((contact) => contact.primaryContact) ?? customer.contacts[0];
  })();

  const handleOnAddPayment = () => {
    if (!serviceOrder) return;
    if (view !== 'bill') {
      onAddPayment();
      return;
    }
    if (payables.some((item) => item.haulerID !== payables[0].haulerID) || !serviceOrder.vendorID) {
      setShowHaulerBillSelect(true);
      return;
    }
    onAddPayment(serviceOrder.vendorID);
  };

  if (!serviceOrder || !customer) {
    return (
      <div className="px-5 py-1 bg-white shadow-md rounded-br-4 rounded-bl-4 flex justify-center items-center sticky top-0 z-10">
        Select an order
      </div>
    );
  }

  return (
    <div className="px-5 py-1 bg-white shadow-md rounded-br-4 rounded-bl-4 flex justify-center items-center sticky top-0 z-10">
      <div className="flex justify-between items-center flex-1">
        <div>
          {customer.companyName ? customer.companyName : `${primaryContact?.firstName} ${primaryContact?.lastName}`}
        </div>
        <RadioGroup name="view-switch" value={view} onChange={handleRadioChange} row>
          <FormControlLabel
            value="invoice"
            control={<Radio style={{ padding: 3 }} />}
            label={<span style={{ fontSize: 14 }}>Receivable</span>}
            disabled={!ordersMatch}
          />
          <FormControlLabel
            value="bill"
            control={<Radio style={{ padding: 3 }} />}
            label={<span style={{ fontSize: 14 }}>Payable</span>}
            disabled={!ordersMatch}
          />
        </RadioGroup>
        <div>
          <button className="btn-dark-grey-outlined py-1" onClick={() => onCancel()}>
            Cancel
          </button>
          <button className="btn-primary mx-5 py-1" disabled={ordersMatch || isLoading} onClick={() => onSave()}>
            save
          </button>
          <MenuButton className="btn-icon h-9 w-9 !p-1" buttonItem={<EllipsisVerticalIcon className="h-6 w-6" />}>
            <MenuItem onClick={() => handleOnAddPayment()} disabled={!ordersMatch || (view === 'bill' && !payables[0])}>
              Add Payment
            </MenuItem>
            <MenuItem onClick={() => onCreateNewCard()}>Create New {view}</MenuItem>
            <MenuItem onClick={() => onNeedsAttention()}>
              {/* serviceOrder.needsAttentionBilling */ false ? <StarIcon className="h-5 w-5 text-[#72EFFF]" /> : null}
              Needs Attention - Billing
            </MenuItem>
            <MenuItem onClick={() => onSaveClose()}>Save &amp; Close Order</MenuItem>
          </MenuButton>
        </div>
      </div>
      <Dialog
        open={showHaulerBillSelect}
        onClose={() => setShowHaulerBillSelect(false)}
        styledTitle="Select Hauler to Pay"
      >
        <List>
          {payables.map((bill) => (
            <ListItem
              button
              key={bill.invoiceDetails.invoiceNumber}
              onClick={() => {
                setShowHaulerBillSelect(false);
                onAddPayment(bill.haulerID);
              }}
            >
              {bill.vendorName}
            </ListItem>
          ))}
        </List>

        <div className="flex justify-end">
          <button className="btn-dark-grey-outlined" onClick={() => setShowHaulerBillSelect(false)} type="button">
            Cancel
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default TopBar;
