import React from 'react';
import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import * as turf from '@turf/turf';
import Dialog from '../../Dialog';
import InputGooglePlaces from '../../InputGooglePlaces';
import { EditModes } from '../MapEditor';

export type RadiusFromAddressSubmit = {
  coordinates: {
    lat: number;
    lng: number;
  };
  radius: number;
  unit: turf.Units;
  mode: EditModes;
};

const RadiusFromAddressModal = ({
  open,
  onCancel,
  onSubmit,
}: {
  open: boolean;
  onCancel: () => void;
  onSubmit: ({ coordinates, radius, unit, mode }: RadiusFromAddressSubmit) => void;
}) => {
  const [addressInputValue, setAddressInputValue] = React.useState('');
  const [coordinates, setCoordinates] = React.useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const [radius, setRadius] = React.useState('0');
  const [unit /*, setUnit */] = React.useState<turf.Units>('miles');
  const [mode, setMode] = React.useState<EditModes>('draw');

  return (
    <Dialog open={open} onClose={onCancel} styledTitle="Radius From Address" className="!max-w-3xl">
      <div className="flex flex-col gap-2 w-[50vw]">
        <InputGooglePlaces
          onSelect={(result: any) => {
            console.log(result);
            setAddressInputValue(result.formatted_address);
            setCoordinates({
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
            });
          }}
          onChange={(value: any) => {
            console.log(value);
            setAddressInputValue(value);
          }}
          latLng={true}
          label="Address"
          value={addressInputValue}
        />
        {/* Distance input with miles as unit */}
        <TextField
          label="Distance"
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">miles</InputAdornment>,
          }}
          value={radius}
          onChange={(e) => {
            // allow only one decimal
            if (e.target.value.match(/^(\d*)\.{0,1}(\d){0,1}$/)) {
              setRadius(e.target.value);
            } else if (e.target.value.match(/^[0-9]*$/)) {
              setRadius(e.target.value);
            }
          }}
        />

        {/* Drop down to select mode (cut or add) */}
        <FormControl fullWidth>
          <InputLabel>Mode</InputLabel>
          <Select
            size="small"
            label="Mode"
            value={mode}
            onChange={(e) => {
              setMode(e.target.value as EditModes);
            }}
          >
            <MenuItem value="draw">Add</MenuItem>
            <MenuItem value="cut" disabled>
              Cut
            </MenuItem>
          </Select>
        </FormControl>

        <p>
          Enter an address and a radius to create a circular pricing zone. You can also use the map to create a circular
          pricing zone. When the mode is set to add it will add the circle to the pricing zone. When the mode is set to
          remove it will remove the circle from any overlapping zones. This can be useful for creating "donut" pricing.
        </p>

        <div className="flex justify-end gap-2 mt-2">
          <button className="btn-dark-grey-outlined" onClick={onCancel}>
            Cancel
          </button>
          <button
            className="btn-primary"
            disabled={!coordinates || !radius}
            onClick={() => {
              if (coordinates && radius) {
                onSubmit({
                  coordinates: coordinates,
                  radius: parseFloat(radius),
                  unit,
                  mode,
                });
                return;
              }
              throw new Error('Coordinates and radius are required');
            }}
          >
            Save
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default RadiusFromAddressModal;
