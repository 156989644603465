import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Hauler, Invoice, UniversalService } from '@alliance-disposal/transport-types';
import { recalculateInvoiceTotal } from '@wayste/utils';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../../contexts';
import { routes } from '../../../utils';
import TransactionCreate from '../components/TransactionCreate/';
import BillPayment from './BillPayment';
import InvoiceUpdate from './InvoiceUpdate';
import InvoiceSend from './ServiceOrderInvoiceSend';

interface Props {
  serviceGroupingID: string;
  serviceOrderID: string;
}

const InvoicesDetails = ({ serviceGroupingID, serviceOrderID }: Props) => {
  const client = useWaysteClient();
  const history = useHistory();
  const [selectedServiceOrder, setSelectedServiceOrder] = useState<UniversalService.ServiceOrder | null>(null);
  const [customer, setCustomer] = useState<Customer.AllianceCustomerTransport | null>(null);
  const [payables, setPayables] = useState<Invoice.PayableTransport[]>([]);
  const [receivables, setReceivables] = useState<Invoice.ReceivableTransport[]>([]);

  // list of receivable and or payables that have change
  const [payablesChanges, setPayablesChanges] = useState<number[]>([]);
  const [receivablesChanges, setReceivablesChanges] = useState<number[]>([]);

  const hasChanges = payablesChanges.length + receivablesChanges.length > 0;

  const [selectedHauler, setSelectedHauler] = useState<Hauler.HaulerWithAapTransport | null>(null);

  const [selectedInvoice, setSelectedInvoice] = useState<Invoice.ReceivableTransport | null>(null);
  const [showCreateInvoice, setShowCreateInvoice] = useState(false);
  const [showTransactionCreate, setShowTransactionCreate] = useState(false);
  const [showBillPayment, setShowBillPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { showFlash } = useContext(UIContext);

  useEffect(() => {
    if (serviceOrderID && serviceGroupingID) {
      fetchServiceOrderDetails(serviceGroupingID, serviceOrderID);
    }
  }, [serviceOrderID]);

  const fetchServiceOrderDetails = async (serviceGroupingID: string, serviceOrderId: string) => {
    setIsLoading(true);
    setSelectedServiceOrder(null);
    setSelectedInvoice(null);
    setCustomer(null);
    setSelectedHauler(null);
    setReceivables([]);
    setPayables([]);
    // GET THE SERVICE ORDER/BILLING TRANSPORT WHO KNOWS ONLY GOD AND @jay-depot
    const grouping = await client.universalService().serviceGrouping.fetch(serviceGroupingID);
    console.log('grouping', grouping);

    // order is the service order
    const serviceOrder = grouping.serviceOrders.find((order) => order.id === serviceOrderId);

    if (!serviceOrder) {
      throw new Error('Service Order not found');
    }

    // Get receivables, payables, and customer
    console.log('serviceOrder', grouping);
    try {
      const [receivablesResponse, payablesResponse, customerResponse] = await Promise.all([
        grouping.invoiceGroupings.length
          ? client.invoice().adminPortal.receivable.query({
              productInvoiceGroupingID: grouping.invoiceGroupings
                .map((invoiceGrouping) => invoiceGrouping.id)
                .join(','),
            })
          : [],
        grouping.serviceOrders.length
          ? client.invoice().adminPortal.payable.query({
              serviceOrderID: serviceOrderID,
            })
          : [],
        client.customer().adminPortal.fetch(grouping.customerID),
      ]);

      setReceivables(
        receivablesResponse.filter(
          (receivable) =>
            !receivable.invoiceDetails.lineItems.length ||
            receivable.invoiceDetails.lineItems.some((item) => item.serviceOrderID === serviceOrderID),
        ),
      );
      setPayables(payablesResponse);
      setCustomer(customerResponse);
      console.log('customerResponse', customerResponse);
      console.log('receivablesResponse', receivablesResponse);
      console.log('payablesResponse', payablesResponse);

      // SORT THEM  BY INVOICE NUMBER
      // order.invoices = order.invoices.sort((a, b) => (+a.invoiceNumber > +b.invoiceNumber ? 1 : -1));
      setSelectedServiceOrder(serviceOrder);
    } catch (error) {
      console.error(error, serviceGroupingID, serviceOrderId);
      showFlash(
        'Something went wrong loading payables and receivables. Please refresh the page and try again.',
        'warning',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddPayment = async (view: 'invoice' | 'bill', haulerID?: string) => {
    if (view === 'invoice') {
      setShowTransactionCreate(true);
    } else if (view === 'bill') {
      if (!haulerID) {
        alert('Missing hauler id');
        return;
      }
      setIsLoading(true);
      const haulerResponse = await client.vendorService().fetchById(haulerID);
      setSelectedHauler(haulerResponse.data);
      setShowBillPayment(true);
      setIsLoading(false);
    }
  };

  const setReceivable = (
    receivable: Invoice.ReceivableTransport,
    index?: number,
    options?: {
      isAPIResponse?: boolean;
    },
  ) => {
    const updatedReceivables = [...receivables];
    if (!options?.isAPIResponse) {
      receivable.invoiceDetails = recalculateInvoiceTotal(receivable.invoiceDetails);
    }
    if (index || index === 0) {
      updatedReceivables[index] = receivable;
    } else {
      // it's new
      index = receivables.length;
      updatedReceivables.push(receivable);
    }

    setReceivables(updatedReceivables);

    if (options?.isAPIResponse) {
      // remove the index from the changes array
      setReceivablesChanges(Array.from(new Set([...receivablesChanges].filter((i) => i !== index))));
    } else {
      setReceivablesChanges(Array.from(new Set([...receivablesChanges, index])));
    }
  };

  const setPayable = (
    payable: Invoice.PayableTransport,
    index?: number,
    options?: {
      isAPIResponse?: boolean;
    },
  ) => {
    const updatedPayables = [...payables];

    if (!options?.isAPIResponse) {
      payable.invoiceDetails = recalculateInvoiceTotal(payable.invoiceDetails);
    }

    if (index || index === 0) {
      updatedPayables[index] = payable;
    } else {
      // it's new
      index = payables.length;
      updatedPayables.push(payable);
    }
    setPayables(updatedPayables);
    if (options?.isAPIResponse) {
      // remove the index from the changes array
      setPayablesChanges(Array.from(new Set([...payablesChanges].filter((i) => i !== index))));
    } else {
      setPayablesChanges(Array.from(new Set([...payablesChanges, index])));
    }
  };

  const handleRefresh = () => {
    setSelectedServiceOrder(null);
    setSelectedInvoice(null);
    setCustomer(null);
    fetchServiceOrderDetails(serviceGroupingID, selectedServiceOrder?.id || '');
  };

  const handleTransactionSave = () => {
    setShowTransactionCreate(false);
    setShowBillPayment(false);
    setShowCreateInvoice(false);
    setSelectedInvoice(null);
    fetchServiceOrderDetails(serviceGroupingID, selectedServiceOrder?.id || '');
  };

  return (
    <div className="flex flex-1 max-h-screen overflow-y-hidden">
      <InvoiceUpdate
        selectedOrder={selectedServiceOrder}
        customer={customer}
        payables={payables}
        setPayables={(payables) => {
          setPayables(payables);
        }}
        setReceivables={(receivables) => {
          setReceivables(receivables);
        }}
        setReceivable={setReceivable}
        setPayable={setPayable}
        receivables={receivables}
        payablesChanges={payablesChanges}
        receivablesChanges={receivablesChanges}
        isLoading={isLoading}
        onAddPayment={handleAddPayment}
        onShowDetails={() => history.push(routes.orders.details(selectedServiceOrder?.id || ''), { modal: true })}
        hasChanges={hasChanges}
        onCreateReceipt={(invoice) => {
          setSelectedInvoice(invoice);
          setShowCreateInvoice(true);
        }}
        handleRefresh={handleRefresh}
        onCancel={() => {
          setSelectedServiceOrder(null);
          setSelectedInvoice(null);
          setCustomer(null);
          setReceivablesChanges([]);
          setPayablesChanges([]);

          if (serviceOrderID && serviceGroupingID) {
            fetchServiceOrderDetails(serviceGroupingID, serviceOrderID);
          }
        }}
        // onOrderSave={() => handleTransactionSave()}
      />
      {showCreateInvoice && selectedInvoice && customer && selectedServiceOrder && (
        <InvoiceSend
          open={showCreateInvoice}
          customer={customer}
          onCancel={() => {
            setShowCreateInvoice(false);
            setSelectedInvoice(null);
          }}
          onSend={() => {
            handleTransactionSave();
          }}
          order={selectedServiceOrder}
          receivable={selectedInvoice}
        />
      )}
      {showTransactionCreate && customer && (
        <TransactionCreate
          open={showTransactionCreate}
          onCancel={() => setShowTransactionCreate(false)}
          customer={customer}
          onSave={() => handleTransactionSave()}
        />
      )}
      <BillPayment
        open={showBillPayment}
        hauler={selectedHauler}
        onCancel={() => setShowBillPayment(false)}
        onSave={() => handleTransactionSave()}
        onOrderSelect={(order) => fetchServiceOrderDetails(serviceGroupingID, order.id)}
      />
    </div>
  );
};

export default InvoicesDetails;
