import { WaysteClient } from '@alliance-disposal/client';
import { Customer, Invoice, UniversalService } from '@alliance-disposal/transport-types';
import {
  createBillingEvent,
  daysRemainingInBillingPeriod,
  formatDateToYYYYMMDD,
  generatePayablesAndReceivable,
  sanitizeFromPayload,
} from '@wayste/utils';
import { isAxiosError } from 'axios';
import { UniversalServiceFormData } from './UniversalServiceCreate';

export const createServiceGrouping = async (
  data: UniversalServiceFormData,
  {
    customer,
    client,
    showFlash,
    cardSuccessful,
    stripeChargeID,
    last4,
    total,
    onCompleted,
  }: {
    client: WaysteClient;
    customer?: Customer.AllianceCustomerTransport;
    showFlash: (message: string, variant: 'success' | 'warning') => void;
    cardSuccessful: boolean;
    stripeChargeID: string;
    last4: string;
    total: number;
    onCompleted?: (response: UniversalService.ServiceGrouping) => void;
  },
) => {
  try {
    console.log('SUBMITTING', data);
    // Create Customer if not exists.
    if (!customer) {
      try {
        data.serviceGrouping.customerCompanyName = data.customer.companyName ?? '';
        data.customer.serviceLocations = [];
        data.customer.reviewed = false;
        if (data.serviceGrouping.serviceOrders[0].serviceLocation) {
          data.customer.serviceLocations.push(); // Only one service location.
        }
        const response = await client.customer().adminPortal.create(data.customer);
        console.log('Customer created', response);
        data.serviceGrouping.customerID = response.id;
        // Add contacts.
        Promise.all(
          data.customer.contacts.map((contact) => {
            return client.customer().adminPortal.contact.create(response.id, contact);
          }),
        ).then((responses) => {
          console.log('Contacts created', responses);
        });
      } catch (error) {
        showFlash('Error creating customer', 'warning');
        throw new Error('Error creating customer');
      }
    } else {
      data.serviceGrouping.customerID = customer?.id;
      data.serviceGrouping.customerCompanyName = customer?.companyName ?? '';
    }

    // Format Service Grouping.
    if (data.serviceGrouping.contractDetails) {
      if (customer) {
        data.serviceGrouping.contractDetails.salesRepID = customer.accountRepID ?? '';
      } else {
        data.serviceGrouping.contractDetails.salesRepID = data.customer.accountRepID ?? '';
      }
      data.serviceGrouping.contractDetails.contractStartDate =
        formatDateToYYYYMMDD(new Date(data.serviceGrouping.contractDetails.contractStartDate)) ?? '';
      // data.serviceGrouping.contractDetails.contractEndDate =
      //   formatDateToYYYYMMDD(new Date(data.serviceGrouping.contractDetails.contractEndDate)) ?? '';
    }

    if (data.subscriptionDetails) {
      data.subscriptionDetails.startDate = formatDateToYYYYMMDD(new Date(data.subscriptionDetails.startDate)) ?? '';
      if (data.subscriptionDetails.endDate) {
        data.subscriptionDetails.endDate =
          formatDateToYYYYMMDD(new Date(data.subscriptionDetails.endDate)) ?? undefined;
      }
    }

    data.serviceGrouping.name = data.serviceGrouping.serviceOrders[0].serviceLocation?.address.addressLine1 ?? '';

    // Apply Quantity to Service Order.
    data.serviceGrouping.serviceOrders.forEach((order, index) => {
      if (data.subscriptionDetails && order.subscriptionDetails && typeof order.subscriptionDetails !== 'string') {
        order.subscriptionDetails.startDate = data.subscriptionDetails.startDate;
        order.subscriptionDetails.endDate = data.subscriptionDetails.endDate;
        order.subscriptionDetails.billingFrequency = data.subscriptionDetails.billingFrequency;
        order.subscriptionDetails.billingFrequencyUnit = data.subscriptionDetails.billingFrequencyUnit;
        order.subscriptionDetails.billingDay = data.subscriptionDetails.billingDay;
        order.subscriptionDetails.active = true;

        // we don't want to sanitize the periodic events.
        const periodicEvents = [
          ...order.subscriptionDetails.periodicEvents.map((event) =>
            createBillingEvent({ ...event }, event.lineItemTypeID),
          ),
        ];

        sanitizeFromPayload(order.subscriptionDetails);
        order.subscriptionDetails.periodicEvents = periodicEvents;

        // grab periodic events from subscription details and add them to service events.
        order.subscriptionDetails.periodicEvents.forEach((periodicEvent) => {
          if (periodicEvent.unitPrice === 0) return;
          if (!order.serviceEvents) order.serviceEvents = [];

          if (!periodicEvent.lineItemTypeID) throw new Error('Line Item Type ID is required for periodic events');

          const newEvent = createBillingEvent(
            {
              ...periodicEvent,
              dateExpected: new Date().toISOString(),
            },
            periodicEvent.lineItemTypeID,
          );

          if (data.prorate) {
            const d = daysRemainingInBillingPeriod({
              billingFrequency: data.subscriptionDetails.billingFrequency,
              billingFrequencyUnit: data.subscriptionDetails.billingFrequencyUnit,
              billingDay: data.subscriptionDetails.billingDay,
            });
            newEvent.unitPrice = (d.daysRemaining / d.totalDays) * newEvent.unitPrice;
          }
          order.serviceEvents.push(newEvent);
        });
      }
      // remove service events with 0 cost.
      order.serviceEvents = order.serviceEvents?.filter((event) => event.unitPrice !== 0);

      order.serviceLocation = data.serviceGrouping.serviceOrders[0].serviceLocation; // Only one service location.
      order.internalNotes ? (order.internalNotes = order.internalNotes) : (order.internalNotes = '');
      order.needsAttention = false;
      for (let i = 1; i < data.quantity[index]; i++) {
        data.serviceGrouping.serviceOrders.push({
          ...order,
        });
      }
    });
    console.log('new DATA', data);

    // Create Service Grouping.
    const createServiceGroupingResponse = await client
      .universalService()
      .serviceGrouping.createRecursive(data.serviceGrouping);
    console.log('created Grouping', createServiceGroupingResponse);

    console.log('create invoice grouping', createServiceGroupingResponse.invoiceGroupings);
    // Create Invoices.

    console.log('create event', createServiceGroupingResponse);
    console.log(
      'create invoice grouping',
      createServiceGroupingResponse.invoiceGroupings[0],
      createServiceGroupingResponse,
    );
    const { receivable } = generatePayablesAndReceivable(
      createServiceGroupingResponse.invoiceGroupings[0],
      createServiceGroupingResponse,
      {
        customer,
      },
    );
    console.log('receivable', receivable);

    if (receivable) {
      const receivableResponse = await client.invoice().adminPortal.receivable.create(receivable);
      console.log('receivableResponse', receivableResponse);
      // Create Invoice Payment if charged.
      if (
        cardSuccessful &&
        stripeChargeID &&
        last4 &&
        data.serviceGrouping.paymentMethod &&
        receivableResponse.invoiceDetails.id
      ) {
        const invoicePayment: Invoice.PaymentCreateTransport = {
          paymentReceivedDate: new Date().toString(),
          amount: total,
          paymentMethod: data.serviceGrouping.paymentMethod,
          paymentIdentifier: last4,
          stripeChargeID: stripeChargeID,
        };
        console.log('invoicePayment', invoicePayment);
        const invoicePaymentResponse = await client
          .invoice()
          .adminPortal.payment.create(receivableResponse.invoiceDetails.id, invoicePayment);
        console.log('invoicePaymentResponse', invoicePaymentResponse);
      }
    }

    showFlash('Service Order Created', 'success');
    onCompleted?.(createServiceGroupingResponse);
  } catch (error) {
    let message = 'An Error Occurred. Please Try Again.';
    if ((error as Error)?.message) {
      message = (error as Error).message;
    }

    if (isAxiosError(error)) {
      message = error.response?.data?.additionalInfo || error.response?.data?.message || message;
    }

    console.log('Error creating service grouping', error);
    showFlash(message, 'warning');
  }
};
