import { useEffect, useState } from 'react';
import { Pricing } from '@alliance-disposal/transport-types';
import { sortByKey } from '@wayste/utils';
import HaulerPricingList from './HaulerPricingList';

type Props = {
  isLoading: boolean;
  pricing: Pricing.PricingTransport[];
  onEditClicked: (pricing: Pricing.PricingTransport) => void;
  onDeleteClicked: (pricing: Pricing.PricingTransport) => void;
  onAddMaterialClicked: (pricing: Pricing.PricingTransport) => void;
  onEditMaterialClicked: (pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => void;
  onServiceAreaSubmit: (data: any, zone: any) => void;
  onRefreshPricing: () => void;
};

/**
 * Gets and displays all pricing for hauler broken down by county
 * @param {boolean} isLoading Loading state
 * @param {Pricing.PricingTransport[]} pricing Array of pricing
 * @param {Function} onEditClicked Action to take on edit pricing
 * @param {Function} onDeleteClicked Action to take on delete pricing
 * @param {Function} onAddMaterialClicked Run function on Add Material Pricing clicked
 * @param {Function} onEditMaterialClicked Run function on edit material icon clicked
 * @param {Function} onServiceAreaSubmit Run function on service area submit
 * @param {Function} onRefreshPricing Run function on refresh pricing
 */
const HaulerPricingListContainer = ({
  isLoading,
  pricing,
  onEditClicked,
  onDeleteClicked,
  onAddMaterialClicked,
  onEditMaterialClicked,
  onServiceAreaSubmit,
  onRefreshPricing,
}: Props) => {
  const [sortedPricing, setSortedPricing] = useState(pricing);

  useEffect(() => {
    const pricingSorted = sortByKey('zoneName', pricing).filter((item: any) => !item.public);
    setSortedPricing(pricingSorted);
  }, [pricing]);

  return (
    <HaulerPricingList
      pricing={sortedPricing}
      isLoading={isLoading}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked}
      onAddMaterialClicked={onAddMaterialClicked}
      onEditMaterialClicked={onEditMaterialClicked}
      onServiceAreaSubmit={onServiceAreaSubmit}
      onRefreshPricing={onRefreshPricing}
    />
  );
};

export default HaulerPricingListContainer;
