import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Invoice } from '@alliance-disposal/transport-types';
import { Button, DatePicker, Select, SelectOption, TextField, Toggle } from '@wayste/sour-ui';
import { formatServiceAddress, getBillingEmailsString, moneyFormatter } from '@wayste/utils';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import { SEND_VENDOR_PAYOUT_SUMMARY, sendVendorDailyPayoutSummary } from '../../axios/ses';
import { UIContext } from '../../contexts';
import { haulerBillPaymentMethods } from '../../utils';
import Dialog from '../Dialog';
import Loading from '../Loading';
import PayableBreakdownDialog from './PayableBreakdownDialog';

type Props = {
  open: boolean;
  onClose: () => void;
};

export type FEObject = {
  haulerID: string;
  vendorName: string | undefined;
  feOnlyPaymentDate: Date;
  feOnlyPaymentMethod: string;
  feOnlyTransactionID: string;
  feOnlyPayablesBeingPaid: Invoice.PayableTransport[];
  payables: Invoice.PayableTransport[];
  hauler: Hauler.HaulerWithAapTransport | null;
};

const PayablesMakePayment = ({ open, onClose }: Props) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const [isLoading, setIsLoading] = useState(false);
  const [payables, setPayables] = useState<FEObject[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [sendPayoutEmails, setSendPayoutEmails] = useState(true);
  const [openPayableBreakdown, setOpenPayableBreakdown] = useState<null | FEObject>(null);

  const tableHeadings = [
    {
      heading: '√',
      style: { paddingLeft: 10, paddingRight: 10, maxWidth: 18 },
    },
    { heading: 'Hauler' },
    { heading: 'Hauler Inv #s' },
    { heading: 'Total Amt. to Pay' },
    { heading: 'Payment Date' },
    { heading: 'Default Method' },
    { heading: 'Amt. Paid' },
    { heading: 'Payment Method' },
    { heading: 'Transaction ID' },
  ];

  const groupByHaulerID = async (array: Invoice.PayableTransport[]) => {
    const grouped = array.reduce((accumulator: { [key: string]: Invoice.PayableTransport[] }, item) => {
      const haulerID = item.haulerID;
      // If the haulerID doesn't exist in the accumulator object, add it with an empty array
      if (!accumulator[haulerID]) {
        accumulator[haulerID] = [];
      }
      // Add the current item to the haulerID array in the accumulator
      if (item.invoiceDetails.remainingBalance !== 0) accumulator[haulerID].push(item);
      return accumulator;
    }, {});

    // Map the grouped object to the desired format
    const newObjects = Object.entries(grouped)
      .map(([haulerID, payables]) => ({
        haulerID,
        vendorName: payables[0]?.vendorName,
        feOnlyPaymentDate: new Date(),
        feOnlyPaymentMethod: '',
        feOnlyTransactionID: '',
        feOnlyPayablesBeingPaid: [...payables],
        payables,
        hauler: null as Hauler.HaulerWithAapTransport | null,
      }))
      .filter((item) => item.payables.length > 0);

    const finalObjects = [];

    for (const newObject of newObjects) {
      const haulerResponse = await client.vendorService().adminPortal.fetch(newObject.haulerID);

      if (haulerResponse) newObject.hauler = haulerResponse;

      finalObjects.push(newObject);
    }

    return finalObjects;
  };

  const handleGetPayables = async () => {
    setIsLoading(true);

    const response = await client.invoice().adminPortal.payable.query({
      void: false,
      readyForPayment: true,
      paidInFull: false,
    });

    const payableData = response || [];

    // check if any of them are missing a vendorName
    if (payableData.some((item) => !item.vendorName)) {
      // get all haulers
      const haulersResponse = await client.vendorService().adminPortal.query();

      // covert to map
      const haulersMap = new Map<string, Hauler.HaulerWithAapTransport>();

      haulersResponse.forEach((hauler) => {
        haulersMap.set(hauler.id, hauler);
      });

      payableData.forEach((item) => {
        const hauler = haulersMap.get(item.haulerID);
        if (hauler) item.vendorName = hauler.name;
      });
    }

    // group all payables by haulers & inject feOnly fields
    const haulersWithPayables = await groupByHaulerID(payableData);

    console.log('haulersWithPayables: ', haulersWithPayables);

    const sortedPayables = haulersWithPayables.sort((a, b) => {
      if ((a?.vendorName || '') > (b?.vendorName || '')) return 1;
      if ((b?.vendorName || '') > (a?.vendorName || '')) return -1;
      return -1;
    });
    setPayables(sortedPayables);
    setIsLoading(false);
  };

  useEffect(() => {
    // Get all applicable payables
    if (open) handleGetPayables();
    // Prevent the user from accidentally closing the window
    const handleBeforeUnload = (event: { preventDefault: () => void; returnValue: string }) => {
      if (open) {
        event.preventDefault();
        event.returnValue = ''; // Chrome requires returnValue to be set.
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [open]);

  const getTotalAmountDue = (payableArray: Invoice.PayableTransport[]) => {
    let total = 0;
    payableArray.forEach((item) => {
      total = total + item.invoiceDetails.remainingBalance;
    });
    return total;
  };

  const handlePayableValueChange = (
    value: string | Date,
    payable: FEObject,
    field: 'feOnlyPaymentDate' | 'feOnlyPaymentMethod' | 'feOnlyTransactionID',
  ) => {
    const payablesCopy = [...payables];
    const foundIndex = payablesCopy.findIndex((item) => item.haulerID === payable.haulerID);
    const payableItemCopy = { ...payablesCopy[foundIndex] };
    if (field === 'feOnlyPaymentDate') {
      payableItemCopy[field] = value as Date;
    } else {
      payableItemCopy[field] = value as string;
    }
    payablesCopy[foundIndex] = payableItemCopy;
    setPayables(payablesCopy);
  };

  const sanitizeSheetName = (name: string) => {
    let sanitizedName = name.replace(/[:\\/?*[\]]/g, ''); // replace invalid characters
    sanitizedName = sanitizedName.slice(0, 31); // truncate to maximum length
    return sanitizedName;
  };

  const handleDownloadPayoutReport = (payoutSummary: { vendorName: string; data: string[][] }[]) => {
    // create a new workbook
    const wb = XLSX.utils.book_new();
    const sheets = [];
    for (const summary of payoutSummary) {
      // convert the data to a worksheet && add the worksheet to the workbook
      sheets.push({ ws: XLSX.utils.aoa_to_sheet(summary.data), name: sanitizeSheetName(summary.vendorName) });
    }
    sheets.forEach((item) => {
      XLSX.utils.book_append_sheet(wb, item.ws, item.name);
    });
    // write the workbook and download it
    XLSX.writeFile(wb, 'payout_summary.xlsx');
  };

  const getTotalBeingPaid = (payablesBeingPaid: Invoice.PayableTransport[]) => {
    let sum = 0;
    payablesBeingPaid.forEach((obj) => {
      if (obj.invoiceDetails && obj.invoiceDetails.remainingBalance) {
        sum += obj.invoiceDetails.remainingBalance;
      }
    });

    return sum;
  };

  const createPayoutSummaryEmail = (
    vendor: FEObject,
    transactionID: string,
    payablesTotal: number,
    paymentMethod: string,
  ) => {
    const htmlMessage = `
      <p>Hi ${vendor.vendorName},</p>
      <p>Attached please find a xlsx sheet with a breakdown for the payout transaction ID ${transactionID} totaling ${moneyFormatter(
      payablesTotal,
    )} sent to you via ${paymentMethod}.</p>
      <p>Best,</p>
      <p>Sourgum</p>
  `;
    return htmlMessage;
  };

  const handleSendingPayoutSummaryVendorEmails = async (
    payoutSummary: {
      vendorName: string;
      data: string[][];
      vendor: FEObject;
      payablesTotal: number;
      transactionID: string;
      paymentMethod: string;
    }[],
  ) => {
    console.log('payoutSummary here: ', payoutSummary);
    for (const summary of payoutSummary) {
      // create a new workbook
      const wb = XLSX.utils.book_new();
      const wbSheet = { ws: XLSX.utils.aoa_to_sheet(summary.data), name: sanitizeSheetName(summary.vendorName) };
      XLSX.utils.book_append_sheet(wb, wbSheet.ws, wbSheet.name);
      // Write workbook to binary string
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      // Convert binary string to base64
      const base64 = btoa(String.fromCharCode(...new Uint8Array(wbout)));
      const emailData: SEND_VENDOR_PAYOUT_SUMMARY = {
        toAddress: getBillingEmailsString(summary.vendor.hauler?.contacts),
        htmlMessage: createPayoutSummaryEmail(
          summary.vendor,
          summary.transactionID,
          summary.payablesTotal,
          summary.paymentMethod,
        ),
        subject: 'Sourgum Waste Payout Summary',
        fileName: 'sourgum_payout.xlsx',
        attachmentBase64: base64,
        sender: 'billing@sourgum.com',
      };
      console.log('emailData: ', emailData);
      await sendVendorDailyPayoutSummary(emailData);
    }
    return true;
  };

  const handleSave = async () => {
    setIsUpdating(true);
    const payoutSummary = [];
    for (const hauler of payables) {
      if (hauler.feOnlyTransactionID) {
        let payablesTotal = hauler.feOnlyPayablesBeingPaid.reduce((prev, curr) => {
          return prev + curr.invoiceDetails.remainingBalance;
        }, 0);
        payablesTotal = payablesTotal;
        const haulerDownload = {
          vendorName: hauler.vendorName || '',
          data: [['Vendor Name', 'Date', 'Paid Via', 'Transaction ID', 'Vendor Inv #', 'Amount', 'Service Location']],
          vendor: hauler,
          payablesTotal: payablesTotal,
          transactionID: hauler.feOnlyTransactionID,
          paymentMethod: haulerBillPaymentMethods[hauler.feOnlyPaymentMethod as keyof typeof haulerBillPaymentMethods],
        };
        for (const payable of hauler.feOnlyPayablesBeingPaid) {
          const paymentModel = {
            date: hauler.feOnlyPaymentDate.toISOString(),
            amountDollars: payable.invoiceDetails.remainingBalanceDollars,
            paymentMethod: hauler.feOnlyPaymentMethod,
            paymentIdentifier: hauler.feOnlyTransactionID,
            syncedWithAccounting: false,
          };
          haulerDownload.data.push([
            hauler.vendorName || '',
            format(hauler.feOnlyPaymentDate, 'MM/dd/yy'),
            haulerBillPaymentMethods[hauler.feOnlyPaymentMethod as keyof typeof haulerBillPaymentMethods],
            hauler.feOnlyTransactionID,
            payable.invoiceDetails.invoiceNumber || '',
            moneyFormatter(payable.invoiceDetails.remainingBalance),
            formatServiceAddress(payable.invoiceDetails.orderServiceLocation?.address),
          ]);
          try {
            await client.invoice().adminPortal.payment.create(payable.invoiceDetails.id, paymentModel);
          } catch (error) {
            console.warn('PayablesMakePayment handleSave error: ', error);
            alert('STOP. An error occurred, touch nothing and get a SAP dev');
          }
        }
        payoutSummary.push(haulerDownload);
      }
    }
    handleDownloadPayoutReport(payoutSummary);
    if (sendPayoutEmails) await handleSendingPayoutSummaryVendorEmails(payoutSummary);
    showFlash('Payables Successfully Updated', 'success');
    onClose();
    setIsUpdating(false);
  };

  const getHaulerInvoiceNumberString = (payableArray: Invoice.PayableTransport[]) => {
    return payableArray
      .filter(
        (obj, index: number, self) =>
          index === self.findIndex((el) => el.invoiceDetails.invoiceNumber === obj.invoiceDetails.invoiceNumber),
      )
      .map((payable) => payable.invoiceDetails.invoiceNumber)
      .join(', ')
      .trim();
  };

  const handleBreakdownCheckboxClick = (id: string, checked: boolean) => {
    if (!openPayableBreakdown) return;
    const newFEObject = { ...openPayableBreakdown };
    const newPayables = [...payables];
    const foundIndex = newPayables.findIndex((item) => item.haulerID === openPayableBreakdown?.haulerID);
    let updatedArray = [...newFEObject.feOnlyPayablesBeingPaid];
    if (!checked) {
      // remove
      updatedArray = updatedArray.filter((item) => item.id !== id);
    } else {
      // add
      const newItem = newFEObject.payables.find((item) => item.id === id);
      if (newItem) updatedArray.push(newItem);
    }
    newFEObject.feOnlyPayablesBeingPaid = updatedArray;
    newPayables[foundIndex] = newFEObject;
    setPayables(newPayables);
    setOpenPayableBreakdown(newFEObject);
  };

  if (!open) return null;
  if (isLoading) return <Loading fullScreen />;

  return (
    <Dialog styledTitle="Pay Bills" fullScreen hideX open={open} onClose={() => onClose()}>
      <PayableBreakdownDialog
        open={Boolean(openPayableBreakdown)}
        onClose={() => setOpenPayableBreakdown(null)}
        feObject={openPayableBreakdown}
        onCheckboxClick={handleBreakdownCheckboxClick}
      />
      <div className="w-full flex justify-end my-5">
        <Toggle
          label="Send payout summary emails"
          value={sendPayoutEmails}
          onChange={(value) => setSendPayoutEmails(value)}
        />
      </div>
      <div>
        <div className="w-full overflow-x-auto">
          <table className="w-full border-collapse border-spacing-0 text-sm">
            <thead>
              <tr className="border-b [&>*]:p-4">
                {tableHeadings.map((heading) => (
                  <th key={heading.heading} style={heading.style || {}}>
                    {heading.heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {payables.map((item) => (
                <tr className="[&>*]:p-4 border-b" key={item.haulerID}>
                  {/* Check Mark */}
                  <td>
                    {item.feOnlyTransactionID ? (
                      <CheckCircleIcon className="h-6 w-6 text-success" fontSize="small" />
                    ) : null}
                    <div>
                      <button className="btn-primary-text-only" onClick={() => setOpenPayableBreakdown(item)}>
                        View Breakdown
                      </button>
                    </div>
                  </td>
                  {/* Hauler */}
                  <td>{item.vendorName}</td>
                  {/* Hauler Inv #s */}
                  <td>{getHaulerInvoiceNumberString(item.payables)}</td>
                  {/* Total Amt. to Pay */}
                  <td>{moneyFormatter(getTotalAmountDue(item.payables))}</td>
                  {/* Payment Date */}
                  <td className="w-[250px]">
                    <div className="w-[250px]">
                      <DatePicker
                        label="Payment date"
                        value={item.feOnlyPaymentDate}
                        onChange={(value) => handlePayableValueChange(value, item, 'feOnlyPaymentDate')}
                      />
                    </div>
                  </td>
                  {/* Default Method */}
                  <td className="w-[232px]">
                    {
                      haulerBillPaymentMethods[
                        item.hauler?.defaultPaymentMethod as keyof typeof haulerBillPaymentMethods
                      ]
                    }
                  </td>
                  {/* Amt. Paid */}
                  <td>{moneyFormatter(getTotalBeingPaid(item.feOnlyPayablesBeingPaid))}</td>
                  {/* Payment Method */}
                  <td className="w-[232px]">
                    <div className="w-[200px]">
                      <Select
                        label="Payment method"
                        value={item.feOnlyPaymentMethod}
                        onSelect={(value) => handlePayableValueChange(value, item, 'feOnlyPaymentMethod')}
                      >
                        {Object.entries({ ...haulerBillPaymentMethods, '': 'None' }).map((e) => (
                          <SelectOption value={e[0]} key={e[0]}>
                            {e[1]}
                          </SelectOption>
                        ))}
                      </Select>
                    </div>
                  </td>
                  {/* Transaction ID */}
                  <td className="w-[232px]">
                    <div className="w-[200px]">
                      <TextField
                        label="Transaction ID"
                        inputProps={{
                          value: item.feOnlyTransactionID,
                          onChange: (e) => handlePayableValueChange(e.target.value, item, 'feOnlyTransactionID'),
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
              <tr className="[&>*]:p-4 border-b" key="totals">
                {/* Check Mark */}
                <td colSpan={3} />
                {/* Total Amt. to Pay */}
                <td>
                  <span>
                    {'Total: ' +
                      moneyFormatter(
                        payables.reduce((prev, curr) => {
                          return (
                            prev +
                            curr.feOnlyPayablesBeingPaid.reduce((prev2, curr2) => {
                              return prev2 + curr2.invoiceDetails.remainingBalance;
                            }, 0)
                          );
                        }, 0),
                      )}
                  </span>
                </td>
                <td colSpan={2} />
                {/* Amt. Paid */}
                <td>
                  <span>
                    {'Total Paid: ' +
                      moneyFormatter(
                        payables
                          .filter((fe) => fe.feOnlyTransactionID)
                          .reduce((prev, curr) => {
                            return (
                              prev +
                              curr.feOnlyPayablesBeingPaid.reduce((prev2, curr2) => {
                                return prev2 + curr2.invoiceDetails.remainingBalance;
                              }, 0)
                            );
                          }, 0),
                      )}
                  </span>
                </td>
                <td colSpan={2} />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex justify-end gap-4 pt-5">
        <button className="btn-dark-grey-outlined" onClick={() => onClose()} disabled={isUpdating}>
          Cancel
        </button>
        <Button
          className="btn-primary"
          disabled={
            payables.some(
              (item) =>
                (!item.feOnlyTransactionID && item.feOnlyPaymentMethod) ||
                (item.feOnlyTransactionID && !item.feOnlyPaymentMethod),
            ) || isUpdating
          }
          onClick={() => handleSave()}
          loading={isUpdating}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
};

export default PayablesMakePayment;
