import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UIContext } from '../contexts';
import DesktopToolbar from './DesktopToolbar';
import MobileToolbar from './MobileToolbar';
// import ReleaseNotes from './ReleaseNotes';
import Routes from './Routes';

const Layout = ({ onSignOut }: { onSignOut: () => void }) => {
  const { isDesktop } = useContext(UIContext);
  const client = useWaysteClient();

  const [isAppAdmin, setIsAppAdmin] = useState(false);

  useEffect(() => {
    const sub = client.auth().appAdmin.subscribe((admin) => setIsAppAdmin(Boolean(admin)));
    return () => sub.unsubscribe();
  }, []);

  // const [releaseNotesOpen, setReleaseNotesOpen] = useState(false);

  // const handleReleaseNotesClose = () => {
  //   setReleaseNotesOpen(false);
  //   // Untested as release notes are never opened.
  //   client.user().updateCurrentUserProfile({ lastReleaseNotes: pjson.version });
  // };

  return (
    <div className={`flex flex-grow ${isDesktop ? 'flex-row' : 'flex-col'} min-h-screen max-h-screen overflow-auto`}>
      {isDesktop ? <DesktopToolbar onLogout={onSignOut} /> : <MobileToolbar onLogout={onSignOut} />}

      {/* <ReleaseNotes open={releaseNotesOpen} onClose={handleReleaseNotesClose} /> */}

      <main className="flex flex-grow flex-1 flex-col overflow-auto">
        <Routes isAppAdmin={true} />
      </main>
    </div>
  );
};

export default Layout;
