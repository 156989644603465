import React, { useEffect } from 'react';
import { Invoice, UniversalService } from '@alliance-disposal/transport-types';
import { CurrencyTextField, RadioButton, Select, SelectOption, TextField, Toggle } from '@wayste/sour-ui';
import { dollarsToCents, moneyFormatter, pricingBreakdownTotal, round } from '@wayste/utils';
import { Controller, useForm } from 'react-hook-form';
import Dialog from '../../../../components/Dialog';
import { receivableLineItemOptions } from '../../../../utils/invoice-utils';

export type NewLineItemType = {
  itemName: string;
  description: string;
  quantity: number;
  unitPrice: number;
  taxable: boolean;
  totalPrice: number;
};

interface FormProps {
  itemName: string;
  description: string;
  quantity: string;
  unitPrice: string;
  totalPrice: string;
  taxable: boolean;
  addTax: boolean;
  addCc: boolean;
}

interface Props {
  lineItem: Invoice.LineItemTransport;
  ccRate: number;
  taxRate: number;
  open: boolean;
  onCancel: () => void;
  onSave: (newLineItems: Partial<Invoice.LineItemInputTransport>[]) => void;
  onDeleteItem: () => void;
  selectedInvoice?: Invoice.ReceivableTransport | null;
  order: UniversalService.ServiceOrder;
}

const taxOptions = [
  {
    value: 'false',
    label: 'Tax amount included in total',
  },
  {
    value: 'true',
    label: 'Add tax amount on top of total',
  },
];

const ccOptions = [
  {
    value: 'false',
    label: 'CC fee included in total',
  },
  {
    value: 'true',
    label: 'Add CC fee on top of total',
  },
];

const ItemMenuItems = receivableLineItemOptions.map((item) => (
  <SelectOption key={item} value={item}>
    {item}
  </SelectOption>
));

const LineItemModal = ({
  lineItem,
  ccRate,
  taxRate,
  open,
  onCancel,
  onSave,
  onDeleteItem,
  selectedInvoice,
  order,
}: Props) => {
  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    setValue,
    reset,
    watch,
    handleSubmit,
    trigger,
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      itemName: '',
      description: '',
      quantity: '',
      unitPrice: '',
      totalPrice: '',
      taxable: false,
      addTax: false,
      addCc: false,
    },
  });

  const watchAllValues = watch();

  useEffect(() => {
    if (lineItem) {
      const newData = JSON.parse(JSON.stringify(lineItem));
      reset({
        itemName: newData.itemName,
        description: newData.description || '',
        quantity: newData.quantity,
        unitPrice: (newData.unitPrice / 100).toString(),
        totalPrice: ((+newData.quantity * +newData.unitPrice) / 100).toString(),
        taxable: newData.taxable,
        addTax: newData.taxable ? true : false,
        addCc: true,
      });
    } else {
      reset({
        itemName: '',
        description: `${order?.poNumber ? `${order?.poNumber} - ` : ''}${order?.serviceType.family.name} ${
          order?.serviceType.name
        }`,
        quantity: '',
        unitPrice: '',
        totalPrice: '',
        taxable: false,
        addTax: false,
        addCc: false,
      });
    }
  }, [lineItem, reset]);

  const handleQtyRateChange = (data: FormProps) => {
    if (data.unitPrice && data.quantity) {
      setValue('totalPrice', round(+data.unitPrice * +data.quantity).toString());
      trigger('totalPrice');
    }
  };

  const handleAmountChange = (data: FormProps) => {
    if (!data.quantity) {
      setValue('quantity', '1');
      setValue('unitPrice', round(+data.totalPrice, 5).toString());
      trigger(['unitPrice', 'quantity']);
    } else {
      setValue('unitPrice', round(+data.totalPrice / +data.quantity, 5).toString());
      trigger('unitPrice');
    }
  };

  const getTotal = (data: any) => {
    const tax = data.taxable && data.addTax ? round(+data.unitPrice * +data.quantity * taxRate, 5) : 0;
    const total = round((+data.unitPrice * +data.quantity + tax) / (1 - (ccRate && data.addCc ? ccRate : 0)));
    return total;
  };

  const handleItemNameChange = (value: string) => {
    setValue('itemName', value);
    if (value === 'Rental Extension') {
      // @ts-expect-error - waiting on Jason to fix transport types
      setValue('unitPrice', order.rentExtensionFeeDollars.toString());
      trigger('unitPrice');
    }
  };

  const handleCancel = () => {
    onCancel();
    reset();
  };

  const onSubmit = (data: FormProps) => {
    const cleanRate = pricingBreakdownTotal(getTotal(data), +data.quantity, data.taxable ? taxRate : 0, ccRate);
    const newLineItems: Invoice.LineItemInputTransport[] = [
      {
        itemName: data.itemName,
        description: data.description,
        quantity: +data.quantity,
        unitPrice: dollarsToCents(+data.unitPrice),
        taxable: data.taxable,
        totalPrice: dollarsToCents(round(+data.quantity * +data.unitPrice)),
      },
    ];
    if (ccRate && selectedInvoice) {
      const existingLineItem = selectedInvoice.invoiceDetails.lineItems.find((item) => item.itemName === 'CC Fee');

      let newCCFeeLineItem: Invoice.LineItemInputTransport | undefined;

      if (!existingLineItem) {
        newCCFeeLineItem = {
          itemName: 'CC Fee',
          description: '',
          quantity: 1,
          unitPrice: dollarsToCents(cleanRate.ccFee),
          taxable: false,
          totalPrice: dollarsToCents(cleanRate.ccFee),
        };
      }

      if (existingLineItem) {
        newCCFeeLineItem = {
          ...existingLineItem,
          unitPrice: (existingLineItem.unitPrice || 0) + cleanRate.ccFee,
          totalPrice: (existingLineItem.totalPrice || 0) + cleanRate.ccFee,
        };
      }

      if (!newCCFeeLineItem) {
        throw new Error('newCCFeeLineItem is undefined');
      }

      newLineItems.push(newCCFeeLineItem);
    }
    onSave(newLineItems);
    reset();
  };

  if (!open) return null;

  return (
    <Dialog open={open} onClose={handleCancel} styledTitle={`${lineItem ? 'Edit' : 'Add'} Charge`}>
      <form className="flex flex-col gap-6">
        <div>
          {lineItem && !receivableLineItemOptions.includes(lineItem.itemName) ? (
            <p className="opacity-70">{lineItem.itemName}</p>
          ) : (
            <Controller
              name="itemName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'An item name is required',
                },
              }}
              render={({ field }) => (
                <Select
                  label="Item"
                  value={field.value}
                  onSelect={(value) => handleItemNameChange(value)}
                  required
                  error={errors.itemName}
                >
                  {ItemMenuItems}
                </Select>
              )}
            />
          )}
        </div>
        <div>
          <TextField label="Description" inputProps={{ ...register('description') }} />
        </div>
        <div>
          <TextField
            label="Quantity"
            inputProps={{
              ...register('quantity', { required: 'Quantity is required' }),
              step: '0.01',
              onBlur: () => {
                handleQtyRateChange(watchAllValues);
              },
            }}
            required
            type="number"
          />
        </div>
        <div>
          <Controller
            name="unitPrice"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Rate is required',
              },
            }}
            render={({ field }) => (
              <CurrencyTextField
                label="Rate"
                required
                value={field.value}
                onChange={(value) => field.onChange(value)}
                error={errors.unitPrice}
                inputProps={{
                  onBlur: () => {
                    handleQtyRateChange(watchAllValues);
                  },
                }}
                numericFormatProps={{ decimalScale: 5 }}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="totalPrice"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Amount is required',
              },
            }}
            render={({ field }) => (
              <CurrencyTextField
                label="Amount"
                required
                value={field.value}
                onChange={(value) => field.onChange(value)}
                error={errors.totalPrice}
                inputProps={{
                  onBlur: () => {
                    handleAmountChange(watchAllValues);
                  },
                }}
              />
            )}
          />
        </div>
        <div className="flex flex-col gap-3">
          <Controller
            name="taxable"
            control={control}
            render={({ field }) => (
              <Toggle
                label={`Taxable ${taxRate * 100}%`}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                disabled={!taxRate}
              />
            )}
          />
          {watchAllValues.taxable ? (
            <Controller
              name="addTax"
              control={control}
              render={({ field }) => (
                <RadioButton
                  options={taxOptions.map((item) => ({
                    ...item,
                    inputProps: { checked: (field.value ? 'true' : 'false') === item.value },
                  }))}
                  inputProps={{
                    value: field.value ? 'true' : 'false',
                    onChange: (e) => field.onChange(e.target.value === 'true' ? true : false),
                  }}
                />
              )}
            />
          ) : null}
        </div>
        {ccRate ? (
          <div className="flex flex-col gap-3">
            <p className="opacity-60">Charge CC Fee</p>
            <Controller
              name="addCc"
              control={control}
              render={({ field }) => (
                <RadioButton
                  options={ccOptions.map((item) => ({
                    ...item,
                    inputProps: { checked: (field.value ? 'true' : 'false') === item.value },
                  }))}
                  inputProps={{
                    value: field.value ? 'true' : 'false',
                    onChange: (e) => field.onChange(e.target.value === 'true' ? true : false),
                  }}
                />
              )}
            />
          </div>
        ) : null}
      </form>
      <div className="text-lg mt-6">Total: {moneyFormatter(getTotal(watchAllValues), { isDollars: true })}</div>
      <div className="flex justify-end gap-4 pt-4 border-t mt-6">
        <button
          className="btn-delete mr-auto"
          onClick={() => onDeleteItem()}
          disabled={!lineItem || watchAllValues.itemName === 'QP-Haul' || watchAllValues.itemName === 'QP-Dump'}
        >
          delete
        </button>
        <button className="btn-dark-grey-outlined" onClick={() => handleCancel()}>
          Cancel
        </button>
        <button className="btn-primary" type="button" disabled={!isValid || !isDirty} onClick={handleSubmit(onSubmit)}>
          {lineItem ? 'Update' : 'Add'}
        </button>
      </div>
    </Dialog>
  );
};

export default LineItemModal;
