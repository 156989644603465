import React from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { useHistory, useParams } from 'react-router-dom';
import Dialog from '../../components/Dialog';
import OrderDetailsContent from '../../components/OrderDetails';

interface OrderDetailsProps {
  isModal?: boolean;
}

const OrderDetails = ({ isModal }: OrderDetailsProps) => {
  const { id }: { id: string } = useParams();
  const history = useHistory();

  const handleBackButtonClick = () => {
    history.goBack();
  };

  if (isModal)
    return (
      <Dialog open={true} onClose={handleBackButtonClick} styledTitle="Order Details" fullScreen>
        <div className="bg-[#F9F9F9] h-full">
          <OrderDetailsContent id={id} />
        </div>
      </Dialog>
    );

  return (
    <div className="bg-[#F9F9F9]">
      <div className="container mx-auto p-6 pt-5">
        <div className="flex items-center">
          <div>
            <button className="btn-icon" onClick={handleBackButtonClick}>
              <ChevronLeftIcon className="h-6 w-6" />
            </button>
          </div>
          <div className="text-xl">Order Details</div>
        </div>
        <OrderDetailsContent id={id} />
      </div>
    </div>
  );
};

OrderDetails.defaultProps = {
  isModal: false,
};

export default OrderDetails;
