import { SVGProps } from 'react';

interface FaceNeutralIconProps extends SVGProps<SVGSVGElement> {
  titleId?: string | undefined;
}

export const FaceNeutralIcon = ({ ...props }: FaceNeutralIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth={1.5}
      stroke="currentColor"
      aria-hidden={true}
      aria-labelledby={props.titleId}
      {...props}
    >
      <path
        d="M15 16C14.5 16 14 16 13.5 16C13 16 12.591 16 12 16C11.409 16 11 16 10.5 16C10 16 9.383 16 9 16M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM9.75 9.75C9.75 10.164 9.582 10.5 9.375 10.5C9.168 10.5 9 10.164 9 9.75C9 9.336 9.168 9 9.375 9C9.582 9 9.75 9.336 9.75 9.75ZM9.375 9.75H9.383V9.765H9.375V9.75ZM15 9.75C15 10.164 14.832 10.5 14.625 10.5C14.418 10.5 14.25 10.164 14.25 9.75C14.25 9.336 14.418 9 14.625 9C14.832 9 15 9.336 15 9.75ZM14.625 9.75H14.633V9.765H14.625V9.75Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
