import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { UIContext } from '../../contexts';
import CustomerUpdate from './CustomerUpdate';

interface CustomerUpdateContainerProps {
  customer: Customer.AllianceCustomerTransport;
  open: boolean;
  onBackButtonClick: () => void;
}

const CustomerUpdateContainer = ({ customer, open, onBackButtonClick }: CustomerUpdateContainerProps) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (data: Customer.AllianceCustomerTransport) => {
    setIsLoading(true);

    try {
      await client.customer().adminPortal.update(data.id, data);
      showFlash('Customer Successfully Updated', 'success');
      onBackButtonClick();
    } catch (error) {
      showFlash('An Error Occurred Updating Customer', 'warning');
      console.log(error);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CustomerUpdate
      customer={customer}
      onBackButtonClick={onBackButtonClick}
      open={open}
      onSubmit={submitHandler}
      isLoading={isLoading}
    />
  );
};

export default CustomerUpdateContainer;
