import React, { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import ButtonSelect from '../ButtonSelect';
import DataDumper from './DataDumper';
import JoesXXXLab from './JoesXXXLab';
import MarketingReports from './MarketingReport';
import OrdersHeatMap from './OrdersHeatMap';
import QBUpload from './QBOUpload';
import QuickReports from './QuickReports';

const reportOptions = {
  0: {
    label: 'Quick Reports',
    value: 0,
  },
  2: {
    label: 'Cluster Map',
    value: 2,
  },
  6: {
    label: 'Upload Invoices & Bills to QB',
    value: 6,
  },
  9: {
    label: 'Data Dumper',
    value: 9,
  },
  10: {
    label: 'Marketing Report',
    value: 10,
  },
  99: {
    label: 'Do Not Touch This Option - EVER',
    value: 99,
  },
};

const Reports = () => {
  const client = useWaysteClient();
  const [selectedReport, setSelectedReport] = useState(0);

  const handleChange = (newValue: number) => {
    setSelectedReport(newValue);
  };

  const handleGetOrdersByDateRangeSimple = async (startDate: Date, endDate: Date) => {
    const start = new Date(new Date(startDate).setHours(0, 0, 0, 0)).toISOString();
    const end = new Date(new Date(endDate).setHours(24, 0, 0, 0)).toISOString();

    const response = await client.order().adminPortal.query({
      createdAfter: format(new Date(start), 'yyyy-MM-dd'),
      createdBefore: format(new Date(end), 'yyyy-MM-dd'),
      limit: 10000,
    });

    return response.results;
  };

  const getView = () => {
    switch (selectedReport) {
      case 0:
        return <QuickReports />;
      case 2:
        return <OrdersHeatMap onGetOrders={handleGetOrdersByDateRangeSimple} />;
      case 6:
        return <QBUpload />;
      case 9:
        return <DataDumper />;
      case 10:
        return <MarketingReports />;
      case 99:
        return <JoesXXXLab />;
      default:
        return <div>Error Out of Range Value</div>;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <AppBar
        color="default"
        position="relative"
        style={{
          flexDirection: 'row',
          padding: '6px 12px',
          alignItems: 'center',
          marginBottom: 15,
        }}
      >
        <Typography variant="h6" style={{ marginRight: 24 }}>
          Reports
        </Typography>
        <ButtonSelect
          label={reportOptions[selectedReport as keyof typeof reportOptions].label}
          menuItems={Object.values(reportOptions)}
          value={selectedReport}
          onSelect={handleChange}
        />
      </AppBar>

      {getView()}
    </div>
  );
};

export default Reports;
