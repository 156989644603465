import React, { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { SourFilters, SourSearch, SourSearchWrapper } from '@wayste/sour-search';
import { Button, TextField } from '@wayste/sour-ui';
import { centsToDollars, formatServiceAddress, formatUSD } from '@wayste/utils';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import Dialog from '../../../components/Dialog';

const PayoutSummaryModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const client = useWaysteClient();
  const [transactionID, setTransactionID] = useState('');
  const [vendorID, setVendorID] = useState<string>();
  const [loading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string>();
  const [search, setSearch] = useState<string>('');

  // DEFINE ON NAVIGATE LOGIC
  const onNavigate = (entity: any, recordID: string) => {
    setSearch(entity?.name);
    setVendorID(recordID);
  };

  const sanitizeSheetName = (name: string) => {
    let sanitizedName = name.replace(/[:\\/?*[\]]/g, ''); // replace invalid characters
    sanitizedName = sanitizedName.slice(0, 31); // truncate to maximum length
    return sanitizedName;
  };

  const handleDownloadPayoutReport = (payoutSummary: { [key: string]: string[][] }) => {
    // create a new workbook
    const wb = XLSX.utils.book_new();
    const sheets = [];
    for (const vendorName in payoutSummary) {
      // convert the data to a worksheet && add the worksheet to the workbook
      sheets.push({ ws: XLSX.utils.aoa_to_sheet(payoutSummary[vendorName]), name: sanitizeSheetName(vendorName) });
    }
    sheets.forEach((item) => {
      XLSX.utils.book_append_sheet(wb, item.ws, item.name);
    });
    // write the workbook and download it
    XLSX.writeFile(wb, 'payout_summary.xlsx');
  };

  const handleOnClose = () => {
    onClose();
    setTransactionID('');
    setVendorID('');
    setIsLoading(false);
    setSearch('');
    setMessage('');
  };

  const sanitizeStringForObjKey = (str: string) => {
    // Step 1: Remove leading and trailing whitespace
    const trimmedStr = str.trim();
    // Step 2: Replace special characters with a safe alternative
    const sanitizedStr = trimmedStr.replace(/[^\w]/g, '-');
    // Step 3: Convert to lowercase
    const lowercaseStr = sanitizedStr.toLowerCase();
    return lowercaseStr;
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage(undefined);
    if (!transactionID) return;
    try {
      setIsLoading(true);

      const response = await client.invoice().adminPortal.payment.query({
        paymentIdentifer: transactionID,
        haulerID: vendorID || undefined,
      });

      if (!response) {
        throw new Error('Query order error');
      }
      const payments = response?.results;
      console.log('payments: ', payments);

      if (!payments || payments?.length === 0) {
        setMessage('No payments found for provided filters.');
        setIsLoading(false);
        return;
      }

      const paymentsObj: { [key: string]: string[][] } = {};

      for (const payment of payments) {
        if (!payment.invoiceParentID) return;
        const payableResponse = await client.invoice().adminPortal.payable.fetch(payment.invoiceParentID);
        const payable = payableResponse;
        console.log('payable: ', payable);
        if (!payable) {
          alert('Missing payable get SAP dev');
          return;
        }

        if (!payable.invoiceDetails.orderServiceLocation?.address && payable.invoiceDetails.orderID) {
          const order = await client.order().adminPortal.fetch(payable.invoiceDetails.orderID);
          payable.invoiceDetails.orderServiceLocation = order?.serviceLocation;
        }

        const newArray = [
          payable.vendorName || search || payable.haulerID,
          format(new Date(payment.date), 'MM/dd/yy'),
          payment.paymentMethod || '',
          payment.paymentIdentifier || '',
          payable.invoiceDetails.invoiceNumber || '',
          formatUSD(centsToDollars(payment.amount)) || '',
          formatServiceAddress(payable.invoiceDetails.orderServiceLocation?.address) || '',
          payable.invoiceDetails.orderNumber || '',
        ];
        if (paymentsObj[sanitizeStringForObjKey(payable.vendorName || search || payable.haulerID)]) {
          console.log(paymentsObj);
          paymentsObj[sanitizeStringForObjKey(payable.vendorName || search || payable.haulerID)].push(newArray);
        } else {
          paymentsObj[sanitizeStringForObjKey(payable.vendorName || search || payable.haulerID)] = [
            [
              'Vendor Name',
              'Payment Date',
              'Paid Via',
              'Transaction ID',
              'Vendor Inv #',
              'Amount',
              'Service Location',
              'PO Number',
            ],
            newArray,
          ];
        }
      }
      handleDownloadPayoutReport(paymentsObj);
    } catch (error) {
      console.warn('submit error: ', error);
      alert('An error has occurred, please try again or contact dev.');
    }
    setIsLoading(false);
  };

  return (
    <Dialog
      styledTitle="Payout Summary"
      open={open}
      onClose={() => handleOnClose()}
      className="w-[100%] overflow-visible"
    >
      <form onSubmit={onSubmit} className="flex flex-col gap-3">
        {search ? (
          <div className="p-1 flex flex-row justify-between items-center h-6">
            <div>{search}</div>
            <Button
              onClick={() => {
                setSearch('');
                setVendorID('');
              }}
            >
              <XMarkIcon className="w-4 h-4" />
            </Button>
          </div>
        ) : (
          <div>
            <SourSearchWrapper
              options={{
                application: 'aap',
                apiKey: import.meta.env.VITE_ELASTIC_KEY,
                environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
              }}
              onNavigate={onNavigate}
              highlight={true}
              defaultFilters={{
                query: {
                  type: 'sourgum-vendor',
                },
              }}
              size={10}
            >
              <div className="flex flex-row justify-between space-x-4 w-full">
                <SourSearch
                  options={{
                    searchPopoverFixed: false,
                    showTips: false,
                    showMessages: false,
                    resultSize: 'w-[550px]',
                    searchPositionFixed: true,
                    placeholder: 'Search vendors',
                  }}
                />
                <div className="hidden">
                  <SourFilters />
                </div>
              </div>
            </SourSearchWrapper>
          </div>
        )}
        <TextField
          label="Transaction ID"
          required
          inputProps={{
            value: transactionID,
            onChange: (e) => setTransactionID(e.target.value),
          }}
        />
        <div>{message}</div>
        <Button className="btn-primary w-full" type="submit" loading={loading} disabled={!transactionID}>
          Download
        </Button>
      </form>
    </Dialog>
  );
};

export default PayoutSummaryModal;
