import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

type Props = {
  menuItems: { label: string; value: string | number | undefined }[];
  onSelect: (value: any) => void;
  value?: string | number;
  wrapperClass?: string;
  btnClassName?: string;
  label?: string;
  noneOption?: boolean;
  disabled?: boolean;
};

const defaultProps = {
  label: '',
  noneOption: false,
  wrapperClass: '',
  btnClassName: '',
  value: '',
  disabled: false,
};

const ButtonSelect = ({
  label,
  value,
  menuItems,
  onSelect,
  noneOption,
  wrapperClass,
  btnClassName,
  disabled,
}: Props) => {
  const [btnLabel, setBtnLabel] = useState(label);

  useEffect(() => {
    if (value || !label) {
      setBtnLabel(menuItems.find((item) => item.value === value)?.label);
    } else {
      setBtnLabel(label);
    }
  }, [value, label, menuItems]);

  const handleMenuItemClick = (value: string | number | undefined) => {
    onSelect(value);
  };

  return (
    <div className={wrapperClass}>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button
          className={`btn-secondary-white whitespace-nowrap relative ${btnClassName}`}
          disabled={Boolean(disabled)}
        >
          {btnLabel} <ChevronDownIcon className="h-5 w-5" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 mt-2 py-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg max-h-[90vh] overflow-scroll w-max h-max z-50">
            {noneOption && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`px-3 py-1 cursor-pointer text-sm ${active && 'bg-slate-100'} ${
                      value === '' && 'bg-sourgum-green-400 bg-opacity-10'
                    }`}
                    onClick={() => handleMenuItemClick('')}
                  >
                    <span className="pr-5 flex items-center pl-5">None</span>
                  </div>
                )}
              </Menu.Item>
            )}

            {menuItems.map((item) => (
              <Menu.Item key={item.value}>
                {({ active }) => (
                  <div
                    className={`px-3 py-1 cursor-pointer text-sm ${active && 'bg-slate-100'} ${
                      item.value === value && 'bg-sourgum-green-400 bg-opacity-10'
                    }`}
                    onClick={() => handleMenuItemClick(item.value)}
                    key={item.label}
                  >
                    <span className={`pr-5 flex items-center ${item.value === value ? 'pl-0' : 'pl-5'}`}>
                      {item.value === value ? <CheckIcon className="mr-2 h-3.5 w-3.5 text-sourgum-green-400" /> : null}
                      {item.label}
                    </span>
                  </div>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

ButtonSelect.defaultProps = defaultProps;

export default ButtonSelect;
