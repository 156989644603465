import React from 'react';
import { Checkbox, Dialog } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { format } from 'date-fns';
import type { FEObject } from './PayablesMakePayment';

type PayableBreakdownDialogProps = {
  open: boolean;
  onClose: () => void;
  feObject: FEObject | null;
  onCheckboxClick: (id: string, checked: boolean) => void;
};

const PayableBreakdownDialog = ({ open, onClose, feObject, onCheckboxClick }: PayableBreakdownDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      showX
      styledTitle={feObject?.vendorName}
      variant="underlined-header"
      className="max-w-4xl"
    >
      <div className="grid grid-cols-4 gap-4">
        <div>Pay</div>
        <div>Date Created</div>
        <div>Inv Number</div>
        <div>Amount</div>
        {feObject?.payables
          .sort((a, b) =>
            (a.invoiceDetails.metadata?.createdAt || 0) > (b.invoiceDetails.metadata?.createdAt || 0) ? 1 : -1,
          )
          .map((payable) => (
            <React.Fragment key={payable.id}>
              <div>
                <Checkbox
                  inputProps={{
                    checked: Boolean(feObject.feOnlyPayablesBeingPaid.find((item) => item.id === payable.id)),
                    onChange: (e) => onCheckboxClick(payable.id, e.target.checked),
                  }}
                />
              </div>
              <div>
                {payable.invoiceDetails.metadata?.createdAt
                  ? format(new Date(payable.invoiceDetails.metadata.createdAt), 'MM/dd/yy')
                  : null}
              </div>
              <div>{payable.invoiceDetails.invoiceNumber}</div>
              <div>{moneyFormatter(payable.invoiceDetails.remainingBalance)}</div>
            </React.Fragment>
          ))}
      </div>
    </Dialog>
  );
};

export default PayableBreakdownDialog;
