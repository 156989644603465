import React from 'react';
import Grid from '@mui/material/Grid';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import CheckBoxField from '../../utils/components/checkbox-field';
import DatePicker from '../InputDatePicker';

export interface DispatchFormProps {
  values: any;
  setFieldValue: any;
  disabled: boolean;
}

const DispatchForm = ({ values, setFieldValue, disabled }: DispatchFormProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <DatePicker
          label="Delivery date"
          required
          value={values.expectedDeliveryDate}
          // minDate={new Date()}
          onChange={(value: Date) => setFieldValue('expectedDeliveryDate', value)}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          id="sharedDeliveryNotes"
          name="sharedDeliveryNotes"
          label="Delivery notes"
          component={TextField}
          margin="dense"
          disabled={disabled}
          fullWidth
          multiline
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          label="Pickup date"
          value={values.expectedPickupDate}
          // minDate={new Date()}
          onChange={(value: Date) => setFieldValue('expectedPickupDate', value)}
          disabled={disabled}
          componentsProps={{
            actionBar: {
              actions: ['clear', 'cancel', 'accept'],
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          id="sharedPickupNotes"
          name="sharedPickupNotes"
          label="Pickup notes"
          component={TextField}
          margin="dense"
          disabled={disabled}
          fullWidth
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="sharedDeliveryNotesPrivate"
          label="Hauler only notes"
          component={TextField}
          margin="dense"
          disabled={disabled}
          fullWidth
          multiline
          helperText="Notes only the hauler will see when we send emails"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          id="noteOrder"
          name="noteOrder"
          label="Internal notes"
          component={TextField}
          margin="dense"
          disabled={disabled}
          fullWidth
          multiline
          helperText="Notes only Sourgum team members will see"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="recurringOnCall"
          label="Recurring service (on call)"
          checked={values.recurringOnCall}
          component={CheckBoxField}
          margin="dense"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="poNumber"
          label="PO Number"
          component={TextField}
          margin="dense"
          disabled={disabled}
          helperText="If the customer has a Purchase Order (PO) Number"
        />
      </Grid>
    </Grid>
  );
};

export default DispatchForm;
