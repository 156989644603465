import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Field, FormikProps } from 'formik';
import { Select } from 'formik-mui';
import { mediumTypes } from '../../utils/shared-types';
import InputDatePicker from '../InputDatePicker';

const MediumTypeMenuItems = Object.entries(mediumTypes).map((item) => (
  <MenuItem key={`priceType-${item[0]}`} value={item[0]}>
    {item[1]}
  </MenuItem>
));

const ContactedForm = ({ formik }: { formik: FormikProps<any> }) => {
  const index = formik.values.contactEvents.length - 1;
  return (
    <div className="bg-white rounded shadow-dark p-4 mb-5">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Contacted Details</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            name={`contactEvents[${index}].medium`}
            component={Select}
            label="Medium"
            labelId={`contactEvents[${index}].medium-label`}
            formControl={{ fullWidth: true, size: 'small', margin: 'dense' }}
          >
            {MediumTypeMenuItems}
          </Field>
        </Grid>
        <Grid item xs={12} md={3}>
          <InputDatePicker
            label="Date"
            value={formik.values.contactEvents[index].date}
            onChange={(value: Date) => formik.setFieldValue(`contactEvents[${index}].date`, value)}
            disablePast={false}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactedForm;
