import { useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { Loading, Toggle } from '@wayste/sour-ui';
import Grid from '@mui/material/Grid';
import { createOrderConfirmationEmail } from '../../utils/email-utils';

export interface PaymentSummaryProps {
  user: any;
  onSubmit: any;
  isLoading: boolean;
  disabled: boolean;
  formik: any;
  existingCustomer: Customer.AllianceCustomerTransport;
  getOrderTotal: any;
  zonePricing: any;
}

const PaymentSummary = ({
  user,
  onSubmit,
  isLoading,
  disabled,
  formik,
  existingCustomer,
  getOrderTotal,
  zonePricing,
}: PaymentSummaryProps) => {
  const { values, setFieldValue, setValues, isValid } = formik;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onSubmit();
  };

  const getDispatchEmailsString = (customer: Customer.AllianceCustomerTransport) => {
    if (!customer.contacts) return null;

    const dispatchEmails = customer.contacts
      ?.filter((item) => item.sendDispatchEmails)
      .map((item) => item.email)
      .join(', ');

    return dispatchEmails;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <h6 className="text-xl font-medium">Email Order Summary</h6>
      </Grid>
      <Toggle
        value={values.sendEmail}
        label="Send email"
        onChange={(value) => {
          setValues({
            ...values,
            sendEmail: value,
            includeCCForm: false,
          });
        }}
        disabled={!getDispatchEmailsString(existingCustomer)}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: 22,
        }}
      >
        {values.sendEmail && getDispatchEmailsString(existingCustomer) ? (
          <>
            <div
              style={{
                fontSize: 16,
                color: 'rgba(0, 0, 0, 0.68)',
                marginBottom: 7,
              }}
            >
              Email preview
            </div>
          </>
        ) : (
          <div
            style={{
              fontSize: 16,
              color: 'rgba(244, 67, 54, 0.86)',
              marginBottom: 7,
            }}
          >
            YOU MUST READ THE BELOW TO THE CUSTOMER OVER THE PHONE OR SEND THEM AN EMAIL
          </div>
        )}
        <div
          style={{
            backgroundColor: '#FFFFFF',
            borderRadius: 4,
            border: 'solid 1px #979797',
            padding: '9px 14px',
            fontSize: 14,
          }}
        >
          <div style={{ marginBottom: 10 }}>
            Hi {values.primaryContact?.firstName},
            <br />
            <br />
            This is {user.firstName} with Sourgum Waste confirming your roll-dumpster rental. If you need anything you
            can give us a call at (732) 366-9355.
          </div>
          <textarea
            rows={4}
            style={{
              width: '100%',
              fontSize: 14,
              border: 'solid 1px #D8D8D8',
              borderRadius: 4,
            }}
            value={values.emailMessage}
            onChange={(event) => setFieldValue('emailMessage', event.target.value)}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: createOrderConfirmationEmail(values, getOrderTotal(), user, null, zonePricing?.prohibitedItems)
                .customizableHTMLMessage,
            }}
          />
        </div>
      </div>
      <Grid item xs={12}>
        <div className="flex justify-end mt-7 mb-2.5">
          <button
            className="btn-primary"
            disabled={isSubmitting || !isValid || disabled || isLoading}
            type="button"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              setIsSubmitting(true);
              handleSubmit(e);
            }}
          >
            Verify Order
            {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
          </button>
        </div>
      </Grid>
    </Grid>
  );
};

export default PaymentSummary;
