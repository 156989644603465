import React, { useEffect, useState } from 'react';
import { Hauler, Invoice, Order } from '@alliance-disposal/transport-types';
import { SourFilters, SourSearch, SourSearchWrapper } from '@wayste/sour-search';
import { TextField } from '@wayste/sour-ui';
import { useForm } from 'react-hook-form';
import Dialog from '../../../../components/Dialog';

export interface EditPayableFormProps {
  invoiceNumber: string;
  haulerID: string;
  haulerName: string;
  internalNotes?: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  payable?: Invoice.PayableTransport;
  order?: Order.AllianceOrderTransport;
  onSubmit: (values: EditPayableFormProps) => void;
}

const EditPayableModal = ({ open, onClose, payable, onSubmit, order }: Props) => {
  const [searchForHaulers, setSearchForHaulers] = useState(false);

  const onFormSubmit = (values: EditPayableFormProps) => {
    const data = { ...values };
    onSubmit(data);
  };

  const onSearchForHauler = async () => {
    setSearchForHaulers(true);
  };

  const {
    setValue,
    watch,
    formState: { isValid },
    register,
    handleSubmit,
    reset,
  } = useForm<EditPayableFormProps>({
    mode: 'all',
    defaultValues: {
      invoiceNumber: '',
      haulerID: '',
      haulerName: '',
    },
  });

  const watchHaulerName = watch('haulerName');

  useEffect(() => {
    if (order || payable) {
      const thisHaulerID = payable?.haulerID || order?.haulerID || '';
      reset({
        invoiceNumber: payable?.invoiceDetails.invoiceNumber || '',
        haulerID: thisHaulerID,
        haulerName: payable?.vendorName ?? '',
      });
    } else {
      reset({
        invoiceNumber: '',
        haulerID: '',
        haulerName: '',
      });
    }
  }, [payable, order, reset]);

  const handleHaulerClick = (hauler: Hauler.HaulerWithAapTransport, haulerID: string) => {
    const haulerName = payable?.vendorName || '';
    console.log('handleHaulerClick', haulerID, haulerName);
    setValue('haulerID', haulerID);
    setValue('haulerName', hauler.name);
    setSearchForHaulers(false);
  };

  // DEFINE ON NAVIGATE LOGIC
  const onNavigate = (hauler: Hauler.HaulerWithAapTransport, recordID: string) => {
    console.log('onNavigate', hauler, recordID);
    handleHaulerClick(hauler, recordID);
  };

  if (!open) return null;

  return (
    <Dialog
      className="w-[100%] overflow-visible"
      styledTitle={`${payable ? 'Edit' : 'Add'} Hauler Bill`}
      onClose={onClose}
      open={open}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onFormSubmit)}>
        <div>
          <div>
            <span style={{ color: 'rgba(0, 0, 0, 0.68)' }}>For Hauler:</span>
            <b>{watchHaulerName}</b>
          </div>
        </div>
        <div>
          {!searchForHaulers ? (
            <button className="btn-primary-text-only " color="secondary" onClick={onSearchForHauler}>
              Change Hauler
            </button>
          ) : (
            <div>
              <SourSearchWrapper
                onNavigate={onNavigate}
                highlight={true}
                defaultFilters={{
                  query: {
                    type: 'sourgum-vendor',
                  },
                }}
                options={{
                  application: 'aap',
                  apiKey: import.meta.env.VITE_ELASTIC_KEY,
                  environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                }}
              >
                <div className="flex flex-row justify-between space-x-4 w-full">
                  <SourSearch
                    options={{
                      searchPopoverFixed: false,
                      showTips: false,
                      showMessages: false,
                      resultSize: 'w-[700px]',
                      searchPositionFixed: true,
                      placeholder: 'Search vendor',
                    }}
                  />
                  <div className="hidden">
                    <SourFilters />
                  </div>
                </div>
              </SourSearchWrapper>
            </div>
          )}
        </div>
        <div>
          <TextField
            label="Invoice number"
            required={true}
            inputProps={{
              ...register('invoiceNumber', { required: 'Invoice number is required' }),
            }}
          />
        </div>
        <div className="w-full">
          <TextField label="Internal Notes" inputProps={{ ...register('internalNotes') }} />
        </div>

        <div className="flex justify-end gap-4 pt-4 border-t">
          <button className="btn-dark-grey-outlined" onClick={onClose} type="button">
            Cancel
          </button>
          <button className="btn-primary" type="submit" disabled={!isValid}>
            Save
          </button>
        </div>
      </form>
    </Dialog>
  );
};

export default EditPayableModal;
