import { useEffect, useState } from 'react';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button, Checkbox, CurrencyTextField, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Controller, UseFormReturn, get, useFieldArray } from 'react-hook-form';
import { UniversalServiceFormData } from './UniversalServiceCreate';
import { defaultEvent } from './site-service-defaults';

interface SubscriptionDetailsSubFormProps {
  methods: UseFormReturn<UniversalServiceFormData, unknown>;
  selectedServiceTypeFamily?: UniversalService.ServiceTypeFamily;
  index: number;
  update?: boolean;
}

// TODO implement Synced to billing frequency.
const SubscriptionServiceOrderPricingSubForm = ({
  methods,
  selectedServiceTypeFamily,
  index,
  update,
}: SubscriptionDetailsSubFormProps) => {
  const allowRecurringQuantity = Boolean(
    selectedServiceTypeFamily?.lineItemTypes?.find((lineItem) => lineItem.description === 'recurring')?.minQuantity ||
      0 > 0,
  );

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const {
    fields: recurringLineItems,
    append: appendRecurringLineItem,
    remove: removeRecurringLineItem,
  } = useFieldArray({
    control,
    name: `serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents`,
  });

  const {
    fields: oneTimeFees,
    append: appendOneTimeFee,
    remove: removeOneTimeFee,
  } = useFieldArray({
    control,
    name: `serviceGrouping.serviceOrders.${index}.serviceEvents`,
  });

  const addToInvoiceTypes = {
    synced: 'Synced to billing frequency',
    onStatus: 'On status',
  };

  const [addToInvoice, setAddToInvoice] = useState<keyof typeof addToInvoiceTypes>('synced');
  const [addToInvoiceStatus, setAddToInvoiceStatus] = useState<UniversalService.ServiceOrderStatus>();
  useEffect(() => {
    if (addToInvoice === 'onStatus' && addToInvoiceStatus) {
      setValue(
        `serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${0}.applyWhenStatusIs`,
        addToInvoiceStatus,
      );
    }
    // TODO implement Synced to billing frequency.
  }, [addToInvoice, addToInvoiceStatus]);
  return (
    <>
      {update && <div className="text-black opacity-50">These changes will only effect future invoices</div>}
      <div className="flex space-x-2">
        <div className="w-1/2 pr-1">
          <Select
            label={'Add to invoice when'}
            onSelect={(value: string) => {
              setAddToInvoice(value as keyof typeof addToInvoiceTypes);
            }}
            defaultValue={'synced'}
            required
            value={addToInvoice}
          >
            {Object.entries(addToInvoiceTypes).map((option: [string, string], index: number) => (
              <SelectOption key={'addToInvoiceTypes' + index} value={option[0]}>
                {option[1]}
              </SelectOption>
            ))}
          </Select>
        </div>
        {addToInvoice === 'onStatus' && (
          <div className="w-1/2 -pl-1">
            <Select
              label={'Status'}
              onSelect={(value) => setAddToInvoiceStatus(value as UniversalService.ServiceOrderStatus)}
              defaultValue={''}
              required
              value={addToInvoiceStatus}
            >
              {Object.entries(UniversalService.ServiceOrderStatuses).map((option: [string, string], index: number) => (
                <SelectOption key={'status' + index} value={option[0]}>
                  {option[1]}
                </SelectOption>
              ))}
            </Select>
          </div>
        )}
      </div>

      {watch(`serviceGrouping.serviceOrders.${index}.subscriptionDetails.billingFrequencyUnit`) !== 'month' && (
        <>
          <div className="pb-2">Proration behavior</div>
          <Controller
            control={control}
            name={`prorate`}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox
                label="Prorate charges from subscription start to first billing period"
                error={get(errors, 'prorate')}
                inputProps={{
                  value: 'type',
                  checked: field.value,
                  onChange: field.onChange,
                }}
              />
            )}
          />
        </>
      )}
      <div className="grid grid-cols-3 gap-2">
        <Controller
          control={control}
          name={`serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${0}.unitPrice`}
          defaultValue={0}
          render={({ field, fieldState }) => (
            <CurrencyTextField
              label="Recurring Unit Price"
              error={fieldState.error}
              value={(+field.value / 100).toString()}
              onChange={(value) => field.onChange(+value * 100)}
              inputProps={{
                ...field,
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={`serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${0}.priceQuantity`}
          defaultValue={1}
          rules={{
            min: 1,
          }}
          render={({ field, fieldState }) => (
            <TextField
              label="Recurring Quantity"
              type="number"
              error={fieldState.error}
              inputProps={{
                ...field,
                disabled: !allowRecurringQuantity,
              }}
              helperText={
                allowRecurringQuantity ? 'Quantity of the recurring line item to be added to each invoice' : ''
              }
            />
          )}
        />
        <CurrencyTextField
          useCents
          label="Recurring Total"
          value={
            watch(`serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${0}.unitPrice`) *
              watch(`serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${0}.priceQuantity`) || 0
          }
          onChange={(amt) => {
            // divide by the quantity and set the unit price
            setValue(
              `serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${0}.unitPrice`,
              +amt /
                watch(`serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${0}.priceQuantity`),
            );
          }}
          inputProps={{
            disabled: !allowRecurringQuantity,
          }}
        />
      </div>
      {recurringLineItems.slice(1).map((item, lineItemIndex) => (
        <div key={item.id}>
          <div className="flex space-x-2 align-middle">
            <div>
              <Controller
                name={`serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${
                  lineItemIndex + 1
                }.lineItemTypeID`}
                control={control}
                render={({ field }) => (
                  <Select
                    error={get(
                      errors,
                      `serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${
                        lineItemIndex + 1
                      }.lineItemTypeID`,
                    )}
                    label="Line Item Name"
                    onSelect={field.onChange}
                    defaultValue={''}
                    value={field.value}
                  >
                    {selectedServiceTypeFamily?.lineItemTypes.map((lineItem, index) => (
                      <SelectOption key={'lineItem' + index} value={lineItem.id}>
                        {lineItem.description}
                      </SelectOption>
                    ))}
                  </Select>
                )}
              />
            </div>
            <div className="w-1/5">
              <Controller
                control={control}
                name={`serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${
                  lineItemIndex + 1
                }.description`}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    type={'string'}
                    label="Description"
                    error={get(
                      errors,
                      `serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${
                        lineItemIndex + 1
                      }.description`,
                    )}
                    inputProps={{
                      ...field,
                    }}
                  />
                )}
              />
            </div>
            <div className="w-1/5">
              <Controller
                control={control}
                name={`serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${
                  lineItemIndex + 1
                }.priceQuantity`}
                defaultValue={1}
                render={({ field }) => (
                  <TextField
                    required
                    type={'number'}
                    label="Quantity"
                    error={get(
                      errors,
                      `serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${
                        lineItemIndex + 1
                      }.priceQuantity`,
                    )}
                    inputProps={{
                      ...field,
                    }}
                  />
                )}
              />
            </div>
            <div className="w-1/5">
              <Controller
                control={control}
                name={`serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${
                  lineItemIndex + 1
                }.unitPrice`}
                defaultValue={0}
                render={({ field, fieldState }) => (
                  <CurrencyTextField
                    required
                    label="Rate"
                    error={fieldState.error}
                    value={(+field.value / 100).toString()}
                    onChange={(value) => field.onChange(+value * 100)}
                    inputProps={{
                      ...field,
                    }}
                  />
                )}
              />
            </div>
            <div className="w-1/5 mt-2">
              <Controller
                name={`serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${
                  lineItemIndex + 1
                }.taxable`}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    error={get(
                      errors,
                      `serviceGrouping.serviceOrders.${index}.serviceEvents.${lineItemIndex + 1}.taxable`,
                    )}
                    label="Taxable"
                    inputProps={{
                      value: 'type',
                      checked: field.value,
                      onChange: () => field.onChange(!field.value),
                    }}
                  />
                )}
              />
            </div>
          </div>
          <Button
            className="btn-error-text-only whitespace-nowrap mt-2 ml-2"
            onClick={() => removeRecurringLineItem(lineItemIndex + 1)}
            startIcon={<XMarkIcon />}
          >
            Remove line item
          </Button>
        </div>
      ))}
      <div className="px-2 ">
        <Button
          className="btn-secondary-text-only whitespace-nowrap"
          onClick={() => appendRecurringLineItem(defaultEvent)}
          startIcon={<PlusIcon />}
        >
          Add a recurring line item charge
        </Button>
      </div>
      {update ? (
        <div className="text-black opacity-50">Go to the appropriate invoice to edit the delivery fee</div>
      ) : (
        <div className="flex space-x-2">
          <div className="w-1/2">
            <Controller
              control={control}
              name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${0}.unitPrice`}
              defaultValue={0}
              render={({ field }) => (
                <CurrencyTextField
                  label="Delivery fee"
                  error={get(errors, `serviceGrouping.serviceOrders.${index}.serviceEvents.${0}.unitPrice`)}
                  value={(+field.value / 100).toString()}
                  onChange={(value) => field.onChange(+value * 100)}
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />
          </div>
          {/* <div className="w-1/2 mt-2">
            <Controller
              control={control}
              name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${0}.applyToFirstInvoice`}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  label="Automatically add to first invoice"
                  error={get(errors, `serviceGrouping.serviceOrders.${index}.serviceEvents.${0}.applyToFirstInvoice`)}
                  inputProps={{
                    value: 'type',
                    checked: !field.value,
                    onChange: () => field.onChange(!field.value),
                  }}
                />
              )}
            />
          </div> */}
        </div>
      )}
      <div className="flex space-x-2">
        <div className="w-1/2">
          <Controller
            control={control}
            name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${1}.unitPrice`}
            defaultValue={0}
            render={({ field }) => (
              <CurrencyTextField
                label="Removal Fee"
                error={get(errors, `serviceGrouping.serviceOrders.${index}.serviceEvents.${1}.unitPrice`)}
                value={(+field.value / 100).toString()}
                onChange={(value) => field.onChange(+value * 100)}
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
        </div>
        {/* <div className="flex whitespace-nowrap w-[50%]">
          <div className="mt-2 mr-4">
            <Controller
              control={control}
              name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${1}.applyToLastInvoice`}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  label="Automatically add to Final Invoice"
                  error={get(errors, `serviceGrouping.serviceOrders.${index}.serviceEvents.${1}.applyToLastInvoice`)}
                  inputProps={{
                    value: 'type',
                    checked: !field.value,
                    onChange: () => field.onChange(!field.value),
                  }}
                />
              )}
            />
          </div>
          <div className="flex space-x-2">
            <div className="w-1/2 -pl-1">
              <Controller
                name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${1}.applyWhenStatusIs`}
                control={control}
                render={({ field }) => (
                  <Select
                    error={get(errors, `serviceGrouping.serviceOrders.${index}.serviceEvents.${1}.applyWhenStatusIs`)}
                    label={'Add to Invoice when Status is'}
                    onSelect={field.onChange}
                    defaultValue={''}
                    value={field.value}
                  >
                    {Object.entries(UniversalService.ServiceOrderStatuses).map(
                      (option: [string, string], index: number) => (
                        <SelectOption key={'removalStatus' + index} value={option[0]}>
                          {option[1]}
                        </SelectOption>
                      ),
                    )}
                  </Select>
                )}
              />
            </div>
          </div>
        </div> */}
      </div>
      {!update && (
        <>
          {selectedServiceTypeFamily && (
            <>
              <div className="space-y-6">
                {oneTimeFees.slice(2).map((item, lineItemIndex) => (
                  <div key={item.id}>
                    <div className="flex space-x-2 align-middle">
                      <div>
                        <Controller
                          name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${
                            lineItemIndex + 2
                          }.lineItemTypeID`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              error={get(
                                errors,
                                `serviceGrouping.serviceOrders.${index}.serviceEvents.${
                                  lineItemIndex + 2
                                }.lineItemTypeID`,
                              )}
                              label="Line Item Name"
                              onSelect={field.onChange}
                              defaultValue={''}
                              value={field.value}
                            >
                              {selectedServiceTypeFamily?.lineItemTypes.map((lineItem, index) => (
                                <SelectOption key={'lineItem' + index} value={lineItem.id}>
                                  {lineItem.description}
                                </SelectOption>
                              ))}
                            </Select>
                          )}
                        />
                      </div>
                      <div className="w-1/5">
                        <Controller
                          control={control}
                          name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${lineItemIndex + 2}.description`}
                          defaultValue={''}
                          render={({ field }) => (
                            <TextField
                              type={'string'}
                              label="Description"
                              error={get(errors, `serviceEvents.${index}.${lineItemIndex + 2}.description`)}
                              inputProps={{
                                ...field,
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="w-1/5">
                        <Controller
                          control={control}
                          name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${
                            lineItemIndex + 2
                          }.priceQuantity`}
                          defaultValue={undefined}
                          render={({ field }) => (
                            <TextField
                              required
                              type={'number'}
                              label="Quantity"
                              error={get(errors, `serviceEvents.${index}.${lineItemIndex + 2}.quantity`)}
                              inputProps={{
                                ...field,
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="w-1/5">
                        <Controller
                          control={control}
                          name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${lineItemIndex + 2}.unitPrice`}
                          defaultValue={undefined}
                          render={({ field }) => (
                            <CurrencyTextField
                              required
                              label="Rate"
                              error={get(errors, `serviceEvents.${index}.${lineItemIndex + 2}.unitPrice`)}
                              value={(+field.value / 100).toString()}
                              onChange={(value) => field.onChange(+value * 100)}
                              inputProps={{
                                ...field,
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="w-1/5 mt-2">
                        <Controller
                          name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${lineItemIndex + 2}.taxable`}
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <Checkbox
                              error={get(
                                errors,
                                `serviceGrouping.serviceOrders.${index}.serviceEvents.${lineItemIndex + 2}.taxable`,
                              )}
                              label="Taxable"
                              inputProps={{
                                value: 'type',
                                checked: field.value,
                                onChange: () => field.onChange(!field.value),
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <Button
                      className="btn-error-text-only whitespace-nowrap mt-2 ml-2"
                      onClick={() => removeOneTimeFee(lineItemIndex + 2)}
                      startIcon={<XMarkIcon />}
                    >
                      Remove line item
                    </Button>
                  </div>
                ))}
              </div>
              <div className="px-2 pb-4">
                <Button
                  className="btn-secondary-text-only whitespace-nowrap"
                  onClick={() => appendOneTimeFee(defaultEvent)}
                  startIcon={<PlusIcon />}
                >
                  Add additional line item
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default SubscriptionServiceOrderPricingSubForm;
