import React, { useEffect, useRef, useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { US_PHONE_NUMBER_REGEXP, formatE164ToUSPhoneNumber, formatUSPhoneNumberToE164 } from '@wayste/utils';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { leadTypesEnums } from '../../utils';
import ContactedForm from './ContactedForm';
import MarketingForm from './MarketingForm';
import LeadForm from './PTLeadForm';

const leadSchema = Yup.object().shape(
  {
    firstName: Yup.string().required('A first name is required'),
    lastName: Yup.string(),
    company: Yup.string(),
    email: Yup.string()
      .email()
      .when('phoneNumber', {
        is: (phoneNumber) => !phoneNumber || phoneNumber.length === 0,
        then: Yup.string().email().required('A phone number or email is required'),
        otherwise: Yup.string().email(),
      }),
    phoneNumber: Yup.string().when('email', {
      is: (email) => !email || email.length === 0,
      then: Yup.string()
        .matches(US_PHONE_NUMBER_REGEXP, 'Must be a valid phone number, e.g. (555) 867-5309')
        .required('A phone number or email is required'),
      otherwise: Yup.string().matches(US_PHONE_NUMBER_REGEXP, 'Must be a valid phone number, e.g. (555) 867-5309'),
    }),
    requestedStartDate: Yup.date().nullable(),
    customerNotes: Yup.string(),
    notes: Yup.string(),
    address: Yup.object().shape({
      addressLine1: Yup.string(),
      addressLine2: Yup.string(),
      city: Yup.string().required('A city is required'),
      state: Yup.string().required('A state is required'),
      zip: Yup.string().matches(/^\d{5}(-\d{4})?$/, 'Must be a valid zip code'),
    }),
  },
  [['email', 'phoneNumber']],
);

const contactedSchema = Yup.object().shape({
  contactEvents: Yup.array().of(
    Yup.object().shape({
      date: Yup.date().required('A contacted date is required'),
      medium: Yup.string().required('A contacted medium is required'),
    }),
  ),
});

const marketingSchema = Yup.object().shape({
  channel: Yup.string(),
  customerType: Yup.string(),
  searchParams: Yup.string(),
  formUrl: Yup.string(),
});

export interface LeadPTFormsProps {
  onCancel: () => void;
  onSubmit: (lead: Customer.AllianceLeadTransport) => void;
  lead?: Customer.AllianceLeadTransport;
  form?: 'all' | 'lead' | 'contacted' | 'marketing';
  leadPhone?: string;
}

const LeadPTForms = ({ onCancel, onSubmit, lead, form, leadPhone }: LeadPTFormsProps) => {
  const formRef = useRef<any>();
  const [schema, setSchema] = useState<any>([]);

  useEffect(() => {
    let schemaArray;
    switch (form) {
      case 'lead':
        schemaArray = leadSchema;
        break;
      case 'contacted':
        schemaArray = contactedSchema;
        break;
      case 'marketing':
        schemaArray = marketingSchema;
        break;
      default:
        //@ts-expect-error this needs to be fixed
        schemaArray = leadSchema.concat(contactedSchema).concat(marketingSchema);
        break;
    }
    setSchema(schemaArray);
  }, [form]);

  useEffect(() => {
    if (leadPhone && formRef.current) {
      formRef.current.setValues({
        ...formRef.current.values,
        phoneNumber: leadPhone,
      });
    }
  }, [leadPhone]);

  const handleSubmit = (data: Customer.AllianceLeadTransport) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    if (!lead) {
      dataCopy.type = leadTypesEnums.portableToilet;
      if (dataCopy.notes) {
        dataCopy.notesInternal = [
          {
            note: dataCopy.notes,
          },
        ];
      }
    }
    dataCopy.serviceLocation = {
      address: dataCopy.address,
      county: dataCopy.county,
      coordinates: dataCopy.coords || null,
    };
    if (dataCopy.phoneNumber) {
      dataCopy.phoneNumber = formatUSPhoneNumberToE164(dataCopy.phoneNumber);
    } else {
      dataCopy.phoneNumber = null;
    }

    onSubmit(dataCopy);
  };

  const prepareFormData = (data: Customer.AllianceLeadTransport) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.phoneNumber = dataCopy.phoneNumber ? formatE164ToUSPhoneNumber(dataCopy.phoneNumber) : '';
    dataCopy.address = dataCopy.serviceLocation.address;
    dataCopy.county = dataCopy.serviceLocation.county;
    dataCopy.coords = dataCopy.serviceLocation.coordinates;
    if (form === 'contacted') dataCopy.contactEvents.push({ date: null, medium: '' });

    return dataCopy;
  };

  return (
    <Formik
      initialValues={
        lead
          ? prepareFormData(lead)
          : {
              firstName: '',
              lastName: '',
              phoneNumber: leadPhone || '',
              email: '',
              notes: '',
              address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: '',
              },
              county: '',
              requestedStartDate: null,
              customerNotes: '',
              channel: '',
              customerType: '',
              contactEvents: [
                {
                  date: new Date(),
                  medium: '',
                },
              ],
              searchParams: '',
            }
      }
      validationSchema={schema}
      onSubmit={handleSubmit}
      innerRef={formRef as any}
    >
      {(formik) => {
        return (
          <Form>
            {form === 'all' || form === 'lead' ? <LeadForm formik={formik} lead={lead} /> : null}
            {form === 'all' || form === 'contacted' ? <ContactedForm formik={formik} /> : null}
            {form === 'all' || form === 'marketing' ? <MarketingForm formik={formik} /> : null}
            <div
              style={{
                display: 'flex',
                padding: '25px 0 55px',
                justifyContent: 'flex-end',
              }}
            >
              <button className="mr-5 btn-dark-grey-outlined" onClick={onCancel} type="button">
                Cancel
              </button>
              <button
                className="btn-primary"
                onClick={() => handleSubmit(formik.values)}
                disabled={!formik.isValid || !formik.dirty}
                type="button"
              >
                Save
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LeadPTForms;
