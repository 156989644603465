import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Order } from '@alliance-disposal/transport-types';
import { Loading } from '@wayste/sour-ui';
import { buildAddressString } from '@wayste/utils';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format, isPast, isToday } from 'date-fns';
import { startCase } from 'lodash';
import { UIContext } from '../../../contexts';
import { OrderStatus, OrderStatusText, SwitchType } from '../../../utils/shared-types';
import Dialog from '../../Dialog';

const CreateManualSwitchForm = ({ selectedOrder, handleClose }: CreateManualSwitchModalProps) => {
  const { showFlash } = useContext(UIContext) as any;
  const selectedOrderType =
    selectedOrder.status === OrderStatus.ON_HOLD ||
    selectedOrder.status === 'UNASSIGNED' ||
    selectedOrder.status === 'ASSIGNED' ||
    selectedOrder.status === 'DELIVERED'
      ? 'DELIVERY'
      : 'PICKUP';
  const [orders, setOrders] = useState<Order.AllianceOrderTransport[]>([]);
  const [loading, setLoading] = useState(false);
  const [pickupOrder, setPickupOrder] = useState<Order.AllianceOrderTransport>();
  const [deliveryOrder, setDeliveryOrder] = useState<Order.AllianceOrderTransport>();
  const [switchDate, setSwitchDate] = useState<Date>(new Date());
  const [switchType, setSwitchType] = useState<keyof typeof SwitchType | ''>('');
  const client = useWaysteClient();

  useEffect(() => {
    const queryOrders = async () => {
      setLoading(true);
      if (selectedOrderType === 'DELIVERY') {
        setDeliveryOrder(selectedOrder);
      } else {
        setPickupOrder(selectedOrder);
        if (selectedOrder.expectedPickupDate) setSwitchDate(new Date(selectedOrder.expectedPickupDate));
      }

      const orders = await client.order().adminPortal.query({
        allianceCustomerID: selectedOrder.allianceCustomerID,
        status: [
          'UNASSIGNED',
          'ON_HOLD',
          'ASSIGNED',
          'DELIVERED',
          'READY_FULL_PICKUP',
          'READY_EMPTY_PICKUP',
          'PICKED_UP_FULL',
          'PICKED_UP_EMPTY',
          'DUMPED',
          'COMPLETED',
        ].join(','),
        limit: 100,
        offset: 0,
        sortBy: 'orderNumber',
        sortDescending: true,
      });

      const oderData = orders.results as Order.AllianceOrderTransport[];

      setOrders(
        oderData.filter(
          (x) =>
            buildAddressString(x.serviceLocation.address) === buildAddressString(selectedOrder.serviceLocation.address),
        ),
      );

      setLoading(false);
    };
    queryOrders();
  }, []);

  const pickupOptions = useMemo(() => {
    return orders.filter(
      (x) =>
        selectedOrder.id !== x.id &&
        x.status !== 'UNASSIGNED' &&
        x.status !== 'ASSIGNED' &&
        x.status !== 'DELIVERED' &&
        (!x.switches ||
          x.switches.length === 0 ||
          x.switches.length >= 2 ||
          !!x.switches.find((y) => y.fromOrder === y.id)),
    );
  }, [orders]);

  const deliveryOptions = useMemo(() => {
    return orders.filter(
      (x) =>
        selectedOrder.id !== x.id &&
        (x.status === 'UNASSIGNED' || x.status === 'ASSIGNED' || x.status === 'DELIVERED') &&
        (!x.switches ||
          x.switches.length === 0 ||
          x.switches.length >= 2 ||
          !!x.switches.find((y) => y.fromOrder === y.id)),
    );
  }, [orders]);

  return (
    <Grid
      component={'form'}
      onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setLoading(true);
        if (!deliveryOrder?.haulerID && !pickupOrder?.haulerID) return;

        if (!deliveryOrder?.haulerID && pickupOrder?.haulerID) {
          try {
            if (!deliveryOrder?.id) {
              throw new Error('No delivery order ID');
            }
            const res = await client.order().adminPortal.update(deliveryOrder?.id, {
              status: OrderStatus.ASSIGNED,
              haulerID: pickupOrder.haulerID,
              expectedDeliveryDate: switchDate.toISOString(),
            });
            console.log('res deliv', res);
          } catch (error) {
            alert('An error has occurred while updating the delivery order. Try again or contact dev.');
            setLoading(false);
            return;
          }
        }
        try {
          if (!pickupOrder?.id) {
            throw new Error('No pickup order ID');
          }
          const res = await client.order().adminPortal.update(pickupOrder?.id, {
            expectedPickupDate: switchDate.toISOString(),
          });

          console.log('res pickup', res);
        } catch (error) {
          alert('An error has occurred while updating the pickup order. Try again or contact dev.');
          setLoading(false);
          return;
        }
        if (pickupOrder?.haulerID && deliveryOrder?.haulerID) {
          try {
            await client.order().adminPortal.addSwitch({
              fromOrder: pickupOrder?.id,
              toOrder: deliveryOrder?.id,
              type: SwitchType.DUMP_AND_RETURN,
            });
          } catch (error) {
            alert('An error has occurred trying to create manual switch please try again.');
            setLoading(false);
            return;
          }
          setLoading(false);
          showFlash('Manual link successfully created.', 'success');
          handleClose();
        }

        setLoading(false);
        showFlash('Manual link successfully created.', 'success');
        handleClose();
      }}
      container
      spacing={1.75}
    >
      <Grid item xs={12}>
        <Autocomplete
          loading={loading}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          disabled={selectedOrderType === 'DELIVERY'}
          options={deliveryOptions}
          inputValue={selectedOrderType === 'DELIVERY' ? 'Order# ' + String(selectedOrder?.orderNumber) : undefined}
          popupIcon={<ChevronDownIcon className="h-6 w-6" />}
          onChange={(event, newValue) => {
            setDeliveryOrder(newValue ? newValue : undefined);
          }}
          selectOnFocus
          clearOnBlur
          classes={{
            paper: 'mt-2 m-h-[25rem] shadow-xl shadow-[0px_0px_4px_rgba(0,0,0,0.27)]',
          }}
          getOptionLabel={(option) => {
            return option && `Order# ${option?.orderNumber}`;
          }}
          renderOption={(props, option) =>
            !option.haulerID || !option.haulerID || option.haulerID === selectedOrder.haulerID ? (
              <MenuItem key={option.id} {...props}>
                <>
                  {'Order# ' + option?.orderNumber}
                  <br />
                  {'Status: ' + OrderStatusText[option?.status as keyof typeof OrderStatusText]}
                  <br />
                  {option?.switches?.forEach((x) => {
                    return `to: ${x.toOrder === option?.id ? 'this' : 'other'} \n from: ${
                      x.fromOrder === option?.id ? 'this' : 'other'
                    }`;
                  })}
                </>
              </MenuItem>
            ) : (
              <MenuItem
                sx={{
                  cursor: 'not-allowed',
                  opacity: 0.4,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                key={option.id}
              >
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: { backgroundColor: 'black' },
                    },
                  }}
                  title={
                    <h1 style={{ fontSize: '0.96rem' }}>
                      {
                        'You can only link with same hauler / no hauler for now. You can remove the hauler from one of the orders or assign both orders to the same hauler and try again.'
                      }
                    </h1>
                  }
                >
                  <p style={{ width: '100vh' }}>
                    {'Order# ' + option?.orderNumber}
                    <br />
                    {'Status: ' + OrderStatusText[option?.status as keyof typeof OrderStatusText]}
                    <br />
                    {option.expectedPickupDate && (
                      <>{'Delivery Date: ' + format(new Date(option.expectedPickupDate), 'MM/dd/yy')}</>
                    )}
                  </p>
                </Tooltip>
              </MenuItem>
            )
          }
          renderInput={(params) => <TextField {...params} required label="Delivery Order" />}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          loading={loading}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          disabled={selectedOrderType === 'PICKUP'}
          options={pickupOptions}
          popupIcon={<ChevronDownIcon className="h-6 w-6" />}
          inputValue={selectedOrderType === 'PICKUP' ? 'Order# ' + String(selectedOrder?.orderNumber) : undefined}
          onChange={(event, newValue) => {
            setPickupOrder(newValue ? newValue : undefined);
            setSwitchDate(newValue?.expectedPickupDate ? new Date(newValue?.expectedPickupDate) : new Date());
          }}
          selectOnFocus
          clearOnBlur
          classes={{
            paper: 'm-h-[25rem] shadow-xl shadow-[0px_0px_4px_rgba(0,0,0,0.27)]',
          }}
          getOptionLabel={(option) => {
            return option && `Order# ${option?.orderNumber}`;
          }}
          renderOption={(props, option) => (
            <li key={option.id} {...props}>
              {'Order# ' + option?.orderNumber}
              <br />
              {'Status: ' + startCase(OrderStatusText[option?.status as keyof typeof OrderStatusText])}
              <br />

              {option.expectedPickupDate && (
                <>{'Pickup Date: ' + format(new Date(option.expectedPickupDate), 'MM/dd/yy')}</>
              )}
            </li>
          )}
          renderInput={(params) => <TextField {...params} required label="Pickup Order" />}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          required
          fullWidth
          // sx={TextFieldSx}
          size="small"
          className="min-w-[5.5rem]   "
        >
          <InputLabel>Switch Type</InputLabel>
          <Select
            fullWidth
            value={switchType}
            label="Switch Type"
            onChange={(e) => setSwitchType((e.target.value as keyof typeof SwitchType) || '')}
          >
            {[SwitchType.DUMP_AND_RETURN].map((x) => {
              return (
                <MenuItem key={x} value={x}>
                  {x === 'DUMP_AND_RETURN' ? 'Dump and Return' : x === 'SWAP' ? 'Swap' : x}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          value={switchDate}
          onChange={(e) => {
            if (!e) return alert('Error while setting date.');
            setSwitchDate(e);
          }}
          minDate={new Date()}
          renderInput={(props) => (
            <TextField
              {...props}
              label="Switch Date"
              contentEditable={false}
              required
              fullWidth
              helperText={isPast(switchDate) && !isToday(switchDate) ? 'Date cannot be in the past.' : undefined}
              error={isPast(switchDate) && !isToday(switchDate)}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <button className="btn-primary w-full" type="submit" disabled={loading}>
          Create Switch
          {loading && <Loading className="text-white" size="h-4 w-4 ml-2" />}
        </button>
      </Grid>
    </Grid>
  );
};
export type CreateManualSwitchModalProps = {
  open: boolean;
  selectedOrder: Order.AllianceOrderTransport;
  handleClose: () => void;
};
const CreateManualSwitchModal = (props: CreateManualSwitchModalProps) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose} styledTitle="Create Manual Switch">
      <CreateManualSwitchForm {...props} />
    </Dialog>
  );
};
export default CreateManualSwitchModal;
