import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Order } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import { ArrowTopRightOnSquareIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import {
  Collapse,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import routes from '../../utils/routes';
import { timeAgo } from './ChangeRequestResponse';

interface OrderRequestDialogProps {
  open: boolean;
  onClose?: () => void;
}

const ChangeTableRow = ({ request }: { request: Order.ChangeRequestTransport }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const minutesAgo = Math.floor((new Date().getTime() - new Date(request.metadata.createdAt).getTime()) / 60000);

  const fieldNameMap: Record<Order.ChangeTransport['field'], string> = {
    expectedDeliveryDate: 'Delivery Date',
    expectedPickupDate: 'Pickup Date',
    sharedPickupNotes: 'Pickup Notes',
    sharedDeliveryNotes: 'Delivery Notes',
    expectedSize: 'Size',
    material: 'Material',
    status: 'Status',
  };

  function renderChangeValues(change: Order.ChangeTransport) {
    if (change.field === 'expectedDeliveryDate' || change.field === 'expectedPickupDate') {
      return (
        <>
          <TableCell>
            {change.oldValue ? format(new Date(change.oldValue?.replace(/-/g, '/')), 'MM/dd/yyyy') : 'None'}
          </TableCell>
          <TableCell>{format(new Date(change.newValue?.replace(/-/g, '/')), 'MM/dd/yyyy')}</TableCell>
        </>
      );
    }

    return (
      <>
        <TableCell>{change.oldValue}</TableCell>
        <TableCell>{change.newValue}</TableCell>
      </>
    );
  }

  return (
    <>
      <TableRow key={request.id}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <ChevronUpIcon className="h-6 w-6" /> : <ChevronDownIcon className="h-6 w-6" />}
          </IconButton>
        </TableCell>
        <TableCell>{new Intl.RelativeTimeFormat('en').format(-minutesAgo, 'minute')}</TableCell>
        <TableCell>Order Change</TableCell>
        <TableCell>{request.allianceOrder?.orderNumber || 'N/A'}</TableCell>
        <TableCell>
          {request.allianceOrder?.serviceLocation.address.addressLine1}
          {request.allianceOrder?.serviceLocation.address.city}, {request.allianceOrder?.serviceLocation.address.state},{' '}
          {request.allianceOrder?.serviceLocation.address.zip} ({request.allianceOrder?.serviceLocation.county})
        </TableCell>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => {
              request.allianceOrder
                ? history.push(routes.orders.details(request.allianceOrder.id))
                : console.log('No order');
            }}
            color="default"
          >
            <ArrowTopRightOnSquareIcon className="h-6 w-6" />
          </IconButton>
        </TableCell>
        {/* Collapsible section that shows all changes */}
      </TableRow>
      <TableRow key={request.id + '_COLLAPSE'}>
        <TableCell
          colSpan={7}
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
            border: !open ? 'none' : undefined,
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit sx={{ px: 0.5, py: 0.5 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Change</TableCell>
                  <TableCell>Current</TableCell>
                  <TableCell>Requested</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {request.changes.map((change) => (
                  <TableRow key={change.id}>
                    <TableCell>{fieldNameMap[change.field]}</TableCell>
                    {renderChangeValues(change)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ChangeRequestDialog = ({ open, onClose }: OrderRequestDialogProps) => {
  const [requests, setRequests] = useState<Order.ChangeRequestTransport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const client = useWaysteClient();
  const history = useHistory();

  useEffect(() => {
    const sub = client
      .adminPortal()
      .order.changes.subscribe({ status: ['PENDING'] })
      .subscribe((changes) => {
        setRequests(changes.results as unknown as Order.ChangeRequestTransport[]);
      });
    setLoading(false);
    return () => {
      sub.unsubscribe();
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose?.();
      }}
      showX
      styledTitle="Change Requests"
      className="max-w-screen-xl"
    >
      <TableContainer sx={{ maxHeight: '50vh' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Requested</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Order Number</TableCell>
              <TableCell>Service Address</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading
              ? null
              : [...Array(5)].map((e, i) => {
                  return (
                    <TableRow>
                      <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  );
                })}

            {requests.length === 0 && !loading ? (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                  Nothing to see here! 👏🏼
                </TableCell>
              </TableRow>
            ) : (
              requests.map((request) =>
                request.changes.some((c) => c.field === 'status') ? (
                  /* Pickup Request */
                  <TableRow key={request.id}>
                    <TableCell />
                    <TableCell>{timeAgo(new Date(request.metadata.createdAt))}</TableCell>
                    <TableCell>Pick Up Request</TableCell>
                    <TableCell>{request.allianceOrder?.orderNumber || ''}</TableCell>
                    <TableCell>
                      {request.allianceOrder?.serviceLocation.address.addressLine1}
                      {request.allianceOrder?.serviceLocation.address.city},{' '}
                      {request.allianceOrder?.serviceLocation.address.state},{' '}
                      {request.allianceOrder?.serviceLocation.address.zip} (
                      {request.allianceOrder?.serviceLocation.county})
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => {
                          request.allianceOrder
                            ? history.push(routes.orders.details(request.allianceOrder.id))
                            : console.log('No order');
                        }}
                        color="default"
                      >
                        <ArrowTopRightOnSquareIcon className="h-6 w-6" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ) : (
                  /* Change Request */
                  <ChangeTableRow request={request} key={request.id + '_PARENT'} />
                ),
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-end mt-2">
        <button className="btn-primary" onClick={onClose}>
          Done
        </button>
      </div>
    </Dialog>
  );
};

export default ChangeRequestDialog;
