import { Button } from '@wayste/sour-ui';
import { DocumentDuplicateIcon, PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import ServiceAddressSubForm from './ServiceLocationSubForm';
import SubscriptionServiceOrder from './SubscriptionServiceOrder';
import { UniversalServiceFormData } from './UniversalServiceCreate';
import { defaultServiceOrderWithSubscriptionDetails } from './site-service-defaults';

interface SubscriptionServiceOrderAddSubFormProps {
  methods: UseFormReturn<UniversalServiceFormData, unknown>;
}

const SubscriptionServiceOrderAddSubForm = ({ methods }: SubscriptionServiceOrderAddSubFormProps) => {
  const { control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'serviceGrouping.serviceOrders',
  });
  return (
    <>
      <div className="pb-2 mb-6 text-l border-b border-gray-300">Service Address</div>
      <ServiceAddressSubForm fieldName={`serviceGrouping.serviceOrders.${0}.serviceLocation`} showCounty />
      {fields.map((field, index) => (
        <div key={field.id}>
          <SubscriptionServiceOrder index={index} methods={methods} />
        </div>
      ))}
      <div className="flex flex-row justify-center items-center">
        <hr className="w-full" />
        <div className="px-2 flex flex-row justify-center items-center space-x-2">
          <Button
            className="btn-primary-text-only whitespace-nowrap"
            startIcon={<PlusCircleIcon />}
            onClick={() => append(defaultServiceOrderWithSubscriptionDetails)}
          >
            Add Service Order
          </Button>
          |
          <Button className="btn-secondary-text-only whitespace-nowrap" startIcon={<DocumentDuplicateIcon />} disabled>
            Copy Service Order Above
          </Button>
          |
          {fields.length > 1 && (
            <Button
              className="btn-error-text-only whitespace-nowrap"
              startIcon={<XMarkIcon />}
              onClick={() => remove(fields.length - 1)}
            >
              Remove Service Order
            </Button>
          )}
        </div>
        <hr className="w-full" />
      </div>
    </>
  );
};

export default SubscriptionServiceOrderAddSubForm;
