import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, UniversalService } from '@alliance-disposal/transport-types';
import { Loading } from '@wayste/sour-ui';
import { formatServiceAddress } from '@wayste/utils';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { isAxiosError } from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { UIContext, useConfirmationDialog } from '../../contexts';
import { routes } from '../../utils';
import { repList } from '../../utils/shared-types';
import ContactsListCard from '../ContactsListCard/ContactsListCard';
import CustomerDetailsCard from '../CustomerDetailsCard/CustomerDetailsCard';
import { InternalTicket } from '../InternalTicket';
import PayablesTableCard from '../PayablesTableCard/PayablesTableCard';
import ReceivableTableCard from '../ReceivableTableCard/ReceivableTableCard';
import UniversalServicesCancelDialog from '../UniversalServicesCancelDialog/UniversalServicesCancelDialog';
import DetailsCardWrapper from '../ui/DetailsCardWrapper';
import ServiceOrdersListTable from './ServiceOrdersListTable';
import SubscriptionDetailsCard from './SubscriptionDetailsCard';

const ServiceGroupingDetails = () => {
  const { showFlash, godModeActive } = useContext(UIContext);
  const { getConfirmation } = useConfirmationDialog();

  const history = useHistory();
  const client = useWaysteClient();

  const [cancelOpen, setCancelOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serviceGrouping, setServiceGrouping] = useState<UniversalService.ServiceGrouping | undefined>();
  const [customer, setCustomer] = useState<Customer.AllianceCustomerTransport | undefined>(undefined);
  const [lastUpdatedBy, setLastUpdatedBy] = useState<string>('');
  const [createdBy, setCreatedBy] = useState<string>('');
  const [salesRep, setSalesRep] = useState<string>('');

  const { serviceGroupingId } = useParams<{ serviceGroupingId: string }>();

  useEffect(() => {
    fetchServiceGrouping(serviceGroupingId);
  }, [serviceGroupingId]);

  const handleAppAdminDelete = async () => {
    if (!godModeActive) return;

    // first get confirmation from user
    const confirmed = await getConfirmation({
      message:
        'Are you sure you want to delete this service grouping? This action cannot be undone. In most cases, you should cancel the service grouping instead. This is only intended for use in testing or by admins.',
      title: 'Confirm Delete',
      cancelText: 'Cancel',
      confirmText: 'Delete',
    });

    if (!confirmed) return;

    // delete service grouping
    try {
      await client.universalService().serviceGrouping.delete(serviceGroupingId);
      showFlash('Service grouping deleted', 'success');
      history.push(routes.universalServices.list);
    } catch (error) {
      if (isAxiosError(error) && error.response?.data.message) {
        showFlash(error.response.data.message, 'warning');
        return;
      }
      showFlash('Error deleting service grouping', 'warning');
    }
  };

  const fetchServiceGrouping = async (serviceGroupingId: string) => {
    setIsLoading(true);
    try {
      const serviceGroupingResponse = await client.universalService().serviceGrouping.fetch(serviceGroupingId);

      // const vendorIDs = Array.from(new Set(serviceGroupingResponse.serviceOrders.map((order) => order.vendorID)));

      // // const vendors = await Promise.all(
      // //   vendorIDs.map(async (vendorID) => {
      // //     if (!vendorID) return undefined;
      // //     const vendor = await client.vendorService().fetchById(vendorID);
      // //     return vendor.data;
      // //   }),
      // // );

      fetchCustomer(serviceGroupingResponse.customerID);
      setServiceGrouping(serviceGroupingResponse);
      const lastUpdatedByUser = repList.find(
        (user) => user.id === serviceGroupingResponse?.metadata.lastUpdatedByUserID,
      );
      const createdByUser = repList.find((user) => user.id === serviceGroupingResponse?.metadata.createdByUserID);
      const salesRepUser = repList.find((user) => user.id === serviceGroupingResponse?.contractDetails?.salesRepID);
      if (lastUpdatedByUser !== undefined) {
        setLastUpdatedBy(lastUpdatedByUser?.firstName + ' ' + lastUpdatedByUser?.lastName);
      }
      if (createdByUser !== undefined) {
        setCreatedBy(createdByUser?.firstName + ' ' + createdByUser?.lastName);
      }
      if (salesRepUser !== undefined) {
        setSalesRep(salesRepUser?.firstName + ' ' + salesRepUser?.lastName);
      }
    } catch (err) {
      console.log(err);
      showFlash('Error fetching service order', 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCustomer = async (customerId: string) => {
    try {
      const customerResponse = await client.customer().adminPortal.fetch(customerId);
      setCustomer(customerResponse);
    } catch (error) {
      console.log(error);
      showFlash('Error fetching customer', 'warning');
    }
  };

  // CANCEL ENTIRE SERVICE GROUPING
  const onCancel = async () => {
    const cancelServiceGrouping: UniversalService.ServiceGroupingUpdate = {
      ...serviceGrouping,
      status: 'closed',
    };
    try {
      await client.universalService().serviceGrouping.update(serviceGroupingId, cancelServiceGrouping);
      showFlash('Canceling entire service grouping', 'success');
    } catch (error) {
      showFlash('Error canceling service grouping', 'warning');
    }
  };

  const handleAddService = () => {
    if (serviceGrouping && serviceGrouping.type === 'single') {
      showFlash('Add service to single service grouping not implemented', 'warning');
      return;
    }
    history.push(routes.universalServices.serviceGrouping.addOrder(serviceGroupingId));
  };

  if (isLoading) return <Loading />;
  if (!serviceGrouping) return <div>Failed to fetch Service Grouping</div>;

  return (
    <div className="bg-sourgum-greyblue-50 h-full overflow-auto">
      <div className="container mx-auto p-6 pt-5">
        <div className="flex items-center">
          <div className="text-2xl mb-4">Service Grouping Details</div>
        </div>
        <UniversalServicesCancelDialog
          variant="delete"
          type={serviceGrouping?.type === 'single' ? 'oneTime' : 'subscription'}
          cancelOpen={cancelOpen}
          setCancelOpen={setCancelOpen}
          onCancel={onCancel}
        />
        <div className="space-y-2">
          <DetailsCardWrapper
            heading={
              (serviceGrouping?.type === 'single' ? 'Service Grouping ' : 'Subscription ') +
              serviceGrouping?.orderNumber
            }
            buttons={[
              {
                label: (
                  <>
                    <TrashIcon className="w-5 h-5 mr-1" /> Delete
                  </>
                ),
                delete: true,
                onClick: () => handleAppAdminDelete(),
                hide: !godModeActive,
              },
            ]}
          />
          {serviceGrouping?.type !== 'single' && serviceGrouping?.serviceOrders[0].subscriptionDetails && (
            <SubscriptionDetailsCard
              subscriptionDetails={serviceGrouping?.serviceOrders[0].subscriptionDetails}
              contractDetails={serviceGrouping?.contractDetails}
              serviceGrouping={serviceGrouping}
              anyServiceOrderNeedsAttention={
                serviceGrouping?.serviceOrders.some((order) => order.needsAttention) || false
              }
              serviceAddress={
                serviceGrouping?.serviceOrders[0].serviceLocation?.address &&
                serviceGrouping?.serviceOrders.every(
                  (order) =>
                    formatServiceAddress(order.serviceLocation?.address) ===
                    formatServiceAddress(serviceGrouping?.serviceOrders[0].serviceLocation?.address),
                )
                  ? formatServiceAddress(serviceGrouping?.serviceOrders[0].serviceLocation?.address)
                  : 'Multiple Addresses'
              }
              startDate={
                serviceGrouping?.serviceOrders.every(
                  (order) => order.startDate === serviceGrouping?.serviceOrders[0].startDate,
                )
                  ? serviceGrouping?.serviceOrders[0].startDate
                  : 'Multiple Start Dates'
              }
              endDate={
                serviceGrouping?.serviceOrders.every(
                  (order) => order.endDate === serviceGrouping?.serviceOrders[0].endDate,
                )
                  ? serviceGrouping?.serviceOrders[0].endDate
                  : 'Multiple Start Dates'
              }
              vendors={
                Array.from(new Set(serviceGrouping?.serviceOrders.map((order) => order.vendorName))).join(', ') ?? []
              }
              lastUpdatedBy={lastUpdatedBy}
              createdBy={createdBy}
              salesRep={salesRep}
            />
          )}
          <DetailsCardWrapper
            heading="Current Service Orders"
            buttons={
              serviceGrouping?.status !== 'closed'
                ? [
                    {
                      label: (
                        <>
                          <PlusIcon className="w-5 h-5 mr-1" /> Add Service
                          {serviceGrouping?.type !== 'single' && ' to Subscription'}
                        </>
                      ),
                      onClick: handleAddService,
                    },
                  ]
                : []
            }
          >
            <ServiceOrdersListTable
              serviceGrouping={serviceGrouping}
              serviceOrders={serviceGrouping?.serviceOrders.filter((order) => order.status !== 'COMPLETED') || []}
              onRowClicked={(row) => {
                if (!serviceGrouping?.id) return;
                history.push(routes.universalServices.serviceGrouping.serviceOrder.details(serviceGrouping.id, row.id));
              }}
              deletable
            />
          </DetailsCardWrapper>

          <InternalTicket entityID={serviceGrouping?.id} entityType="sourgum-service-grouping" />

          {customer && <CustomerDetailsCard customer={customer} />}
          {customer && <ContactsListCard customer={customer} />}
          <ReceivableTableCard
            suppressQuery={!Boolean(serviceGrouping?.invoiceGroupings.length)}
            serviceGroupings={serviceGrouping ? [serviceGrouping] : []}
            receivableQuery={{
              productInvoiceGroupingID: serviceGrouping?.invoiceGroupings
                .map((invoiceGrouping) => invoiceGrouping.id)
                .join(','),
            }}
          />
          <PayablesTableCard
            suppressQuery={!Boolean(serviceGrouping?.serviceOrders.length)}
            serviceOrderIds={serviceGrouping?.serviceOrders.map((serviceOrder) => serviceOrder.id) ?? []}
            serviceGrouping={serviceGrouping}
          />
          <DetailsCardWrapper heading="Past Service Orders">
            <ServiceOrdersListTable
              deletable={false}
              serviceOrders={serviceGrouping?.serviceOrders.filter((order) => order.status === 'COMPLETED') || []}
              onRowClicked={(row) => {
                if (!serviceGrouping?.id) return;
                history.push(routes.universalServices.serviceGrouping.serviceOrder.details(serviceGrouping.id, row.id));
              }}
            />
          </DetailsCardWrapper>
        </div>
      </div>
    </div>
  );
};

export default ServiceGroupingDetails;
