import { useEffect } from 'react';
import { Pricing } from '@alliance-disposal/transport-types';
import Dialog from '../Dialog';
import MapEditor, { MapEditorSubmit } from './MapEditor';

export interface MapEditorModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (serviceAreas: MapEditorSubmit[]) => void;
  serviceAreas?: Pricing.ServiceAreaTransport[];
  zones?: Pricing.PricingTransport[] | Pricing.HaulerPricingZone[] | Pricing.ProductPricingZoneTransport[];
  auxillaryZones?: Pricing.PricingTransport[];
}

const MapEditorModal = ({ open, onClose, onSubmit, serviceAreas, zones, auxillaryZones }: MapEditorModalProps) => {
  const handleClose = (xClick?: boolean) => {
    if (!xClick) return;
    onClose();
  };

  return (
    <Dialog showX onClose={handleClose} open={open} styledTitle="Edit Service Area" className="!max-w-screen-2xl">
      {zones ? (
        <MapEditor
          zones={zones}
          serviceAreas={serviceAreas}
          auxillaryZones={auxillaryZones}
          onSubmit={(serviceAreas) => {
            onSubmit(serviceAreas);
            onClose();
          }}
        />
      ) : (
        <MapEditor
          serviceAreas={serviceAreas}
          onSubmit={(serviceAreas) => {
            onSubmit(serviceAreas);
            onClose();
          }}
        />
      )}
    </Dialog>
  );
};

export default MapEditorModal;
