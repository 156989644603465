import React from 'react';
import { Textarea } from '@wayste/sour-ui';
import Dialog from '../../Dialog';

type PostalCode = string & { length: 5 };

const PostalCodesModal = ({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (postalCodes: PostalCode[]) => void;
}) => {
  const [postalCodes, setPostalCodes] = React.useState('');
  const isValid: boolean =
    postalCodes
      .split(',')
      .map((code) => code.trim())
      .reduce((acc, curr) => {
        return acc && curr.length === 5 && Boolean(curr.match(/^[0-9]+$/));
      }, true) && postalCodes.split(',').length > 0;

  const splitForSubmit: PostalCode[] = postalCodes
    .split(',')
    .map((code) => code.trim())
    .filter((code) => code.length === 5) as PostalCode[];

  return (
    <Dialog open={open} onClose={onClose} styledTitle="Add Postal Codes" className="!max-w-2xl">
      <div className="flex flex-col">
        <Textarea
          label="Postal Codes"
          inputProps={{
            value: postalCodes,
            onChange: (event) => setPostalCodes(event.target.value),
          }}
        />
        <p className="text-xs text-gray-500">Enter postal codes separated by commas (e.g. 12345, 12345, 12345)</p>
        <div className="flex justify-end gap-2 mt-2">
          <button className="btn-dark-grey-outlined" onClick={onClose}>
            Cancel
          </button>
          <button
            className="btn-primary"
            disabled={!isValid}
            onClick={() => {
              console.log(splitForSubmit);
              onSubmit(splitForSubmit);
              setPostalCodes('');
              onClose();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </Dialog>
  );
};
export default PostalCodesModal;
