import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { FieldProps, useField } from 'formik';
import { TextField } from 'formik-mui';

interface FormikTextFieldProps extends FieldProps {
  label: string;
  margin: 'none' | 'dense' | 'normal' | undefined;
  required: boolean;
  disabled: boolean;
}
const PhoneNumberField = (props: FormikTextFieldProps) => {
  const [val, setVal] = useState(props?.field?.value);
  // grab the element
  const [fieldInput, fieldMeta, fieldHelpers] = useField(props.field.name);
  const saveToFormik = (val: string) => {
    fieldHelpers?.setValue?.(val);
    //Set locally too.

    setVal(val);
  };
  const maskAndSave = (newVal?: string) => {
    const onlyNumbers = newVal?.match(/\d+/g);

    if (!onlyNumbers) {
      saveToFormik('');

      return;
    }

    const nums = onlyNumbers.join('');
    if (nums.length >= 10) {
      const firstThree = nums.slice(0, 3);
      const middleThree = nums.slice(3, 6);
      const lastFour = nums.slice(6, 10);
      const formattedNumber = `(${firstThree}) ${middleThree}-${lastFour}`;
      if (nums.length == 10) {
        fieldHelpers.setError(undefined);
      }
      saveToFormik(formattedNumber);
    } else if (nums.length >= 7) {
      const firstThree = nums.slice(0, 3);
      const middleThree = nums.slice(3, 6);
      const formattedNumber = `(${firstThree}) ${middleThree}-${nums.slice(6)}`;
      saveToFormik(formattedNumber);
    } else if (nums.length >= 4) {
      const firstThree = nums.slice(0, 3);
      const formattedNumber = `(${firstThree}) ${nums.slice(3)}`;
      saveToFormik(formattedNumber);
    } else if (nums.length >= 3) {
      const formattedNumber = `(${nums}`;
      saveToFormik(formattedNumber);
    } else {
      saveToFormik(`(${nums}`);
    }
  };
  useEffect(() => {
    if (fieldMeta?.value) {
      setVal(fieldMeta.value);
    }
  }, [fieldInput?.value]);

  const Memo = useMemo(() => {
    return (
      <TextField
        {...props}
        InputProps={{
          onChange: (e) => maskAndSave(e.target.value),
          value: val,
          startAdornment: <InputAdornment position="start">+1</InputAdornment>,
        }}
      />
    );
  }, [fieldMeta.error, fieldMeta.value, fieldMeta.touched, props.disabled, val]);
  return <>{Memo}</>;
};

export default PhoneNumberField;
