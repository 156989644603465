import { Material, Order } from '@alliance-disposal/transport-types';
import { WaysteIcon } from '@wayste/sour-components';
import { Button, Tooltip } from '@wayste/sour-ui';
import { getPrimaryContact, moneyFormatter } from '@wayste/utils';
import { CheckIcon, DocumentDuplicateIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { twMerge } from 'tailwind-merge';
import { routes } from '../../utils';
import StarRating from '../StarRating';
import { VendorPricingRow } from './SpreadsTable';

interface SpreadTableRowProps {
  haulerPricing: VendorPricingRow;
  sourgumPricing: {
    haul: number;
    dump: number;
    tonLimit: number;
    overage: number;
  };
  selectedMaterial: Material;
  selectedSize?: string | number;
  onRowClick?: (haulerPricing: VendorPricingRow, pricingSnapshot?: Order.VendorPricingSnapshotTransportCreate) => void;
  selectedHauler?: string;
}

export const SpreadTableRow = ({
  haulerPricing,
  sourgumPricing,
  selectedMaterial,
  selectedSize,
  onRowClick,
  selectedHauler,
}: SpreadTableRowProps) => {
  const getHaulerHaulRateDisplay = () => {
    if (!haulerPricing?.rowRates) return '';
    if (haulerPricing.rowRates.materialData.sizes.some((size) => size.tonLimit)) {
      return (
        <span className="flex flex-col">
          {moneyFormatter(haulerPricing.rowRates.sizeData.haul)}
          <span className="text-black opacity-50">{` TL: ${haulerPricing.rowRates.sizeData.tonLimit} `}</span>
          {haulerPricing?.rowRates?.sizeData?.tonLimit &&
          haulerPricing?.rowRates?.sizeData?.tonLimit > sourgumPricing?.tonLimit ? (
            <Tooltip text="Hauler has a higher ton limit than Sourgum">
              <ExclamationTriangleIcon className="h-5 w-5 text-warning" />
            </Tooltip>
          ) : null}
        </span>
      );
    }
    return moneyFormatter(haulerPricing.rowRates.haul);
  };

  const getHaulSpreadDisplay = () => {
    if (!haulerPricing?.rowRates || !haulerPricing.rowRates.haul) return '';
    const spread = sourgumPricing?.haul - haulerPricing.rowRates.haul;
    return <span className={spread > 0 ? 'text-success' : 'text-red-500'}>{moneyFormatter(spread)}</span>;
  };

  const getDumpSpreadDisplay = () => {
    if (!haulerPricing?.rowRates) return '';
    const spread = sourgumPricing?.dump - +haulerPricing.rowRates.dump;
    return <span className={spread > 0 ? 'text-success' : 'text-red-500'}>{moneyFormatter(spread)}</span>;
  };

  const getOverageSpreadDisplay = () => {
    if (!haulerPricing?.rowRates) return '';
    const spread = sourgumPricing?.overage - haulerPricing.rowRates.dump;
    return <span className={spread > 0 ? 'text-success' : 'text-red-500'}>{moneyFormatter(spread)}</span>;
  };

  const getCalculatedProfitDisplay = () => {
    if (!haulerPricing?.rowRates) return '';
    const estProfit = haulerPricing.rowRates.estProfit;
    return <span className={estProfit > 0 ? 'text-success' : 'text-red-500'}>{moneyFormatter(estProfit)}</span>;
  };

  const getTableRowStyles = () => {
    let className = '';
    if (
      !haulerPricing?.hauler?.active ||
      haulerPricing?.rowRates?.materialData?.doesNotService ||
      (selectedSize && selectedMaterial && !haulerPricing?.rowRates?.sizeData)
    ) {
      className = 'bg-[#f8d7da]';
    }
    return className;
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const toolTipContent = (
    <div onClick={(e) => e.stopPropagation()}>
      <div className="whitespace-pre-line">Notes: {`${haulerPricing.notes} ${haulerPricing.hauler.notes}`}</div>
      <div>Open Sat: {haulerPricing.hauler?.hoursOfOperation?.sat?.start ? 'Yes' : 'No'}</div>
      <div>Sizes: {haulerPricing?.rowRates?.materialData?.sizes?.map((size) => `${size.size}, `)}</div>
      <div>
        Rental Period: {haulerPricing?.hauler?.defaultRentPeriod?.value} days -{' '}
        {moneyFormatter(haulerPricing?.hauler?.rentExtensionFee, {
          undefinedBehavior: 'Unknown',
        })}{' '}
        / day
      </div>
      <div className="bg-white text-black text-opacity-90 mt-1 -mx-2 text-sm flex flex-col min-w-[150px]">
        <Button
          className="btn-primary-text-only py-1 text-sm"
          disabled={!getPrimaryContact(haulerPricing.hauler)?.phoneNumber}
          onClick={() => copyToClipboard(getPrimaryContact(haulerPricing.hauler)?.phoneNumber)}
          endIcon={<DocumentDuplicateIcon className="h-3.5 w-3.5" />}
        >
          {getPrimaryContact(haulerPricing.hauler)?.phoneNumber}
        </Button>
        <Button
          className="btn-primary-text-only py-1 text-sm"
          disabled={!getPrimaryContact(haulerPricing.hauler)?.email}
          onClick={() => copyToClipboard(getPrimaryContact(haulerPricing.hauler)?.email)}
          endIcon={<DocumentDuplicateIcon className="h-3.5 w-3.5" />}
        >
          <span className="lowercase">{getPrimaryContact(haulerPricing.hauler)?.email}</span>
        </Button>
      </div>
    </div>
  );

  return (
    <tr
      onClick={() => onRowClick?.(haulerPricing)}
      className={twMerge(`[&>*]:px-4 [&>*]:py-1.5 border-b`, 'cursor-pointer hover:bg-slate-50', getTableRowStyles())}
    >
      {/* Check Icon for selected hauler */}
      <td className="!p-0">
        {selectedHauler && selectedHauler === haulerPricing.haulerID ? (
          <CheckIcon className="h-6 w-6 text-success" />
        ) : null}
      </td>
      <td>
        <div className="flex items-center gap-2">
          <div>
            <Tooltip text={toolTipContent}>
              <span
                onClick={() => {
                  if (!onRowClick) {
                    if (haulerPricing.haulerID) window.open(routes.haulers.details(haulerPricing.haulerID));
                  }
                }}
              >
                {haulerPricing.haulerName} <StarRating rating={haulerPricing?.hauler?.rating} fontSize="small" />
              </span>
            </Tooltip>
          </div>
          {(haulerPricing?.rowRates?.materialData || haulerPricing.waysteUsage) && (
            <div className="flex flex-col items-center">
              {((haulerPricing?.rowRates?.materialData && !haulerPricing?.rowRates?.materialData?.sourgumApproved) ||
                !haulerPricing.sourgumApproved) && (
                <Tooltip text="Pricing has not been approved by Sourgum">
                  <ExclamationTriangleIcon className="h-6 w-6 text-warning" />
                </Tooltip>
              )}
              {haulerPricing.waysteUsage ? (
                haulerPricing.waysteUsage === 'LITE' ? (
                  <Tooltip text="Hauler has used Wayste Lite in the last 7 days">
                    <div className="flex items-center gap-1 justify-center">
                      <WaysteIcon />
                      <span className="font-light text-sm leading-none">Lite</span>
                    </div>
                  </Tooltip>
                ) : (
                  /* Wayste Standard */
                  <Tooltip text="Hauler has used Wayste Standard in the last 7 days">
                    <div>
                      <WaysteIcon />
                    </div>
                  </Tooltip>
                )
              ) : null}
            </div>
          )}
        </div>
      </td>
      <td>
        {haulerPricing?.rowRates?.materialData?.doesNotService
          ? 'Does not service'
          : haulerPricing?.rowRates?.materialData?.type}
      </td>
      <td>{getHaulerHaulRateDisplay()}</td>
      <td>{haulerPricing?.rowRates?.sizeData?.dump ? moneyFormatter(haulerPricing.rowRates.sizeData.dump) : null}</td>
      <td>{getHaulSpreadDisplay()}</td>
      <td>{getDumpSpreadDisplay()}</td>
      <td>{getOverageSpreadDisplay()}</td>
      <td>{getCalculatedProfitDisplay()}</td>
    </tr>
  );
};
