import { useEffect, useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { Button, RadioButton, Toggle } from '@wayste/sour-ui';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import MuiTextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Field } from 'formik';
import { Select, TextField } from 'formik-mui';
import { createOrderConfirmationEmail } from '../../utils/email-utils';
import { paymentMethods, paymentMethodsEnums, paymentTerms, paymentTermsEnums } from '../../utils/shared-types';
// import ChargeCard from '../ChargeCard';
import ChargeCard2 from '../ChargeCard/ChargeCard2';

export interface PaymentSummaryProps {
  user: any;
  onSubmit: any;
  isLoading: boolean;
  disabled: boolean;
  formik: any;
  existingCustomer: Customer.AllianceCustomerTransport | null;
  getOrderTotal: any;
  zonePricing: any;
}

const PaymentSummary = ({
  user,
  onSubmit,
  isLoading,
  disabled,
  formik,
  existingCustomer,
  getOrderTotal,
  zonePricing,
}: PaymentSummaryProps) => {
  const { values, setFieldValue, setValues, isValid } = formik;
  const [fetchedStripeId, setFetchedStripeId] = useState('');
  const [chargeOption, setChargeOption] = useState('now');
  const [cardSuccessful, setCardSuccessful] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setValues({
      ...values,
      sendEmail: values.primaryContact?.email ? true : false,
    });
  }, [values.primaryContact?.email]);

  const handleFetchStripeCharge = async () => {
    alert(
      'WARNING. Make sure that the total amount for this order listed above matches the total amount in the Roll Off Order Placed email. They can be different.',
    );
  };

  const handleOrderOnlineToggle = (value: any) => {
    if (!value) {
      setValues({
        ...values,
        websiteSale: value,
        stripeId: existingCustomer?.stripeId || '',
        paymentInfo: null,
      });
      setCardSuccessful(false);
    } else {
      const paymentInfo = {
        paymentReceivedDate: new Date(),
        amountDollars: +getOrderTotal(),
        paymentMethod: values.paymentMethod,
        paymentIdentifier: null,
        stripeChargeID: null,
      };
      setValues({
        ...values,
        paymentInfo: paymentInfo,
        stripeId: fetchedStripeId,
        websiteSale: value,
      });
      setCardSuccessful(true);
    }
  };

  const handleChargeSuccessful = (stripeChargeID: string, last4: string, stripeId: string) => {
    const paymentInfo = {
      paymentReceivedDate: new Date(),
      amountDollars: +getOrderTotal(),
      paymentMethod: values.paymentMethod,
      paymentIdentifier: last4,
      stripeChargeID: stripeChargeID,
    };
    setValues({ ...values, stripeId, paymentInfo });
    setCardSuccessful(true);
  };

  const handleStripeCustomerFetched = (res: any) => {
    if (res) setFetchedStripeId(res.id);
  };

  const handleSaveSuccessful = (stripeId: string) => {
    setFieldValue('stripeId', stripeId);
    setCardSuccessful(true);
  };

  const handleChargeIdChange = (event: React.ChangeEvent<any>) => {
    setValues({
      ...values,
      paymentInfo: {
        ...values.paymentInfo,
        stripeChargeID: event.target.value.trim(),
      },
    });
  };

  const handleSubmit = (event: React.ChangeEvent<any>) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <Typography variant="h6">Payment Info</Typography>
      </Grid>
      <Grid item xs={12}>
        <Toggle
          value={values.websiteSale}
          disabled={(values.paymentInfo && !values.websiteSale ? true : false) || disabled}
          label="Ordered Via The Website"
          onChange={handleOrderOnlineToggle}
        />
      </Grid>
      {values.websiteSale ? (
        <>
          <Grid item xs={12} md={6}>
            <Field
              id="stripeId"
              name="stripeId"
              label="Stripe Id"
              component={TextField}
              margin="dense"
              disabled={disabled}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiTextField
              label="Stripe Charge Id"
              value={values?.paymentInfo?.stripeChargeID ? values.paymentInfo.stripeChargeID : ''}
              onChange={handleChargeIdChange}
              margin="dense"
              disabled={disabled}
              fullWidth
              onBlur={handleFetchStripeCharge}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              id="searchParams"
              name="searchParams"
              label="Search Params"
              component={TextField}
              margin="dense"
              disabled={disabled}
              fullWidth
              helperText="From email"
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <Field
              name="paymentTerm"
              component={Select}
              label="Payment term"
              labelId="paymentTerm-label"
              formControl={{ fullWidth: true, size: 'small' }}
              disabled={Boolean(values.paymentInfo) || disabled}
              inputProps={{
                id: 'paymentTerm',
              }}
            >
              {Object.entries(paymentTerms).map((item) => (
                <MenuItem key={`paymentTerm-${item[0]}`} value={item[0]}>
                  {item[1]}
                </MenuItem>
              ))}
            </Field>
            <div style={{ color: 'rgba(0,0,0,0.54)', fontSize: 12 }}>
              You can set default payment settings in the customer details screen
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="paymentMethod"
              label="Payment method"
              labelId="paymentMethod-label"
              formControl={{ fullWidth: true, size: 'small' }}
              component={Select}
              disabled={Boolean(values.paymentInfo) || disabled}
              inputProps={{
                id: 'paymentMethod',
              }}
            >
              {Object.entries(paymentMethods).map((item) => (
                <MenuItem key={`paymentMethod-${item[0]}`} value={item[0]}>
                  {item[1]}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          {values.paymentTerm !== paymentTermsEnums.onCharge && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <b>Issue Invoice</b>
                </Grid>
                <Grid item xs={12}>
                  <i>
                    If you want to make edits to this orders invoice before sending it to the customer, click{' '}
                    <b>Draft</b>
                  </i>
                </Grid>
                <Grid item xs={12}>
                  <div className="flex">
                    <RadioButton
                      options={[
                        { value: 'NOW', label: 'Now', inputProps: { checked: values.issueInvoice === 'NOW' } },
                        { value: 'DRAFT', label: 'Draft', inputProps: { checked: values.issueInvoice === 'DRAFT' } },
                      ]}
                      wrapperClass="mr-4"
                      inputProps={{
                        onChange: (e) => {
                          const newValue = e.target.value;
                          setFieldValue('issueInvoice', newValue);
                        },
                        name: 'issueInvoice',
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <b>Read This to Customer:</b> So you are aware there is a $25 cancellation fee if you cancel at any time
            before your delivery date. If you cancel on your delivery date, of if we are unable to deliver the dumpster,
            a trip charge may also be applied. We do not pickup your dumpster automatically, we wait to hear from you.
          </Grid>
          {values.paymentMethod === paymentMethodsEnums.creditCard ? (
            <div style={{ marginBottom: 32, marginTop: 16 }}>
              <ChargeCard2
                total={+getOrderTotal() * 100}
                showChargeOption
                customer={{ ...values }}
                disabled={disabled}
                onChargeSuccessful={handleChargeSuccessful}
                onSaveSuccessful={handleSaveSuccessful}
                onStripeCustomerFetched={handleStripeCustomerFetched}
                onChargeOptionChange={setChargeOption}
              />
            </div>
          ) : null}
        </>
      )}
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <Typography variant="h6">Email Order Summary</Typography>
      </Grid>
      <Toggle
        value={values.sendEmail}
        label="Send email"
        onChange={(value) => {
          setValues({
            ...values,
            sendEmail: value,
            includeCCForm: false,
          });
        }}
        disabled={!values.primaryContact?.email}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: 22,
        }}
      >
        {values.sendEmail ? (
          <>
            <div
              style={{
                fontSize: 16,
                color: 'rgba(0, 0, 0, 0.68)',
                marginBottom: 7,
              }}
            >
              Email preview
            </div>
          </>
        ) : (
          <div
            style={{
              fontSize: 16,
              color: 'rgba(244, 67, 54, 0.86)',
              marginBottom: 7,
            }}
          >
            YOU MUST READ THE BELOW TO THE CUSTOMER OVER THE PHONE OR SEND THEM AN EMAIL
          </div>
        )}
        <div
          style={{
            backgroundColor: '#FFFFFF',
            borderRadius: 4,
            border: 'solid 1px #979797',
            padding: '9px 14px',
            fontSize: 14,
          }}
        >
          <div style={{ marginBottom: 10 }}>
            Hi {values.primaryContact?.firstName},
            <br />
            <br />
            This is {user?.firstName} with Sourgum Waste confirming your roll-dumpster rental. If you need anything you
            can give us a call at (732) 366-9355.
          </div>
          <textarea
            rows={4}
            style={{
              width: '100%',
              fontSize: 14,
              border: 'solid 1px #D8D8D8',
              borderRadius: 4,
            }}
            value={values.emailMessage}
            onChange={(event) => setFieldValue('emailMessage', event.target.value)}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: createOrderConfirmationEmail(values, getOrderTotal(), user, null, zonePricing?.prohibitedItems)
                .customizableHTMLMessage,
            }}
          />
        </div>
      </div>
      <Grid item xs={12}>
        <div className="flex justify-end mt-8 mb-2.5">
          <Button
            className="btn-primary"
            disabled={
              isLoading ||
              isSubmitting ||
              !isValid ||
              disabled ||
              (values.paymentMethod === paymentMethodsEnums.creditCard &&
                values.paymentTerm === paymentTermsEnums.onCharge &&
                chargeOption !== 'none' &&
                !cardSuccessful)
            }
            type="button"
            onClick={(e: React.ChangeEvent<any>) => {
              setIsSubmitting(true);
              handleSubmit(e);
            }}
            loading={isLoading}
          >
            Create Order
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default PaymentSummary;
