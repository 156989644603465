import { useContext, useEffect, useState } from 'react';
import { isBetaTester, useIsAppAdmin, useWaysteClient } from '@alliance-disposal/client';
import { Profile } from '@alliance-disposal/transport-types';
import CssBaseline from '@mui/material/CssBaseline';
import { indigo } from '@mui/material/colors';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';
import Auth from './components/Auth';
import FlashMessage from './components/FlashMessage';
import Loading from './components/Loading';
import { UIContext, UIProvider, UserContext, UserProvider } from './contexts';
import Layout from './structure/Layout';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    default: PaletteOptions['primary'];
  }
}

const muiTheme = createTheme(
  {
    typography: {
      fontSize: 14,
    },
    palette: {
      primary: {
        main: '#007AFF',
      },
      secondary: indigo,
      default: {
        main: '#FFFFFF',
        contrastText: '#000000',
      },
    },
    components: {
      MuiInput: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: { height: '46px', paddingTop: '10px' },
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            height: '46px',
          },
        },
      },
    },
  },
  //adaptV4Theme({})
);

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <UIProvider>
          <UserProvider>
            <MainView />
          </UserProvider>
        </UIProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;

const MainView = () => {
  const { flash } = useContext(UIContext);

  const client = useWaysteClient();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userIsLoading, setUserIsLoading] = useState<boolean>(true);
  const { listUsers } = useContext(UserContext);
  const userIsBetaTester = isBetaTester();
  const { isAdmin } = useIsAppAdmin();

  const signOutUser = async () => {
    await client.auth().signOut();
    setIsLoggedIn(false);
    setUserIsLoading(false);
  };

  const fetchProfileAndRoster = async (auth: Profile.AuthorityTransport | null) => {
    if (auth) {
      Sentry.setUser({ id: auth?.profileID });
      // Fetch user profile
      await client.user().fetchCurrentUserProfile();
      await client.profile().adminPortal.fetchRoster();
      setUserIsLoading(false);
      listUsers();
    }
    setUserIsLoading(false);
    setIsLoggedIn(auth !== null && auth !== undefined);
  };

  useEffect(() => {
    // Subscribe to auth changes.
    setUserIsLoading(true);
    const sub = client.auth().currentAuthority.subscribe(fetchProfileAndRoster);
    return () => {
      sub.unsubscribe();
    };
  }, [client, userIsLoading]);

  // if the user is beta tester, then redirect to the beta tester page
  useEffect(() => {
    if (isAdmin) {
      console.warn('User is admin, suppressing beta tester redirect');
      return;
    }

    if (window.location.href.includes('localhost')) {
      console.warn('User is on localhost, suppressing beta tester redirect');
      return;
    }

    // current location
    const currentLocation = window.location.href;
    // make a URL object
    const url = new URL(currentLocation);
    if (userIsBetaTester && !currentLocation.includes('v2')) {
      // change the hostname to v2
      url.hostname = 'admin.v2.sourgum.com';
      const newUrl = url.toString();

      // redirect to v2
      window.location.href = newUrl;
    } /* else if (!userIsBetaTester && currentLocation.includes('v2')) {
      // user is not beta tester, but the url contains v2
      // change the hostname to v1
      url.hostname = 'admin.sourgum.com';
      const newUrl = url.toString();

      // redirect to stable
      window.location.href = newUrl;
    } */
  }, [userIsBetaTester]);

  if (!isLoggedIn && userIsLoading) return <Loading />;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {isLoggedIn ? (
        <Layout
          onSignOut={() => {
            signOutUser();
          }}
        />
      ) : (
        <Auth />
      )}
      <FlashMessage
        showFlash={flash.showFlash}
        flashMessageText={flash.flashMessageText}
        flashStyle={flash.flashStyle || undefined}
      />
    </LocalizationProvider>
  );
};
