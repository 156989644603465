import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Order } from '@alliance-disposal/transport-types';
import { Loading, Toggle } from '@wayste/sour-ui';
import { buildAddressString, getDispatchEmailsString, getPrimaryCustomerContact } from '@wayste/utils';
import { getPrimaryContact } from '@wayste/utils';
import Grid from '@mui/material/Grid';
import { sendDynamicEmail } from '../../axios/sendgrid';
import { sendEmail } from '../../axios/ses';
import { UIContext } from '../../contexts';
import { OrderStatus, getCustomerCCAddresses, noReplyEmail } from '../../utils';
import Dialog from '../Dialog';

export interface SendEmailDialogProps {
  open: boolean;
  onClose: () => void;
  changes: any;
  customer: Customer.AllianceCustomerTransport;
  order: Order.AllianceOrderTransport;
}

const SendEmailDialog = ({ open, onClose, changes, customer, order }: SendEmailDialogProps) => {
  const client = useWaysteClient();
  const userProfile = client.user().get();

  const { showFlash } = useContext(UIContext);
  const [sendCustomerEmail, setSendCustomerEmail] = useState(false);
  const [sendHaulerEmail, setSendHaulerEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (order.status === OrderStatus.UNASSIGNED) setSendHaulerEmail(false);
  }, [order]);

  useEffect(() => {
    console.log('Email changes array', changes);
  }, [changes]);

  const customerEmailData = {
    emailTemplate: 'RO_CHANGES_EMAIL',
    toEmail: getPrimaryCustomerContact(customer)?.email,
    ccEmails: getCustomerCCAddresses(customer, order.serviceLocation.address).serviceCCAddresses,
    first_name: getPrimaryContact(customer.contacts)?.firstName,
    csa_name: userProfile.firstName,
    order_number: order.orderNumber,
    service_location: buildAddressString(order.serviceLocation.address),
    changes_block: changes
      .filter((item: any) => item.label !== 'Additional Delivery Notes' && item.label !== 'Additional Pickup Notes')
      .map((item: any) => {
        if (item.label === 'Pickup Date' && item.oldValue && !item.newValue) {
          return `<p><b>${item.label}:</b> Cancel pickup until further notice.</p>`;
        }
        return `<p><b>${item.label}:</b> from ${item.oldValue} to ${item.newValue}</p>`;
      })
      .join(''),
  };

  const customerHtmlMessage = `<p>Hi ${getPrimaryCustomerContact(customer)?.firstName},</p>
  <p>This is ${userProfile.firstName} with Sourgum Waste. I've updated the following information for your order #${
    order.orderNumber
  } at ${buildAddressString(order.serviceLocation.address)} for you.</p>
  ${changes
    .filter((item: any) => item.label !== 'Additional Delivery Notes' && item.label !== 'Additional Pickup Notes')
    .map((item: any) => {
      if (item.label === 'Pickup Date' && item.oldValue && !item.newValue) {
        return `<p><b>${item.label}:</b> Cancel pickup until further notice.</p>`;
      }
      return `<p><b>${item.label}:</b> from ${item.oldValue} to ${item.newValue}</p>`;
    })
    .join('')}
  <p>If you have any questions please let me know and I'll be happy to help you.</p>
  <p>Thank you,</p>
  <p>${userProfile.firstName}</p>`;

  const haulerEmailText = `Confirmation of changes to Sourgum Waste dumpster rental:
  \n\nOrder #${order.orderNumber}
  \n\nAddress: ${buildAddressString(order.serviceLocation.address)}
  \n\n${changes.map((item: any) => {
    if (item.label === 'Pickup Date' && item.oldValue && !item.newValue) {
      return `${item.label}: Cancel pickup until further notice.\n\n`;
    }
    return `${item.label}: from ${item.oldValue} to ${item.newValue}\n\n`;
  })}
  If you have any questions please let me know and I'll be happy to help you.
  \n\nThank you,
  \n\n${userProfile.firstName}`;

  const haulerHtmlMessage = `<p>Confirmation of changes to Sourgum Waste dumpster rental:</p>
  <p><b>Order #</b>: ${order.orderNumber}</p>
  <p><b>Address</b>: ${buildAddressString(order.serviceLocation.address)}</p>
  ${changes
    .map((item: any) => {
      if (item.label === 'Pickup Date' && item.oldValue && !item.newValue) {
        return `<p><b>${item.label}:</b> Cancel pickup until further notice.</p>`;
      }
      return `<p><b>${item.label}:</b> from ${item.oldValue} to ${item.newValue}</p>`;
    })
    .join('')}
  <p>If you have any questions please let me know and I'll be happy to help you.</p>
  <p>Thank you,</p>
  <p>${userProfile.firstName}</p>`;

  const handleSendClick = async () => {
    setIsLoading(true);
    if (sendHaulerEmail && order.haulerID) {
      const haulerResponse = await client.vendorService().fetchById(order.haulerID);
      const hauler = haulerResponse.data;
      const dispatchEmailsString = getDispatchEmailsString(hauler.contacts);
      if (dispatchEmailsString) {
        const haulerData = {
          htmlMessage: haulerHtmlMessage,
          textMessage: haulerEmailText,
          toAddress: dispatchEmailsString,
          subject: `Sourgum Waste Order Update - ${order.orderNumber}`,
          bcc: noReplyEmail,
        };
        try {
          await sendEmail('send-email', haulerData);
        } catch (error) {
          console.warn('OrderUpdate send hauler email error: ', error);
          showFlash('An error occurred sending Hauler email', 'warning');
        }
      } else {
        showFlash('No Hauler Email', 'warning');
      }
    }
    if (sendCustomerEmail) {
      try {
        // @ts-expect-error TODO: need to fix this
        await sendDynamicEmail(customerEmailData);
      } catch (error) {
        console.warn('OrderUpdate send customer email error: ', error);
        showFlash('An error occurred sending Customer email', 'warning');
      }
    }
    showFlash('Emails Sent Successfully', 'success');
    setIsLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} styledTitle="Send Update Emails" className="max-w-2xl">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              Do you want to send the email below to the hauler?
            </Grid>
            <Grid item xs={12}>
              <div dangerouslySetInnerHTML={{ __html: haulerHtmlMessage }} style={{ color: 'rgba(0, 0, 0, 0.58)' }} />
            </Grid>
            <Grid item xs={6}>
              <Toggle
                value={sendHaulerEmail}
                label="Hauler"
                onChange={setSendHaulerEmail}
                disabled={order.status === OrderStatus.UNASSIGNED}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              Do you want to send the email below to the customer?
            </Grid>
            <Grid item xs={12}>
              <div dangerouslySetInnerHTML={{ __html: customerHtmlMessage }} style={{ color: 'rgba(0, 0, 0, 0.58)' }} />
            </Grid>
            <Grid item xs={12}>
              <Toggle value={sendCustomerEmail} label="Customer" onChange={setSendCustomerEmail} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="flex justify-end gap-4 pt-4">
        <button className="btn-dark-grey-outlined" onClick={onClose} type="button" disabled={isLoading}>
          Cancel
        </button>
        <button className="btn-primary" onClick={handleSendClick} type="submit" disabled={isLoading}>
          Send to Selected
          {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
        </button>
      </div>
    </Dialog>
  );
};

export default SendEmailDialog;
