import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { getDispatchEmailsString } from '@wayste/utils';
import { asyncForEach } from '@wayste/utils';
import { isAxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { sendEmail } from '../../axios/ses';
import { UIContext, useConfirmationDialog } from '../../contexts';
import { OrderStatus, orderCancellationEmailHauler, routes } from '../../utils';
import OrderAssignHauler from './OrderAssignHauler';
import OrderEnterTicket from './OrderEnterTicket';
import OrderReadyPickUp from './OrderReadyPickUp';
import OrderStatusPopper from './OrderStatusPopper';

interface Props {
  order: Order.AllianceOrderTransport;
  navigateOnly?: boolean;
  className?: string;
}

const OrderStatusChanger = ({ order, navigateOnly, className }: Props) => {
  const client = useWaysteClient();
  const history = useHistory();
  const location = useLocation();
  const { getConfirmation } = useConfirmationDialog();
  const { showFlash } = useContext(UIContext);
  // const { createReceivable, updateReceivableOld, updatePayableOld } = useContext(PayableReceivableContext);
  const [openModal, setOpenModal] = useState<any>(null);
  const [modalLoading, setModalLoading] = useState(false);

  const userProfile = client.user().get();

  const fetchHauler = async (id: string) => {
    try {
      const response = await client.vendorService().fetchById(id);

      return { status: 'success', data: { ...response.data } };
    } catch (error) {
      console.warn('fetchHauler apolloError for ID: ', id, error);
      throw error;
    }
  };

  const handleCloseModal = () => {
    setModalLoading(false);
    setOpenModal(null);
  };

  const handleSubmitAssign = async (
    orderUpdate: Order.AllianceOrderUpdateInput,
    email?: {
      htmlMessage: string;
      textMessage: string;
      toAddress: string;
      subject: string;
      bcc: string;
    },
    pricingSnapshot?: Order.VendorPricingSnapshotTransportCreate,
  ) => {
    setModalLoading(true);

    try {
      if (pricingSnapshot) {
        orderUpdate.vendorPricingSnapshot = pricingSnapshot;
      }
      await await client.order().adminPortal.update(order.id, orderUpdate);
      showFlash('Order Successfully Updated', 'success');

      if (!email) {
        throw new Error('No Email Provided');
      }
      // Send email to new hauler
      try {
        await sendEmail('send-email', email);
      } catch (error) {
        console.error('error: ', error);
        throw new Error('Error Sending Email to New Hauler');
      }

      // Prompt User to Send email to old hauler that order was cancelled
      if (order.haulerID && order.haulerID !== orderUpdate.haulerID) {
        const shouldSendCancellationEmail = await getConfirmation({
          title: 'Send Email to Old Hauler?',
          message: 'Do you want to send a cancellation email to the hauler you are taking this order away from?',
        });

        if (shouldSendCancellationEmail) {
          const haulerResponse = await fetchHauler(order.haulerID);

          const hauler = haulerResponse.data;
          if (!hauler) return;
          const haulerEmailData = orderCancellationEmailHauler(hauler, order);
          const dispatchEmailsString = getDispatchEmailsString(hauler?.contacts);

          if (!Boolean(dispatchEmailsString)) {
            showFlash('No hauler email exists', 'warning');
            return;
          }

          try {
            await sendEmail('send-email', haulerEmailData);
          } catch (error) {
            console.error('error: ', error);
            throw new Error('Error Sending Cancellation Email to Old Hauler');
          }
        }
      }
    } catch (error) {
      let message = 'An Error Occurred';

      if ((error as Error).message) {
        message = (error as Error).message;
      }

      if (isAxiosError(error)) {
        message = error.response?.data?.additionalInfo || error.response?.data?.message || message;
      }

      showFlash(message, 'warning');
    } finally {
      handleCloseModal();
    }
  };

  const handleSubmitDumped = async (
    orderUpdate: Order.AllianceOrderUpdateInput,
    updatedInvoices: Invoice.ReceivableTransport[],
    updatedBills: Invoice.PayableTransport[],
  ) => {
    await await client.order().adminPortal.update(order.id, { ...orderUpdate });

    const uniqInvoiceSet = new Set(updatedInvoices.map((invoice: any) => JSON.stringify(invoice)));
    if (uniqInvoiceSet.size < updatedInvoices.length) {
      alert('Grab an AAP dev, duplicate invoices creating now...');
    }
    await asyncForEach(updatedInvoices, async (invoice) => {
      if (invoice.id) {
        const updatedReceivable: Invoice.ReceivableUpdateTransport = {
          customerID: invoice.customerID,
          invoiceDetails: {
            ...invoice.invoiceDetails,
          },
        };
        try {
          await client.invoice().adminPortal.receivable.update(invoice.id, updatedReceivable);
        } catch (error) {
          console.error('error: ', error);
        }
      } else {
        const createObj: Invoice.ReceivableCreateTransport = {
          customerID: invoice.customerID,
          invoiceDetails: {
            lineItems: invoice.invoiceDetails.lineItems,
            taxRate: invoice.invoiceDetails.taxRate,
            taxAmountDollars: invoice.invoiceDetails.taxAmountDollars,
            issueDate: invoice.invoiceDetails.issueDate ? invoice.invoiceDetails.issueDate : undefined,
            dueDate: invoice.invoiceDetails.dueDate ? invoice.invoiceDetails.dueDate : undefined,
            syncedWithAccounting: invoice.invoiceDetails.syncedWithAccounting,
            orderID: invoice.invoiceDetails.orderID,
            orderNumber: invoice?.invoiceDetails?.orderNumber,
            invoiceNumber: invoice.invoiceDetails.invoiceNumber,
            orderServiceLocation: invoice.invoiceDetails.orderServiceLocation,
            void: invoice.invoiceDetails.void,
          },
        };

        try {
          await client.invoice().adminPortal.receivable.create(createObj);
        } catch (error) {
          console.error('error: ', error);
        }
      }
    });
    const uniqBillSet = new Set(updatedBills.map((bill: any) => JSON.stringify(bill)));
    if (uniqBillSet.size < updatedBills.length) {
      alert('Grab an AAP dev, duplicate bills creating now...');
    }
    await asyncForEach(updatedBills, async (payable: any) => {
      const payableModel = payable;

      if (payable.topLevelID) {
        const updateObj: Invoice.PayableUpdateTransport = {
          invoiceDetails: {
            void: payableModel.void,
            syncedWithAccounting: payableModel.syncedWithAccounting,
            taxAmountDollars: payableModel.taxAmountDollars,
            taxRate: payableModel.taxRate,
            orderID: payableModel.orderID,
            lineItems: payableModel.lineItems,
            issueDate: payableModel.issueDate ? payableModel.issueDate.toISOString() : undefined,
            invoiceNumber: payableModel.invoiceNumber,
            dueDate: payableModel.dueDate ? payableModel.dueDate.toISOString() : undefined,
          },
        };

        try {
          await client.invoice().adminPortal.payable.update(payable.id, updateObj);
        } catch (error) {
          console.error('error: ', error);
          showFlash('An Error Occurred', 'warning');
        }
      } else {
        const invoiceDetails = { ...payable };

        const createObj: Invoice.PayableCreateTransport = {
          haulerID: payable.haulerID,
          vendorName: payable.vendorName,
          invoiceDetails: {
            dueDate: invoiceDetails.dueDate ? invoiceDetails.dueDate.toISOString() : undefined,
            invoiceNumber: invoiceDetails.invoiceNumber,
            issueDate: invoiceDetails.issueDate ? invoiceDetails.issueDate.toISOString() : new Date().toISOString(),
            lineItems: invoiceDetails.lineItems,
            orderID: invoiceDetails.orderID,
            orderNumber: order?.orderNumber?.toString() || '',
            orderServiceLocation: order.serviceLocation,
            taxRate: invoiceDetails.taxRate,
            syncedWithAccounting: invoiceDetails.syncedWithAccounting,
            taxAmountDollars: invoiceDetails.taxAmountDollars,
            void: invoiceDetails.void,
          },
        };

        try {
          await client.invoice().adminPortal.payable.create(createObj);
        } catch (error) {
          console.error('error: ', error);
          showFlash('An Error Occurred', 'warning');
        }
      }
    });
    if (orderUpdate.status === OrderStatus.COMPLETED && !location.pathname.includes(routes.billing.list)) {
      history.push({ pathname: routes.billing.list, search: `?id=${order.id}` });
    } else {
      handleCloseModal();
    }
  };

  const handleBackToOpen = async () => {
    const confirmed = await getConfirmation({
      title: 'Reset to Open?',
      message: 'Are you sure you want to reset this order to open?',
    });
    if (confirmed) {
      await client.order().adminPortal.update(order.id, {
        status: OrderStatus.UNASSIGNED,
        haulerConfirmedDelivery: false,
        haulerDumpRateDollars: null,
        haulerHaulRateDollars: null,
        haulerID: null,
        haulerPricingSnapshot: null,
      });
      const sendEmailConfirmed = await getConfirmation({
        title: 'Send Email to Hauler?',
        message: 'Do you want to send a cancellation email to the hauler?',
        cancelText: 'No',
        confirmText: 'Yes',
      });
      if (sendEmailConfirmed) {
        const haulerResponse = await fetchHauler(order?.haulerID || '');
        if (haulerResponse.status === 'error') {
          console.warn('handleChange haulerResponse error: ', haulerResponse);
          alert('Something went wrong get AAP Dev');
          return;
        }
        const hauler = haulerResponse.data;
        if (!hauler) return;
        const haulerEmailData = orderCancellationEmailHauler(hauler, order);
        const dispatchEmailsString = getDispatchEmailsString(hauler?.contacts);
        if (Boolean(dispatchEmailsString)) {
          try {
            await sendEmail('send-email', haulerEmailData);
          } catch (error) {
            showFlash('Error Sending Hauler Email', 'warning');
          }
        } else {
          alert('No hauler email exists');
        }
      }
      handleCloseModal();
    }
  };

  const handleChange = async (newStatus: string) => {
    switch (newStatus) {
      case OrderStatus.UNASSIGNED:
        if (order.status === OrderStatus.UNASSIGNED) break;
        handleBackToOpen();
        break;
      case OrderStatus.ASSIGNED:
        setOpenModal(OrderStatus.ASSIGNED);
        break;
      case OrderStatus.DELIVERED:
        await client.order().adminPortal.update(order.id, {
          status: OrderStatus.DELIVERED,
        });
        handleCloseModal();
        break;
      case OrderStatus.READY_FULL_PICKUP:
        setOpenModal(OrderStatus.READY_FULL_PICKUP);
        break;
      case OrderStatus.DUMPED:
        if (order.status !== OrderStatus.DUMPED) {
          await client.order().adminPortal.update(order.id, {
            status: OrderStatus.DUMPED,
          });
        }
        setOpenModal(OrderStatus.DUMPED);
        break;
      default:
        alert('missing case');
        break;
    }
  };

  return (
    <>
      <OrderStatusPopper order={order} onChange={handleChange} navigateOnly={navigateOnly} className={className} />
      {openModal === OrderStatus.ASSIGNED ? (
        <OrderAssignHauler
          open={true}
          onCancel={handleCloseModal}
          onSubmit={handleSubmitAssign}
          order={order}
          loading={modalLoading}
        />
      ) : null}
      {openModal === OrderStatus.READY_FULL_PICKUP ? (
        <OrderReadyPickUp open={true} order={order} user={userProfile} onCancel={handleCloseModal} />
      ) : null}
      {openModal === OrderStatus.DUMPED ? (
        <OrderEnterTicket open={true} order={order} onCancel={handleCloseModal} onSave={handleSubmitDumped} />
      ) : null}
    </>
  );
};

export default OrderStatusChanger;
