import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, UniversalService } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { daysRemainingInBillingPeriod } from '@wayste/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { UIContext } from '../../contexts';
import SubscriptionServiceOrderAddSubForm from './SubscriptionServiceOrderAddSubForm';
import { UniversalServiceFormData } from './UniversalServiceCreate';
import {
  defaultServiceGrouping,
  defaultServiceOrderWithSubscriptionDetails,
  defaultServiceSubscriptionDetails,
} from './site-service-defaults';

const SubscriptionServiceOrderAdd = () => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const history = useHistory();
  const params: { serviceGroupingId: string } = useParams();
  const methods = useForm<UniversalServiceFormData>();
  const { formState, handleSubmit, reset } = methods;
  const [serviceGrouping, setServiceGrouping] = useState<UniversalService.ServiceGrouping | undefined>(undefined);
  const [customer, setCustomer] = useState<Customer.AllianceCustomerTransport | undefined>(undefined);

  const fetchServiceGrouping = async () => {
    try {
      const serviceGrouping = await client.universalService().serviceGrouping.fetch(params.serviceGroupingId);
      setServiceGrouping(serviceGrouping);
      const customer = await client.customer().adminPortal.fetch(serviceGrouping.customerID);
      setCustomer(customer);
    } catch (error) {
      console.warn('fetchServiceGrouping for ID: ', error);
      showFlash('Error fetching service grouping.', 'warning');
    }
  };

  const onSubmit = async (data: UniversalServiceFormData) => {
    try {
      // CREATE SERVICE ORDERS
      const promises = data.serviceGrouping.serviceOrders.map((order) => {
        order.serviceLocation = data.serviceGrouping.serviceOrders[0].serviceLocation; // service location shared across all service orders.
        if (serviceGrouping?.serviceOrders[0].subscriptionDetails && typeof order.subscriptionDetails !== 'string') {
          order.subscriptionDetails = {
            ...serviceGrouping?.serviceOrders[0].subscriptionDetails,
            periodicEvents: order.subscriptionDetails?.periodicEvents ?? [],
          };
          if (order.subscriptionDetails?.endDate === '') {
            order.subscriptionDetails.endDate = undefined;
          }
          // grab periodic events from subscription details and add them to service events.
          order.subscriptionDetails.periodicEvents.forEach((periodicEvent) => {
            if (!order.serviceEvents) order.serviceEvents = [];
            const newEvent = {
              ...periodicEvent,
              dateExpected: new Date().toISOString(),
            };
            if (data.prorate) {
              const d = daysRemainingInBillingPeriod({
                billingFrequency: data.subscriptionDetails.billingFrequency,
                billingFrequencyUnit: data.subscriptionDetails.billingFrequencyUnit,
                billingDay: data.subscriptionDetails.billingDay,
              });
              newEvent.unitCost = (d.daysRemaining / d.totalDays) * newEvent.unitCost;
            }
            order.serviceEvents.push(newEvent);
          });
        }
        return client.universalService().serviceGrouping.serviceOrder.create(params.serviceGroupingId, order);
      });
      Promise.all(promises)
        .then(() => {
          showFlash('Service Orders Successfully Added', 'success');
          console.log('Service Orders Successfully Added', promises);
          history.goBack();
        })
        .catch(() => {
          showFlash('Error Adding Service Orders', 'warning');
        });
    } catch (error) {
      showFlash('Error Adding Service Order', 'warning');
    }
  };

  useEffect(() => {
    reset({
      serviceGrouping: {
        ...defaultServiceGrouping,
        type: 'open-ended',
        contractDetails: {
          active: true,
        },
        serviceOrders: [{ ...defaultServiceOrderWithSubscriptionDetails }],
      },
      subscriptionDetails: defaultServiceSubscriptionDetails,
    });
    fetchServiceGrouping();
  }, []);

  useEffect(() => {
    if (!customer) return;
    console.log('customer', customer);
    reset({
      ...formState,
      customer: customer,
    });
  }, [customer]);

  return (
    <div className="p-16 flex-1 flex flex-col max-w-[960px]">
      <FormProvider {...methods}>
        <form>
          <div className="pb-2 mb-6 text-2xl">{`Add Service Order to Service Grouping ${
            serviceGrouping?.description ?? ''
          }`}</div>
          <div className="space-y-6">
            <SubscriptionServiceOrderAddSubForm methods={methods} />
          </div>
          <div className="mt-2 float-right">
            <Button className="btn-dark-grey-outlined mr-4" onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button className="btn-primary" onClick={handleSubmit(onSubmit)}>
              Add Service Orders
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
export default SubscriptionServiceOrderAdd;
