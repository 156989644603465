import { createContext, useMemo, useState } from 'react';
import { ReactNode } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { apm } from '@elastic/apm-rum';
import * as Sentry from '@sentry/react';
import { fetchAvailableAuthorityTokens, fetchCurrent, list } from './graphql/queries';

type UserContext = {
  listUsers: () => void;
  fetchAuthorizationTokens: (cognitoToken: string) => Promise<{ status: string; data: any }>;
  fetchCurrentUser: () => Promise<any>;
  companyRoster: any[];
  userIDToNameMap: Record<string, any>;
  activeUserIDToNameMap: Record<number, string>;
};

const initialValue: UserContext = {
  listUsers: () => null,
  fetchAuthorizationTokens: () => Promise.resolve({ status: 'error', data: null }),
  fetchCurrentUser: () => Promise.resolve(null),
  companyRoster: [],
  userIDToNameMap: {},
  activeUserIDToNameMap: {},
};

/**
 * @deprecated - use WaysteClient instead
 * @type {import('react').Context<UserContextType>} */
export const UserContext = createContext(initialValue);
/**
 * @deprecated - use WaysteClient instead
 */
export const UserProvider = ({ children }: { children: ReactNode }) => {
  const waysteClient = useWaysteClient();
  const client = waysteClient.apolloClient();
  const [userIDToNameMap, setUserIDToNameMap] = useState({});
  const [activeUserIDToNameMap, setActiveUserIDToNameMap] = useState({});
  const [companyRoster, setCompanyRoster] = useState([]);

  const listUsers = async () => {
    try {
      const { data, error } = await client.query({
        query: list,
      });
      if (error) console.warn('listUsers Error: ', error);
      if (!data || error) {
        alert('An error occurred getting the users. Try reloading the browser and trying again.');
        setCompanyRoster([]);
        return;
      }
      const { getAllianceRoster: userData } = data;
      setUserIDToNameMap(
        userData.reduce(
          (acc: Record<number, string>, curr: any) => ((acc[curr.id] = `${curr.firstName} ${curr.lastName}`), acc),
          {},
        ),
      );
      setActiveUserIDToNameMap(
        userData.reduce((acc: Record<number, string>, curr: any) => {
          if (curr.active) {
            return (acc[curr.id] = `${curr.firstName} ${curr.lastName}`), acc;
          }
          return acc;
        }, {}),
      );
      setCompanyRoster(userData);
    } catch (apolloError) {
      console.warn('listUsers apolloError: ', apolloError);
      alert('An error occurred getting the users. Try reloading the browser and trying again.');
      setCompanyRoster([]);
    }
  };

  const fetchAuthorizationTokens = async (cognitoToken: string) => {
    try {
      const response = await client.query({
        query: fetchAvailableAuthorityTokens,
        variables: { cognitoToken },
        fetchPolicy: 'no-cache',
      });
      return {
        status: 'success',
        data: response?.data?.getAvailableAuthorityTokens || null,
      };
    } catch (error) {
      console.warn('UserContext fetchAuthorizationTokens error: ', error);
      return { status: 'error', data: null };
    }
  };

  const fetchCurrentUser = async () => {
    try {
      const { data, error } = await client.query({
        query: fetchCurrent,
      });
      if (error) console.warn('fetchCurrentUser Error: ', error);
      if (!data || error) {
        alert('There was an error getting your user profile. Try reloading the browser and trying again.');
        return null;
      }
      const { getCurrentUserProfile: userData } = data;
      const userCopy = JSON.parse(JSON.stringify(userData));
      Sentry.setUser({
        id: userCopy?.id,
        username: userCopy?.username,
        email: userCopy?.email,
      });

      if (apm.isActive()) {
        apm.setUserContext({
          id: userCopy.id,
          username: userCopy.username ?? undefined,
          email: userCopy.email ?? undefined,
        });
      }

      await listUsers();
      return userCopy;
    } catch (apolloError) {
      console.warn('fetchCurrentUser apolloError: ', apolloError);
      alert('There was an error getting your user profile. Try reloading the browser and trying again.');
      return null;
    }
  };

  const values = useMemo(
    () => ({
      listUsers,
      fetchAuthorizationTokens,
      fetchCurrentUser,
      companyRoster,
      userIDToNameMap,
      activeUserIDToNameMap,
    }),
    [userIDToNameMap, companyRoster, activeUserIDToNameMap],
  );

  return <UserContext.Provider value={values as any}>{children}</UserContext.Provider>;
};
