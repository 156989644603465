import React, { useContext, useEffect } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Pricing } from '@alliance-disposal/transport-types';
import { Button, Checkbox, CurrencyTextField, Loading, Select, SelectOption, TextField } from '@wayste/sour-ui';
import InputAdornment from '@mui/material/InputAdornment';
import { Controller, useForm } from 'react-hook-form';
import { UIContext } from '../../contexts';
import { growthStageTypes, onlineGuaranteeTypes, operatingDayTypes, pricingMetros, statesAbb } from '../../utils';

/**
 * Form to create and update Sourgum Waste pricing
 * @param {Object} pricing Pricing schema object
 * @param {Function} onSubmit Function on form submission
 * @param {Function} onCancel Function on cancel clicked
 * @param {Boolean} isLoading Replaces submit and cancel with a loading indicator
 */

export interface PricingFormProps {
  mode: 'CREATE' | 'UPDATE';
  pricing?: Pricing.PricingTransport;
  onSubmit:
    | ((
        data: Pricing.PricingCreateTransport,
        pricingZones?: Pricing.PricingTransport[],
        copyExistingZone?: string,
      ) => void)
    | ((
        data: Pricing.PricingUpdateTransport,
        pricingZones?: Pricing.PricingTransport[],
        copyExistingZone?: string,
      ) => void);
  onCancel: (data: unknown) => void;
  isLoading: boolean;
}

export interface FormProps {
  state: Pricing.UsStateAbbreviation;
  zoneName: string;
  haul: string;
  tripCharge: number | '';
  rentalPeriod: { value: number; unit: string };
  maxRentalDaysAllowed: number;
  rentExtensionFee: number;
  prePaidRentalDaysDiscountRate: number;
  allowForPrePaidRentalDays: boolean;
  onlineCheckoutPageGuarantee: string;
  currentGrowthStage: 'SEED' | 'GROWTH' | 'MATURE' | undefined;
  metro: string;
  materials: Record<string, any>;
  notes: string;
  days: {
    mon: 'OPEN' | 'CLOSED' | 'LIMITED' | 'UNKNOWN';
    tue: 'OPEN' | 'CLOSED' | 'LIMITED' | 'UNKNOWN';
    wed: 'OPEN' | 'CLOSED' | 'LIMITED' | 'UNKNOWN';
    thu: 'OPEN' | 'CLOSED' | 'LIMITED' | 'UNKNOWN';
    fri: 'OPEN' | 'CLOSED' | 'LIMITED' | 'UNKNOWN';
    sat: 'OPEN' | 'CLOSED' | 'LIMITED' | 'UNKNOWN';
    sun: 'OPEN' | 'CLOSED' | 'LIMITED' | 'UNKNOWN';
  };
  prohibitedItems: string;
  copyExistingZone?: string;
}

const PricingForm = ({ mode, pricing, onSubmit, onCancel, isLoading }: PricingFormProps) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const [haulerPricingZones, setHaulerPricingZones] = React.useState<Pricing.PricingTransport[]>([]);
  const { handleSubmit, reset, watch, setValue, control } = useForm<FormProps>({
    defaultValues: {
      state: undefined,
      zoneName: '',
      haul: '',
      tripCharge: '',
      rentalPeriod: { value: 14, unit: 'day' },
      maxRentalDaysAllowed: 30,
      rentExtensionFee: 1000,
      prePaidRentalDaysDiscountRate: 0,
      allowForPrePaidRentalDays: false,
      onlineCheckoutPageGuarantee: '',
      currentGrowthStage: 'SEED',
      metro: '',
      materials: {},
      notes: '',
      days: {
        mon: 'OPEN',
        tue: 'OPEN',
        wed: 'OPEN',
        thu: 'OPEN',
        fri: 'OPEN',
        sat: 'CLOSED',
        sun: 'CLOSED',
      },
      prohibitedItems: '',
    },
    mode: 'all',
  });

  // FORM HOOKS
  const formValues = watch();

  useEffect(() => {
    if (formValues.state) {
      fetchStatePricingZones(formValues.state);
    }
  }, [formValues.state]);

  useEffect(() => {
    if (pricing) {
      reset(prepareFormData(pricing));
    }
  }, [pricing]);

  ////////////////////////////////////////////////////////
  // FUNCTIONS
  ///////////////////////////////////////////////////////

  const fetchStatePricingZones = async (newState: Pricing.UsStateAbbreviation) => {
    try {
      setValue('copyExistingZone', '');
      const data = await client.pricing().adminPortal.pricing.query({ state: newState, public: true, noJoins: false });
      setHaulerPricingZones(data);
    } catch (error) {
      console.log('error', error);
      showFlash('An Error Occurred', 'warning');
    }
  };

  const handleFormSubmit = async (data: FormProps) => {
    if (pricing) {
      const newData = {
        ...data,
        haulerID: pricing.haulerID,
        public: true,
        zoneName: data.zoneName,
        tripCharge: data.tripCharge ? data.tripCharge : 0,
        tripChargeDollars: data.tripCharge ? data.tripCharge / 100 : 0,
        rentExtensionFeeDollars: data.rentExtensionFee ? data.rentExtensionFee / 100 : 0,
        prohibitedItems: data.prohibitedItems.split(',').map((item) => item.trim()),
        pricingData: [],
        state: data.state ? data.state : pricing.state,
      };

      if (newData.prePaidRentalDaysDiscountRate) {
        newData.prePaidRentalDaysDiscountRate = newData.prePaidRentalDaysDiscountRate * 100;
      }
      if (newData.pricingData) {
        //@ts-expect-error -this is correct
        delete newData?.pricingData;
      }

      onSubmit(newData, [], data.copyExistingZone);
    } else {
      const newData = {
        ...data,
        public: true,
        zoneName: data.zoneName,
        tripCharge: data.tripCharge ? data.tripCharge : 0,
        tripChargeDollars: data.tripCharge ? data.tripCharge / 100 : 0,
        rentExtensionFeeDollars: data.rentExtensionFee ? data.rentExtensionFee / 100 : 0,
        prohibitedItems: data.prohibitedItems.split(',').map((item) => item.trim()),
        pricingData: [],
      };
      if (newData.prePaidRentalDaysDiscountRate) {
        newData.prePaidRentalDaysDiscountRate = newData.prePaidRentalDaysDiscountRate * 100;
      }

      onSubmit(newData as Pricing.PricingCreateTransport, haulerPricingZones, data.copyExistingZone);
    }
  };

  const prepareFormData = (data: Pricing.PricingTransport) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData.prePaidRentalDaysDiscountRate = newData.prePaidRentalDaysDiscountRate / 100;
    newData.tripCharge = newData.tripCharge;
    newData.tripChargeDollars = newData.tripCharge / 100;
    newData.prohibitedItems = newData.prohibitedItems.join(', ');
    delete newData?.pricingData;
    return newData;
  };

  ////////////////////////////////////////////////////////
  // RENDER SECTION
  ///////////////////////////////////////////////////////

  return (
    <form className="m-4 space-y-6" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="container space-y-4">
        <div className="flex flex-col space-y-4">
          <div>
            <Controller
              control={control}
              name="zoneName"
              render={({ field }) => <TextField inputProps={{ ...field }} label="Zone name" required />}
            />
          </div>
          <div>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Select
                  label="State"
                  required
                  onSelect={(value) => {
                    field.onChange(value);
                  }}
                  value={field.value}
                >
                  {statesAbb.map((item) => (
                    <SelectOption key={`state-${item}`} value={item}>
                      {item}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <Controller
            name="currentGrowthStage"
            control={control}
            render={({ field }) => (
              <Select
                label="Zone Stage"
                onSelect={(value) => {
                  field.onChange(value);
                }}
                value={field.value}
              >
                {Object.entries(growthStageTypes).map((item) => (
                  <SelectOption key={`growth-stage-${item[0]}`} value={item[0]}>
                    {item[1]}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="flex flex-col space-y-4">
          <Controller
            name="metro"
            control={control}
            render={({ field }) => (
              <Select
                label="Metro"
                onSelect={(value) => {
                  field.onChange(value);
                }}
                value={field.value}
              >
                {Object.entries(pricingMetros).map((item) => (
                  <SelectOption key={`metro-${item[0]}`} value={item[0]}>
                    {item[1]}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="flex flex-col space-y-4">
          <div className="text-2xl">Zone Pricing</div>
        </div>
        <div className="flex flex-col space-y-4 md:w-1/3">
          <Controller
            name="tripCharge"
            control={control}
            render={({ field }) => (
              <CurrencyTextField required useCents label="Trip charge" variant="standard" {...field} />
            )}
          />
        </div>
        <div className="pb-4 flex flex-col space-x-4 md:flex-row">
          <Controller
            name="rentalPeriod.value"
            control={control}
            render={({ field }) => (
              <TextField
                label="Rental period"
                type="number"
                required
                inputProps={{ ...field, onChange: (e) => field.onChange(e.target.value) }}
              />
            )}
          />

          <Controller
            name="rentExtensionFee"
            control={control}
            render={({ field }) => (
              <CurrencyTextField required useCents label="Rental Extension Fee" variant="standard" {...field} />
            )}
          />

          <Controller
            name="maxRentalDaysAllowed"
            control={control}
            render={({ field }) => (
              <TextField
                label="Max rental days allowed"
                type="number"
                required
                inputProps={{ ...field, onChange: (e) => field.onChange(e.target.value) }}
                helperText="The longest a dumpster can stay out without needing a switch"
              />
            )}
          />
        </div>
        <div className="flex flex-col space-y-4">
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <TextField label="Notes" inputProps={{ value: field.value, onChange: field.onChange }} />
            )}
          />
        </div>
        {haulerPricingZones.length > 0 && mode === 'CREATE' && (
          <>
            <Controller
              control={control}
              name="copyExistingZone"
              render={({ field }) => (
                <Select
                  label="Copy existing zone"
                  value={field.value}
                  onSelect={(value) => {
                    field.onChange(value || '');
                  }}
                  helperText="Optional: Select existing zone to copy over pricing from that zone"
                  renderLabel={(value) => {
                    if (typeof value === 'string') {
                      return value ? `${haulerPricingZones?.find((item) => item.id === value)?.zoneName} ` : '';
                    }
                    return '';
                  }}
                >
                  {haulerPricingZones?.map((item, index) => (
                    <SelectOption key={`zone-${item.zoneName}-${index}`} value={item.id}>
                      {item.zoneName}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </>
        )}
        <br />
        <div className="flex flex-col ">
          <h2 className="text-2xl">Prohibited Items</h2>
          <p className="text-sm italic">Write as a comma separated list</p>
          <br />
          <Controller
            name="prohibitedItems"
            control={control}
            render={({ field }) => <TextField label="Prohibited items" inputProps={{ ...field }} />}
          />
        </div>
        <div className="flex flex-col ">
          <h2 className="text-2xl ">For Online Checkout</h2>
        </div>
        <div className="flex flex-col ">
          <p className="text-md">
            IMPORTANT: You need to allow each material for this zone to be accessible for online checkout. The below
            fields apply to all materials in this zone.
          </p>
        </div>
        <div className="flex flex-col ">
          <Controller
            name="onlineCheckoutPageGuarantee"
            control={control}
            render={({ field }) => (
              <Select
                label="Online Checkout Guarantee Text"
                onSelect={(value) => {
                  field.onChange(value);
                }}
                value={field.value}
              >
                {Object.entries(onlineGuaranteeTypes).map((item) => (
                  <SelectOption key={`online-checkout-guarantee-${item[0]}`} value={item[0]}>
                    {item[1]}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="flex flex-col md:flex-row space-x-4 justify-center items-center ">
          <Controller
            name="allowForPrePaidRentalDays"
            control={control}
            render={({ field }) => (
              <Checkbox
                label="Allow Pre-Paying for Rental Days"
                inputProps={{
                  checked: field.value,
                  onChange: (e) => field.onChange(e.target.checked),
                }}
              />
            )}
          />

          <Controller
            name="prePaidRentalDaysDiscountRate"
            control={control}
            render={({ field }) => (
              <TextField
                label="Pre-Paid Rental Day Discount"
                type="number"
                required
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                inputProps={{ ...field, onChange: (e) => field.onChange(e.target.value) }}
                helperText="The discount to apply for pre purchasing rental days."
              />
            )}
          />
        </div>

        <div className="flex flex-col md: w-1/2">
          <h2 className="text-2xl">Operating Days</h2>
        </div>

        <div>
          {formValues.days && (
            <div className="m-0 flex flex-col  space-y-4 justify-between items-start">
              {Object.keys(formValues.days).map((key, i) => (
                <React.Fragment key={i}>
                  <div className="flex space-y-4 ">
                    <div className="flex flex-row justify-start items-center w-full ">
                      <div className="w-[100px]">{key}</div>
                      <Controller
                        //@ts-expect-error -this is correct
                        name={`days.${key}`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            label="Operating"
                            onSelect={(value) => {
                              field.onChange(value);
                            }}
                            value={field.value}
                          >
                            {Object.entries(operatingDayTypes).map((item) => (
                              <SelectOption key={`day-${item[0]}`} value={item[0]}>
                                {item[1]}
                              </SelectOption>
                            ))}
                          </Select>
                        )}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>

      <hr className="my-4" />
      <div className="flex justify-end gap-4">
        <Button className="btn-dark-grey-outlined" type="button" onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button className="btn-primary" type="submit" disabled={isLoading}>
          {pricing ? 'Update' : 'Create'}
          {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
        </Button>
      </div>
    </form>
  );
};

export default PricingForm;
