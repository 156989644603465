import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { InternalTicket } from '@alliance-disposal/transport-types';
import { Button, Menu, MenuItem, TextField, Tooltip } from '@wayste/sour-ui';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { UIContext } from '../../contexts';
import { InternalTicketContext } from './context';

type ManageTagsProps = {
  tags: InternalTicket.TagTransport[];
  /**
   * Passing in a ticketID will allow for tags to be added and removed from the ticket via Wayste Client
   */
  ticketID?: string;
  onAddTag?: (tag: InternalTicket.TagTransport) => void;
  onRemoveTag?: (tag: InternalTicket.TagTransport) => void;
  readOnly?: boolean;
  disabled?: boolean;
};

export const ManageTags = ({ tags, ticketID, onAddTag, onRemoveTag, readOnly, disabled }: ManageTagsProps) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const { fetchInternalTickets } = useContext(InternalTicketContext);
  const [allTags, setAllTags] = useState<InternalTicket.TagTransport[]>([]);
  const [filteredTags, setFilteredTags] = useState<InternalTicket.TagTransport[]>([]);

  const handleGetTags = async () => {
    try {
      const response = await client.internalTicket().adminPortal.tag.query({ deletedAt: false });
      setAllTags(response.results);
      setFilteredTags(response.results);
    } catch (error) {
      console.warn('Error fetching tags: ', error);
      showFlash('Error fetching tags', 'error');
    }
  };

  useEffect(() => {
    if (!readOnly && !disabled) {
      handleGetTags();
    }
  }, []);

  const handleAdd = async (tag: InternalTicket.TagTransport) => {
    // Don't allow for duplicate tags to be added.
    if (tags.find((existingTag) => existingTag.id === tag.id)) {
      return;
    }
    if (ticketID) {
      const updatedTags = [...tags, tag].map((tagItem) => tagItem.id);
      try {
        await client.internalTicket().adminPortal.update(ticketID, { tags: updatedTags });
        fetchInternalTickets();
      } catch (error) {
        console.warn('Error adding tag: ', error);
        showFlash('Error adding tag', 'error');
      }
    }
    if (onAddTag) {
      onAddTag(tag);
    }
  };

  const handleRemove = async (tag: InternalTicket.TagTransport) => {
    if (ticketID) {
      const updatedTags = tags.filter((existingTag) => existingTag.id !== tag.id).map((tagItem) => tagItem.id);
      try {
        await client.internalTicket().adminPortal.update(ticketID, { tags: updatedTags });
        fetchInternalTickets();
      } catch (error) {
        console.warn('Error removing tag: ', error);
        showFlash('Error removing tag', 'error');
      }
      fetchInternalTickets();
    }
    if (onRemoveTag) {
      onRemoveTag(tag);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value.toLowerCase();
    const newFilteredObjects = allTags.filter((tag) => tag.name.toLowerCase().includes(searchText));
    setFilteredTags(newFilteredObjects);
  };

  return (
    <div className="flex gap-1 items-center">
      {tags.map((tag) => (
        <Tooltip text={tag.description} key={tag.id}>
          <div
            className="py-1 px-2 rounded flex items-center text-xs gap-1 border border-black"
            style={{ backgroundColor: tag.backgroundColor || 'white', color: tag.textColor || 'black' }}
          >
            {tag.name}
            {!readOnly && (
              <XMarkIcon
                className="h-5 w-5 cursor-pointer"
                onClick={() => {
                  if (!disabled) handleRemove(tag);
                }}
              />
            )}
          </div>
        </Tooltip>
      ))}
      {!readOnly && (
        // @ts-expect-error - TODO the type of <Menu> needs to be reworked to be able to handle <MenuItem> + ...map(...<MenuItem>)
        <Menu
          button={
            <Button
              endIcon={<PlusCircleIcon className="h-5 w-5" />}
              className="btn-secondary-text-only"
              disabled={disabled}
            >
              Tag
            </Button>
          }
        >
          <MenuItem onClick={(e) => e.preventDefault()}>
            <TextField
              inputProps={{
                onChange: (e) => handleInputChange(e),
              }}
            />
          </MenuItem>
          {filteredTags.map((tagOption) => (
            <MenuItem key={tagOption.id} onClick={() => handleAdd(tagOption)}>
              {tagOption.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};
