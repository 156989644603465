import React, { useEffect, useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { Pricing } from '@alliance-disposal/transport-types';
import { buildAddressString } from '@wayste/utils';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { statesDetails } from '../../utils/state-details';
import GoogleStreetView from '../GoogleStreetView';
import InputAddress from '../InputAddress';
import OrderPricingFields from '../OrderPricingFields';
import Radios from '../ui/inputs/Radios';

const trimmedAddressListLength = 5;

export interface OrderFormProps {
  values: any;
  setFieldValue: any;
  setValues: any;
  zonePricing: Pricing.PricingTransport | null;
  onGetPricing: (
    loc: {
      lat: number;
      lng: number;
      zip?: string;
      state: string;
    },
    customerID?: string,
  ) => void;
  handleBlur: any;
  disabled: boolean;
  existingCustomer: Customer.AllianceCustomerTransport | null;
}

const OrderForm = ({
  values,
  setFieldValue,
  setValues,
  zonePricing,
  onGetPricing,
  handleBlur,
  disabled,
  existingCustomer,
}: OrderFormProps) => {
  const [addressRadio, setAddressRadio] = useState<string>('new');
  const [addressOptions, setAddressOptions] = useState<any>([{ value: 'new', label: 'Enter address', obj: {} }]);
  const [displayAddressOptions, setDisplayAddressOptions] = useState<any>([
    { value: 'new', label: 'Enter address', obj: {} },
  ]);
  const [streetViewAddress, setStreetViewAddress] = useState(null);

  useEffect(() => {
    if (values.serviceLocations) {
      const newAddressOptions: any[] = [];
      const selectedAddress = buildAddressString(values.serviceLocation.address);
      values.serviceLocations.forEach((item: any) => {
        const addressString = buildAddressString(item.address);
        if (selectedAddress === addressString) {
          setAddressRadio(addressString);
        }
        newAddressOptions.push({
          value: addressString,
          label: addressString,
          obj: item,
        });
      });
      const sortedAddresses = newAddressOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

      // DE DUPE ADDRESSES BASED ON LABELS
      // const addressSet = new Set();
      // const filteredOptions: any[] = [];

      // sortedAddresses.forEach((item: any) => {
      //   if (addressSet.has(item.label)) {
      //     return;
      //   }
      //   addressSet.add(item.label);
      //   filteredOptions.push(item);
      // });

      const allOptions = [{ value: 'new', label: 'Enter address', obj: {} }, ...sortedAddresses];

      setAddressOptions(allOptions);
      if (sortedAddresses.length <= trimmedAddressListLength) {
        setDisplayAddressOptions(allOptions);
      } else {
        setDisplayAddressOptions(allOptions.filter((_, index) => index <= trimmedAddressListLength));
      }
    }

    if (values.taxExempt) setFieldValue('tax', false);
  }, [values.serviceLocations]);

  const handleAddressChange = (
    county: string,
    address: any,
    taxRate: number,
    coords?: {
      lat: number;
      lng: number;
    },
  ) => {
    if (coords) {
      onGetPricing({ ...coords, zip: address.zip, state: address.state }, values.id);
    }

    const obj = {
      ...values,
      serviceLocation: {
        ...values.serviceLocation,
        county,
        address: {
          addressLine1: address.addressLine1 || '',
          addressLine2: address.addressLine2 || '',
          city: address.city || '',
          state: address.state || '',
          zip: address.zip || '',
        },
      },
      taxRate: taxRate,
    };
    if (coords) {
      obj.serviceLocation.coordinates = coords;
    } else {
      alert('No coords get an AAP Dev');
    }
    setValues(obj);
  };

  const handleRadioAddressSelect = (value: any) => {
    console.log('value: ', value);
    if (value === 'new') {
      handleAddressChange(
        '',
        {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          zip: '',
        },
        0,
      );
    } else {
      const selectedAddress = addressOptions.find((item: any) => item.value === value);
      console.log('addressOptions: ', addressOptions);

      console.log('selectedAddress: ', selectedAddress);
      if (selectedAddress) {
        console.log('customer: ss ', selectedAddress);
        handleAddressChange(
          selectedAddress?.obj?.county,
          selectedAddress.obj.address,
          +statesDetails[selectedAddress?.obj?.address.state as keyof typeof statesDetails].salesTaxRate,
          selectedAddress.obj.coordinates,
        );
      }
    }
    console.log('value: set radio ', value);
    setAddressRadio(value);
  };

  // useEffect(() => {
  //   handleRadioAddressSelect(addressRadio);
  // }, [addressRadio]);

  const handleServiceAddressLength = () => {
    if (displayAddressOptions.length === addressOptions.length) {
      setDisplayAddressOptions(addressOptions.filter((_: any, index: number) => index <= trimmedAddressListLength));
    } else {
      setDisplayAddressOptions(addressOptions);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Typography variant="h6">Service Address</Typography>
        </Grid>
        <Grid item xs={12}>
          <Radios options={displayAddressOptions} onChange={handleRadioAddressSelect} />
          {addressOptions.length > trimmedAddressListLength + 1 ? (
            <div>
              <button className="btn-secondary-text-only" onClick={handleServiceAddressLength} type="button">
                Show {displayAddressOptions.length === addressOptions.length ? 'Fewer' : 'All'}
                {' Addresses'}
              </button>
            </div>
          ) : null}
        </Grid>
        {addressRadio === 'new' ? (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <InputAddress
                  name="serviceLocation.address"
                  google
                  required
                  latLng
                  disabled={Boolean(disabled)}
                  onSelect={(obj: any) => {
                    console.log('obj adddress: ', obj);
                    handleAddressChange(
                      obj.county,
                      obj.address,
                      +statesDetails[obj.address.state as keyof typeof statesDetails].salesTaxRate,
                      obj.coords,
                    );
                    setStreetViewAddress(obj.address);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {streetViewAddress && <GoogleStreetView address={streetViewAddress} />}
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Typography variant="h6">Pricing Info</Typography>
        </Grid>
      </Grid>
      <OrderPricingFields
        setFieldValue={setFieldValue}
        values={values}
        setValues={setValues}
        handleBlur={handleBlur}
        zonePricing={zonePricing}
        disabled={Boolean(disabled)}
        existingCustomer={existingCustomer}
      />
    </>
  );
};

export default OrderForm;
