import React, { useEffect, useState } from 'react';
import { StarIcon as StarOutlineIcon } from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/24/solid';

type Props = {
  rating: number;
  outOf: number;
  onChange?: any;
  fontSize?: 'small' | undefined;
};

const defaultProps = {
  rating: null,
  outOf: 5,
  onChange: null,
  fontSize: undefined,
};

const StarRating = ({ rating, outOf, onChange, fontSize }: Props) => {
  const [stars, setStars] = useState<number[]>([]);
  const [changeableRating, setChangeableRating] = useState(rating || 0);
  const [hovered, setHovered] = useState(0);
  const [tailwindSize, setTailwindSize] = useState('h-6 w-6');

  useEffect(() => {
    if (fontSize === 'small') {
      setTailwindSize('h-5 w-5');
    } else {
      setTailwindSize('h-6 w-6');
    }
  }, [fontSize]);

  useEffect(() => {
    const newStars = Array.from({ length: outOf }, (_, index) => index + 1);
    setStars(newStars);
  }, [outOf]);

  const handleChangeRating = (newRating: number) => {
    setChangeableRating(newRating);
    onChange(newRating);
  };

  const handleHoverRating = (hoveredRating: number) => {
    setHovered(hoveredRating);
  };

  useEffect(() => {
    setChangeableRating(rating);
  }, [rating]);

  if (rating === null && !onChange) return null;

  if (onChange)
    return (
      <div className="flex text-lime-500">
        {stars.map((star) => (
          <div
            key={star}
            style={{ cursor: 'pointer' }}
            onClick={() => handleChangeRating(star)}
            onMouseEnter={() => handleHoverRating(star)}
            onMouseLeave={() => handleHoverRating(0)}
          >
            {changeableRating < star ? (
              hovered < star ? (
                <StarOutlineIcon className={tailwindSize} />
              ) : (
                <StarIcon className={tailwindSize} />
              )
            ) : (
              <StarIcon className={tailwindSize} />
            )}
          </div>
        ))}
      </div>
    );
  return (
    <div className="flex text-lime-500">
      {stars.map((star) =>
        rating < star ? (
          <StarOutlineIcon key={star} className={tailwindSize} />
        ) : (
          <StarIcon key={star} className={tailwindSize} />
        ),
      )}
    </div>
  );
};

StarRating.defaultProps = defaultProps;

export default StarRating;
