import React, { useContext, useEffect, useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { Loading } from '@wayste/sour-ui';
import { PlusIcon } from '@heroicons/react/24/solid';
import { retrieveCustomer } from '../../../axios/stripev2';
import DetailsCardWrapper from '../../../components/ui/DetailsCardWrapper';
import { UIContext } from '../../../contexts';
import { useAuthToken } from '../../../hooks/authhook';

type CustomerCardsProps = {
  customer: Customer.AllianceCustomerTransport;
};

const CustomerCards = ({ customer }: CustomerCardsProps) => {
  const { showFlash } = useContext(UIContext);
  const { token } = useAuthToken();
  const [stripeData, setStripeData] = useState<any>(null);
  const [stripeLoading, setStripeLoading] = useState(false);

  useEffect(() => {
    if (customer.stripeId) getStripeData(customer.stripeId);
  }, []);

  const getStripeData = async (stripeId: string) => {
    setStripeLoading(true);
    try {
      const data = await retrieveCustomer(stripeId, token, true);
      setStripeData(data);
      setStripeLoading(false);
    } catch (error) {
      console.error('Stripe Data Error: ', error);
      showFlash('An Error Occurred', 'warning');
      setStripeLoading(false);
    }
  };

  return (
    <DetailsCardWrapper
      heading="Cards"
      buttons={[
        {
          label: (
            <>
              <PlusIcon className="h-5 w-5 mr-1" /> Add Card
            </>
          ),
          onClick: () => alert('Add Card'),
        },
      ]}
    >
      {stripeLoading ? (
        <div className="w-full flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <div style={{ margin: '-12px -20px -20px' }}>
          {stripeData?.sources?.data?.map((source: any, index: number) => {
            let card = null;
            if (source.card) card = source.card;
            if (card)
              return (
                <div
                  key={source.id}
                  style={{
                    padding: '8px 20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: index !== stripeData.sources.data.length - 1 ? 'solid 1px #D8D8D8' : '',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <div>{card.brand}</div>
                    <div style={{ padding: '0 16px' }}>•••• {card.last4}</div>
                    <div>
                      {card.exp_month}/{card.exp_year}
                    </div>
                  </div>

                  <div style={{ display: 'flex' }}>
                    <div>
                      {source.id === stripeData.default_source ? (
                        <span
                          style={{
                            color: '#3D4EAC',
                            backgroundColor: '#D6ECFF',
                            padding: '1px 8px',
                            borderRadius: 10,
                            fontSize: 12,
                          }}
                        >
                          Default
                        </span>
                      ) : (
                        <span className="cursor-pointer text-wayste-blue-400">Set as default</span>
                      )}
                    </div>
                    <div className="px-4 text-edit cursor-pointer">Edit</div>
                    <div className="text-delete cursor-pointer">Delete</div>
                  </div>
                </div>
              );
            return null;
          })}
        </div>
      )}
    </DetailsCardWrapper>
  );
};

export default CustomerCards;
