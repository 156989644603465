import { useWaysteClient } from '@alliance-disposal/client';
import { InternalTicket, Profile } from '@alliance-disposal/transport-types';
import { Button, Menu, MenuItem } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { Disclosure } from '@headlessui/react';
import { ArrowUturnLeftIcon, PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { ManageTags } from './ManageTags';
import StatusMenu from './StatusMenu';

type InternalTicketCardProps = {
  ticket: InternalTicket.InternalTicketTransport;
  onReplyClick: (ticketID: string) => void;
  onEditClick: (ticket: InternalTicket.InternalTicketTransport) => void;
  onRedactClick: (ticketID: string) => void;
  isReply?: boolean;
};

export const InternalTicketCard = ({
  ticket,
  onReplyClick,
  isReply,
  onEditClick,
  onRedactClick,
}: InternalTicketCardProps) => {
  const client = useWaysteClient();
  const rosterMap = client.profile().adminPortal.getRosterFromMemory().idToProfileMap;

  return (
    <Disclosure defaultOpen={!Boolean(ticket.deletedAt)}>
      {Boolean(ticket.deletedAt) && (
        <Disclosure.Button as="div" className="btn-secondary-text-only !justify-start !text-red-500">
          Show Redacted Message
        </Disclosure.Button>
      )}
      <Disclosure.Panel>
        <div className="flex flex-col gap-y-1.5" style={{ color: Boolean(ticket.deletedAt) ? 'red' : undefined }}>
          <div className="flex gap-2 items-center">
            <div className="rounded-full bg-slate-600 text-white w-6 h-6 flex items-center justify-center text-xs uppercase">
              {rosterMap[ticket.metadata.createdByUserID]?.firstName?.charAt(0)}
              {rosterMap[ticket.metadata.createdByUserID]?.lastName?.charAt(0)}
            </div>
            <div>
              {rosterMap[ticket.metadata.createdByUserID]?.firstName}{' '}
              {rosterMap[ticket.metadata.createdByUserID]?.lastName}
            </div>
            <div>-</div>
            <div>{format(new Date(ticket.metadata.createdAt), 'EEE MM/dd/yy hh:mm a')}</div>
          </div>
          <div className="flex flex-col md:flex-row gap-2">
            <div className="flex gap-2">
              <div>Assigned to:</div>
              {/* assigned team */}
              <div>
                {ticket.assignedTeam &&
                  Profile.SourgumTeamLabels[ticket.assignedTeam as keyof typeof Profile.SourgumTeamLabels]}
              </div>
              <div>-</div>
              <div>
                {/* assigned to profile */}
                {ticket.assignedToProfileID &&
                  rosterMap[ticket.assignedToProfileID]?.firstName +
                    ' ' +
                    rosterMap[ticket.assignedToProfileID]?.lastName}
              </div>
            </div>
            {(ticket.issueCausedByProfileID || ticket.monetaryLoss) && (
              <>
                <div className="hidden md:block">|</div>
                <div className="flex gap-2">
                  <div>Issue caused by:</div>
                  <div>
                    {ticket.issueCausedByProfileID &&
                      rosterMap[ticket.issueCausedByProfileID]?.firstName +
                        ' ' +
                        rosterMap[ticket.issueCausedByProfileID]?.lastName}
                  </div>
                  <div>{moneyFormatter(ticket.monetaryLoss || 0)}</div>
                </div>
              </>
            )}
          </div>
          <div>
            <ManageTags tags={ticket.tags} ticketID={ticket.id} disabled={Boolean(ticket.deletedAt)} />
          </div>
          <div>{ticket.note}</div>
          <div className="flex gap-2 justify-end items-center">
            <Menu
              button={
                <Button className="btn-icon" disabled={Boolean(ticket.deletedAt)}>
                  <EllipsisHorizontalIcon className="h-6 w-6" />
                </Button>
              }
            >
              <MenuItem className="flex items-center gap-1 [&>*]:text-edit" onClick={() => onEditClick(ticket)}>
                <PencilIcon className="h-5 w-5" /> <span>Edit</span>
              </MenuItem>
              <MenuItem className="flex items-center gap-1 [&>*]:text-delete" onClick={() => onRedactClick(ticket.id)}>
                <TrashIcon className="h-5 w-5" /> <span>Redact</span>
              </MenuItem>
            </Menu>
            {!isReply && (
              <Button
                startIcon={<ArrowUturnLeftIcon className="h-5 w-5" />}
                className="btn-secondary-text-only"
                onClick={() => onReplyClick(ticket.id)}
                disabled={Boolean(ticket.deletedAt)}
              >
                Reply
              </Button>
            )}
            {/* status */}
            <StatusMenu
              status={ticket.status}
              disabled={Boolean(ticket.deletedAt) || (!ticket.assignedToProfileID && !ticket.assignedTeam)}
              ticketID={ticket.id}
            />
          </div>
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
};
