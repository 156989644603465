import React from 'react';
import TextField from '@mui/material/TextField';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const defaultProps = {
  disabled: false,
  error: false,
  label: 'Google autocomplete address',
  required: false,
  latLng: false,
};

type Props = {
  onChange: any;
  onSelect: any;
  value: string;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  onBlur?: any;
  latLng?: boolean;
  required?: boolean;
  searchOptions?: any;
  textFieldProps?: any;
};

const InputGooglePlaces = ({
  onChange,
  onSelect,
  value,
  disabled,
  error,
  label,
  onBlur,
  latLng,
  required,
  searchOptions,
  textFieldProps,
}: Props) => {
  const handleSelect = async (address: any) => {
    try {
      const results = await geocodeByAddress(address);
      let coordinates = null;
      if (latLng) {
        try {
          const coordResults = await getLatLng(results[0]);
          const { lat, lng } = coordResults;
          coordinates = { lat, lng };
        } catch (error) {
          console.warn(error);
          alert('Lat Lng Error open console and send to AAP Dev');
        }
      }
      onSelect(results[0], coordinates);
    } catch (error) {
      console.error('GooglePlaces Error', error);
      alert('Geocode Address Error open console and send to AAP Dev');
    }
  };

  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChange}
      onSelect={handleSelect}
      searchOptions={{
        ...searchOptions,
        componentRestrictions: { country: 'us' },
      }}
      onError={(err) => console.warn('PlacesAutocomplete component error', err)}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: 'relative' }}>
          <TextField
            // margin='dense'
            fullWidth
            label={label}
            required={required}
            error={error}
            helperText={error ? 'Invalid address' : null}
            {...getInputProps({
              onBlur: onBlur ? onBlur : null,
              autoComplete: 'nope',
              disabled: disabled,
            })}
            {...textFieldProps}
          />
          <div
            style={{
              boxShadow: '0 1px 3px rgba(0, 0, 0, 0.5)',
              borderRadius: 4,
              margin: '0 3px',
              zIndex: 999,
              backgroundColor: '#FFFFFF',
              position: 'absolute',
              width: '100%',
            }}
          >
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    style,
                  })}
                  style={{ padding: 10, cursor: 'pointer' }}
                  key={suggestion.placeId + index}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

InputGooglePlaces.defaultProps = defaultProps;

export default InputGooglePlaces;
