import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Invoice, Order } from '@alliance-disposal/transport-types';
import structuredClone from '@ungap/structured-clone';
import { UIContext, useConfirmationDialog } from '../../../../contexts';
import { ccRate } from '../../../../utils/pricing-utils';
import { EditInvoiceFormProps } from './EditModal';
import InvoiceUpdate, { InvoiceType } from './InvoiceUpdate';

interface Props {
  selectedOrder: Order.AllianceOrderTransport | null;
  customer: Customer.AllianceCustomerTransport | null;
  isLoading: boolean;
  // onOrderSave: () => void;
  onShowDetails: () => void;
  onCancel: () => void;
  onCreateReceipt: (invoice: Invoice.ReceivableTransport) => void;
  onAddPayment: (view: InvoiceType, haulerID?: string) => void;

  payables: Invoice.PayableTransport[];
  receivables: Invoice.ReceivableTransport[];

  setPayables: (payables: Invoice.PayableTransport[]) => void;
  setReceivables: (payables: Invoice.ReceivableTransport[]) => void;

  setReceivable: (
    receivable: Invoice.ReceivableTransport,
    index?: number,
    options?: {
      isAPIResponse?: boolean;
    },
  ) => void;
  setPayable: (
    receivable: Invoice.PayableTransport,
    index?: number,
    options?: {
      isAPIResponse?: boolean;
    },
  ) => void;

  hasChanges: boolean;
  payablesChanges: number[];
  receivablesChanges: number[];
  handleRefresh: () => void;
}

const InvoiceUpdateContainer = ({
  selectedOrder,
  customer,
  isLoading,
  onCreateReceipt,
  onShowDetails,
  onAddPayment,
  onCancel,
  payables,
  receivables,
  hasChanges,
  setReceivable,
  setReceivables,
  setPayable,
  setPayables,
  payablesChanges,
  receivablesChanges,
  handleRefresh,
}: Props) => {
  const client = useWaysteClient();
  const { getConfirmation } = useConfirmationDialog();
  const { showFlash } = useContext(UIContext);
  const [order, setOrder] = useState<Order.AllianceOrderTransport | null>(selectedOrder);
  const [orderCopy, setOrderCopy] = useState<any>(structuredClone(selectedOrder));
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setOrder(selectedOrder);
    /* const newOrder = structuredClone(selectedOrder);
    setOrderCopy(newOrder);
    checkOrdersMatch(selectedOrder, newOrder); */
  }, [selectedOrder]);

  // ^^^^^^^^^^^^^^^^^
  // wtf is this doing

  const handleGetHaulerName = async (index: number): Promise<string> => {
    //  loop through everything and make sure the correct hauler name is on every bill and the order
    const haulerResponse = await client.vendorService().adminPortal.fetch(payables[index].haulerID);
    return haulerResponse.name || '';
  };

  const handleUpdate = (newOrder: Order.AllianceOrderUpdateInput) => {
    // newOrder.invoices = newOrder.invoices.map((invoice: any) => {
    //   return updateCalculatedFields(invoice);
    // });
    // newOrder.bills = newOrder.bills.map((bill: any) => {
    //   return updateCalculatedFields(bill);
    // });

    setOrderCopy({ ...orderCopy, ...newOrder });
  };

  const handleOnRefund = (invoice: Invoice.ReceivableUpdateTransport) => {
    handleSaveReceivable(invoice as Invoice.ReceivableTransport);
    handleRefresh();
  };

  const handleUploadQB = () => {
    // onOrderSave();s
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChangeTax = async (taxRate: number, receivableIndex: number) => {
    const receivable = JSON.parse(JSON.stringify(receivables[receivableIndex])) as Invoice.ReceivableTransport;
    receivable.invoiceDetails.taxRate = taxRate;

    const keepTotal = await getConfirmation({
      title: 'Keep Current Invoice Total?',
      message:
        'Do you want to keep the existing invoice total as is OR do you want to change the total to reflect the new CC Fee?',
      confirmText: 'Keep Total',
      cancelText: 'Change Total',
    });
    if (keepTotal) {
      const currentTotal = receivable.invoiceDetails.total;
      const totalWithoutTax = receivable.invoiceDetails.lineItems.reduce((total, lineItem) => {
        return total + (lineItem.totalPrice || (lineItem.unitPrice || 0) * lineItem.quantity);
      }, 0);
      const taxableTotal = receivable.invoiceDetails.lineItems.reduce((total, lineItem) => {
        if (!lineItem.taxable) return total;
        return total + (lineItem.totalPrice || (lineItem.unitPrice || 0) * lineItem.quantity);
      }, 0);

      const difference = currentTotal - totalWithoutTax;
      const adjustmentRate = difference / taxableTotal + 1;

      if (taxRate === 0) {
        // tax is being set to 0 & line items need to increase proportionally
        const newLineItems = receivable.invoiceDetails.lineItems.map((lineItem) => {
          if (!lineItem.taxable) return lineItem;

          const newTotalPrice = (lineItem.totalPrice || 0) * adjustmentRate;

          return {
            ...lineItem,
            unitPrice: Math.round(newTotalPrice / lineItem.quantity),
            totalPrice: Math.round(newTotalPrice),
          };
        });

        receivable.invoiceDetails.lineItems = newLineItems;

        setReceivable(receivable, receivableIndex);
        return;
      }

      // tax is being set to the taxRate have to decrease line items

      const newLineItems = receivable.invoiceDetails.lineItems.map((lineItem) => {
        if (!lineItem.taxable) return lineItem;

        const newTotalPrice = (lineItem.totalPrice || 0) / (1 + taxRate);

        return {
          ...lineItem,
          unitPrice: Math.round(newTotalPrice / lineItem.quantity),
          totalPrice: Math.round(newTotalPrice),
        };
      });

      receivable.invoiceDetails.lineItems = newLineItems;
      setReceivable(receivable, receivableIndex);
    }

    // remove tax from invoice without keeping the total
    if (taxRate === 0) {
      receivable.invoiceDetails.taxRate = 0;
      receivable.invoiceDetails.taxAmount = 0;

      setReceivable(receivable, receivableIndex);
      return;
    }

    // tax rate is not 0 so we need to calculate the tax amount and add it to the invoice
    const taxableTotal = receivable.invoiceDetails.lineItems.reduce((total, lineItem) => {
      if (!lineItem.taxable) return total;

      return total + (lineItem.totalPrice || (lineItem.unitPrice || 0) * lineItem.quantity);
    }, 0);

    const taxAmount = taxableTotal * taxRate;

    receivable.invoiceDetails.taxRate = taxRate;
    receivable.invoiceDetails.taxAmount = Math.ceil(taxAmount);

    setReceivable(receivable, receivableIndex);
  };

  const handleChangeCC = async (value: number, invoiceIndex: number) => {
    if (!order) return;

    // grab the invoice we are editing
    const receivable = JSON.parse(JSON.stringify(receivables[invoiceIndex])) as Invoice.ReceivableTransport;

    const keepTotal = await getConfirmation({
      title: 'Keep Current Invoice Total?',
      message:
        'Do you want to keep the existing invoice total as is OR do you want to change the total to reflect the new CC Fee?',
      confirmText: 'Keep Total',
      cancelText: 'Change Total',
    });

    if (!keepTotal) {
      if (value === 0) {
        receivable.invoiceDetails.lineItems = receivable.invoiceDetails.lineItems.filter(
          (item) => item.itemName !== 'CC Fee',
        );
      } else if (value === ccRate && !receivable.invoiceDetails.lineItems.find((item) => item.itemName === 'CC Fee')) {
        const calculatedCCPrice = receivable.invoiceDetails.total / (1 - ccRate) - receivable.invoiceDetails.total;

        // save new line item and add it to invoice

        // updatedInvoice.invoiceDetails.lineItems = [
        //   ...updatedInvoice.invoiceDetails.lineItems,
        //   new LineItemModel({
        //     itemName: 'CC Fee',
        //     description: '',
        //     quantity: 1,
        //     unitPriceDollars: calculatedCCPrice,
        //     totalPriceDollars: calculatedCCPrice,
        //     taxable: false,
        //   }),
        // ];

        receivable.invoiceDetails.lineItems.push({
          description: '',
          itemName: 'CC Fee',
          quantity: 1,
          taxable: false,
          totalPrice: calculatedCCPrice,
          unitPrice: calculatedCCPrice,
          id: '',
        });
      }

      setReceivable(receivable, invoiceIndex);
    } else if (keepTotal) {
      const currentTotal = receivable.invoiceDetails.total;

      if (value === 0) {
        const ccLineItem = receivable.invoiceDetails.lineItems.find((item) => item.itemName === 'CC Fee');
        // Never had a CC Fee
        if (!ccLineItem) return;
        const creditCardFee = ccLineItem.totalPrice || 0;
        receivable.invoiceDetails.lineItems = receivable.invoiceDetails.lineItems.filter(
          (item) => item.itemName !== 'CC Fee',
        );

        const taxRate = receivables[invoiceIndex].invoiceDetails.taxRate || 0;
        const totalWithoutCreditCard = currentTotal - creditCardFee;

        const newLineItems = receivable.invoiceDetails.lineItems.map((lineItem) => {
          let proportion;
          let newTotalPrice;
          if (lineItem.taxable) {
            const adjustedTotal = Math.round((lineItem.totalPrice || 0) * (1 + taxRate));
            proportion = adjustedTotal / totalWithoutCreditCard;

            const newAdjustedTotal = Math.round(adjustedTotal + proportion * creditCardFee);
            newTotalPrice = Math.round(newAdjustedTotal / (1 + taxRate));
          } else {
            proportion = (lineItem.totalPrice || 0) / totalWithoutCreditCard;
            newTotalPrice = (lineItem.totalPrice || 0) + Math.round(proportion * creditCardFee);
          }
          return {
            ...lineItem,
            unitPrice: Math.round(newTotalPrice / lineItem.quantity),
            totalPrice: newTotalPrice,
          };
        });

        receivable.invoiceDetails.lineItems = newLineItems;
      } else {
        const calculatedCCFee = Math.round(currentTotal * ccRate);
        const newLineItems = receivable.invoiceDetails.lineItems.map((lineItem) => {
          const newTotalPrice = (lineItem.totalPrice || 0) - (lineItem.totalPrice || 0) * ccRate;
          return {
            ...lineItem,
            unitPrice: newTotalPrice / lineItem.quantity,
            totalPrice: newTotalPrice,
          };
        });

        // save new line item and add it to invoice
        newLineItems.push({
          description: '',
          itemName: 'CC Fee',
          quantity: 1,
          taxable: false,
          totalPrice: calculatedCCFee,
          unitPrice: calculatedCCFee,
          id: '',
        });

        receivable.invoiceDetails.lineItems = newLineItems;
      }

      setReceivable(receivable, invoiceIndex);

      if (currentTotal !== receivables[invoiceIndex].invoiceDetails.total) {
        showFlash('Please double check the values are as expected, otherwise contact Luc.', 'warning');
      }
    }

    // updatedInvoices[invoiceIndex] = updatedInvoice;
    // const updatedOrder = { ...orderCopy, invoices: updatedInvoices };
    // handleUpdate(updatedOrder);
  };

  const handleLineItemSave = (
    receivableIndex: number,
    lineItems: Partial<Invoice.LineItemTransport>[],
    lineItemIndex: number | undefined,
  ) => {
    if (!order) return;

    // let's grab the relevant invoice
    const receivable = receivables[receivableIndex];

    const fullLineItems: Invoice.LineItemTransport[] = lineItems.map((lineItem) => {
      return {
        ...lineItem,
        totalPrice: (lineItem.unitPrice || 0) * (lineItem.quantity || 0),
      } as Invoice.LineItemTransport;
    });

    // if we have a lineItemIndex we are editing an existing line item
    if (lineItemIndex || lineItemIndex === 0) {
      receivable.invoiceDetails.lineItems[lineItemIndex] = fullLineItems[0];
    } else {
      receivable.invoiceDetails.lineItems.push(...fullLineItems);
    }

    // if it exists recalculate the CC Fee
    const ccLineItem = fullLineItems.find((item) => item.itemName === 'CC Fee');

    if (ccLineItem) {
      const preCCTotal = fullLineItems.reduce(
        (total, lineItem) => (total += lineItem.totalPrice || (lineItem.unitPrice || 0) * (lineItem.quantity || 0)),
        0,
      );

      ccLineItem.unitPrice = preCCTotal * ccRate;
      ccLineItem.totalPrice = preCCTotal * ccRate;
    }

    setReceivable(receivable, receivableIndex);
  };

  const handleDeleteLineItem = (receivableIndex: number, lineItemIndex: number) => {
    if (!order) return;

    // let's grab the relevant invoice
    const receivable = receivables[receivableIndex];

    // remove the line item by index
    receivable.invoiceDetails.lineItems.splice(lineItemIndex, 1);

    // if it exists recalculate the CC Fee
    const ccLineItem = receivable.invoiceDetails.lineItems.find((item) => item.itemName === 'CC Fee');

    if (ccLineItem) {
      const preCCTotal = receivable.invoiceDetails.lineItems.reduce(
        (total, lineItem) => (total += lineItem.totalPrice || (lineItem.unitPrice || 0) * lineItem.quantity),
        0,
      );

      ccLineItem.unitPrice = preCCTotal * ccRate;
      ccLineItem.totalPrice = preCCTotal * ccRate;
    }

    setReceivable(receivable, receivableIndex);
  };

  const handleCreateReceipt = (receivableIndex: number) => {
    if (!order) return;
    !hasChanges
      ? onCreateReceipt(receivables[receivableIndex])
      : showFlash('You have not saved your changes.', 'warning');
  };

  const handleBillLineItemSave = (
    payableIndex: number,
    item: Partial<Invoice.LineItemTransport>,
    lineItemIndex: number | undefined,
  ) => {
    // get the payable
    const payable = payables[payableIndex];

    // this is an edit
    if (lineItemIndex || lineItemIndex === 0) {
      // We know this maybe be incomplete (i.e missing id) but that's ok because it could be a new line item
      payable.invoiceDetails.lineItems[lineItemIndex] = { ...item } as Invoice.LineItemTransport;

      setPayable(payable, payableIndex);
      return;
    }

    // this is a new line item
    payable.invoiceDetails.lineItems.push(item as Invoice.LineItemTransport);
    setPayable(payable, payableIndex);
  };

  const handleBillDeleteLineItem = (payableIndex: number, lineItemIndex: number) => {
    // get the payable
    const payable = payables[payableIndex];

    payable.invoiceDetails.lineItems.splice(lineItemIndex, 1);

    setPayable(payable, payableIndex);
  };

  const handleInvoiceEditModalSave = (formPayload: EditInvoiceFormProps, receivableIndex: number) => {
    // let's grab the relevant invoice
    const receivable = receivables[receivableIndex];

    receivable.invoiceDetails.internalNotes = formPayload.internalNotes || receivable.invoiceDetails.internalNotes;
    receivable.invoiceDetails.memo = formPayload.memo || receivable.invoiceDetails.memo;

    if (formPayload.issueDate) {
      // remove the line item by index
      receivable.invoiceDetails.issueDate = formPayload.issueDate.toISOString();
    }

    setReceivable(receivable, receivableIndex);

    handleUpdate({
      paymentMethod: formPayload.paymentMethod,
      paymentTerm: formPayload.paymentTerm,
    });
  };

  const handleUpdateBill = (
    payableIndex: number,
    data: {
      invoiceNumber?: string;
      haulerID?: string;
      haulerName?: string;
      readyForPayment?: boolean;
      internalNotes?: string;
    },
  ) => {
    const payable = payables[payableIndex];

    payable.invoiceDetails.invoiceNumber = data.invoiceNumber;
    payable.invoiceDetails.internalNotes = data.internalNotes || payable.invoiceDetails.internalNotes;
    payable.haulerID = data.haulerID || payable.haulerID;
    payable.vendorName = data.haulerName || payable.vendorName;

    if (data.readyForPayment !== undefined) {
      payable.readyForPayment = data.readyForPayment;
    }

    setPayable(payable, payableIndex);
  };

  const handleCreateNewReceivable = () => {
    // create new invoice
    const newInvoice: Invoice.ReceivableTransport = {
      invoiceDetails: {
        orderServiceLocation: order?.serviceLocation,
        orderNumber: order?.orderNumber?.toString() || '',
        invoiceNumber: String(receivables.length + 1),
        taxRate: 0,
        lineItems: [],
        void: false,
        payments: [],
        refunds: [],
        status: 'DRAFT',
        id: '',
        totalDollars: 0,
        total: 0,
        isWaysteInvoice: false,
        paidInFull: false,
        remainingBalance: 0,
        remainingBalanceDollars: 0,
        syncedWithAccounting: false,
        taxAmount: 0,
        taxAmountDollars: 0,
        type: 'receivable',
        orderID: order?.id || '',
      },
      customerID: customer?.id || '',
      id: '',
      customerCompanyName: customer?.companyName || '',
      customerName: order?.customerName || '',
    };

    setReceivable(newInvoice);
  };

  const handleCreateNewPayable = (data: {
    haulerID: string;
    invoiceNumber: string;
    haulerName: string;
    lineItems?: Invoice.LineItemInputTransport[];
  }) => {
    // create new bill
    // create new invoice
    const newPayable: Invoice.PayableTransport = {
      invoiceDetails: {
        invoiceNumber: data.invoiceNumber,
        taxRate: 0,
        lineItems: [],
        void: false,
        payments: [],
        refunds: [],
        status: 'DRAFT',
        id: '',
        totalDollars: 0,
        total: 0,
        isWaysteInvoice: payables.some((payable) => payable.invoiceDetails.isWaysteInvoice),
        waysteOrderNumber:
          payables.find((payable) => payable.invoiceDetails.waysteOrderNumber)?.invoiceDetails.waysteOrderNumber ||
          undefined,
        paidInFull: false,
        remainingBalance: 0,
        remainingBalanceDollars: 0,
        syncedWithAccounting: false,
        taxAmount: 0,
        taxAmountDollars: 0,
        type: 'receivable',
        orderID: order?.id || '',
      },
      haulerID: data.haulerID || payables[0]?.haulerID || '',
      vendorName: data.haulerName,
      readyForPayment: false,
      id: '',
    };

    if (data.lineItems) {
      newPayable.invoiceDetails.lineItems.push(...(data.lineItems as Invoice.LineItemTransport[]));
    }

    setPayable(newPayable);
  };

  const handleDeleteReceivable = (receivableIndex: number) => {
    // mark invoice as void
    const receivable = receivables[receivableIndex];

    // if it doesn't have an id it hasn't been saved yet so we can just remove it
    if (!receivable.id) {
      const updatedReceivables = [...receivables];
      updatedReceivables.splice(receivableIndex, 1);
      setReceivables(updatedReceivables);
      return;
    }
    receivable.invoiceDetails.void = true;
    receivable.invoiceDetails.status = 'VOID';
    setReceivable(receivable, receivableIndex);
  };

  const handleDeletePayable = (payableIndex: number) => {
    // mark bill as void
    const payable = payables[payableIndex];

    // if it doesn't have an id it hasn't been saved yet so we can just remove it
    if (!payable.id) {
      const updatedPayables = [...payables];
      updatedPayables.splice(payableIndex, 1);
      setPayables(updatedPayables);
      return;
    }

    payable.invoiceDetails.void = true;
    payable.invoiceDetails.status = 'VOID';
    setPayable(payable, payableIndex);
  };

  // we should separate this into two functions, one for creating a new receivable and one for updating an existing receivable
  const handleSaveReceivable = async (
    updatedReceivable: Invoice.ReceivableTransport,
  ): Promise<Invoice.ReceivableTransport> => {
    if (!order) throw new Error('Order is not loaded');

    // if the update has an id then it is an update otherwise it is a create
    const isUpdate = !!updatedReceivable.id;
    updatedReceivable.invoiceDetails.orderID = order.id; // make sure this is always set

    // if it is an update we need to update the invoice
    if (isUpdate) {
      return await client
        .invoice()
        .adminPortal.receivable.update(updatedReceivable.id, updatedReceivable as Invoice.ReceivableUpdateTransport);
    }

    return await client.invoice().adminPortal.receivable.create(updatedReceivable);
  };

  // we should separate this into two functions, one for creating a new payable and one for updating an existing payable
  const handleSavePayable = async (updatedPayable: Invoice.PayableTransport) => {
    if (!order) throw new Error('Order is not loaded');

    // if the update has an id then it is an update otherwise it is a create
    const isUpdate = !!updatedPayable.id;
    updatedPayable.invoiceDetails.orderID = order.id; // make sure this is always set
    updatedPayable.invoiceDetails.orderNumber = order.orderNumber?.toString() || '';
    updatedPayable.vendorName ??= order.vendorName || undefined;

    // if it is an update we need to update the invoice
    if (isUpdate) {
      return await client
        .invoice()
        .adminPortal.payable.update(updatedPayable.id, updatedPayable as Invoice.ReceivableUpdateTransport);
    }

    const waysteOrderID = payables.find((payable) => payable.invoiceDetails.waysteOrderID)?.invoiceDetails
      .waysteOrderID;

    if (waysteOrderID) {
      updatedPayable.invoiceDetails.waysteOrderID = waysteOrderID;
    }

    return await client.invoice().adminPortal.payable.create(updatedPayable);
  };

  const handleSave = async (view: 'invoice' | 'bill', close?: boolean) => {
    if (!selectedOrder) return;
    if (view === 'invoice') {
      // save inv
      try {
        await Promise.all(
          receivablesChanges.map(async (receivableIndex) => {
            const receivable = await handleSaveReceivable(receivables[receivableIndex]);

            // override the receivable in place
            setReceivable(receivable, receivableIndex, {
              isAPIResponse: true,
            });

            return receivable;
          }),
        );
        showFlash('Receivables Successfully Updated', 'success');
      } catch (error) {
        console.error(error);
        showFlash('Failed to save receivables', 'warning');
        return;
      } finally {
        setIsUpdating(false);
      }
    }
    if (view === 'bill') {
      // save bill
      try {
        await Promise.all(
          payablesChanges.map(async (payableIndex) => {
            const payable = await handleSavePayable(payables[payableIndex]);

            // override the payable in place
            setPayable(payable, payableIndex, {
              isAPIResponse: true,
            });

            return payable;
          }),
        );
        showFlash('Payables Successfully Updated', 'success');
      } catch (error) {
        console.error(error);
        showFlash('Failed to save payables', 'warning');
        return;
      } finally {
        setIsUpdating(false);
      }
    }

    if (close) {
      // this close is referring to marking the order close
      // update order was here
    }
    if (close) {
      onCancel();
    } else {
      // onOrderSave(); ?? idk what this did
    }
  };

  const handleNeedsAttentionToggle = async () => {
    const newOrder = {
      ...orderCopy,
      needsAttentionBilling: !orderCopy?.needsAttentionBilling,
    };
    handleUpdate(newOrder);
  };

  return (
    <InvoiceUpdate
      order={selectedOrder}
      customer={customer}
      ordersMatch={!hasChanges}
      isLoading={isLoading || isUpdating}
      onCreateReceipt={handleCreateReceipt}
      payables={payables}
      receivables={receivables}
      //handleSaveReceivable={handleSaveReceivable}
      handleCreateNewReceivable={handleCreateNewReceivable}
      handleCreateNewPayable={handleCreateNewPayable}
      handleDeleteReceivable={handleDeleteReceivable}
      handleDeletePayable={handleDeletePayable}
      onChangeTax={handleChangeTax}
      onChangeCC={handleChangeCC}
      onUploadQB={handleUploadQB}
      onReceivableLineItemSave={handleLineItemSave}
      onReceivableDeleteLineItem={handleDeleteLineItem}
      onEditModalSave={handleInvoiceEditModalSave}
      onShowOrderDetails={onShowDetails}
      onAddPayment={onAddPayment}
      onSave={(view) => handleSave(view)}
      onSaveClose={(view) => handleSave(view, true)}
      onCancel={onCancel}
      handleOnRefund={handleOnRefund}
      onUpdateBill={handleUpdateBill}
      onBillLineItemSave={handleBillLineItemSave}
      onBillDeleteLineItem={handleBillDeleteLineItem}
      onNeedsAttention={handleNeedsAttentionToggle}
      onGetHaulerName={handleGetHaulerName}
    />
  );
};

export default InvoiceUpdateContainer;
