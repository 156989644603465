import React from 'react';
import { Hauler, Pricing } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import { HaulerPricingForm, RollOffPricingFormProps } from '../HaulerPricingForm/HaulerPricingForm';
import { HaulerPricingFormUniversal } from '../HaulerPricingForm/HaulerPricingFormUniversal';

interface HaulerPricingUpdateDialogProps {
  open: boolean;
  onBackButtonClick: (data: { status: string | number }) => void;
  onSubmit: (data: RollOffPricingFormProps) => Promise<void>;
  onDelete?: (data: any) => void;
  isLoading: boolean;
  hauler: Hauler.HaulerWithAapTransport;
  mode: 'Rolloff' | 'Universal';
  pricing: Pricing.PricingTransport;
  haulerPricingZones: Pricing.PricingTransport[];
}

const HaulerPricingUpdate = ({
  pricing,
  open,
  onBackButtonClick,
  onSubmit,
  isLoading,
  hauler,
  onDelete,
  mode,
  haulerPricingZones,
}: HaulerPricingUpdateDialogProps) => {
  return (
    <Dialog
      styledTitle={`Update Vendor Pricing Zone (${mode})`}
      onClose={() => onBackButtonClick({ status: 'cancel' })}
      open={open}
      className="max-w-xl"
    >
      {mode === 'Rolloff' ? (
        <HaulerPricingForm
          haulerPricingZones={haulerPricingZones}
          pricing={pricing}
          onSubmit={onSubmit as (data: RollOffPricingFormProps) => Promise<void>}
          onCancel={onBackButtonClick}
          isLoading={isLoading}
          hauler={hauler}
          onDelete={onDelete}
          mode={mode}
        />
      ) : (
        <HaulerPricingFormUniversal
          pricing={pricing}
          onSubmit={onSubmit}
          mode={mode}
          onCancel={onBackButtonClick}
          isLoading={isLoading}
          hauler={hauler}
          onDelete={onDelete}
        />
      )}
    </Dialog>
  );
};

export default HaulerPricingUpdate;
