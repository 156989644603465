import React, { useEffect } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { buildAddressString } from '@wayste/utils';
import { useConfirmationDialog } from '../../contexts';
import { CustomerFormFields } from '../CustomerForm';

export interface CustomerFormProps {
  disabled: boolean;
  formik: any;
  onUseExistingCustomer: (customer: any, values?: any) => void;
  existingCustomer: any;
}

const CustomerForm = ({ disabled, formik, onUseExistingCustomer, existingCustomer }: CustomerFormProps) => {
  const { getConfirmation } = useConfirmationDialog();
  const { values, setValues, setFieldValue } = formik;
  const client = useWaysteClient();

  useEffect(() => {
    if (
      values.serviceLocation.address.addressLine1 &&
      buildAddressString(values.serviceLocation.address) === buildAddressString(values.billingAddress)
    ) {
      setFieldValue('sameAsService', true);
    }
  }, [values.serviceLocation.address]);

  useEffect(() => {
    if (values.sameAsService) {
      setValues({ ...values, billingAddress: values.serviceLocation.address });
    }
  }, [values.sameAsService]);

  useEffect(() => {
    if (values.taxExempt) {
      setFieldValue('tax', false);
    }
  }, [values.taxExempt]);

  const handleEmailBlur = async () => {
    if (
      !formik.errors.contacts?.find((contact: any) => contact.primaryContact)?.email &&
      values.primaryContact?.email
    ) {
      let customerDuplicate;
      console.log('RESPONSE QUERY', {
        email: values.primaryContact?.email.toLowerCase().trim(),
      });
      const response = (await client.customer().adminPortal.query({
        email: values.primaryContact?.email.toLowerCase().trim(),
      })) as any;
      console.log('RESPONSE THIS', response);

      if (response && response?.status === 'error') {
        customerDuplicate = null;
      } else if (response && response && response.length > 0) {
        customerDuplicate = response[0];
        // TODO remove this once able to "merge" customers
        if (
          values.primaryContact?.email.toLowerCase().trim() === 'bdibartolo@elevatedremodeling.net' &&
          response[0].customerNumber !== 3495
        ) {
          customerDuplicate = response.find((item: any) => item.customerNumber === 3495);
        }
      }
      if (customerDuplicate && customerDuplicate.id !== values.id) {
        const confirmed = await getConfirmation({
          title: 'Duplicate Customer',
          message:
            'This is a duplicate email. Click CONFIRM if you want to use the already EXISTING customer. Click CANCEL if you want to create a NEW customer with a DIFFERENT email.',
        });
        if (confirmed) {
          onUseExistingCustomer(customerDuplicate);
        } else {
          onUseExistingCustomer(null, {
            companyName: values.companyName,
            notes: values.notes,
            contacts: [
              {
                firstName: values.primaryContact?.firstName,
                lastName: values.primaryContact?.lastName,
                phoneNumber: values.primaryContact?.phoneNumber,

                primaryContact: true,
                sendDispatchEmails: values.primaryContact?.sendDispatchEmails,
                sendBillingEmails: values.primaryContact?.sendBillingEmails,
                notes: values.primaryContact?.notes,
              },
            ],
          });
        }
      }
    }
  };

  return (
    <CustomerFormFields
      customer={existingCustomer} // this was missing before and was not causing an error
      showSameAs
      subForm
      formik={formik}
      disabled={disabled}
      onEmailBlur={handleEmailBlur}
      lockFields={Boolean(existingCustomer)}
    />
  );
};

export default CustomerForm;
