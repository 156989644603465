import React from 'react';
import { Pricing } from '@alliance-disposal/transport-types';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PricingForm from '../PricingForm';

/**
 * Component to Create pricing for a county
 * @param {Function} onBackButtonClick Function on modal close
 * @param {Boolean} open Controls if modal is visible
 * @param {Function} onSubmit Function on create
 * @param {Boolean} isLoading Replaces submit and cancel with a loading indicator
 */

export interface PricingCreateProps {
  onBackButtonClick: () => void;
  onSubmit: (
    data: Pricing.PricingCreateTransport,
    pricingZones?: Pricing.PricingTransport[],
    copyExistingZone?: string,
  ) => void;
  isLoading: boolean;
}

const PricingCreate = ({ onBackButtonClick, onSubmit, isLoading }: PricingCreateProps) => {
  return (
    <Container disableGutters style={{ backgroundColor: '#FFFFFF' }}>
      <Toolbar className="bg-sourgum-greyblue-900 text-white">
        <Typography variant="h6">Create Zone Pricing</Typography>
      </Toolbar>
      <Container maxWidth="md">
        <PricingForm mode="CREATE" onSubmit={onSubmit} onCancel={onBackButtonClick} isLoading={isLoading} />
      </Container>
    </Container>
  );
};

export default PricingCreate;
