import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Order, Profile } from '@alliance-disposal/transport-types';
import { Pricing } from '@alliance-disposal/transport-types';
import { buildAddressString, getDateFormat, round } from '@wayste/utils';
import { addDays, format, isSameDay, isTomorrow } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { sendDynamicEmail } from '../../axios/sendgrid';
import { UIContext } from '../../contexts';
import { OrderStatus, createOrderConfirmationEmailSendGrid, getCustomerCCAddresses, routes } from '../../utils';
import Loading from '../Loading';
import OrderReview from './OrderReview';

const OrderReviewContainer = () => {
  const { id }: { id: string } = useParams();
  const { showFlash } = useContext(UIContext);
  const client = useWaysteClient();
  const userProfile: Profile.ProfileTransport = client.user().get();
  const history = useHistory();

  const [zonePricing, setZonePricing] = useState<Pricing.PricingTransport | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [existingCustomer, setExistingCustomer] = useState<Customer.AllianceCustomerTransport | null>(null);
  const [currentOrder, setCurrentOrder] = useState<Order.AllianceOrderTransport | null>(null);

  const handleGetOrder = async (id: string) => {
    const response = await client.order().adminPortal.fetch(id);
    if (response?.status !== OrderStatus.NEEDS_REVIEW) {
      history.push(routes.orders.details(id));
    }
    const customerResponse = await client.customer().adminPortal.fetch(response.allianceCustomerID);

    if (customerResponse) {
      setExistingCustomer(customerResponse);
      setCurrentOrder(response);
    } else {
      alert('An error occurred get an AAP dev');
      return;
    }
  };

  useEffect(() => {
    handleGetOrder(id);
  }, [id]);

  const handleBackButtonClick = () => {
    history.goBack();
  };

  const getOrderTotal = (values: any) => {
    let total = values.price;
    values.otherLineItems.forEach((item: any) => {
      total = total + item.totalPriceDollars;
    });
    return round(total);
  };

  const handleGetPricing = async (location: { lat: number; lng: number; zip: string; state: string }) => {
    try {
      const results = await client.pricing().adminPortal.location.query(location);
      // TODO replace this with a query that just gets the public true pricing
      const publicPricing = results.filter((item: any) => item.public === true);
      if (publicPricing.length === 1) {
        setZonePricing(publicPricing[0]);
      } else {
        if (publicPricing.length > 1) alert('Get an AAP Dev pricing overlap');
        setZonePricing(null);
      }
    } catch (error) {
      console.warn('handleGetPricing Error: ', error);
      alert('An error occurred, get an AAP dev, touch nothing');
    }
  };

  const handleSendEmail = async (values: any) => {
    const ccResponse = getCustomerCCAddresses(values);
    const emailData = createOrderConfirmationEmailSendGrid(
      values,
      getOrderTotal(values),
      userProfile,
      null,
      zonePricing?.prohibitedItems ? zonePricing.prohibitedItems.join(', ') : '',
      ccResponse.serviceCCAddresses,
    );
    const response = await sendDynamicEmail(emailData as any);
    if (
      isSameDay(new Date(), values.expectedDeliveryDate) ||
      (isTomorrow(values.expectedDeliveryDate) && new Date().getHours() >= 9)
    ) {
      const deliveryTomorrowEmailData = {
        emailTemplate: 'RO_DELIVERY_TOMORROW',
        toEmail: values.primaryContact?.email,
        first_name: values?.contacts?.[0]?.firstName,
        delivery_date: format(new Date(values.expectedDeliveryDate), 'EEEE MM/dd/yy'),
        service_location: buildAddressString(values.serviceLocation.address),
        csa_name: userProfile.firstName,
        ccEmails: ccResponse.serviceCCAddresses || [],
        today: isSameDay(new Date(), values.expectedDeliveryDate) ? 'Today' : null,
      };
      await sendDynamicEmail(deliveryTomorrowEmailData as any);
    }
    return response;
  };

  const handleDBUpdate = async (orderObj: Order.AllianceOrderUpdateInput, values: any) => {
    try {
      await client.order().adminPortal.update(values.id, orderObj);
    } catch (orderError) {
      console.warn('create Order orderError: ', orderError);
      showFlash('An Error Occurred Creating Order', 'warning');
      alert('An error occurred get an AAP dev');
      setIsLoading(false);
      return;
    }
    if (values.noteOrder)
      await client.order().adminPortal.notes.create(values.id, { note: values.noteOrder, isIssue: false });

    if (values.sendEmail) {
      const emailResponse = await handleSendEmail(values);
      if (emailResponse.status === 'error') {
        showFlash('An Error Occurred Sending the Confirmation Email', 'warning');
      }
    }
    showFlash('Order Successfully Updated', 'success');
    handleBackButtonClick();
  };

  const handleSubmit = (values: any) => {
    setIsLoading(true);

    const orderObj: Order.AllianceOrderUpdateInput = {
      ...currentOrder,
      adjustedRentalPeriod: {
        value: values.adjustedRentalPeriod,
        unit: 'DAYS',
      },
      serviceLocation: values.serviceLocation,
      extendingRental: values.extendingRental || false,
      material: values.material,
      expectedSize: {
        size: values.expectedSize,
        type: 'OPEN_TOP',
      },
      vendorName: values.vendorName || '',
      expirationDate:
        values.expectedDeliveryDate && values.adjustedRentalPeriod
          ? addDays(new Date(values.expectedDeliveryDate), values.adjustedRentalPeriod).toISOString()
          : undefined,
      rentalEndDate:
        values.rentalEndDate || values.rentalEndDate === null
          ? getDateFormat(values.rentalEndDate)
          : values.expectedPickupDate || values.expectedPickupDate === null
          ? getDateFormat(values.expectedPickupDate)
          : undefined,

      expectedDeliveryDate: values.expectedDeliveryDate,
      sharedDeliveryNotes: values.sharedDeliveryNotes,
      expectedPickupDate: values.expectedPickupDate,
      sharedPickupNotes: values.sharedPickupNotes,
      poNumber: values.poNumber,
      sharedDeliveryNotesPrivate: values.sharedDeliveryNotesPrivate,
      recurringOnCall: values.recurringOnCall,
      status: OrderStatus.UNASSIGNED,
    };

    console.log('Order Review Payload', orderObj);

    handleDBUpdate(orderObj, values);
  };

  if (!currentOrder) return <Loading />;

  return (
    <>
      {existingCustomer ? (
        <OrderReview
          open={true}
          onBackButtonClick={handleBackButtonClick}
          user={userProfile}
          onSubmit={handleSubmit}
          zonePricing={zonePricing}
          onGetPricing={handleGetPricing}
          isLoading={isLoading}
          getOrderTotal={getOrderTotal}
          currentOrder={currentOrder}
          existingCustomer={existingCustomer}
        />
      ) : (
        <div> something went wrong </div>
      )}
    </>
  );
};

export default OrderReviewContainer;
