/**
 *
 * @deprecated - Use Sour Components Version
 */
export const AmexIcon = () => {
  return (
    <svg width="27" height="19" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.64 17.8133L27.2533 16.2666L25.8133 17.8133H22.9867H16.9067V10.6133H14.08L17.6 2.55997H21.0133L22.24 5.3333V2.55997H26.5067L27.2533 4.63997L28 2.55997H31.2533V1.3333C31.2533 0.586636 30.6667 -3.05176e-05 29.92 -3.05176e-05H1.33333C0.586667 -3.05176e-05 0 0.586636 0 1.3333V18.6666C0 19.4133 0.586667 20 1.33333 20H29.92C30.6667 20 31.2533 19.4133 31.2533 18.6666V17.8133H28.64Z"
        fill="#0071CE"
      />
      <path
        d="M29.0132 16.96H31.2532L28.3198 13.8133L31.2532 10.72H29.0665L27.1998 12.7466L25.3865 10.72H23.1465L26.1332 13.8666L23.1465 16.96H25.3332L27.1998 14.9333L29.0132 16.96Z"
        fill="#0071CE"
      />
      <path
        d="M19.5734 15.52V14.56H23.0934V13.12H19.5734V12.16H23.1467V10.72H17.8667V16.96H23.1467V15.52H19.5734Z"
        fill="#0071CE"
      />
      <path
        d="M29.5998 9.81329H31.1998V3.51996L28.6398 3.57329L27.2532 7.46663L25.8132 3.57329H23.1465V9.81329H24.8532V5.43996L26.4532 9.81329H27.9465L29.5998 5.43996V9.81329Z"
        fill="#0071CE"
      />
      <path
        d="M20.4268 3.5733H18.2401L15.4668 9.8133H17.3335L17.8668 8.58664H20.7468L21.2801 9.8133H23.2001L20.4268 3.5733ZM18.4535 7.14664L19.3068 5.11997L20.1601 7.14664H18.4535Z"
        fill="#0071CE"
      />
    </svg>
  );
};
/**
 *
 * @deprecated - Use Sour Components Version
 */
export const VisaIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 780 500"
      height="22"
      viewBox="0 0 780 500"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m293.2 348.73 33.359-195.76h53.358l-33.384 195.76zm246.11-191.54c-10.569-3.966-27.135-8.222-47.821-8.222-52.726 0-89.863 26.551-90.181 64.604-.297 28.129 26.515 43.822 46.754 53.185 20.771 9.598 27.752 15.716 27.652 24.283-.133 13.123-16.586 19.115-31.924 19.115-21.355 0-32.701-2.967-50.225-10.273l-6.878-3.111-7.487 43.822c12.463 5.467 35.508 10.199 59.438 10.445 56.09 0 92.502-26.248 92.916-66.885.199-22.27-14.016-39.215-44.801-53.188-18.65-9.056-30.072-15.099-29.951-24.269 0-8.137 9.668-16.838 30.56-16.838 17.446-.271 30.088 3.534 39.936 7.5l4.781 2.259zm137.31-4.223h-41.23c-12.772 0-22.332 3.486-27.94 16.234l-79.245 179.4h56.031s9.159-24.121 11.231-29.418c6.123 0 60.555.084 68.336.084 1.596 6.854 6.492 29.334 6.492 29.334h49.512l-43.187-195.64zm-65.417 126.41c4.414-11.279 21.26-54.724 21.26-54.724-.314.521 4.381-11.334 7.074-18.684l3.606 16.878s10.217 46.729 12.353 56.527h-44.293zm-363.3-126.41-52.239 133.5-5.565-27.129c-9.726-31.274-40.025-65.157-73.898-82.12l47.767 171.2 56.455-.063 84.004-195.39-56.524-.001"
        fill="#0e4595"
      />
      <path
        d="m146.92 152.96h-86.041l-.682 4.073c66.939 16.204 111.23 55.363 129.62 102.42l-18.709-89.96c-3.229-12.396-12.597-16.096-24.186-16.528"
        fill="#f2ae14"
      />
    </svg>
  );
};
/**
 *
 * @deprecated - Use Sour Components Version
 */
export const MastercardIcon = () => {
  return (
    <svg width="29" height="20" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.0203 4.75867H11.187V15.2413H17.0203V4.75867Z" fill="#FF5F00" />
      <path
        d="M11.5576 10C11.5567 8.99044 11.7854 7.99391 12.2266 7.08584C12.6677 6.17777 13.3097 5.38197 14.1038 4.75868C13.1204 3.98565 11.9393 3.50491 10.6955 3.37142C9.45172 3.23792 8.19548 3.45705 7.07037 4.00376C5.94525 4.55048 4.99664 5.40272 4.33298 6.46306C3.66932 7.52341 3.31738 8.74909 3.31738 10C3.31738 11.2509 3.66932 12.4766 4.33298 13.5369C4.99664 14.5973 5.94525 15.4495 7.07037 15.9962C8.19548 16.5429 9.45172 16.7621 10.6955 16.6286C11.9393 16.4951 13.1204 16.0143 14.1038 15.2413C13.3097 14.618 12.6677 13.8222 12.2266 12.9142C11.7854 12.0061 11.5567 11.0096 11.5576 10Z"
        fill="#EB001B"
      />
      <path
        d="M24.8901 10C24.8902 11.2509 24.5383 12.4766 23.8747 13.5369C23.211 14.5973 22.2625 15.4495 21.1374 15.9962C20.0123 16.5429 18.756 16.7621 17.5123 16.6286C16.2685 16.4951 15.0874 16.0143 14.104 15.2413C14.8975 14.6174 15.5389 13.8215 15.98 12.9135C16.4211 12.0056 16.6503 11.0094 16.6503 10C16.6503 8.9906 16.4211 7.99438 15.98 7.08646C15.5389 6.17853 14.8975 5.3826 14.104 4.75868C15.0874 3.98565 16.2685 3.50491 17.5123 3.37142C18.756 3.23792 20.0123 3.45706 21.1374 4.00378C22.2625 4.5505 23.211 5.40274 23.8747 6.46308C24.5383 7.52343 24.8902 8.7491 24.8901 10Z"
        fill="#F79E1B"
      />
      <path
        d="M24.254 14.131V13.9164H24.3405V13.8727H24.1201V13.9164H24.2067V14.131H24.254ZM24.6818 14.131V13.8723H24.6143L24.5366 14.0503L24.4589 13.8723H24.3913V14.131H24.439V13.9359L24.5118 14.1041H24.5613L24.6342 13.9354V14.131H24.6818Z"
        fill="#F79E1B"
      />
    </svg>
  );
};
/**
 *
 * @deprecated - Use Sour Components Version
 */
export const DiscoverIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="30" height="30" viewBox="50 50 500 400">
      <path
        shape-rendering="crispEdges"
        d="M61.3 129.9h377.3v240.2H61.4z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M437.3 263.5s-103.1 72.7-291.9 105.3h291.9V263.5z"
        style={{
          fill: '#f48120',
        }}
      />
      <path d="M438.6 128.5H60v243h380V128.4h-1.4v.1zm-1.3 2.7v237.5H62.8V131.2h374.5z" />
      <path d="M93.2 202.8H77.5v55h15.7c8.3 0 14.3-2 19.6-6.3 6.3-5.2 10-13 10-21.1-.1-16.3-12.2-27.6-29.6-27.6zm12.6 41.4c-3.4 3-7.7 4.4-14.7 4.4h-2.9v-36.4h2.9c6.9 0 11.1 1.2 14.7 4.4 3.7 3.3 5.9 8.4 5.9 13.7 0 5.3-2.2 10.6-5.9 13.9z" />
      <path d="M127.7 202.8h10.7v55h-10.7z" />
      <path d="M164.6 223.9c-6.4-2.4-8.3-4-8.3-6.9 0-3.5 3.4-6.1 8-6.1 3.2 0 5.9 1.3 8.6 4.5l5.6-7.3c-4.6-4-10.1-6.1-16.2-6.1-9.7 0-17.2 6.8-17.2 15.8 0 7.6 3.5 11.5 13.5 15.1 4.2 1.5 6.3 2.5 7.4 3.1 2.1 1.4 3.2 3.4 3.2 5.7 0 4.5-3.5 7.8-8.3 7.8-5.1 0-9.2-2.6-11.7-7.3l-6.9 6.7c4.9 7.3 10.9 10.5 19 10.5 11.1 0 19-7.4 19-18.1.2-8.9-3.5-12.9-15.7-17.4z" />
      <path d="M183.8 230.4c0 16.2 12.7 28.7 29 28.7 4.6 0 8.6-.9 13.4-3.2v-12.6c-4.3 4.3-8.1 6-12.9 6-10.8 0-18.5-7.8-18.5-19 0-10.6 7.9-18.9 18-18.9 5.1 0 9 1.8 13.4 6.2V205c-4.7-2.4-8.6-3.4-13.2-3.4-16.1 0-29.2 12.8-29.2 28.8z" />
      <path d="m311.4 239.8-14.7-37H285l23.3 56.4h5.8l23.7-56.4h-11.6z" />
      <path d="M342.7 257.8h30.4v-9.3h-19.7v-14.8h19v-9.3h-19v-12.2h19.7v-9.4h-30.4z" />
      <path d="M415.6 219.1c0-10.3-7.1-16.2-19.5-16.2h-15.9v55h10.7v-22.1h1.4l14.8 22.1h13.2L403 234.7c8.1-1.7 12.6-7.2 12.6-15.6zm-21.6 9.1h-3.1v-16.7h3.3c6.7 0 10.3 2.8 10.3 8.2 0 5.5-3.6 8.5-10.5 8.5z" />
      <linearGradient
        id="XMLID_3_"
        x1={274.075}
        x2={253.821}
        y1={246.053}
        y2={277.765}
        gradientTransform="matrix(1 0 0 -1 0 500)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: '#f89f20',
          }}
        />
        <stop
          offset={0.25}
          style={{
            stopColor: '#f79a20',
          }}
        />
        <stop
          offset={0.533}
          style={{
            stopColor: '#f68d20',
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: '#f58720',
          }}
        />
        <stop
          offset={0.723}
          style={{
            stopColor: '#f48120',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#f37521',
          }}
        />
      </linearGradient>
      <circle
        id="XMLID_415_"
        cx={259.1}
        cy={230.5}
        r={29.3}
        style={{
          fill: 'url(#XMLID_3_)',
        }}
      />
      <linearGradient
        id="XMLID_4_"
        x1={270.878}
        x2={241.276}
        y1={246.48}
        y2={304.338}
        gradientTransform="matrix(1 0 0 -1 0 500)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: '#f58720',
          }}
        />
        <stop
          offset={0.359}
          style={{
            stopColor: '#e16f27',
          }}
        />
        <stop
          offset={0.703}
          style={{
            stopColor: '#d4602c',
          }}
        />
        <stop
          offset={0.982}
          style={{
            stopColor: '#d05b2e',
          }}
        />
      </linearGradient>
      <circle
        id="XMLID_414_"
        cx={259.1}
        cy={230.5}
        r={29.3}
        style={{
          opacity: 0.65,
          fill: 'url(#XMLID_4_)',
        }}
      />
      <g>
        <path d="M422.4 204.9c0-1-.7-1.5-1.8-1.5H419v4.8h1.2v-1.9l1.4 1.9h1.4l-1.6-2c.6-.1 1-.6 1-1.3zm-2 .7h-.2v-1.3h.2c.6 0 .9.2.9.6 0 .5-.3.7-.9.7z" />
        <path d="M420.8 201.6c-2.3 0-4.2 1.9-4.2 4.2s1.9 4.2 4.2 4.2c2.3 0 4.2-1.9 4.2-4.2s-1.9-4.2-4.2-4.2zm0 7.7c-1.8 0-3.4-1.5-3.4-3.5 0-1.9 1.5-3.5 3.4-3.5 1.8 0 3.3 1.6 3.3 3.5s-1.5 3.5-3.3 3.5z" />
      </g>
    </svg>
  );
};
