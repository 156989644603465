import { useEffect, useState } from 'react';
import { Hauler } from '@alliance-disposal/transport-types';
import { AddressSubForm, OperatingHoursSubForm } from '@wayste/sour-components';
import { Button, Checkbox, Loading, Select, SelectOption, TextField, Textarea } from '@wayste/sour-ui';
import { removeTypeName } from '@wayste/utils';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { Controller, FormProvider, get, useForm } from 'react-hook-form';
import { useFlash } from '../../hooks/useFlash';
import { durationUnitTypes, haulerBillPaymentMethods } from '../../utils';
import { statesAbb } from '../../utils/state-details';
import { ContactSubForm } from '../ContactForm/ContactSubForm';
import StarRating from '../StarRating';

const rentUnitTypes = {
  ...durationUnitTypes,
};

export interface VendorFormProps {
  hauler?: Hauler.HaulerWithAapTransport;
  onSubmit: (data: Hauler.HaulerWithAapDataUpdate) => void;
  onCancel: () => void;
  isLoading: boolean;
  onDeactivate?: () => void;
  onReactivate?: () => void;
}

interface FormProps {
  name: string;
  notes?: string;
  rating?: number;
  defaultPaymentMethod?: string;
  defaultRentPeriod: {
    unit: string;
    value: string;
  };
  rentExtensionFee?: string;
  billingAddress: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    zip: string;
  };
  contacts?: {
    notes?: string | undefined;
    department?: string | undefined;
    email?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    phoneNumber?: string | undefined;
    sendBillingEmails: boolean;
    sendDispatchEmails: boolean;
    sendMarketingEmails: boolean;
    primaryContact: boolean;
  }[];
  hoursOfOperation: {
    mon: {
      start: Date | '';
      end: Date | '';
      open: boolean;
    };
    tue: {
      start: Date | '';
      end: Date | '';
      open: boolean;
    };
    wed: {
      start: Date | '';
      end: Date | '';
      open: boolean;
    };
    thu: {
      start: Date | '';
      end: Date | '';
      open: boolean;
    };
    fri: {
      start: Date | '';
      end: Date | '';
      open: boolean;
    };
    sat: {
      start: Date | '';
      end: Date | '';
      open: boolean;
    };
    sun: {
      start: Date | '';
      end: Date | '';
      open: boolean;
    };
  };
}

/**
 * Form to create and update a hauler
 * @param {Object} hauler Hauler schema object
 * @param {Function} onSubmit Function on form submission
 * @param {Function} onCancel Function on cancel clicked
 * @param {Boolean} isLoading Replaces submit and cancel with a loading indicator
 */
export const VendorForm = ({ onSubmit, hauler, onCancel, isLoading, onDeactivate, onReactivate }: VendorFormProps) => {
  const { showFlash } = useFlash();
  const [toggleServiceInfoSection, setToggleServiceInfoSection] = useState<boolean>(true);

  //FORM CONTEXT
  const methods = useForm<FormProps>({
    mode: 'all',
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;

  const submitForm = (data: FormProps) => {
    const copy = { ...data };
    const cleanHoursOfOperation: Hauler.HaulerWithAapDataUpdate['hoursOfOperation'] = {
      mon: {
        start: copy.hoursOfOperation.mon.start ? copy.hoursOfOperation.mon.start.toISOString() : null,
        end: copy.hoursOfOperation.mon.end ? copy.hoursOfOperation.mon.end.toISOString() : null,
        open: copy.hoursOfOperation.mon.open,
      },
      tue: {
        start: copy.hoursOfOperation.tue.start ? copy.hoursOfOperation.tue.start.toISOString() : null,
        end: copy.hoursOfOperation.tue.end ? copy.hoursOfOperation.tue.end.toISOString() : null,
        open: copy.hoursOfOperation.tue.open,
      },
      wed: {
        start: copy.hoursOfOperation.wed.start ? copy.hoursOfOperation.wed.start.toISOString() : null,
        end: copy.hoursOfOperation.wed.end ? copy.hoursOfOperation.wed.end.toISOString() : null,
        open: copy.hoursOfOperation.wed.open,
      },
      thu: {
        start: copy.hoursOfOperation.thu.start ? copy.hoursOfOperation.thu.start.toISOString() : null,
        end: copy.hoursOfOperation.thu.end ? copy.hoursOfOperation.thu.end.toISOString() : null,
        open: copy.hoursOfOperation.thu.open,
      },
      fri: {
        start: copy.hoursOfOperation.fri.start ? copy.hoursOfOperation.fri.start.toISOString() : null,
        end: copy.hoursOfOperation.fri.end ? copy.hoursOfOperation.fri.end.toISOString() : null,
        open: copy.hoursOfOperation.fri.open,
      },
      sat: {
        start: copy.hoursOfOperation.sat.start ? copy.hoursOfOperation.sat.start.toISOString() : null,
        end: copy.hoursOfOperation.sat.end ? copy.hoursOfOperation.sat.end.toISOString() : null,
        open: copy.hoursOfOperation.sat.open,
      },
      sun: {
        start: copy.hoursOfOperation.sun.start ? copy.hoursOfOperation.sun.start.toISOString() : null,
        end: copy.hoursOfOperation.sun.end ? copy.hoursOfOperation.sun.end.toISOString() : null,
        open: copy.hoursOfOperation.sun.open,
      },
    };

    if (hauler) {
      const submit: Hauler.HaulerWithAapDataUpdate = {
        ...copy,
        hoursOfOperation: cleanHoursOfOperation,
        defaultRentPeriod: {
          unit: copy.defaultRentPeriod.unit,
          value: Number(copy.defaultRentPeriod?.value),
        },
        rentExtensionFee: Number(copy.rentExtensionFee),
        billingAddress: copy.billingAddress,
      };

      // ensure at least one contact is primary
      const primaryContact = submit?.contacts?.find((contact) => contact.primaryContact === true);

      if (!primaryContact) {
        showFlash('Please select a primary contact', 'warning');
        return;
      }
      onSubmit(submit);
    } else {
      const submit: Hauler.HaulerWithAapDataUpdate = {
        ...copy,
        hoursOfOperation: cleanHoursOfOperation,
        defaultRentPeriod: {
          unit: copy.defaultRentPeriod.unit,
          value: Number(copy.defaultRentPeriod?.value),
        },
        rentExtensionFee: Number(copy.rentExtensionFee),
        billingAddress: copy.billingAddress,
        yardAddress: copy.billingAddress,
        active: true,
      };

      if (toggleServiceInfoSection === false) {
        delete submit.defaultRentPeriod;
        delete submit.rentExtensionFee;
      }

      onSubmit(submit);
    }
  };

  const contactsDefault = {
    notes: '',
    contacts: [
      {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        department: '',
        notes: '',
        sendBillingEmails: true,
        sendDispatchEmails: true,
        primaryContact: true,
        sendMarketingEmails: false,
      },
    ],
  };

  // SET DEFAULT VALUES WHEN CREATING A NEW VENDOR
  useEffect(() => {
    reset({
      ...contactsDefault,
      hoursOfOperation: {
        mon: {
          start: '',
          end: '',
          open: false,
        },
        tue: {
          start: '',
          end: '',
          open: false,
        },
        wed: {
          start: '',
          end: '',
          open: false,
        },
        thu: {
          start: '',
          end: '',
          open: false,
        },
        fri: {
          start: '',
          end: '',
          open: false,
        },
        sat: {
          start: '',
          end: '',
          open: false,
        },
        sun: {
          start: '',
          end: '',
          open: false,
        },
      },
    });
  }, []);

  // SET DEFAULT VALUES WHEN EDITING A VENDOR
  useEffect(() => {
    if (hauler) {
      const newData = {
        ...removeTypeName(hauler),
      };
      delete newData.metadata;
      delete newData.waysteHaulerId;

      if (!hauler.rentExtensionFee) {
        setToggleServiceInfoSection(false);
      }

      if (hauler.hoursOfOperation) {
        for (const day in newData.hoursOfOperation) {
          if (newData.hoursOfOperation[day].start === null) {
            newData.hoursOfOperation[day].start = '';
          } else {
            newData.hoursOfOperation[day].start = new Date(newData.hoursOfOperation[day].start);
          }
          if (newData.hoursOfOperation[day].end === null) {
            newData.hoursOfOperation[day].end = '';
          } else {
            newData.hoursOfOperation[day].end = new Date(newData.hoursOfOperation[day].end);
          }
        }
      }

      reset({
        ...newData,
      });
    }
  }, [hauler]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitForm)}>
        {!hauler && (
          <>
            <div className="flex flex-row mt-4">
              <Button className="btn-dark-grey-outlined" onClick={onCancel} disabled={isLoading}>
                Back
              </Button>
              <div className="ml-4 text-xl w-full">Create new vendor</div>
            </div>
            <br />
          </>
        )}
        <br />
        {/* Hidden Fields */}
        <div className="flex flex-col space-y-6">
          <div className="text-xl">Company Information</div>
          {/* VENDOR NAME FIELD  */}
          <Controller
            control={control}
            name={`name`}
            defaultValue={''}
            rules={{
              required: {
                value: true,
                message: 'Vendor name is required',
              },
            }}
            render={({ field }) => (
              <TextField
                type={'string'}
                label="Vendor name"
                error={get(errors, `name`)}
                required
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
          {/* VENDOR NOTES FIELD */}
          <Controller
            control={control}
            name={`notes`}
            defaultValue={''}
            render={({ field }) => (
              <Textarea
                label="Vendor notes"
                error={get(errors, `notes`)}
                inputProps={{
                  ...field,
                }}
              />
            )}
          />

          <div className="pb-2 text-xl border-b border-gray-300">Contacts</div>

          <ContactSubForm />

          {/* CONTACTS FIELD */}
          <div className="text-xl">Rating</div>

          {/* RATING FIELD */}
          <Controller
            control={control}
            name={`rating`}
            render={({ field }) => <StarRating rating={field.value} onChange={field.onChange} />}
          />

          <div className="text-xl">Billing Info</div>

          {/* BILLING PAYMENt METHOD FIELD  */}
          <Controller
            control={control}
            name={`defaultPaymentMethod`}
            defaultValue={''}
            render={({ field }) => (
              <Select
                error={get(errors, `defaultPaymentMethod`)}
                label={'Default Payment Method'}
                onSelect={(value) => {
                  field.onChange(value);
                }}
                defaultValue={''}
                value={field.value}
              >
                {Object.keys(haulerBillPaymentMethods).map((option: string, index: number) => (
                  <SelectOption key={index} value={option}>
                    {haulerBillPaymentMethods[option as keyof typeof haulerBillPaymentMethods]}
                  </SelectOption>
                ))}
              </Select>
            )}
          />

          <AddressSubForm
            statesList={statesAbb}
            apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
            fieldName="billingAddress"
            required
          />

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="border-b pb-1 flex justify-between items-center">
                  <div className="text-xl">Hours of operation</div>
                  <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 `} />
                </Disclosure.Button>
                <Disclosure.Panel className="text-gray-500">
                  <OperatingHoursSubForm />
                  <hr className="mt-4" />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <div className="text-xl">Service Info</div>

          <Checkbox
            label="Services roll-off"
            inputProps={{
              value: 'type',
              checked: toggleServiceInfoSection,
              onChange: () => setToggleServiceInfoSection(!toggleServiceInfoSection),
            }}
          />
          {toggleServiceInfoSection && (
            <>
              {/* SERVICE INFO FIELD */}
              <div className="flex flex-row space-x-4">
                <Controller
                  control={control}
                  name={`defaultRentPeriod.unit`}
                  defaultValue={''}
                  rules={{
                    required: {
                      value: true,
                      message: 'Rental period units is required',
                    },
                  }}
                  render={({ field }) => (
                    <Select
                      error={get(errors, `defaultRentPeriod.unit`)}
                      label={'Rental period units'}
                      onSelect={(value) => {
                        field.onChange(value);
                      }}
                      defaultValue={''}
                      value={field.value}
                      required
                    >
                      {Object.entries(rentUnitTypes).map((option: [string, string], index: number) => (
                        <SelectOption key={index} value={option[0]}>
                          {option[1]}
                        </SelectOption>
                      ))}
                    </Select>
                  )}
                />

                <Controller
                  control={control}
                  name={`defaultRentPeriod.value`}
                  defaultValue={''}
                  rules={{
                    required: {
                      value: true,
                      message: 'Rental period is required',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      type={'number'}
                      label="Rental period"
                      error={get(errors, `defaultRentPeriod.value`)}
                      required
                      inputProps={{
                        ...field,
                        value: field?.value ? parseInt(field.value) : '',
                        onChange: (e) => {
                          if (parseInt(e.target.value) < 0) return;
                          field.onChange(parseInt(e.target.value));
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div className="w-1/2 pr-2">
                <Controller
                  control={control}
                  name={`rentExtensionFee`}
                  defaultValue={''}
                  rules={{
                    required: {
                      value: true,
                      message: 'Rental period is required',
                    },
                    min: {
                      value: 0,
                      message: 'Rental extension fee must be greater than 0',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      startAdornment={<div>$</div>}
                      type={'number'}
                      label="Rental extension fee"
                      required
                      error={get(errors, `rentExtensionFee`)}
                      inputProps={{
                        ...field,
                        value: field?.value ? (Number(field.value) / 100) | 0 : '',
                        onChange: (e) => {
                          if (parseInt(e.target.value) < 0) {
                            field.onChange(0);
                            return;
                          } else {
                            field.onChange(parseInt(e.target.value) * 100);
                          }
                        },
                      }}
                    />
                  )}
                />
              </div>
            </>
          )}
          {/* <Checkbox
            label="Has Universal Services"
            inputProps={{
              value: 'type',
              checked: toggleAncillaryServiceSection,
              onChange: () => setToggleAncillaryServiceSection(!toggleAncillaryServiceSection),
            }}
          />

          {toggleAncillaryServiceSection ? <></> : <></>} */}

          <div className="flex justify-end gap-4">
            {hauler ? (
              hauler.active ? (
                <button className="btn-delete mr-auto" onClick={onDeactivate} disabled={isLoading} type="button">
                  Deactivate Vendor
                </button>
              ) : (
                <button
                  className="btn-primary-outlined mr-auto"
                  onClick={onReactivate}
                  disabled={isLoading}
                  type="button"
                >
                  Re-Activate Vendor
                </button>
              )
            ) : null}
            <button className="mr-5 btn-dark-grey-outlined" onClick={onCancel} disabled={isLoading} type="button">
              Cancel
            </button>
            <button className="btn-primary" type="submit" disabled={isLoading}>
              {hauler ? 'Update' : 'Create'}
              {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
            </button>
          </div>
        </div>
        <br />
        <br />
      </form>
    </FormProvider>
  );
};
