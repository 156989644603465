import { Customer } from '@alliance-disposal/transport-types';
import { formatUSD, moneyFormatter } from '@wayste/utils';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { FormikProps } from 'formik';
import { materials, priceTypesEnums } from '../../utils/shared-types';
import PricingCalc from '../PricingCalc';

const quoteHeadings = ['Size', 'Material', 'Ton Limit', 'Overage Fee', 'Rental Period', 'Price Quoted', ''];

/**
 *
 * @param {[Object]} quotes Array of Lead Quote objects
 * @param {Function} onAddOrder Passes up quote to be sent to OrderCreate
 */

export interface QuoteFormProps {
  quotes: Customer.AllianceLeadQuote[];
  formik: FormikProps<any>;
  onAddOrder: (quote: Customer.AllianceLeadQuote) => void;
  startingValues?: any;
  onGoogleSelect: (address: any) => void;
}

const QuoteForm = ({ quotes, formik, onAddOrder, startingValues, onGoogleSelect }: QuoteFormProps) => {
  const handleAddQuoteClicked = (values: any) => {
    if (values.price === 0) {
      formik.setValues({
        ...formik.values,
        requestedSize: values.expectedSize,
        requestedMaterial: values.material,
      });
    } else {
      const newQuotes = [
        ...quotes,
        {
          ...values,
          dumpRate: values.dumpRate,
          dumpRateDollars: values.dumpRateDollars,
          price: values.price,
          priceDollars: values.priceDollars,
          overage: values.overage,
          overageDollars: values.overageDollars,
          discountAppliedDollars: null,
          size: {
            size: +values.expectedSize,
            // TODO this has to be changed from OPEN_TOP to variable once we start handling other types
            type: 'OPEN_TOP',
          },
          rentPeriod: {
            value: values.rentPeriod,
            unit: 'DAYS',
          },
          taxable: values.tax,
          tonLimit: values.weightLimit,
          rentExtensionFeeDollars: values.rentExtensionFeeDollars,
        },
      ];
      formik.setValues({
        ...formik.values,
        requestedSize: newQuotes[0].size.size,
        requestedMaterial: newQuotes[0].material,
        quotes: newQuotes,
      });
    }
  };

  const handleRemove = (index: number) => {
    const newQuotes = quotes.filter((_, itemIndex) => itemIndex !== index);
    formik.setFieldValue('quotes', newQuotes);
  };

  const NoQuotes = () => (
    <Grid item xs={12}>
      <Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.68)' }}>
        {formik.values.requestedSize
          ? `Requested ${formik.values.requestedSize} YD for ${
              materials[formik.values.requestedMaterial as keyof typeof materials]
            }`
          : 'Not Quoted'}
      </Typography>
    </Grid>
  );

  const QuoteTable = () => (
    <Grid item xs={12}>
      <TableContainer style={{ margin: '0 -16px', width: 'calc(100% + 32px)' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {quoteHeadings.map((item) => (
                <TableCell key={item} style={{ backgroundColor: '#F9F9F9', whiteSpace: 'nowrap' }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {quotes.map((item, index) => (
              <TableRow key={item.size.size + item.material}>
                <TableCell>
                  {item.size.size}
                  {' YD'}
                </TableCell>
                <TableCell>{materials[item.material as keyof typeof materials]}</TableCell>
                <TableCell>{item.priceType === priceTypesEnums.ton ? `${item.tonLimit} tons` : 'N/A'}</TableCell>
                <TableCell>
                  {item.priceType === priceTypesEnums.ton
                    ? formatUSD(item.overageDollars ? item.overageDollars : '')
                    : 'N/A'}
                </TableCell>
                <TableCell>
                  {item.rentPeriod?.value}
                  {' Days '}
                  {item.rentExtensionFeeDollars ? formatUSD(item?.rentExtensionFeeDollars) : 0}
                  {' / extra day'}
                </TableCell>
                <TableCell>{moneyFormatter(item.price)}</TableCell>
                <TableCell>
                  <button
                    className="btn-primary-text-only"
                    onClick={() => onAddOrder(item)}
                    disabled={!item.priceDollars}
                    type="button"
                  >
                    <PlusIcon className="h-4 w-4 mr-1" />
                    Add Order
                  </button>
                  {item.id ? null : (
                    <button className="btn-base text-delete ml-1" onClick={() => handleRemove(index)} type="button">
                      <XMarkIcon className="h-4 w-4 mr-1" />
                      Remove
                    </button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );

  return (
    <div>
      {formik.values.requestedSize ? (
        <div style={{ padding: 12 }}>
          <Typography variant="h6">
            Requested a {formik.values.requestedSize} YD for{' '}
            {materials[formik.values.requestedMaterial as keyof typeof materials]}
          </Typography>
        </div>
      ) : (
        <></>
      )}
      <div style={{ margin: '20px 0' }}>
        <PricingCalc
          onAddDetails={handleAddQuoteClicked}
          forLead
          onGoogleSelect={onGoogleSelect}
          startingValues={startingValues}
        />
      </div>
      <div className="bg-white rounded shadow-dark p-4 mb-5">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Quote Details</Typography>
          </Grid>
          {quotes.length === 0 ? <NoQuotes /> : <QuoteTable />}
        </Grid>
      </div>
    </div>
  );
};

QuoteForm.defaultProps = {
  startingValues: null,
};

export default QuoteForm;
