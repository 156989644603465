import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Profile, UniversalService } from '@alliance-disposal/transport-types';
import {
  Button,
  Checkbox,
  CurrencyTextField,
  Dialog,
  Select,
  SelectOption,
  TanDataGrid,
  Textarea,
} from '@wayste/sour-ui';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { UIContext } from '../../contexts';
import { needsAttentionStrings } from '../../utils';

interface Props {
  serviceGrouping: UniversalService.ServiceGrouping;
  serviceOrderID?: string;
  hideAddButton?: boolean;
}

const InternalNotes = ({ serviceGrouping, serviceOrderID, hideAddButton = false }: Props) => {
  // constants
  const client = useWaysteClient();
  const dateFormat = 'EEE MM/dd/yy hh:mm';
  const [open, setOpen] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [roster, setRoster] = useState<Profile.RosterTransport[]>([]);
  const notes =
    serviceOrderID && serviceGrouping?.internalNotes
      ? serviceGrouping.internalNotes.filter((note) => serviceOrderID && note.serviceOrderID === serviceOrderID)
      : serviceGrouping?.internalNotes;

  // functions
  const handleClose = () => {
    setOpen(false);
    setShowAdd(false);
  };

  const noteSummary = (note: UniversalService.InternalNote, index: number) => {
    const user = roster.find((profile) => profile.id === note.userID);
    const responsibleUser = roster.find((profile) => profile.id === note.responsibleUserID);
    return (
      <div key={note.timestamp} className={index !== notes.length - 1 ? 'mb-5' : ''}>
        <div className="mb-2">
          {note.serviceOrderID
            ? serviceGrouping.serviceOrders.find((serviceOrder) => serviceOrder.id === note.serviceOrderID)
                ?.fullOrderNumber + ' - '
            : null}
          {note.timestamp ? format(new Date(note.timestamp), dateFormat) + ' - ' : null}
          {user?.firstName} {user?.lastName}
        </div>
        {note.isIssue ? (
          <div className="mb-2">
            <span className="font-bold text-red-600">Issue</span>
            {' - '}
            {note.issueParty}
            {Boolean(note.responsibleUserID)
              ? `  ${responsibleUser?.firstName}${' '}${responsibleUser?.lastName} $${note.monetaryLoss || '0'}`
              : null}
          </div>
        ) : null}
        <div className="mb-2"></div>
        <span className={needsAttentionStrings.includes(note.note) ? 'bg-[#CC5500' : ''}>{note.note}</span>
      </div>
    );
  };

  // useEffects
  useEffect(() => {
    if (!notes || !roster) return;
    setData(getRowsFromNote());
  }, [roster]);

  useEffect(() => {
    client
      .adminPortal()
      .profile.fetchRoster()
      .then((response) =>
        setRoster(response.data.filter((profile) => profile.active && profile.firstName && profile.lastName)),
      );
  }, []);

  // Notes Table
  type Row = {
    date: string;
    user: string;
    isIssue: string;
    issueParty: string;
    monetaryLoss: string;
    serviceOrderNumber?: string;
    note: string;
    blank: string;
  };

  const getRowsFromNote = () => {
    if (!notes) return [];
    return notes.map((note) => {
      const user = roster.find((profile) => profile.id === note.userID);
      const responsibleUser = roster.find((profile) => profile.id === note.responsibleUserID);
      const row: Row = {
        date: format(new Date(note.timestamp), dateFormat),
        user: user?.firstName + ' ' + user?.lastName,
        note: note.note,
        isIssue: note.isIssue ? 'Yes' : '',
        issueParty: note.issueParty
          ? UniversalService.IssuePartyTypeLabels[note.issueParty]
          : '' + (responsibleUser ? ` ${responsibleUser.firstName} ${responsibleUser.lastName}` : ''),
        monetaryLoss: note.monetaryLoss ? '$' + note.monetaryLoss : '',
        serviceOrderNumber: note.serviceOrderID
          ? serviceGrouping.serviceOrders.find((order) => order.id === note.serviceOrderID)?.fullOrderNumber
          : '',
        blank: '',
      };
      return row;
    });
  };

  const [data, setData] = useState<Row[]>(getRowsFromNote());

  const columnHelper = createColumnHelper<Row>();
  const columns: ColumnDef<Row, any>[] = [
    columnHelper.accessor('date', {
      header: 'Date',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('user', {
      header: 'User',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('serviceOrderNumber', {
      header: 'Order #',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('isIssue', {
      header: 'Is Issue',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('issueParty', {
      header: 'Issue Party',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('monetaryLoss', {
      header: 'Monetary Loss',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('note', {
      header: 'Note',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('blank', {
      header: '',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
  ];

  return (
    <>
      <div>
        {notes && notes.map((note, index) => noteSummary(note, index))}
        {hideAddButton ? null : (
          <button className="btn-primary-text-only py-1 px-2.5" onClick={() => setOpen(true)}>
            Add New Note
          </button>
        )}
      </div>
      <Dialog open={open} onClose={handleClose} styledTitle="Internal Notes" className="max-w-[60%]">
        <TanDataGrid data={data} columns={columns} className="-mt-4 -mb-5 -ml-6 -mr-6" />
        <NoteAddDialog
          serviceGrouping={serviceGrouping}
          serviceOrderID={serviceOrderID}
          showAdd={showAdd}
          setShowAdd={setShowAdd}
        />
        <Button className="btn-primary mt-4" onClick={() => setShowAdd(true)}>
          Add New Note
        </Button>
        <div className="flex justify-end mt-4">
          <button className="btn-dark-grey-outlined" onClick={handleClose} type="button">
            Close
          </button>
        </div>
      </Dialog>
    </>
  );
};

interface AddNoteDialogProps {
  serviceGrouping: UniversalService.ServiceGrouping;
  showAdd: boolean;
  setShowAdd: (show: boolean) => void;
  serviceOrderID?: string;
}

const NoteAddDialog = ({ serviceGrouping, showAdd, setShowAdd, serviceOrderID }: AddNoteDialogProps) => {
  const client = useWaysteClient();
  const serviceOrder = serviceGrouping.serviceOrders.find((order) => order.id === serviceOrderID);
  const { showFlash } = useContext(UIContext);
  const methods = useForm<UniversalService.InternalNoteInput>();
  const { control, handleSubmit, watch } = methods;
  const [needsAttention, setNeedsAttention] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const note = watch('note');
  const isIssue = watch('isIssue');
  const issueParty = watch('issueParty');

  const [roster, setRoster] = useState<Profile.RosterTransport[]>([]);
  useEffect(() => {
    client
      .adminPortal()
      .profile.fetchRoster()
      .then((response) =>
        setRoster(response.data.filter((profile) => profile.active && profile.firstName && profile.lastName)),
      );
  }, []);

  useEffect(() => {
    if (serviceOrder) {
      // Set needsAttention initial value to the value of the serviceOrder.needsAttention
      setNeedsAttention(serviceOrder.needsAttention);
    }
  }, [serviceGrouping]);

  const onSubmit = async (data: UniversalService.InternalNoteInput) => {
    setIsLoading(true);
    try {
      data.serviceOrderID = serviceOrderID;
      if (data.monetaryLoss === 0) {
        data.monetaryLoss = undefined;
      }
      // Only update needsAttention if it has changed for Service Orders.
      if (serviceOrderID) {
        if (serviceOrder && needsAttention !== serviceOrder.needsAttention) {
          // Create a note for the needsAttention change.
          const needsAttentionNote: UniversalService.InternalNoteInput = {
            note: needsAttention
              ? `${serviceOrder.fullOrderNumber} ${needsAttentionStrings[0]}`
              : `${serviceOrder.fullOrderNumber} ${needsAttentionStrings[1]}`,
            isIssue: false,
            serviceOrderID: serviceOrderID,
          };
          await client.universalService().serviceGrouping.addNote(serviceGrouping.id, needsAttentionNote);
          await client
            .universalService()
            .serviceGrouping.serviceOrder.update(serviceGrouping.id, serviceOrderID, { needsAttention });
        }
      }
      await client.universalService().serviceGrouping.addNote(serviceGrouping.id, data);
      showFlash('Note added', 'success');
    } catch (error) {
      showFlash('failed to add note', 'warning');
    } finally {
      // Try to refresh the data
      serviceGrouping = await client.universalService().serviceGrouping.fetch(serviceGrouping.id);
      setIsLoading(false);
    }
    setShowAdd(false);
  };

  return (
    <Dialog open={showAdd} onClose={() => setShowAdd(false)} styledTitle="Add Note" className="max-w-3xl">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="gap-2">
            <Controller
              name="note"
              control={control}
              defaultValue=""
              render={({ field, fieldState }) => (
                <Textarea
                  label="New Note"
                  error={fieldState.error}
                  inputProps={{
                    value: field.value,
                    onChange: field.onChange,
                  }}
                />
              )}
            />
            {serviceOrderID && (
              <Checkbox
                label="Needs Attention"
                inputProps={{
                  checked: needsAttention,
                  onChange: (e) => setNeedsAttention(e.target.checked),
                }}
              />
            )}
            <Controller
              name="isIssue"
              control={control}
              defaultValue={false}
              render={({ field, fieldState }) => (
                <Checkbox
                  label="Is Issue"
                  inputProps={{
                    checked: field.value,
                    onChange: field.onChange,
                  }}
                  error={fieldState.error}
                />
              )}
            />
          </div>

          {isIssue && (
            <div className="space-y-2 mt-2">
              <Controller
                name="monetaryLoss"
                control={control}
                defaultValue={0}
                render={({ field, fieldState }) => (
                  <CurrencyTextField
                    startAdornment={'$'}
                    label="Monetary Loss"
                    error={fieldState.error}
                    onChange={field.onChange}
                    value={String(field.value)}
                  />
                )}
              />
              <Controller
                name="issueParty"
                control={control}
                defaultValue={undefined}
                render={({ field, fieldState }) => (
                  <Select
                    label={'Issue Party'}
                    onSelect={(value: string) => field.onChange(value as UniversalService.IssuePartyType)}
                    defaultValue={''}
                    error={fieldState.error}
                    value={field.value as string}
                  >
                    {UniversalService.IssuePartyTypes.map((type) => (
                      <SelectOption key={type} value={type}>
                        {UniversalService.IssuePartyTypeLabels[type]}
                      </SelectOption>
                    ))}
                  </Select>
                )}
              />
              {issueParty === 'sourgum' && (
                <Controller
                  name="responsibleUserID"
                  control={control}
                  defaultValue={''}
                  render={({ field, fieldState }) => (
                    <Select
                      label={'Responsible Party'}
                      onSelect={field.onChange}
                      defaultValue={''}
                      value={field.value}
                      error={fieldState.error}
                    >
                      {roster.map((profile) => (
                        <SelectOption key={profile.id} value={profile.id}>
                          {(profile.firstName || '') + ' ' + (profile.lastName || '')}
                        </SelectOption>
                      ))}
                    </Select>
                  )}
                />
              )}
            </div>
          )}

          <div className="flex space justify-end space-x-4 mt-4">
            <Button className="btn-dark-grey-outlined mt-2" onClick={() => setShowAdd(false)} type="button">
              Close
            </Button>
            <Button className="btn-primary mt-2" type="submit" disabled={note === ''} loading={isLoading}>
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default InternalNotes;
