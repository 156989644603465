import React, { useContext, useEffect, useState } from 'react';
import { Order } from '@alliance-disposal/transport-types';
import { titleCase } from '@wayste/utils';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import routes from '../../utils/routes';
import { OrderStatus, OrderStatusText } from '../../utils/shared-types';

const popperOptionsAll = ['ON_HOLD', 'UNASSIGNED', 'ASSIGNED', 'DELIVERED', 'READY_FULL_PICKUP', 'DUMPED'];

const popperOptions = {
  UNASSIGNED: OrderStatusText.UNASSIGNED,
  ASSIGNED: OrderStatusText.ASSIGNED,
  DELIVERED: OrderStatusText.DELIVERED,
  READY_FULL_PICKUP: OrderStatusText.READY_FULL_PICKUP,
  DUMPED: OrderStatusText.DUMPED,
};

const orderStatusOptions = Object.entries(popperOptions).map((item) => ({
  label: item[1],
  value: item[0],
}));

interface Props {
  order: Order.AllianceOrderTransport;
  onChange: (value: string) => void;
  navigateOnly?: boolean;
  className?: string;
}

const OrderStatusPopper = ({ order, onChange, navigateOnly, className }: Props) => {
  const history = useHistory();
  const { godModeActive } = useContext(UIContext);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [statusColor, setStatusColor] = useState('');

  useEffect(() => {
    switch (order.status) {
      case OrderStatus.NEEDS_REVIEW:
        setStatusColor('bg-statuses-NEEDS_REVIEW-primary text-statuses-NEEDS_REVIEW-text');
        break;
      case OrderStatus.UNASSIGNED:
        setStatusColor('bg-statuses-UNASSIGNED-primary text-statuses-UNASSIGNED-text');
        break;
      case OrderStatus.ON_HOLD:
        setStatusColor('bg-statuses-ON_HOLD-primary text-statuses-ON_HOLD-text');
        break;
      case OrderStatus.ASSIGNED:
        setStatusColor('bg-statuses-ASSIGNED-primary text-statuses-ASSIGNED-text');
        break;
      case OrderStatus.DELIVERED:
        setStatusColor('bg-statuses-DELIVERED-primary text-statuses-DELIVERED-text');
        break;
      case OrderStatus.READY_FULL_PICKUP:
        setStatusColor('bg-statuses-READY_FULL_PICKUP-primary text-statuses-READY_FULL_PICKUP-text');
        break;
      case OrderStatus.DUMPED:
        setStatusColor('bg-statuses-DUMPED-primary text-statuses-DUMPED-text');
        break;
      case OrderStatus.COMPLETED:
        setStatusColor('bg-statuses-COMPLETED-primary text-statuses-COMPLETED-text');
        break;
      default:
        setStatusColor('');
        break;
    }
  }, [order.status]);

  const checkEnabled = (value: string) => {
    const currentIndex = popperOptionsAll.indexOf(order.status);
    const newIndex = popperOptionsAll.indexOf(value);
    if (godModeActive || (newIndex === popperOptionsAll.length - 1 && currentIndex === popperOptionsAll.length - 1)) {
      return true;
    }
    return newIndex < currentIndex || newIndex === currentIndex + 1;
  };

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (order.status === OrderStatus.NEEDS_REVIEW) {
      history.push(routes.orders.review(order.id));
      return;
    }
    if (navigateOnly) {
      history.push(routes.orders.details(order.id));
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTitle = () => {
    let text = OrderStatusText[order.status];
    if (order.status === OrderStatus.DUMPED) text = 'enter ticket';
    return text;
  };

  return (
    <div>
      <button
        className={`btn-base w-28 h-8 whitespace-nowrap rounded ${className} ${statusColor}`}
        type="button"
        onClick={(e) => handleClick(e)}
      >
        {getTitle()}
      </button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {orderStatusOptions.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            selected={item.value === order.status}
            onClick={() => {
              handleClose();
              onChange(item.value);
            }}
            disabled={!checkEnabled(item.value)}
            dense
          >
            {titleCase(item.label)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default OrderStatusPopper;
