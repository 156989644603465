import React, { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { V1 } from '@alliance-disposal/pricing';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { Button, CurrencyTextField, Dialog, Select, SelectOption, TextField } from '@wayste/sour-ui';
import {
  formatServiceAddress,
  formatUSD,
  getDateFormat,
  moneyFormatter,
  pricingBreakdownTotal,
  round,
  useYupValidationResolver,
} from '@wayste/utils';
import { PlusIcon } from '@heroicons/react/20/solid';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/solid';
import { differenceInCalendarDays, format } from 'date-fns';
import { Controller, FormProvider, useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import { useConfirmationDialog } from '../../contexts';
import { OrderStatus } from '../../utils';
import { ccRate } from '../../utils/pricing-utils';
import { haulerChargeItems, priceTypesEnums } from '../../utils/shared-types';
import InternalOrderNotes from '../InternalOrderNotes';
import OrderImageHandler from '../OrderImageHandler';

const validationSchema = Yup.object().shape({
  priceType: Yup.string().oneOf(Object.keys(priceTypesEnums)),
  extensionDays: Yup.number(),
  rentExtensionFeeDollars: Yup.number(),
  bills: Yup.array().of(
    Yup.object().shape({
      invoiceNumber: Yup.string().required('A Hauler Invoice Number is Required'),
      lineItems: Yup.array().of(
        Yup.object().shape({
          itemName: Yup.string().oneOf(Object.keys(haulerChargeItems)).required('An item is required'),
          totalPriceDollars: Yup.number().typeError('A total is required').required('A total is required'),
        }),
      ),
    }),
  ),
});

const HaulerCostMenuItems = Object.entries(haulerChargeItems).map((item) => (
  <SelectOption key={`billItems-${item[0]}`} value={item[0]}>
    {item[1]}
  </SelectOption>
));

const blankHaulerCost = {
  itemName: '',
  quantity: '',
  unitPriceDollars: '',
  description: '',
  totalPriceDollars: '',
  taxable: false,
};

interface FormProps {
  priceType: 'ton' | 'flat' | 'yard';
  actualWeightDumped?: number | '';
  extensionDays?: number;
  rentExtensionFeeDollars?: number;
  invoiceNumber?: string;
  bills: any[];
}

interface Props {
  order: Order.AllianceOrderTransport;
  open: boolean;
  onCancel: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Ugh fine.
  onSave: (updateObj: any, updatedInvoices: any[], updatedBills: any[]) => void;
}

const OrderEnterTicket = ({ order, open, onCancel, onSave }: Props) => {
  const [receivables, setReceivables] = useState<Invoice.ReceivableTransport[]>([]);
  const [orderWithInvoice, setOrderWithInvoice] = useState<
    | (Order.AllianceOrderTransport & {
        bills: Invoice.PayableTransport[];
        receivables: Invoice.ReceivableTransport[];
      })
    | null
  >(null);
  const [payables, setPayables] = useState<Invoice.PayableTransport[]>([]);
  const client = useWaysteClient();
  const { getConfirmation } = useConfirmationDialog();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getOrdersWithInvoice = async (order: Order.AllianceOrderTransport) => {
    const receivables = await client.invoice().adminPortal.receivable.query({
      orderID: order.id,
    });
    setReceivables(receivables);

    const payables = await client.invoice().adminPortal.payable.query({
      orderID: order.id,
    });
    setPayables(payables);

    return {
      ...order,
      receivables: receivables,
      bills: payables,
    };
  };

  useEffect(() => {
    (async () => {
      if (order) {
        const orderWithInvoice = await getOrdersWithInvoice(order);
        setOrderWithInvoice(orderWithInvoice);
      }
    })();
  }, [order]);

  useEffect(() => {
    if (!orderWithInvoice) return;
    const defaults = prepareFormData(orderWithInvoice);
    methods.reset(defaults);
  }, [orderWithInvoice]);

  const prepareFormData = (
    data: Order.AllianceOrderTransport & {
      bills: Invoice.PayableTransport[];
      receivables: Invoice.ReceivableTransport[];
    },
  ) => {
    const endDate = (order.rentalEndDate || order.expectedPickupDate)?.replace(/-/g, '/');

    // calculate extension days
    const daysOver = !endDate
      ? 0
      : differenceInCalendarDays(new Date(endDate), new Date(order.expectedDeliveryDate?.replace(/-/g, '/'))) -
        order.adjustedRentalPeriod.value;

    const newData: {
      priceType: 'ton' | 'flat' | 'yard';
      actualWeightDumped?: number | '';
      extensionDays?: number;
      rentExtensionFeeDollars?: number;
      invoiceNumber?: string;
      bills: any[];
    } = {
      priceType: data.priceType ? (data.priceType as 'ton' | 'flat' | 'yard') : 'ton',
      actualWeightDumped: data.actualWeightDumped?.value || '',
      rentExtensionFeeDollars: data.rentExtensionFeeDollars || 0,
      extensionDays: daysOver > 0 ? daysOver : 0,
      invoiceNumber: '',
      bills: [],
    };

    if (data.bills && data.bills.length > 0) {
      newData.bills = [];
      data.bills.forEach((bill: Invoice.PayableTransport) => {
        const newBill = {
          ...bill,
          lineItems: [] as Invoice.LineItemInputTransport[],
        };
        bill.invoiceDetails.lineItems.forEach((item: Invoice.LineItemInputTransport) => {
          newBill.lineItems.push({
            ...item,
            totalPriceDollars: round((item.unitPriceDollars || 0) * item.quantity),
          });
        });
        newData.bills.push(newBill);
      });
    } else {
      const requiredBill = {
        invoiceNumber: '',
        lineItems: [
          {
            ...blankHaulerCost,
            itemName: 'haul',
          },
          {
            ...blankHaulerCost,
            itemName: 'dump',
          },
        ],
      };
      newData.bills.push(requiredBill);
    }

    return newData;
  };

  const methods = useForm<FormProps>({
    mode: 'all',
    defaultValues: {},
    resolver: useYupValidationResolver(validationSchema),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, isDirty, errors },
    watch,
  } = methods;

  // Use useFieldArray to handle dynamic array fields
  const { fields: billFields, append: appendBill } = useFieldArray({
    control,
    name: 'bills',
  });

  const watchBills = useWatch({ name: 'bills', control });
  const watchActualWeightDumped = watch('actualWeightDumped');

  const rentalExtHandler = async (lastInvoiceIndex: number, values: FormProps) => {
    if (JSON.stringify(receivables).includes('Rental Extension')) {
      const confirmed = await getConfirmation({
        title: 'Rental Extension Already Exists',
        message:
          'An invoice in this Order already includes a Rental Extension line item. Are you sure you want to add another?',
      });
      if (!confirmed) return null;
    }
    const invoiceCCItem = receivables[lastInvoiceIndex].invoiceDetails.lineItems.find(
      (item) => item.itemName === 'CC Fee',
    );
    const priceBreakdown = pricingBreakdownTotal(
      round(
        (values.extensionDays ? +values.extensionDays : 0) *
          (values.rentExtensionFeeDollars ? +values.rentExtensionFeeDollars : 0),
      ),
      values.extensionDays ? +values.extensionDays : 0,
      0,
      invoiceCCItem ? ccRate : 0,
    );
    const rentExtItem = {
      itemName: 'Rental Extension',
      quantity: priceBreakdown.quantity,
      unitPriceDollars: priceBreakdown.unitPriceDollars,
      description: `${values.extensionDays} days`,
      totalPriceDollars: round(priceBreakdown.quantity * priceBreakdown.unitPriceDollars),
      taxable: false,
    };
    return {
      totalPriceDollars: +round(
        (values.extensionDays ? +values.extensionDays : 0) *
          (values.rentExtensionFeeDollars ? +values.rentExtensionFeeDollars : 0),
      ),
      item: rentExtItem,
      ccFee: priceBreakdown.ccFee,
    };
  };

  const overageHandler = async (lastInvoiceIndex: number, values: FormProps) => {
    if (JSON.stringify(receivables).includes('Overage')) {
      const confirmed = await getConfirmation({
        title: 'Overage Already Exists',
        message:
          'An invoice in this Order already includes an Overage line item. Are you sure you want to add another?',
      });
      if (!confirmed) return null;
    }

    const totalPriceDollars =
      ((Number(values.actualWeightDumped) || 0) - (Number(order?.weightLimit?.value) || 0)) *
      (Number(order.overageDollars) || 0);
    const invoiceCCItem = receivables[lastInvoiceIndex].invoiceDetails.lineItems.find(
      (item) => item.itemName === 'CC Fee',
    );
    const priceBreakdown = pricingBreakdownTotal(
      ((Number(values.actualWeightDumped) || 0) - (Number(order?.weightLimit?.value) || 0)) *
        (Number(order.overageDollars) || 0),
      (Number(values.actualWeightDumped) || 0) - (Number(order.weightLimit?.value) || 0),
      0,
      invoiceCCItem ? ccRate : 0,
    );
    const overageItem = {
      itemName: 'Overage',
      quantity: priceBreakdown.quantity,
      unitPriceDollars: priceBreakdown.unitPriceDollars,
      description: `${priceBreakdown.quantity} tons over the ${order.weightLimit?.value} ton weight limit.`,
      totalPriceDollars: round(priceBreakdown.quantity * priceBreakdown.unitPriceDollars),
      taxable: false,
    };
    return {
      totalPriceDollars: +totalPriceDollars,
      item: overageItem,
      ccFee: priceBreakdown.ccFee,
    };
  };

  const onSubmit = async (values: FormProps) => {
    setIsSubmitting(true);
    let rentExtItem = null;
    let overageItem = null;

    const lastInvoiceNumber = Math.max(...receivables.map((item) => +(item?.invoiceDetails?.invoiceNumber || 0)));

    const updatedBills: any = [];
    values.bills.forEach((bill) => {
      const cleanedBill = bill;
      if (!bill.haulerID) {
        cleanedBill.haulerID = order.haulerID;
        cleanedBill.vendorName = order.vendorName;
        cleanedBill.orderID = order.id;
      }
      cleanedBill.lineItems = cleanedBill.lineItems.map((item: any) => ({
        ...item,
        quantity: Number(item.quantity),
        unitPriceDollars: Number(item.unitPriceDollars),
        totalPriceDollars: Number(item.totalPriceDollars),
      }));
      cleanedBill.taxRate = order.taxRate;
      if (cleanedBill.lineItems.length === 0) return;
      cleanedBill.lineItems = cleanedBill.lineItems.map((item: any) => {
        if (
          (!item.quantity && !item.unitPriceDollars) ||
          (item.quantity !== '' &&
            item.unitPriceDollars !== '' &&
            round(item.quantity * item.unitPriceDollars) !== item.totalPriceDollars)
        ) {
          return {
            ...item,
            quantity: 1,
            unitPriceDollars: item.totalPriceDollars,
          };
        }
        return item;
      });

      const billObject = {
        ...cleanedBill,
      };

      updatedBills.push(billObject);
    });

    const weightDumped = values.actualWeightDumped ? Number(values.actualWeightDumped) : '';

    const updateObj: any = {
      rentExtensionFeeDollars: values.rentExtensionFeeDollars,
      actualWeightDumped: weightDumped == null || weightDumped == '' ? null : { value: weightDumped, unit: 'TONS' },
    };

    // Check to see if all needed info from hauler has been entered
    if (
      values.bills.some((billItem) => billItem.lineItems.some((item: any) => item.itemName === 'haul')) &&
      values.bills.some((billItem) => billItem.lineItems.some((item: any) => item.itemName === 'dump')) &&
      (order.priceType === priceTypesEnums.ton
        ? Number(values.actualWeightDumped) || Number(values.actualWeightDumped) === 0
        : true)
    ) {
      updateObj.status = OrderStatus.COMPLETED;
    }

    console.log('Order update payload', updateObj);
    let newCCFee = 0;
    // Check to see if an invoice line item needs to be created for rental extension fee

    if (
      values.extensionDays &&
      values.extensionDays > 0 &&
      values.rentExtensionFeeDollars &&
      values.rentExtensionFeeDollars > 0
    ) {
      const res = await rentalExtHandler(lastInvoiceNumber - 1, values);

      if (res) {
        rentExtItem = res.item;
        newCCFee = round(newCCFee + res.ccFee);
      }
    }

    // Check to see if an invoice line item needs to be created for overage fee
    if (
      Number(values?.actualWeightDumped || 0) > Number(order?.weightLimit?.value || 0) &&
      (Number(order.overageDollars) > 0 || Number(order.overageDollars) < 0)
    ) {
      const res = await overageHandler(lastInvoiceNumber - 1, values);
      if (res) {
        overageItem = res.item;
        newCCFee = round(newCCFee + res.ccFee);
      }
    }
    // Create the invoice line items for rental extension and/or overage fees

    let updatedInvoices: any[] = [];

    if (rentExtItem || overageItem) {
      updatedInvoices = [...receivables];
      let newItems: any[] = [];
      if (rentExtItem) newItems = [...newItems, { ...rentExtItem }];
      if (overageItem) newItems = [...newItems, { ...overageItem }];
      // if there is a draft we update it otherwise we create a new invoice
      if (receivables[lastInvoiceNumber - 1].invoiceDetails.status === 'DRAFT') {
        const ccFeeItemIndex = updatedInvoices[lastInvoiceNumber - 1].lineItems?.findIndex(
          (item: any) => item.itemName === 'CC Fee',
        );
        if (ccFeeItemIndex >= 0) {
          const lineItemsCopy = [...updatedInvoices[lastInvoiceNumber - 1].lineItems];
          lineItemsCopy[ccFeeItemIndex] = {
            ...lineItemsCopy[ccFeeItemIndex],
            unitPriceDollars: round(lineItemsCopy[ccFeeItemIndex].unitPriceDollars + newCCFee),
            totalPriceDollars: round(lineItemsCopy[ccFeeItemIndex].totalPriceDollars + newCCFee),
          };
          updatedInvoices[lastInvoiceNumber - 1].lineItems = lineItemsCopy;
        }
        updatedInvoices[lastInvoiceNumber - 1].lineItems = [
          ...updatedInvoices[lastInvoiceNumber - 1].lineItems,
          ...newItems,
        ];

        console.log('Updated Invoices', updatedInvoices);
      } else {
        if (newCCFee) {
          newItems.push({
            itemName: 'CC Fee',
            quantity: 1,
            description: '',
            unitPriceDollars: newCCFee,
            totalPriceDollars: newCCFee,
            taxable: false,
          });
        }

        const newInvoice: Invoice.ReceivableCreateTransport = {
          customerID: order.allianceCustomerID,
          invoiceDetails: {
            lineItems: newItems,
            taxRate: receivables[lastInvoiceNumber - 1].invoiceDetails.taxRate,
            orderID: order.id,
            orderNumber: order.orderNumber?.toString(),
            invoiceNumber: (lastInvoiceNumber + 1).toString(),
            taxAmountDollars: newItems.reduce(
              (total, item) =>
                round(
                  total +
                    (item.taxable
                      ? item.totalPriceDollars * receivables[lastInvoiceNumber - 1].invoiceDetails.taxRate
                      : 0),
                ),
              0,
            ),
          },
        };

        console.log('New invoices', newInvoice);

        updatedInvoices.push(newInvoice);
      }
    }

    console.log('UPDATED order, invoices,bills', updateObj, updatedInvoices, updatedBills);

    onSave(updateObj, updatedInvoices, updatedBills);
  };

  const onAddAnotherPayableClicked = () => {
    appendBill({
      invoiceNumber: '',
      bill: billFields[billFields.length - 1].bill + 1,
      lineItems: [],
    });
  };

  if (receivables.length < 1 || !order) return <div>Error missing invoices or order</div>;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      styledTitle="Enter Hauler Invoice &amp; Dump Ticket"
      className="max-w-screen-2xl"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>Enter the expense information for order {order.orderNumber}:</div>
          <div className="my-2">{formatServiceAddress(order.serviceLocation.address)}</div>
          <div>
            {`Delivered: ${format(new Date(getDateFormat(order.expectedDeliveryDate)), 'EEE MM/dd/yy')} | Picked Up: ${
              order.expectedPickupDate ? format(new Date(getDateFormat(order.expectedPickupDate)), 'EEE MM/dd/yy') : ''
            }`}
          </div>
          <OrderImageHandler order={order} inline showUploadDropZones />
          <InternalOrderNotes order={order} hideAddButton collapsible />
          <div className="grid gap-4">
            <div className="w-full">
              <h6 className="text-xl font-medium">Info from Hauler</h6>
            </div>
            {order.priceType === priceTypesEnums.ton ? (
              <div className="w-full">
                <div className="max-w-[250px]">
                  <TextField
                    label="Tons dumped"
                    required
                    inputProps={{
                      ...register('actualWeightDumped', { required: true }),
                      step: '0.01',
                    }}
                    type="number"
                    error={errors?.actualWeightDumped}
                  />
                </div>
              </div>
            ) : null}
            <hr />
            {billFields.map((bill, billIndex) => (
              <React.Fragment key={bill.id}>
                <PayableGroup
                  bill={bill}
                  billIndex={billIndex}
                  order={order}
                  watchActualWeightDumped={watchActualWeightDumped}
                />
                {billIndex !== billFields.length - 1 && <hr />}
              </React.Fragment>
            ))}
            <div className="flex flex-row justify-center items-center w-full">
              <hr className="w-full" />
              <div className="px-2 flex flex-row justify-center items-center space-x-2">
                <Button
                  className="btn-secondary-text-only whitespace-nowrap"
                  onClick={onAddAnotherPayableClicked}
                  startIcon={<PlusCircleIcon />}
                >
                  Add another payable from hauler
                </Button>
              </div>
              <hr className="w-full" />
            </div>
            <div className="w-full">
              <div>
                <p className="text-xs">
                  (if invoice is from a Different Hauler you must add it via the Billing Screen)
                </p>
              </div>
            </div>
            <CustomerInfoTable order={order} watchActualWeightDumped={watchActualWeightDumped} />
          </div>
          <div className="flex gap-4 justify-end mt-4 pt-4 border-t">
            <button onClick={onCancel} className="btn-dark-grey-outlined" type="button" disabled={isSubmitting}>
              Cancel
            </button>
            <Button
              loading={isSubmitting}
              type="submit"
              className="btn-primary"
              disabled={
                isSubmitting ||
                !isValid ||
                !isDirty ||
                !watchBills.some((billItem) => billItem.lineItems.some((item: any) => item.itemName === 'haul')) ||
                !watchBills.some((billItem) => billItem.lineItems.some((item: any) => item.itemName === 'dump'))
              }
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default OrderEnterTicket;

interface PayableGroupProps {
  bill: any;
  billIndex: number;
  order: Order.AllianceOrderTransport;
  watchActualWeightDumped?: number | '';
}

const PayableGroup = ({ bill, billIndex, order, watchActualWeightDumped }: PayableGroupProps) => {
  const { control } = useFormContext();
  const watchLineItems = useWatch({ name: `bills[${billIndex}].lineItems`, control });
  const {
    fields: lineItemFields,
    append: appendLineItem,
    remove: removeLineItem,
  } = useFieldArray({
    control,
    name: `bills[${billIndex}].lineItems`,
  });

  const getChargeItemHelperText = (itemName: any) => {
    console.log('getChargeItemHelperText', itemName);
    if (order.haulerPricingSnapshot) {
      if (order.haulerPricingSnapshot?.tonLimit.value) {
        const subtotalValue = V1.quotedPriceBuild(
          order.haulerPricingSnapshot?.priceType === priceTypesEnums.yard
            ? order.haulerPricingSnapshot.size
            : order.haulerPricingSnapshot?.tonLimit.value,
          order.haulerPricingSnapshot.dump,
          order.haulerPricingSnapshot.haul,
          0,
          0,
          false,
          0,
        ).total;
        if (itemName === 'haul') {
          return `Should be ${moneyFormatter(subtotalValue)}`;
        }
        if (itemName === 'dump') {
          if ((Number(watchActualWeightDumped) || 0) <= order.haulerPricingSnapshot?.tonLimit.value) {
            return `Should be ${moneyFormatter(0)}`;
          }
          return `Should be ${moneyFormatter(
            (order.haulerPricingSnapshot.dump ? order.haulerPricingSnapshot.dump : 0) *
              ((Number(watchActualWeightDumped) || 0) - order.haulerPricingSnapshot?.tonLimit.value),
          )}`;
        }
        return null;
      }
      if (itemName === 'haul') {
        return `Should be ${moneyFormatter(order.haulerPricingSnapshot.haul)}`;
      }
      if (itemName === 'dump') {
        return `Should be ${moneyFormatter(
          (watchActualWeightDumped
            ? (order.haulerPricingSnapshot.dump ? order.haulerPricingSnapshot.dump : 0) * +watchActualWeightDumped
            : order.haulerPricingSnapshot.dump) || undefined,
        )}`;
      }
    }
    return null;
  };

  const getChargeItemError = (item: any) => {
    if (!item || !order.haulerPricingSnapshot || !+item?.totalPriceDollars) return null;
    if (item.itemName === 'haul') {
      const subtotalValue = V1.quotedPriceBuild(
        order.haulerPricingSnapshot?.priceType === priceTypesEnums.yard
          ? order.haulerPricingSnapshot.size
          : order.haulerPricingSnapshot?.tonLimit.value,
        order.haulerPricingSnapshot.dump,
        order.haulerPricingSnapshot.haul,
        0,
        0,
        false,
        0,
      ).total;
      if (
        order.haulerPricingSnapshot?.tonLimit.value
          ? +item.totalPriceDollars * 100 !== subtotalValue
          : +item.totalPriceDollars * 100 !== order.haulerPricingSnapshot.haul
      ) {
        return <div className="text-red-500 text-xs ml-3.5 mt-1">Haul does not match what was quoted.</div>;
      }
    }
    if (item.itemName === 'dump') {
      if (
        order.haulerPricingSnapshot?.tonLimit.value
          ? moneyFormatter(+item.totalPriceDollars * 100) !==
            moneyFormatter(
              (order.haulerPricingSnapshot.dump ? order.haulerPricingSnapshot.dump : 0) *
                ((Number(watchActualWeightDumped) || 0) - order.haulerPricingSnapshot?.tonLimit.value),
            )
          : moneyFormatter(item.totalPriceDollars * 100) !==
            moneyFormatter(
              (watchActualWeightDumped
                ? (order.haulerPricingSnapshot.dump ? order.haulerPricingSnapshot.dump : 0) * +watchActualWeightDumped
                : order.haulerPricingSnapshot.dump) || 0,
            )
      ) {
        return <div className="text-red-500 text-xs ml-3.5 mt-1">Dump does not match what was quoted.</div>;
      }
    }
    return null;
  };

  const getPayableTotal = () => {
    return watchLineItems.reduce(
      (accumulator: any, currentValue: any) => accumulator + Number(currentValue.totalPriceDollars),
      0,
    );
  };

  return (
    <div className="w-full grid gap-4">
      {bill.haulerID && bill.haulerID !== order.haulerID ? (
        <div className="w-full">
          <p>Bill from {bill.vendorName}</p>
        </div>
      ) : null}
      <div className="w-full">
        <div className="max-w-[250px]">
          <Controller
            name={`bills[${billIndex}].invoiceNumber`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Hauler invoice number"
                required
                error={error}
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="w-full grid grid-cols-3 gap-4">
        {lineItemFields.map((lineItem, lineItemIndex) => (
          <React.Fragment key={lineItem.id}>
            <div className="w-full">
              <Controller
                name={`bills[${billIndex}].lineItems[${lineItemIndex}].itemName`}
                control={control}
                render={({ field }) => (
                  <Select
                    label="Charge Item"
                    onSelect={(e) => {
                      field.onChange(e);
                    }}
                    value={field.value}
                  >
                    {HaulerCostMenuItems}
                  </Select>
                )}
              />
            </div>
            <div className="w-full">
              <Controller
                name={`bills[${billIndex}].lineItems[${lineItemIndex}].totalPriceDollars`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CurrencyTextField
                    label="Amount"
                    required
                    value={field.value.toString()}
                    onChange={(value) => field.onChange(value)}
                    helperText={getChargeItemHelperText(watchLineItems[lineItemIndex]?.itemName) || undefined}
                    error={error}
                  />
                )}
              />
              {getChargeItemError(watchLineItems[lineItemIndex])}
            </div>
            <div className="w-full flex">
              <button className="btn-icon ml-0" onClick={() => removeLineItem(lineItemIndex)} type="button">
                <TrashIcon className="h-6 w-6 text-delete" />
              </button>
            </div>
          </React.Fragment>
        ))}
        <div className="text-right font-bold">Payable total:</div>
        <div>{formatUSD(getPayableTotal())}</div>
      </div>
      <div className="w-full">
        <Button
          className="btn-secondary-text-only whitespace-nowrap text-sm"
          onClick={() => appendLineItem(blankHaulerCost)}
          startIcon={<PlusIcon />}
          disabled={bill.amountPaidDollars > 0}
        >
          Add another item to hauler bill
        </Button>
      </div>
    </div>
  );
};

interface CustomerInfoTableProps {
  order: Order.AllianceOrderTransport;
  watchActualWeightDumped?: number | '';
}

const CustomerInfoTable = ({ order, watchActualWeightDumped }: CustomerInfoTableProps) => {
  const [showExtInputs, setShowExtInputs] = useState(false);
  const { setValue, register, watch, trigger } = useFormContext();

  const watchExtensionDays = watch('extensionDays');
  const watchRentExtensionFeeDollars = watch('rentExtensionFeeDollars');
  console.log(watchExtensionDays, watchRentExtensionFeeDollars);

  useEffect(() => {
    if (!order.rentalEndDate) return;
    const daysOver =
      differenceInCalendarDays(new Date(order.rentalEndDate), new Date(order.expectedDeliveryDate)) -
      order.adjustedRentalPeriod.value;
    if (daysOver > 0) {
      setShowExtInputs(true);
      setValue('extensionDays', daysOver);
      setValue('rentExtensionFeeDollars', order.rentExtensionFeeDollars);
    }
  }, [order.rentalEndDate, order.expectedDeliveryDate, order.adjustedRentalPeriod, order.rentExtensionFeeDollars]);

  return (
    <>
      <div className="w-full">
        <h6 className="text-xl font-medium">Info for Customer</h6>
      </div>
      {order.priceType === priceTypesEnums.ton &&
      watchActualWeightDumped &&
      order.weightLimit &&
      order.weightLimit?.value >= 0 &&
      +watchActualWeightDumped > +order.weightLimit.value ? (
        <div className="grid grid-cols-3 gap-4">
          <div className="w-full">
            <TextField
              label={`Tons over ${order.weightLimit.value} ton limit`}
              inputProps={{
                value: round(+watchActualWeightDumped - +order.weightLimit.value),
                onChange: () => null,
                disabled: true,
              }}
            />
          </div>
          <div className="w-full">
            <CurrencyTextField
              label="Overage fee"
              value={order?.overageDollars?.toString() || ''}
              onChange={() => null}
              inputProps={{
                disabled: true,
              }}
            />
          </div>
          <div className="w-full">
            <CurrencyTextField
              label="Overage total"
              value={round(
                (+watchActualWeightDumped - +order.weightLimit.value) *
                  (order.overageDollars ? +order.overageDollars : 0),
              ).toString()}
              onChange={() => null}
              inputProps={{
                disabled: true,
              }}
            />
          </div>
        </div>
      ) : null}
      {showExtInputs ? (
        <div className="grid grid-cols-3 gap-4">
          <div className="w-full">
            <TextField
              label="Rental extension days"
              inputProps={{
                ...register('extensionDays'),
                disabled: true,
              }}
            />
          </div>
          <div className="w-full">
            <CurrencyTextField
              label="Extension daily fee"
              value={watchRentExtensionFeeDollars.toString()}
              onChange={() => null}
              inputProps={{
                disabled: true,
              }}
            />
          </div>
          <div className="w-full">
            <CurrencyTextField
              label="Extension fee total"
              value={round(+watchExtensionDays * +watchRentExtensionFeeDollars).toString()}
              onChange={() => null}
              inputProps={{
                disabled: true,
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
