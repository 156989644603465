import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Hauler, Invoice, Order, Pricing } from '@alliance-disposal/transport-types';
import { Loading } from '@wayste/sour-ui';
import { buildAddressString, getDateFormat, getDispatchEmailsString } from '@wayste/utils';
import { getPrimaryCustomerContact } from '@wayste/utils';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, Radio, TextField } from '@mui/material';
import { format } from 'date-fns';
import { round } from 'lodash';
import { ORDER_CONFIRMATION_RO, RO_READY_PICKUP, sendDynamicEmail } from '../../../axios/sendgrid';
import { sendEmail } from '../../../axios/ses';
import { getPublicLinkShortCode } from '../../../axios/wayste-backend';
import { UIContext } from '../../../contexts';
import { useAuthToken } from '../../../hooks/authhook';
import {
  OrderStatus,
  aggregateMaterialKeys,
  createProhibitedItemList,
  getCustomerCCAddresses,
  materials,
  noReplyEmail,
  orderCancellationEmailCustomer,
  orderCancellationEmailHauler,
  paymentMethodsEnums,
} from '../../../utils';
import Dialog from '../../Dialog';

const StatusEnum: { [x in keyof typeof OrderStatus]: number } = {
  CANCELLED: -1,

  NEEDS_REVIEW: 0,
  UNASSIGNED: 0,
  ON_HOLD: 0,

  ASSIGNED: 2,
  DELIVERED: 3,
  READY_FULL_PICKUP: 4,
  READY_EMPTY_PICKUP: 4,
  PICKED_UP_FULL: 5,
  PICKED_UP_EMPTY: 5,
  DUMPED: 6,
  COMPLETED: 7,
};
const ResendEmailModalForm = ({ selectedOrder }: ResendEmailModalProps) => {
  const { token } = useAuthToken();
  const waysteClient = useWaysteClient();
  const { showFlash } = useContext(UIContext) as any;
  const [loading, setLoading] = useState(false);
  const client = useWaysteClient();
  const userProfile = client.user().get();
  const orderStatus = StatusEnum[selectedOrder.status as keyof typeof StatusEnum] || 0;

  const isSwitch = selectedOrder.switches && selectedOrder.switches.length >= 1 ? true : false;
  const [customerEmail, setCustomerEmail] = useState<string>();
  const [haulerEmail, setHaulerEmail] = useState<string>();
  const [customer, setCustomer] = useState<Customer.AllianceCustomerTransport>();
  const [zonePricing, setZonePricing] = useState<any>();
  const [haulerPricing, setHaulerPricing] = useState<Pricing.PricingTransport>();
  const [hauler, setHauler] = useState<Hauler.HaulerWithAapDataUpdate>();
  const [receivables, setReceivables] = useState<Invoice.ReceivableTransport[]>();
  const [emailsToSend, setEmailsToSend] = useState<{
    customer: {
      deliveryConfirmation: boolean;
      confirmationWithPayment: boolean;
      deliveryTomorrowConfirmation: boolean;
      pickupConfirmation: boolean;
      readyPickupSwapConfirmation: boolean;
      cancellation: boolean;
    };
    hauler: {
      deliveryConfirmation: boolean;
      pickupConfirmation: boolean;
      switchConfirmation: boolean;
      cancellation?: {
        type: 'delay_pickup' | 'final_removal';
      };
    };
  }>({
    customer: {
      deliveryConfirmation: false,
      confirmationWithPayment: false,
      deliveryTomorrowConfirmation: false,
      pickupConfirmation: false,
      readyPickupSwapConfirmation: false,
      cancellation: false,
    },
    hauler: {
      deliveryConfirmation: false,
      pickupConfirmation: false,
      switchConfirmation: false,
      cancellation: undefined,
    },
  });

  // const haulerPickupEmailPreview = useMemo(() => {}, [])
  useEffect(() => {
    const getInfo = async () => {
      if (!selectedOrder.allianceCustomerID && !selectedOrder.haulerID) {
        showFlash('No customer or hauler found', 'warning');
        return;
      }
      if (!selectedOrder.haulerID) {
        showFlash('No hauler found', 'warning');
        return;
      }
      const fetch = await Promise.allSettled([
        waysteClient.customer().adminPortal.fetch(selectedOrder.allianceCustomerID),
        waysteClient.vendorService().fetchById(selectedOrder?.haulerID),
        waysteClient.invoice().adminPortal.receivable.query({
          orderID: selectedOrder.id,
        }),
      ]);
      const customer = fetch[0];
      const hauler = fetch[1];
      const receivables = fetch[2];

      if (receivables.status !== 'fulfilled' || !receivables.value || !receivables.value) {
        return alert('Failed to fetch receivables');
      }

      setReceivables(receivables.value);

      if (customer.status !== 'fulfilled' || !customer.value || !customer.value) {
        return alert('Failed to fetch customer');
      }
      setCustomer(customer.value);
      const customerEmail = customer.value.contacts.find((contact: any) => contact.primaryContact)?.email;
      if (!customerEmail) {
        return alert('Failed to fetch customer');
      }

      setCustomerEmail(customerEmail);
      if (hauler.status !== 'fulfilled' || !hauler.value || !hauler.value.data) {
        return alert('Failed to fetch hauler');
      }
      setHauler(hauler.value.data);
      const haulerEmail = getDispatchEmailsString(hauler.value.data.contacts);
      if (!haulerEmail) {
        return alert('Failed to fetch hauler email');
      }
      setHaulerEmail(haulerEmail);
      if (selectedOrder.serviceLocation.coordinates && selectedOrder.allianceCustomerID && selectedOrder.haulerID)
        handleGetPricing(
          {
            ...selectedOrder.serviceLocation.coordinates,
            zip: selectedOrder.serviceLocation.address.zip,
            state: selectedOrder.serviceLocation.address.state,
          },
          selectedOrder.allianceCustomerID,
          selectedOrder.haulerID,
        );
    };

    if (selectedOrder.allianceCustomerID && selectedOrder.haulerID) {
      getInfo();
    }
  }, [selectedOrder]);

  useEffect(() => {
    console.log('receivables', receivables);
  }, [receivables]);

  const generatePaymentLink = async (values: Order.AllianceOrderTransport, receivableID: string) => {
    if (values.paymentMethod !== paymentMethodsEnums.check) {
      const response = await getPublicLinkShortCode(receivableID, token);
      if (response.status === 'error') return null;
      return response?.data?.key;
    }
    return null;
  };

  const calcOrderTotal = (receivables?: Invoice.ReceivableTransport[]) => {
    let total = 0;
    receivables?.forEach((invoice: Invoice.ReceivableTransport) => {
      if (!invoice.invoiceDetails.void) {
        total += invoice.invoiceDetails.total;
      }
    });
    return round(total / 100);
  };

  const createWeightLimitString = (values: Order.AllianceOrderTransport) => {
    if (values.weightLimit?.value || values.weightLimit?.value === 0) {
      return `${values.weightLimit.value} tons`;
    } else if (aggregateMaterialKeys.indexOf(values.material) > -1 && +values.expectedSize.size >= 20) {
      return 'You may only fill the dumpster 3/4 of the way. If filled to the top we may refuse pickup or charge an overfilled fee.';
    } else {
      return 'N/A';
    }
  };

  const handleGetPricing = async (
    location: { lat: number; lng: number; zip: string; state: string },
    customerID: string,
    haulerID?: string,
  ) => {
    try {
      // TODO replace this with a query that just gets the public true pricing
      const results = await waysteClient.pricing().adminPortal.location.query(location, customerID);
      //console.log('res2: ', results2);
      console.log('results: ', results);
      if (!results)
        alert(
          "Please check the hauler pricing, there may be no pricing for this hauler or the service location is outside of the hauler's service area",
        );

      if (haulerID) {
        const haulerPricing = results
          .map((item: any) => item as Pricing.PricingTransport)
          .filter((item: any) => item.haulerID === haulerID);

        console.log('haulerPricing: ', haulerPricing);

        if (haulerPricing.length !== 1) {
          console.warn('Number of pricing zones does not equal 1: ', haulerPricing);
          console.warn(
            'all pricing zones',
            results.map((item: any) => item as Pricing.PricingTransport),
          );
          showFlash(
            'Please check the hauler pricing, there is either a zone overlap or no pricing for this hauler at this service location',
            'warning',
          );
        }

        setHaulerPricing(haulerPricing[0]);
      }

      const publicPricing = results.filter((item: any) => item.public === true);

      console.log('publicPricing: ', publicPricing);
      if (publicPricing.length === 1) {
        setZonePricing(publicPricing[0]);
      } else {
        if (publicPricing.length > 1) alert('Get an AAP Dev pricing overlap');
        setZonePricing(null);
      }
    } catch (error) {
      console.warn('handleGetPricing Error: ', error);
      alert('An error occurred, get an AAP dev, touch nothing');
    }
  };

  const onSendEmail = async () => {
    console.log('emailsToSend', emailsToSend);
    setLoading(true);
    const receivableID = receivables?.[0]?.id;
    if (!receivableID) {
      setLoading(false);
      return alert('No receivable found');
    }
    console.log('receivableID', receivableID);
    try {
      if (!customer || !customer.contacts?.find((contact) => contact.primaryContact)?.email) return;
      if (emailsToSend.customer.deliveryConfirmation) {
        // const ccResponse = getCustomerCCAddresses(values);
        const emailData: ORDER_CONFIRMATION_RO = {
          csa_name: userProfile?.firstName ? userProfile.firstName : '',
          delivery_date: format(new Date(getDateFormat(selectedOrder.expectedDeliveryDate)), 'EEEE MM/dd/yy'),
          delivery_instructions: selectedOrder.sharedDeliveryNotes || '',
          dumpster_size: selectedOrder.expectedSize.size + ' YD',
          emailTemplate: 'ORDER_CONFIRMATION_RO',
          first_name: getPrimaryCustomerContact(customer)?.firstName || '',
          material: selectedOrder.material,
          order_total: receivables.length === 0 ? 0 : calcOrderTotal(receivables),
          prohibited_items_string: createProhibitedItemList(
            selectedOrder.material as MaterialEnums,
            zonePricing?.prohibitedItems,
          ),
          rental_extension_fee: selectedOrder.rentExtensionFeeDollars || '',
          rental_period: selectedOrder.adjustedRentalPeriod ? selectedOrder.adjustedRentalPeriod.value : '',
          service_location: buildAddressString(selectedOrder.serviceLocation.address),
          toEmail: getPrimaryCustomerContact(customer)?.email || '',
          weight_limit: createWeightLimitString(selectedOrder),
          overage_fee: selectedOrder.overageDollars || undefined,
          pickup_date: selectedOrder.expectedPickupDate
            ? format(new Date(getDateFormat(selectedOrder.expectedPickupDate)), 'EEEE MM/dd/yy')
            : undefined,
        };

        console.log('emailData', emailData);
        if (
          (typeof emailData.order_total === 'number' && emailData.order_total > 0) ||
          (typeof emailData.order_total === 'string' && parseInt(emailData.order_total) > 0)
        ) {
          const payment_link = await generatePaymentLink(selectedOrder, receivableID);
          emailData.payment_link = payment_link;
        }

        console.log('emailData 2', emailData);
        const customerEmailResponse = await sendDynamicEmail(emailData);
        if (customerEmailResponse.status !== 'success') {
          showFlash('Error sending customer order confirmation email', 'warning');
        } else {
          showFlash('Successfully sent customer order confirmation email', 'success');
        }
      }
      if (emailsToSend.customer.pickupConfirmation) {
        if (!selectedOrder.expectedPickupDate) return;

        const emailData: RO_READY_PICKUP = {
          emailTemplate: 'RO_READY_PICKUP',
          toEmail: customer.contacts?.find((contact) => contact.primaryContact)?.email || '',
          ccEmails: getCustomerCCAddresses(customer, selectedOrder.serviceLocation.address).serviceCCAddresses,
          first_name: customer?.contacts?.find((contact) => contact.primaryContact)?.firstName || '',
          csa_name: userProfile?.firstName ? userProfile.firstName : '',
          prohibited_items_list: createProhibitedItemList(
            selectedOrder.material as MaterialEnums,
            zonePricing?.prohibitedItems,
          ),
          order_number: selectedOrder.orderNumber || '',
          service_location: buildAddressString(selectedOrder.serviceLocation.address),
          pickup_date: format(new Date(getDateFormat(selectedOrder.expectedPickupDate)), 'EEEE MM/dd/yy'),
          pickup_notes: selectedOrder.sharedPickupNotes || '',
        };

        const customerEmailResponse = await sendDynamicEmail(emailData);
        if (customerEmailResponse.status !== 'success') {
          showFlash('Error sending customer pickup email email', 'warning');
        } else {
          showFlash('Successfully sent customer pickup email', 'success');
        }
      }
      if (emailsToSend.customer.cancellation) {
        if (customer) {
          const cancellationEmail = orderCancellationEmailCustomer(customer, selectedOrder);
          try {
            await sendEmail('send-email', cancellationEmail);
            showFlash('Successfully sent customer cancellation email', 'success');
          } catch (error) {
            showFlash('Error Sending customer cancellation email', 'warning');
          }
        }
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      return alert('Something went wrong sending customer emails, please try again');
    }

    try {
      if (!hauler) return;
      if (!haulerPricing) {
        throw new Error(
          'Hauler Pricing not found, please double check the order is assigned to a hauler and that pricing exists for that hauler in for this service location',
        );
      }

      // we need to find the matching material and size
      const haulerMaterial = haulerPricing.pricingData.find((item) => item.material === selectedOrder.material);

      if (!haulerMaterial) {
        throw new Error(
          `Hauler Material not found, please double check the hauler pricing contains the material ${selectedOrder.material}`,
        );
      }

      if (!haulerMaterial.sourgumApproved) {
        throw new Error(`Hauler Material pricing has not been approved by Sourgum.`);
      }

      const haulerSize = haulerMaterial.sizes.find((item) => item.size === String(selectedOrder.expectedSize.size));

      if (!haulerSize) {
        throw new Error(
          `Hauler Size not found, please double check the hauler pricing contains the size ${selectedOrder.expectedSize.size}`,
        );
      }

      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      if (emailsToSend.hauler.deliveryConfirmation) {
        const emailData = [
          { label: 'Order Number', value: selectedOrder.orderNumber },
          { label: 'Delivery Address', value: buildAddressString(selectedOrder?.serviceLocation.address) },
          {
            label: 'Delivery Date',
            value: format(new Date(getDateFormat(selectedOrder.expectedDeliveryDate)), 'EEEE MM/dd/yy'),
          },
          {
            label: 'Pick Up Date',
            value: selectedOrder.expectedPickupDate
              ? format(new Date(getDateFormat(selectedOrder.expectedPickupDate)), 'EEEE MM/dd/yy')
              : null,
          },
          {
            label: 'Delivery Instructions',
            value: `${selectedOrder.sharedDeliveryNotes}. If there are any issues call (732) 366-9355 while still on site.`,
          },
          {
            label: 'Size',
            value: `${selectedOrder.expectedSize.size}  yard dumpster`,
          },
          {
            label: 'Material',
            value: materials[selectedOrder.material as keyof typeof materials],
          },
          { label: 'County', value: selectedOrder.serviceLocation.county },
          {
            label: 'Haul Rate',
            value: formatter.format(selectedOrder.haulerHaulRateDollars || 0),
          },
          {
            label: 'Dump Rate',
            value: formatter.format(selectedOrder.haulerDumpRateDollars || 0),
          },
          {
            label: 'Ton Limit',
            value: `${haulerSize.tonLimit || 0} tons`,
          },
        ];
        if (selectedOrder.sharedDeliveryNotesPrivate) {
          emailData.push({
            label: 'Additional Notes',
            value: selectedOrder.sharedDeliveryNotesPrivate,
          });
        }
        const confirmationUrl = 'https://app.wayste.com/update-alliance/';
        const htmlMessage = `
          <p>Sourgum Waste dumpster rental confirmation details:</p>
          ${emailData
            .map((item) => {
              if (
                (item.label === 'Pick Up Date' && !item.value) ||
                (item.label === 'Dump Rate' && selectedOrder.haulerDumpRateDollars === 0) ||
                (item.label === 'Ton Limit' && !haulerSize.tonLimit)
              )
                return '';
              return `<p><b>${item.label}:</b> ${item.value}</p>`;
            })
            .join('')}
          <p style="margin: 20px 0">
            <a href="${confirmationUrl}?category=delivery_confirmed&id=${
          selectedOrder.id
        }" style="text-decoration: none;color: white;background: #007AFF;padding: 7px 15px;border-radius: 4px;box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);">Click Here to Confirm Delivery</a>
          </p>
          <p>Please click above on the Confirm Delivery button if you are accepting this order.</p>
          <p>If the button does not work, please go to ${confirmationUrl}?category=delivery_confirmed&id=${
          selectedOrder.id
        } or reply Confirmed to this email.</p>
        `;

        const textMessage = `
          Sourgum Waste dumpster rental confirmation details:
          ${emailData
            .map((item) => {
              if (
                (item.label === 'Pick Up Date' && !item.value) ||
                (item.label === 'Dump Rate' && !selectedOrder.haulerDumpRateDollars) ||
                (item.label === 'Ton Limit' && !haulerSize.tonLimit)
              )
                return '';
              return `\n\n${item.label}: ${item.value}`;
            })
            .join('')}
          \n\nPlease go to ${confirmationUrl}?category=delivery_confirmed&id=${
          selectedOrder.id
        } or reply Confirmed to accept this order.
        `;
        try {
          await sendEmail('send-email', {
            htmlMessage: htmlMessage,
            textMessage: textMessage,
            toAddress: getDispatchEmailsString(hauler.contacts),
            subject: `Sourgum Waste Dumpster Confirmation  - ${selectedOrder.orderNumber}`,
            bcc: noReplyEmail,
          });
          showFlash('Successfully sent Hauler delivery confirmation email', 'success');
        } catch (error) {
          showFlash('Error Sending Hauler delivery confirmation Email', 'warning');
        }
      }
      if (emailsToSend.hauler.pickupConfirmation) {
        console.log('pickup hauler');
        if (!selectedOrder.expectedPickupDate) return;
        const haulerEmailItems = [
          { label: 'Pickup Order Number', value: selectedOrder.orderNumber },
          { label: 'Service Address', value: buildAddressString(selectedOrder?.serviceLocation.address) },
          {
            label: `Pickup Date`,
            value: format(new Date(getDateFormat(selectedOrder.expectedPickupDate)), 'EEEE MM/dd/yy'),
          },
          {
            label: `Size`,
            value: `${selectedOrder.expectedSize.size} yard dumpster`,
          },
          {
            label: `Material`,
            value: materials[selectedOrder.material as keyof typeof materials],
          },
          { label: 'County', value: selectedOrder.serviceLocation.county },
          {
            label: 'Pickup Instructions',
            value: `${selectedOrder.sharedPickupNotes}. If there are any issues call (732) 366-9355 while still on site.`,
          },
        ];
        if (selectedOrder.sharedPickupNotesPrivate) {
          haulerEmailItems.push({
            label: 'Additional Notes',
            value: selectedOrder.sharedPickupNotesPrivate,
          });
        }
        const confirmationUrl = 'https://app.wayste.com/update-alliance/';
        const confirmationId = `id=${selectedOrder.id}`;
        const haulerEmailHtml = `
          <p>Sourgum Waste dumpster rental ${'PICKUP FINAL REMOVAL'} confirmation details:</p>
          ${haulerEmailItems
            .map((item) => {
              return `<p><b>${item.label}:</b> ${item.value}</p>`;
            })
            .join('')}
          <p style="margin: 20px 0">
            <a href="${confirmationUrl}?category=pickup_confirmed&${confirmationId}" style="text-decoration: none;color: white;background: #007AFF;padding: 7px 15px;border-radius: 4px;box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);">Click Here to Confirm ${'Pickup'}</a>
          </p>
          <p>Please click above on the Confirm ${'Pickup'} button if you are accepting this order.</p>
          <p>If the button does not work, please go to ${confirmationUrl}?category=pickup_confirmed&${confirmationId} or reply Confirmed to this email.</p>
        `;

        const haulerEmailText = `Sourgum Waste dumpster rental ${'PICKUP FINAL REMOVAL'} confirmation details:
        ${haulerEmailItems
          .map((item) => {
            return `\n\n${item.label}: ${item.value}`;
          })
          .join('')}
        Please go to ${confirmationUrl}?category=pickup_confirmed&${confirmationId} or reply Confirmed to confirm this ${'Pickup'}.`;
        const haulerEmailData = {
          htmlMessage: haulerEmailHtml,
          textMessage: haulerEmailText,
          toAddress: getDispatchEmailsString(hauler.contacts),
          subject: `Pickup Final Removal- ${selectedOrder.orderNumber}`,
          bcc: noReplyEmail,
        };
        try {
          await sendEmail('send-email', haulerEmailData);
          showFlash('Successfully sent Hauler pickup confirmation email', 'success');
        } catch (error) {
          showFlash('Error sending Hauler pickup confirmation email', 'warning');
        }
      }
      if (emailsToSend.hauler.switchConfirmation) {
        const selectedOrderType =
          selectedOrder.status === OrderStatus.ASSIGNED
            ? 'DELIVERY'
            : selectedOrder.status === OrderStatus.READY_FULL_PICKUP
            ? 'PICKUP'
            : undefined;
        if (!selectedOrderType || !selectedOrder.switches || selectedOrder.switches.length === 0) return;

        const switchID =
          selectedOrder.switches[0].toOrder === selectedOrder.id
            ? selectedOrder.switches[0].fromOrder
            : selectedOrder.switches[0].toOrder;

        const fetchSwitch = await client.order().adminPortal.fetch(switchID);

        const switchOrder = fetchSwitch;
        const pickupOrder = selectedOrderType === 'PICKUP' ? selectedOrder : switchOrder;
        const deliveryOrder = selectedOrderType === 'DELIVERY' ? selectedOrder : switchOrder;
        const haulerEmailItems = [
          { label: 'Pickup Order Number', value: pickupOrder.orderNumber },
          {
            label: 'Service Address',
            value: buildAddressString(pickupOrder.serviceLocation.address),
          },
          {
            label: `Dump and Return Date`,
            value: format(new Date(getDateFormat(deliveryOrder.expectedDeliveryDate)), 'EEEE MM/dd/yy'),
          },
          {
            label: `Size`,
            value: `${deliveryOrder.expectedSize.size} yard dumpster`,
          },
          {
            label: `Pickup Material`,
            value: materials[pickupOrder.material as keyof typeof materials],
          },

          { label: 'County', value: pickupOrder.serviceLocation.county },
          {
            label: 'Pickup Instructions',
            value: `${
              pickupOrder.sharedPickupNotes ? 'pickupOrder.pickupNotes. ' : ''
            } If there are any issues call (732) 366-9355 while still on site.`,
          },
          {
            label: 'New Delivery Order Number',
            value: deliveryOrder.orderNumber,
          },
          {
            label: 'Delivery Size',
            value: `${deliveryOrder.expectedSize.size} yard dumpster`,
          },
          {
            label: 'Delivery Instructions',
            value: deliveryOrder.sharedDeliveryNotes,
          },
          {
            label: 'Delivery Material',
            value: materials[deliveryOrder.material as keyof typeof materials],
          },
        ];

        const confirmationUrl = 'https://app.wayste.com/update-alliance/';
        const confirmationId = `id=${pickupOrder.id}&switch=${deliveryOrder.id}`;
        const haulerEmailHtml = `
        <p>Sourgum Waste dumpster rental ${'DUMP AND RETURN'} confirmation details:</p>
        ${haulerEmailItems
          .map((item) => {
            if (!item.value) return '';
            return `<p><b>${item.label}:</b> ${item.value}</p>`;
          })
          .join('')}
        <p style="margin: 20px 0">
          <a href="${confirmationUrl}?category=pickup_confirmed&${confirmationId}" style="text-decoration: none;color: white;background: #007AFF;padding: 7px 15px;border-radius: 4px;box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);">Click Here to Confirm Switch.</a>
        </p>
        <p>Please click above on the Confirm Switch button if you are accepting this order.</p>
        <p>If the button does not work, please go to ${confirmationUrl}?category=pickup_confirmed&${confirmationId} or reply Confirmed to this email.</p>
      `;

        const haulerEmailText = `Sourgum Waste dumpster rental DUMP AND RETURN confirmation details:
      ${haulerEmailItems
        .map((item) => {
          if (!item.value) return '';
          return `\n\n${item.label}: ${item.value}`;
        })
        .join('')}
      Please go to ${confirmationUrl}?category=pickup_confirmed&${confirmationId} or reply Confirmed to confirm this Switch.`;

        const haulerEmailData = {
          htmlMessage: haulerEmailHtml,
          textMessage: haulerEmailText,
          toAddress: getDispatchEmailsString(hauler.contacts),
          subject: `Sourgum Waste Dumpster Swap - ${pickupOrder.orderNumber}`,
          bcc: noReplyEmail,
        };
        try {
          await sendEmail('send-email', haulerEmailData);
          showFlash('Successfully sent Hauler switch confirmation email', 'success');
        } catch (error) {
          showFlash('Error sending Hauler switch confirmation email', 'warning');
        }
      }
      if (emailsToSend.hauler.cancellation) {
        console.log('hauler', emailsToSend.hauler.cancellation);
        const cancellationEmail = orderCancellationEmailHauler(
          hauler,
          selectedOrder,
          isSwitch ? emailsToSend.hauler.cancellation.type : null,
        );
        try {
          await sendEmail('send-email', cancellationEmail);
          showFlash('Successfully sent Hauler cancellation email', 'success');
        } catch (error) {
          showFlash('Error sending Hauler cancellation confirmation email', 'warning');
        }
      }
    } catch (error) {
      setLoading(false);
      showFlash((error as any)?.message || `Something went wrong sending hauler emails, please try again.`, 'warning');
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <Grid container spacing={1.85}>
      {/* CUSTOMER EMAILS */}

      <Grid item xs={12}>
        <TextField
          value={customerEmail || 'No customer email found'}
          disabled
          label="Customer Email"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      {/* CUSTOMER DELIVER OR JUST ORDER, ITS AMBIGUOUS ENOUGH TO JUST BE A GENERIC CONFIRMATION WITH PAYMENT LINK */}
      <Grid xs={12} sx={{ marginX: 2 }}>
        <FormControl>
          <FormControlLabel
            value={emailsToSend.customer.deliveryConfirmation}
            control={
              <Checkbox
                size="small"
                disabled={!customerEmail || !(orderStatus >= StatusEnum.ASSIGNED)}
                checked={emailsToSend.customer.deliveryConfirmation}
                onChange={(_, checked) => {
                  setEmailsToSend({
                    ...emailsToSend,
                    customer: {
                      ...emailsToSend.customer,
                      deliveryConfirmation: checked,
                    },
                  });
                }}
                value={emailsToSend.customer.deliveryConfirmation}
              />
            }
            label="Customer Order Confirmation"
          />
          {emailsToSend.customer.deliveryConfirmation && (
            <FormHelperText>
              This includes payment link if its not yet paid in full. Payment will only be for the FIRST receivable
              associated with the order.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      {/* CUSTOMER PICKUP */}

      <Grid xs={12} sx={{ marginX: 2 }}>
        <FormControl>
          <FormControlLabel
            value={emailsToSend.customer.pickupConfirmation}
            control={
              <Checkbox
                size="small"
                disabled={
                  !customerEmail ||
                  !(orderStatus >= StatusEnum.READY_FULL_PICKUP && orderStatus < StatusEnum.PICKED_UP_FULL)
                }
                checked={emailsToSend.customer.pickupConfirmation}
                onChange={(_, checked) => {
                  setEmailsToSend({
                    ...emailsToSend,
                    customer: {
                      ...emailsToSend.customer,
                      pickupConfirmation: checked,
                    },
                  });
                }}
                value={emailsToSend.customer.pickupConfirmation}
              />
            }
            label="Customer Pickup Order Confirmation"
          />
          {emailsToSend.customer.pickupConfirmation && isSwitch && (
            <FormHelperText>Pickup confirmation will not include payment link.</FormHelperText>
          )}
        </FormControl>
      </Grid>
      {/* CUSTOMER CANCEL */}

      <Grid xs={12} sx={{ marginX: 2 }}>
        <FormControl>
          <FormControlLabel
            value={!!emailsToSend.customer.cancellation}
            control={
              <Checkbox
                size="small"
                disabled={!customerEmail || orderStatus !== StatusEnum.CANCELLED}
                checked={!!emailsToSend.customer.cancellation}
                onChange={(_, checked) =>
                  setEmailsToSend({
                    ...emailsToSend,
                    customer: {
                      ...emailsToSend.customer,
                      cancellation: checked,
                    },
                  })
                }
              />
            }
            label="Customer Order Cancellation"
          />
        </FormControl>
      </Grid>

      {/* HAULER EMAILS */}
      <Grid item xs={12}>
        <TextField
          value={haulerEmail || 'No hauler email found'}
          disabled
          label="Hauler Email"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      {/* HAULER DELIVERY */}

      <Grid xs={12} sx={{ marginX: 2 }}>
        <FormControl>
          <FormControlLabel
            value={emailsToSend.hauler.deliveryConfirmation}
            control={
              <Checkbox
                size="small"
                disabled={
                  !customerEmail || !(orderStatus >= StatusEnum.ASSIGNED && orderStatus < StatusEnum.READY_FULL_PICKUP)
                }
                checked={emailsToSend.hauler.deliveryConfirmation}
                onChange={(_, checked) =>
                  setEmailsToSend({
                    ...emailsToSend,
                    hauler: {
                      ...emailsToSend.hauler,
                      deliveryConfirmation: checked,
                    },
                  })
                }
                value={emailsToSend.hauler.deliveryConfirmation}
              />
            }
            label="Hauler Delivery Confirmation"
          />
        </FormControl>
      </Grid>
      {/* HAULER PICKUP */}

      <Grid xs={12} sx={{ marginX: 2 }}>
        <FormControl>
          <FormControlLabel
            value={emailsToSend.hauler.pickupConfirmation}
            control={
              <Checkbox
                size="small"
                disabled={
                  !haulerEmail ||
                  isSwitch ||
                  !(orderStatus >= StatusEnum.READY_FULL_PICKUP && orderStatus < StatusEnum.PICKED_UP_FULL)
                }
                checked={emailsToSend.hauler.pickupConfirmation}
                onChange={(_, checked) => {
                  setEmailsToSend({
                    ...emailsToSend,
                    hauler: {
                      ...emailsToSend.hauler,
                      pickupConfirmation: checked,
                    },
                  });
                }}
                value={emailsToSend.hauler.pickupConfirmation}
              />
            }
            label="Hauler Pickup Order Confirmation"
          />
        </FormControl>
      </Grid>
      {/* HAULER SWITCH */}

      <Grid xs={12} sx={{ marginX: 2 }}>
        <FormControl>
          <FormControlLabel
            value={emailsToSend.hauler.switchConfirmation}
            control={
              <Checkbox
                size="small"
                disabled={!haulerEmail || !isSwitch}
                checked={emailsToSend.hauler.switchConfirmation}
                onChange={(_, checked) =>
                  setEmailsToSend({
                    ...emailsToSend,
                    hauler: {
                      ...emailsToSend.hauler,
                      switchConfirmation: checked,
                    },
                  })
                }
                value={emailsToSend.hauler.switchConfirmation}
              />
            }
            label="Hauler Switch Confirmation"
          />
        </FormControl>
      </Grid>
      {/* HAULER CANCEL */}

      <Grid xs={12} sx={{ marginX: 2 }}>
        <FormControl>
          <FormControlLabel
            value={!!emailsToSend.hauler.cancellation}
            control={
              <Checkbox
                size="small"
                disabled={!haulerEmail || orderStatus !== StatusEnum.CANCELLED}
                checked={!!emailsToSend.hauler.cancellation}
                onChange={(_, checked) =>
                  setEmailsToSend({
                    ...emailsToSend,
                    hauler: {
                      ...emailsToSend.hauler,
                      cancellation: checked
                        ? {
                            type: 'final_removal',
                          }
                        : undefined,
                    },
                  })
                }
              />
            }
            label="Hauler Order Cancellation"
          />
        </FormControl>
      </Grid>
      {emailsToSend.hauler.cancellation && !isSwitch && (
        <>
          <Grid xs={12} sx={{ marginX: 2, marginLeft: 5 }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Radio
                    size="small"
                    disabled={!haulerEmail || orderStatus !== StatusEnum.CANCELLED}
                    checked={emailsToSend.hauler?.cancellation?.type === 'final_removal'}
                    onChange={(_, checked) =>
                      setEmailsToSend({
                        ...emailsToSend,
                        hauler: {
                          ...emailsToSend.hauler,
                          cancellation: checked
                            ? {
                                type: 'final_removal',
                              }
                            : undefined,
                        },
                      })
                    }
                  />
                }
                label="Final Removal"
              />
            </FormControl>
          </Grid>
          <Grid xs={12} sx={{ marginX: 2, marginLeft: 5 }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Radio
                    size="small"
                    disabled={!haulerEmail || orderStatus !== StatusEnum.CANCELLED}
                    checked={emailsToSend.hauler?.cancellation?.type === 'delay_pickup'}
                    onChange={(_, checked) =>
                      setEmailsToSend({
                        ...emailsToSend,
                        hauler: {
                          ...emailsToSend.hauler,
                          cancellation: checked
                            ? {
                                type: 'delay_pickup',
                              }
                            : undefined,
                        },
                      })
                    }
                  />
                }
                label="Delay Pickup"
              />
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <button className="btn-primary float-right" type="button" onClick={onSendEmail}>
          Send Emails
          {loading && <Loading className="text-white" size="h-4 w-4 ml-2" />}
        </button>
      </Grid>
    </Grid>
  );
};
export type ResendEmailModalProps = {
  open: boolean;
  handleClose: () => void;
  selectedOrder: Order.AllianceOrderTransport;
};
const ResendEmailModal = (props: ResendEmailModalProps) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose} styledTitle="Resend Email">
      <ResendEmailModalForm {...props} />
    </Dialog>
  );
};
export default ResendEmailModal;
