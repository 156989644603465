import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Order, PaginatedResult } from '@alliance-disposal/transport-types';
import { DatePicker, Dialog, Loading, TextField } from '@wayste/sour-ui';
import { formatISODateString } from '@wayste/utils';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Collapse } from '@mui/material';
import { format } from 'date-fns';
import _ from 'lodash';
import { useAlertDialog } from '../../contexts';
import OrderReadyPickUp from '../OrderStatusChanger/OrderReadyPickUp';
import SendEmailDialog from '../OrderUpdate/SendEmailDialog';

export const fieldNameMap: Record<Order.ChangeTransport['field'], string> = {
  expectedDeliveryDate: 'Delivery Date',
  expectedPickupDate: 'Pickup Date',
  sharedPickupNotes: 'Pickup Notes',
  sharedDeliveryNotes: 'Delivery Notes',
  expectedSize: 'Size',
  material: 'Material',
  status: 'Status',
};

export const timeAgo = (date: Date) => {
  const minutesAgo = Math.floor((new Date().getTime() - new Date(date).getTime()) / 60000);
  return new Intl.RelativeTimeFormat('en').format(-minutesAgo, 'minute');
};
interface OrderRequestDialogProps {
  orderID: string;
  open: boolean;
  onClose?: () => void;
  order: Order.AllianceOrderTransport;
  customer: any;
}

const ChangeTableRow = ({
  request,
  onSubmitted,
}: {
  request: Order.ChangeRequestTransport;
  onSubmitted?: (update: Order.UpdateChangeRequest, res: Order.ChangeRequestTransport) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const client = useWaysteClient();
  const alert = useAlertDialog();

  const [changeRequestUpdate, setChangeRequestUpdate] = useState<Order.UpdateChangeRequest>({
    id: request.id,
    changes: request.changes.map((change) => ({
      id: change.id,
      status: change.status,
      notes: '',
      amendment: change.newValue,
    })),
  });

  const submit = async () => {
    setSubmitting(true);

    if (!changeRequestUpdate.changes.every((change) => change.status !== 'PENDING')) {
      console.log('All changes must be approved or denied');
      setSubmitting(false);
      return;
    }

    const update = changeRequestUpdate;

    update.changes.map((change) => {
      // find matching change in request.changes
      const originalChange = request.changes.find((c) => c.id === change.id) as Order.ChangeTransport;

      // make sure the amendment is different from the original value
      if (originalChange.newValue === change.amendment) {
        delete change.amendment;
      } else {
        change.status = 'APPROVED_WITH_CHANGES';
      }

      if (change.notes === '') {
        delete change.notes;
      }

      return change;
    });

    console.log('CR Update Payload', update);

    try {
      const res = await client.adminPortal().order.changes.update(update);
      if (onSubmitted) {
        onSubmitted(update, res);
      }
    } catch (error) {
      alert.getAlert({
        message:
          'Error updating change request, please try again. If the error persists, please contact support. If you are submitting an IT ticket, please include the following error message: ' +
          JSON.stringify(error),
        severity: 'error',
      });
    }

    setSubmitting(false);
  };

  const rejectChange = (id: string) => {
    setChangeRequestUpdate({
      ...changeRequestUpdate,
      changes: changeRequestUpdate.changes.map((change) =>
        change.id === id ? { ...change, status: 'REJECTED' } : change,
      ),
    });
  };

  const acceptChange = (id: string) => {
    setChangeRequestUpdate({
      ...changeRequestUpdate,
      changes: changeRequestUpdate.changes.map((change) =>
        change.id === id ? { ...change, status: 'APPROVED' } : change,
      ),
    });
  };

  function renderChangeValues(change: Order.ChangeTransport) {
    if (change.field === 'expectedDeliveryDate' || change.field === 'expectedPickupDate') {
      return (
        <>
          <td>{change.oldValue ? format(new Date(change.oldValue?.replace(/-/g, '/')), 'MM/dd/yyyy') : 'N/A'}</td>
          <td>
            {edit ? (
              <div className="max-w-[150px]">
                <DatePicker
                  value={
                    changeRequestUpdate.changes.find((c) => c.id === change.id)?.amendment
                      ? new Date(
                          formatISODateString(changeRequestUpdate.changes.find((c) => c.id === change.id)?.amendment),
                        )
                      : ''
                  }
                  format="keyboardDate"
                  onChange={(value) => {
                    setChangeRequestUpdate({
                      ...changeRequestUpdate,
                      changes: changeRequestUpdate.changes.map((c) =>
                        c.id === change.id
                          ? {
                              ...c,
                              amendment: value ? value.toDateString() : value,
                              status: 'PENDING',
                            }
                          : change,
                      ),
                    });
                  }}
                  minDate={new Date()}
                />
              </div>
            ) : (
              formatISODateString(change.newValue)
            )}
          </td>
        </>
      );
    }

    return (
      <>
        <td>{change.oldValue}</td>
        <td>
          {edit ? (
            <TextField
              inputProps={{
                value: changeRequestUpdate.changes.find((c) => c.id === change.id)?.amendment || '',
                onChange: (e) => {
                  setChangeRequestUpdate({
                    ...changeRequestUpdate,
                    changes: changeRequestUpdate.changes.map((c) =>
                      c.id === change.id ? { ...c, amendment: e.target.value, status: 'PENDING' } : change,
                    ),
                  });
                },
                disabled: !edit,
              }}
            />
          ) : (
            change.newValue
          )}
        </td>
      </>
    );
  }

  return (
    <>
      <tr key={request.id} className="[&>*]:py-1.5 [&>*]:px-4 [&>*]:border-b">
        <td>
          <button type="button" className="btn-icon" aria-label="expand row" onClick={() => setOpen(!open)}>
            {open ? <ChevronUpIcon className="h-6 w-6" /> : <ChevronDownIcon className="h-6 w-6" />}
          </button>
        </td>
        <td>{timeAgo(new Date(request.metadata.createdAt))}</td>
        {/* <TableCell>{request.allianceOrder?.orderNumber || "N/A"}</TableCell> */}
        <td>{request.changes.some((change) => change.field === 'status') ? 'Pickup Request' : 'Order Change'}</td>
        <td>
          {/* {request.allianceOrder?.serviceLocation.address.addressLine1}
          {request.allianceOrder?.serviceLocation.address.city},{" "}
          {request.allianceOrder?.serviceLocation.address.state},{" "}
          {request.allianceOrder?.serviceLocation.address.zip} (
          {request.allianceOrder?.serviceLocation.county}) */}
        </td>
        <td>
          {/* Submit Button */}

          {open ? (
            <button
              className="btn-primary w-full"
              type="submit"
              disabled={!changeRequestUpdate.changes.every((change) => change.status !== 'PENDING')}
              onClick={submit}
            >
              Submit
              {submitting && <Loading className="text-white" size="h-4 w-4 ml-2" />}
            </button>
          ) : null}
        </td>
        {/* Collapsible section that shows all changes */}
      </tr>
      <tr key={request.id + 'changes'}>
        <td colSpan={7} className={`pb-0 pt-0 ${!open ? 'none' : ''}`}>
          <Collapse in={open} timeout="auto" unmountOnExit sx={{ px: 0.5, py: 0.5 }}>
            <table className="w-full border-collapse border-spacing-0">
              <thead>
                <tr className="[&>*]:py-1.5 [&>*]:px-4 border-b">
                  <td>Status</td>
                  <td>Change</td>
                  <td>Current</td>
                  <td>Requested</td>
                  {/* <TableCell>Notes to customer</TableCell> */}
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody>
                {request.changes.map((change) => (
                  <tr className="[&>*]:py-1.5 [&>*]:px-4 border-b">
                    <td> {_.startCase(changeRequestUpdate.changes.find((c) => c.id === change.id)?.status)}</td>
                    <td>{fieldNameMap[change.field]}</td>
                    {renderChangeValues(change)}
                    <td className="whitespace-nowrap">
                      <>
                        <button
                          type="button"
                          className="btn-icon"
                          onClick={() => {
                            rejectChange(change.id);
                          }}
                        >
                          <XMarkIcon className="h-6 w-6 text-red-500" />
                        </button>
                        <button
                          type="button"
                          className="btn-icon"
                          onClick={() => {
                            setEdit(!edit);
                          }}
                        >
                          <PencilIcon className="h-6 w-6 text-edit" />
                        </button>
                        <button
                          type="button"
                          className="btn-icon"
                          onClick={() => {
                            acceptChange(change.id);
                          }}
                        >
                          <CheckIcon className="h-6 w-6 text-success" />
                        </button>{' '}
                      </>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Collapse>
        </td>
      </tr>
    </>
  );
};

export const ChangeRequestResponse = ({ orderID, open, onClose, order, customer }: OrderRequestDialogProps) => {
  // prettier-ignore
  const [requests, setRequests] = useState<Order.ChangeRequestTransport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSendEmails, setShowSendEmails] = useState<boolean>(false);
  const [emailChanges, setEmailChanges] = useState<any[]>([]);
  const client = useWaysteClient();
  const userProfile = client.user().get();

  const [showPickupDialog, setShowPickupDialog] = useState<string | false>(false);

  useEffect(() => {
    const sub = client
      .adminPortal()
      .order.changes.subscribe({ status: ['PENDING'], orderID })
      .subscribe((changesRequests: PaginatedResult<Order.ChangeRequestTransport>) => {
        setRequests(changesRequests.results);

        if (changesRequests.results.length === 0 && open && onClose) {
          onClose();
        }
      });
    setLoading(false);
    return () => {
      sub.unsubscribe();
    };
  }, [open, orderID]);

  const schedulePickup = (id: string) => {
    setShowPickupDialog(id);
  };

  const removeChange = (id: string) => {
    setRequests(requests.filter((r) => r.id !== id));
  };

  const changeSubmitted = (update: Order.UpdateChangeRequest, request: Order.ChangeRequestTransport) => {
    setEmailChanges(
      request.changes.map((change) => {
        const updatePayload = update.changes.find((c) => c.id === change.id);

        return {
          label: fieldNameMap[change.field],
          oldValue: change.oldValue,
          newValue: updatePayload?.amendment ? updatePayload.amendment : change.newValue,
        };
      }),
    );

    setShowSendEmails(true);

    setRequests(requests.filter((r) => r.id !== request.id));
  };

  return (
    <Dialog open={open} onClose={() => onClose && onClose()} styledTitle="Change Requests" className="max-w-screen-xl">
      <SendEmailDialog
        open={showSendEmails}
        onClose={() => setShowSendEmails(false)}
        changes={emailChanges}
        customer={customer}
        order={order}
      />
      <hr />
      <div className="w-full overflow-x-auto max-h-[50vh]">
        <table className="w-full border-separate border-spacing-0 min-w-[45vw] text-sm">
          <thead>
            <tr className="[&>*]:px-4 [&>*]:py-1.5 [&>*]:sticky [&>*]:top-0 [&>*]:z-[2] [&>*]:font-normal [&>*]:border-b">
              <th />
              <th>Requested</th>
              <th>Type</th>
              <th />
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading
              ? null
              : [...Array(5)].map(() => {
                  return (
                    <tr>
                      <td colSpan={6} className="text-center [&>*]:px-4 [&>*]:py-1.5 [&>*]:border-b">
                        <Loading />
                      </td>
                    </tr>
                  );
                })}

            {requests.length === 0 && !loading ? (
              <tr>
                <td colSpan={6} className="text-center [&>*]:px-4 [&>*]:py-1.5 [&>*]:border-b">
                  Nothing to see here! 👏🏼
                </td>
              </tr>
            ) : (
              requests.map((request) =>
                request.changes.some((c) => c.field === 'status') ? (
                  <tr className="[&>*]:px-4 [&>*]:py-1.5 [&>*]:border-b">
                    <td />
                    <td>{timeAgo(new Date(request.metadata.createdAt))}</td>
                    <td>Pick Up Request</td>
                    <td />
                    <td>
                      <button
                        className="btn-primary"
                        onClick={() => {
                          schedulePickup(request.id);
                        }}
                      >
                        Schedule Pick Up
                      </button>

                      {showPickupDialog === request.id ? (
                        <OrderReadyPickUp
                          open={true}
                          order={order}
                          user={userProfile}
                          onCancel={() => {
                            setShowPickupDialog(false);
                          }}
                          changeRequest={request}
                          onChangeRequestUpdated={removeChange}
                        />
                      ) : null}
                    </td>
                  </tr>
                ) : (
                  <ChangeTableRow request={request} onSubmitted={changeSubmitted} key={request.id + 'wrapper'} />
                ),
              )
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end gap-4 mt-4">
        <button className="btn-primary" onClick={() => onClose && onClose()}>
          Close
        </button>
      </div>
    </Dialog>
  );
};
