import React from 'react';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

const InputDatePicker = ({ format, inputVariant, componentsProps = {}, ...props }: any) => {
  return (
    <DatePicker
      onChange={props.onChange}
      value={props.value}
      label={props.label}
      disabled={props.disabled}
      disablePast={props.disablePast === false ? false : true}
      orientation="portrait"
      renderInput={(textFieldProps) => <TextField margin="dense" fullWidth {...textFieldProps} {...props} />}
      componentsProps={componentsProps}
    />
  );
};

InputDatePicker.defaultProps = {
  format: 'MM/dd/yy',
  inputVariant: 'standard',
};

export default InputDatePicker;
