import React, { useEffect, useState } from 'react';
import { V1 as sourgumPricingFunctions } from '@alliance-disposal/pricing';
import { Customer, Invoice, Pricing } from '@alliance-disposal/transport-types';
import { Toggle } from '@wayste/sour-ui';
import { formatUSD, round } from '@wayste/utils';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import MuiTextField from '@mui/material/TextField';
import { Field } from 'formik';
import { Select, TextField } from 'formik-mui';
import { useConfirmationDialog } from '../contexts';
import { ccRate, salesTaxExemptMaterials } from '../utils/pricing-utils';
import routes from '../utils/routes';
import { materialEnums, materials, priceTypesEnums, rollOffSizes } from '../utils/shared-types';
import Dialog from './Dialog';
import Radios from './ui/inputs/Radios';

/**
 * All the fields and functions needed to auto calculate the price for a dumpster
 * @param {Function} handleBlur Function fired on tonLimit blur
 * @param {Function} setFieldValue Formik builtin function to set a field value
 * @param {Function} setValues Formik builtin function to set form values
 * @param {Object} values Formik builtin object that holds form values
 * @param {Object} zonePricing Database object with Sourgum pricing for a specific zone
 * @param {Boolean} disabled Should all fields be disabled
 */

export interface OrderPricingFieldsProps {
  handleBlur: any;
  setFieldValue: any;
  setValues: any;
  values: any;
  zonePricing: Pricing.PricingTransport | null;
  disabled?: boolean;
  existingCustomer: Customer.AllianceCustomerTransport | null;
  passedSetOnce?: boolean;
  disableFields?: string[];
  constraints?: boolean;
  swapConstraints?: any;
  showSwitchText?: boolean;
  fromNeedsReview?: boolean;
}

const OrderPricingFields = ({
  setValues,
  values,
  setFieldValue,
  zonePricing,
  handleBlur,
  disabled,
  existingCustomer,
  passedSetOnce,
  disableFields,
  constraints,
  swapConstraints,
  showSwitchText,
  fromNeedsReview,
}: OrderPricingFieldsProps) => {
  const { getConfirmation } = useConfirmationDialog();
  const [tonLimitBlur, setTonLimitBlur] = useState(true);
  const [setOnce, setSetOnce] = useState(false);
  const [silence, setSilence] = useState(false);
  const [showLineItem, setShowLineItem] = useState(false);
  const [disableSalesTax, setDisableSalesTax] = useState(false);

  useEffect(() => {
    setSilence(true);
    setSetOnce(false);
    setPricing(true);
    setTimeout(() => {
      setSilence(false);
      console.log('results zonePricing', zonePricing);
    }, 500);
  }, [zonePricing, values.material]);

  useEffect(() => {
    console.log('results zonePricing', zonePricing);
  }, [zonePricing]);

  useEffect(() => {
    if (values.price) setSetOnce(true);
  }, []);

  useEffect(() => {
    if (existingCustomer?.taxExempt) {
      setFieldValue('tax', false);
    }
  }, [existingCustomer]);

  // Updates pricing based on all relevant fields
  useEffect(() => {
    setPricing();
  }, [
    values.taxRate,
    values.tax,
    values.cc,
    tonLimitBlur,
    values.weightLimit,
    values.priceType,
    values.expectedSize,
    ccRate,
  ]);

  useEffect(() => {
    console.log('values', values);
  }, [values]);

  const setInitialPrice = (normalizedPricing: any) => {
    // TODO: Change to '===' once backend is fixed, server currently returning item.size as string instead of number
    const sizePricing = normalizedPricing.sizes.find((item: any) => item.size == values.expectedSize);
    if (!sizePricing) {
      alert('No pricing for size');
      const noPricingValues = { tax: true };
      if (salesTaxExemptMaterials.includes(values.material)) {
        noPricingValues.tax = false;
      }
      return { total: null, newValues: noPricingValues };
    }
    console.log('HERE: ', sizePricing, values, ccRate);
    const breakdown = sourgumPricingFunctions.quotedPriceBuild(
      +sizePricing.multiplier,
      +sizePricing.dump,
      +sizePricing.haul,
      !sizePricing.tax || existingCustomer?.taxExempt ? 0 : values.taxRate,
      !values.cc ? 0 : ccRate,
      true,
      +sizePricing.overage,
    );
    console.log('breakdown: ', breakdown);
    return {
      total: breakdown.total / 100,
      newValues: {
        price: breakdown.total / 100,
        dumpRateDollars: +sizePricing.dump || null,
        overageDollars: sizePricing.overage ? breakdown.overage / 100 : '',
        weightLimit: +sizePricing?.tonLimit || +sizePricing?.tonLimit === 0 ? +sizePricing?.tonLimit : '',
        priceType: sizePricing.type,
        tax: existingCustomer?.taxExempt ? false : sizePricing.tax,
        adjustedRentalPeriod: zonePricing?.rentalPeriod?.value || '',
        rentExtensionFeeDollars: zonePricing?.rentExtensionFeeDollars,
      },
    };
  };

  const setPricing = async (initialCheck?: boolean) => {
    if (tonLimitBlur && zonePricing && values.material && values.expectedSize) {
      const found = zonePricing.pricingData.find((item: any) => item.material === values.material);
      if (!found) {
        alert('No pricing found');
        if (salesTaxExemptMaterials.includes(values.material)) {
          setDisableSalesTax(true);
          setValues({
            ...values,
            tax: false,
          });
        } else {
          setDisableSalesTax(false);
        }
        return;
      }
      const normalizedPricing = sourgumPricingFunctions.normalizeMaterialSize(found);
      normalizedPricing.sizes = normalizedPricing.sizes.map((item) => {
        return {
          ...item,
          originalType: item.type,
        };
      });
      // TODO: Change to '===' once backend is fixed, server currently returning item.size as string instead of number
      const sizePricing = normalizedPricing.sizes.find((item) => item.size == values.expectedSize);
      if (sizePricing) {
        let newValues = {};
        let total = null;

        console.log('weight limit', values.weightLimit);

        if ((!setOnce || initialCheck) && !passedSetOnce) {
          const initialPricing = setInitialPrice(normalizedPricing);
          newValues = initialPricing.newValues;
          total = initialPricing.total;
          console.log('initialPricing', initialPricing);
        } else {
          if (silence) return;
          if (!sizePricing.haul) return;
          const breakdown = sourgumPricingFunctions.quotedPriceBuild(
            +values.weightLimit,
            +sizePricing.dump,
            +sizePricing.haul,
            !values.tax || existingCustomer?.taxExempt ? 0 : values.taxRate,
            !values.cc ? 0 : ccRate,
            true,
            +sizePricing.overage,
          );
          total = breakdown.total / 100;
          newValues = {
            price: breakdown.total / 100,
            dumpRateDollars: sizePricing.dump,
            weightLimit: sizePricing.type === priceTypesEnums.ton ? values.weightLimit : '',
            overageDollars: sizePricing.type === priceTypesEnums.ton ? values.overageDollars : '',
          };
          console.log('quotedPriceBuild', breakdown);
        }

        if (values.price && values.price !== total && !fromNeedsReview) {
          const override = await getConfirmation({
            title: 'Price Change',
            message: `Change from current Price Quoted of $${values.price}. To updated Price Quoted of $${total}?`,
          });
          if (!override) return;
        }

        setValues({
          ...values,
          ...newValues,
          tax: existingCustomer?.taxExempt ? false : sizePricing.tax,
          maxRentalDaysAllowed: zonePricing.maxRentalDaysAllowed,
        });

        // IF NEEDS REVIEW OVERWRITE WITH QUOTED VALUES ON INITIAL LOAD
        if (fromNeedsReview) {
          setValues({
            ...values,
          });
        }
        setSetOnce(true);
      } else {
        alert('No pricing found');
      }
    }
  };

  const handleMaterialSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === materialEnums.transportation) {
      setFieldValue('priceType', priceTypesEnums.flat);
    }
  };

  const handleLineItemAdd = (item: Invoice.LineItemTransport) => {
    const newArray = [...values.otherLineItems, item];
    setFieldValue('otherLineItems', newArray);
    setShowLineItem(false);
  };

  const handleLineItemRemove = (index: number) => {
    const newArray = [...values.otherLineItems].filter((_, itemIndex) => itemIndex !== index);
    setFieldValue('otherLineItems', newArray);
    setShowLineItem(false);
  };

  const getOtherLineItemsTotal = () => {
    let total = values.price;
    values.otherLineItems.forEach((item: Invoice.LineItemTransport) => {
      total = total + item.totalPriceDollars;
    });
    return round(total);
  };

  const handleSizeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = parseInt(e.target.value);
    const updateData = { expectedSize: newSize, weightLimit: '', overageDollars: '' };
    console.log(values.expectedSize, newSize);
    if (values.expectedSize && values.expectedSize !== newSize) {
      console.log(
        'found: ',
        zonePricing?.pricingData.find((item: any) => item.material === values.material),
      );
      const found = zonePricing?.pricingData.find((item: any) => item.material === values.material);
      console.log('found: ', found);
      if (!found) return;
      const normalizedPricing = sourgumPricingFunctions.normalizeMaterialSize(found);
      console.log('found: norm', found);
      const sizePricing = normalizedPricing.sizes.find((item) => {
        console.log(item.size, newSize);
        return item.size == newSize;
      });
      console.log('hit');
      console.log('sizePricing: ', sizePricing);
      console.log('values: ', values);
      if (sizePricing?.tonLimit && sizePricing?.tonLimit !== values.weightLimit) {
        const override = await getConfirmation({
          title: 'Ton Limit Change',
          message: `Do you want to update the ton limit to ${sizePricing.tonLimit} to match the correct ton limit for the ${newSize} YD pricing?`,
        });
        if (override) {
          updateData.weightLimit = sizePricing.tonLimit.toString();
          //updateData.overageDollars = sizePricing.overage ? (sizePricing.overage / 100).toString() : '';
        }
      }
    }
    setValues({
      ...values,
      ...updateData,
    });
  };

  return (
    <Grid marginTop={'2px'} container spacing={3} alignItems={'center'} justifyContent={'center'}>
      <Grid item xs={12} md={6}>
        <Field
          id="serviceLocation.county"
          name="serviceLocation.county"
          label="County"
          component={TextField}
          margin="dense"
          disabled={true}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {/* <InputLabel htmlFor='material'>Material</InputLabel> */}

        <Field
          name="material"
          component={Select}
          label="Material"
          labelId="material-label"
          formControl={{ fullWidth: true, size: 'small' }}
          inputProps={{
            id: 'material',
            onChange: handleMaterialSelect,
          }}
          disabled={disabled || (disableFields && disableFields.includes('material'))}
        >
          {Object.entries(materials).map((item) => (
            <MenuItem
              sx={{ zIndex: 400 }}
              key={`material-${item[0]}`}
              value={item[0]}
              disabled={constraints ? !Object.keys(swapConstraints.size)?.includes(item[0]) : false}
            >
              {item[1]}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Radios
          //@ts-expect-error fix this
          value={values.priceType}
          style={{ flexDirection: 'row' }}
          disabled={disabled || (disableFields && disableFields.includes('priceType'))}
          options={[
            { value: priceTypesEnums.ton, label: 'By Ton' },
            { value: priceTypesEnums.yard, label: 'By Yard' },
            { value: priceTypesEnums.flat, label: 'Flat Rate' },
          ]}
          onChange={(value: any) => {
            setValues({
              ...values,
              priceType: value,
              weightLimit: '',
              overageDollars: '',
            });
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <div>
          <Field
            name="expectedSize"
            component={Select}
            disabled={disabled || (disableFields && disableFields.includes('expectedSize'))}
            label="Box size"
            labelId="expectedSize-label"
            formControl={{ fullWidth: true, size: 'small' }}
            inputProps={{
              id: 'expectedSize',
              onChange: handleSizeChange,
            }}
          >
            {Object.values(rollOffSizes).map((item) => (
              <MenuItem
                key={`size-${item}`}
                value={item}
                disabled={constraints ? !swapConstraints.size[values.material]?.includes(parseInt(item)) : false}
              >
                {item}
              </MenuItem>
            ))}
          </Field>
          {showSwitchText && (
            <FormHelperText style={{ paddingLeft: '2%' }}>
              Only sizes for the current hauler of this order are shown. If you need to service a different size, please
              make this a final removal and issue a new order with a different hauler.
            </FormHelperText>
          )}
        </div>
      </Grid>
      <Grid item xs={12} md={6} alignSelf={'flex-start'}>
        {values.priceType === priceTypesEnums.ton ? (
          <Field
            key={`weightLimit-${values.expectedSize}`}
            id="weightLimit"
            name="weightLimit"
            label="Ton limit"
            component={TextField}
            value={values.weightLimit}
            onChange={(e: any) => {
              console.log('e.target.value: ', e.target.value);
              setFieldValue('weightLimit', e.target.value);
            }}
            margin="dense"
            type="number"
            step="0.01"
            onFocus={() => {
              setTonLimitBlur(false);
            }}
            InputProps={{
              onBlur: (e: any) => {
                setTonLimitBlur(true);
                handleBlur(e);
              },
            }}
            fullWidth
            disabled={disabled || (disableFields && disableFields.includes('weightLimit'))}
          />
        ) : null}
        {showSwitchText && (
          <FormHelperText style={{ visibility: 'hidden' }}>
            <br />
            <br />
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          id="adjustedRentalPeriod"
          name="adjustedRentalPeriod"
          label="Rental period"
          component={TextField}
          margin="dense"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">/ days</InputAdornment>,
          }}
          fullWidth
          disabled={disabled || (disableFields && disableFields.includes('adjustedRentalPeriod'))}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          id="rentExtensionFeeDollars"
          name="rentExtensionFeeDollars"
          label="Rental extension fee"
          component={TextField}
          margin="dense"
          type="number"
          step="0.01"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          fullWidth
          disabled={disabled || (disableFields && disableFields.includes('rentExtensionFeeDollars'))}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Toggle
          value={values.tax}
          label="Sales tax"
          onChange={(value) => setFieldValue('tax', value)}
          disabled={
            disabled ||
            (!values.tax && existingCustomer?.taxExempt) ||
            disableSalesTax ||
            (disableFields && disableFields.includes('tax'))
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Toggle
          value={values.cc}
          label="CC fee"
          onChange={(value) => setFieldValue('cc', value)}
          disabled={disabled || (disableFields && disableFields.includes('cc'))}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          id="price"
          name="price"
          label="Price quoted"
          component={TextField}
          margin="dense"
          type="number"
          step="0.01"
          fullWidth
          disabled={disabled || (disableFields && disableFields.includes('price'))}
        />
        {values.otherLineItems.length > 0 ? (
          <TableContainer style={{ marginBottom: 7 }}>
            <Table size="small">
              <TableBody>
                {values.otherLineItems.map((item: Invoice.LineItemTransport, index: number) => (
                  <TableRow key={`${item.itemName}-${index}`}>
                    <TableCell>{item.itemName}</TableCell>
                    {item.totalPriceDollars && <TableCell>{formatUSD(item.totalPriceDollars)}</TableCell>}
                    <TableCell>
                      <IconButton onClick={() => handleLineItemRemove(index)} size="large">
                        <XMarkIcon className="h-6 w-6 text-delete" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <b>Total</b>
                  </TableCell>
                  <TableCell>{formatUSD(getOtherLineItemsTotal())}</TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
        <button
          className="btn-primary-text-only"
          disabled={disabled || !values.price || fromNeedsReview}
          onClick={() => setShowLineItem(true)}
          type="button"
        >
          Apply Discount
        </button>
      </Grid>
      <Grid item xs={12} md={6} alignSelf={'self-start'}>
        {values.priceType === priceTypesEnums.ton ? (
          <Field
            id="overage"
            name="overageDollars"
            label="Overage fee"
            component={TextField}
            margin="dense"
            type="number"
            step="0.01"
            fullWidth
            disabled={disabled || (disableFields && disableFields.includes('overage'))}
          />
        ) : null}
      </Grid>
      {existingCustomer ? (
        <Grid item xs={12}>
          <button
            className="btn-primary-text-only"
            onClick={() => window.open(routes.customers.details(existingCustomer.id))}
            type="button"
          >
            Open Customer Details
          </button>
          {zonePricing?.customerID ? (
            <div className="mt-2 text-info-dark text-xs">Customer Has Special Pricing</div>
          ) : null}
        </Grid>
      ) : null}
      {showLineItem ? (
        <ExtraItemDialog open={showLineItem} onClose={() => setShowLineItem(false)} onSave={handleLineItemAdd} />
      ) : null}
    </Grid>
  );
};

export default OrderPricingFields;

export interface ExtraItemDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (item: any) => void;
}

const ExtraItemDialog = ({ open, onClose, onSave }: ExtraItemDialogProps) => {
  const [discount, setDiscount] = useState('');
  const [amount, setAmount] = useState<number>(0);

  const discountOptions = [
    { name: 'Discount', amount: 25 },
    { name: 'New Customer Promotion', amount: 25 },
    { name: 'Military Discount', amount: 25 },
    { name: 'Loyalty Discount', amount: 25 },
    { name: 'New Customer - K1C29Y', amount: 50 },
    { name: 'New Customer - 2SHJSR', amount: 25 },
  ];

  const handleSave = () => {
    onSave({
      itemName: discount,
      description: '',
      quantity: 1,
      taxable: false,
      totalPriceDollars: -Math.abs(amount),
    });
  };

  const handleDiscountChange = (e: SelectChangeEvent<string>) => {
    const newDiscountItem = e.target.value;
    if (!newDiscountItem) return;

    const selectedOption = discountOptions.find((item) => item.name === newDiscountItem);

    if (selectedOption) {
      const newAmount = selectedOption.amount;
      setDiscount(newDiscountItem);
      setAmount(newAmount);
    }
  };

  return (
    <Dialog open={open} className="max-w-xl" onClose={onClose} styledTitle="Apply Discount">
      <Grid className="pt-4" container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel size="small">Discount Item</InputLabel>
            <MuiSelect
              size="small"
              placeholder="Discount Item"
              label="Discount Item"
              value={discount}
              onChange={(e: SelectChangeEvent<string>) => handleDiscountChange(e)}
            >
              {discountOptions.map((item) => (
                <MenuItem key={`discount-${item.name}`} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <MuiTextField
            label="Discount amount"
            type="number"
            value={amount}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmount(parseInt(e.target.value))}
            InputProps={{
              startAdornment: <InputAdornment position="start">$ -</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <div className="flex justify-end gap-4 pt-4">
        <button className="btn-dark-grey-outlined" onClick={onClose} type="button">
          cancel
        </button>
        <button className="btn-primary" onClick={handleSave} disabled={!discount || !amount} type="button">
          save
        </button>
      </div>
    </Dialog>
  );
};
