import React, { useContext, useEffect, useState } from 'react';
import { useCurrentAuthorityToken } from '@alliance-disposal/client';
import { Customer, Invoice } from '@alliance-disposal/transport-types';
import { Button, Dialog } from '@wayste/sour-ui';
import axios from 'axios';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import { UIContext } from '../../contexts';

const JoesXXXLab = () => {
  const token = `Bearer ${useCurrentAuthorityToken()}`;
  const { godModeActive } = useContext(UIContext);
  const [seconds, setSeconds] = useState(30);
  const [showAccessDenied, setShowAccessDenied] = useState(true);
  const [downloadableData, setDownloadableData] = useState<any[]>([]);

  useEffect(() => {
    if (godModeActive) {
      setShowAccessDenied(false);
    } else {
      setShowAccessDenied(true);
    }
  }, [godModeActive]);

  useEffect(() => {
    // Exit early if the countdown reaches zero
    if (seconds === 0) return;

    // Create a timeout that decrements the countdown every second
    const intervalId = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);

    // Clear the interval if the component is unmounted
    return () => clearInterval(intervalId);
  }, [seconds]); // Only re-run the effect if the seconds change

  const runDangerousFunction = async () => {
    console.log('start');
    const exportData = [];
    const params: Invoice.GetPayablesQuery = {
      issuedAfter: '2023-06-01',
      // issuedBefore: '2023-07-01',
      void: false,
    };
    let counter = 1;
    try {
      const res: { data: Invoice.PayableTransport[] } = await axios.get(
        `${import.meta.env.VITE_BASE_API_URL}/invoice/payable`,
        {
          headers: {
            Authorization: `${token}`,
          },
          params,
        },
      );
      console.log('all data', res.data);
      for (const invoice of res.data) {
        for (const lineItem of invoice.invoiceDetails.lineItems) {
          if (lineItem.totalPrice !== 0) {
            const billDate =
              invoice.invoiceDetails.payments?.[0]?.paymentMethod === 'creditCard'
                ? format(new Date(invoice.invoiceDetails.payments?.[0]?.date), 'MM/dd/yyyy')
                : invoice.invoiceDetails.issueDate
                ? format(new Date(invoice.invoiceDetails.issueDate), 'MM/dd/yyyy')
                : '';
            exportData.push({
              'Bill No.': `${invoice.invoiceDetails.orderNumber} - ${invoice.invoiceDetails.invoiceNumber}`,
              Vendor: invoice.vendorName,
              'Bill Date': billDate,
              Memo: invoice.invoiceDetails.orderNumber,
              Amount: lineItem.totalPriceDollars,
              'Category/Account': lineItem.itemName,
              Type: 'Item Details',
              Qty: 1,
              Rate: lineItem.totalPriceDollars,
              lineItemPairCode: counter,
              paymentMethod: invoice.invoiceDetails.payments?.[0]?.paymentMethod || '',
            });
          }
        }
        counter = counter + 1;
      }
      setDownloadableData(exportData);
    } catch (error) {
      console.warn('runDangerousFunction error: ', error);
    }
    console.log('end');
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center h-full">
        <div>
          <Button className="btn-primary" onClick={runDangerousFunction}>
            Never Push this button
          </Button>
        </div>
        <div className="mt-6">
          {downloadableData.length === 0 ? null : (
            <CSVLink className="btn-secondary" data={downloadableData}>
              Download Data
            </CSVLink>
          )}
        </div>
      </div>
      <Dialog open={showAccessDenied} onClose={() => null}>
        <div className="text-center">
          <p>DELETING ALL ORDER DATA FOREVER IN</p>
          <br />
          <p className="pb-4">Only a SAP Dev can stop this now that is has begun...🏃</p>
          {seconds > 0 ? (
            <p className="text-red-500">Time remaining: {seconds} seconds</p>
          ) : (
            <p>
              🎉 NOT 🎊 <br />
              Don't touch things you're not supposed to 😡
              <br />
              Now get off of my page.
            </p>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default JoesXXXLab;
