import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, Textarea } from '@wayste/sour-ui';
import { SubmitHandler, useForm } from 'react-hook-form';
import { sendSMS } from '../../axios/twilio';
import { UIContext } from '../../contexts';

interface Props {
  message?: string;
  open: boolean;
  onClose: () => void;
  onSMSSent: () => void;
  toNumber: string;
}

interface FormProps {
  message: string;
}

const SMSSendDialog = ({ open, onClose, onSMSSent, message, toNumber }: Props) => {
  const { showFlash } = useContext<any>(UIContext);

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: useMemo(() => {
      return {
        message: message || '',
      };
    }, [message]),
  });

  useEffect(() => {
    reset({ message: message || '' });
  }, [message]);

  const onFormSubmit: SubmitHandler<FormProps> = async (data) => {
    console.log(data);
    setIsLoading(true);
    try {
      const response = await sendSMS({ toNumber, message: data.message });
      setIsLoading(false);
      console.log(response);
      if (response.status === 'error') {
        showFlash('An error occurred, check the console', 'warning');
        return;
      }
      showFlash('Email Sent!', 'success');
      onSMSSent();
    } catch (error) {
      showFlash('An error occurred, check the console', 'warning');
      return;
    }
  };

  return (
    <Dialog onClose={onClose} open={open} styledTitle="Compose SMS">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="min-h-[400px]">
          <div className="flex flex-col gap-1">
            <div>
              Send to: <b>{toNumber}</b>
            </div>
            <div>
              <Textarea
                required
                label="Custom message"
                inputProps={{ ...register('message', { required: 'A message is required' }) }}
                error={errors.message}
                height="h-[300px]"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button className="btn-dark-grey-outlined" onClick={onClose} type="button" disabled={isLoading}>
            Cancel
          </button>
          <Button className="btn-primary ml-5" disabled={!isValid} loading={isLoading} type="submit">
            Send
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default SMSSendDialog;
