import { useState } from 'react';
import { UniversalService } from '@alliance-disposal/transport-types';
import { TextField } from '@wayste/sour-ui';
import { Controller, UseFormReturn, get } from 'react-hook-form';
import SubscriptionServiceOrderPricingSubForm from './SubscriptionServiceOrderPricingSubForm';
import SubscriptionServiceOrderSubForm from './SubscriptionServiceOrderSubForm';
import { UniversalServiceFormData } from './UniversalServiceCreate';

interface SubscriptionServiceOrderProps {
  methods: UseFormReturn<UniversalServiceFormData, unknown>;
  index: number;
}

const SubscriptionServiceOrder = ({ methods, index }: SubscriptionServiceOrderProps) => {
  const [selectedServiceTypeFamily, setSelectedServiceTypeFamily] = useState<
    UniversalService.ServiceTypeFamily | undefined
  >(undefined);
  const {
    control,
    formState: { errors },
  } = methods;
  return (
    <div className="space-y-6">
      <div className="pb-2 mb-6 text-l border-b border-gray-300">Add Service</div>
      <SubscriptionServiceOrderSubForm
        mode="create"
        methods={methods}
        index={index}
        selectedServiceTypeFamily={selectedServiceTypeFamily}
        setSelectedServiceTypeFamily={setSelectedServiceTypeFamily}
      />
      <div className="pb-2 mb-6 text-l border-b border-gray-300">Pricing Info</div>
      {selectedServiceTypeFamily && (
        <SubscriptionServiceOrderPricingSubForm
          methods={methods}
          index={index}
          selectedServiceTypeFamily={selectedServiceTypeFamily}
        />
      )}
      <div className="w-1/2 pr-1 ">
        <Controller
          control={control}
          name={`quantity.${index}`}
          defaultValue={1}
          render={({ field }) => (
            <TextField
              type={'number'}
              label="Quantity of this sku with these details"
              required
              error={get(errors, `quantity.${index}`)}
              inputProps={{
                ...field,
              }}
            />
          )}
        />
      </div>
    </div>
  );
};
export default SubscriptionServiceOrder;
