import React, { useContext, useEffect, useState } from 'react';
import { Customer, MaterialLabels, Pricing } from '@alliance-disposal/transport-types';
import { moneyFormatter } from '@wayste/utils';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import { routes } from '../../utils';

interface Props {
  customPricing: (Pricing.CustomerDiscountTransport & { customerData: Customer.AllianceCustomerTransport })[];
}

interface ComparedSizeObjectType {
  custom: number;
  standard: number | null | undefined;
  difference: number | undefined;
}

interface ComparedSizeType {
  dump: ComparedSizeObjectType | null;
  haul: ComparedSizeObjectType | null;
  over: ComparedSizeObjectType | null;
  tonLimit: ComparedSizeObjectType | null;
  size: string;
}

const headers = ['Customer Name', 'AE', 'AM', 'Material', 'Haul Diff', 'Dump Diff', 'Overage Diff', 'Tons Diff'];

const CustomPricingList = ({ customPricing }: Props) => {
  const { showFlash } = useContext(UIContext);
  const history = useHistory();
  const [displayData, setDisplayData] = useState<
    (Pricing.CustomerDiscountTransport & {
      customerData: Customer.AllianceCustomerTransport;
      comparedPricing: Pricing.CustomerDiscountTransport['discountData'][0] & {
        priceType: string;
        comparedSizes: (undefined | ComparedSizeType)[];
      };
    })[]
  >([]);

  useEffect(() => {
    console.log('custom pricing: ', customPricing);
    const cleanPricing: (Pricing.CustomerDiscountTransport & {
      customerData: Customer.AllianceCustomerTransport;
      comparedPricing: Pricing.CustomerDiscountTransport['discountData'][0] & {
        priceType: string;
        comparedSizes: (undefined | ComparedSizeType)[];
      };
    })[] = [];
    customPricing.forEach((item) => {
      item.discountData.forEach((discount) => {
        const standardPricingMaterial = item.pricingZone?.pricingData.find(
          (pricingDataItem) => pricingDataItem.material === discount.material,
        );
        const comparedPricing = {
          ...discount,
          priceType: standardPricingMaterial?.type || '',
          comparedSizes: discount.sizes.map((size) => {
            if (!size.haul && !size.dump && !size.over && !size.tonLimit) {
              return undefined;
            }
            const standardPricingSize = standardPricingMaterial?.sizes.find((sizeItem) => sizeItem.size === size.size);
            if (item.discountType === Pricing.CustomerDiscountType.FIXED) {
              return {
                dump: size.dump
                  ? {
                      custom: size.dump,
                      standard: standardPricingSize?.dump,
                      difference: standardPricingSize?.dump ? size.dump - standardPricingSize.dump : undefined,
                    }
                  : null,
                haul: size.haul
                  ? {
                      custom: size.haul,
                      standard: standardPricingSize?.haul,
                      difference: standardPricingSize?.haul ? size.haul - standardPricingSize.haul : undefined,
                    }
                  : null,
                over: size.over
                  ? {
                      custom: size.over,
                      standard: standardPricingSize?.over,
                      difference: standardPricingSize?.over ? size.over - standardPricingSize.over : undefined,
                    }
                  : null,
                tonLimit:
                  size.tonLimit || size.tonLimit === 0
                    ? {
                        custom: size.tonLimit,
                        standard: standardPricingSize?.tonLimit,
                        difference:
                          standardPricingSize?.tonLimit || standardPricingSize?.tonLimit === 0
                            ? size.tonLimit - standardPricingSize.tonLimit
                            : undefined,
                      }
                    : null,
                size: size.size,
              };
            } else {
              return {
                dump: size.dump
                  ? {
                      custom: size.dump,
                      standard: standardPricingSize?.dump,
                      difference: standardPricingSize?.dump
                        ? size.dump - (standardPricingSize.dump - size.dump)
                        : undefined,
                    }
                  : null,
                haul: size.haul
                  ? {
                      custom: size.haul,
                      standard: standardPricingSize?.haul,
                      difference: standardPricingSize?.haul
                        ? size.haul - (standardPricingSize.haul - size.haul)
                        : undefined,
                    }
                  : null,
                over: size.over
                  ? {
                      custom: size.over,
                      standard: standardPricingSize?.over,
                      difference: standardPricingSize?.over
                        ? size.over - (standardPricingSize.over - size.over)
                        : undefined,
                    }
                  : null,
                tonLimit:
                  size.tonLimit || size.tonLimit === 0
                    ? {
                        custom: size.tonLimit,
                        standard: standardPricingSize?.tonLimit,
                        difference:
                          standardPricingSize?.tonLimit || standardPricingSize?.tonLimit === 0
                            ? size.tonLimit - (standardPricingSize.tonLimit - size.tonLimit)
                            : undefined,
                      }
                    : null,
                size: size.size,
              };
            }
          }),
        };
        if (comparedPricing) {
          cleanPricing.push({
            ...item,
            comparedPricing,
          });
        } else {
          console.error('comparedPricing is undefined');
          showFlash('Error in comparedPricing', 'warning');
        }
      });
    });
    setDisplayData(
      cleanPricing.sort((a, b) =>
        MaterialLabels[a.comparedPricing.material as keyof typeof MaterialLabels] >
        MaterialLabels[b.comparedPricing.material as keyof typeof MaterialLabels]
          ? 1
          : -1,
      ),
    );
  }, [customPricing]);

  const handleRowClicked = (id?: string) => {
    if (id) history.push(routes.customers.details(id));
  };

  return (
    <div className="w-full flex-1 overflow-x-auto">
      <table className="w-full border-collapse overflow-x-auto">
        <thead>
          <tr>
            {headers.map((heading) => (
              <td className="py-1.5 px-4 border-0 border-b border-solid border-b-gray-200 text-sm" key={heading}>
                {heading}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {displayData.map((item) => (
            <tr
              key={item.id}
              onClick={() => handleRowClicked(item.customerID)}
              className="cursor-pointer hover:bg-slate-50 [&>*]:border-b [&>*]:py-1.5 [&>*]:px-4 text-sm"
            >
              <td>
                {item.customerData.companyName ||
                  item.customerData.contacts?.find((contact) => contact.primaryContact)?.firstName}
              </td>
              <td>{item.customerData.accountRepName}</td>
              <td>{item.customerData.accountManagerName}</td>
              <td>{MaterialLabels[item.comparedPricing.material as keyof typeof MaterialLabels]}</td>
              <td>
                {item.comparedPricing.comparedSizes.map((sizeItem) => {
                  if (!sizeItem || !sizeItem.haul) return null;
                  return (
                    <div>
                      {sizeItem?.size}: {moneyFormatter(sizeItem?.haul?.custom)} -{' '}
                      {moneyFormatter(sizeItem?.haul?.standard)} = {moneyFormatter(sizeItem?.haul?.difference)}
                    </div>
                  );
                })}
              </td>
              <td>
                {item.comparedPricing.comparedSizes.map((sizeItem) => {
                  if (!sizeItem || !sizeItem.dump) return null;
                  return (
                    <div>
                      {sizeItem?.size}: {moneyFormatter(sizeItem?.dump?.custom)} -{' '}
                      {moneyFormatter(sizeItem?.dump?.standard)} = {moneyFormatter(sizeItem?.dump?.difference)}
                    </div>
                  );
                })}
              </td>
              <td>
                {item.comparedPricing.comparedSizes.map((sizeItem) => {
                  if (!sizeItem || !sizeItem.over) return null;
                  return (
                    <div>
                      {sizeItem?.size}: {moneyFormatter(sizeItem?.over?.custom)} -{' '}
                      {moneyFormatter(sizeItem?.over?.standard)} = {moneyFormatter(sizeItem?.over?.difference)}
                    </div>
                  );
                })}
              </td>
              <td>
                {item.comparedPricing.comparedSizes.map((sizeItem) => {
                  if (!sizeItem || (!sizeItem.tonLimit && sizeItem.tonLimit !== 0)) return null;
                  return (
                    <div>
                      {sizeItem?.size}: {sizeItem?.tonLimit?.custom} - {sizeItem?.tonLimit?.standard} ={' '}
                      {sizeItem?.tonLimit?.difference}
                    </div>
                  );
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomPricingList;
