import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { formatE164ToUSPhoneNumber } from '@wayste/utils';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { UIContext } from '../../contexts';
import routes from '../../utils/routes';
import { leadTypes, leadTypesEnums } from '../../utils/shared-types';
import ButtonSelect from '../ButtonSelect';
import Dialog from '../Dialog';
import { LeadCMForms, LeadPTForms, LeadROForms, LeadRSForms } from '../LeadForms';
import Loading from '../Loading';

export interface LeadCreateProps {
  isModal?: boolean;
}

const LeadCreate = ({ isModal }: LeadCreateProps) => {
  const client = useWaysteClient();
  const history = useHistory();
  const location = useLocation();
  const { showFlash } = useContext(UIContext);

  // STATE
  const [leadType, setLeadType] = useState(leadTypesEnums.rollOff);
  const [isLoading, setIsLoading] = useState(false);
  const [leadPhone, setLeadPhone] = useState<string | undefined>(undefined);

  //////////////////////////////////////////////
  // HOOKS SECTION
  //////////////////////////////////////////////

  useEffect(() => {
    if (location.search.includes('lead_phone_query=')) {
      const searchObj = queryString.parse(location.search);
      const phoneNumber = searchObj.lead_phone_query;

      if (phoneNumber) {
        setLeadPhone(formatE164ToUSPhoneNumber(phoneNumber ? phoneNumber.toString() : ''));
      }
    }
  }, []);

  //////////////////////////////////////////////
  // FUNCTION SECTION
  //////////////////////////////////////////////

  const handleBackButtonClick = () => {
    history.goBack();
  };

  const handleLeadTypeChange = (value: keyof typeof leadTypes) => {
    setLeadType(value);
  };

  const handleSubmit = async (leadObj: Customer.AllianceLeadTransport) => {
    setIsLoading(true);
    leadObj.status = 'open';
    leadObj.needsAttention = leadObj.needsAttention ? leadObj.needsAttention : false;
    leadObj.customerType = leadObj.customerType ? leadObj.customerType : 'unknown';

    console.log('leadObj create: ', leadObj);
    const quotes = leadObj?.quotes ? [...leadObj.quotes] : [];
    const haulerQuotes = leadObj.haulerQuotes ? [...leadObj.haulerQuotes] : [];

    try {
      const response = await client.customer().adminPortal.leads.create(leadObj);

      const leadID = response?.id;
      for (const quote of quotes) {
        try {
          await client.customer().adminPortal.leads.quote.create(leadID, quote);
        } catch (error) {
          showFlash("Quote Didn't Save", 'warning');
          throw error;
        }
      }

      for (const haulerQuote of haulerQuotes) {
        try {
          await client.customer().adminPortal.leads.haulerQuote.create(leadID, haulerQuote);
        } catch (error) {
          showFlash("Hauler Quote Didn't Save", 'warning');
          throw error;
        }
      }

      try {
        await client.customer().adminPortal.leads.contactEvent.create(leadID, leadObj.contactEvents[0] as any);
      } catch (error) {
        showFlash("Contact Event Didn't Save", 'warning');
        throw error;
      }

      if (leadObj?.notesInternal?.length > 0) {
        try {
          await client.customer().adminPortal.leads.internalNote.create(leadID, leadObj.notesInternal[0]);
        } catch (error) {
          showFlash("Internal Note Didn't Save", 'warning');
          throw error;
        }
      }
      showFlash('Lead Successfully Created', 'success');

      history.push(routes.leads.details(response?.id));
    } catch (error) {
      // showFlash('An Error Occurred', 'warning');
      console.log('error creating lead: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  //////////////////////////////////////////////
  // RENDER SECTION
  //////////////////////////////////////////////

  if (isLoading) return <Loading />;

  const getContent = () => {
    switch (leadType) {
      case leadTypesEnums.rollOff:
        return (
          <LeadROForms onCancel={handleBackButtonClick} onSubmit={handleSubmit} leadPhone={leadPhone} form="all" />
        );
      case leadTypesEnums.commercial:
        return (
          <LeadCMForms onCancel={handleBackButtonClick} onSubmit={handleSubmit} leadPhone={leadPhone} form="all" />
        );
      case leadTypesEnums.residential:
        return (
          <LeadRSForms onCancel={handleBackButtonClick} onSubmit={handleSubmit} leadPhone={leadPhone} form="all" />
        );
      case leadTypesEnums.portableToilet:
        return (
          <LeadPTForms onCancel={handleBackButtonClick} onSubmit={handleSubmit} leadPhone={leadPhone} form="all" />
        );
      default:
        return <div>Lead type does not exist</div>;
    }
  };

  if (isModal)
    return (
      <Dialog fullScreen open={true} styledTitle="Create New Roll Off Lead" onClose={handleBackButtonClick}>
        <div style={{ display: 'flex', marginBottom: 15 }}>
          <ButtonSelect
            menuItems={Object.entries(leadTypes).map((item) => ({
              value: item[0],
              label: `${item[1]} Lead`,
            }))}
            value={leadType}
            onSelect={handleLeadTypeChange}
          />
        </div>
        {getContent()}
      </Dialog>
    );

  return (
    <div className="pt-2 bg-gray-50 w-full max-w-7xl px-4 xl:mx-auto xl:px-6">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button type="button" className="btn-icon" onClick={handleBackButtonClick}>
          <ChevronLeftIcon className="h-6 w-6" />
        </button>
        <h6 className="text-xl font-medium">Create New Lead</h6>
        <ButtonSelect
          menuItems={Object.entries(leadTypes).map((item) => ({
            value: item[0],
            label: `${item[1]} Lead`,
          }))}
          value={leadType}
          onSelect={handleLeadTypeChange}
          wrapperClass="mx-3"
        />
      </div>
      {getContent()}
    </div>
  );
};

export default LeadCreate;
