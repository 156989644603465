import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { sortByKey } from '@wayste/utils';
import { format } from 'date-fns';
import { UserContext } from '../../contexts';
import { materials } from '../../utils';
import Loading from '../Loading';
import FieldNameMap from './audit-log-name-map';

type EntityTypeEnum =
  | 'OrderSwitch'
  | 'AllianceCustomer'
  | 'Order'
  | 'DailySchedule'
  | 'ServiceAreas'
  | 'CustomerEnumerator'
  | 'Pricing'
  | 'HaulerAapData'
  | 'Dumpster'
  | 'DumpsterActivityRecord'
  | 'ScheduleEntry'
  | 'AllianceCustomerContact'
  | 'Hauler'
  | 'OrderEnumerator'
  | 'Customer'
  | 'AllianceOrder'
  | 'AllianceCustomer';

type Props = {
  entityID: string;
  entityType: EntityTypeEnum;
};

const deepDiffMapper = (function () {
  return {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: 'unchanged',
    map: function (obj1: any, obj2: any) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw 'Invalid argument. Function given, object expected.';
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        return {
          type: this.compareValues(obj1, obj2),
          oldData: obj1,
          newData: obj2,
        };
      }

      const diff: any = {};
      for (const key in obj1) {
        if (this.isFunction(obj1[key])) {
          continue;
        }

        let value2 = undefined;
        if (obj2[key] !== undefined) {
          value2 = obj2[key];
        }

        diff[key] = this.map(obj1[key], value2);
      }
      for (const key in obj2) {
        if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
          continue;
        }

        diff[key] = this.map(undefined, obj2[key]);
      }

      return diff;
    },
    compareValues: function (value1: any, value2: any) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }
      if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
        return this.VALUE_UNCHANGED;
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED;
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED;
      }
      return this.VALUE_UPDATED;
    },
    isFunction: function (x: any) {
      return Object.prototype.toString.call(x) === '[object Function]';
    },
    isArray: function (x: any) {
      return Object.prototype.toString.call(x) === '[object Array]';
    },
    isDate: function (x: any) {
      return Object.prototype.toString.call(x) === '[object Date]';
    },
    isObject: function (x: any) {
      return Object.prototype.toString.call(x) === '[object Object]';
    },
    isValue: function (x: any) {
      return !this.isObject(x) && !this.isArray(x);
    },
  };
})();

const AuditLogTable = ({ entityID, entityType }: Props) => {
  const client = useWaysteClient();
  const { userIDToNameMap } = useContext(UserContext);
  const [isChangingLoading, setIsChangingLoading] = useState(false);
  const [changeData, setChangeData] = useState<any[]>([]);
  const [haulerNameMap, setHaulerNameMap] = useState({});

  const listHaulerNames = async () => {
    setIsChangingLoading(true);
    try {
      const response = await client.vendorService().fetch();
      const nameMap = response.data.reduce((obj: any, item: any) => ({ ...obj, [item.id]: item.name }), {});
      setHaulerNameMap(nameMap);
      setIsChangingLoading(false);
    } catch (apolloError) {
      console.warn('listAllHaulers apolloError: ', apolloError);
      setHaulerNameMap({});
      setIsChangingLoading(false);
      return;
    }
  };

  const handleGetAuditLog = async (id: string, type: EntityTypeEnum) => {
    setIsChangingLoading(true);
    const response = await client.auditLog().adminPortal.query(type, id);
    if (!response.data) return;
    console.log('AuditTable TESTING: ', response);
    const { data }: { data: any } = response;
    console.log('AuditTable all data: ', data);
    const filteredData = data.filter((item: any) => item.action !== 'create');
    const updateData: any = [];
    filteredData.forEach((item: any) => {
      const blankUpdateObject = {
        userID: item.userID,
        date: new Date(item.timestamp),
      };
      item.changes.forEach((change: any) => {
        if (change.field === 'pricingData') {
          console.log('pricingData parse: ', JSON.parse(change.oldValue), JSON.parse(change.newValue));
          const pricingDataOld = JSON.parse(change.oldValue);
          const pricingDataNew = JSON.parse(change.newValue);
          if (pricingDataOld.length > 0) {
            pricingDataNew.forEach((pricingChange: { material: MaterialEnums; [key: string]: any }) => {
              const found = pricingDataOld.find(
                (oldPricingChange: any) => oldPricingChange.material === pricingChange.material,
              );
              if (found) {
                const result = deepDiffMapper.map(found, pricingChange);
                console.log(result);
                Object.keys(result).forEach((key: any) => {
                  if (
                    key === 'sizes' &&
                    Object.values(result[key]).some(
                      (sizeChange: any) => sizeChange.size.type === 'updated' || sizeChange.tonLimit.type === 'updated',
                    )
                  ) {
                    updateData.push({
                      ...blankUpdateObject,
                      field: `${materials[pricingChange.material]} - Sizes`,
                      oldValue: found.sizes
                        .map((sizeItem: any) => `${sizeItem.size} YD ${sizeItem.tonLimit} Tons`)
                        .join(' | '),
                      newValue: pricingChange.sizes
                        .map((sizeItem: any) => `${sizeItem.size} YD ${sizeItem.tonLimit} Tons`)
                        .join(' | '),
                    });
                  } else if (result[key].type === 'updated' && FieldNameMap[type][change.field][key]) {
                    updateData.push({
                      ...blankUpdateObject,
                      field: `${materials[pricingChange.material]} - ${FieldNameMap[type][change.field][key].label}`,
                      oldValue: FieldNameMap[type][change.field][key].formatter
                        ? FieldNameMap[type][change.field][key].formatter(result[key].oldData, { haulerNameMap })
                        : result[key].oldData,
                      newValue: FieldNameMap[type][change.field][key].formatter
                        ? FieldNameMap[type][change.field][key].formatter(result[key].newData, { haulerNameMap })
                        : result[key].newData,
                    });
                  }
                });
              }
            });
          }
        } else if (FieldNameMap[type][change.field]) {
          const formattedOldValue = FieldNameMap[type][change.field].formatter
            ? FieldNameMap[type][change.field].formatter(change.oldValue, {
                haulerNameMap,
              })
            : change.oldValue;
          const formattedNewValue = FieldNameMap[type][change.field].formatter
            ? FieldNameMap[type][change.field].formatter(change.newValue, {
                haulerNameMap,
              })
            : change.newValue;

          if (formattedNewValue !== formattedOldValue) {
            updateData.push({
              ...blankUpdateObject,
              field: FieldNameMap[type][change.field].label,
              oldValue: formattedOldValue,
              newValue: formattedNewValue,
            });
          }
        }
      });
    });
    console.log('updateData: ', updateData);
    setChangeData(sortByKey('date', updateData, true));
    setIsChangingLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (!entityID || !entityType) return;
      await listHaulerNames();
      handleGetAuditLog(entityID, entityType);
    })();
  }, [entityID, entityType]);

  if (isChangingLoading) return <Loading />;

  if (changeData.length === 0) return <div>No Changes</div>;

  return (
    <div className="w-full overflow-x-auto">
      <table className="border-collapse w-full overflow-x-auto">
        <thead>
          <tr>
            <td className="py-1.5 px-4 border-b text-sm">Field</td>
            <td className="py-1.5 px-4 border-b text-sm">Old Value</td>
            <td className="py-1.5 px-4 border-b text-sm">New Value</td>
            <td className="py-1.5 px-4 border-b text-sm">Updated On</td>
            <td className="py-1.5 px-4 border-b text-sm">Updated By</td>
          </tr>
        </thead>
        <tbody>
          {changeData.map((item: any) => (
            <tr key={`${item.field}-${item.date}`}>
              <td className="py-1.5 px-4 border-b text-sm">{item.field}</td>
              <td className="py-1.5 px-4 border-b text-sm">{item.oldValue}</td>
              <td className="py-1.5 px-4 border-b text-sm">{item.newValue}</td>
              <td className="py-1.5 px-4 border-b text-sm">{format(item.date, 'MM/dd/yy')}</td>
              <td className="py-1.5 px-4 border-b text-sm">{userIDToNameMap[item.userID]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AuditLogTable;
