import { useState } from 'react';
import { DayOfWeek, ServiceOrder, UniversalService } from '@alliance-disposal/transport-types';
import { Checkbox, DatePicker, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { formatOrdinalSuffix, toTitleCase } from '@wayste/utils';
import { addDays, addMonths, addYears } from 'date-fns';
import { Controller, UseFormReturn, get } from 'react-hook-form';
import { UniversalServiceFormData } from './UniversalServiceCreate';

interface SubscriptionDetailsSubFormProps {
  methods: UseFormReturn<UniversalServiceFormData, unknown>;
}

const SubscriptionDetailsSubForm = ({ methods }: SubscriptionDetailsSubFormProps) => {
  const [endForever, setEndForever] = useState<boolean>(false);
  const [contractEndDate, setContractEndDate] = useState<string | undefined>(undefined);
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const computeContractEndDate = () => {
    const startDate = getValues('serviceGrouping.contractDetails.contractStartDate');
    // if the contract start date is changed, update the contract end date if we have a term and term unit
    if (startDate) {
      const term = getValues('serviceGrouping.contractDetails.contractTerm');
      const termUnit = getValues('serviceGrouping.contractDetails.contractTermUnit');

      if (term != null && termUnit) {
        let newDate = new Date(startDate);
        switch (termUnit) {
          case 'day':
            newDate = addDays(newDate, term);
            break;
          case 'week':
            newDate = addDays(newDate, term * 7);
            break;
          case 'month':
            newDate = addMonths(newDate, term);
            break;
          case 'year':
            newDate = addYears(newDate, term);
            break;
        }

        setContractEndDate(newDate.toISOString());
      }
    }
  };

  const [billingFrequencyUnit, billingFrequency] = watch([
    'subscriptionDetails.billingFrequencyUnit',
    'subscriptionDetails.billingFrequency',
  ]);

  const frequencyLabel: Record<UniversalService.BillingFrequencyUnit, string> = {
    month: 'Day of Month',
    week: 'Day of Week',
    day: 'Day of Period',
  };

  const selectOption: Record<
    UniversalService.BillingFrequencyUnit,
    {
      label: string;
      value: number;
    }[]
  > = {
    month: Array.from(Array(28).keys()).map((day) => ({
      label: day + 1 + formatOrdinalSuffix(day + 1),
      value: day + 1,
    })),

    week: Array.from(Array(7).keys()).map((day) => ({
      // @ts-expect-error - ts is not smart enough to know that day + 1 will always be a valid day of the week
      label: DayOfWeek[day + 1],
      value: day + 1,
    })),

    day: Array.from(Array(Number(billingFrequency)).keys()).map((day) => ({
      label: day + 1 + formatOrdinalSuffix(day + 1),
      value: day + 1,
    })),
  };

  console.log('freq', billingFrequencyUnit, billingFrequency, selectOption);

  return (
    <>
      <div className="pb-2 mb-6 text-l border-b border-gray-300">Subscription Details</div>
      <div className="flex space-x-2">
        <div className="w-1/2">
          <Controller
            control={control}
            name="subscriptionDetails.startDate"
            render={({ field }) => (
              <DatePicker
                value={field.value ? new Date(field.value) : undefined}
                onChange={(date) => field.onChange(date)}
                // minDate={new Date()}
                required
                label="Subscription Start Date"
                closeOnSelect
                clearable
              />
            )}
          />
        </div>
        <div className="w-1/2">
          <Controller
            control={control}
            name="subscriptionDetails.endDate"
            render={({ field }) => (
              <DatePicker
                value={field.value ? new Date(field.value) : undefined}
                onChange={(date) => field.onChange(date)}
                // minDate={new Date()}
                label="Subscription End Date"
                closeOnSelect
                clearable
                enableForever
                forever={endForever}
                onForeverChange={(forever) => {
                  setEndForever(forever);
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="flex space-x-2">
        <div className="w-1/2 flex space-x-2">
          <Controller
            name="subscriptionDetails.billingFrequency"
            control={control}
            defaultValue={1}
            render={({ field }) => (
              <TextField
                type={'number'}
                error={get(errors, 'subscriptionDetails.billingFrequency')}
                label="Billing Frequency"
                required
                inputProps={{
                  ...field,
                  onChange: (e) => {
                    field.onChange(Number(e.target.value));
                  },
                }}
              />
            )}
          />
          <Controller
            name="subscriptionDetails.billingFrequencyUnit"
            control={control}
            render={({ field }) => (
              <Select
                error={get(errors, 'subscriptionDetails.billingFrequencyUnit')}
                label={`Every ${billingFrequency}`}
                onSelect={field.onChange}
                defaultValue={'month'}
                required
                value={field.value}
              >
                {ServiceOrder.BillingFrequencyUnits.map((unit, index) => (
                  <SelectOption key={'billingFrequencyUnits' + index} value={unit}>
                    {toTitleCase(unit + (billingFrequency > 1 ? 's' : ''))}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="w-1/2">
          <Controller
            name="subscriptionDetails.billingDay"
            control={control}
            render={({ field }) => (
              <Select
                error={get(errors, 'subscriptionDetails.billingDay')}
                label={frequencyLabel[billingFrequencyUnit]}
                onSelect={field.onChange}
                defaultValue={''}
                value={field.value}
                required
              >
                {selectOption[billingFrequencyUnit].map((option, index) => (
                  <SelectOption key={'billingDay' + index} value={option.value}>
                    {option.label}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
        </div>
      </div>
      <div className="flex space-x-2">
        <Checkbox
          label="Has Contract"
          inputProps={{
            checked: Boolean(watch('serviceGrouping.contractDetails')),
            onChange: (e) => {
              console.log(e.target.checked);
              if (e.target.checked) {
                setValue('serviceGrouping.contractDetails', {
                  contractTerm: 0,
                  contractTermUnit: 'month',
                  contractStartDate: '',
                  active: true,
                  salesRepID: '',
                });
              } else {
                setValue('serviceGrouping.contractDetails', undefined);
              }
            },
          }}
        />
      </div>
      {Boolean(watch('serviceGrouping.contractDetails')) && (
        <>
          <div className="flex space-x-2">
            <div className="w-1/2">
              <Controller
                control={control}
                name="serviceGrouping.contractDetails.contractTerm"
                defaultValue={undefined}
                render={({ field }) => (
                  <TextField
                    type={'number'}
                    label="Contract Term"
                    error={get(errors, 'serviceGrouping.contractDetails.contractTerm')}
                    required
                    inputProps={{
                      ...field,
                      disabled: !Boolean(watch('serviceGrouping.contractDetails')),
                      onChange: (e) => {
                        field.onChange(Number(e.target.value));
                        computeContractEndDate();
                      },
                    }}
                  />
                )}
              />
            </div>
            <div className="w-1/2">
              <Controller
                control={control}
                name="serviceGrouping.contractDetails.contractTermUnit"
                defaultValue={undefined}
                render={({ field }) => (
                  <Select
                    error={get(errors, 'serviceGrouping.contractDetails.contractTermUnit')}
                    label="Contract Term Unit"
                    onSelect={(change) => {
                      field.onChange(change);
                      computeContractEndDate();
                    }}
                    defaultValue={''}
                    value={field.value}
                    required
                    disabled={!Boolean(watch('serviceGrouping.contractDetails'))}
                  >
                    {ServiceOrder.ContractTermUnits.map((unit, index) => (
                      <SelectOption key={'contractTermUnit' + index} value={unit}>
                        {toTitleCase(unit)}
                      </SelectOption>
                    ))}
                  </Select>
                )}
              />
            </div>
          </div>
          <div className="flex space-x-2">
            <div className="w-1/2">
              <Controller
                control={control}
                name="serviceGrouping.contractDetails.contractStartDate"
                render={({ field }) => (
                  <DatePicker
                    value={field.value ? new Date(field.value) : ''}
                    onChange={(date) => {
                      field.onChange(date);
                      computeContractEndDate();
                    }}
                    // minDate={new Date()}
                    label="Contract Start Date"
                    closeOnSelect
                    clearable
                    required
                  />
                )}
              />
            </div>
            <div className="w-1/2">
              <DatePicker
                value={contractEndDate ? new Date(contractEndDate) : undefined}
                minDate={new Date()}
                label="Contract End Date"
                closeOnSelect
                clearable
                inputProps={{
                  disabled: true,
                }}
              />
            </div>
          </div>
        </>
      )}
      <Controller
        control={control}
        name="subscriptionDetails.internalNotes"
        render={({ field }) => (
          <TextField
            type={'string'}
            label="Internal Notes"
            helperText="Notes only Sourgum team members can see"
            error={get(errors, 'subscriptionDetails.internalNotes')}
            inputProps={{
              ...field,
            }}
          />
        )}
      />
    </>
  );
};
export default SubscriptionDetailsSubForm;
