import { useEffect, useState } from 'react';
import { Hauler, Pricing } from '@alliance-disposal/transport-types';
import { Button, Checkbox, CurrencyTextField, Loading, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { round } from '@wayste/utils';
import { Controller, useForm } from 'react-hook-form';
import { statesAbb } from '../../utils';

export interface RollOffPricingFormProps {
  id?: string;
  haulerID: string;
  state: Pricing.UsStateAbbreviation;
  zoneName: string;
  tripCharge: number;
  tripChargeDollars: number;
  haulerName: string;
  notes: string;
  copyExistingZone?: string;
}
export interface HaulerPricingFormProps {
  hauler: Hauler.HaulerWithAapTransport;
  haulerPricingZones: Pricing.PricingTransport[];
  pricing?: Pricing.PricingTransport;
  onSubmit: (data: RollOffPricingFormProps) => Promise<void>;
  onCancel: (data: { status: string | number }) => void;
  isLoading: boolean;
  onDelete?: (data: any) => void;
  mode: 'Rolloff' | 'Universal';
}

export interface FormProps {
  state: string;
  zoneName: string;
  tripCharge: number | '';
  notes: string;
  copyExistingZone?: string;
}

export const HaulerPricingForm = ({
  pricing,
  onSubmit,
  onCancel,
  isLoading,
  hauler,
  haulerPricingZones,
}: HaulerPricingFormProps) => {
  // STATE
  const [acknowledge, setAcknowledge] = useState<boolean>(false);
  // FORM STATE
  const { handleSubmit, reset, watch, control } = useForm<FormProps>({
    defaultValues: {
      state: '',
      zoneName: '',
      tripCharge: '',
      notes: '',
      copyExistingZone: '',
    },
    mode: 'all',
  });

  const formValues = watch();

  /////////////////////////////////////////////
  // COMPONENT FUNCTIONS
  /////////////////////////////////////////////

  const handleFormSubmit = async (data: FormProps) => {
    if (!pricing) {
      const newData: RollOffPricingFormProps = {
        state: data.state as Pricing.UsStateAbbreviation,
        haulerID: hauler.id,
        tripCharge: data.tripCharge ? round(data.tripCharge, 0) : 0,
        tripChargeDollars: data.tripCharge ? round(data.tripCharge / 100) : 0,
        haulerName: hauler.name,
        zoneName: data.zoneName,
        notes: data.notes,
        copyExistingZone: data.copyExistingZone,
      };

      onSubmit(newData);
    } else {
      const newData: RollOffPricingFormProps = {
        id: pricing.id,
        state: data.state as Pricing.UsStateAbbreviation,
        haulerID: hauler.id,
        tripCharge: data.tripCharge ? round(data.tripCharge, 0) : 0,
        tripChargeDollars: data.tripCharge ? round(data.tripCharge / 100) : 0,
        haulerName: hauler.name,
        zoneName: data.zoneName,
        notes: data.notes,
        copyExistingZone: data.copyExistingZone,
      };

      onSubmit(newData);
    }
  };

  useEffect(() => {
    if (pricing) {
      reset({
        zoneName: pricing.zoneName ? pricing.zoneName : '',
        tripCharge: pricing.tripCharge ? pricing.tripCharge : '',
        state: pricing.state,
        notes: pricing.notes ? pricing.notes : '',
      });
    }
  }, [pricing]);

  ////////////////////////////////////////////
  // RENDER SECTION
  ////////////////////////////////////////////

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="my-4 space-y-4">
        <div className="w-full">
          <Controller
            name="zoneName"
            control={control}
            render={({ field }) => <TextField label="Zone name" required inputProps={{ ...field }} />}
          />
        </div>

        <div className="w-full md:w-1/2">
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <Select
                label="State"
                required
                onSelect={(value) => {
                  field.onChange(value);
                }}
                value={field.value}
              >
                {statesAbb.map((item) => (
                  <SelectOption key={`state-${item}`} value={item}>
                    {item}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
        </div>

        <div className="w-full">
          <h2 className="pl-2 text-xl mb-4">Zone Pricing</h2>
        </div>

        <div className="w-full md:w-1/2">
          <Controller
            name="tripCharge"
            control={control}
            render={({ field }) => <CurrencyTextField useCents label="Trip charge" variant="standard" {...field} />}
          />
        </div>

        <div className="w-full">
          <Controller
            name="notes"
            control={control}
            render={({ field }) => <TextField label="Notes" inputProps={{ ...field }} />}
          />
        </div>

        {haulerPricingZones.length > 0 && (
          <>
            <div className="p-2">
              <p>
                <b>Optional:</b> Select existing zone to copy over pricing from that zone
              </p>
            </div>
            <Controller
              control={control}
              name="copyExistingZone"
              render={({ field }) => (
                <Select
                  label="Copy existing zone"
                  value={field.value}
                  onSelect={(value) => {
                    field.onChange(value || '');
                  }}
                  renderLabel={(value) => {
                    if (typeof value === 'string') {
                      return value ? `${haulerPricingZones?.find((item) => item.id === value)?.zoneName} ` : '';
                    }
                    return '';
                  }}
                >
                  {haulerPricingZones?.map((item) => (
                    <SelectOption key={`zone-${item.zoneName}`} value={item.id}>
                      {item.zoneName}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </>
        )}

        {formValues.copyExistingZone && haulerPricingZones.length > 0 && (
          <div className="py-4">
            <Checkbox
              inputProps={{
                checked: acknowledge,
                onChange: (e) => setAcknowledge(e.target.checked),
              }}
              label="I understand that checking this box will overwrite all pricing in this zone. These changes in pricing will only apply to new orders. Orders that have been delivered or accepted for delivery will not be subject to this pricing change. Contractor discounts will remain unchanged and will need to be updated if necessary."
              labelClass="text-sm"
            ></Checkbox>
          </div>
        )}
      </div>
      <div className="flex justify-end gap-4 pt-4">
        <Button
          className="btn-dark-grey-outlined"
          onClick={() => onCancel({ status: 'cancel' })}
          type="button"
          disabled={isLoading}
        >
          cancel
        </Button>

        {formValues.copyExistingZone ? (
          <Button className="btn-primary" type="submit" disabled={isLoading || !acknowledge}>
            {pricing ? 'Update' : 'Create'}
            {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
          </Button>
        ) : (
          <Button className="btn-primary" type="submit" disabled={isLoading}>
            {pricing ? 'Update' : 'Create'}
            {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
          </Button>
        )}
      </div>
    </form>
  );
};
