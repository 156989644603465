import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface Props {
  open: boolean;
  onClose: (xClick?: boolean) => void;
  children: React.ReactNode;
  styledTitle?: string;
  className?: string;
  fullScreen?: boolean;
  showX?: boolean;
  hideX?: boolean;
}

const DialogWrapper = ({
  open,
  onClose,
  styledTitle,
  children,
  className = '',
  fullScreen = false,
  showX = false,
  hideX,
}: Props) => {
  const disableInitialFocusRef = useRef(null);

  const closeModal = (xClick?: boolean) => {
    onClose(xClick);
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className={`relative z-[1300]`} onClose={closeModal} initialFocus={disableInitialFocusRef}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className={`flex min-h-full items-center justify-center p-4 text-center ${fullScreen ? '!p-0' : ''}`}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full transform overflow-auto rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all max-h-[90vh] ${
                  fullScreen ? '!w-screen !h-screen !max-w-[100vw] !max-h-[100vh] rounded-none !overflow-scroll' : ''
                } ${className && className.includes('max-w') ? '' : 'max-w-md'} ${className}`}
              >
                <div ref={disableInitialFocusRef} />
                {styledTitle && (
                  <div
                    className={`-mt-6 -mx-6 mb-3 rounded-t-2xl bg-sourgum-greyblue-900 px-6 py-2.5 text-xl font-medium text-white flex justify-between items-center ${
                      fullScreen ? 'rounded-t-none' : ''
                    }`}
                  >
                    {styledTitle}
                    {((fullScreen && !hideX) || showX) && (
                      <button className="btn-icon" onClick={() => closeModal(true)}>
                        <XMarkIcon className="cursor-pointer h-6 w-6 hover:text-sourgum-greyblue-900" />
                      </button>
                    )}
                  </div>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DialogWrapper;
