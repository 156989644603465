import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { SourFilters, SourSearch } from '@wayste/sour-search';
import { PlusIcon } from '@heroicons/react/24/solid';
import ChangeRequestDialog from '../ChangeRequestDialog';
import OrderRow from './OrderRow';

const tableHeaders = [
  { heading: 'Status' },
  { heading: 'Delivery' },
  { heading: 'Pick Up' },
  { heading: 'Expires' },
  { heading: 'Address' },
  { heading: 'Order #' },
  { heading: 'Customer' },
  { heading: 'Details' },
];
interface OrdersListProps {
  orders: (Order.AllianceOrderTransport & {
    receivables: Invoice.ReceivableTransport[];
    payables: Invoice.PayableTransport[];
  })[];
  usingSearchData: boolean;
  onCreateNewClicked: () => void;
  onSendHaulerCheckIns: () => void;
  onOrderRowClicked: (
    order: Order.AllianceOrderTransport & {
      receivables: Invoice.ReceivableTransport[];
      payables: Invoice.PayableTransport[];
    },
  ) => void;
  isDefaultFilter: boolean;
}

const OrdersList = ({
  orders,
  usingSearchData,
  onCreateNewClicked,
  onOrderRowClicked,
  isDefaultFilter,
}: OrdersListProps) => {
  const client = useWaysteClient();
  const [changeRequestCount, setChangeRequestCount] = useState(0);
  const [changeRequestDialogOpen, setChangeRequestDialogOpen] = useState(false);

  useEffect(() => {
    const sub = client
      .adminPortal()
      .order.changes.subscribe({
        status: ['PENDING'],
      })
      .subscribe((changes: { total: number }) => {
        setChangeRequestCount(changes.total);
      });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <>
      <ChangeRequestDialog
        open={changeRequestDialogOpen}
        onClose={() => {
          setChangeRequestDialogOpen(false);
        }}
      />
      <div className=" w-full flex flex-col-reverse sm:flex-row gap-2 sm:justify-between item-center sm:mt-4 sm:p-2">
        <div className="flex flex-row justify-between gap-2 w-full px-2 sm:px-0">
          <SourSearch
            options={{
              searchPopoverFixed: false,
              showTips: true,
              showMessages: !isDefaultFilter,
              showResults: !isDefaultFilter,
              placeholder: 'Search Orders',
            }}
          />
          <div className="ml-auto sm:ml-0 h-10">
            <SourFilters className="h-10 shadow-none border" />
          </div>
        </div>

        <div className="w-full grid grid-cols-2 gap-2 sm:flex flex-1 flex-row h-10 p-2 sm:py-0 sm:pl-0 min-w-fit">
          <button className="flex-shrink-0 btn-primary h-full" onClick={() => onCreateNewClicked()}>
            <PlusIcon className="pr-1 h-5 w-5" /> New
          </button>

          <button
            className="flex-shrink-0 btn-secondary-dark-grey relative"
            onClick={() => {
              setChangeRequestDialogOpen(true);
            }}
          >
            Change Requests
            <span className="inline-flex items-center justify-center p-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full absolute z-1 -top-2.5 -left-2">
              {changeRequestCount}
            </span>
          </button>
        </div>
      </div>
      <div className="flex flex-col flex-1 overflow-auto m-2 bg-white rounded-md shadow-dark">
        <div className="flex-1 w-full overflow-x-auto">
          <table className="w-full border-separate border-spacing-0">
            <thead>
              <tr>
                {tableHeaders.map((heading) => (
                  <td
                    key={heading.heading}
                    className="whitespace-nowrap p-4 sticky top-0 z-[2] text-sm bg-white border-b border-b-gray-200"
                  >
                    {heading.heading}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <OrderRow
                  key={order.id}
                  order={order}
                  onOrderRowClicked={onOrderRowClicked}
                  usingSearchData={usingSearchData}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default OrdersList;
