import React, { useContext } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { PlusIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { UserContext } from '../../contexts/UserContext';
import { mediumTypes } from '../../utils/shared-types';

export interface ContactedDetailsProps {
  lead: Customer.AllianceLeadTransport;
  onActionClick: (action: string) => void;
}

const ContactedDetails = ({ lead, onActionClick }: ContactedDetailsProps) => {
  const { userIDToNameMap } = useContext(UserContext);

  return (
    <div className="bg-white rounded shadow-dark py-1.5 mb-5">
      <div className="px-4">
        <div className="flex justify-between items-center">
          <h6 className="text-xl font-medium">Contacted Details</h6>
          <button className="btn-primary" onClick={() => onActionClick('contacted')}>
            <PlusIcon className="h-5 w-5 mr-1.5" />
            Add
          </button>
        </div>
        Created at {format(new Date(lead.metadata.createdAt), 'MM/dd/yy hh:mm aaa')} by{' '}
        {userIDToNameMap[lead.metadata.createdByUserID]}
      </div>
      <hr className="mt-2" />
      <div className="w-full -mb-1.5">
        <table className="w-full border-collapse border-spacing-0 text-sm">
          <thead>
            <tr className="[&>*]:px-4 [&>*]:py-1.5 bg-gray-100 font-norma text-left">
              <th>Date</th>
              <th>Medium</th>
            </tr>
          </thead>
          <tbody>
            {lead.contactEvents.map((item: Customer.AllianceContactEvent) => (
              <tr key={item.date} className="[&>*]:px-4 [&>*]:py-1.5 [&>*]:border-b">
                {item?.date && <td>{format(new Date(item.date), 'MM/dd/yy hh:mm aaa')}</td>}
                <td>{mediumTypes[item?.medium as keyof typeof mediumTypes]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactedDetails;
