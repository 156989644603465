import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { S3ItemReference, UniversalService } from '@alliance-disposal/transport-types';
import { formatISODateString, formatServiceAddress, formatTimestamp } from '@wayste/utils';
import { PencilIcon, PhotoIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import { orderImageTypes, routes } from '../../../utils';
import FileHandler from '../../FileHandler/FileHandler';
import InternalNotes from '../../UniversalServices/InternalNotes';
import UniversalServicesStatusPopper from '../../UniversalServices/UniversalServicesStatusPopper';
import CardInfoList from '../../ui/CardInfoList';
import DetailsCardWrapper from '../../ui/DetailsCardWrapper';

interface OneTimeServiceDetailsCardProps {
  serviceOrder: UniversalService.ServiceOrder;
  serviceGrouping: UniversalService.ServiceGrouping;
  lastUpdatedBy?: string;
  createdBy?: string;
  vendor?: string;
}

interface cardData {
  label: string;
  value: React.ReactNode;
}

const OneTimeServiceDetailsCard = ({
  serviceOrder,
  serviceGrouping,
  lastUpdatedBy,
  createdBy,
  vendor,
}: OneTimeServiceDetailsCardProps) => {
  const history = useHistory();
  const client = useWaysteClient();
  const [images, setImages] = useState<S3ItemReference[] | undefined>(serviceOrder.images);
  const [showImages, setShowImages] = useState<boolean>(false);

  const handleEditServiceClick = () => {
    history.push(
      routes.universalServices.serviceGrouping.serviceOrder.update(serviceOrder.serviceGroupingID, serviceOrder.id),
    );
  };

  const onFileUpload = async (file: S3ItemReference) => {
    const orderUpdate: UniversalService.ServiceOrderUpdate = {
      images: [...(images || []), file],
    };
    await client
      .universalService()
      .serviceGrouping.serviceOrder.update(serviceOrder.serviceGroupingID, serviceOrder.id, orderUpdate);
    setImages(orderUpdate.images);
  };

  const onFilesEdit = async (files: S3ItemReference[]) => {
    const orderUpdate: UniversalService.ServiceOrderUpdate = {
      images: files,
    };
    await client
      .universalService()
      .serviceGrouping.serviceOrder.update(serviceOrder.serviceGroupingID, serviceOrder.id, orderUpdate);
    setImages(orderUpdate.images);
  };

  const colOne: cardData[] = [
    {
      label: 'Service',
      value: `${serviceOrder.serviceType.family.name} - ${serviceOrder.serviceType.name}`,
    },
    {
      label: 'PO Number',
      value: serviceOrder.poNumber,
    },
    {
      label: 'Service Address',
      value: formatServiceAddress(serviceOrder.serviceLocation?.address),
    },
    {
      label: 'County',
      value: serviceOrder.serviceLocation?.county,
    },
  ];
  const colTwo: cardData[] = [
    {
      label: 'Start Date',
      value: formatISODateString(serviceOrder.startDate, undefined, ''),
    },
    {
      label: 'End Date',
      value: formatISODateString(serviceOrder.endDate, undefined, ''),
    },
    {
      label: 'Delivery Date',
      value: formatISODateString(serviceOrder.deliveryDate, undefined, 'N/A - No Asset Delivered'),
    },
    {
      label: 'Delivery Notes',
      value: serviceOrder.deliveryNotes,
    },
    {
      label: 'Removal Date',
      value: formatISODateString(serviceOrder.removalDate, undefined, ''),
    },
    {
      label: 'Vendor only Notes',
      value: serviceOrder.vendorOnlyNotes,
    },
    {
      label: 'Internal Notes',
      value: <InternalNotes serviceGrouping={serviceGrouping} serviceOrderID={serviceOrder.id} />,
    },
  ];
  const colThree: cardData[] = [
    {
      label: 'Needs Attention',
      value: serviceOrder.needsAttention ? 'Yes' : 'No',
    },
    {
      label: 'Order #',
      value: serviceOrder.fullOrderNumber,
    },
    {
      label: 'Status',
      value: <UniversalServicesStatusPopper serviceOrder={serviceOrder} />,
    },
    {
      label: 'Vendor',
      value: vendor,
    },
    {
      label: 'Issues',
      value: '',
    },
    {
      label: 'Created By',
      value: createdBy,
    },
    {
      label: 'Created Date',
      value: formatTimestamp(serviceOrder.metadata.createdAt, ''),
    },
    {
      label: 'Last Updated By',
      value: lastUpdatedBy,
    },
    {
      label: 'Last Updated Date',
      value: formatTimestamp(serviceOrder.metadata.lastUpdatedAt, ''),
    },
  ];
  return (
    <DetailsCardWrapper
      heading="Service Details"
      buttons={[
        {
          label: (
            <>
              <PencilIcon className="w-5 h-5 mr-1" /> Edit Service
            </>
          ),
          onClick: handleEditServiceClick,
        },
        {
          label: (
            <>
              <PhotoIcon className="w-5 h-5 mr-1" /> Service Image
            </>
          ),
          onClick: () => setShowImages(true),
        },
      ]}
    >
      <FileHandler
        files={images || []}
        folderName={`order${serviceOrder.fullOrderNumber?.toString() ?? ''}/`}
        types={orderImageTypes}
        open={showImages}
        onCancel={() => setShowImages(false)}
        onFileUpload={onFileUpload}
        onFilesEdit={onFilesEdit}
      />
      <div className={'grid grid-cols-1 gap-4 lg:grid-cols-3'}>
        <CardInfoList data={colOne} border />
        <CardInfoList data={colTwo} border />
        <CardInfoList data={colThree} />
      </div>
    </DetailsCardWrapper>
  );
};

export default OneTimeServiceDetailsCard;
