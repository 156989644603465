import React, { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Pricing } from '@alliance-disposal/transport-types';
import { formatUSD, moneyFormatter } from '@wayste/utils';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/solid';
import Grid from '@mui/material/Grid';
import { format } from 'date-fns';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import {
  growthStageTypes,
  materials,
  onlineGuaranteeTypes,
  operatingDayTypes,
  priceTypes,
  priceTypesEnums,
  routes,
} from '../../utils';
import AuditLogTable from '../AuditLogTable';
import CustomPricingTable from '../CustomPricing/components/CustomPricingTable';
import CustomMaterialPricingModal, {
  AddMaterialProps,
} from '../CustomPricing/components/modals/CustomMaterialPricingModal';
import DeleteCustomMaterialPricingModal, {
  DeleteMaterialProps,
} from '../CustomPricing/components/modals/DeleteCustomMaterialPricingModal';
import Dialog from '../Dialog';
import Loading from '../Loading';
import MapEditorModal from '../MapEditor/MapEditorModal';
import MaterialPricingDialog from '../MaterialPricingDialog';
import PricingUpdate from '../PricingUpdate';
import ServiceAreasMap from '../ServiceAreaMap';
import DetailsCardWrapper from '../ui/DetailsCardWrapper';

const materialHeadings = ['Material', 'Price Type', 'Haul', 'Dump', 'Sizes', 'Order Online', 'Last Updated', ''];

const PricingDetails = () => {
  const history = useHistory();
  const client = useWaysteClient();
  const { id } = useParams() as { id?: string };
  const [pricing, setPricing] = useState<Pricing.PricingTransport | null>(null);
  const [serviceAreas, setServiceAreas] = useState<Pricing.ServiceAreaTransport[]>();
  const [contractorPricing, setContractorPricing] = useState<Pricing.CustomerDiscountTransport[]>();
  const [customPricingModalProps, setCustomPricingModalProps] = useState<AddMaterialProps | Record<string, never>>({});
  const [deleteCustomPricingModalProps, setDeleteCustomPricingModalProps] = useState<
    DeleteMaterialProps | Record<string, never>
  >({});
  const [showUpdatePricing, setShowUpdatePricing] = useState(false);
  const [showMaterialPricingDialog, setShowMaterialPricingDialog] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState<{
    [x: string]: any;
  } | null>(null);
  const [showPricingChangesID, setShowPricingChangesID] = useState(false);
  const [editServiceArea, setEditServiceArea] = useState(false);

  type MaterialKey = keyof typeof materials;

  const getContractorPricing = async (id: string) => {
    const contractorDiscount = await client.adminPortal().customerDiscount.query({
      contractor: true,
      pricingZoneID: id,
    });
    setContractorPricing(contractorDiscount.results);
  };

  const handleGetPricing = async () => {
    if (!id) return;
    const data = await client.pricing().adminPortal.pricing.fetch(id);

    const serviceAreas = data.serviceAreas;
    setServiceAreas(serviceAreas);

    setPricing(data);
  };

  useEffect(() => {
    if (id) {
      handleGetPricing();
      getContractorPricing(id);
    }
    // TODO this would be better served as a subscription IMO
  }, [id]);

  const sortMaterials = (data: Pricing.PricingTransport['pricingData']) => {
    return data.sort((a, b) =>
      materials[a.material as MaterialKey] > materials[b.material as MaterialKey]
        ? 1
        : materials[b.material as MaterialKey] > materials[a.material as MaterialKey]
        ? -1
        : 0,
    );
  };

  const handleAddContractorMaterial = async ({
    materialDiscount,
    pricingZoneID,
  }: {
    pricingZoneID: string;
    materialDiscount: Pricing.DiscountDataTransport;
  }) => {
    try {
      if (!pricing?.id) return;
      const payload: Pricing.DiscountDataTransport = {
        ...materialDiscount,
      };
      const deep = _.cloneDeep(contractorPricing);

      const findZoneIndex = deep?.findIndex((x) => x.pricingZoneID === pricingZoneID);
      if (findZoneIndex === undefined || findZoneIndex === -1 || !deep) return;
      const newPricingDiscount = deep[findZoneIndex];

      const materialExistIndex = newPricingDiscount.discountData.findIndex(
        (x) => x.material === materialDiscount.material,
      );
      // if exist, replace, else push...
      if (materialExistIndex === undefined || materialExistIndex === -1) {
        newPricingDiscount.discountData = newPricingDiscount.discountData.map((x) => {
          return {
            material: x.material,
            sizes: x.sizes.map((y) => {
              const sizeMapped: Pricing.DiscountDataTransport['sizes'][0] = {
                haul: y.haul,
                dump: y.dump,
                over: y.over,
                tonLimit: y.tonLimit,
                size: y.size,
              };
              return sizeMapped;
            }),
          };
        });

        newPricingDiscount.discountData.push(payload);
      } else {
        newPricingDiscount.discountData = newPricingDiscount.discountData.map((x) => {
          return {
            material: x.material,
            sizes: x.sizes.map((y) => {
              const sizeMapped: Pricing.DiscountDataTransport['sizes'][0] = {
                haul: y.haul,
                dump: y.dump,
                over: y.over,
                tonLimit: y.tonLimit,
                size: y.size,
              };
              return sizeMapped;
            }),
          };
        });
        newPricingDiscount.discountData[materialExistIndex] = payload;
      }
      await client.adminPortal().customerDiscount.changes.update({
        updateCustomerDiscountId: newPricingDiscount.id,
        changes: {
          discountData: newPricingDiscount.discountData,
          contractor: true,
        },
      });

      await getContractorPricing(pricing.id);
    } catch (error) {
      console.warn('error', error);
      alert('An error has occurred, please contact an AAP dev.');
    }
  };

  const handleDeleteContractorMaterial = async ({
    customerDiscountID,
    material,
  }: {
    customerDiscountID: string;
    material: string;
  }) => {
    if (!pricing?.id) return;
    try {
      const deep = _.cloneDeep(contractorPricing);

      const findZoneIndex = deep?.findIndex((x) => x.id === customerDiscountID);
      if (findZoneIndex === undefined || findZoneIndex === -1 || !deep) return;
      const customerDiscount = deep[findZoneIndex];
      const filteredData = customerDiscount.discountData.filter((x) => x.material !== material);
      customerDiscount.discountData = filteredData;
      customerDiscount.discountData = customerDiscount.discountData.map((x) => {
        return {
          material: x.material,
          sizes: x.sizes.map((y) => {
            const sizeMapped: Pricing.DiscountDataTransport['sizes'][0] = {
              haul: y.haul,
              dump: y.dump,
              over: y.over,
              tonLimit: y.tonLimit,
              size: y.size,
            };
            return sizeMapped;
          }),
        };
      });
      await client.adminPortal().customerDiscount.changes.update({
        updateCustomerDiscountId: customerDiscountID,
        changes: {
          discountData: customerDiscount.discountData,
          contractor: true,
        },
      });

      await getContractorPricing(pricing?.id);
    } catch (error) {
      console.warn('ERROR', error);
      alert('An error has occurred, please contact AAP dev.');
    }
  };

  const handleEditClick = (
    material: {
      [x: string]: any;
    } | null,
  ) => {
    setSelectedMaterial(material);
    setShowMaterialPricingDialog(true);
  };

  const handleBackButtonClick = () => {
    const pathParam = pricing?.state ? `?state=${pricing.state}` : '';
    history.push(`${routes.pricing.list}${pathParam}`);
  };

  const handleMaterialPricingClose = (response: any) => {
    setShowMaterialPricingDialog(false);
    setSelectedMaterial(null);
    if (response?.status === 'success') {
      handleGetPricing();
    }
  };

  const handlePricingUpdateClose = (response: any) => {
    setShowUpdatePricing(false);
    if (response?.status === 'success') {
      handleGetPricing();
    }
  };

  const handleViewPricingChanges = () => {
    setShowPricingChangesID(true);
  };

  const handleServiceAreaSubmit = async (data?: any) => {
    //if (!data || !pricingZoneData?.id) return;
    const addServiceAreaPayload: Pricing.ServiceAreaTransport[] = data?.map((x: any) => {
      const serviceArea: any = {
        boundary: x.serviceArea,
        boundaryFormat: x.serviceAreaFormat,
        areaName: x.areaName,
        taxJurisdiction: x.taxJurisdiction,
      };
      return serviceArea;
    });
    if (pricing) {
      try {
        await client.pricing().adminPortal.serviceArea.update(pricing.id, addServiceAreaPayload);
        await handleGetPricing();
        showFlash('Service Area Updated', 'success');
      } catch (error) {
        showFlash('Error updating service area', 'error');
      }
    }
  };

  if (!pricing) return <Loading />;

  const allowOnlineCheckout = pricing.pricingData.some((material) => material.allowOnlineCheckout);

  const details = [
    { label: 'State', value: pricing.state },
    { label: 'Rental Period', value: pricing.rentalPeriod?.value },
    {
      label: 'Rental Extension',
      value: pricing.rentExtensionFeeDollars ? formatUSD(pricing.rentExtensionFeeDollars) : '',
    },
    { label: 'Max Rental Period', value: pricing.maxRentalDaysAllowed },
    {
      label: 'Trip Charge',
      value: pricing.tripCharge ? moneyFormatter(pricing.tripCharge) : '',
    },
    {
      label: 'Last Updated',
      value: format(new Date(pricing.metadata?.lastUpdatedAt), 'MM/dd/yy'),
    },
    {
      label: 'Growth Stage',
      value: growthStageTypes[pricing.currentGrowthStage as keyof typeof growthStageTypes],
    },
    { label: 'Metro', value: pricing.metro },
  ];
  const orderOnlineDetails = [
    // TODO allowOnlineCheckout needs to be moved to the material level
    {
      label: 'Order Materials Online',
      value: allowOnlineCheckout ? 'Yes' : 'No',
    },
    {
      label: 'Checkout Page Guarantee',
      value: onlineGuaranteeTypes[pricing.onlineCheckoutPageGuarantee as keyof typeof onlineGuaranteeTypes],
    },
    {
      label: 'Allowed to Pre-Purchase Rental Days',
      value: pricing.allowForPrePaidRentalDays ? 'Yes' : 'No',
    },
    {
      label: 'Pre-Purchase Rental Days Discount Rate',
      value: `${pricing?.prePaidRentalDaysDiscountRate ? pricing?.prePaidRentalDaysDiscountRate / 100 : 0}%`,
    },
  ];

  const getDumpRateDisplay = (material: Pricing.PricingDataTransport) => {
    if (material.type === priceTypesEnums.flat || material.sizes.every((size) => !size.dump)) {
      return '';
    }
    if (material.sizes.every((size) => size.dump === material.sizes[0]?.dump)) {
      return moneyFormatter(material.sizes[0]?.dump || 0);
    }
    return Object.values(material.sizes).map((item) => (
      <div key={item.size}>
        <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}>{item.size}:</span> {moneyFormatter(item.dump)}
        {', '}
      </div>
    ));
  };

  return (
    <div className="container mx-auto px-6 pt-5 pb-12" style={{ paddingTop: 20, paddingBottom: 48 }}>
      {/* Modals */}
      <MapEditorModal
        open={editServiceArea}
        serviceAreas={pricing?.serviceAreas}
        onClose={() => {
          setEditServiceArea(false);
        }}
        onSubmit={handleServiceAreaSubmit}
      />
      {/* Page Content */}
      <div
        style={{
          paddingBottom: 20,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <button className="btn-dark-grey-outlined" onClick={handleBackButtonClick}>
          <ChevronLeftIcon className="h-5 w-5 mr-1" />
          Pricing
        </button>
      </div>

      <ServiceAreasMap
        pricingZones={serviceAreas ? serviceAreas : undefined}
        editButtonClicked={() => {
          setEditServiceArea(true);
        }}
      />

      <DetailsCardWrapper
        heading={pricing.zoneName || ''}
        buttons={[
          {
            label: (
              <>
                <PencilIcon className="h-4 w-4 mr-1.5" />
                Edit Pricing
              </>
            ),
            onClick: () => setShowUpdatePricing(true),
          },
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {details.map((item) => (
                <React.Fragment key={item.label}>
                  <Grid item xs={6} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {item.label}
                  </Grid>
                  <Grid item xs={6}>
                    {item.value}
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={12}>
                <b>Online Checkout Options</b>
              </Grid>
              {orderOnlineDetails.map((item) => (
                <React.Fragment key={item.label}>
                  <Grid item xs={6} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {item.label}
                  </Grid>
                  <Grid item xs={6}>
                    {item.value}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div>
                  <b>Operating Days</b>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {Object.keys(pricing.days).map((key) => (
                    <div key={key} style={{ marginRight: 10 }}>
                      <div
                        style={{
                          marginBottom: 3,
                          textTransform: 'capitalize',
                          color: 'rgba(0, 0, 0, 0.58)',
                        }}
                      >
                        {key}
                      </div>
                      <div>
                        {operatingDayTypes[
                          pricing.days[key as keyof typeof pricing.days] as keyof typeof operatingDayTypes
                        ] || '?'}
                      </div>
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <b>Prohibited Items</b>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>{pricing.prohibitedItems.join(', ')}</div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <b>Zone Notes</b>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>{pricing?.notes}</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DetailsCardWrapper>
      <DetailsCardWrapper
        heading="Material Pricing"
        buttons={[
          {
            label: (
              <>
                <PlusIcon className="h-5 w-5 mr-1.5" />
                Add New Material
              </>
            ),
            onClick: () => setShowMaterialPricingDialog(true),
          },
          {
            label: 'View Change History',
            onClick: () => handleViewPricingChanges(),
          },
        ]}
      >
        <div style={{ margin: '-16px -20px -20px' }}>
          <div className="w-full overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  {materialHeadings.map((heading) => (
                    <td
                      className="text-sm py-1.5 px-4 border-0 border-b border-solid border-b-gray-200"
                      key={heading}
                      style={{
                        backgroundColor: '#F9F9F9',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {heading}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortMaterials(pricing.pricingData).map((material) => (
                  <tr key={material.material}>
                    <td className="text-sm py-1.5 px-4 border-0 border-b border-solid border-b-gray-200">
                      {materials[material.material as MaterialKey]}
                    </td>
                    <td className="text-sm py-1.5 px-4 border-0 border-b border-solid border-b-gray-200">
                      {priceTypes[material.type as keyof typeof priceTypes]}
                    </td>
                    <td className="text-sm py-1.5 px-4 border-0 border-b border-solid border-b-gray-200">
                      {material.type !== priceTypesEnums.flat &&
                      material.sizes.every((size) => size.haul === material.sizes[0]?.haulDollars)
                        ? `$${material.sizes[0]?.haulDollars}`
                        : Object.values(material.sizes).map((item) => (
                            <div key={item.size}>
                              <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}>{item.size}:</span> ${item.haulDollars}
                              {material.type === priceTypesEnums.ton &&
                              material.sizes.some((size) => size.haul !== material.sizes[0]?.haulDollars) ? (
                                <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}> TL: {item.tonLimit}</span>
                              ) : null}
                              {', '}
                            </div>
                          ))}
                    </td>
                    <td className="text-sm py-1.5 px-4 border-0 border-b border-solid border-b-gray-200">
                      {getDumpRateDisplay(material)}
                    </td>
                    {material.type !== priceTypesEnums.flat ? (
                      <td className="text-sm py-1.5 px-4 border-0 border-b border-solid border-b-gray-200">
                        {material.sizes.map((size, index) => (
                          <span key={size.size}>
                            {size.size}
                            {index === material.sizes.length - 1 ? '' : ', '}
                          </span>
                        ))}
                      </td>
                    ) : (
                      <td />
                    )}
                    <td className="text-sm py-1.5 px-4 border-0 border-b border-solid border-b-gray-200">
                      {material.allowOnlineCheckout ? 'Yes' : 'No'}
                    </td>
                    <td className="text-sm py-1.5 px-4 border-0 border-b border-solid border-b-gray-200">
                      {material.lastUpdatedAt ? format(new Date(material.lastUpdatedAt), 'MM/dd/yy') : ''}
                    </td>
                    <td className="text-sm py-1.5 px-4 border-0 border-b border-solid border-b-gray-200">
                      <button className="btn-icon" onClick={() => handleEditClick(material)}>
                        <PencilIcon className="h-5 w-5 text-edit" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </DetailsCardWrapper>
      {contractorPricing && (
        <div style={{}}>
          {contractorPricing.length === 0 ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'lightgray',
                paddingTop: '2rem',
                paddingBottom: '2rem',
              }}
            >
              <button
                onClick={async () => {
                  const createContractorPricing = await client.adminPortal().customerDiscount.create({
                    contractor: true,
                    pricingZoneID: pricing.id,
                    discountData: [],
                  });
                  const deep = _.cloneDeep(contractorPricing);
                  deep.push(createContractorPricing);
                  setContractorPricing(deep);
                }}
                className="btn-primary"
              >
                <PlusIcon className="h-5 w-5 mr-1.5" />
                Create Contractor Pricing For Zone
              </button>
            </div>
          ) : (
            <DetailsCardWrapper
              heading="Contractor Pricing"
              buttons={[
                {
                  label: (
                    <>
                      <PlusIcon className="h-5 w-5 mr-1.5" />
                      Add New Material
                    </>
                  ),
                  onClick: () => {
                    setCustomPricingModalProps({
                      selectedCustomerDiscount: contractorPricing[0],
                    });
                  },
                },
                {
                  label: 'View Change History',
                  onClick: () => console.log('view pricing clicked'),
                },
              ]}
            >
              <div style={{ margin: '-16px -20px -20px' }}>
                <CustomPricingTable
                  customerDiscount={contractorPricing[0]}
                  onDeleteClick={(materialDiscountInfo) => {
                    setDeleteCustomPricingModalProps({
                      material: materialDiscountInfo.material,
                      customerDiscount: contractorPricing[0],
                    });
                  }}
                  onEditClick={(materialDiscountInfo) => {
                    setCustomPricingModalProps({
                      selectedCustomerDiscount: contractorPricing[0],
                      selectedDiscountData: materialDiscountInfo,
                    });
                  }}
                />
              </div>
            </DetailsCardWrapper>
          )}
        </div>
      )}
      {/* Modals */}
      <PricingUpdate open={showUpdatePricing} onBackButtonClick={handlePricingUpdateClose} pricing={pricing} />
      <CustomMaterialPricingModal
        handleClose={() => setCustomPricingModalProps({})}
        handleSubmit={handleAddContractorMaterial}
        {...customPricingModalProps}
      />
      <DeleteCustomMaterialPricingModal
        {...deleteCustomPricingModalProps}
        handleDeleteMaterial={handleDeleteContractorMaterial}
        handleClose={() => setDeleteCustomPricingModalProps({})}
      />
      <MaterialPricingDialog
        open={showMaterialPricingDialog}
        material={selectedMaterial}
        zone={pricing}
        onBackButtonClick={handleMaterialPricingClose}
      />
      <Dialog
        styledTitle="Pricing Change Log"
        className="max-w-2xl overflow-scroll"
        open={showPricingChangesID}
        onClose={() => setShowPricingChangesID(false)}
      >
        {showPricingChangesID ? <AuditLogTable entityID={pricing.id} entityType="Pricing" /> : null}
        <div className="flex justify-end pt-2">
          <button className="btn-secondary-text-only" onClick={() => setShowPricingChangesID(false)}>
            Close
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default PricingDetails;
function showFlash(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}
