import { useEffect, useRef, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Order } from '@alliance-disposal/transport-types';
import { Pricing } from '@alliance-disposal/transport-types';
import { calculateOrderTotal, getPrimaryCustomerContact } from '@wayste/utils';
import { getDateFormat } from '@wayste/utils';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { paymentMethodsEnums, paymentTermsEnums, priceTypesEnums } from '../../utils';
import { statesAbb } from '../../utils/state-details';
import Dialog from '../Dialog';
import DispatchForm from './DispatchForm';
import OrderForm from './OrderForm';
import PaymentSummary from './PaymentSummary';

const OrderSchema = Yup.object().shape({
  serviceLocation: Yup.object().shape({
    address: Yup.object().shape({
      addressLine1: Yup.string().required('Address 1 is required'),
      addressLine2: Yup.string(),
      city: Yup.string().required('City is required'),
      state: Yup.string().oneOf(statesAbb).required('State is required'),
      zip: Yup.string()
        .matches(/^\d{5}(-\d{4})?$/, 'Must be a valid zip code')
        .required('Zip is required'),
    }),
    county: Yup.string().required('County is required'),
  }),
  material: Yup.string().required('Material is required'),
  priceType: Yup.string().required('Price type is required'),
  expectedSize: Yup.string().required('Size is required'),
  weightLimit: Yup.number().when('priceType', {
    is: priceTypesEnums.ton,
    then: Yup.number().required('Ton limit is required'),
  }),
  adjustedRentalPeriod: Yup.number().required('Rental period is required'),
  rentExtensionFeeDollars: Yup.number().required('Rental extension fee is required'),
  tax: Yup.boolean(),
  taxRate: Yup.number(),
  cc: Yup.boolean(),
  price: Yup.number().required('Quoted Price is required'),
  overageDollars: Yup.number().when('priceType', {
    is: priceTypesEnums.ton,
    then: Yup.number().required('Overage is required'),
  }),
});

type OrderSchemaType = Yup.InferType<typeof OrderSchema>;

const DispatchSchema = Yup.object().shape({
  expectedDeliveryDate: Yup.date().required('A delivery date is required'),
  sharedDeliveryNotes: Yup.string().required('Delivery notes are required'),
  expectedPickupDate: Yup.date().nullable().default(null),
  sharedPickupNotes: Yup.string(),
  noteOrder: Yup.string(),
  sharedDeliveryNotesPrivate: Yup.string(),
  recurringOnCall: Yup.boolean(),
  poNumber: Yup.string(),
});

type DispatchSchemaType = Yup.InferType<typeof DispatchSchema>;

const PaymentSchema = Yup.object().shape({});

type PaymentSchemaType = Yup.InferType<typeof PaymentSchema>;

//const schemaArray = [OrderSchema, DispatchSchema, PaymentSchema];

export interface OrderReviewProps {
  open: boolean;
  onBackButtonClick: any;
  onSubmit: any;
  user: any;
  zonePricing: Pricing.PricingTransport | null;
  onGetPricing: (location: { lat: number; lng: number; zip: string; state: string }) => Promise<void>;
  isLoading: any;
  getOrderTotal: any;
  currentOrder: any;
  existingCustomer: Customer.AllianceCustomerTransport;
}

const OrderReview = ({
  open,
  onBackButtonClick,
  onSubmit,
  user,
  zonePricing,
  onGetPricing,
  isLoading,
  getOrderTotal,
  currentOrder,
  existingCustomer,
}: OrderReviewProps) => {
  const matchesMD = useMediaQuery('(min-width: 960px)');
  const [activeStep, setActiveStep] = useState<number>(3);
  const [validationSchema, setValidationSchema] = useState<OrderSchemaType | DispatchSchemaType | PaymentSchemaType>(
    OrderSchema,
  );
  const formRef = useRef<any>();
  //const modalRef = useRef<any>();

  const handleNext = () => {
    const nextStep = Math.min(activeStep + 1, 3);

    let nextValidationSchema;

    switch (nextStep) {
      case 1:
        nextValidationSchema = DispatchSchema;
        break;
      case 2:
        nextValidationSchema = PaymentSchema;
        break;
      default:
        nextValidationSchema = OrderSchema;
    }

    setValidationSchema(nextValidationSchema);
    setActiveStep(nextStep);
  };

  const handleSubmit = async (values: any) => {
    return onSubmit(values);
  };

  const handleBackButtonClick = (xClicked: any) => {
    if (!xClicked || (formRef.current && formRef.current.values.paymentInfo)) return;
    onBackButtonClick();
  };

  return (
    <Dialog
      open={open}
      onClose={handleBackButtonClick}
      styledTitle="Review Dispatch Ticket"
      fullScreen
      // ref={modalRef as unknown as any}
      // innerRef={formRef}
    >
      <div className={`p-5 flex-1 flex flex-col ${matchesMD ? 'max-w-[960px]' : 'max-w-[500px]'} mx-auto`}>
        {validationSchema !== undefined && (
          <Formik
            initialValues={{
              serviceLocation: {
                address: {
                  addressLine1: '',
                  addressLine2: '',
                  city: '',
                  state: '',
                  zip: '',
                },
                county: '',
                coordinates: null,
              },
              material: '',
              priceType: priceTypesEnums.ton,
              expectedSize: '',
              weightLimit: '',
              adjustedRentalPeriod: '',
              rentExtensionFeeDollars: '',
              tax: true,
              taxRate: 0,
              cc: true,
              price: '',
              otherLineItems: [],
              coords: null,
              dumpRateDollars: null,
              overageDollars: '',
              expectedDeliveryDate: null,
              sharedDeliveryNotes: '',
              expectedPickupDate: null,
              sharedPickupNotes: '',
              noteOrder: '',
              sharedDeliveryNotesPrivate: '',
              recurringOnCall: false,
              poNumber: '',

              paymentTerm: paymentTermsEnums.onCharge,
              paymentMethod: paymentMethodsEnums.creditCard,
              websiteSale: false,
              stripeId: '',
              paymentInfo: null,
              sendEmail: true,
              includeCCForm: false,
              emailMessage: '',
              emailStaticHtml: '',
              emailStaticText: '',
            }}
            validationSchema={validationSchema[activeStep as keyof typeof validationSchema]}
            innerRef={formRef}
            onSubmit={handleSubmit}
          >
            {(formik: any) => (
              <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <FormContent
                  formik={formik}
                  handleNext={handleNext}
                  activeStep={activeStep}
                  zonePricing={zonePricing}
                  onGetPricing={onGetPricing}
                  user={user}
                  currentOrder={currentOrder}
                  isLoading={isLoading}
                  getOrderTotal={() => getOrderTotal(formik.values)}
                  onFormSubmit={() => handleSubmit(formik.values)}
                  existingCustomer={existingCustomer}
                />
              </Form>
            )}
          </Formik>
        )}
      </div>
    </Dialog>
  );
};

export default OrderReview;

export interface FormContentProps {
  formik: any;
  handleNext: any;
  activeStep: number;
  zonePricing: Pricing.PricingTransport | null;
  onGetPricing: any;
  user: any;
  currentOrder: Order.AllianceOrderTransport | null;
  isLoading: any;
  getOrderTotal: any;
  onFormSubmit: any;
  existingCustomer: any;
}

const FormContent = ({
  formik,
  handleNext,
  activeStep,
  zonePricing,
  onGetPricing,
  user,
  currentOrder,
  isLoading,
  getOrderTotal,
  onFormSubmit,
  existingCustomer,
}: FormContentProps) => {
  const client = useWaysteClient();

  const getOrdersWithInvoice = async (order: Order.AllianceOrderTransport) => {
    const receivables = await client.invoice().adminPortal.receivable.query({
      orderID: order.id,
    });

    return calculateOrderTotal(receivables) / 100;
  };

  useEffect(() => {
    (async () => {
      if (currentOrder) {
        const price = await getOrdersWithInvoice(currentOrder);
        formik.setValues({
          ...formik.values,
          ...currentOrder,
          price,

          expectedDeliveryDate: getDateFormat(currentOrder.expectedDeliveryDate) || null,
          expectedPickupDate: getDateFormat(currentOrder.expectedPickupDate) || null,
          rentalEndDate: getDateFormat(currentOrder.rentalEndDate) || null,
          adjustedRentalPeriod: currentOrder.adjustedRentalPeriod.value || null,
          extendingRental: currentOrder.extendingRental || false,
          expectedSize: currentOrder.expectedSize.size || null,
          weightLimit: currentOrder?.weightLimit?.value || null,
          actualWeightDumped: currentOrder?.actualWeightDumped?.value || null,
          primaryContact: getPrimaryCustomerContact(existingCustomer),
          paymentTerm: existingCustomer?.defaultPaymentSettings?.paymentTerm || paymentTermsEnums.onCharge,
          paymentMethod: existingCustomer?.defaultPaymentSettings?.paymentMethod || paymentMethodsEnums.creditCard,
          cc:
            !existingCustomer?.defaultPaymentSettings?.paymentMethod ||
            existingCustomer?.defaultPaymentSettings?.paymentMethod === 'creditCard'
              ? true
              : false,
        });
      }
    })();
  }, [currentOrder]);

  useEffect(() => {
    if (formik.isValid && activeStep !== 0) {
      handleNext();
    }
  }, [formik.isValid]);

  useEffect(() => {
    if (activeStep > 0 && activeStep < 3) {
      handleValidateForm();
    }
  }, [activeStep]);

  const handleValidateForm = () => {
    formik.validateForm().then((res: any) => {
      if (Object.keys(res).length === 0) {
        handleNext();
      }
    });
  };

  return (
    <div style={{ flex: 1 }}>
      <Stepper activeStep={activeStep} orientation="vertical" connector={null}>
        <Step expanded className="TESTING">
          <StepLabel
            sx={{
              '& .MuiSvgIcon-root': {
                width: 32,
                height: 32,
              },
              '& .MuiStepLabel-label': {
                fontSize: 22,
                marginBottom: 1,
                //marginLeft: theme.spacing(2),
              },
            }}
          >
            Order Info
          </StepLabel>
          <StepContent
            sx={{
              border: 0,
              marginBottom: '2%',
              '& .MuiFormControl-root': {
                // marginTop: theme.spacing(1),
                // marginRight: theme.spacing(3),
                // marginBottom: theme.spacing(2),
              },
            }}
          >
            <OrderForm
              //validationSchema={OrderSchema} // not used
              disabled={false}
              zonePricing={zonePricing}
              onGetPricing={onGetPricing}
              values={formik.values}
              setFieldValue={formik.setFieldValue}
              setValues={formik.setValues}
              handleBlur={formik.handleBlur}
              existingCustomer={existingCustomer}
            />
          </StepContent>
        </Step>
        <Step expanded>
          <StepLabel
            sx={{
              '& .MuiSvgIcon-root': {
                width: 32,
                height: 32,
              },
              '& .MuiStepLabel-label': {
                fontSize: 22,
                marginBottom: 1,
                //marginLeft: theme.spacing(2),
              },
            }}
          >
            Dispatch Info
          </StepLabel>
          <StepContent
            sx={{
              border: 0,

              //marginBottom: theme.spacing(6),
              '& .MuiFormControl-root': {
                // marginTop: theme.spacing(1),
                // marginRight: theme.spacing(3),
                // marginBottom: theme.spacing(2),
              },
            }}
          >
            <DispatchForm
              //validationSchema={DispatchSchema}
              disabled={false}
              values={formik.values}
              setFieldValue={formik.setFieldValue}
              // setValues={formik.setValues}
              // disabled={activeStep < 2} was like this
            />
          </StepContent>
        </Step>
        <Step expanded>
          <StepLabel
            sx={{
              '& .MuiSvgIcon-root': {
                width: 32,
                height: 32,
              },
              '& .MuiStepLabel-label': {
                fontSize: 22,
                marginBottom: 1,
                //marginLeft: theme.spacing(2),
              },
            }}
          >
            Payment &amp; Summary
          </StepLabel>
          <StepContent
            sx={{
              border: 0,
              //marginBottom: theme.spacing(6),
              '& .MuiFormControl-root': {
                // marginTop: theme.spacing(1),
                // marginRight: theme.spacing(3),
                // marginBottom: theme.spacing(2),
              },
            }}
          >
            <PaymentSummary
              user={user}
              existingCustomer={existingCustomer}
              isLoading={isLoading}
              onSubmit={onFormSubmit}
              disabled={false}
              // disabled={activeStep < 3} this was disabled
              formik={formik}
              getOrderTotal={getOrderTotal}
              zonePricing={zonePricing}
            />
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
};
