import { UniversalService } from '@alliance-disposal/transport-types';
import {
  booleanFormatter,
  endOfCurrentPeriod,
  formatISODateString,
  formatOrdinalSuffix,
  formatTimestamp,
  moneyFormatter,
} from '@wayste/utils';
import { format } from 'date-fns';
import InternalNotes from '../UniversalServices/InternalNotes';
import CardInfoList from '../ui/CardInfoList';
import DetailsCardWrapper from '../ui/DetailsCardWrapper';

interface cardData {
  label: string;
  value: React.ReactNode;
}

interface SubscriptionDetailsCardProps {
  serviceGrouping: UniversalService.ServiceGrouping;
  subscriptionDetails: UniversalService.SubscriptionDetails;
  contractDetails?: UniversalService.ContractDetails;
  anyServiceOrderNeedsAttention?: boolean;
  serviceAddress: string;
  startDate?: string;
  endDate?: string;
  vendors?: string;
  lastUpdatedBy?: string;
  createdBy?: string;
  salesRep?: string;
}

const frequencyLabel: Record<UniversalService.BillingFrequencyUnit, string> = {
  month: 'day of Month',
  week: 'day of Week',
  day: 'day of Period',
};

const SubscriptionDetailsCard = ({
  subscriptionDetails,
  serviceGrouping,
  contractDetails,
  serviceAddress,
  startDate,
  endDate,
  vendors,
  lastUpdatedBy,
  createdBy,
  salesRep,
  anyServiceOrderNeedsAttention,
}: SubscriptionDetailsCardProps) => {
  const getCancelationDate = () => {
    const date = serviceGrouping?.serviceOrders?.find((serviceOrder) => serviceOrder.cancellationDetails)
      ?.cancellationDetails?.date;
    return date ? format(new Date(date), 'MM/dd/yyyy') : '';
  };

  const colOne: cardData[] = [
    {
      label: 'Name',
      value: serviceGrouping.name,
    },
    {
      label: 'Service Address',
      value: serviceAddress,
    },
    {
      label: 'Create new Invoice',
      value:
        subscriptionDetails?.billingDay && subscriptionDetails?.billingFrequencyUnit
          ? `${subscriptionDetails?.billingDay}${formatOrdinalSuffix(subscriptionDetails?.billingDay)} ${
              frequencyLabel[subscriptionDetails?.billingFrequencyUnit]
            }`
          : '',
    },
    {
      label: 'Next Invoice On',
      value:
        subscriptionDetails?.billingFrequency === 1 && subscriptionDetails?.billingFrequencyUnit === 'month'
          ? format(
              endOfCurrentPeriod({
                billingDay: subscriptionDetails?.billingDay,
                billingFrequency: subscriptionDetails?.billingFrequency,
                billingFrequencyUnit: subscriptionDetails?.billingFrequencyUnit,
              }),
              'MM/dd/yyyy',
            )
          : '',
    },
    {
      label: 'Next Invoice Amount',
      value: moneyFormatter(
        subscriptionDetails?.periodicEvents.reduce((acc, curr) => acc + curr.unitPrice * curr.priceQuantity, 0),
      ),
    },
    {
      label: 'Cancellation Date ',
      value: getCancelationDate(),
    },
    {
      label: 'Cancellation Reason',
      value: serviceGrouping?.serviceOrders?.find((serviceOrder) => serviceOrder.cancellationDetails)
        ?.cancellationDetails?.reason,
    },
  ];
  const colTwo: cardData[] = [
    {
      label: 'Start Date',
      value:
        startDate === 'Multiple Start Dates'
          ? 'Multiple Start Dates'
          : formatISODateString(startDate, undefined, 'Error - Start Date Not Found'),
    },
    {
      label: 'End Date',
      value: formatISODateString(endDate, undefined, ''),
    },
    {
      label: 'Contract Term',
      value: contractDetails?.contractTerm,
    },
    {
      label: 'Contract Start Date',
      value: formatISODateString(contractDetails?.contractStartDate, undefined, ''),
    },
    {
      label: 'Contract End Date',
      value: formatISODateString(contractDetails?.contractEndDate, undefined, ''),
    },
    {
      label: 'Account Executive',
      value: salesRep,
    },
    {
      label: 'Internal Notes',
      value: <InternalNotes serviceGrouping={serviceGrouping} />,
    },
  ];
  const colThree: cardData[] = [
    {
      label: 'Subscription #',
      value: serviceGrouping.orderNumber ?? '',
    },
    {
      label: 'Needs Attention',
      value: booleanFormatter(anyServiceOrderNeedsAttention),
    },
    {
      label: 'Current Vendors',
      value: vendors,
    },
    {
      label: 'Total Issues',
      value: /* serviceGrouping.internalNotes?.filter((note) => note.isIssue).length ?? */ 'None',
    },
    {
      label: 'Created By',
      value: createdBy,
    },
    {
      label: 'Created Date',
      value: formatTimestamp(subscriptionDetails?.metadata.createdAt),
    },
    {
      label: 'Last Updated By',
      value: lastUpdatedBy,
    },
    {
      label: 'Last Updated Date',
      value: formatTimestamp(subscriptionDetails?.metadata.lastUpdatedAt),
    },
  ];

  return (
    <DetailsCardWrapper heading="Subscription Details">
      <div className={'grid grid-cols-1 gap-4 lg:grid-cols-3'}>
        <CardInfoList data={colOne} border />
        <CardInfoList data={colTwo} border />
        <CardInfoList data={colThree} />
      </div>
    </DetailsCardWrapper>
  );
};

export default SubscriptionDetailsCard;
