import { CurrencyTextField, DatePicker, Dialog, TextField } from '@wayste/sour-ui';
import { Controller, FieldValues, FormProvider, get, useForm } from 'react-hook-form';

interface UniversalServicesCancelDialogProps {
  type: 'oneTime' | 'subscription';
  cancelOpen: boolean;
  setCancelOpen: (open: boolean) => void;
  onCancel: (
    cancelationDate: Date | null,
    customerCancelationFee: number,
    vendorCancelationFee: number,
    cancelationReason: string,
  ) => void;
  variant?: 'delete' | 'filled-header' | 'underlined-header' | undefined;
}
const UniversalServicesCancelDialog = ({
  variant = undefined,
  type,
  cancelOpen,
  onCancel,
  setCancelOpen,
}: UniversalServicesCancelDialogProps) => {
  const methods = useForm();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const submitForm = (data: FieldValues) => {
    onCancel(
      data.cancelationDate || null,
      data.customerCancelationFee,
      data.vendorCancelationFee,
      data.cancelationReason,
    );
  };

  const getButtonText = (type: string) => {
    switch (type) {
      case 'subscription':
        return 'End Subscription';
      case 'oneTime':
        return 'Cancel Service Order';
    }
  };

  const getConfirmText = (type: string) => {
    switch (type) {
      case 'subscription':
        return 'Subscription';
      case 'oneTime':
        return 'Service Order';
    }
  };

  return (
    <Dialog
      variant={variant}
      open={cancelOpen}
      onClose={() => setCancelOpen(false)}
      styledTitle={type === 'subscription' ? 'Cancel Subscription' : 'Cancel Service Order'}
      className="min-w-[50%] min-h-[50%]"
    >
      <FormProvider {...methods}>
        <input type="hidden" {...register(`active`, { value: true })} />
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="space-y-6 w-3/4">
            <div>{`Are you sure you want to cancel this ${getConfirmText(type)}?`}</div>
            {type !== 'oneTime' ? (
              <Controller
                control={control}
                name={'cancelationDate'}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onChange={(date) => field.onChange(date)}
                    minDate={new Date()}
                    required
                    label="Cancel on Date"
                    closeOnSelect
                    clearable
                  />
                )}
              />
            ) : (
              <></>
            )}
            <div className="flex space-x-2">
              <Controller
                key={'customerCancelationFee'}
                control={control}
                name={`customerCancelationFee`}
                defaultValue={''}
                rules={{
                  required: {
                    value: true,
                    message: 'Vendor name is required',
                  },
                }}
                render={({ field }) => (
                  <CurrencyTextField
                    type={'string'}
                    currency="USD"
                    value={field.value}
                    onChange={field.onChange}
                    label="Customer Cancelation Fee"
                    error={get(errors, 'customerCancelationFee')}
                    required
                    inputProps={{
                      ...field,
                    }}
                  />
                )}
              />
              <Controller
                key={'vendorCancelationFee'}
                control={control}
                name={'vendorCancelationFee'}
                defaultValue={''}
                rules={{
                  required: {
                    value: true,
                    message: 'Vendor cancelation fee is required',
                  },
                }}
                render={({ field }) => (
                  <CurrencyTextField
                    type={'string'}
                    currency="USD"
                    value={field.value}
                    onChange={field.onChange}
                    label="Vendor Cancelation Fee"
                    error={get(errors, 'vendorCancelationFee')}
                    required
                    inputProps={{
                      ...field,
                    }}
                  />
                )}
              />
            </div>
            <Controller
              key={'cancelationReason'}
              control={control}
              name={`cancelationReason`}
              defaultValue={''}
              rules={{
                required: {
                  value: true,
                  message: 'Cancelation Reason is required',
                },
              }}
              render={({ field }) => (
                <TextField
                  type={'string'}
                  label="Cancelation Reason"
                  error={get(errors, `cancelationReason`)}
                  required
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />
          </div>
          <div className="mt-10 pt-10">
            <button className="btn-error float-right" type="submit">
              {getButtonText(type)}
            </button>
            <button
              className="mr-5  btn-dark-grey-outlined float-right"
              onClick={() => setCancelOpen(false)}
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default UniversalServicesCancelDialog;
