import React, { useEffect, useState } from 'react';
import { useIsAppAdmin, useWaysteClient } from '@alliance-disposal/client';
import { Customer, Invoice, Order } from '@alliance-disposal/transport-types';
import { ArrowsUpDownIcon, PencilIcon, PhotoIcon, WrenchIcon } from '@heroicons/react/24/solid';
import { OrderStatus, getOrderCurrentSwitch } from '../../utils';
import AuditLogTable from '../AuditLogTable';
import { ChangeRequestResponse } from '../ChangeRequestDialog';
import Dialog from '../Dialog';
import { MergeCustomerDialog } from '../MergeCustomerDialog/MergeCustomerDialog';
import OrderDetailsLists from '../OrderDetailsLists';
import OrderImageHandler from '../OrderImageHandler';
import OrderUpdate from '../OrderUpdate';
import DetailsCardWrapper from '../ui/DetailsCardWrapper';
import CreateManualSwitchModal from './components/CreateManualSwitchModal';
import ResendEmailModal from './components/ResendEmailModal';

interface Props {
  order: Order.AllianceOrderTransport;
  customer: Customer.AllianceCustomerTransport;
  payables: Invoice.PayableTransport[];
  receivables: Invoice.ReceivableTransport[];
  onUpdate: () => void;
  onSplitSwitch: () => void;
}

const OrderDetailsCard = ({ order, onUpdate, customer, payables, receivables, onSplitSwitch }: Props) => {
  const [showOrderImages, setShowOrderImages] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showOrderChanges, setShowOrderChanges] = useState(false);
  const [changeRequestCount, setChangeRequestCount] = useState(0);
  const [changeRequestDialogOpen, setChangeRequestDialogOpen] = useState(false);
  const [manualSwitchModalOpen, setManualSwitchModalOpen] = useState(false);
  const [resendEmailModalOpen, setResendEmailModalOpen] = useState(false);
  const [showMergeCustomerDialog, setShowMergeCustomerDialog] = useState(false);

  const client = useWaysteClient();
  const { isAdmin } = useIsAppAdmin();

  useEffect(() => {
    const sub = client
      .adminPortal()
      .order.changes.subscribe({
        status: ['PENDING'],
        orderID: order.id,
      })
      .subscribe((changes) => {
        setChangeRequestCount(changes.total);
        console.log('changes', changes);
      });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <DetailsCardWrapper
      heading="Order Details"
      buttons={[
        {
          label: (
            <>
              <PencilIcon className="h-4 w-4 mr-1" /> Edit Order
            </>
          ),
          onClick: () => setShowEdit(true),
        },
        {
          label: (
            <>
              <PhotoIcon className="h-5 w-5 mr-1" /> Order Images
            </>
          ),
          onClick: () => setShowOrderImages(true),
          badgeAmount: order.images?.length,
        },
        {
          label: 'View History',
          onClick: () => setShowOrderChanges(true),
        },
        {
          label: 'Change Requests',
          badgeAmount: changeRequestCount,
          disabled: changeRequestCount === 0,
          onClick: () => setChangeRequestDialogOpen(true),
        },
        {
          label: (
            <>
              <ArrowsUpDownIcon className="h-5 w-5 mr-1" /> Split Switch
            </>
          ),
          onClick: () => onSplitSwitch(),
          hide: !getOrderCurrentSwitch(order),
        },
        {
          label: 'Manual Switch',
          // if theres two+ switches, this order already maxed out switches
          // if its delivered or below we check if it has prev switch, if do, no manual cuz prev pickup will be overridden.
          // if its ready pickup or above, we check if fromOrder is itself if not it can be chained.
          // @ts-expect-error typescript does not like this
          hide:
            order?.switches?.length >= 2 ||
            order.status === OrderStatus.NEEDS_REVIEW ||
            (order.status === OrderStatus.UNASSIGNED ||
            order.status === OrderStatus.ASSIGNED ||
            order.status === OrderStatus.DELIVERED
              ? order.switches?.find((x) => x.toOrder === order.id)
              : // @ts-expect-error typescript does not like this
              order.switches?.find((x) => x.fromOrder === order.id) || order?.status === OrderStatus.DELIVERED
              ? // @ts-expect-error typescript does not like this
                order?.switches?.toOrder === order.id
              : // @ts-expect-error typescript does not like this
                order?.switches?.fromOrder === order.id),
          onClick: () => setManualSwitchModalOpen(true),
        },
        {
          label: 'Resend Emails',
          // if theres two+ switches, this order already maxed out switches
          // if its delivered or below we check if it has prev switch, if do, no manual cuz prev pickup will be overridden.
          // if its ready pickup or above, we check if fromOrder is itself if not it can be chained.
          hide:
            !order.haulerID ||
            (order.status !== OrderStatus.ASSIGNED && order.status !== OrderStatus.READY_FULL_PICKUP),
          onClick: () => setResendEmailModalOpen(true),
        },
        {
          label: (
            <>
              <WrenchIcon className="h-4 w-4 mr-1" />
            </>
          ),
          onClick: () => setShowMergeCustomerDialog(true),
          hide: !isAdmin,
        },
      ]}
    >
      <MergeCustomerDialog
        open={showMergeCustomerDialog}
        fromCustomer={customer}
        moveOrder={order}
        onClose={() => {
          setShowMergeCustomerDialog(false);
        }}
      ></MergeCustomerDialog>
      <ResendEmailModal
        open={resendEmailModalOpen}
        selectedOrder={order}
        handleClose={() => setResendEmailModalOpen(false)}
      />
      <CreateManualSwitchModal
        open={manualSwitchModalOpen}
        selectedOrder={order}
        handleClose={() => setManualSwitchModalOpen(false)}
      />
      <ChangeRequestResponse
        open={changeRequestDialogOpen}
        onClose={() => setChangeRequestDialogOpen(false)}
        orderID={order.id}
        order={order}
        customer={customer}
      />
      <OrderDetailsLists order={order} payables={payables} receivables={receivables} />
      {showOrderImages ? (
        <OrderImageHandler order={order} open={showOrderImages} onCancel={() => setShowOrderImages(false)} />
      ) : null}

      <OrderUpdate
        order={order}
        customer={customer}
        open={showEdit}
        onBackButtonClick={() => {
          setShowEdit(false);
          onUpdate();
        }}
      />
      <Dialog
        styledTitle="Order Change Log"
        open={showOrderChanges}
        className="max-w-screen-2xl"
        onClose={() => setShowOrderChanges(false)}
        showX={true}
      >
        {showOrderChanges ? <AuditLogTable entityID={order.id} entityType="AllianceOrder" /> : null}
      </Dialog>
    </DetailsCardWrapper>
  );
};

OrderDetailsCard.defaultProps = {
  onUpdate: () => undefined,
};

export default OrderDetailsCard;
