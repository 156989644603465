import { useEffect, useState } from 'react';
import { UniversalService } from '@alliance-disposal/transport-types';
import { TanDataGrid } from '@wayste/sour-ui';
import { formatServiceAddress, getGroupingServiceDateRange } from '@wayste/utils';
import { ColumnDef, Row, createColumnHelper } from '@tanstack/react-table';
import UniversalServicesStatusPopper from '../UniversalServices/UniversalServicesStatusPopper';

type Order = {
  serviceGrouping: UniversalService.ServiceGrouping;
  status?: string;
  serviceRange?: string;
  subscription?: string;
  serviceSKU?: string;
  address?: string;
  orderNumber?: string;
  customer?: string;
  vendor?: string;
  filler?: undefined;
};

const getRowFromServiceGrouping = (grouping: UniversalService.ServiceGrouping) => {
  const order: Order = {
    serviceGrouping: grouping,
    status: grouping.serviceOrders.every((serviceOrder) => serviceOrder.status)
      ? grouping.serviceOrders[0].status
      : 'MIXED',
    serviceRange: getGroupingServiceDateRange(grouping).string,
    subscription: grouping.type !== 'single' ? 'Subscription' : 'One Time',
    serviceSKU: [...new Set(grouping.serviceOrders.map((serviceOrder) => serviceOrder.serviceType.family.name))].join(
      ', ',
    ),
    address: grouping.serviceOrders.every((serviceOrder) => serviceOrder.serviceLocation)
      ? formatServiceAddress(grouping.serviceOrders[0].serviceLocation?.address)
      : 'Multiple',
    orderNumber: grouping.orderNumber,
    customer: grouping.customerCompanyName || grouping.customerName,
    vendor: grouping.serviceOrders.every((serviceOrder) => !serviceOrder.vendorName)
      ? ''
      : grouping.serviceOrders.every((serviceOrder) => serviceOrder.vendorName)
      ? grouping.serviceOrders[0].vendorName
      : 'Multiple',
  };
  return order;
};

interface UniversalServicesListTableProps {
  page: number;
  pages: number;
  size: number;
  total: number;
  serviceGrouping: UniversalService.ServiceGrouping[];
  onRowClicked: (order: UniversalService.ServiceGrouping) => void;
  loading?: boolean;
  setPage: (page: number) => void;
  setPageSize: (size: number) => void;
}

const UniversalServicesListTable = ({
  serviceGrouping,
  onRowClicked,
  setPage,
  setPageSize,
  loading,
  page,
  pages,
  size,
  total,
}: UniversalServicesListTableProps) => {
  // const history = useHistory();
  const [data, setData] = useState<Order[]>(serviceGrouping.map((group) => getRowFromServiceGrouping(group)) ?? []);

  useEffect(() => {
    setData(serviceGrouping.map((serviceOrder) => getRowFromServiceGrouping(serviceOrder)));
  }, [serviceGrouping]);

  const onRowClick = (order: Row<Order>) => {
    onRowClicked(order.original.serviceGrouping);
  };

  const columnHelper = createColumnHelper<Order>();
  const columns: ColumnDef<Order, any>[] = [
    columnHelper.accessor('orderNumber', {
      header: 'Order #',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (info) => (
        <UniversalServicesStatusPopper
          display="label"
          serviceOrder={{ status: info.getValue() } as UniversalService.ServiceOrder}
        />
      ),
    }),
    columnHelper.accessor('serviceRange', {
      header: 'Service Range',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('subscription', {
      header: 'Subscription',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('serviceSKU', {
      header: 'Service',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('address', {
      header: 'Address',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('customer', {
      header: 'Customer',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('vendor', {
      header: 'Vendor',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('filler', {
      header: '',
    }),
  ];
  return (
    <div className="shadow rounded-md overflow-auto">
      <TanDataGrid
        data={data}
        rowTotal={total}
        pages={pages}
        size={size}
        page={page}
        onPageChange={(page) => {
          setPage(page);
        }}
        onSizeChange={(size) => {
          setPageSize(size);
        }}
        columns={columns}
        onRowClick={onRowClick}
        showPagination
        paginationMode="server"
        loading={loading}
        windowed={true}
      />
    </div>
  );
};

export default UniversalServicesListTable;
