import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Pricing, UniversalService } from '@alliance-disposal/transport-types';
import { TanDataGrid } from '@wayste/sour-ui';
import { MapIcon } from '@heroicons/react/24/outline';
import { PencilIcon } from '@heroicons/react/24/solid';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import Loading from '../Loading';
import MapEditorModal from '../MapEditor/MapEditorModal';
import DetailsCardWrapper from '../ui/DetailsCardWrapper';
import { AddServiceToZoneDialog } from './AddServicesToZoneModal';

type Props = {
  pricing: Pricing.ProductPricingZoneTransport[];
  rollOffPricing: Pricing.PricingTransport[];
  vendor: Hauler.HaulerWithAapTransport;
  onEditClicked: () => void;
  onServiceAreaSubmit: (data: any, zone: any) => void;
  isLoading: boolean;
  onRefreshPricing: () => void;
  handleServicesUpdate: () => void;
};

/**
 * Displays all universal services based on zones and vendor
 * @param {Array} pricing Array of pricing objects
 * @param {Object} vendor Vendor object
 */
const UniversalServicePricingList = ({
  pricing,
  rollOffPricing,
  vendor,
  isLoading,
  onServiceAreaSubmit,
  handleServicesUpdate,
}: Props) => {
  // CLIENT
  const client = useWaysteClient();
  //DIALOG STATES
  const [showZoneDetailsModal, setShowZoneDetailModal] = useState(false);
  const [editServiceArea, setEditServiceArea] = useState(false);
  const [selectedServiceAreas, setSelectedServiceAreas] = useState<any>([]);
  const [selectedZone, setSelectedZone] = useState<string>('');
  const [loading, setIsLoading] = useState<boolean>(false);
  const [productMap, setProductMap] = useState<Map<string, UniversalService.ServiceTypeFamily[]>>(new Map());

  const preprocessZonesAndSKUs = (
    pricing: Pricing.ProductPricingZoneTransport[],
    services: UniversalService.ServiceTypeFamily[],
  ) => {
    const productMap = new Map<string, UniversalService.ServiceTypeFamily | undefined>();

    pricing.forEach((zone: Pricing.ProductPricingZoneTransport) => {
      zone.SKUs.forEach((sku: Pricing.ProductPricingSKUTransport) => {
        productMap.set(sku.skuID, undefined);
      });
    });

    services.forEach((service: UniversalService.ServiceTypeFamily) => {
      service.serviceTypes.forEach((sku: UniversalService.ServiceType) => {
        if (sku.active && productMap.has(sku.id)) {
          productMap.set(sku.id, { ...service, serviceTypes: [sku] });
        }
      });
    });

    const zoneProductMap = pricing.reduce((map, zone: Pricing.ProductPricingZoneTransport) => {
      const servicesForZone = zone.SKUs.reduce((servicesArray, sku: Pricing.ProductPricingSKUTransport) => {
        const service = productMap.get(sku.skuID);
        if (service) {
          servicesArray.push(service);
        }
        return servicesArray;
      }, [] as UniversalService.ServiceTypeFamily[]);

      return map.set(zone.id, servicesForZone);
    }, new Map<string, UniversalService.ServiceTypeFamily[]>());

    return zoneProductMap;
  };
  const fetchData = async () => {
    setIsLoading(true);
    try {
      // FETCH ALL AVAILABLE SERVICES AND SKUS
      const services = await client.universalService().serviceFamily.query({ orderBy: 'name' });
      const zoneProductMap = preprocessZonesAndSKUs(pricing, services.results);
      setProductMap(zoneProductMap);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pricing]);

  ////////////////////////////////////////////
  // TABLE SECTION
  ////////////////////////////////////////////

  const columnHelper = createColumnHelper<UniversalService.ServiceTypeFamily>();

  const columns: ColumnDef<UniversalService.ServiceTypeFamily, any>[] = [
    columnHelper.accessor('name', {
      header: 'Service Type',
      cell: (values) => <div className="w-[150px]">{values.getValue()}</div>,
    }),

    columnHelper.accessor('serviceTypes', {
      header: 'SKUs',
      cell: (values) => (
        <div className="">
          {values
            .getValue()
            //.filter((sku: UniversalService.ServiceLineItemType) => rowData?.skuIDs.includes(sku.id))
            .map((sku: UniversalService.ServiceType) => <div key={sku.name}>{sku.name}</div>)
            .map((divElement: JSX.Element, index: number, array: JSX.Element[]) => {
              const comma = index !== array.length - 1 ? ', ' : '';
              return divElement.props.children + comma;
            })}
        </div>
      ),
    }),
  ];

  ////////////////////////////////////////////
  // RENDER SECTION
  ////////////////////////////////////////////

  if (loading || isLoading) return <Loading />;

  return (
    <>
      {/* Modals */}
      <AddServiceToZoneDialog
        open={showZoneDetailsModal}
        vendorID={vendor.id}
        onClose={() => setShowZoneDetailModal(false)}
        handleServicesUpdate={handleServicesUpdate}
        zoneID={selectedZone}
      />
      <MapEditorModal
        zones={pricing}
        auxillaryZones={rollOffPricing}
        open={editServiceArea}
        serviceAreas={selectedServiceAreas}
        onClose={() => {
          setEditServiceArea(false);
        }}
        onSubmit={(data) => {
          onServiceAreaSubmit(data, selectedZone);
        }}
      />
      {/* Zone Content */}
      {pricing.map((zone) => (
        <DetailsCardWrapper
          heading={zone.zoneName || 'No Zone Name'}
          buttons={[
            {
              label: (
                <>
                  <PencilIcon className="h-4 w-4 mr-1.5" />
                  Edit Zone Details
                </>
              ),
              onClick: () => {
                setSelectedZone(zone.id);
                setShowZoneDetailModal(true);
              },
            },
            {
              label: (
                <>
                  <MapIcon className="h-5 w-5 mr-1.5" />
                  Edit Pricing Zone
                </>
              ),
              onClick: () => {
                setSelectedZone(zone.id);
                setSelectedServiceAreas(zone.serviceAreas);
                setEditServiceArea(true);
              },
            },
          ]}
        >
          {/* Modals */}

          <div className="mb-4 flex flex-row space-x-10">
            <div className="flex items-center mb-2">
              <span className="mr-2 text-gray-500">State:</span>
              {zone.state}
            </div>

            <div className="flex items-center mb-2">
              <span className="mr-2 text-gray-500">Last Updated:</span>
              {format(new Date(zone.metadata.createdAt), 'MM/dd/yy')}
            </div>

            <div className="flex items-center mb-2">
              <span className="mr-2 text-gray-500">Notes:</span>
              {zone.notes}
            </div>
          </div>
          <div style={{ margin: '0 -20px -20px' }}>
            {productMap && (
              <TanDataGrid data={productMap.get(zone.id) || []} columns={columns} type={'two-column'}></TanDataGrid>
            )}
          </div>
        </DetailsCardWrapper>
      ))}
    </>
  );
};

export default UniversalServicePricingList;
