import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Invoice, UniversalService } from '@alliance-disposal/transport-types';
import { useHistory } from 'react-router-dom';
import Dialog from '../../../../components/Dialog';
import Loading from '../../../../components/Loading';
import { useFlash } from '../../../../hooks/useFlash';
import { paymentMethodsEnums, routes } from '../../../../utils';
import BillPayment, { PaymentProp } from './BillPayment';

export interface BillPaymentContainerProps {
  open: boolean;
  onOrderSelect: (order: UniversalService.ServiceOrder) => void;
  onCancel: () => void;
  hauler: Hauler.HaulerWithAapTransport | null;
  onSave: () => void;
}

const BillPaymentContainer = ({ open, onCancel, hauler, onSave }: BillPaymentContainerProps) => {
  const client = useWaysteClient();
  const history = useHistory();
  const { showFlash } = useFlash();
  const [payments, setPayments] = useState<PaymentProp[]>([]);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [totalPayment, setTotalPayment] = useState(0);
  const [billTotals, setBillTotals] = useState({ total: 0, totalDue: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [showReview, setShowReview] = useState(false);

  const prepareUIData = async (payables: Invoice.PayableTransport[]) => {
    let paymentsArray: PaymentProp[] = [];

    try {
      const test = payables.filter((payable) => payable.invoiceDetails.serviceOrderID);
      console.log(test);
      const paymentsPromises = payables
        .filter((payable) => payable.invoiceDetails.serviceOrderID)
        .map(async (bill: Invoice.PayableTransport) => {
          // we just filtered them above, so we know this is true
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const order = await client.universalService().serviceOrder.fetch(bill.invoiceDetails.serviceOrderID!);

          const payment: PaymentProp = {
            orderId: bill.invoiceDetails.serviceOrderID || '',
            orderNumber: order.fullOrderNumber,
            orderObj: order,
            billObj: bill,
            invoiceNumber: bill.invoiceDetails.invoiceNumber?.toString() || '',
            invoiceID: bill.invoiceDetails.id,
            readyForPayment: bill.readyForPayment,
            total: bill.invoiceDetails.totalDollars,
            paid: bill.invoiceDetails.total - bill.invoiceDetails.remainingBalance,
            value: '',
          };

          return payment;
        });

      paymentsArray = await Promise.all(paymentsPromises.filter(Boolean)); // Remove any undefined elements.

      // handleSetPayments(paymentsArray);
    } catch (error) {
      // Handle any errors that occurred during the asynchronous operations.
      showFlash('There was an error fetching payables.', 'warning');
      console.error('Error fetching and processing payables:', error);
    } finally {
      // Stop loading, regardless of whether the promise resolves or rejects.
      handleSetPayments(paymentsArray);
      setIsLoading(false);
    }
  };

  const handleGetOpenPayables = async (haulerID: string) => {
    const response = await client.invoice().adminPortal.payable.query({
      haulerID: haulerID,
      paidInFull: false,
      // readyForPayment: true,
    });

    // SPLIT THIS UP FOR REVIEW PAYABLES ... OR BOTH? IF BILL TOTAL IS 0 IGNORE IT
    prepareUIData(response);
  };

  useEffect(() => {
    if (!hauler) return;
    setIsLoading(true);
    setPaymentMethod(hauler.defaultPaymentMethod || '');
    handleGetOpenPayables(hauler.id);
  }, [hauler]);

  const handleCancel = () => {
    setShowReview(false);
    setPaymentMethod('');
    onCancel();
  };

  const handleOrderNumberClick = (selectedOrder: PaymentProp) => {
    history.push(routes.billing.details(selectedOrder.orderObj.id));
    handleCancel();
  };

  const handleSetPayments = (newPayments: PaymentProp[]) => {
    let total = 0;
    newPayments.forEach((payment) => {
      total = total + +payment.value;
    });
    setTotalPayment(total);
    getTotalBills();
    setPayments(newPayments);
  };

  const getTotalBills = () => {
    let total = 0;
    let totalDue = 0;
    payments.forEach((payment) => {
      total = total + payment.total;
      totalDue = totalDue + (payment.total - (payment.paid || 0));
    });
    setBillTotals({
      total: total,
      totalDue: totalDue,
    });
  };

  const handleUpdatePayment = (value: number | '', index: number) => {
    const newPayments = [...payments];
    newPayments[index] = {
      ...newPayments[index],
      value: String(value),
    };
    handleSetPayments(newPayments);
  };

  const handleUpdateCheckAmount = (value: string) => {
    if (paymentMethod === paymentMethodsEnums.check) {
      let checkTotal = +value;
      const newPayments: PaymentProp[] = [];

      payments.forEach((payment) => {
        const amtDue = payment.total - (payment.paid || 0);
        let newValue = 0;
        if (amtDue > 0) {
          if (Math.sign(checkTotal - amtDue) > 0) {
            newValue = amtDue;
            checkTotal = checkTotal - amtDue;
          } else if (checkTotal !== 0) {
            newValue = checkTotal;
            checkTotal = checkTotal - checkTotal;
          }
        }

        newPayments.push({
          ...payment,
          value: String(newValue),
        });
      });
      handleSetPayments(newPayments);
    }
  };

  const handleSave = async (cleanedPayments: Invoice.PaymentCreateTransport[]) => {
    setIsLoading(true);
    console.log('cleanedPayments', cleanedPayments);
    try {
      await Promise.all(
        cleanedPayments.map((payment) => {
          if (!payment.invoiceID) throw new Error('Invoice ID is required');
          client.invoice().adminPortal.payment.create(payment.invoiceID, payment);
        }),
      );
      showFlash('Payables Successfully Updated', 'success');
      onSave();
    } catch (error) {
      console.error(error);
      showFlash('There was an error creating payments.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const getView = () => {
    if (!hauler || isLoading) return <Loading />;
    if (showReview) {
      return (
        <div>
          <h1 className="text-2xl">
            This view has been removed for now. Please use the Billing Details page to review and update payables.
            <br /> If you feel this view is necessary, please contact an your admin.
          </h1>
        </div>

        /*  <ReviewBills
          payments={payments}
          onBackButtonClick={() => setShowReview(false)}
          onBillUpdate={onBillUpdate}
          onOrderSelect={(order) => {
            onOrderSelect(order);
            handleCancel();
          }}
        /> */
      );
    }
    return (
      <BillPayment
        onUpdatePayment={handleUpdatePayment}
        onCancel={handleCancel}
        billTotals={billTotals}
        onUpdateCheckAmount={handleUpdateCheckAmount}
        paymentMethod={paymentMethod}
        onSetPaymentMethod={setPaymentMethod}
        payments={payments}
        totalPayment={totalPayment}
        onSave={handleSave}
        onOrderSelect={handleOrderNumberClick}
        onShowReview={() => setShowReview(true)}
      />
    );
  };

  if (!open) return null;
  if (open && !hauler) return <Loading />;

  return (
    <Dialog styledTitle={`Manage Payments for ${hauler?.name}`} open={open} fullScreen onClose={handleCancel}>
      {getView()}
    </Dialog>
  );
};

export default BillPaymentContainer;
