import React, { useEffect, useState } from 'react';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';

const requirementLabels = [
  { key: 'char8', label: 'Min 8 characters' },
  { key: 'upper', label: '1 upper case letter' },
  { key: 'special', label: '1 symbol (!@#$%^)' },
  { key: 'lower', label: '1 lower case letter' },
  { key: 'number', label: '1 number' },
];

type Props = {
  password: string;
  setValid: React.Dispatch<React.SetStateAction<boolean>>;
};

const PasswordRequirements = ({ password, setValid }: Props) => {
  const [requirements, setRequirements] = useState<{ [key: string]: boolean }>({
    char8: false,
    upper: false,
    lower: false,
    number: false,
    special: false,
  });

  useEffect(() => {
    const newRequirements = {
      char8: password.length >= 8 ? true : false,
      upper: /.*[A-Z].*/.test(password),
      lower: /.*[a-z].*/.test(password),
      number: /.*\d.*/.test(password),
      special: /.*[-_!@#$%^&*.,"'`:;|~><?].*/.test(password),
    };

    setRequirements(newRequirements);

    if (Object.values(newRequirements).every((item) => item === true)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [password]);

  return (
    <div>
      <div className="font-bold">Password Requirements</div>
      <div className="flex mt-3 flex-row flex-wrap">
        {requirementLabels.map((item) => (
          <div key={item.key} className="w-1/2 mb-2.5 flex flex-row items-center">
            {requirements[item.key] ? (
              <CheckCircleIcon className="w-4 h-4 text-success" />
            ) : (
              <XMarkIcon className="w-4 h-4 mr-5 text-red-500" />
            )}
            <div>{item.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PasswordRequirements;
