import { useEffect } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { CustomerTypeLabels } from '@alliance-disposal/transport-types/dist/customer';
import { formatServiceAddress, moneyFormatter } from '@wayste/utils';
import { FaceFrownIcon, FaceSmileIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { FaceNeutralIcon } from '../../assets/icons/misc';
import { paymentMethods, paymentTerms } from '../../utils';
import { channelTypes, commercialLocationTypes, customerMotivations } from '../../utils/shared-types';
import CardInfoList from '../ui/CardInfoList';

interface CustomerDetailsListsProps {
  customer: Customer.AllianceCustomerTransport;
}
const CustomerDetailsLists = ({ customer }: CustomerDetailsListsProps) => {
  useEffect(() => {
    console.log('customer xx', customer);
  }, [customer]);

  const colOne = [
    { label: 'Account #', value: customer.customerNumber },
    { label: 'Company', value: customer.companyName },
    {
      label: 'Customer Type',
      value: customer.type && CustomerTypeLabels[customer.type as keyof typeof CustomerTypeLabels],
    },
    {
      label: 'Business Type',
      value:
        customer.businessType && commercialLocationTypes[customer.businessType as keyof typeof commercialLocationTypes],
    },
    {
      label: 'Motivation',
      value: customer.motivation && customerMotivations[customer.motivation as keyof typeof customerMotivations],
    },
    { label: 'Account Manager', value: customer.accountManagerName },
    { label: 'Account Executive', value: customer.accountRepName },
    {
      label: 'Experience',
      value:
        customer.overallExperience === 'Positive' ? (
          <FaceSmileIcon className="h-6 w-6 text-success" />
        ) : customer.overallExperience === 'Negative' ? (
          <FaceFrownIcon className="h-6 w-6 text-delete" />
        ) : (
          <FaceNeutralIcon className="h-6 w-6 text-[#F0810F]" />
        ),
    },
    { label: 'Reviewed Us', value: customer.reviewed ? 'Yes' : 'No' },

    { label: 'Customer Notes', value: customer.notes },
  ];

  const colTwo = [
    { label: 'Account Balance', value: 'open customer details' },
    { label: 'Account Credit', value: `${moneyFormatter(customer.credit)}` },
    { label: 'Billing Address', value: formatServiceAddress(customer.billingAddress) },
    { label: 'Tax Exempt', value: customer.taxExempt ? 'Yes' : 'No' },
    {
      label: 'Default Payment Settings',
      value: `${paymentTerms[customer.defaultPaymentSettings?.paymentTerm as keyof typeof paymentTerms]} ${
        paymentMethods[customer.defaultPaymentSettings?.paymentMethod as keyof typeof paymentMethods]
      }`,
    },
    {
      label: 'Allowed Payment Methods',
      value: customer.defaultPaymentSettings?.allowedPaymentMethod
        ?.map((item) => paymentMethods[item as keyof typeof paymentMethods])
        .join(', '),
    },
    {
      label: 'Marketing Channel',
      value: customer.channel
        ? channelTypes[customer.channel as keyof typeof channelTypes]
          ? channelTypes[customer.channel as keyof typeof channelTypes]
          : customer.channel
        : '',
    },
    {
      label: 'Created',
      value: format(new Date(customer.metadata?.createdAt || Date.now()), 'MM/dd/yy hh:mm aaa'),
    },
  ];

  return (
    <div className={`grid grid-cols-1 gap-4 lg:grid-cols-2`}>
      <CardInfoList data={colOne} border />
      <CardInfoList data={colTwo} />
    </div>
  );
};

export default CustomerDetailsLists;
