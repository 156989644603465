import React, { useState } from 'react';
import { Dialog } from '@wayste/sour-ui';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';
import { VendorForm } from '../HaulerForm';

export interface HaulerUpdateProps {
  onBackButtonClick: () => void;
  open: boolean;
  onSubmit: any;
  hauler: any;
  onDeactivate: (id: string) => void;
  onReactivate: (id: string) => void;
  isLoading: boolean;
}

/**
 * Component to Create an item in the haulers table
 * @param {Function} onBackButtonClick Function on modal close
 * @param {Boolean} open Controls if modal is visible
 * @param {Function} onSubmit Function on update
 * @param {Object} hauler Starting hauler data
 * @param {Boolean} isLoading Repalces submit and cancel with a loading indicator
 */
const HaulerUpdate = ({
  open,
  onBackButtonClick,
  onSubmit,
  hauler,
  onDeactivate,
  onReactivate,
  isLoading,
}: HaulerUpdateProps) => {
  const [showDelete, setShowDelete] = useState(false);

  const handleDeactivateClicked = () => {
    setShowDelete(true);
  };

  const handleDeleteClose = () => {
    setShowDelete(false);
  };

  const handleDeactivate = () => {
    onDeactivate(hauler.id);
    handleDeleteClose();
  };

  return (
    <>
      <Dialog open={open} className="max-w-2xl" onClose={onBackButtonClick} styledTitle="Update Vendor">
        <VendorForm
          isLoading={isLoading}
          onSubmit={onSubmit}
          hauler={hauler}
          onCancel={onBackButtonClick}
          onDeactivate={handleDeactivateClicked}
          onReactivate={() => onReactivate(hauler.id)}
        />
      </Dialog>
      <ConfirmDeleteDialog
        open={showDelete}
        onClose={handleDeleteClose}
        onDelete={handleDeactivate}
        title="Deactivate Hauler"
        deleteText="Deactivate"
        maxWidth="sm"
      >
        <br />
        Are you sure you want to deactivate {<b>{hauler.name}</b>}? You will no longer be able to assign Orders to them.
        <br />
        <br />
        The hauler's data will not be deleted and you can reactivate them at any time.
      </ConfirmDeleteDialog>
    </>
  );
};

export default HaulerUpdate;
