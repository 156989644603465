import { useEffect } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { AddressSubForm } from '@wayste/sour-components';
import { Checkbox, CurrencyTextField, Loading } from '@wayste/sour-ui';
import { Select, SelectOption, TextField } from '@wayste/sour-ui';
import { nullableFields, removeTypeName } from '@wayste/utils';
import { FaceFrownIcon, FaceSmileIcon } from '@heroicons/react/24/outline';
import { Controller, FormProvider, get, useForm, useFormContext, useWatch } from 'react-hook-form';
import { FaceNeutralIcon } from '../../assets/icons/misc';
import { useConfirmationDialog } from '../../contexts';
import { channelTypes, customerMotivations, paymentMethods, paymentMethodsEnums, paymentTerms } from '../../utils';
import { accountExecutives, accountManagers } from '../../utils/shared-types';
import { statesAbb } from '../../utils/state-details';
import { ContactSubForm } from '../ContactForm/ContactSubForm';

export interface CustomerFormProps {
  customer?: Customer.AllianceCustomerTransport;
  isLoading: boolean;
  onSubmit: (data: Customer.AllianceCustomerTransport) => void;
  onCancel: () => void;
}

export const CustomerForm2 = ({ customer, isLoading, onSubmit, onCancel }: CustomerFormProps) => {
  const methods = useForm<Customer.AllianceCustomerTransport>({
    defaultValues: customer,
  });
  const { handleSubmit } = methods;
  const submitForm = (data: Customer.AllianceCustomerTransport) => {
    const copy = { ...data };

    if (customer) {
      console.log('customer data here', data);
      const submit = {
        ...copy,
        id: customer.id,
      };

      onSubmit(nullableFields(submit, 'companyName'));
    } else {
      const submit = {
        ...copy,
      };
      onSubmit(nullableFields(submit, 'companyName'));
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitForm)}>
        <CustomerForm2Fields
          customer={customer}
          isLoading={isLoading}
          subForm={false}
          onCancel={onCancel}
          fieldName=""
        />
      </form>
    </FormProvider>
  );
};

interface CustomerForm2FieldsProps {
  customer?: Customer.AllianceCustomerTransport;
  fieldName?: string;
  isLoading: boolean;
  subForm?: boolean;
  onCancel: () => void;
  onUseExistingCustomer?: React.Dispatch<React.SetStateAction<Customer.AllianceCustomerTransport | undefined>>;
}

export const CustomerForm2Fields = ({
  customer,
  fieldName,
  isLoading,
  subForm,
  onCancel,
  onUseExistingCustomer,
}: CustomerForm2FieldsProps) => {
  if (!fieldName) {
    fieldName = '';
  }
  const client = useWaysteClient();
  const { getConfirmation } = useConfirmationDialog();

  const methods = useFormContext();
  const {
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = methods;
  const contactData = useWatch({
    control,
    name: `${fieldName}contacts`,
  });

  const contactsDefault = {
    notes: '',
    contacts: [
      {
        id: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        department: '',
        notes: '',
        locationPreferences: [],
        sendBillingEmails: true,
        sendDispatchEmails: true,
        primaryContact: true,
      },
    ],
  };

  const handleEmailDuplicate = async () => {
    const contacts: Customer.AllianceCustomerContactTransport[] = getValues(fieldName + 'contacts');
    if (!contacts) {
      return;
    }
    const primaryContactIndex = contacts.findIndex((contact) => contact.primaryContact);
    if (primaryContactIndex === -1 || !contacts[primaryContactIndex].email?.toLowerCase().trim()) {
      return;
    }
    const primaryEmail = contacts[primaryContactIndex].email?.toLowerCase().trim();
    const response = await client.customer().adminPortal.query({ email: primaryEmail });
    if (response.length === 0) {
      return;
    }
    if (onUseExistingCustomer) {
      const confirmed = await getConfirmation({
        title: 'Duplicate Customer',
        message:
          'This is a duplicate email. Click CONFIRM if you want to use the already EXISTING customer. Click CANCEL if you want to create a NEW customer with a DIFFERENT email.',
      });
      if (confirmed) {
        onUseExistingCustomer(response[0]);
      } else {
        setValue(`${fieldName}contacts.${primaryContactIndex}.email`, '');
      }
    }
  };

  // CHECK FOR DUPLICATE EMAILS
  useEffect(() => {
    if (subForm) handleEmailDuplicate();
  }, [contactData]);

  // SET DEFAULT VALUES WHEN CREATING A NEW CUSTOMER
  useEffect(() => {
    reset({
      ...contactsDefault,
    });
  }, []);

  // SET DEFAULT VALUES WHEN EDITING A CUSTOMER
  useEffect(() => {
    if (customer) {
      console.log('customer data here set', customer);
      const newData = {
        ...removeTypeName(customer),
      };
      delete newData.metadata;
      delete newData.id;

      reset({
        ...newData,
      });
    } else {
      reset({
        ...contactsDefault,
      });
    }
  }, [customer]);

  return (
    <>
      {!customer && !subForm && (
        <>
          <div className="flex flex-row mt-4">
            <button className="btn-dark-grey-outlined" onClick={onCancel} disabled={isLoading} type="button">
              Back
            </button>
            <div className="ml-4 text-xl w-full">Create New Customer</div>
          </div>
          <br />
        </>
      )}
      <br />
      <div className="flex flex-col space-y-6">
        <div className="pb-2 text-xl border-b border-gray-300">Customer</div>
        <Controller
          control={control}
          name={`${fieldName}companyName`}
          defaultValue={''}
          render={({ field }) => (
            <TextField
              type={'string'}
              label="Company name"
              error={get(errors, `${fieldName}name`)}
              inputProps={{
                ...field,
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={`${fieldName}notes`}
          defaultValue={''}
          render={({ field }) => (
            <TextField
              type={'string'}
              label="Customer notes"
              error={get(errors, `${fieldName}notes`)}
              inputProps={{
                ...field,
              }}
            />
          )}
        />

        {customer && !subForm ? (
          <div className="flex ml-1">
            <div className="flex w-1/2 space-x-2 justify-between">
              <div className="block text-gray-700 whitespace-nowrap">Has Review Us</div>
              <div className="pr-4">
                <Controller
                  name={`${fieldName}reviewed`}
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox inputProps={{ value: 'type', checked: field.value, onChange: field.onChange }} />
                  )}
                />
              </div>
            </div>

            <div className="flex w-1/2 space-x-2 justify-between">
              <div className="block text-gray-700 whitespace-nowrap">Experience</div>
              <Controller
                name={`${fieldName}overallExperience`}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FaceSmileIcon
                    className={`h-6 w-6 cursor-pointer ${
                      field.value === 'Positive' ? 'text-success' : 'text-[#D8D8D8]'
                    }`}
                    onClick={() => field.onChange('Positive')}
                  />
                )}
              />
              <Controller
                name={'overallExperience'}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FaceNeutralIcon
                    className={`h-6 w-6 cursor-pointer ${
                      field.value === 'Neutral' ? 'text-[#F0810F]' : 'text-[#D8D8D8]'
                    }`}
                    onClick={() => field.onChange('Neutral')}
                  />
                )}
              />
              <Controller
                name={'overallExperience'}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FaceFrownIcon
                    className={`h-6 w-6 cursor-pointer ${
                      field.value === 'Negative' ? 'text-delete' : 'text-[#D8D8D8]'
                    }`}
                    onClick={() => field.onChange('Negative')}
                  />
                )}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="pb-2 mb-2 text-l border-b border-gray-300">Contacts</div>
            <ContactSubForm fieldName={fieldName} isCustomer />
          </div>
        )}

        <div className="pb-2 text-l border-b border-gray-300">Billing Details</div>

        <div className="flex space-x-2 justify-between">
          <div className="w-1/2 py-1">
            <Controller
              control={control}
              name={`${fieldName}credit`}
              defaultValue={customer?.credit || 0}
              render={({ field }) => (
                <CurrencyTextField value={field.value} onChange={field.onChange} useCents label="Account Credit" />
              )}
            />
          </div>

          <div className="flex flex-col w-1/2 ml-2">
            <div className="flex space-x-2 justify-between">
              <div className="block text-gray-700 whitespace-nowrap">Tax Exempt</div>
              <div className="pr-4">
                <Controller
                  name={`${fieldName}taxExempt`}
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox inputProps={{ value: 'type', checked: field.value, onChange: field.onChange }} />
                  )}
                />
              </div>
            </div>

            <div className="flex space-x-2 justify-between">
              <div className="block text-gray-700 whitespace-nowrap">Do Not Service</div>
              <div className="pr-4">
                <Controller
                  name={`${fieldName}doNotService`}
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox inputProps={{ value: 'type', checked: field.value, onChange: field.onChange }} />
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <AddressSubForm
          statesList={statesAbb}
          apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
          fieldName={`${fieldName}billingAddress`}
          required
        />

        <div className="flex space-x-2">
          <div className="w-1/2">
            <Controller
              name={`${fieldName}defaultPaymentSettings.paymentTerm`}
              control={control}
              render={({ field }) => (
                <Select
                  error={get(errors, `${fieldName}defaultPaymentSettings.paymentTerm`)}
                  label={'Payment Term'}
                  required
                  onSelect={field.onChange}
                  defaultValue={''}
                  value={field.value}
                >
                  {Object.entries(paymentTerms).map((option: [string, string], index: number) => (
                    <SelectOption key={'paymentTerms' + index} value={option[0]}>
                      {option[1]}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </div>
          <div className="w-1/2">
            <Controller
              name={`${fieldName}defaultPaymentSettings.paymentMethod`}
              control={control}
              render={({ field }) => (
                <Select
                  error={get(errors, `${fieldName}defaultPaymentSettings.paymentMethods`)}
                  label={'Payment Method'}
                  required
                  onSelect={field.onChange}
                  defaultValue={''}
                  value={field.value}
                >
                  {Object.entries(paymentMethods).map((option: [string, string], index: number) => (
                    <SelectOption key={'paymentMethods' + index} value={option[0]}>
                      {option[1]}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </div>
        </div>
        <div className="flex space-x-2">
          <div className="w-1/2">
            <Controller
              name={`${fieldName}defaultPaymentSettings.allowedPaymentMethod`}
              control={control}
              render={({ field }) => (
                <Select
                  error={get(errors, `${fieldName}defaultPaymentSettings.allowedPaymentMethod`)}
                  label={'Allowed Payment Method'}
                  onSelect={field.onChange}
                  defaultValue={''}
                  value={field.value}
                  multiple
                >
                  {Object.entries(paymentMethods).map((option: [string, string], index: number) => (
                    <SelectOption
                      key={'allowedPaymentMethods' + index}
                      value={option[0]}
                      disabled={option[0] === paymentMethodsEnums.creditCard}
                    >
                      {option[1]}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </div>
          <div className="w-1/2">
            <Controller
              name={`${fieldName}invoiceIssueTerm`}
              control={control}
              render={({ field }) => (
                <Select
                  error={get(errors, `${fieldName}invoiceIssueTerm`)}
                  label={'Invoice Issue Term'}
                  onSelect={field.onChange}
                  defaultValue={''}
                  value={field.value}
                >
                  {Object.entries(Customer.InvoiceIssueTermLabels).map((option: [string, string], index: number) => (
                    <SelectOption key={'invoiceIssueTerm' + index} value={option[0]}>
                      {option[1]}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </div>
        </div>
        <div className="pb-2 text-l border-b border-gray-300">Marketing Details</div>
        <div className="flex space-x-2">
          <div className="w-1/2">
            <Controller
              name={`${fieldName}channel`}
              control={control}
              render={({ field }) => (
                <Select
                  error={get(errors, `${fieldName}channel`)}
                  label={'Channel'}
                  required
                  onSelect={field.onChange}
                  defaultValue={''}
                  value={field.value}
                >
                  {Object.entries(channelTypes).map((option: [string, string], index: number) => (
                    <SelectOption key={'channel' + index} value={option[0]}>
                      {option[1]}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </div>
          <div className="w-1/2">
            <Controller
              name={`${fieldName}type`}
              control={control}
              render={({ field }) => (
                <Select
                  error={get(errors, `${fieldName}customerType`)}
                  label={'Customer Type'}
                  required
                  onSelect={field.onChange}
                  defaultValue={''}
                  value={field.value}
                >
                  {Customer.customerTypes.map((option: string, index: number) => (
                    <SelectOption key={'types' + index} value={option}>
                      {Customer.CustomerTypeLabels[option as keyof typeof Customer.CustomerTypeLabels]}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </div>
        </div>
        <div className="w-1/2 pr-1">
          <Controller
            name={`${fieldName}motivation`}
            control={control}
            render={({ field }) => (
              <Select
                error={get(errors, `${fieldName}motivation`)}
                label={'Motivation'}
                onSelect={field.onChange}
                defaultValue={''}
                value={field.value}
              >
                {Object.entries(customerMotivations).map((option: [string, string], index: number) => (
                  <SelectOption key={'motivation' + index} value={option[0]}>
                    {option[1]}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="pb-2 text-l border-b border-gray-300">Sales Details</div>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <Controller
              name={`${fieldName}accountRepID`}
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  error={get(errors, `${fieldName}accountRepID`)}
                  label={'Account Executive'}
                  onSelect={(value) => {
                    field.onChange(value);
                    if (value) {
                      const profile = accountExecutives.find((profile) => profile.id === value);
                      if (profile) {
                        setValue(`${fieldName}accountRepName`, profile.firstName + ' ' + profile.lastName);
                      }
                    }
                  }}
                  defaultValue={''}
                  required
                  value={field.value || ''}
                  renderLabel={(value) => {
                    // if the value is blank, and not dirty, then return null
                    if (!value && !fieldState.isTouched) return null;

                    const profile = accountExecutives.find((profile) => profile.id === value);
                    return profile ? profile.firstName + ' ' + profile.lastName : 'None';
                  }}
                >
                  <SelectOption key={'accountRepID-blank'} value={''}>
                    None
                  </SelectOption>
                  {accountExecutives.map((profile) => (
                    <SelectOption key={'accountRepID' + profile.id} value={profile.id}>
                      {profile.firstName + ' ' + profile.lastName}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </div>
          <div className="">
            <Controller
              name={`${fieldName}accountManagerID`}
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  error={get(errors, `${fieldName}accountManagerID`)}
                  label={'Account Manager'}
                  onSelect={(value) => {
                    field.onChange(value);
                    if (value) {
                      const profile = accountManagers.find((profile) => profile.id === value);
                      if (profile) {
                        setValue(`${fieldName}accountManagerName`, profile.firstName + ' ' + profile.lastName);
                      }
                    }
                  }}
                  defaultValue={''}
                  value={field.value}
                  renderLabel={(value) => {
                    if (!value && !fieldState.isTouched) return null;
                    const profile = accountManagers.find((profile) => profile.id === value);
                    return profile ? profile.firstName + ' ' + profile.lastName : 'None';
                  }}
                >
                  <SelectOption key={'accountManagerID-blank'} value={''}>
                    None
                  </SelectOption>
                  {accountManagers.map((profile) => (
                    <SelectOption key={'accountManagerID' + profile.id} value={profile.id}>
                      {profile.firstName + ' ' + profile.lastName}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </div>
        </div>
        {!subForm && (
          <div className="flex justify-end gap-4">
            <button className="btn-dark-grey-outlined px-10" onClick={onCancel} disabled={isLoading} type="button">
              Cancel
            </button>
            <button className="btn-primary px-10 -py-1" type="submit" disabled={isLoading}>
              {customer ? 'Update' : 'Create'}
              {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
            </button>
          </div>
        )}
      </div>
      <br />
    </>
  );
};

export default CustomerForm2;
