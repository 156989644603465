import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { prepareDateStringsForForm } from '@wayste/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { UIContext } from '../../contexts';
import OneTimeServiceOrderSubForm from './OneTimeServiceOrderSubForm';
import { UniversalServiceFormData } from './UniversalServiceCreate';

const OneTimeServiceOrderUpdate = () => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const history = useHistory();
  const params: { serviceGroupingId: string; serviceOrderId: string } = useParams();
  const methods = useForm<UniversalServiceFormData>();
  const { handleSubmit, reset } = methods;
  const [serviceGrouping, setServiceGrouping] = useState<UniversalService.ServiceGrouping | undefined>(undefined);
  const [serviceOrder, setServiceOrder] = useState<UniversalService.ServiceOrder | undefined>(undefined);

  const fetchServiceGrouping = async () => {
    try {
      const serviceGrouping = await client.universalService().serviceGrouping.fetch(params.serviceGroupingId);
      console.log('data', serviceGrouping);
      setServiceGrouping(serviceGrouping);
      const fetchedServiceOrder = serviceGrouping.serviceOrders.find((order) => order.id === params.serviceOrderId);

      if (fetchedServiceOrder) {
        // convert any keys that include date to date
        prepareDateStringsForForm(fetchedServiceOrder, 'startDate', 'endDate', 'deliveryDate', 'endDate');
      }

      setServiceOrder(fetchedServiceOrder);
    } catch (error) {
      console.warn('fetchServiceOrder apolloError for ID: ', error);
      return { status: 'error', data: null };
    }
  };

  const onSubmit = async (data: UniversalServiceFormData) => {
    console.log('data', data);
    try {
      // UPDATE SERVICE GROUPING
      const serviceGroupingUpdatePayload: UniversalService.ServiceGroupingUpdate = {
        ...data.serviceGrouping,
      };
      await client.universalService().serviceGrouping.update(params.serviceGroupingId, serviceGroupingUpdatePayload);

      // UPDATE SERVICE ORDER
      const newOrder = (data.serviceGrouping.serviceOrders as unknown as UniversalService.ServiceOrder[]).find(
        (order) => order?.id === params.serviceOrderId,
      );
      const serviceOrderUpdatePayload: UniversalService.ServiceOrderUpdate = {
        ...newOrder,
        serviceDays: serviceOrder?.serviceDays.map((day) => Number(day)),
        vendorID: serviceOrder?.vendorID || undefined,
        serviceTypeID: serviceOrder?.serviceType.id !== newOrder?.serviceType.id ? newOrder?.serviceType.id : undefined,
      };

      console.log('serviceOrderUpdatePayload', serviceOrderUpdatePayload);

      await client
        .universalService()
        .serviceGrouping.serviceOrder.update(
          params.serviceGroupingId,
          params.serviceOrderId,
          serviceOrderUpdatePayload,
        );

      showFlash('Service Order Successfully Updated', 'success');
      history.goBack();
    } catch (error) {
      console.log('Service Order Update error ', error);
      showFlash('Error Updating Service Order', 'warning');
    }
  };

  useEffect(() => {
    fetchServiceGrouping();
  }, []);

  useEffect(() => {
    if (!serviceGrouping) return;
    reset({
      serviceGrouping: serviceGrouping,
    });
  }, [serviceGrouping]);

  return (
    <div className="p-16 flex-1 flex flex-col max-w-[960px]">
      <FormProvider {...methods}>
        <form>
          <div className="pb-2 mb-6 text-2xl">Update Service Order</div>
          <div className="space-y-6">
            <OneTimeServiceOrderSubForm
              methods={methods}
              serviceFamily={serviceOrder?.serviceType.family}
              mode="update"
            />
          </div>
          <div className="mt-2 float-right">
            <Button className="btn-dark-grey-outlined mr-4" onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button className="btn-primary" onClick={handleSubmit(onSubmit)}>
              Update
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
export default OneTimeServiceOrderUpdate;
