import { useState } from 'react';
import { V1, V1 as sourgumPricingFunctions } from '@alliance-disposal/pricing';
import { Toggle } from '@wayste/sour-ui';
import { centsToDollars, dollarsToCents, moneyFormatter } from '@wayste/utils';
import { ccRate } from '../../utils/pricing-utils';
import { priceTypesEnums } from '../../utils/shared-types';

interface Props {
  pricing: any;
  chargeCC: boolean;
  taxRate: number;
  roundTotal: boolean;
  onUpdatePricing: (key: string, value: any) => void;
  onAddDetails: (size: number, tonLimit: number, total: number, overage: number) => void;
  forLead: boolean;
  material: string;
  discount: number;
}

const PricingTable = ({
  pricing,
  chargeCC,
  taxRate,
  roundTotal,
  onUpdatePricing,
  onAddDetails,
  forLead,
  material,
  discount,
}: Props) => {
  const [overageOverride, setOverageOverride] = useState('');
  const [lowerTonLimitOverageAdjustment, setLowerTonLimitOverageAdjustment] = useState(false);

  const getTotal = (size: any) => {
    const normalizedSize = pricing.sizes.find((item: any) => item.size === size.size);
    const quotedPrice = sourgumPricingFunctions.quotedPriceBuild(
      normalizedSize?.multiplier,
      +normalizedSize?.dump,
      +normalizedSize?.haul,
      pricing.tax ? +taxRate : 0,
      chargeCC ? +ccRate : 0,
      roundTotal,
      normalizedSize?.overage,
    );
    return {
      total: quotedPrice.total - +discount,
      tax: quotedPrice.tax,
      cc: quotedPrice.cc,
      overage: quotedPrice.overage,
    };
  };

  const getOverage = (size: any) => {
    if (pricing.type !== priceTypesEnums.ton) return 0;
    const finalTotal =
      (Number(overageOverride) * 100 || +getTotal(size).overage || 0) * (lowerTonLimitOverageAdjustment ? 1.2 : 1);
    return V1.round(finalTotal);
  };

  const handleUpdatePricing = (event: any, size: any) => {
    let sizesCopy = [...pricing.sizes];
    sizesCopy = sizesCopy.map((item) => {
      if (item.size === size.size) {
        return {
          ...item,
          tonLimit: event.target.value,
          multiplier: event.target.value,
        };
      }
      return { ...item };
    });
    onUpdatePricing('sizes', sizesCopy);
  };

  const handleOnAddDetails = (size: any) => {
    const pricingSize = pricing.sizes.find((item: any) => item.size === size.size);
    onAddDetails(size.size, +pricingSize.tonLimit, getTotal(size).total, getOverage(size));
  };

  const rowData = [
    {
      label: 'Size',
      func: (size: any) => size.size,
      show: true,
    },
    {
      label: 'Ton Limit',
      func: (size: any) => (
        <input
          style={{
            border: 'solid 1px #979797',
            borderRadius: 4,
            padding: '3px 10px',
            width: 60,
            fontSize: 14,
            backgroundColor: 'transparent',
          }}
          type="number"
          step="0.01"
          value={size.tonLimit}
          onChange={(event) => handleUpdatePricing(event, size)}
        />
      ),
      show: pricing.type === priceTypesEnums.ton,
    },
    {
      label: 'Overage',
      func: (size: any) => moneyFormatter(getOverage(size)),
      show: pricing.type === priceTypesEnums.ton,
    },
    {
      label: 'Sales Tax',
      func: (size: any) => moneyFormatter(getTotal(size).tax),
      show: true,
    },
    {
      label: 'CC',
      func: (size: any) => moneyFormatter(getTotal(size).cc),
      show: true,
    },
    {
      label: 'Total',
      func: (size: any) => moneyFormatter(getTotal(size).total),
      show: true,
    },
    {
      label: '',
      func: (size: any) => (
        <button
          className="btn-secondary-text-only px-2 py-1"
          onClick={() => handleOnAddDetails(size)}
          disabled={forLead && !material}
          type="button"
        >
          {forLead ? (getTotal(size).total === 0 ? 'Add Request' : 'Add Quote') : 'Add Order'}
        </button>
      ),
      show: Boolean(pricing.material) || forLead,
    },
  ];

  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex-1 w-full overflow-x-auto">
        <table className="w-full border-collapse">
          <tbody>
            {rowData.map((row) => {
              if (row.show) {
                return (
                  <tr key={row.label}>
                    <td className="p-4 border-b text-sm">{row.label}</td>
                    {pricing.sizes.map((size: any) => (
                      <td className="p-4 border-b text-sm" key={size.size}>
                        {row.func(size)}
                      </td>
                    ))}
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </table>
      </div>
      {pricing.type === priceTypesEnums.ton ? (
        <div className="whitespace-nowrap border-none md:border-solid md:border-l md:border-l-slate-200 md:pl-5 my-8 md:my-0 md:ml-5">
          <div
            style={{
              color: 'rgba(0,0,0,0.67)',
              fontWeight: 500,
              fontSize: 16,
              marginBottom: 18,
            }}
          >
            Overage
          </div>
          <div style={{ marginBottom: 22 }}>
            Override
            <input
              style={{
                border: 'solid 1px #979797',
                borderRadius: 4,
                padding: '3px 10px',
                width: 60,
                fontSize: 14,
                backgroundColor: 'transparent',
                marginLeft: 40,
              }}
              type="number"
              step="0.01"
              value={overageOverride}
              onChange={(event) => setOverageOverride(event.target.value)}
            />
          </div>
          <div style={{ marginBottom: 22 }}>
            <Toggle
              label="Lower Ton Limit Adjustment"
              value={lowerTonLimitOverageAdjustment}
              onChange={setLowerTonLimitOverageAdjustment}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PricingTable;
