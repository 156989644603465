import React, { useEffect, useState } from 'react';
import { Invoice, UniversalService } from '@alliance-disposal/transport-types';
import { SourFilters, SourSearch, SourSearchWrapper } from '@wayste/sour-search';
import { TextField } from '@wayste/sour-ui';
import { useForm } from 'react-hook-form';
import Dialog from '../../../../components/Dialog';

interface FormProps {
  invoiceNumber: string;
  haulerID: string;
  haulerName: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  bill?: Invoice.PayableTransport;
  order?: UniversalService.ServiceOrder;
  onSubmit: (values: FormProps) => void;
}

const BillDetails = ({ open, onClose, bill, onSubmit, order }: Props) => {
  const [searchForHaulers, setSearchForHaulers] = useState(false);

  const onFormSubmit = (values: FormProps) => {
    const data = { ...values };
    onSubmit(data);
  };

  const onSearchForHauler = async () => {
    setSearchForHaulers(true);
  };

  const {
    setValue,
    watch,
    formState: { isValid, isDirty },
    register,
    handleSubmit,
    reset,
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      invoiceNumber: '',
      haulerID: '',
      haulerName: '',
    },
  });

  const watchHaulerName = watch('haulerName');

  useEffect(() => {
    if (order || bill) {
      const thisHaulerID = bill?.haulerID || order?.vendorID || '';
      reset({
        invoiceNumber: bill?.invoiceDetails.invoiceNumber || '',
        haulerID: thisHaulerID,
        haulerName: bill?.vendorName ?? '',
      });
    } else {
      reset({
        invoiceNumber: '',
        haulerID: '',
        haulerName: '',
      });
    }
  }, [bill, order, reset]);

  const handleHaulerClick = (haulerID: string) => {
    const haulerName = bill?.vendorName || '';
    setValue('haulerID', haulerID);
    setValue('haulerName', haulerName);
    setSearchForHaulers(false);
  };

  // DEFINE ON NAVIGATE LOGIC
  const onNavigate = (_: any, recordID: string) => {
    handleHaulerClick(recordID);
  };

  if (!open) return null;

  return (
    <Dialog
      className="w-[100%] overflow-visible"
      styledTitle={`${bill ? 'Edit' : 'Add'} Hauler Bill`}
      onClose={onClose}
      open={open}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onFormSubmit)}>
        <div>
          <div>
            <span style={{ color: 'rgba(0, 0, 0, 0.68)' }}>For Hauler:</span>
            <b>{watchHaulerName}</b>
          </div>
        </div>
        <div>
          {!searchForHaulers ? (
            <button className="btn-primary-text-only " color="secondary" onClick={onSearchForHauler}>
              Change Hauler
            </button>
          ) : (
            <div>
              <SourSearchWrapper
                onNavigate={onNavigate}
                highlight={true}
                defaultFilters={{
                  query: {
                    type: 'sourgum-vendor',
                  },
                }}
                options={{
                  application: 'aap',
                  apiKey: import.meta.env.VITE_ELASTIC_KEY,
                  environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                }}
              >
                <div className="flex flex-row justify-between space-x-4 w-full">
                  <SourSearch
                    options={{
                      searchPopoverFixed: false,
                      showTips: false,
                      showMessages: false,
                      resultSize: 'w-[700px]',
                      searchPositionFixed: true,
                      placeholder: 'Search vendor',
                    }}
                  />
                  <div className="hidden">
                    <SourFilters />
                  </div>
                </div>
              </SourSearchWrapper>
            </div>
          )}
        </div>
        <div>
          <TextField
            label="Invoice number"
            required={true}
            inputProps={{
              ...register('invoiceNumber', { required: 'Invoice number is required' }),
            }}
          />
        </div>

        <div className="flex justify-end gap-4 pt-4 border-t">
          <button className="btn-dark-grey-outlined" onClick={onClose} type="button">
            Cancel
          </button>
          <button className="btn-primary" type="submit" disabled={!isValid || !isDirty}>
            Save
          </button>
        </div>
      </form>
    </Dialog>
  );
};

export default BillDetails;
