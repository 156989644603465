import React from 'react';
import { Loading, TextField } from '@wayste/sour-ui';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import Dialog from '../../Dialog';
import { EditModes } from '../MapEditor';

export type OSM_SearchResult = {
  place_id: number;
  display_name: string;
  lat: number;
  lon: number;
  geojson: GeoJSON.Polygon;
};

type SearchForTownModalProps = {
  open: boolean;
  onSearch: (searchString: string) => Promise<OSM_SearchResult[]>;
  onCancel: () => void;
  onSubmit: (geoJSON: GeoJSON.Polygon, mode: EditModes) => void;
};

const SearchOSMModal = ({ open, onCancel, onSearch, onSubmit }: SearchForTownModalProps) => {
  const [townName, setTownName] = React.useState('');
  const [results, setResults] = React.useState<OSM_SearchResult[] | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTownName(event.target.value);
  };

  const handleSearch = async () => {
    setLoading(true);
    // Call the search function provided by the parent component
    const results = await onSearch(townName);
    setResults(results.filter((results) => Boolean(results.geojson) && results.geojson.type === 'Polygon'));
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onCancel} styledTitle="Add/Cut City or County" className="!max-w-xl" showX>
      <div className="flex flex-col">
        <div className="flex gap-2 items-center">
          <TextField
            label="Town Name"
            inputProps={{
              value: townName,
              onChange: (e) => handleChange(e),
            }}
          />
          <button
            onClick={handleSearch}
            className="btn-icon"
            disabled={loading}
            data-testid="search-button"
            type="button"
          >
            <MagnifyingGlassIcon className="text-wayste-blue-400 h-6 w-6" />
          </button>
        </div>
        {/* show the results with a button two the right that says add which calls confirm */}
        {loading && <Loading />}
        {results && results.length === 0 ? (
          <p className="mt-2">No Results</p>
        ) : (
          <div className="mt-2">
            {results?.map((result) => (
              <div key={result.place_id} className="flex flex-col gap-1">
                <div className="flex py-1 items-center gap-2">
                  <p>{result.display_name}</p>
                  <button
                    className="btn-error-text-only"
                    onClick={() => {
                      onSubmit(result.geojson, 'cut');
                      onCancel();
                    }}
                  >
                    Cut
                  </button>
                  <button
                    className="btn-primary-text-only"
                    onClick={() => {
                      onSubmit(result.geojson, 'draw');
                      onCancel();
                    }}
                  >
                    Add
                  </button>
                </div>
                <hr />
              </div>
            ))}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default SearchOSMModal;
