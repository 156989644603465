import { useContext } from 'react';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { formatISODateString, formatServiceAddress, getDateFormat } from '@wayste/utils';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { OrderStatus, getOrderCurrentSwitch, materials } from '../../utils';
import routes from '../../utils/routes';
import InternalOrderNotes from '../InternalOrderNotes';
import OrderStatusChanger from '../OrderStatusChanger';
import CardInfoList from '../ui/CardInfoList';

const dateFormat = 'EEE MM/dd/yy';

const getPreviousSwitch = (order: Order.AllianceOrderTransport) => {
  if (order.switches?.length === 2) {
    return (
      <Link style={{ textDecoration: 'none' }} to={routes.orders.details(order.switches[1].fromOrder)}>
        <button className="btn-dark-grey-outlined">Go to Previous Order</button>
      </Link>
    );
  } else {
    if (order.switches?.length === 1) {
      const currentSwitch = getOrderCurrentSwitch(order);
      const id = currentSwitch?.relationship === 'PARENT' ? currentSwitch?.toOrder : currentSwitch?.fromOrder;
      const from = order.switches[0].fromOrder;
      if (id === from) {
        return <Typography>None</Typography>;
      }
      if (order.switches[0].fromOrder !== order.id)
        return (
          <Link style={{ textDecoration: 'none' }} to={routes.orders.details(order.switches[0].fromOrder)}>
            <button className="btn-dark-grey-outlined">Go to Previous Order</button>
          </Link>
        );
    }
    return <Typography>None</Typography>;
  }
};

interface Props {
  order: Order.AllianceOrderTransport;
  payables?: Invoice.PayableTransport[];
  receivables?: Invoice.ReceivableTransport[];
  break3?: boolean;
}

/**
 * Displays bulk of Order data in lists using CardInfoList
 * @param {*} order Order schema object
 * @param {Boolean} break3 Moves the 3rd column below the first 2
 */
const OrderDetailsLists = ({ order, payables, receivables, break3 }: Props) => {
  const { userIDToNameMap } = useContext(UserContext);
  const history = useHistory();

  const getWeightLimit = (order: Order.AllianceOrderTransport) => {
    if (order.priceType === 'ton') {
      if (order.weightLimit && order.weightLimit.value) {
        return order.weightLimit.value || 0;
      }
    } else {
      return null;
    }
  };

  const getActualWeightDumped = (order: Order.AllianceOrderTransport) => {
    if (order?.actualWeightDumped?.value !== undefined) {
      if (order?.actualWeightDumped?.value >= 0) {
        return order.actualWeightDumped?.value;
      } else {
        return 0;
      }
    } else {
      return null;
    }
  };

  const colOne = [
    { label: 'Service Address', value: formatServiceAddress(order.serviceLocation.address) },
    { label: 'PO Number', value: order.poNumber },
    { label: 'County', value: order.serviceLocation.county },
    { label: 'Material', value: materials[order.material as keyof typeof materials] },
    { label: 'Size', value: order?.expectedSize.size ? order.expectedSize.size + ' YD' : 0 + ' YD' },
    { label: 'Ton Limit', value: getWeightLimit(order) === null ? '' : getWeightLimit(order) + ' Tons' },
    {
      label: 'Tons Dumped',
      value: getActualWeightDumped(order) === null ? '' : getActualWeightDumped(order) + ' Tons',
    },
    {
      label: 'Overage Fee',
      value: order.overageDollars ? `$${order.overageDollars.toFixed(2)}` : '',
    },
    {
      label: 'Current Switch',
      value: getOrderCurrentSwitch(order) ? (
        <Link
          style={{ textDecoration: 'none' }}
          to={routes.orders.details(
            getOrderCurrentSwitch(order).relationship === 'PARENT'
              ? getOrderCurrentSwitch(order).toOrder
              : getOrderCurrentSwitch(order).fromOrder,
          )}
        >
          <button className="btn-dark-grey-outlined mb-0.5">
            Go to {getOrderCurrentSwitch(order).relationship === 'PARENT' ? 'Next' : 'Previous'}
            {' Order'}
          </button>
        </Link>
      ) : (
        'None'
      ),
    },
    {
      label: 'Previous Switch',
      value: getPreviousSwitch(order),
    },
  ];
  const colTwo = [
    {
      label: 'Delivery Date',
      value: format(getDateFormat(order.expectedDeliveryDate), dateFormat),
    },
    { label: 'Rental Period', value: order.adjustedRentalPeriod.value },
    {
      label: 'Expiration',
      value: formatISODateString(order?.expirationDate, dateFormat),
    },
    {
      label: 'Pick Up Date',
      value: order.expectedPickupDate ? format(getDateFormat(order.expectedPickupDate), dateFormat) : '',
    },
    { label: 'Extension Fee', value: order.rentExtensionFeeDollars },
    { label: 'Delivery Notes', value: order.sharedDeliveryNotes },
    { label: 'Pick Up Notes', value: order.sharedPickupNotes },
    {
      label: 'Hauler Only Notes',
      value: `Delivery: ${order.sharedDeliveryNotesPrivate} | Pickup: ${order.sharedPickupNotesPrivate}`,
    },
    { label: 'Internal Notes', value: <InternalOrderNotes order={order} /> },
  ];

  const colThree = [
    { label: 'Needs Attention', value: order.needsAttention ? 'Yes' : '' },
    { label: 'Order #', value: order.orderNumber },
    { label: 'Recurring (on call)', value: order.recurringOnCall ? 'Yes' : '' },
    {
      label: 'Order Status',
      value:
        order.status === OrderStatus.CANCELLED ? (
          <span style={{ color: 'red', textTransform: 'capitalize' }}>{order.status}</span>
        ) : (
          <OrderStatusChanger order={order} />
        ),
    },
    {
      label: 'Hauler',
      value: (
        <span
          onClick={() => {
            if (order.haulerID) {
              // is optional so the check makes sense
              history.push(routes.haulers.details(order.haulerID), {
                modal: true,
              });
            }
          }}
          className="cursor-pointer text-wayste-blue-400"
        >
          {order.vendorName}
        </span>
      ),
    },
    {
      label: 'Issues',
      value: order.internalNotes.filter((note) => note.isIssue).length,
    },
    { label: 'Checkout Type', value: order.checkoutType },
    { label: 'Created By', value: userIDToNameMap[order.metadata.createdByUserID] },
    {
      label: 'Created',
      value: format(new Date(order.metadata.createdAt), 'EEE MM/dd/yy h:mm aaa'),
    },
    { label: 'Last Updated By', value: userIDToNameMap[order.metadata.lastUpdatedByUserID] },
    {
      label: 'Updated',
      value: order.metadata.lastUpdatedAt
        ? format(new Date(order.metadata.lastUpdatedAt), 'EEE MM/dd/yy h:mm aaa')
        : '',
    },
  ];

  return (
    <div className={`grid grid-cols-1 gap-4 ${break3 ? 'lg:grid-cols-2' : 'lg:grid-cols-3'}`}>
      <CardInfoList data={colOne} border />
      <CardInfoList data={colTwo} border={!break3} />
      {break3 ? (
        <div className="lg:col-span-2">
          <hr />
        </div>
      ) : null}
      <CardInfoList data={colThree} />
    </div>
  );
};

export default OrderDetailsLists;
