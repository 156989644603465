import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Hauler, UniversalService } from '@alliance-disposal/transport-types';
import { Loading } from '@wayste/sour-ui';
import { generatePayablesAndReceivable } from '@wayste/utils';
import { formatDateToYYYYMMDD } from '@wayste/utils/dist/formatter/date-formatter';
import { ChevronLeftIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { NavLink, useParams } from 'react-router-dom';
import { UIContext } from '../../contexts';
import { routes } from '../../utils';
import { repList } from '../../utils/shared-types';
import CustomerDetailsCard from '../CustomerDetailsCard/CustomerDetailsCard';
import PayablesTableCard from '../PayablesTableCard/PayablesTableCard';
import UniversalServicesCancelDialog from '../UniversalServicesCancelDialog/UniversalServicesCancelDialog';
import DetailsCardWrapper from '../ui/DetailsCardWrapper';
import { AddServiceEventDialog } from './AddEventDialog';
import OneTimeBillingDetailsCard from './OneTime/OneTimeBillingDetailsCard';
import OneTimeDetailsCard from './OneTime/OneTimeDetailsCard';
import OneTimeServiceDetailsCard from './OneTime/OneTimeServiceDetailsCard';
import SubscriptionBillingDetailsCard from './Subscription/SubscriptionBillingDetailsCard';
import SubscriptionDetailsCard from './Subscription/SubscriptionDetailsCard';
import SubscriptionServiceDetailsCard from './Subscription/SubscriptionServiceDetailsCard';

const ServiceOrderDetails = () => {
  const { showFlash, godModeActive } = useContext(UIContext);
  const { serviceGroupingId, serviceOrderId } = useParams<{ serviceGroupingId: string; serviceOrderId: string }>();
  const [cancelOpen, setCancelOpen] = useState<boolean>(false);
  const [showAddServiceEventDialog, setAddServiceEventDialog] = useState<boolean>(false);
  const [customer, setCustomer] = useState<Customer.AllianceCustomerTransport | undefined>(undefined);
  const [vendor, setVendor] = useState<Hauler.HaulerWithAapTransport | undefined>(undefined);
  const [serviceGrouping, setServiceGrouping] = useState<UniversalService.ServiceGrouping | undefined>();
  const [serviceOrder, setServiceOrder] = useState<UniversalService.ServiceOrder | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [lastUpdatedBy, setLastUpdatedBy] = useState<string>('');
  const [createdBy, setCreatedBy] = useState<string>('');
  const [salesRep, setSalesRep] = useState<string>('');

  const client = useWaysteClient();

  useEffect(() => {
    fetchServiceOrder(serviceGroupingId);
  }, [serviceGroupingId]);

  const fetchServiceOrder = async (serviceGroupingId: string) => {
    setLoading(true);
    try {
      const serviceGroupingResponse = await client.universalService().serviceGrouping.fetch(serviceGroupingId);

      // find the service order
      const serviceOrderResponse = serviceGroupingResponse.serviceOrders.find((order) => order.id === serviceOrderId);

      if (!serviceOrderResponse) {
        throw new Error('Service order not found');
      }

      if (serviceOrderResponse.vendorID) {
        const vendor = await client.vendorService().fetchById(serviceOrderResponse.vendorID);
        setVendor(vendor.data);
      }
      fetchCustomer(serviceOrderResponse.customerID);
      setServiceGrouping(serviceGroupingResponse);
      setServiceOrder(serviceOrderResponse);
      const lastUpdatedByUser = repList.find(
        (user) => user.id === serviceGroupingResponse?.metadata.lastUpdatedByUserID,
      );
      const createdByUser = repList.find((user) => user.id === serviceGroupingResponse?.metadata.createdByUserID);
      const salesRepUser = repList.find((user) => user.id === serviceGroupingResponse?.contractDetails?.salesRepID);
      if (lastUpdatedByUser !== undefined) {
        setLastUpdatedBy(lastUpdatedByUser?.firstName + ' ' + lastUpdatedByUser?.lastName);
      }
      if (createdByUser !== undefined) {
        setCreatedBy(createdByUser?.firstName + ' ' + createdByUser?.lastName);
      }
      if (salesRepUser !== undefined) {
        setSalesRep(salesRepUser?.firstName + ' ' + salesRepUser?.lastName);
      }
    } catch (err) {
      console.log(err);
      showFlash('Error fetching service order', 'warning');
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomer = async (customerId: string) => {
    try {
      const customerResponse = await client.customer().adminPortal.fetch(customerId);
      setCustomer(customerResponse);
    } catch (error) {
      console.log(error);
      showFlash('Error fetching customer', 'warning');
    }
  };

  const handleCancelServiceOrder = async (
    cancelationDate: Date | null,
    customerCancelationFee: number,
    vendorCancelationFee: number,
    cancelationReason: string,
  ) => {
    const cancelServiceOrderPayload: UniversalService.CancellationDetails = {
      date: new Date().toISOString(),
      reason: cancelationReason,
      customerFee: customerCancelationFee,
      vendorFee: vendorCancelationFee,
    };

    const serviceOrderUpdatePayload: UniversalService.ServiceOrderUpdate = {
      ...serviceOrder,
      vendorID: serviceOrder?.vendorID || undefined,
      cancellationDetails: cancelServiceOrderPayload,
    };

    try {
      await client
        .universalService()
        .serviceGrouping.serviceOrder.update(serviceGroupingId, serviceOrderId, serviceOrderUpdatePayload);

      showFlash('Service Order Successfully Cancelled', 'success');
      fetchServiceOrder(serviceGroupingId);
      setCancelOpen(false);
    } catch (error) {
      console.log(error);
      showFlash('An Error Occurred updating service order', 'warning');
    }
  };

  const handleCancelSubscription = async (
    cancelationDate: Date | null,
    customerCancelationFee: number,
    vendorCancelationFee: number,
    cancelationReason: string,
  ) => {
    if (!cancelationDate) return;
    if (!serviceOrder?.subscriptionDetails) return;

    const updatePayload: UniversalService.ServiceOrderUpdate = {
      ...serviceOrder,
      vendorID: serviceOrder?.vendorID || undefined,
      cancellationDetails: {
        date: cancelationDate.toISOString(),
        reason: cancelationReason,
        customerFee: customerCancelationFee,
        vendorFee: vendorCancelationFee,
      },
      subscriptionDetails: {
        ...serviceOrder.subscriptionDetails,
        endDate: formatDateToYYYYMMDD(cancelationDate),
        active: false,
      },
    };

    try {
      await client
        .universalService()
        .serviceGrouping.serviceOrder.update(serviceGroupingId, serviceOrderId, updatePayload);

      showFlash('Successfully Cancelled Subscription', 'success');
      setCancelOpen(false);
    } catch (error) {
      console.log(error);
      showFlash('Error Cancelling subscription', 'warning');
    }
  };

  // if (serviceOrder.vendorID) {
  //   client
  //     .vendorService()
  //     .fetchById(serviceOrder.vendorID)
  //     .then((res) => setVendor(res.data))
  //     .catch((err) => console.log(err));
  // }
  // const contractDetails: ServiceOrder.ContractDetails = {
  //   id: '123123',
  //   salesRepID: '12313',
  // };

  // client
  //   .adminPortal()
  //   .profile.fetchRoster()
  //   .then((res) => setRoster(res.data))
  //   .catch((err) => console.log(err));

  // const onCancel = (
  //   cancelationDate: Date | null,
  //   customerCancelationFee: number,
  //   vendorCancelationFee: number,
  //   cancelationReason: string,
  // ) => {
  //   alert('cancel order');
  // };

  const handleGenerateInvoice = async () => {
    if (!serviceGrouping?.invoiceGroupings[0] || !serviceOrder) return;

    const latestInvoiceGrouping = serviceGrouping.invoiceGroupings.reduce((prev, current) => {
      return prev.metadata.createdAt > current.metadata.createdAt ? prev : current;
    }, serviceGrouping.invoiceGroupings[0]);

    const invoice = generatePayablesAndReceivable(latestInvoiceGrouping, serviceGrouping, {
      customer: customer,
    });

    if (!invoice.receivable) {
      showFlash('No Invoice to Generate.', 'warning');
      return;
    }

    try {
      setLoading(true);
      await client.invoice().adminPortal.receivable.create(invoice.receivable);
    } catch (error) {
      showFlash('Error generating invoice', 'warning');
    } finally {
      setLoading(false);
    }
  };

  const oneTime =
    serviceGrouping?.type === 'single' && serviceGrouping && serviceOrder ? (
      <div className="space-y-2 mt-25">
        <DetailsCardWrapper
          heading={'Order # ' + serviceOrder?.fullOrderNumber}
          buttons={[
            {
              label: <>Cancel Order</>,
              onClick: () => setCancelOpen(true),
              disabled: serviceOrder?.cancellationDetails || serviceOrder.status == 'COMPLETED' ? true : false,
              props: {
                className: 'bg-error-dark',
              },
            },
          ]}
        />

        <UniversalServicesCancelDialog
          variant="delete"
          type="oneTime"
          cancelOpen={cancelOpen}
          setCancelOpen={setCancelOpen}
          onCancel={handleCancelServiceOrder}
        />
        <OneTimeServiceDetailsCard
          serviceOrder={serviceOrder}
          serviceGrouping={serviceGrouping}
          vendor={vendor ? vendor.name : undefined}
          lastUpdatedBy={lastUpdatedBy}
          createdBy={createdBy}
        />
        <OneTimeDetailsCard serviceOrder={serviceOrder} />
        {customer && <CustomerDetailsCard customer={customer} />}
        <PayablesTableCard serviceOrderIds={[serviceOrder.id]} serviceGrouping={serviceGrouping} />
        <OneTimeBillingDetailsCard serviceOrder={serviceOrder} serviceGrouping={serviceGrouping} />
      </div>
    ) : null;

  const subscription =
    serviceGrouping?.type !== 'single' && serviceGrouping && serviceOrder ? (
      <div className="space-y-2">
        <AddServiceEventDialog
          open={showAddServiceEventDialog}
          onClose={() => {
            setAddServiceEventDialog(false);
          }}
          serviceGrouping={serviceGrouping}
          serviceOrder={serviceOrder}
        />
        <DetailsCardWrapper
          heading={'Order # ' + serviceOrder?.fullOrderNumber}
          buttons={[
            {
              label: <>Cancel Subscription</>,
              onClick: () => setCancelOpen(true),
              disabled: serviceOrder?.subscriptionDetails ? !serviceOrder.subscriptionDetails.active : true,
              props: {
                className: 'bg-error-dark',
              },
            },
            {
              label: 'Generate Invoice',
              onClick: () => {
                handleGenerateInvoice();
              },
            },
            {
              label: 'Add Service Events',
              onClick: () => {
                setAddServiceEventDialog(true);
              },
            },
          ]}
        />

        <UniversalServicesCancelDialog
          variant="delete"
          type="subscription"
          cancelOpen={cancelOpen}
          setCancelOpen={setCancelOpen}
          onCancel={handleCancelSubscription}
        />
        <SubscriptionServiceDetailsCard
          serviceOrder={serviceOrder}
          serviceGrouping={serviceGrouping}
          vendor={vendor ? vendor.name : undefined}
          lastUpdatedBy={lastUpdatedBy}
          createdBy={createdBy}
        />
        <SubscriptionDetailsCard
          serviceOrder={serviceOrder}
          serviceGrouping={serviceGrouping}
          contractDetails={serviceGrouping.contractDetails}
          salesRep={salesRep ? salesRep : ''}
        />
        {customer && <CustomerDetailsCard customer={customer} />}
        <SubscriptionBillingDetailsCard serviceOrder={serviceOrder} />
      </div>
    ) : (
      <div>
        <div className="rounded-md shadow-md p-8 flex justify-center flex-col items-center">
          <ExclamationCircleIcon className="h-12 w-12 text-error-dark" />
          <div className="text-xl">
            Service Grouping is missing contract details.
            <br />
            Please contact your administrator.
          </div>
        </div>
      </div>
    );

  if (!serviceGrouping && !loading) {
    return (
      <div className="w-full p-6 pt-5 h-full">
        <div className="flex justify-start">
          <div className="text-2xl mb-4">Service Order Details</div>
        </div>
        <div className="flex justify-center flex-col items-center h-full">
          <div className="rounded-md shadow-md p-8 flex justify-center flex-col items-center">
            <ExclamationCircleIcon className="h-12 w-12 text-error-dark" />
            <div className="text-xl">Service Order not found</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full p-6 pt-5 min-h-full flex flex-col gap-2">
      <div className="flex justify-start items-center">
        <NavLink to={routes.universalServices.serviceGrouping.details(serviceGroupingId)} className="text-primary-dark">
          <button className="btn-icon">
            <ChevronLeftIcon className="h-5 w-5" />
          </button>
        </NavLink>
        <div className="text-2xl leading-none">
          {serviceGrouping?.type === 'single' ? 'Grouping' : 'Subscription'} <span className="text-gray-400">/</span>{' '}
          Service Order Details
        </div>
      </div>
      {/* if loading show loading */}
      {loading ? (
        <div className="flex justify-center flex-col items-center h-full">
          <Loading />
        </div>
      ) : // if serviceGrouping is single show oneTime else show subscription
      serviceGrouping?.type === 'single' ? (
        oneTime
      ) : (
        subscription
      )}
    </div>
  );
};

export default ServiceOrderDetails;
