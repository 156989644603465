import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Invoice, Material, Order, Pricing } from '@alliance-disposal/transport-types';
import { Button, Loading, Toggle } from '@wayste/sour-ui';
import {
  determineWaysteActive,
  dollarsToCents,
  formatServiceAddress,
  getDateFormat,
  getDispatchEmailsString,
  moneyFormatter,
  pricingBreakdownTotal,
} from '@wayste/utils';
import { WaysteActivity } from '@wayste/utils';
import { format } from 'date-fns';
import { round } from 'lodash';
import { UIContext } from '../../contexts';
import {
  OrderStatus,
  ccRate,
  materials,
  noReplyEmail,
  paymentMethodsEnums,
  paymentTermsEnums,
  priceTypes,
  priceTypesEnums,
  quotedPriceItems,
} from '../../utils';
import Dialog from '../Dialog';
import SpreadsTable, { VendorPricingRow } from '../SpreadsTable/SpreadsTable';

interface Props {
  order: Order.AllianceOrderTransport;
  open: boolean;
  onCancel: () => void;
  onSubmit: (
    data: any,
    emailData?: {
      htmlMessage: string;
      textMessage: string;
      toAddress: string;
      subject: string;
      bcc: string;
    },
    pricingSnapshot?: Order.VendorPricingSnapshotTransportCreate,
  ) => void;
  loading: boolean;
}

export type HaulerPricingWithHauler = Pricing.PricingTransport & {
  hauler: Hauler.HaulerWithAapTransport;
  waysteUsage: WaysteActivity;
};

const OrderAssignHauler = ({ order, open, onCancel, onSubmit, loading }: Props) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const [haulerID, setHaulerID] = useState('');
  const [rawPricingWithHaulers, setRawPricingWithHaulers] = useState<HaulerPricingWithHauler[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [haulerEmail, setHaulerEmail] = useState<null | string>(null);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState<boolean>(true);
  const [receivables, setReceivables] = useState<Invoice.ReceivableTransport[]>([]);
  const [pricingSnapshot, setPricingSnapshot] = useState<Order.VendorPricingSnapshotTransportCreate | undefined>(
    undefined,
  );

  const handleGetPricing = async () => {
    setIsLoading(true);

    const pricingResults: Pricing.PricingTransport[] = await client.pricing().adminPortal.location.query({
      ...order.serviceLocation.coordinates,
      zip: order.serviceLocation.address.zip,
      state: order.serviceLocation.address.state,
    });

    const haulerPricing: HaulerPricingWithHauler[] = [];

    const haulerPricingPromises = pricingResults.map(async (item) => {
      if (item.haulerID && !item.public) {
        try {
          // for anyone who comes across this, this syntax is called destructuring assignment
          // basically I'm first destructuring the array returned from the promise
          // then I'm destructuring the results from the paginated result and renaming it to haulerWaysteActivity
          const [hauler, { results: haulerWaysteActivity }] = await Promise.all([
            client.vendorService().adminPortal.fetch(item.haulerID),
            client.vendorService().adminPortal.activity.query({
              haulerID: item.haulerID,
            }),
          ]);

          if (hauler && hauler.active) {
            haulerPricing.push({ ...item, hauler, waysteUsage: determineWaysteActive(hauler, haulerWaysteActivity) });
          }
        } catch (error) {
          console.log('error: ', error);
          showFlash('An error occurred fetching hauler pricing', 'warning');
        }
      }
      return null;
    });

    await Promise.all(haulerPricingPromises);

    // const normalizedHaulers: (Pricing.PricingTransport & { hauler: Hauler.HaulerWithAapTransport })[] = JSON.parse(
    //   JSON.stringify(haulerPricing),
    // );
    setRawPricingWithHaulers(haulerPricing);
    // normalizedHaulers.forEach((item, index: number) => {
    //   const found = item.pricingData.find((x) => x.material === order.material);
    //   if (item.pricingData && found) {
    //     const normalizedMaterial = sourgumPricingFunctions.normalizeMaterialSize(found);
    //     normalizedMaterial.sizes = normalizedMaterial.sizes.map((item) => {
    //       return {
    //         ...item,
    //         originalType: item.type,
    //       };
    //     });
    //     const foundIndex = item.pricingData.findIndex((x) => x.material === order.material);
    //     normalizedHaulers[index].pricingData[foundIndex].normalizedMaterial = normalizedMaterial;
    //   }
    // });
    // setPricingWithHaulers(normalizedHaulers);
    setIsLoading(false);
  };

  useEffect(() => {
    if (order.haulerID) setHaulerID(order.haulerID);
    handleGetPricing();
  }, []);

  useEffect(() => {
    const fetchReceivables = async () => {
      try {
        const data = await client.invoice().adminPortal.receivable.query({
          orderID: order.id,
        });
        setReceivables(data);
      } catch (error) {
        console.log('error: ', error);
      } finally {
        setIsLoadingInvoices(false);
      }
    };

    fetchReceivables();
    console.log('order: use ', order);
  }, [order]);

  if (isLoadingInvoices) {
    return <Loading />;
  }

  const handleSubmit = () => {
    const haulerPricing = rawPricingWithHaulers.find((item) => item.haulerID === haulerID);
    const pricing = haulerPricing?.pricingData.find((item) => item.material === order.material) || null;
    const sizePricing = pricing?.sizes ? pricing.sizes.find((item) => +item.size === +order.expectedSize.size) : null;
    const haulerPricingSnapshot: Order.HaulerPricingSnapshotTransport = {
      dump: sizePricing?.dump || 0,
      over: sizePricing?.over || 0,
      haul: sizePricing?.haul || 0,
      size: Number(sizePricing?.size) || order.expectedSize.size,
      tonLimit: { value: sizePricing?.tonLimit || 0, unit: 'TONS' },
      rentalPeriod: haulerPricing?.rentalPeriod ? haulerPricing?.rentalPeriod : { value: 0, unit: 'DAYS' },
      rentalExtensionFee: Math.round((haulerPricing?.rentExtensionFeeDollars || 0) * 100),
      priceType: pricing?.type || priceTypes.ton,
      paymentTerm: paymentTermsEnums.net30,
      paymentMethod: haulerPricing?.hauler.defaultPaymentMethod || paymentMethodsEnums.check,
    };

    const emailData = [
      { label: 'PO Number', value: `${order.orderNumber}` },
      { label: 'Delivery Address', value: formatServiceAddress(order.serviceLocation.address) },
      {
        label: 'Delivery Date',
        value: format(new Date(getDateFormat(order.expectedDeliveryDate)), 'EEEE MM/dd/yy'),
      },
      {
        label: 'Pick Up Date',
        value: order.expectedPickupDate
          ? format(new Date(getDateFormat(order.expectedPickupDate)), 'EEEE MM/dd/yy')
          : null,
      },
      {
        label: 'Delivery Instructions',
        value: `${order.sharedDeliveryNotes}. If there are any issues call (732) 366-9355 while still on site.`,
      },
      { label: 'Size', value: `${order.expectedSize.size}  yard dumpster` },
      {
        label: 'Material',
        value: materials[order.material as keyof typeof materials],
      },
      { label: 'County', value: order.serviceLocation.county },
      {
        label: 'Haul Rate',
        value: moneyFormatter(sizePricing?.haul ? +sizePricing?.haul : 0),
      },
      {
        label: 'Dump Rate',
        value: moneyFormatter(sizePricing?.dump ? +sizePricing?.dump : 0),
      },
    ];

    if (order.sharedDeliveryNotesPrivate) {
      emailData.push({
        label: 'Additional Notes',
        value: order.sharedDeliveryNotesPrivate,
      });
    }
    // IF pricing type is ton, and the ton limit is GREATER THAN 0 then add Ton Limit and Subtotal
    if (sizePricing?.tonLimit && Number(sizePricing.tonLimit) > 0 && pricing?.type !== priceTypes.flat) {
      if (sizePricing.dump !== null && sizePricing.haul !== undefined && sizePricing.dump !== undefined) {
        emailData.push({ label: 'Ton Limit', value: `${sizePricing?.tonLimit} tons` });
        const subTotal = round((sizePricing?.haul * 100 + sizePricing?.dump * 100 * sizePricing.tonLimit) / 100);
        emailData.push({ label: 'Subtotal', value: moneyFormatter(subTotal) });
      }
      // ELSE if pricing type is NOT ton show Ton Limit as N/A
    } else if (pricing?.type !== priceTypesEnums.ton) {
      emailData.push({ label: 'Ton Limit', value: 'N/A' });
    }

    const confirmationUrl = 'https://app.wayste.com/update-alliance/';
    const htmlMessage = `
    	<p>Sourgum Waste dumpster rental confirmation details:</p>
    	${emailData
        .map((item) => {
          if (
            (item.label === 'Pick Up Date' && !item.value) ||
            (item.label === 'Dump Rate' && (sizePricing?.dump ? +sizePricing?.dump : 0) === 0)
          )
            return '';

          return `<p><b>${item.label}:</b> ${item.value}</p>`;
        })
        .join('')}
    	<p style="margin: 20px 0">
    		<a href="${confirmationUrl}?category=delivery_confirmed&id=${
      order.id
    }" style="text-decoration: none;color: white;background: #007AFF;padding: 7px 15px;border-radius: 4px;box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);">Click Here to Confirm Delivery</a>
    	</p>
    	<p>Please click above on the Confirm Delivery button if you are accepting this order at the above details.</p>
    	<p>If the button does not work, please go to ${confirmationUrl}?category=delivery_confirmed&id=${
      order.id
    } or reply Confirmed to this email.</p>
    `;

    const textMessage = `
    	Sourgum Waste dumpster rental confirmation details:
    	${emailData
        .map((item) => {
          if (
            (item.label === 'Pick Up Date' && !item.value) ||
            (item.label === 'Dump Rate' && (sizePricing?.dump ? +sizePricing?.dump : 0) === 0)
          )
            return '';
          return `\n\n${item.label}: ${item.value}`;
        })
        .join('')}
    	\n\nPlease go to ${confirmationUrl}?category=delivery_confirmed&id=${
      order.id
    } or reply Confirmed to accept this order.
    `;
    onSubmit(
      {
        hauler: haulerPricing?.haulerName,
        haulerDumpRateDollars: sizePricing?.dump
          ? +sizePricing?.dump / 100
          : 0
          ? Math.round(sizePricing?.dump ? +sizePricing?.dump : 0) / 100
          : 0,
        haulerHaulRateDollars: sizePricing?.haul
          ? +sizePricing?.haul / 100
          : 0
          ? Math.round(sizePricing?.haul ? +sizePricing?.haul : 0) / 100
          : 0,
        haulerID: haulerID,
        status: OrderStatus.ASSIGNED,
        haulerPricingSnapshot,
        vendorName: haulerPricing?.haulerName,
      },
      sendEmail && haulerEmail
        ? {
            htmlMessage,
            textMessage,
            toAddress: haulerEmail,
            subject: `Sourgum Waste Dumpster Confirmation - ${order.orderNumber}`,
            bcc: noReplyEmail,
          }
        : undefined,
      pricingSnapshot,
    );
  };

  const handleHaulerSelected = (
    pricing: VendorPricingRow,
    pricingSnapshot?: Order.VendorPricingSnapshotTransportCreate,
  ) => {
    setIsLoading(true);
    const dispatchEmailsString = getDispatchEmailsString(pricing.hauler.contacts);
    if (dispatchEmailsString) {
      setHaulerEmail(dispatchEmailsString);
      setSendEmail(true);
    } else {
      setHaulerEmail(null);
      setSendEmail(false);
    }
    setPricingSnapshot(pricingSnapshot);
    setHaulerID(pricing.hauler.id);
    setIsLoading(false);
  };

  return (
    <Dialog open={open} styledTitle="Assign Hauler" onClose={onCancel} className="!max-w-5xl">
      <div className="flex flex-col">
        <div>Assign hauler to:</div>
        <div className="my-2">{formatServiceAddress(order.serviceLocation.address)}</div>
        <div>
          <>
            {order.expectedSize.size} YD For {materials[order.material as keyof typeof materials]} -{' '}
            {priceTypes[order.priceType as keyof typeof priceTypes]} Pricing
            {(Number(order.weightLimit?.value) || 0) >= 0 ? ` - ${order.weightLimit?.value} Ton Limit` : null}
          </>
        </div>
        <div className="mt-8">
          <div className="mb-7">
            <Toggle
              label="Send confirmation email to hauler"
              value={sendEmail}
              onChange={(value) => setSendEmail(value)}
              disabled={!haulerEmail}
            />
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="mt-4">
              <SpreadsTable
                haulerWithPricing={rawPricingWithHaulers}
                selectedMaterial={order.material as Material}
                selectedSize={order.expectedSize.size}
                selectedHauler={haulerID}
                orderID={order.id}
                onRowClick={handleHaulerSelected}
                sourgumPricing={{
                  haul: dollarsToCents(
                    receivables
                      .sort(
                        (a, b) => +(a.invoiceDetails.invoiceNumber || '') - +(b.invoiceDetails.invoiceNumber || ''),
                      )[0]
                      ?.invoiceDetails.lineItems.find((item) => item.itemName === quotedPriceItems.haul)
                      ?.unitPriceDollars || 0,
                  ),
                  dump: dollarsToCents(order.dumpRateDollars || 0),
                  tonLimit: order.weightLimit?.value || 0,
                  overage: dollarsToCents(
                    pricingBreakdownTotal(
                      order.overageDollars || 0,
                      1,
                      0,
                      receivables
                        .sort(
                          (a, b) => +(a.invoiceDetails.invoiceNumber || '') - +(b.invoiceDetails.invoiceNumber || ''),
                        )[0]
                        ?.invoiceDetails.lineItems.find((item) => item.itemName === 'CC Fee')
                        ? ccRate
                        : 0,
                    ).unitPriceDollars,
                  ),
                }}
              />
            </div>
          )}
        </div>
        <div className="pt-8 flex justify-end gap-4">
          <button className="btn-dark-grey-outlined" type="button" onClick={onCancel} disabled={isLoading || loading}>
            Cancel
          </button>
          <Button
            className="btn-primary"
            type="button"
            disabled={!haulerID || isLoading || loading}
            onClick={() => handleSubmit()}
            loading={isLoading || loading}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default OrderAssignHauler;
