import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { UIContext } from '../../contexts';
import ReceivableTableCard from '../ReceivableTableCard/ReceivableTableCard';
import ServiceGroupingTableCard from './ServiceGroupingTableCard';

const UniversalServicesDetails = ({ customerID }: { customerID: string }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serviceGroupings, setServiceGroupings] = useState<UniversalService.ServiceGrouping[]>([]);
  const [invoiceGroupings, setInvoiceGroupings] = useState<string[]>([]);
  const { showFlash } = useContext(UIContext);
  const client = useWaysteClient();

  useEffect(() => {
    fetchServiceGroupings();
  }, []);

  useEffect(() => {
    if (serviceGroupings.length) fetchInvoiceGroupings();
  }, [serviceGroupings]);

  const fetchServiceGroupings = async () => {
    setIsLoading(true);
    try {
      const serviceGroupings = await client.universalService().serviceGrouping.query({
        customerID: customerID,
      });
      setServiceGroupings(serviceGroupings.results);
    } catch (error) {
      console.error(error);
      showFlash('Error Fetching Service Groupings', 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInvoiceGroupings = async () => {
    setIsLoading(true);
    try {
      const serviceOrderIDs = serviceGroupings
        .map((serviceGrouping) => serviceGrouping.serviceOrders.map((serviceOrder) => serviceOrder.id).flat())
        .flat();
      const invoiceGroupings = await client.universalService().invoice.queryByServiceOrderId(serviceOrderIDs);
      setInvoiceGroupings(invoiceGroupings);
    } catch (error) {
      console.error(error);
      showFlash('Error Fetching Invoice Groupings', 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="text-2xl border-b mb-6 mt-10"> Universal Services</div>
      <ServiceGroupingTableCard serviceGroupings={serviceGroupings} isLoading={isLoading} />
      <ReceivableTableCard
        serviceGroupings={serviceGroupings}
        suppressQuery={!Boolean(invoiceGroupings.length)}
        receivableQuery={{
          productInvoiceGroupingID: invoiceGroupings.join(','),
        }}
      />
    </>
  );
};
export default UniversalServicesDetails;
