import React, { useContext } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { InternalTicket } from '@alliance-disposal/transport-types';
import { Button, Menu, MenuItem } from '@wayste/sour-ui';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { UIContext } from '../../contexts';
import { InternalTicketContext } from './context';

type StatusMenuProps = {
  status?: InternalTicket.InternalTicketStatus | null;
  /**
   * Passing in a ticketID will allow for status changed on the ticket via Wayste Client
   */
  ticketID?: string;
  onStatusChange?: (status?: InternalTicket.InternalTicketStatus | null) => void;
  disabled?: boolean;
};

const StatusMenu = ({ status, ticketID, onStatusChange, disabled }: StatusMenuProps) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const { fetchInternalTickets } = useContext(InternalTicketContext);

  const getButtonColors = () => {
    switch (status) {
      case 'OPEN':
        return 'bg-gray-600 text-white';
      case 'IN_PROGRESS':
        return 'bg-blue-600 text-white';
      case 'SOLVED':
        return 'bg-green-600 text-white';
      default:
        return 'text-black bg-white border border-black';
    }
  };

  const handleStatusChange = async (newStatus?: InternalTicket.InternalTicketStatus | null) => {
    if (ticketID) {
      try {
        await client.internalTicket().adminPortal.update(ticketID, { status: newStatus });
        fetchInternalTickets();
      } catch (error) {
        showFlash('Error occurred updating status', 'error');
        return;
      }
    }
    if (onStatusChange) {
      onStatusChange(newStatus);
    }
  };

  return (
    // @ts-expect-error TODO menu needs to be able to accept children like this
    <Menu
      button={
        <Button
          className={`btn-base ${getButtonColors()}`}
          endIcon={<ChevronDownIcon className="h-5 w-5" />}
          disabled={disabled}
        >
          {status ? InternalTicket.InternalTicketStatusLabels[status] : 'No Status'}
        </Button>
      }
    >
      <MenuItem onClick={() => handleStatusChange(null)}>No Status</MenuItem>
      {Object.entries(InternalTicket.InternalTicketStatusLabels).map((item) => (
        <MenuItem
          key={item[0]}
          onClick={() => handleStatusChange(item[0] as keyof typeof InternalTicket.InternalTicketStatusLabels)}
        >
          {item[1]}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default StatusMenu;
