import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Float } from '@headlessui-float/react';
import { Menu, Transition } from '@headlessui/react';
import { useFlash } from '../../hooks/useFlash';
import AssignToVendorDialog from '../UniversalServiceForms/AssignToVendorDialog';

interface UniversalServicesStatusPopperProps {
  serviceOrder: UniversalService.ServiceOrder;
  serviceGrouping?: UniversalService.ServiceGrouping;
  shouldRefresh?: () => void;
  display?: 'button' | 'label';
}

const UniversalServicesStatusPopper = ({
  serviceOrder: current,
  shouldRefresh,
  display,
  serviceGrouping,
}: UniversalServicesStatusPopperProps) => {
  const [modalOpen, setModalOpen] = useState<'ASSIGNED_TO_VENDOR' | null>(null);
  const [serviceOrder, setServiceOrder] = useState<UniversalService.ServiceOrder>(current);
  const client = useWaysteClient();
  const { showFlash } = useFlash();
  const getColor = (status: UniversalService.ServiceOrderStatus | 'MIXED') => {
    if (status === 'MIXED') return 'bg-indigo-500 text-white';

    return `bg-statuses-${status}-primary text-statuses-${status}-text`;
  };

  const onStatusChange = async (status: UniversalService.ServiceOrderStatus) => {
    console.log(status);
    if (status === 'ASSIGNED') {
      setModalOpen('ASSIGNED_TO_VENDOR');
    } else {
      try {
        await client
          .universalService()
          .serviceGrouping.serviceOrder.update(serviceOrder.serviceGroupingID, serviceOrder.id, { status });
        shouldRefresh?.();
        showFlash('Service Order Updated', 'success');
      } catch (error) {
        console.error(error);
        showFlash('An Error Occurred updating the service order.', 'warning');
      }
    }
  };

  /* 

    <button className={`btn-base w-28 h-8 whitespace-nowrap rounded ${getColor(serviceOrder.status)}`} type="button">
      {UniversalService.ServiceOrderStatusLabels[serviceOrder.status]}
    </button>
*/

  if (display === 'label') {
    if (current.cancellationDetails) {
      return (
        <div className="btn-base w-28 font-bold capitalize h-8 whitespace-nowrap rounded text-white bg-error-dark cursor-auto">
          CANCELLED
        </div>
      );
    }
    return (
      <div className={`btn-base w-28 h-8 whitespace-nowrap rounded cursor-auto ${getColor(serviceOrder.status)}`}>
        {UniversalService.ServiceOrderStatusLabels[serviceOrder.status]}
      </div>
    );
  }
  if (current.cancellationDetails) {
    return (
      <button
        className="btn-base w-28 font-bold capitalize h-8 whitespace-nowrap rounded text-white bg-error-dark cursor-auto"
        type="button"
      >
        CANCELLED
      </button>
    );
  }
  return (
    <>
      <AssignToVendorDialog
        open={modalOpen === 'ASSIGNED_TO_VENDOR'}
        serviceOrder={serviceOrder}
        serviceGrouping={serviceGrouping}
        onClose={(update) => {
          setServiceOrder({ ...serviceOrder, ...update });
          setModalOpen(null);
          shouldRefresh?.();
        }}
      />

      <Menu>
        {({ open }) => (
          <Float adaptiveWidth portal flip>
            <Menu.Button className={`btn-base w-28 h-8 whitespace-nowrap rounded ${getColor(serviceOrder.status)}`}>
              {UniversalService.ServiceOrderStatusLabels[serviceOrder.status]}
            </Menu.Button>
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Menu.Items
                className={'bg-white mt-2 flex flex-col divide-y min-w-[200px] rounded-md shadow-md p-2'}
                static
              >
                {UniversalService.ServiceOrderStatuses.filter((status) =>
                  serviceOrder.serviceType.family.allowedStatuses.includes(status),
                ).map((status) => (
                  <Menu.Item as="div" className="flex items-center py-1 justify-center" key={status}>
                    {({ active }) => {
                      // you cannot change the status to the current status or to a status that is before the current status, except for the On Hold status
                      const disabled =
                        serviceOrder.status === status ||
                        (UniversalService.ServiceOrderStatuses.indexOf(status) <
                          UniversalService.ServiceOrderStatuses.indexOf(serviceOrder.status) &&
                          status === 'ON_HOLD');

                      return (
                        <button
                          className={`leading-none w-full rounded py-2 px-1 ${
                            !disabled && active && 'bg-sourgum-green-200'
                          } ${disabled && 'bg-gray-200 text-gray-500 cursor-not-allowed'}`}
                          type="button"
                          disabled={disabled}
                          onClick={() => onStatusChange(status)}
                        >
                          {UniversalService.ServiceOrderStatusLabels[status]}
                        </button>
                      );
                    }}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Float>
        )}
      </Menu>
    </>
  );
};

export default UniversalServicesStatusPopper;
