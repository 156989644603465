import { UniversalService } from '@alliance-disposal/transport-types';
import { formatISODateString } from '@wayste/utils';
import { PencilIcon } from '@heroicons/react/24/solid';
import { useHistory, useParams } from 'react-router-dom';
import { routes } from '../../../utils';
import CardInfoList from '../../ui/CardInfoList';
import DetailsCardWrapper from '../../ui/DetailsCardWrapper';

interface SubscriptionDetailsCardProps {
  serviceOrder: UniversalService.ServiceOrder;
  serviceGrouping: UniversalService.ServiceGrouping;
  contractDetails?: UniversalService.ContractDetails;
  salesRep: string;
}

interface cardData {
  label: string;
  value: React.ReactNode;
}

const SubscriptionDetailsCard = ({
  serviceOrder,
  contractDetails,
  salesRep,
  serviceGrouping,
}: SubscriptionDetailsCardProps) => {
  const history = useHistory();
  const params: { serviceGroupingId: string; serviceOrderId: string } = useParams();

  const handleSubscriptionDetails = () => {
    history.push(
      routes.universalServices.serviceGrouping.subscription.subscriptionUpdate(
        params.serviceGroupingId,
        params.serviceOrderId,
      ),
    );
  };
  const colOne: cardData[] = [
    {
      label: 'Subscription Start Date',
      value: formatISODateString(serviceOrder.subscriptionDetails?.startDate, undefined, 'Error - No Start Date'),
    },
    {
      label: 'Subscription End Date',
      value: formatISODateString(serviceOrder.subscriptionDetails?.endDate, undefined, ''),
    },
    {
      label: 'Contract Term',
      value: contractDetails?.contractTerm || '',
    },
    {
      label: 'Contract Start Date',
      value: formatISODateString(contractDetails?.contractStartDate, undefined, '') || '',
    },
    {
      label: 'Contract End Date',
      value: formatISODateString(contractDetails?.contractEndDate, undefined, '') || '',
    },
    {
      label: 'Sales Rep',
      value: salesRep,
    },
  ];

  const colTwo: cardData[] = [
    {
      label: 'Subscription #',
      value: 'S' + serviceGrouping.orderNumber,
    },
    {
      label: 'Subscription Name',
      value: serviceGrouping.name,
    },
    {
      label: 'Subscription Status',
      value: serviceOrder.subscriptionDetails?.active ? 'Active' : 'Inactive', // Should be subscription status
    },
  ];
  const colThree: cardData[] = [
    {
      label: 'Internal Notes Subscription',
      value: serviceOrder.subscriptionDetails?.internalNotes,
    },
  ];
  return (
    <DetailsCardWrapper
      heading="Subscription Details"
      buttons={[
        {
          label: (
            <>
              <PencilIcon className="w-5 h-5 mr-1" />
              Edit Subscription Details
            </>
          ),
          onClick: handleSubscriptionDetails,
        },
      ]}
    >
      <div className={'grid grid-cols-1 gap-4 lg:grid-cols-3'}>
        <CardInfoList data={colOne} border />
        <CardInfoList data={colTwo} border />
        <CardInfoList data={colThree} />
      </div>
    </DetailsCardWrapper>
  );
};

export default SubscriptionDetailsCard;
