import { Customer } from '@alliance-disposal/transport-types';
import Container from '@mui/material/Container';
import CustomerForm2 from '../CustomerForm/CustomerForm2';
import Dialog from '../Dialog';

interface CustomerUpdateProps {
  customer: Customer.AllianceCustomerTransport;
  onBackButtonClick: () => void;
  open: boolean;
  onSubmit: (data: Customer.AllianceCustomerTransport) => void;
  isLoading: boolean;
}
const CustomerUpdate = ({ customer, onBackButtonClick, open, onSubmit, isLoading }: CustomerUpdateProps) => {
  return (
    <Dialog
      open={open}
      onClose={onBackButtonClick}
      styledTitle="Update Customer"
      className="overflow-scroll !max-w-2xl"
    >
      <Container>
        <CustomerForm2 customer={customer} onCancel={onBackButtonClick} onSubmit={onSubmit} isLoading={isLoading} />
      </Container>
    </Dialog>
  );
};

export default CustomerUpdate;
