import axios from 'axios';
import type Stripe from 'stripe';

type CreateCustomerProps = {
  name: string;
  email?: string;
  phone?: string;
  sourceId?: string;
};

const waysteStripeEndpoint = import.meta.env.VITE_BASE_API_URL + '/stripe';

if (!waysteStripeEndpoint) {
  alert('COULD NOT LOAD STRIPE, DO NOT DO ANYTHING GET AN AAP DEV');
}

const stripeEndpoints = {
  base: waysteStripeEndpoint,
  charge: waysteStripeEndpoint + '/charge/',
  checkout: waysteStripeEndpoint + '/checkout/',
  customer: waysteStripeEndpoint + '/customer/',
  customerSources: (customerId: string) => {
    return waysteStripeEndpoint + '/customer/' + customerId + '/sources';
  },
  customerQueryEmail: (email: string) => {
    return waysteStripeEndpoint + `/customer?email=${email}`;
  },
  customerInvoices: (customerId: string) => {
    return waysteStripeEndpoint + '/customer/' + customerId + '/invoices';
  },
  invoice: waysteStripeEndpoint + '/invoice/',
  invoiceItem: waysteStripeEndpoint + '/invoice-item/',
  refund: waysteStripeEndpoint + '/refund/',
  source: waysteStripeEndpoint + '/source/',
};

/**
 * Creates a Customer in Stripe. Returns Stripe Customer Data
 */
/* export const createCustomer = async (data: CreateCustomerProps, token: string) => {
  return new Promise(async (resolve, reject) => {
    if (!data.email) {
      reject('You must provide an email');
    }
    data.email = data.email?.toLowerCase().trim();

    const formattedData = {
      email: data.email,
      source: data.sourceId,
      name: data.name,
      phone: data.phone,
    };
    const url = stripeEndpoints.customer;
    try {
      const res = await axios({
        method: 'POST',
        url,
        headers: { Authorization: token },
        data: formattedData,
      });
      resolve(res.data);
    } catch (error) {
      reject(error);
      console.warn('axios Stripe createCustomer Error: ', error);
    }
  });
}; */

export const createCustomer = async (data: CreateCustomerProps, token: string) => {
  if (!data.email) {
    throw new Error('You must provide an email');
  }

  data.email = data.email?.toLowerCase().trim();

  const formattedData = {
    email: data.email,
    source: data.sourceId,
    name: data.name,
    phone: data.phone,
  };
  const url = stripeEndpoints.customer;

  try {
    const { data } = await axios.post<Stripe.Customer>(url, formattedData, {
      headers: { Authorization: token },
    });
    return data;
  } catch (error) {
    console.error('axios Stripe createCustomer Error: ', error);
    throw error;
  }
};

/**
 * Updates a Stripe Customer based on ID
 * @param {String} id Stripe Customer ID
 * @param {Object} data Object containing data to update
 * @param {String} token Auth token
 */
export const updateCustomer = async (id: string, data: unknown, token: string) => {
  return new Promise(async (resolve, reject) => {
    const url = stripeEndpoints.customer + id;
    try {
      const res = await axios({
        method: 'PATCH',
        url,
        headers: { Authorization: token },
        data,
      });
      resolve(res.data);
    } catch (error) {
      console.error('axios Stripe updateCustomer Error: ', error);
      reject(error);
    }
  });
};

/**
 * Retrieve Customer data from Stripe based on Stripe Customer ID. Returns Stripe Customer Data
 * @param {String} id Stripe customer id
 * @param {String} token Auth token
 */
export const retrieveCustomer = async (id: string, token: string, withInvoices?: boolean) => {
  return new Promise(async (resolve, reject) => {
    const url = withInvoices ? stripeEndpoints.customerInvoices(id) : stripeEndpoints.customer + id;

    try {
      const res = await axios({
        method: 'GET',
        url,
        headers: { Authorization: token },
      });
      resolve(res.data);
    } catch (error) {
      console.error('axios Stripe retrieveCustomer Error: ', error);
      reject(error);
    }
  });
};

/**
 * Searches Stripe Customers for ones whose email matches provided email. Returns array of matching Stripe Customer Data
 * @param {string} email - Email address of customer
 * @param {String} token Auth token
 */
export const findCustomer = async (email: string, token: string) => {
  return new Promise(async (resolve, reject) => {
    const url = stripeEndpoints.customer;
    try {
      const res = await axios({
        method: 'GET',
        url,
        headers: { Authorization: token },
        params: { email },
      });
      console.log(res.data);
      resolve(res.data);
    } catch (error) {
      console.warn('axios Stripe findCustomer Error: ', error);
      reject(error);
    }
  });
};

/**
 * Saves a Credit or Debit card to a customer in stripe. Returns Stripe Card data
 * @param {String} customerId - Stripe Customer ID
 * @param {String} sourceId - Need create a source via CardElement and pass in sourceID
 * @param {String} token Auth token
 */
export const createCard = async (customerId: string, sourceId: string, token: string) => {
  console.log('Creating card...');
  return new Promise(async (resolve, reject) => {
    const url = stripeEndpoints.customerSources(customerId);
    try {
      const res = await axios({
        method: 'POST',
        url,
        headers: { Authorization: token },
        data: {
          sourceId: sourceId,
        },
      });
      console.log(res);
      resolve(res.data);
    } catch (error: any) {
      reject(error);
      alert(`An error occurred saving the Card to Stripe - ${error.response.data?.message}`);
    }
  });
};

/**
 * Stripe Charges customers card. Returns either the results of a successful charge or false if failed
 * @param {String} sourceId ID created from react-stripe-js CardElement
 * @param {String} customerId Stripe customer ID
 * @param {(Number|String)} amount The amount to charge the card in standard dollar format i.e 1.00
 * @param {String} token Auth token
 * @param {String} description Option charge description, defaults to "Dumpster rental"
 */
export const createChargeToCard = (
  sourceId: string,
  customerId: string,
  amount: number,
  token: string,
  description?: string,
) => {
  return new Promise(async (resolve, reject) => {
    const stripeAmount = Math.round(+amount * 100).toString();
    const data = {
      amount: stripeAmount,
      source: sourceId,
      customer: customerId,
      description: description ? description : 'Dumpster rental',
    };
    const url = stripeEndpoints.charge;
    try {
      const res = await axios({
        method: 'POST',
        url,
        headers: { Authorization: token },
        data,
      });
      resolve(res.data);
    } catch (error: any) {
      console.warn('axios Stripe createChargeToCard Error: ', error);
      reject(false);
      alert(
        `STOP. A possible error may have occurred. DO NOT refresh or close your screen. Contact an AAP Dev. - ${error.response.data?.message}`,
      );
    }
  });
};

/**
 * Creates an invoice and sends it to the customer
 * @deprecated - use backend invoice links
 * @param {String} stripeCustomerId Stripe Customer ID
 * @param {Number} total Invoice total, in standard USD format
 * @param {String} orderId ID of the order to be able to pair the Stripe invoice back to
 * @param {Number} invoiceNumber The Sourgum invoice number to be associated with the Stripe invoice
 * @param {String} token Auth token
 * @param {Object} customerData DEPRECATED If invoice is for a new customer { name, email } email is required
 * @returns Data on if the invoice sent correctly or not
 */
export const createInvoice = async (
  stripeCustomerId: string,
  total: number,
  orderId: string,
  invoiceNumber: number,
  receivableID: string,
  token: string,
  customerData?: { name?: string; email?: string },
) => {
  if (!receivableID) {
    console.log(stripeCustomerId, total, orderId, invoiceNumber, receivableID);
    alert('Missing receivableID on stripe createInvoice, get an AAP Dev');
  }

  if (customerData) {
    console.error('DEPRECATED, PLEASE USE THE CUSTOMER ENDPOINT TO CREATE THE CUSTOMER BEFORE SENDING THE INVOICE');
    alert('Customer must be created before sending an notice, get an AAP Dev');
  }

  return new Promise(async (resolve, reject) => {
    const amount = Math.round(+total * 100);
    const data = {
      invoice: { customer: stripeCustomerId },
      metadata: { orderId, invoiceNumber, receivableID },
      invoiceItem: { customer: stripeCustomerId, amount },
    };

    const url = stripeEndpoints.invoice;

    try {
      const res = await axios({
        method: 'POST',
        url: url,
        headers: { Authorization: token },
        data,
      });
      resolve(res.data);
    } catch (error) {
      console.warn('axios Stripe createInvoice Error: ', error);
      reject(error);
    }
  });
};

export const voidInvoice = (invoiceId: string, token: string) => {
  return new Promise(async (resolve, reject) => {
    const url = stripeEndpoints.invoice + invoiceId;

    try {
      const res = await axios({
        method: 'DELETE',
        url: url,
        headers: { Authorization: token },
      });
      resolve(res.data);
    } catch (error) {
      console.warn('axios Stripe voidInvoice Error: ', error);
      reject(error);
    }
  });
};

/**
 * Creates a refund in Stripe for a specific charge
 * @param {String} stripeChargeID ID of the Stripe charge applying refund to
 * @param {Number} total USD amount to refund (cannot be greater than original charge amount)
 * @param {String} token Auth token
 * @param {String} reason Optional reason for refund oneOf(null, duplicate, fraudulent, requested_by_customer)
 */
export const createStripeRefund = async (
  stripeChargeID: string,
  total: number,
  token: string,
  reason?: null | 'duplicate' | 'fraudulent' | 'requested_by_customer',
) => {
  return new Promise(async (resolve, reject) => {
    const amount = Math.round(+total * 100);
    const data = {
      charge: stripeChargeID,
      amount: amount,
      reason: reason,
    };
    const url = stripeEndpoints.refund;
    try {
      const res = await axios({
        method: 'POST',
        url,
        headers: { Authorization: token },
        data,
      });
      resolve(res.data);
    } catch (error) {
      console.warn('axios Stripe createStripeRefund Error: ', error);
      reject(error);
    }
  });
};
