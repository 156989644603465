import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useField } from 'formik';

const CheckBoxField = ({ fieldProps, ...props }: any) => {
  const [field] = useField(props.field.name);

  return (
    // @ts-ignore
    <FormControlLabel control={<Checkbox {...field} checked={field.value} {...fieldProps} />} {...props} />
  );
};

export default CheckBoxField;
