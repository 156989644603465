import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { V1 } from '@alliance-disposal/pricing';
import { Customer, UniversalService } from '@alliance-disposal/transport-types';
import { TanDataGrid } from '@wayste/sour-ui';
import { daysRemainingInBillingPeriod, moneyFormatter, toTitleCase } from '@wayste/utils';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { UniversalServiceFormData } from './UniversalServiceCreate';

type Row = {
  type: string;
  sku: string;
  lineItem: string;
  unitPrice: number;
  prorated?: string;
  quantity: number;
  total: number;
  taxable: string;
};

interface TotalTableProps {
  formData: UniversalServiceFormData;
  total: number;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
  customer?: Customer.AllianceCustomerTransport;
  className?: string;
}
const TotalTable = ({ formData, total, setTotal, customer, className }: TotalTableProps) => {
  if (
    !formData.serviceGrouping ||
    !formData.serviceGrouping.serviceOrders ||
    formData.serviceGrouping.serviceOrders.length === 0
  ) {
    return <></>;
  }

  const client = useWaysteClient();
  const [serviceTypeFamilies, setServiceTypeFamilies] = useState<UniversalService.ServiceTypeFamily[]>([]);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const [state, setState] = useState<string>('');

  useEffect(() => {
    client
      .universalService()
      .serviceFamily.query({ orderBy: 'name' })
      .then((response) => {
        setServiceTypeFamilies(response.results);
      });
  }, []);

  const getRowFromServiceEvent = (
    serviceEvent: UniversalService.ServiceEventCreate | UniversalService.ServiceEventTemplate,
    serviceTypeFamily: UniversalService.ServiceTypeFamily,
    serviceType: UniversalService.ServiceType,
    prorate?: string,
    prorateValue?: number,
  ) => {
    return {
      type: serviceTypeFamily?.name ?? '',
      sku: serviceType?.name ?? '',
      lineItem:
        serviceTypeFamily?.lineItemTypes.find((lineItem) => lineItem.id === serviceEvent.lineItemTypeID)?.description ??
        '',
      unitPrice: serviceEvent.unitPrice,
      quantity: serviceEvent.priceQuantity,
      prorated: formData.prorate ? prorate : '',
      total: serviceEvent.unitPrice * serviceEvent.priceQuantity * (formData.prorate ? prorateValue ?? 1 : 1),
      taxable: serviceEvent.taxable ? 'Yes' : 'No',
    };
  };

  const getRowsFromFormData = (formData: UniversalServiceFormData) => {
    const rows: Row[] = [];
    formData.serviceGrouping.serviceOrders.forEach((serviceOrder, index) => {
      let serviceTypeFamily: UniversalService.ServiceTypeFamily | undefined = undefined;
      let serviceType: UniversalService.ServiceType | undefined = undefined;
      serviceTypeFamilies.forEach((family) => {
        family.serviceTypes.forEach((type) => {
          if (type.id === serviceOrder.serviceTypeID) {
            serviceTypeFamily = family;
            serviceType = type;
          }
        });
      });
      if (!serviceTypeFamily || !serviceType) {
        return;
      }
      if (!serviceOrder.serviceEvents || serviceOrder.serviceEvents.length === 0) {
        return;
      }
      // Add Recurring Service Events
      if (serviceOrder.subscriptionDetails && typeof serviceOrder.subscriptionDetails !== 'string') {
        if (
          !serviceOrder.subscriptionDetails?.periodicEvents ||
          serviceOrder.subscriptionDetails?.periodicEvents.length === 0
        ) {
          return;
        }

        serviceOrder.subscriptionDetails?.periodicEvents.forEach((serviceEvent) => {
          if (!serviceTypeFamily || !serviceType || serviceEvent.unitPrice === 0) {
            return;
          }
          // this is needed so that the original service event is not mutated
          const event = { ...serviceEvent };
          event.priceQuantity *= formData.quantity[index];
          if (formData.subscriptionDetails.billingFrequencyUnit !== 'month') {
            rows.push(getRowFromServiceEvent(event, serviceTypeFamily, serviceType));
            return;
          }

          const d = daysRemainingInBillingPeriod({
            billingFrequency: formData.subscriptionDetails.billingFrequency,
            billingFrequencyUnit: formData.subscriptionDetails.billingFrequencyUnit,
            billingDay: formData.subscriptionDetails.billingDay,
          });

          rows.push(
            getRowFromServiceEvent(
              event,
              serviceTypeFamily,
              serviceType,
              `${d.daysRemaining}/${d.totalDays}`,
              d.daysRemaining / d.totalDays,
            ),
          );
        });
      }
      // Add One Time Service Events
      serviceOrder.serviceEvents?.forEach((serviceEvent) => {
        if (!serviceTypeFamily || !serviceType || serviceEvent.unitPrice === 0) {
          return;
        }
        const event = { ...serviceEvent };
        event.priceQuantity *= formData.quantity[index];
        rows.push(getRowFromServiceEvent(event, serviceTypeFamily, serviceType));
      });
    });
    return rows;
  };
  const [data, setData] = useState<Row[]>(getRowsFromFormData(formData) ?? []);
  useEffect(() => {
    setData(getRowsFromFormData(formData) ?? []);
  }, [formData]);

  const columnHelper = createColumnHelper<Row>();
  const columns: ColumnDef<Row, any>[] = [
    columnHelper.accessor('type', {
      header: 'Type',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('sku', {
      header: 'SKU',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('lineItem', {
      header: 'Line Item',
      cell: (info) => <div className="whitespace-nowrap">{toTitleCase(info.getValue())}</div>,
    }),
    columnHelper.accessor('unitPrice', {
      header: 'Unit Price',
      cell: (info) => <div className="whitespace-nowrap">{moneyFormatter(info.getValue())}</div>,
    }),
    columnHelper.accessor('prorated', {
      header: 'Prorated',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('quantity', {
      header: 'QTY',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('total', {
      header: 'Total',
      cell: (info) => <div className="whitespace-nowrap">{moneyFormatter(info.getValue())}</div>,
    }),
    columnHelper.accessor('taxable', {
      header: 'Taxable',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
  ];

  useEffect(() => {
    setSubTotal(data.map((row) => row.total).reduce((a, b) => a + b, 0));
    setState(formData.serviceGrouping.serviceOrders[0].serviceLocation?.address.state ?? ''); // All service orders should have the same address
    if (formData.customer?.taxExempt || customer?.taxExempt) {
      setTax(0);
    } else {
      setTax(
        data
          .map((row) => {
            if (row.taxable === 'Yes') {
              return row.total * V1.findTaxRate(state);
            } else {
              return 0;
            }
          })
          .reduce((a, b) => a + b, 0),
      );
    }
    setTotal(subTotal + tax);
  }, [data, subTotal]);

  return (
    <div className={`w-3/4 content-center p-5 mt-10 border${className ? ` ${className}` : ''}`}>
      <TanDataGrid data={data} columns={columns} className="-mt-5 -mb-5 -ml-5 -mr-5" />
      <div className="text-right mt-10">{'Subtotal: ' + moneyFormatter(subTotal)}</div>
      <div className="text-right">{`Tax ${state} (${V1.findTaxRate(state) * 100}%): ${moneyFormatter(tax)}`}</div>
      <div className="text-right">{'Total: ' + moneyFormatter(total)}</div>
    </div>
  );
};
export default TotalTable;
