import React, { useEffect, useRef, useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { US_PHONE_NUMBER_REGEXP, formatE164ToUSPhoneNumber, formatUSPhoneNumberToE164 } from '@wayste/utils';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { channelTypesEnums } from '../../utils';
import routes from '../../utils/routes';
import ContactedForm from './ContactedForm';
import MarketingForm from './MarketingForm';
import LeadForm from './ROLeadForm';
import QuoteForm from './ROQuoteForm';

const leadSchema = Yup.object().shape(
  {
    firstName: Yup.string(),
    lastName: Yup.string(),
    company: Yup.string().nullable(),
    email: Yup.string()
      .email()
      .when(['phoneNumber', 'channel'], {
        is: (phoneNumber, channel) =>
          (!phoneNumber || phoneNumber.length === 0) &&
          channel !== channelTypesEnums.yelp &&
          channel !== channelTypesEnums.thumbtack &&
          channel !== channelTypesEnums.sourgumcomChatWidget &&
          channel !== channelTypesEnums.gmb,
        then: Yup.string().email().required('A phone number or email is required'),
        otherwise: Yup.string().email(),
      }),
    phoneNumber: Yup.string().when(['email', 'channel'], {
      is: (email, channel) =>
        (!email || email.length === 0) &&
        channel !== channelTypesEnums.yelp &&
        channel !== channelTypesEnums.thumbtack &&
        channel !== channelTypesEnums.sourgumcomChatWidget &&
        channel !== channelTypesEnums.gmb,
      then: Yup.string()
        .matches(US_PHONE_NUMBER_REGEXP, 'Must be a valid phone number, e.g. (555) 867-5309')
        .required('A phone number or email is required'),
      otherwise: Yup.string().matches(US_PHONE_NUMBER_REGEXP, 'Must be a valid phone number, e.g. (555) 867-5309'),
    }),
    requestedDeliveryDate: Yup.date().nullable(),
    followUpDate: Yup.date().nullable(),
    customerNotes: Yup.string().nullable(),
    notes: Yup.string(),
    address: Yup.object().shape({
      addressLine1: Yup.string(),
      addressLine2: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      zip: Yup.string().matches(/^\d{5}(-\d{4})?$/, 'Must be a valid zip code'),
    }),
    requestedSize: Yup.string(),
  },
  [['email', 'phoneNumber']],
);

const contactedSchema = Yup.object().shape({
  contactEvents: Yup.array().of(
    Yup.object().shape({
      date: Yup.date().required('A contacted date is required'),
      medium: Yup.string().required('A contacted medium is required'),
    }),
  ),
});

const marketingSchema = Yup.object().shape({
  channel: Yup.string(),
  customerType: Yup.string(),
  searchParams: Yup.string(),
  formUrl: Yup.string(),
});

const quoteSchema = Yup.object().shape({
  quotes: Yup.array(),
});

export interface LeadROFormsProps {
  onCancel: () => void;
  onSubmit: (lead: Customer.AllianceLeadTransport) => void;
  lead?: Customer.AllianceLeadTransport;
  form?: 'all' | 'lead' | 'quote' | 'contacted' | 'marketing';
  leadPhone?: string;
}

const LeadROForms = ({ onCancel, onSubmit, lead, form, leadPhone }: LeadROFormsProps) => {
  const history = useHistory();
  const formRef = useRef<any>();
  const [schema, setSchema] = useState<any>([]);

  useEffect(() => {
    let schemaArray;
    switch (form) {
      case 'quote':
        schemaArray = quoteSchema;
        break;
      case 'lead':
        schemaArray = leadSchema;
        break;
      case 'contacted':
        schemaArray = contactedSchema;
        break;
      case 'marketing':
        schemaArray = marketingSchema;
        break;
      default:
        //@ts-expect-error this needs to be fixed
        schemaArray = leadSchema.concat(contactedSchema).concat(marketingSchema).concat(quoteSchema);
        break;
    }
    setSchema(schemaArray);
  }, [form]);

  useEffect(() => {
    if (leadPhone && formRef.current) {
      formRef.current.setValues({
        ...formRef.current.values,
        phoneNumber: leadPhone,
      });
    }
  }, [leadPhone]);

  const handleSubmit = (data: Customer.AllianceLeadTransport) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    if (!lead) {
      dataCopy.type = 'rollOff';
      if (dataCopy.notes) {
        dataCopy.notesInternal = [
          {
            note: dataCopy.notes,
          },
        ];
      }
    }
    if (dataCopy.phoneNumber) {
      dataCopy.phoneNumber = formatUSPhoneNumberToE164(dataCopy.phoneNumber);
    } else {
      dataCopy.phoneNumber = null;
    }
    if (!dataCopy.address.addressLine2) dataCopy.address.addressLine2 = '';
    dataCopy.serviceLocation = {
      address: dataCopy.address,
      county: dataCopy.county,
      coordinates: dataCopy.coords || null,
    };
    dataCopy.requestedSize = {
      size: +dataCopy.requestedSize,
      type: '',
    };
    dataCopy.email === dataCopy.email || null;
    dataCopy.phoneNumber === dataCopy.phoneNumber || null;
    dataCopy.customerNotes === dataCopy.customerNotes || null;

    onSubmit(dataCopy);
  };

  const prepareFormData = (data: Customer.AllianceLeadTransport) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.lastName = dataCopy.lastName || '';
    dataCopy.company = dataCopy.company || '';
    dataCopy.customerNotes = dataCopy.customerNotes || '';
    dataCopy.notes = dataCopy.notes || '';
    dataCopy.currentHauler = dataCopy.currentHauler || '';
    dataCopy.email = dataCopy.email || '';
    dataCopy.phoneNumber = dataCopy.phoneNumber ? formatE164ToUSPhoneNumber(dataCopy.phoneNumber) : '';
    dataCopy.requestedSize = dataCopy.requestedSize?.size || '';
    dataCopy.address = dataCopy.serviceLocation.address;
    dataCopy.county = dataCopy.serviceLocation.county;
    dataCopy.coords = dataCopy.serviceLocation.coordinates;
    if (form === 'contacted') dataCopy.contactEvents.push({ date: null, medium: '' });
    return dataCopy;
  };

  const handleAddOrder = (quote: any, values: any) => {
    history.push(routes.orders.new, {
      startingValues: {
        ...quote,
        // TODO when updating leads need to pass in ALL contact info and in OrderCreateContainer make sure that it checks ALL contacts for existing customer records
        contacts: [
          {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber ? formatE164ToUSPhoneNumber(values.phoneNumber) : '',
            email: values.email ? values.email.trim() : '',
            department: '',
            primaryContact: true,
            sendBillingEmails: true,
            sendDispatchEmails: true,
          },
        ],
        serviceLocation: {
          address: values.address,
          county: values.county,
          coordinates: values.coords,
        },
        price: quote.priceDollars,
        channel: values.channel || '',
        type: values.customerType || '',
        dumpRateDollars: quote.dumpRateDollars,
        rentExtensionFeeDollars: quote.rentExtensionFeeDollars,
        adjustedRentalPeriod: quote.rentPeriod.value,
        weightLimit: quote.tonLimit,
        expectedSize: quote.size.size,
        overageDollars: quote.overageDollars,
      },
      fromLead: !lead ? 'create' : lead.id,
    });
  };

  return (
    <Formik
      initialValues={
        lead
          ? prepareFormData(lead)
          : {
              firstName: '',
              lastName: '',
              phoneNumber: '',
              email: '',
              address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: '',
              },
              county: '',
              coords: null,
              requestedDeliveryDate: null,
              followUpDate: null,
              customerNotes: '',
              notes: '',
              channel: '',
              customerType: '',
              contactEvents: [
                {
                  date: new Date(),
                  medium: 'phone',
                },
              ],
              searchParams: '',
              quotes: [],
            }
      }
      validationSchema={schema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {(formik) => {
        return (
          <Form>
            {form === 'all' || form === 'quote' ? (
              <QuoteForm
                quotes={formik.values.quotes}
                formik={formik}
                onAddOrder={(quote) => handleAddOrder(quote, formik.values)}
                onGoogleSelect={(res) =>
                  formik.setValues({
                    ...formik.values,
                    //@ts-expect-error this needs to be fixed when date types are switched
                    address: res.address,
                    coords: res.coords,
                    county: res.county,
                  })
                }
                startingValues={lead ? lead.serviceLocation : null}
              />
            ) : null}
            {form === 'all' || form === 'lead' ? <LeadForm formik={formik} lead={lead} /> : null}
            {form === 'all' || form === 'contacted' ? <ContactedForm formik={formik} /> : null}
            {form === 'all' || form === 'marketing' ? <MarketingForm formik={formik} /> : null}
            <div
              style={{
                display: 'flex',
                padding: '25px 0 55px',
                justifyContent: 'flex-end',
              }}
            >
              <button className="mr-5 btn-dark-grey-outlined" onClick={onCancel} type="button">
                Cancel
              </button>
              <button
                className="btn-primary"
                onClick={() => handleSubmit(formik.values)}
                type="button"
                disabled={
                  !formik.dirty ||
                  !formik.isValid ||
                  (formik.values.quotes.length === 0 &&
                    (!formik.values.requestedSize || !formik.values.requestedMaterial))
                }
              >
                Save
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LeadROForms;
