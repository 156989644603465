import mapboxgl from 'mapbox-gl';

export function addFeatureToMap(
  map: mapboxgl.Map,
  sourceID: string,
  geoJson: GeoJSON.Feature<GeoJSON.GeometryObject>,
  listeners: Partial<
    Record<
      keyof mapboxgl.MapLayerEventType,
      (
        e: mapboxgl.MapMouseEvent & {
          features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData,
      ) => void
    >
  > = {},
): string {
  map.addSource(sourceID, {
    type: 'geojson',
    data: geoJson,
  });

  // Add a new layer to visualize the polygon.
  map.addLayer({
    id: 'fill' + sourceID,
    type: 'fill',
    source: sourceID, // reference the data source
    layout: {},
    paint: {
      'fill-color': '#0080ff', // blue color fill
      'fill-opacity': 0.5,
    },
  });
  // Add a black outline around the polygon.
  map.addLayer({
    id: 'outline' + sourceID,
    type: 'line',
    source: sourceID,
    layout: {},
    paint: {
      'line-color': '#000',
      'line-width': 2,
    },
  });

  map.on('mouseenter', 'fill' + sourceID, (e: any) => {
    map.setPaintProperty('fill' + sourceID, 'fill-opacity', 0.7);

    if (listeners.mouseenter) {
      listeners.mouseenter(e);
    }
  });

  map.on('mouseleave', 'fill' + sourceID, (e: any) => {
    map.getCanvas().style.cursor = '';
    map.setPaintProperty('fill' + sourceID, 'fill-opacity', 0.5);

    if (listeners.mouseleave) {
      listeners.mouseleave(e);
    }
  });

  map.on('click', 'fill' + sourceID, (e: any) => {
    if (listeners.click) {
      listeners.click(e);
    }
  });

  map.on('mousemove', 'fill' + sourceID, (e: any) => {
    if (listeners.mousemove) {
      listeners.mousemove(e);
    }
  });

  return sourceID;
}
