import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { buildAddressString, formatE164ToUSPhoneNumber } from '@wayste/utils';
import { PencilIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { UIContext } from '../../contexts';
import Dialog from '../Dialog';
import { LeadPTForms } from '../LeadForms';
import CardInfoList from '../ui/CardInfoList';
import ContactedDetails from './ContactedDetails';
import InternalNotes from './InternalNotes';
import MarketingDetails from './MarketingDetails';

export interface LeadPTDetailsProps {
  rawLead: Customer.AllianceLeadTransport;
}

const LeadPTDetails = ({ rawLead }: LeadPTDetailsProps) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);

  // STATE
  const [lead, setLead] = useState<Customer.AllianceLeadTransport>(rawLead);
  const [updateForm, setUpdateForm] = useState<any>(null);

  //////////////////////////////////////////////
  // HOOKS SECTION
  //////////////////////////////////////////////

  useEffect(() => {
    if (!rawLead) return;
    setLead(rawLead);
  }, [rawLead]);

  //////////////////////////////////////////////
  // FUNCTION SECTION
  //////////////////////////////////////////////

  const handleUpdateSubmit = async (leadObj: any) => {
    const form: any = updateForm;

    setUpdateForm(null);
    switch (form) {
      case 'contacted':
        for (const event of leadObj.contactEvents) {
          if (!event.id) {
            try {
              await client.customer().adminPortal.leads.contactEvent.create(lead.id, event);
              showFlash('Contact Event Successfully Updated', 'success');
            } catch (error) {
              showFlash('Error Updating Contact Event', 'warning');
            }
          }
        }
        break;
      default:
        try {
          await client.customer().adminPortal.leads.update(lead.id, leadObj);
          showFlash('Lead Successfully Updated', 'success');
        } catch (error) {
          showFlash('Error Updating Lead', 'warning');
        }
        break;
    }
  };

  //////////////////////////////////////////////
  // RENDER SECTION
  //////////////////////////////////////////////

  if (!lead) return <div>Lead does not exist</div>;

  const leadColOne = [
    { label: 'Name', value: `${lead.firstName} ${lead.lastName}` },
    { label: 'Company', value: lead.company },
    { label: 'Email', value: lead.email },
    {
      label: 'Phone',
      value: lead.phoneNumber ? formatE164ToUSPhoneNumber(lead.phoneNumber) : null,
    },
  ];

  const leadColTwo = [
    {
      label: 'Location',
      value: lead?.serviceLocation?.address ? buildAddressString(lead?.serviceLocation?.address) : null,
    },
    { label: 'County', value: lead?.serviceLocation?.county },
  ];

  const leadColThree = [
    {
      label: 'Start Date',
      value: lead.requestedStartDate ? format(new Date(lead.requestedStartDate), 'MM/dd/yy') : '',
    },
    {
      label: 'Follow Up Date',
      value: lead.followUpDate ? format(new Date(lead.followUpDate), 'MM/dd/yy') : '',
    },
    { label: 'Cus Notes', value: lead.customerNotes },
  ];

  return (
    <div className="w-full mx-auto max-w-7xl">
      <div className="bg-white rounded shadow-dark px-4 py-1.5 mb-5">
        <div className="flex justify-between items-center">
          <h6 className="text-sm font-medium">Lead Details</h6>
          <button className="btn-primary" onClick={() => setUpdateForm('lead')}>
            <PencilIcon className="h-4 w-4 mr-1" />
            Edit
          </button>
        </div>
        <hr className="my-2 -mx-4" />
        <div className={`gap-4 grid grid-cols-1 lg:grid-cols-3`}>
          <CardInfoList data={leadColOne} containerSize={4} border />
          <CardInfoList data={leadColTwo} containerSize={4} border />
          <CardInfoList data={leadColThree} containerSize={4} />
          <div className="border-t flex lg:col-span-3 pt-4">
            <div className="text-gray-500 mr-5">Notes</div>
            <div>
              <InternalNotes notesInternal={lead.notesInternal} collection={'leads'} docId={lead.id} />
            </div>
          </div>
        </div>
      </div>
      <ContactedDetails onActionClick={setUpdateForm} lead={lead} />
      <MarketingDetails onActionClick={setUpdateForm} lead={lead} />
      <Dialog
        open={Boolean(updateForm)}
        className="max-w-6xl"
        styledTitle="Update Lead"
        onClose={() => setUpdateForm(null)}
      >
        {updateForm ? (
          <LeadPTForms
            onCancel={() => setUpdateForm(null)}
            onSubmit={handleUpdateSubmit}
            lead={lead}
            form={updateForm}
          />
        ) : null}
      </Dialog>
    </div>
  );
};

export default LeadPTDetails;
