import { memo } from 'react';
import { Invoice } from '@alliance-disposal/transport-types';
import { GridRenderCellParams } from '@mui/x-data-grid';

interface GridCellStatusProps {
  value: Invoice.InvoiceStatus | 'NONE';
  width?: number;
}

export const GridCellStatus = memo(function GridCellStatus(props: GridCellStatusProps) {
  switch (props.value) {
    case 'DRAFT':
      return (
        <div className="inline-flex items-center h-6 px-2.5 rounded-full text-xs font-medium bg-white text-[#6b7280] border-2 border-[#e0e0e0] border-dashed">
          Draft
        </div>
      );
    case 'PAST_DUE':
      return (
        <div className="inline-flex items-center h-6 px-2.5 rounded-full text-xs font-medium bg-error-light text-error-dark">
          Past due
        </div>
      );
    case 'PAID':
      return (
        <div className="inline-flex items-center h-6 px-2.5 rounded-full text-xs font-medium bg-[#d7f7c2] text-[#05690d]">
          Paid
        </div>
      );
    case 'NONE':
      return (
        <div className="inline-flex items-center h-6 px-2.5 rounded-full text-xs font-medium bg-white text-[#05690d] border-2 border-[#e0e0e0]">
          None
        </div>
      );
    default:
      return (
        <div className="inline-flex items-center h-6 px-2.5 rounded-full text-xs font-medium bg-[#cff5f6] text-[#0055bc]">
          Open
        </div>
      );
  }
});

function renderCellReceivablesStatus(params: GridRenderCellParams<Invoice.InvoiceStatus>) {
  return <GridCellStatus value={params.value || 'PENDING'} width={params.colDef.computedWidth} />;
}

export default renderCellReceivablesStatus;
