import { useContext, useState } from 'react';
import { Button, Dialog, Textarea } from '@wayste/sour-ui';
import { sendDynamicEmail } from '../../axios/sendgrid';
import { UIContext } from '../../contexts';

export interface EmailSendDialogProps {
  open: boolean;
  onClose: () => void;
  onEmailSent: () => void;
  message: string;
  preText?: string;
  emailData: any;
}

const EmailSendDialog = ({ open, onClose, onEmailSent, message, preText, emailData }: EmailSendDialogProps) => {
  const { showFlash } = useContext(UIContext);
  const [isLoading, setIsLoading] = useState(false);
  const [localMessage, setLocalMessage] = useState(message);

  const handleSubmit = async () => {
    setIsLoading(true);

    emailData.custom_message = localMessage;
    try {
      const response = await sendDynamicEmail(emailData);
      setIsLoading(false);
      console.log(response);
      if (response.status === 'error') {
        showFlash('An error occurred, check the console', 'warning');
        return;
      }
      showFlash('Email Sent!', 'success');
      onEmailSent();
    } catch (error) {
      showFlash('An error occurred, check the console', 'warning');
      return;
    }
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <div className="text-xl mb-4">Compose Email</div>
      <div style={{ minHeight: 400 }}>
        <div className="flex flex-col gap-4">
          <div>
            Send to: <b>{emailData?.toEmail}</b>
          </div>
          {preText ? <div>{preText}</div> : null}
          <div>
            <Textarea
              label="Custom message"
              inputProps={{
                value: localMessage,
                onChange: (e) => setLocalMessage(e.target.value),
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4 justify-end">
        <button className="btn-dark-grey-outlined" onClick={onClose} type="button">
          Cancel
        </button>
        <Button className="btn-primary" onClick={() => handleSubmit()} type="button" loading={isLoading}>
          Send
        </Button>
      </div>
    </Dialog>
  );
};

export default EmailSendDialog;
