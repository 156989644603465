import React from 'react';
import { Loading as SourLoading } from '@wayste/sour-ui';

const Loading = ({ fullScreen }: { fullScreen?: boolean }) => {
  return (
    <div
      className={
        fullScreen
          ? 'fixed top-0 bottom-0 right-0 left-0 bg-black z-[1300] bg-opacity-50 backdrop-blur-sm'
          : 'container p-6 mx-auto'
      }
    >
      <SourLoading size="h-12 w-12" className="absolute top-0 bottom-0 left-0 right-0 m-auto" />
    </div>
  );
};

export default Loading;
