import { Hauler } from '@alliance-disposal/transport-types';
import Container from '@mui/material/Container';
import { VendorForm } from '../HaulerForm';

type Props = {
  onBackButtonClick: () => void;
  onSubmit: (data: Hauler.HaulerWithAapDataUpdate) => Promise<void>;
  isLoading: boolean;
};

/**
 * Component to Create an item in the haulers table
 * @param {Function} onBackButtonClick Function on modal close
 * @param {Boolean} open Controls if modal is visible
 * @param {Function} onSubmit Function on update
 * @param {Boolean} isLoading Replaces submit and cancel with a loading indicator
 */
const HaulerCreate = ({ onBackButtonClick, onSubmit, isLoading }: Props) => {
  return (
    <Container disableGutters style={{ backgroundColor: '#FFFFFF' }}>
      {/* <Toolbar className="bg-sourgum-greyblue-900 text-white">
        <Typography variant="h6">Create Hauler</Typography>
      </Toolbar> */}

      <Container maxWidth="sm">
        <VendorForm onSubmit={onSubmit} onCancel={onBackButtonClick} isLoading={isLoading} />
      </Container>
    </Container>
  );
};

export default HaulerCreate;
