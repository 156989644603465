import React, { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { Button, Dialog, Textarea } from '@wayste/sour-ui';
import { format } from 'date-fns';
import { UIContext, UserContext } from '../../contexts';

const dateFormat = 'EEE MM/dd/yy hh:mm';

interface Props {
  collection: string;
  docId: string;
  notesInternal: Customer.AllianceLeadInternalNote[];
  hideAddButton?: boolean;
}

const defaultProps = {
  hideAddButton: false,
};

const InternalNotes = ({ collection, docId, hideAddButton, notesInternal }: Props) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const { userIDToNameMap } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setNewNote('');
    setShowAdd(false);
  };

  const handleNewNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewNote(event.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const newItem: Customer.AllianceLeadInternalNoteCreateTransport = {
      note: newNote,
    };
    if (collection === 'leads') {
      try {
        await client.customer().adminPortal.leads.internalNote.create(docId, newItem);
        showFlash('Note added', 'success');
      } catch (error) {
        showFlash('failed to add note', 'error');
      } finally {
        setIsLoading(false);
      }
    } else {
      showFlash('Collection does not exist', 'error');
    }
    setShowAdd(false);
    setNewNote('');
    setIsLoading(false);
  };

  return (
    <>
      <div>
        {notesInternal.map((note, index) => (
          <div style={{ marginBottom: index !== notesInternal.length - 1 ? 7 : 0 }} key={note.timestamp}>
            <div style={{ marginBottom: 3 }}>
              {format(new Date(note.timestamp), dateFormat)}
              {' - '}
              {userIDToNameMap[note?.userID]}
            </div>
            {note.note}
          </div>
        ))}
        {hideAddButton ? null : (
          <button className="btn-primary-text-only py-1 px-2.5" onClick={() => setOpen(true)}>
            Add New Note
          </button>
        )}
      </div>
      <Dialog open={open} onClose={handleClose} styledTitle="Internal Notes" className="max-w-3xl">
        <div className="w-full overflow-x-auto">
          <table className="w-full border-collapse border-spacing-0 text-sm">
            <thead>
              <tr className="[&>*]:p-4 border-b">
                <td className="w-48">Date</td>
                <td className="w-48">User</td>
                <td>Note</td>
              </tr>
            </thead>
            <tbody>
              {notesInternal.map((note) => (
                <tr key={note.timestamp} className="[&>*]:p-4 [&>*]:border-b">
                  <td className="w-48">{format(new Date(note.timestamp), dateFormat)}</td>
                  <td className="w-48">{userIDToNameMap[note?.userID]}</td>
                  <td>{note.note}</td>
                </tr>
              ))}
              {showAdd ? (
                <tr>
                  <td colSpan={3} className="text-right p-4 border-b">
                    <Textarea
                      label="New Note"
                      inputProps={{
                        value: newNote,
                        onChange: (e) => handleNewNoteChange(e),
                      }}
                    />
                    <button
                      className="btn-primary-text-only mt-2"
                      onClick={handleSubmit}
                      disabled={!newNote}
                      type="button"
                    >
                      Save
                    </button>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
        {!showAdd ? (
          <Button className="btn-primary mt-4" onClick={() => setShowAdd(true)} loading={isLoading}>
            Add New Note
          </Button>
        ) : null}
        <div className="flex justify-end mt-4">
          <button className="btn-dark-grey-outlined" onClick={handleClose} type="button">
            Close
          </button>
        </div>
      </Dialog>
    </>
  );
};

InternalNotes.defaultProps = defaultProps;

export default InternalNotes;
