import React from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';

type Props = {
  showFlash: boolean;
  flashStyle?: 'warning' | 'success' | 'error' | 'info' | '';
  flashMessageText: string;
};

const defaultProps = {
  flashStyle: null,
};

const FlashMessage = ({ showFlash, flashStyle, flashMessageText }: Props) => {
  const getStyle = () => {
    switch (flashStyle) {
      case 'error':
      case 'warning':
        return (
          <div className="bg-delete text-white flex items-center px-4 py-3.5 text-sm rounded min-w-[288px] shadow-xl">
            <ExclamationTriangleIcon className="h-6 w-6 mr-2" />
            {flashMessageText}
          </div>
        );
      case 'success':
        return (
          <div className="bg-success text-white flex items-center px-4 py-3.5 text-sm rounded min-w-[288px] shadow-xl">
            <CheckCircleIcon className="h-6 w-6 mr-2" />
            {flashMessageText}
          </div>
        );
      default:
        return (
          <div className="bg-black text-white flex items-center px-4 py-3.5 text-sm rounded min-w-[288px] shadow-xl">
            {flashMessageText}
          </div>
        );
    }
  };

  return (
    <Transition
      show={showFlash}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed top-6 right-6 left-auto z-[1400] justify-end items-center">{getStyle()}</div>
    </Transition>
  );
};

FlashMessage.defaultProps = defaultProps;

export default FlashMessage;
