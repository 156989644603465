import React, { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { Loading } from '@wayste/sour-ui';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import {
  // OrderStatus,
  allianceHaulerID,
} from '../../utils/shared-types';

const QBUpload = () => {
  const waysteClient = useWaysteClient();

  const [csvData, setCSVData] = useState<any[]>([]);
  // const [batchData, setBatchData] = useState<any[]>([]);
  // const [batchPayableReceivable, setBatchPayableReceivable] = useState<any[]>([]);
  const [needManualReview, setNeedManualReview] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [
    isComplete,
    // setIsComplete
  ] = useState(false);

  const qbStatus = (payceivable: Invoice.PayableTransport) => {
    const noPaymentsSynced =
      payceivable.invoiceDetails.payments.length === 0 ||
      payceivable.invoiceDetails.payments.every((payment) => !payment.syncedWithAccounting);
    const noRefundSynced =
      payceivable.invoiceDetails.refunds.length > 0 ||
      payceivable.invoiceDetails.refunds.every((refund) => !refund.syncedWithAccounting);

    if (!payceivable.invoiceDetails.syncedWithAccounting && noPaymentsSynced && noRefundSynced) {
      return 'none';
    }

    const allPaymentsSynced =
      payceivable.invoiceDetails.payments.length > 0 &&
      payceivable.invoiceDetails.payments.every((payment) => payment.syncedWithAccounting);
    const allRefundsSynced = payceivable.invoiceDetails.refunds.every((refund) => refund.syncedWithAccounting);

    if (payceivable.invoiceDetails.syncedWithAccounting && allPaymentsSynced && allRefundsSynced) {
      return 'complete';
    }
    return 'partial';
  };

  const getOrdersWithInvoice = async (orderData: Order.AllianceOrderTransport[]) => {
    const orderIds = orderData.map((order) => order.id);

    const receivables = await waysteClient.invoice().adminPortal.receivable.query({
      orderID: orderIds.join(','),
    });

    const payables = await waysteClient.invoice().adminPortal.payable.query({
      orderID: orderIds.join(','),
    });

    const mapped = orderData.flatMap((order) => {
      const orderReceivables = receivables.filter((receivable) => receivable.invoiceDetails.orderID === order.id);
      const orderPayables = payables.filter((payable) => payable.invoiceDetails.orderID === order.id);
      return {
        ...order,
        receivables: orderReceivables,
        bills: orderPayables,
      };
    });

    return mapped as (Order.AllianceOrderTransport & {
      receivables: Invoice.ReceivableTransport[];
      bills: Invoice.PayableTransport[];
    })[];
  };

  const convertToQBNamingConventionBills = (item: any) => {
    let qbItem = item;
    switch (item) {
      case 'dump':
        qbItem = 'Dump';
        break;
      case 'haul':
        qbItem = 'Haul';
        break;
      case 'rentalExtension':
        qbItem = 'Rental Extension';
        break;
      case 'portaPotty':
        qbItem = 'Portable Toilets Expense';
        break;
      case 'tripCharge':
        qbItem = 'Trip Charge';
        break;
      case 'liveLoad':
        qbItem = 'Live Load';
        break;
      case 'contamination':
      case 'overfill':
      case 'surcharge':
      case 'extraFeeItem':
      case 'dumpsterLiner':
      case 'frozenLoad':
      case 'labor':
        qbItem = 'Additional Fee(s)';
        break;
      default:
        break;
    }
    return qbItem;
  };

  const createBillItems = (bill: Invoice.PayableTransport, order: Order.AllianceOrderTransport) => {
    console.log('createBillItems: ', bill, order);
    const csvLineItems: any[] = [];
    bill.invoiceDetails.lineItems.forEach((item: any) => {
      csvLineItems.push({
        type: 'Bill',
        date: bill.invoiceDetails.metadata?.createdAt
          ? format(new Date(bill.invoiceDetails.metadata?.createdAt), 'MM/dd/yyyy')
          : 'MISSING CREATED',
        order_created_date: format(new Date(order.metadata.createdAt), 'MM/dd/yyyy'),
        vendor: bill.vendorName,
        refNumber: `${order.orderNumber} - ${bill.invoiceDetails.invoiceNumber}`,
        expensesAccount: convertToQBNamingConventionBills(item.itemName),
        expensesAmount: item.totalPriceDollars,
        qbStatus: qbStatus(bill),
        paidInFull: bill.invoiceDetails.paidInFull,
        syncedWithQB: bill.invoiceDetails.syncedWithAccounting,
        paymentDate: bill.invoiceDetails.payments[0]
          ? format(new Date(bill.invoiceDetails.payments[0]?.date), 'MM/dd/yy')
          : '',
        paidWith: bill.invoiceDetails.payments[0] ? bill.invoiceDetails.payments[0]?.paymentMethod : '',
        invoiceID: bill.id,
      });
    });
    return csvLineItems;
  };

  const createCSVSheet = async () => {
    setIsLoading(true);
    const needReview: any[] = [];
    const csvLineItems: any[] = [];
    // const batchUpdate: any[] = [];
    const payableReceivableUpdates = [];
    let haulers: any[] = [];
    const haulerResponse = await waysteClient.vendorService().fetch();
    haulers = haulerResponse.data;

    // const response = await queryOrders({ closed: false }, 5000);

    let orders = [];
    try {
      const response = await waysteClient.order().adminPortal.query({
        closed: false,
        limit: 5000,
      });
      orders = await getOrdersWithInvoice(response.results);
    } catch (error) {
      console.log('createCSVSheet error: ', error);
      return;
    }

    for (const order of orders as (Order.AllianceOrderTransport & {
      receivables: Invoice.ReceivableTransport[];
      bills: Invoice.PayableTransport[];
    })[]) {
      // const customerData = await fetchCustomer(order.customerID);
      // order.customer = customerData.data;
      // order.invoices.forEach((invoice) => {
      //   if (qbStatus(invoice) === 'complete') return;
      //   // Remove Refund line items
      //   invoice.lineItems = invoice.lineItems.filter((item) => item.itemName !== 'Refund');
      //   if (order.status === OrderStatus.CANCELLED && invoice.payments.length === 0) return;
      //   if (!order.haulerID) {
      //     needReview.push({
      //       type: 'invoice',
      //       reason: 'Hauler Not Assigned',
      //       order,
      //       invoice,
      //     });
      //     return;
      //   }
      //   if (invoice.refunds.length > 0) {
      //     needReview.push({
      //       type: 'invoice',
      //       reason: 'Invoice Refund',
      //       order,
      //       invoice,
      //     });
      //     return;
      //   }
      //   if (invoice.payments.length > 1) {
      //     needReview.push({
      //       type: 'invoice',
      //       reason: 'Multiple Payments',
      //       order,
      //       invoice,
      //     });
      //     return;
      //   }
      // });
      order.bills.forEach((bill: Invoice.PayableTransport, billIndex: any) => {
        if (bill.invoiceDetails.syncedWithAccounting || bill.invoiceDetails.lineItems.length === 0) return;
        const haulerForBill = haulers.find((hauler) => hauler.id === bill.haulerID);
        bill.vendorName = haulerForBill.name; // haulername is not on the bill
        // If !qbBill && paid
        // Bill and Bill Payment
        // If qbBill && paid
        // Bill Payment
        // If !qbBill && !paid
        // Bill

        const isRefund = bill.invoiceDetails.lineItems.some((item) => item.itemName === 'refund');

        if (isRefund) {
          needReview.push({ type: 'bill', reason: 'Bill Refund', order, bill });
          return;
        }

        if (order.haulerID === allianceHaulerID) {
          needReview.push({
            type: 'bill',
            reason: 'Alliance Bill',
            order,
            bill,
          });
          return;
        }
        if (bill.invoiceDetails.payments.length > 1) {
          needReview.push({
            type: 'bill',
            reason: 'Multiple Payments',
            order,
            bill,
          });
          return;
        }

        // Bill and Bill Payment
        const billItems = createBillItems(bill, order);
        billItems.forEach((item) => csvLineItems.push(item));
        payableReceivableUpdates.push({
          type: 'payable',
          data: { ...bill, syncedWithAccounting: true },
        });
        order.bills[billIndex].invoiceDetails.syncedWithAccounting = true;
      });
      // End Order For Each Loop
    }
    setNeedManualReview(needReview);
    console.log('Need Review: ', needReview);
    console.log(csvLineItems);
    setCSVData(csvLineItems);
    // setBatchData(batchUpdate);
    // setBatchPayableReceivable(payableReceivableUpdates);
    setIsLoading(false);
  };

  // const handleBatchUpdate = async () => {
  //   console.log(batchData);
  //   console.log(batchPayableReceivable);
  //   setIsLoading(true);
  //   await asyncForEach(batchPayableReceivable, async (update) => {
  //     if (update.type === 'payable') {
  //       try {
  //         const response = await updatePayable(update.data);
  //       } catch (error) {
  //         console.log('handleBatchUpdate updatePayable response: ', error);
  //         alert('An error occurred');
  //       }
  //     } else if (update.type === 'payment') {
  //       const response = await updatePayment(update.data.invoiceId, update.data.paymentId, update.data.payment);
  //       if (response?.status === 'error') {
  //         console.log('handleBatchUpdate updatePayment response: ', response);
  //         alert('An error occurred');
  //       }
  //     } else {
  //       console.warn('missing update type for: ', update);
  //     }
  //   });
  //   await asyncForEach(batchData, async (orderUpdate) => {
  //     const response = await updateOrder(orderUpdate);
  //     if (response.status === 'error') {
  //       console.log('handleBatchUpdate updateOrder response: ', response);
  //       alert('An error occurred');
  //     }
  //   });
  //   setIsLoading(false);
  //   setIsComplete(true);
  // };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          flex: 1,
        }}
      >
        <div>
          <div style={{ marginBottom: 30 }}>
            <Loading />
          </div>
          <div>
            <h6 className="text-lg">Please Wait. Do Not Close this Tab</h6>
          </div>
        </div>
      </div>
    );
  }

  if (isComplete) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <div>
          <h5 className="text-xl">Download Complete! All Invoices Have Been Marked as Uploaded to QB</h5>
        </div>
        {needManualReview.length > 0 ? (
          <div>
            <h6 className="text-lg">Un-Uploaded Invoices and Bills</h6>
            <div>
              {needManualReview.map((item) => (
                <div key={item?.order?.orderNumber + item?.invoice?.invoiceNumber}>
                  {item?.type === 'invoice'
                    ? `${item?.order?.orderNumber} - ${item?.invoice?.invoiceNumber} Reason: ${item?.reason}`
                    : `${item?.order?.orderNumber} - ${item?.bill?.bill} Reason: ${item?.reason}`}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  // const tempFun2 = async () => {
  //   setIsLoading(true);
  //   // close everything prior to 13000
  //   const response = await queryOrders({ closed: false }, 5000);
  //   if (response.status === 'error') {
  //     alert('An error occurred get AAP Dev');
  //     return;
  //   }
  //   const orders = response.data;
  //   const downloadData: any[] = [];
  //   for (const order of orders) {
  //     console.log(order);
  //     const customerData = await fetchCustomer(order.customerID);
  //     order.customer = customerData?.data;

  //     order.invoices.forEach((invoice: any) => {
  //       // Remove Refund line items
  //       invoice.lineItems = invoice.lineItems.filter((item: any) => item.itemName !== 'Refund');
  //       if ((order.status === OrderStatus.CANCELLED && invoice.payments.length === 0) || invoice.syncedWithAccounting) {
  //         return;
  //       }
  //       downloadData.push({
  //         hasRefunds: invoice.refunds.length > 0 ? 'yes' : 'no',
  //         totalDollars: invoice.totalDollars,
  //         createdAt: invoice?.metadata?.createdAt ? format(new Date(invoice.metadata.createdAt), 'MM/dd/yy') : '',
  //         issueDate: invoice.issueDate ? format(new Date(invoice.issueDate), 'MM/dd/yy') : '',
  //         invoiceNumber: `${order.orderNumber}-${invoice.invoiceNumber}`,
  //         name: `${order.customer.primaryContact.lastName}, ${order.customer.primaryContact.firstName}`,
  //         company: order.customer.companyName || '',
  //         orderID: order.id,
  //         invoiceID: invoice.id,
  //         paidInFull: invoice.paidInFull,
  //         status: order.status,
  //         orderLastUpdated: order?.metadata?.lastUpdatedAt
  //           ? format(new Date(order.metadata.lastUpdatedAt), 'MM/dd/yy')
  //           : '',
  //         paymentDate:
  //           invoice.paidInFull && invoice.payments[0]?.date
  //             ? format(new Date(invoice.payments[0]?.date), 'MM/dd/yy')
  //             : '',
  //       });
  //     });
  //   }
  //   setCSVData(downloadData);
  //   setIsLoading(false);
  // };

  return (
    <div className="p-4">
      <div style={{ marginBottom: 30 }}>
        <button className="btn-primary" onClick={createCSVSheet}>
          Generate QB Upload File
        </button>
      </div>
      {csvData.length === 0 ? (
        'Get Data First to Generate Upload File'
      ) : (
        <CSVLink data={csvData}>Download QB Upload File &amp; Update Database</CSVLink>
      )}
      <div style={{ marginTop: 30 }}>
        <b>IMPORTANT:</b> Once you download the file, all of the invoices and bills within the download will be marked
        as uploaded to QB. This cannot be undone automatically.
      </div>
    </div>
  );
};

export default QBUpload;
