/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { type Invoice, MaterialLabels, type Order } from '@alliance-disposal/transport-types';
import { SourFilters, SourSearch, SourSearchResponse, SourSearchWrapper } from '@wayste/sour-search';
import { formatISODateString, formatServiceAddress, getRouterPath } from '@wayste/utils';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { Alert, Drawer, Snackbar } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuButton, MenuItem } from '../../components/Menu';
import OrderStatusChanger from '../../components/OrderStatusChanger/OrderStatusChanger';
import PayablesMakePayment from '../../components/PayablesMakePayment';
import PayablesReview from '../../components/PayablesReview';
import { UIContext } from '../../contexts';
import { useAuthToken } from '../../hooks/authhook';
import { routes } from '../../utils';
import { getOrderBillStatus2, getOrderInvoiceStatus } from '../../utils/invoice-utils';
import BulkInvoiceModal from './components/BulkInvoiceModal';
import InvoiceSummaryModal from './components/InvoiceSummaryModal';
import InvoicesDetails from './components/InvoicesDetails';
import PayoutSummaryModal from './components/PayoutSummaryModal';
import renderCellReceivablesStatus from './components/renderCellReceivablesStatus';

interface AllianceOrderWithReceivables extends Order.AllianceOrderTransport {
  receivables: Invoice.ReceivableTransport[];
  payables: Invoice.PayableTransport[];
}

function BillingPage() {
  const { token } = useAuthToken();
  const { showFlash } = useContext(UIContext);
  const location = useLocation();
  const history = useHistory();
  const [showInvoiceDrawer, setShowInvoiceDrawer] = useState(false);

  const [openInvoicesLoading, setOpenInvoicesLoading] = useState(true);
  const [invoicesCount, setInvoicesCount] = useState(0);
  const [openInvoices, setOpenInvoices] = useState<AllianceOrderWithReceivables[]>([]);

  const client = useWaysteClient();
  const [filterData, setFilterData] = useState<Order.AllianceOrderTransport[]>([]);
  const [page, setPage] = useState(0);
  const [selectedOrderId, setSelectedOrderId] = useState('');
  const [pageSize, setPageSize] = useState<number>(25);
  const [searchActive, setSearchActive] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchResultsCount, setSearchResultsCount] = useState<number>(0);
  const [openBulkInvoice, setOpenBulkInvoice] = useState(false);
  const [openInvoiceSummary, setOpenInvoiceSummary] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [snackConfig, setSnackConfig] = useState<{
    text: string;
    style: 'success' | 'error';
    open: boolean;
  }>({
    text: '',
    style: 'success',
    open: false,
  });
  const [showPayablesPay, setShowPayablesPay] = useState(false);
  const [showPayablesReview, setShowPayablesReview] = useState(false);
  const [showPayoutSummary, setShowPayoutSummary] = useState(false);
  ///////////////////// FOR SOUR SEARCH /////////////////////
  const defaultFilter = 'sourgum-order';
  const [isDefaultFilter, setIsDefaultFilter] = useState<boolean | undefined>(true);
  const [searchPage, setSearchPage] = useState<number>(0);
  const [searchPageSize, setSearchPageSize] = useState<number>(25);

  const onNavigate = (entity: any, recordID: string, name: string) => {
    const path = getRouterPath(name, recordID, routes);
    history.push(path);
  };

  const processHits = async (hits: SourSearchResponse['results']['hits']['hits']) => {
    const results: SourSearchResponse['results']['hits']['hits'][0]['_source'][] = hits.map((hit) => hit._source);
    const ids = results.map((result: any) => result.id);

    // This prevents the query from running if there are no ids
    // which would try to fetch all invoices
    if (!ids.length) return [];

    const receivable = await client.invoice().adminPortal.receivable.query({
      orderID: ids.join(','),
    });

    const receivablesMap: Record<string, Invoice.ReceivableTransport[]> = {};

    receivable.forEach((receivable: Invoice.ReceivableTransport) => {
      //@ts-ignore
      if (receivablesMap[receivable.invoiceDetails.orderID]) {
        //@ts-ignore
        receivablesMap[receivable.invoiceDetails.orderID].push(receivable);
      } else {
        //@ts-ignore
        receivablesMap[receivable.invoiceDetails.orderID] = [receivable];
      }
    });

    const Payables = await client.invoice().adminPortal.payable.query({
      orderID: ids.join(','),
    });

    const payablesMap: Record<string, Invoice.PayableTransport[]> = {};

    Payables.forEach((payable: Invoice.PayableTransport) => {
      //@ts-ignore
      if (payablesMap[payable.invoiceDetails.orderID]) {
        //@ts-ignore
        payablesMap[payable.invoiceDetails.orderID].push(payable);
      } else {
        //@ts-ignore
        payablesMap[payable.invoiceDetails.orderID] = [payable];
      }
    });
    const processed = await processOrdersForSearchTable(results, receivablesMap, payablesMap);
    return processed;
  };

  // DEFINE ON RESULTS LOGIC
  const handleSearchResults = async (response: SourSearchResponse) => {
    setSearchLoading(true);
    if (searchPage !== response.page) setSearchPage(response.page);
    response.type === defaultFilter ? setIsDefaultFilter(true) : setIsDefaultFilter(false);
    const res = await processHits(response.results.hits.hits);
    setSearchResultsCount(response.totalHits);
    setSearchResults(res);

    setSearchLoading(false);
  };

  const onActiveSearch = (active: boolean) => {
    setSearchActive(active);
  };

  const onActiveFilter = (active: boolean) => {
    setFilterActive(active);
  };

  ///////////////////////////////////////////////

  const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackConfig({ open: false, text: '', style: 'success' });
  };

  const openSnackbar = (text: string, style: 'success' | 'error') => {
    setSnackConfig({ open: true, text, style });
  };

  useEffect(() => {
    const subscription = client.order().adminPortal.subscription.subscribeToOpenInvoices(
      {
        query: {
          status: [
            'NEEDS_REVIEW',
            'UNASSIGNED',
            'ON_HOLD',
            'ASSIGNED',
            'DELIVERED',
            'READY_FULL_PICKUP',
            'READY_EMPTY_PICKUP',
            'PICKED_UP_FULL',
            'PICKED_UP_EMPTY',
            'DUMPED',
            'COMPLETED',
          ],
          closed: false,
        },
        sortBy: 'orderNumber',
        sortDescending: true,
        limit: pageSize,
        offset: (page || 0) * pageSize,
      },
      (response: { status: string; data: any; count: number }) => {
        if (response.status === 'error') {
          showFlash('An Error Occurred Getting Orders', 'warning');
          setOpenInvoicesLoading(false);
        }
        getOrdersWithInvoice(response.data).then((orders) => {
          setOpenInvoices(orders);
          setOpenInvoicesLoading(false);
          setInvoicesCount(response.count);
        });
      },
    );

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [page, pageSize]);

  const checkQueryParams = () => {
    if (location.search.includes('?id=')) {
      const searchParam = location.search;
      const str = searchParam.substring(searchParam.lastIndexOf('=') + 1);
      const dirtyString = str.indexOf('/');
      const orderId = str.substring(0, dirtyString !== -1 ? dirtyString : str.length);
      setSelectedOrderId(orderId);
      setShowInvoiceDrawer(true);
    } else {
      setSelectedOrderId('');
      setShowInvoiceDrawer(false);
    }
  };

  useEffect(() => {
    checkQueryParams();
  }, [location.search]);

  const handleGetDelInvoices = () => {
    axios
      .get(`${import.meta.env.VITE_BASE_API_URL}/reports/invoice/delinquent?output=CSV`, {
        headers: {
          Authorization: token,
        },
        responseType: 'blob', // important
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'delinquent-invoices.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((e: Error) => {
        openSnackbar('Something went wrong.' + e.message, 'error');
      });
    handleCloseSnack();
    openSnackbar('This may take a minute, you can keep using AAP in the meantime.', 'success');
  };

  const processOrdersForTable = async (
    passedOrders: (Order.AllianceOrderTransport & {
      receivables: Invoice.ReceivableTransport[];
      payables: Invoice.PayableTransport[];
    })[],
  ) => {
    const results = passedOrders.map((order) => ({
      ...order,
      qb: order.receivables.every((inv) => inv?.invoiceDetails?.paidInFull || inv?.invoiceDetails?.void),
      receivablesStatus: getOrderInvoiceStatus(order),
      payablesStatus: getOrderBillStatus2(order),
      serviceAddress: formatServiceAddress(order.serviceLocation.address),
      customerDisplayName: order.customerCompanyName || order.customerName,
    }));

    return results;
  };

  const processOrdersForSearchTable = async (
    passedOrders: any[],
    receivablesMap: Record<string, Invoice.ReceivableTransport[]>,
    payablesMap: Record<string, Invoice.PayableTransport[]>,
  ) => {
    const results = passedOrders.map((order) => {
      const orderExtended = {
        ...order,
        receivables: receivablesMap[order.id] || [],
        payables: payablesMap[order.id] || [],
      };
      return {
        ...orderExtended,
        receivablesStatus: getOrderInvoiceStatus(orderExtended),
        payablesStatus: getOrderBillStatus2(orderExtended),
        serviceAddress: formatServiceAddress(orderExtended.serviceLocation.address),
        customerDisplayName: order.customerCompanyName || order.customerName,
      };
    });
    return await Promise.all(results);
  };

  const getOrdersWithInvoice = async (orderData: Order.AllianceOrderTransport[]) => {
    const orderIds = orderData.map((order: Order.AllianceOrderTransport) => order.id);

    if (orderIds.length === 0) return [];

    const receivables = await client.invoice().adminPortal.receivable.query({
      orderID: orderIds.join(','),
    });

    const payables = await client.invoice().adminPortal.payable.query({
      orderID: orderIds.join(','),
    });

    const mapped = orderData.flatMap((order: Order.AllianceOrderTransport) => {
      const orderReceivables = receivables.filter((receivable) => receivable.invoiceDetails.orderID === order.id);
      const orderPayables = payables.filter((payable) => payable.invoiceDetails.orderID === order.id);
      return {
        ...order,
        receivables: orderReceivables,
        payables: orderPayables,
      };
    });

    return mapped;
  };

  useEffect(() => {
    (async () => {
      processOrdersForTable(openInvoices.map((item) => item)).then((processedOrders) => {
        setFilterData(processedOrders);
      });
    })();
  }, [openInvoices]);

  ///////////////////////////////////////////////
  // TABLE SCHEMA
  ///////////////////////////////////////////////

  const columns: GridColDef[] = [
    {
      field: 'orderNumber',
      headerName: 'Order #',
      width: 70,
      type: 'string',
      align: 'center',
    },
    { field: 'qb', headerName: 'QB', width: 20, type: 'boolean', hide: true },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      renderCell: (value) => <OrderStatusChanger order={value.row} navigateOnly={true} className="!h-6" />,
      width: 115,
    },

    {
      field: 'receivablesStatus',
      headerName: 'Receivables',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      renderCell: renderCellReceivablesStatus,
      width: 100,
    },
    {
      field: 'payablesStatus',
      headerName: 'Payables',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      renderCell: renderCellReceivablesStatus,
      width: 90,
    },
    {
      field: 'customerDisplayName',
      headerName: 'Customer',
      type: 'string',
      width: 200,
    },
    {
      field: 'serviceAddress',
      headerName: 'Address',
      type: 'string',
      width: 300,
    },
    {
      field: 'vendorName',
      headerName: 'Hauler',
      type: 'string',
      width: 200,
    },
    {
      field: 'expectedDeliveryDate',
      headerName: 'Delivery Date',
      type: 'date',
      align: 'center',
      headerAlign: 'center',
      valueGetter: ({ value }) => {
        if (!value) return null;
        return formatISODateString(value);
      },

      width: 100,
    },
    {
      field: 'expectedPickupDate',
      headerName: 'Pickup Date',
      type: 'date',
      align: 'center',
      headerAlign: 'center',
      valueGetter: ({ value }) => {
        if (!value) return null;
        return formatISODateString(value);
      },
      width: 100,
    },
    {
      field: 'material',
      headerName: 'Material',
      type: 'string',
      cellClassName: 'text-xs',
      valueGetter: ({ value }) => {
        return MaterialLabels[value as keyof typeof MaterialLabels];
      },
      width: 150,
    },
    {
      field: 'actualWeightDumped',
      headerName: 'Tons',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      valueGetter: ({ value }) => {
        return value?.value || value?.value === 0 ? value.value : null;
      },
      width: 75,
    },
  ];

  ///////////////////////////////////////////////
  // RENDER
  ///////////////////////////////////////////////

  return (
    <div className="bg-white shadow-dark rounded m-2 flex-1 flex">
      <BulkInvoiceModal open={openBulkInvoice} handleClose={() => setOpenBulkInvoice(false)} />
      <InvoiceSummaryModal open={openInvoiceSummary} handleClose={() => setOpenInvoiceSummary(false)} />
      <PayoutSummaryModal open={showPayoutSummary} onClose={() => setShowPayoutSummary(false)} />
      <PayablesReview open={showPayablesReview} onClose={() => setShowPayablesReview(false)} />
      <PayablesMakePayment open={showPayablesPay} onClose={() => setShowPayablesPay(false)} />
      <div className="container mx-auto p-2 flex-1">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: 0,
            padding: 0,
            alignItems: 'end',
          }}
        >
          <h1 className="text-2xl">Billing</h1>
          <MenuButton className="btn-icon !p-0 h-6 w-6" buttonItem={<EllipsisHorizontalIcon className="h-6 w-6" />}>
            <MenuItem onClick={handleGetDelInvoices}>Delinquent Invoices Report</MenuItem>
            <MenuItem onClick={() => setOpenBulkInvoice(true)}>Bulk Invoices Download</MenuItem>
            <MenuItem
              onClick={() => {
                setOpenInvoiceSummary(true);
              }}
            >
              Invoice Summary
            </MenuItem>
            <MenuItem onClick={() => setShowPayoutSummary(true)}>Payout Summary</MenuItem>
            <MenuItem onClick={() => setShowPayablesReview(true)}>Review All Bills</MenuItem>
            <MenuItem onClick={() => setShowPayablesPay(true)}>Pay All Bills</MenuItem>
          </MenuButton>
        </div>
        <br />
        <div className="py-2 w-full flex flex-row justify-between item-center">
          <SourSearchWrapper
            options={{
              application: 'aap',
              apiKey: import.meta.env.VITE_ELASTIC_KEY as string,
              environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
            }}
            onNavigate={onNavigate}
            onResults={handleSearchResults}
            onSearch={onActiveSearch}
            onFilter={onActiveFilter}
            defaultFilters={{
              query: {
                type: defaultFilter,
              },
            }}
            size={searchPageSize}
            page={searchPage}
            createQueryParams={{ method: 'with_filter', removeOn: 'empty_string_inactive_filter' }}
          >
            <div className="flex flex-row justify-between space-x-4 w-full">
              <SourSearch
                options={{
                  searchPopoverFixed: false,
                  showTips: !isDefaultFilter,
                  showMessages: !isDefaultFilter,
                  showResults: !isDefaultFilter,
                  placeholder: 'Search Orders',
                }}
              />
              <SourFilters />
            </div>
          </SourSearchWrapper>
        </div>
        <div className="w-full h-[85%]">
          <DataGrid
            rows={searchActive || filterActive ? searchResults : filterData}
            rowCount={searchActive ? searchResultsCount : invoicesCount}
            rowHeight={30}
            columns={columns}
            pageSize={searchActive || filterActive ? searchPageSize : pageSize}
            loading={searchActive || filterActive ? searchLoading : openInvoicesLoading}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
              setSearchPageSize(newPageSize);
            }}
            onPageChange={(newPage) => {
              setPage(newPage);
              setSearchPage(newPage);
            }}
            disableSelectionOnClick
            disableColumnSelector
            rowsPerPageOptions={[25, 50, 100]}
            onCellClick={(p, e) => {
              if (p.field === 'status') {
                e.stopPropagation();
                return;
              }
              history.push({
                pathname: routes.billing.list,
                search: `?id=${String(p.row.id)}`,
              });
              setShowInvoiceDrawer(true);
            }}
            page={searchActive || filterActive ? searchPage : page}
            pagination
            paginationMode={searchActive || filterActive ? 'server' : 'server'}
          />
        </div>
      </div>
      <Drawer
        sx={{
          width: '90%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '90%',
            boxSizing: 'border-box',
          },
          zIndex: 40,
        }}
        variant="temporary"
        open={showInvoiceDrawer}
        anchor="right"
        onClose={() => {
          setShowInvoiceDrawer(!showInvoiceDrawer);
          history.push({ pathname: routes.billing.list });
        }}
        disableEnforceFocus
      >
        <InvoicesDetails orderID={selectedOrderId} />
      </Drawer>
      <Snackbar open={snackConfig.open} autoHideDuration={3000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={snackConfig.style} sx={{ width: '100%' }}>
          {snackConfig.text}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default BillingPage;
