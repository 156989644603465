import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { buildAddressString, formatUSD, moneyFormatter } from '@wayste/utils';
import { formatE164ToUSPhoneNumber } from '@wayste/utils';
import { DevicePhoneMobileIcon, PaperAirplaneIcon, PencilIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import { createLeadEmailMaterialString } from '../../utils/email-utils';
import routes from '../../utils/routes';
import {
  materials as allMaterials,
  leadStatusTypesEnums,
  mainSite,
  mediumTypesEnums,
  priceTypesEnums,
} from '../../utils/shared-types';
import Dialog from '../Dialog';
import { LeadROForms } from '../LeadForms';
import CardInfoList from '../ui/CardInfoList';
import ContactedDetails from './ContactedDetails';
import EmailSendDialog from './EmailSendDialog';
import InternalNotes from './InternalNotes';
import MarketingDetails from './MarketingDetails';
import SMSSendDialog from './SMSSendDialog';

const materials = { ...allMaterials, other: 'Other' };

const quoteHeadings = ['Size', 'Material', 'Ton Limit', 'Overage Fee', 'Rental Period', 'Price Quoted', ''];

export interface LeadRODetailsProps {
  rawLead: Customer.AllianceLeadTransport;
}

const LeadRODetails = ({ rawLead }: LeadRODetailsProps) => {
  const history = useHistory();
  const { showFlash } = useContext(UIContext);
  const client = useWaysteClient();
  const userProfile = client.user().get();

  // STATE
  const [lead, setLead] = useState<Customer.AllianceLeadTransport>(rawLead);
  const [updateForm, setUpdateForm] = useState<any>(null);
  const [sendingRejection, setSendingRejection] = useState<boolean>(false);
  const [quoteSend, setQuoteSend] = useState<any>({
    open: false,
    to: '',
    message: '',
    preText: null,
    data: null,
  });
  const [smsSend, setSMSSend] = useState({
    open: false,
    toNumber: '',
    message: '',
  });

  //////////////////////////////////////////////
  // HOOKS SECTION
  //////////////////////////////////////////////

  useEffect(() => {
    if (!rawLead) return;
    setLead(rawLead);
  }, [rawLead]);

  //////////////////////////////////////////////
  // FUNCTION SECTION
  //////////////////////////////////////////////

  const handleSendQuote = async (quote: any) => {
    let webSaleUrl = null;
    if (lead.quotes.every((item: any) => item.pricingExists)) {
      try {
        const accessTokenResponse = await client.customer().adminPortal.leads.generateToken(lead.id);
        webSaleUrl = accessTokenResponse?.key;
      } catch (error) {
        console.error(error);
      }
    }
    const materialString = materials[quote.material as keyof typeof materials];
    const areaText =
      `${lead?.serviceLocation?.address.city}, ${lead?.serviceLocation?.address.state}` +
      (lead?.serviceLocation?.address.zip ? ` ${lead?.serviceLocation.address.zip}` : '');
    const specialMaterialString = createLeadEmailMaterialString(quote.material);
    const data = {
      first_name: lead.firstName,
      csa_name: userProfile.firstName,
      dumpster_size: quote.size.size,
      material: materialString,
      service_location: areaText,
      quote_url: webSaleUrl,
      quote_total: quote.priceDollars,
      ton_limit: quote.tonLimit, // ternary was quote.priceType === priceTypesEnums.ton
      rental_period: quote.rentPeriod?.value,
      overage_fee: quote.overageDollars,
      rental_extension_fee: quote.rentExtensionFeeDollars,
      max_rental_days: quote.maxRentalDaysAllowed,
      special_material_string: specialMaterialString,
      custom_message: null,
      emailTemplate: 'LEAD_QUOTE_RO',
      toEmail: lead.email,
      price_type: quote.priceType,
    };

    setQuoteSend({
      ...quoteSend,
      open: true,
      to: lead.email || '',
      data,
      preText: (
        <span>
          You can enter a custom message below. It is optional to add, you don't need to add one. If you enter a custom
          message it will be inserted as below.
          <br />
          <br />
          Hi {data.first_name},<br />
          This is {data.csa_name} from Sourgum Waste. ENTER CUSTOM MESSAGE HERE. Here is the quote you requested:
          <br />
          ...quote details follow.
        </span>
      ),
    });
  };

  const handleSendSMSQuote = async (quote: any) => {
    let webSaleUrl = null;
    if (lead.quotes.every((item: any) => item.pricingExists)) {
      try {
        const accessTokenResponse = await client.customer().adminPortal.leads.generateToken(lead.id);
        webSaleUrl = accessTokenResponse?.key;
      } catch (error) {
        console.error(error);
      }
    }
    const materialString = materials[quote.material as keyof typeof materials];
    const areaText =
      `${lead?.serviceLocation?.address.city}, ${lead?.serviceLocation?.address.state}` +
      (lead?.serviceLocation?.address.zip ? ` ${lead?.serviceLocation.address.zip}` : '');
    setSMSSend({
      open: true,
      toNumber: lead.phoneNumber || '',
      message: `Hi ${lead.firstName}, your quote from Sourgum Waste is ready!\n\nDumpster size: ${
        quote.size.size
      } yard\nWaste material: ${materialString}\nService location: ${areaText}\nAll-inclusive price: ${formatUSD(
        quote.priceDollars,
      )}\n\nThis includes all taxes and fees,${quote.tonLimit ? ` a ${quote.tonLimit} ton weight limit` : ''} and a ${
        quote.rentPeriod?.value
      } day rental period. ${
        webSaleUrl
          ? `Order now: https://www.sourgum.com/checkout/?quote_id=${webSaleUrl}`
          : 'Call us at (855) 706-1176 to order.'
      }`,
    });
  };

  const handleSendFollowUp = async (quote: any) => {
    let webSaleUrl = null;
    if (lead.quotes.every((item: any) => item.pricingExists)) {
      try {
        const accessTokenResponse = await client.customer().adminPortal.leads.generateToken(lead.id);
        webSaleUrl = accessTokenResponse?.key;
      } catch (error) {
        console.error(error);
      }
    }

    const data = {
      first_name: lead.firstName,
      csa_name: userProfile.firstName,
      dumpster_size: quote.size.size,
      quote_total: quote.priceDollars,
      quote_url: webSaleUrl,
      custom_message: null,
      emailTemplate: 'LEAD_FOLLOW_UP_RO',
      toEmail: lead.email,
    };
    setQuoteSend({
      ...quoteSend,
      open: true,
      to: lead.email || '',
      data,
      preText: (
        <span>
          You can enter a custom message below. It is optional to add, you don't need to add one. If you enter a custom
          message it will be inserted as below.
          <br />
          <br />
          Hi {data.first_name},<br />
          I hope all is well. We just wanted to check in and see if you were still interested in renting a dumpster?
          <br />
          <br />
          We can offer a {data.dumpster_size} dumpster for ${data.quote_total}, all taxes and fees included.
          <br />
          <br />
          ENTER CUSTOM MESSAGE HERE.
          <br />
          <br />
          ...quote details follow.
        </span>
      ),
    });
  };

  const handleSendDoNotService = () => {
    const areaText = lead?.serviceLocation?.address.city
      ? `${lead.serviceLocation.address.city}${
          lead.serviceLocation.address.state ? `, ${lead.serviceLocation.address.state}` : ''
        }`
      : 'your area';
    const data = {
      emailTemplate: 'DO_NOT_SERVICE_AREA',
      first_name: lead.firstName,
      csa_name: userProfile.firstName,
      toEmail: lead.email,
      service_location: areaText,
      service_type: 'roll-off dumpster rentals',
    };

    setQuoteSend({
      ...quoteSend,
      open: true,
      to: lead.email || '',
      data,
      preText: (
        <span>
          You can enter a custom message below. It is optional to add, you don't need to add one. If you enter a custom
          message it will be inserted as below.
          <br />
          <br />
          Hi {data.first_name},<br />
          Unfortunately, we do not currently service {data.service_location} with {data.service_type}, but it is
          definitely in our growth road map!
          <br />
          <br />
          ENTER CUSTOM MESSAGE HERE.
          <br />
          <br />
          As <b>America's First Haulsourcing Platform</b>, we make booking dumpster rentals easier than easy. And we
          want to roll it out for you sooner.
          <br />
          <br />
          ...more stuff.
        </span>
      ),
    });
  };

  const handleSendQuoteClose = () => {
    setQuoteSend({
      open: false,
      to: '',
      message: '',
      preText: null,
    });
  };

  const handleSendSMSClose = () => {
    setSMSSend({
      open: false,
      toNumber: '',
      message: '',
    });
  };

  const handleCopyQuoteToClipboard = async (quote: any) => {
    let webSaleUrl = 'You can always call us Monday to Sunday 7am to 7pm at (732) 366-9355.\n\n';

    if (lead.quotes.every((item: any) => item.pricingExists)) {
      try {
        const accessTokenResponse = await client.customer().adminPortal.leads.generateToken(lead.id);
        webSaleUrl = `You can also order online by going to https://${mainSite}/checkout/?quote_id=${accessTokenResponse?.key}\n\n`;
      } catch (error) {
        console.error(error);
      }
    }
    const materialString = materials[quote.material as keyof typeof materials].toLowerCase();
    const specialMaterialString = createLeadEmailMaterialString(quote.material);
    const areaText =
      `${lead?.serviceLocation?.address.city}, ${lead?.serviceLocation?.address.state}` +
      (lead?.serviceLocation?.address.zip ? ` ${lead?.serviceLocation?.address.zip}` : '');
    const message =
      `Hi ${lead.firstName},\n\n` +
      `This is ${userProfile.firstName} with Sourgum Waste. We can provide you with a ${quote.size.size} yard dumpster for ${materialString} delivered to ${areaText} for $${quote.priceDollars}\n\n` +
      `That price includes all taxes and fees,${
        quote.priceType === priceTypesEnums.ton ? ` a ${quote.tonLimit} ton weight limit,` : ''
      } and up to a ${quote.rentPeriod?.value} day rental period.\n\n` +
      `${
        quote.priceType === priceTypesEnums.ton
          ? `So long as you do not go over the weight limit, that is the final price you'll pay. If the weight of your debris does go over the weight limit there is an overage fee of ${quote.overageDollars} per additional ton, prorated to your exact overage weight.\n\n`
          : ''
      }` +
      `You can keep the dumpster for the full ${
        quote.rentPeriod?.value
      } day rental period or call anytime before for an early pickup.${
        +quote.rentExtensionFeeDollars
          ? ` If you need the dumpster for longer you can extend for $${
              quote.rentExtensionFeeDollars
            } per additional day. Please note there is a ${
              quote.maxRentalDaysAllowed || '30'
            } day maximum on all rental extensions, ordering a dump and return resets this limit.`
          : ''
      }\n\n` +
      `${specialMaterialString ? `${specialMaterialString}\n\n` : ''}` +
      `If you would like to schedule your dumpster rental, or if you have any questions, please let me know and I'll be happy to help you.\n\n` +
      webSaleUrl +
      `Thank you,\n\n` +
      `${userProfile.firstName}`;
    return navigator.clipboard.writeText(message);
  };

  const handleAddOrder = (quote: any) => {
    history.push(routes.orders.new, {
      startingValues: {
        ...quote,
        id: null,
        serviceLocation: {
          address: lead?.serviceLocation?.address,
          county: lead?.serviceLocation?.county,
          coordinates: lead?.serviceLocation?.coordinates,
        },
        price: quote.priceDollars,
        dumpRateDollars: quote.dumpRateDollars,
        rentExtensionFeeDollars: quote.rentExtensionFeeDollars,
        adjustedRentalPeriod: quote.rentPeriod.value,
        weightLimit: quote.tonLimit,
        expectedSize: quote.size.size,
        overageDollars: quote.overageDollars,
        channel: lead.channel || '',
        type: lead.customerType || '',
        // TODO when updating leads need to pass in ALL contact info and in OrderCreateContainer make sure that it checks ALL contacts for existing customer records
        contacts: [
          {
            firstName: lead.firstName || '',
            lastName: lead.lastName || '',
            phoneNumber: lead.phoneNumber ? formatE164ToUSPhoneNumber(lead.phoneNumber) : '',
            email: lead.email ? lead.email.trim() : '',
            department: '',
            primaryContact: true,
            sendBillingEmails: true,
            sendDispatchEmails: true,
          },
        ],
      },
      fromLead: lead.id,
    });
  };

  const handleUpdateSubmit = async (leadObj: any) => {
    const form = updateForm;

    setUpdateForm(null);
    switch (form) {
      case 'quote':
        for (const quote of leadObj.quotes) {
          if (!quote.id) {
            try {
              await client.customer().adminPortal.leads.quote.create(lead.id, quote);
              showFlash('Lead Successfully Updated', 'success');
            } catch (error) {
              showFlash('Error Updating Lead', 'warning');
            }
          }
        }
        break;
      case 'contacted':
        for (const event of leadObj.contactEvents) {
          if (!event.id) {
            try {
              await client.customer().adminPortal.leads.contactEvent.create(lead.id, event);
              showFlash('Event Successfully Updated', 'success');
            } catch (error) {
              showFlash('Error Updating Event', 'warning');
            }
          }
        }
        break;
      default:
        try {
          const { id, ...leadObjWithoutId } = leadObj;
          await client.customer().adminPortal.leads.update(lead.id, leadObjWithoutId);
          showFlash('Lead Successfully Updated', 'success');
        } catch (error) {
          showFlash('Error Updating Lead', 'warning');
        }
        break;
    }
  };

  const handleEmailSent = async () => {
    handleSendQuoteClose();
    setUpdateForm(null);

    if (!lead.email) {
      alert('No email sent. Lead does not have an email');
      setSendingRejection(false);
      return;
    }
    if (sendingRejection) {
      try {
        await client.customer().adminPortal.leads.update(lead.id, {
          status: 'lost',
          reasonLost: 'outOfArea',
          reasonLostNote: '',
        });
        showFlash('Lead Successfully Updated', 'success');
      } catch (error) {
        showFlash('Error Updating Lead', 'warning');
      }
    }

    const event = {
      date: new Date().toISOString(),
      medium: mediumTypesEnums.email,
    };

    try {
      await client.customer().adminPortal.leads.contactEvent.create(lead.id, event);
      showFlash('Event Successfully Updated', 'success');
    } catch (error) {
      showFlash('Error Updating Event', 'warning');
    }

    setSendingRejection(false);
  };

  const handleSMSSent = async () => {
    handleSendSMSClose();

    if (!lead.phoneNumber) {
      alert('No SMS sent. Lead does not have a phone number');
      return;
    }

    const event = {
      date: new Date().toISOString(),
      medium: mediumTypesEnums.sms,
    };
    try {
      await client.customer().adminPortal.leads.contactEvent.create(lead.id, event);
      showFlash('Event Successfully Updated', 'success');
    } catch (error) {
      showFlash('Error Updating Event', 'warning');
    }
  };

  const handleRemoveQuote = async (quoteID: string) => {
    try {
      await client.customer().adminPortal.leads.quote.delete(lead.id, quoteID);
      showFlash('Quote Successfully Deleted', 'success');
    } catch (error) {
      console.error(error);
      showFlash('Error Deleting Quote', 'warning');
    }
  };

  const onDoNotServiceArea = () => {
    setSendingRejection(true);
    handleSendDoNotService();
  };

  //////////////////////////////////////////////
  // RENDER SECTION
  //////////////////////////////////////////////

  if (!lead) return <div>Lead does not exist</div>;

  const showPreferredContact = (value: 'EMAIL' | 'PHONE' | 'TEXT' | null | undefined) => {
    if (value === 'EMAIL') return 'Email';
    if (value === 'PHONE') return 'Phone';

    return null;
  };

  const leadColOne = [
    { label: 'Name', value: `${lead.firstName} ${lead.lastName}` },
    { label: 'Company', value: lead.company },
    { label: 'Email', value: lead.email },
    {
      label: 'Phone',
      value: lead.phoneNumber ? formatE164ToUSPhoneNumber(lead.phoneNumber) : null,
    },
    {
      label: 'Preferred Method',
      value: showPreferredContact(lead?.preferredContactMethod),
    },
  ];

  const leadColTwo = [
    {
      label: 'Location',
      value: lead?.serviceLocation?.address ? buildAddressString(lead.serviceLocation.address) : null,
    },
    { label: 'County', value: lead?.serviceLocation?.county },
    { label: 'Size', value: lead.requestedSize?.size || null },
    {
      label: 'Material',
      value: lead.requestedMaterial ? materials[lead.requestedMaterial as keyof typeof materials] : '',
    },
  ];

  const leadColThree = [
    {
      label: 'Delivery Date',
      value: lead.requestedDeliveryDate ? format(new Date(lead.requestedDeliveryDate), 'MM/dd/yy') : '',
    },
    {
      label: 'Follow up Date',
      value: lead.followUpDate ? format(new Date(lead.followUpDate), 'MM/dd/yy') : '',
    },
    {
      label: 'Existing Cus',
      value: lead.allianceCustomer ? (
        <Link to={routes.customers.details(lead.allianceCustomer.id)}>Go to customer record</Link>
      ) : null,
    },
    { label: 'Cus Notes', value: lead.customerNotes },
  ];

  return (
    <div>
      <div className="bg-white rounded shadow-dark px-4 py-1.5 mb-5">
        <div className="flex justify-between items-center">
          <h6 className="text-xl font-medium">Lead Details</h6>
          <button className="btn-primary" onClick={() => setUpdateForm('lead')}>
            <PencilIcon className="h-4 w-4 mr-1" />
            Edit
          </button>
        </div>
        <hr className="my-2 -mx-4" />
        <div className={`gap-4 grid grid-cols-1 lg:grid-cols-3`}>
          <CardInfoList data={leadColOne} containerSize={4} border />
          <CardInfoList data={leadColTwo} containerSize={4} border />
          <CardInfoList data={leadColThree} containerSize={4} />
          <div className="border-t flex lg:col-span-3 pt-4">
            <div className="text-gray-500 mr-5">Notes</div>
            <div>
              <InternalNotes notesInternal={lead.notesInternal} collection={'leads'} docId={lead.id} />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white rounded shadow-dark py-1.5 mb-5">
        <div className="px-4">
          <div className="flex justify-between items-center">
            <h6 className="text-xl font-medium">Quote Details</h6>
            <button className="ml-auto mr-5 btn-dark-grey-outlined" onClick={() => onDoNotServiceArea()}>
              Don't Service Area
            </button>
            <button className="btn-primary" onClick={() => setUpdateForm('quote')}>
              <PencilIcon className="h-4 w-4 mr-1" />
              Edit
            </button>
          </div>
        </div>
        <hr className="mt-2" />
        <div className="w-full overflow-x-auto -mb-1.5">
          <table className="w-full text-sm border-collapse border-spacing-0">
            <thead>
              <tr className="[&>*]:whitespace-nowrap [&>*]:px-4 [&>*]:py-1.5 bg-gray-100 border-b">
                {quoteHeadings.map((item) => (
                  <td key={item}>{item}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {lead.quotes.map((item: any) => (
                <tr key={item.id} className={`[&>*]:px-4 [&>*]:py-1.5 border-b ${item.active ? '' : 'bg-disabled'}`}>
                  <td>{`${item.size.size} YD`}</td>
                  <td>{materials[item.material as keyof typeof materials]}</td>
                  <td>{item.priceType === priceTypesEnums.ton ? `${item.tonLimit} tons` : 'N/A'}</td>
                  <td>{item.priceType === priceTypesEnums.ton ? moneyFormatter(item.overage) : 'N/A'}</td>
                  <td>
                    {item.rentPeriod?.value}
                    {' Days '}
                    {moneyFormatter(item.rentExtensionFee)}
                    {' / extra day'}
                  </td>
                  <td>{moneyFormatter(item.price)}</td>
                  {item.active ? (
                    <td>
                      <div>
                        <button
                          className="btn-primary-text-only px-2 py-1 mb-1"
                          onClick={() => handleAddOrder(item)}
                          type="button"
                        >
                          <PlusIcon className="h-4 w-4 mr-1" />
                          Add Order
                        </button>
                      </div>
                      <div>
                        <button
                          className="btn-secondary-text-only px-2 py-1 mb-1"
                          onClick={() => handleSendQuote(item)}
                          disabled={!lead.email}
                          type="button"
                        >
                          <PaperAirplaneIcon className="h-4 w-4 mr-1" />
                          Send Email Quote
                        </button>
                      </div>
                      <div>
                        <button
                          className="btn-secondary-text-only px-2 py-1 mb-1"
                          onClick={() => handleSendFollowUp(item)}
                          disabled={lead.status !== leadStatusTypesEnums.open || !lead.email}
                          type="button"
                        >
                          Send Email Follow Up
                        </button>
                      </div>
                      <div>
                        <Button
                          className="btn-secondary-text-only px-2 py-1 mb-1"
                          onClick={() => handleSendSMSQuote(item)}
                          disabled={!lead.phoneNumber}
                          startIcon={<DevicePhoneMobileIcon />}
                        >
                          Send SMS Quote
                        </Button>
                      </div>
                      <div>
                        <button
                          className="btn-secondary-text-only px-2 py-1 mb-1"
                          onClick={() => handleCopyQuoteToClipboard(item)}
                          type="button"
                        >
                          Copy to Clipboard
                        </button>
                      </div>
                      <button className="btn-base text-delete" onClick={() => handleRemoveQuote(item.id)} type="button">
                        <XMarkIcon className="h-4 w-4 mr-1" />
                        Remove
                      </button>
                    </td>
                  ) : (
                    <td />
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ContactedDetails onActionClick={setUpdateForm} lead={lead} />
      <MarketingDetails onActionClick={setUpdateForm} lead={lead} />
      <Dialog
        open={Boolean(updateForm)}
        className="max-w-6xl"
        styledTitle="Update Lead"
        onClose={() => setUpdateForm(null)}
      >
        {updateForm ? (
          <LeadROForms
            onCancel={() => setUpdateForm(null)}
            onSubmit={handleUpdateSubmit}
            lead={lead}
            form={updateForm}
          />
        ) : null}
      </Dialog>
      <EmailSendDialog
        open={quoteSend.open}
        message={quoteSend.message}
        onClose={handleSendQuoteClose}
        onEmailSent={handleEmailSent}
        preText={quoteSend.preText}
        emailData={quoteSend.data}
      />
      <SMSSendDialog
        open={smsSend.open}
        message={smsSend.message}
        toNumber={smsSend.toNumber}
        onClose={handleSendSMSClose}
        onSMSSent={handleSMSSent}
      />
    </div>
  );
};

export default LeadRODetails;
