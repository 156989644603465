import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { SourSearchResponse, SourSearchWrapper } from '@wayste/sour-search';
import { SourFilters, SourSearch } from '@wayste/sour-search';
import { getRouterPath } from '@wayste/utils';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import { routes } from '../../utils';
import Loading from '../Loading';
import UniversalServicesListTable from './UniversalServicesListTable';

const UniversalServicesList = () => {
  const client = useWaysteClient();
  const history = useHistory();
  const { showFlash } = useContext(UIContext);
  const searchPageSize = 25;
  const [searchData, setSearchData] = useState<UniversalService.ServiceGrouping[]>([]);
  const [dbData, setDBData] = useState<UniversalService.ServiceGrouping[]>([]);
  const [searchPage, setSearchPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [isDefaultFilter, setIsDefaultFilter] = useState(true);

  ///////////////////////////////////////////////
  // Query DB
  ///////////////////////////////////////////////

  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    fetchServiceGrouping();
  }, [page, pageSize]);

  const fetchServiceGrouping = async () => {
    setIsLoading(true);
    try {
      const query: UniversalService.ServiceGroupingQuery = {
        offset: page * pageSize,
        limit: pageSize,
        reverseSort: true,
      };
      const response = await client.universalService().serviceGrouping.query(query);
      setDBData(response.results);
      setTotalPages(Math.ceil(response.total / pageSize));
      setTotalRecords(response.total);
    } catch (error) {
      console.error(error);
      showFlash('An Error Occurred loading service groupings.', 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  ///////////////////////////////////////////////
  // NEW SEARCH
  ///////////////////////////////////////////////

  const onNavigate = (_: unknown, recordID: string, name: string) => {
    const path = getRouterPath(name, recordID, routes);
    history.push(path);
  };

  const onActiveSearch = (active: boolean) => {
    setSearchActive(active);
  };

  const onActiveFilter = (active: boolean) => {
    setFilterActive(active);
  };

  // THIS NEEDS TO BE SPEED UP
  const processHits = async (hits: SourSearchResponse['results']['hits']['hits']) => {
    const results: SourSearchResponse['results']['hits']['hits'][0]['_source'][] = [];
    hits.map((hit) => {
      results.push(hit._source);
    });
    return results as any[];
  };

  // DEFINE ON RESULTS LOGIC
  const handleSearchResults = async (response: SourSearchResponse) => {
    if (searchPage !== response.page) setSearchPage(response.page);
    response.type === 'sourgum-order' ? setIsDefaultFilter(true) : setIsDefaultFilter(false);
    if (response.type === 'sourgum-order') {
      // Change this
      const res = await processHits(response.results.hits.hits);
      setSearchData(res);
    } else {
      setSearchData([]);
    }
  };

  /* 
  not used yet - will need for search pagination
  
const handleSearchPageChange = (value: number) => {
    setSearchPage(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  }; */

  ///////////////////////////////////////////////
  // END NEW SEARCH
  ///////////////////////////////////////////////

  const onCreateNewClicked = () => {
    history.push(routes.universalServices.new);
  };

  const handleOpenRowDetails = (group: UniversalService.ServiceGrouping) => {
    // if there is more than one service order, go to the subscription details
    history.push(routes.universalServices.serviceGrouping.details(group.id));
  };

  if (isLoading) return <Loading />;

  return (
    <div className="w-full p-6 pt-5">
      <div className="text-2xl pl-2">Orders</div>
      <div className="w-full flex flex-col">
        <SourSearchWrapper
          options={{
            application: 'aap',
            apiKey: import.meta.env.VITE_ELASTIC_KEY,
            environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
          }}
          onNavigate={onNavigate}
          onResults={handleSearchResults}
          onSearch={onActiveSearch}
          onFilter={onActiveFilter}
          createQueryParams={{ method: 'with_filter', removeOn: 'empty_string_inactive_filter' }}
          page={searchPage} // SourSearch is 0-indexed, we are 1-indexed
          size={searchPageSize}
        >
          <div className="mt-4 py-2 flex flex-row w-full">
            <div className="w-full flex flex-row justify-between item-center">
              <div className="flex flex-row justify-between space-x-4 w-full pr-5">
                <SourSearch
                  options={{
                    searchPopoverFixed: false,
                    showTips: true,
                    showMessages: !isDefaultFilter,
                    showResults: !isDefaultFilter,
                    placeholder: 'Search...',
                  }}
                />
              </div>
              <div className="flex flex-1 flex-row space-x-2 h-10">
                <SourFilters />
                <button className="flex-shrink-0 btn-primary px-4 ml-4 h-full" onClick={onCreateNewClicked}>
                  <PlusIcon className="pr-1 h-5 w-5" /> New
                </button>
              </div>
            </div>
          </div>
          <UniversalServicesListTable
            page={page}
            pages={totalPages}
            size={pageSize}
            total={totalRecords}
            setPage={setPage}
            setPageSize={setPageSize}
            serviceGrouping={searchActive || filterActive ? searchData : dbData}
            onRowClicked={handleOpenRowDetails}
            loading={isLoading}
          />
        </SourSearchWrapper>
      </div>
    </div>
  );
};

export default UniversalServicesList;
