import { Customer, Order } from '@alliance-disposal/transport-types';

/* eslint-disable no-sequences */
export const channelTypes = {
  facebookMarketplace: 'Facebook Marketplace',
  facebookPPC: 'Facebook Ad',
  internet: 'Internet Search',
  adwords: 'Google AdWords',
  bingAds: 'Bing Ads',
  referral: 'Referral',
  salesRep: 'Sales rep',
  // salesRepFernando: 'Sales rep - warm - Fernando',
  // salesRepFernandoCold: 'Sales rep - cold - Fernando',
  // salesRepShawnM: 'Sales rep - warm - Shawn M',
  // salesRepShawnMCold: 'Sales rep - cold - Shawn M',
  salesRepMikeB: 'Sales rep - warm - Mike B',
  salesRepMikeBCold: 'Sales rep - cold - Mike B',
  // salesRepMikeC: 'Sales rep - warm - Mike C',
  // salesRepMikeCCold: 'Sales rep - cold - Mike C',
  // salesRepLuciano: 'Sales rep - warm - Lou Smith',
  // salesRepLucianoCold: 'Sales rep - cold - Lou Smith',
  coldOutreach: 'Cold Outreach',
  salesRepWarm: 'Sales rep warm',
  salesRepCold: 'Sales rep cold',
  salesEmailProspecting: 'Sales email prospecting',
  yelp: 'Yelp',
  thumbtack: 'Thumbtack',
  angiLeads: 'Angi Leads',
  craftJack: 'CraftJack',
  sourgumcomChatWidget: 'Sourgum.com Chat Widget',
  gmb: 'Google My Business',
  hometownLocal: 'Hometown Local',
  linkedInAds: 'LinkedIn Ads',
  other: 'Other - Slack Joe & Put in Notes',
  unknown: 'Unknown',
};

export const channelTypesEnums = {
  facebookMarketplace: 'facebookMarketplace',
  facebookPPC: 'facebookPPC',
  internet: 'internet',
  adwords: 'adwords',
  bingAds: 'bingAds',
  referral: 'referral',
  salesRep: 'salesRep',
  salesRepFernando: 'salesRepFernando',
  salesRepShawnM: 'salesRepShawnM',
  salesRepMikeB: 'salesRepMikeB',
  salesRepLuciano: 'salesRepLuciano',
  yelp: 'yelp',
  thumbtack: 'thumbtack',
  angiLeads: 'angiLeads',
  craftJack: 'craftJack',
  sourgumcomChatWidget: 'sourgumcomChatWidget',
  gmb: 'gmb',
  hometownLocal: 'hometownLocal',
  linkedInAds: 'linkedInAds',
  other: 'other',
  unknown: 'unknown',
};

export const durationUnitTypes = {
  DAYS: 'Days',
  HOURS: 'Hours',
  WEEKS: 'Weeks',
};

export const durationUnitEnums = {
  DAYS: 'DAYS',
  HOURS: 'HOURS',
  WEEKS: 'WEEKS',
};

export const operatingDayTypes = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  LIMITED: 'Limited',
  UNKNOWN: 'Unknown',
};

export const growthStageTypes = {
  SEED: 'Seed',
  GROWTH: 'Growth',
  MATURE: 'Mature',
};

export const pricingMetros = {
  NJ: 'NJ',
  DE: 'DE',
  'Philadelphia PA': 'Philadelphia PA',
  'Baltimore MD': 'Baltimore MD',
  DC: 'DC',
  'Jacksonville FL': 'Jacksonville FL',
  'Orlando FL': 'Orlando FL',
  'Tampa FL': 'Tampa FL',
  'Miami FL': 'Miami FL',
  'FL Panhandle': 'FL Panhandle',
  'Virginia Beach VA': 'Virginia Beach VA',
  'Richmond VA': 'Richmond VA',
  'Charlotte NC': 'Charlotte NC',
  'Raleigh-Durham NC': 'Raleigh-Durham NC',
  'Winston-Salem NC': 'Winston-Salem NC',
  'Greenville SC': 'Greenville SC',
  'Columbia SC': 'Columbia SC',
  'Charleston SC': 'Charleston SC',
  'Atlanta GA': 'Atlanta GA',
  'Augusta GA': 'Augusta GA',
  'Athens GA': 'Athens GA',
  'Long Island NY': 'Long Island NY',
  'Pittsburgh PA': 'Pittsburgh PA',
  'No Metro': 'No Metro',
};

export const onlineGuaranteeTypes = {
  '24': 'Same / Next Day',
  '48': '48 Hrs Notice',
  '72': '72 Hrs Notice',
};

/**
 * @deprecated use CustomerTypeLabels instead
 */
export const customerTypes = Customer.customerTypes;

export const customerTypesEnums = Customer.CustomerTypeLabels;

export const customerMotivations = {
  moving: 'Moving',
  sellingHome: 'Selling Home',
  forTenant: 'For Tenant',
  death: 'Death',
  decluttering: 'De-Cluttering',
  renovation: 'Renovation',
  storeClosing: 'Store Closing',
  storeOpening: 'Store Opening',
  warehouseCleanout: 'Warehouse Clean-out',
  other: 'Other - Slack Joe & Put in Notes',
};

export const customerMotivationsEnums = {
  moving: 'moving',
  sellingHome: 'sellingHome',
  forTenant: 'forTenant',
  death: 'death',
  decluttering: 'decluttering',
  renovation: 'renovation',
  storeClosing: 'storeClosing',
  storeOpening: 'storeOpening',
  warehouseCleanout: 'warehouseCleanout',
  other: 'other',
};

export const departmentTypes = {
  primary: 'Primary',
  site: 'Site contact',
  billing: 'Billing',
  other: 'Other (specify in notes)',
};

export const materials = {
  cND: 'Construction & Demolition',
  bulky13: 'Household Items',
  msw: 'MSW',
  bNB: 'Brush & Branch',
  bNL: 'Brush & Logs',
  bNS: 'Brush & Stumps',
  concrete: 'Clean Concrete',
  comix: 'Concrete & Brick Mix',
  tires: 'Tires',
  mattresses: 'Mattresses',
  concreteRebar: 'Concrete With Rebar',
  asphalt: 'Asphalt',
  dirt: 'Dirt',
  scrap: 'Scrap Metal',
  creosote: 'Creosote',
  asbestos: 'Friable Asbestos',
  asbestosNonFriable: 'Non-Friable Asbestos',
  cardboard: 'Cardboard',
  transportation: 'Transportation',
  roofing: 'Asphalt Shingles',
  singleStream: 'Single Stream Recycling',
  other: 'Other',
};

export const materialEnums: { [key: string]: MaterialEnums } = {
  cND: 'cND',
  bulky13: 'bulky13',
  msw: 'msw',
  bNB: 'bNB',
  bNL: 'bNL',
  bNS: 'bNS',
  concrete: 'concrete',
  comix: 'comix',
  tires: 'tires',
  mattresses: 'mattresses',
  concreteRebar: 'concreteRebar',
  asphalt: 'asphalt',
  dirt: 'dirt',
  scrap: 'scrap',
  creosote: 'creosote',
  asbestos: 'asbestos',
  asbestosNonFriable: 'asbestosNonFriable',
  cardboard: 'cardboard',
  transportation: 'transportation',
  roofing: 'roofing',
  other: 'other',
};

export const aggregateMaterialKeys = ['concrete', 'comix', 'concreteRebar', 'asphalt', 'dirt'];

export enum OrderStatus {
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  UNASSIGNED = 'UNASSIGNED',
  ON_HOLD = 'ON_HOLD',
  ASSIGNED = 'ASSIGNED',
  DELIVERED = 'DELIVERED',
  READY_FULL_PICKUP = 'READY_FULL_PICKUP',
  READY_EMPTY_PICKUP = 'READY_EMPTY_PICKUP',
  PICKED_UP_FULL = 'PICKED_UP_FULL',
  PICKED_UP_EMPTY = 'PICKED_UP_EMPTY',
  DUMPED = 'DUMPED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export const OrderStatusText = Order.SourgumOrderStatusLabels;

export const paymentMethods = {
  creditCard: 'Credit / Debit Card',
  check: 'Check',
  ach: 'ACH',
};

export const orderImageTypes = {
  dumpTicket: 'Dump Ticket',
  dumpTicketClean: 'Dump Ticket Clean',
  haulerPayable: 'Invoice from Hauler',
  shareWithAll: 'Share with Hauler & Customer',
  shareWithHauler: 'Share with Hauler Only',
  shareWithCustomer: 'Share with Customer Only',
  other: 'Other',
};

export const customerFileTypes = {
  taxExemptionForm: 'Tax Exemption Form',
  net30Form: 'Net 30 Form',
  businessCard: 'Business Card',
  other: 'Other',
};

export const haulerFileTypes = {
  coi: 'Certificate of Insurance',
  coiOld: 'Certificate of Insurance (Old)',
  pricingSheet: 'Pricing Sheet',
  serviceArea: 'Service Area',
  indemnificationForm: 'Indemnification Form',
  st3: 'ST-3',
  w9: 'W-9',
  other: 'Other',
};

export const paymentMethodsEnums = {
  creditCard: 'creditCard',
  check: 'check',
  stripe: 'stripe',
  accountCredit: 'accountCredit',
  ach: 'ach',
};

export const everyPaymentMethods = {
  creditCard: 'Credit Card',
  check: 'Check',
  stripe: 'Stripe',
  accountCredit: 'Account Credit',
  ach: 'ACH',
};

/**
 * Only to be used for display purposes
 */
export const allPaymentMethods = {
  creditCard: 'Credit / Debit Card',
  check: 'Check',
  stripe: 'Credit / Debit Card',
};

export const paymentTerms = {
  onCharge: 'Before delivery',
  onDump: 'Due on dump',
  net15: 'Net 15',
  net30: 'Net 30',
};

export const paymentTermsEnums = {
  onCharge: 'onCharge',
  onDump: 'onDump',
  net15: 'net15',
  net30: 'net30',
};

export const priceTypes = {
  ton: 'Ton',
  yard: 'Yard',
  flat: 'Flat Rate',
};

/**
 * Price Type options
 * @enum {String}
 */
export const priceTypesEnums = {
  ton: 'ton',
  yard: 'yard',
  flat: 'flat',
};

export const leadTypes = {
  rollOff: 'Roll Off',
  commercial: 'Commercial',
  residential: 'Residential',
  portableToilet: 'Portable Toilet',
};

export const leadTypesEnums = {
  rollOff: 'rollOff',
  commercial: 'commercial',
  residential: 'residential',
  portableToilet: 'portableToilet',
};

export const leadStatusTypes = {
  open: 'Open',
  won: 'Won',
  lost: 'Lost',
};

export type LeadTypes = 'rollOff' | 'commercial' | 'residential';

export type LeadStatus = 'open' | 'won' | 'lost';

export const leadStatusTypesEnums: { [key: string]: LeadStatus } = {
  open: 'open',
  won: 'won',
  lost: 'lost',
};

export const leadReasonsLost = {
  noResponse: 'No Response',
  duplicate: 'Duplicate',
  noAvailability: 'We Had No Availability',
  price: 'We were too expensive',
  diffHauler: 'Went With a Different Hauler',
  noLongerNeedService: 'They No Longer Need Any Service At All',
  outOfArea: 'Out of Service Area',
  rentalPeriodShot: 'Rental Period Too Short',
  badContactDetails: 'Email and phone number invalid',
  unknown: 'Unknown',
  other: 'Other',
};

export const leadReasonsLostEnums = {
  noResponse: 'noResponse',
  noAvailability: 'noAvailability',
  price: 'price',
  diffHauler: 'diffHauler',
  noLongerNeedService: 'noLongerNeedService',
  outOfArea: 'outOfArea',
  unknown: 'unknown',
  other: 'other',
};

export const mediumTypes = {
  phone: 'Phone',
  email: 'Email',
  sms: 'Text',
  sourgumChatWidget: 'Sourgum Chat Widget',
};

export const mediumTypesEnums = {
  phone: 'phone',
  email: 'email',
  sms: 'sms',
  sourgumChatWidget: 'sourgumChatWidget',
};

export const rentalUnits = {
  day: 'day',
  week: 'week',
};

export const rollOffSizes = {
  10: '10',
  15: '15',
  20: '20',
  30: '30',
  40: '40',
  100: '100',
};

export const commercialSizes = {
  64: '64 Gallon',
  96: '96 Gallon',
  1: '1 Yard',
  2: '2 Yard',
  3: '3 Yard',
  4: '4 Yard',
  6: '6 Yard',
  8: '8 Yard',
};

export const residentialSizes = {
  64: '64',
  96: '96',
};

export const commercialMaterials = {
  trash: 'Trash',
  ssr: 'SSR',
  cardboard: 'Cardboard',
  food: 'Food Waste',
  foodOil: 'Organic Oil Waste',
  other: 'Other',
};

export const commercialMaterialsEnums = {
  trash: 'trash',
  ssr: 'ssr',
  cardboard: 'cardboard',
  food: 'food',
  foodOil: 'foodOil',
  other: 'other',
};

export const residentialMaterials = {
  trash: 'Trash',
  ssr: 'SSR',
  cardboard: 'Cardboard',
  other: 'Other',
};

export const residentialMaterialsEnums = {
  trash: 'trash',
  ssr: 'ssr',
  cardboard: 'cardboard',
  other: 'other',
};

export const commercialBillingFrequencies = {
  monthly: 'Monthly',
  quarterly: 'Quarterly',
};

export const commercialBillingFrequenciesEnums = {
  monthly: 'monthly',
  quarterly: 'quarterly',
};

export const residentialBillingFrequencies = {
  monthly: 'Monthly',
  quarterly: 'Quarterly',
};

export const residentialBillingFrequenciesEnums = {
  monthly: 'monthly',
  quarterly: 'quarterly',
};

export const commercialServiceFrequency = {
  week1: '1 x Week',
  week2: '2 x Week',
  week3: '3 x Week',
  week4: '4 x Week',
  week5: '5 x Week',
  eow: 'Every Other Week',
  month1: '1 x Month',
};

export const commercialServiceFrequencyEnums = {
  week1: 'week1',
  week2: 'week2',
  week3: 'week3',
  week4: 'week4',
  week5: 'week5',
  eow: 'eow',
  month1: 'month1',
};

export const residentialServiceFrequency = {
  week1: '1 x Week',
  week2: '2 x Week',
  week3: '3 x Week',
  week4: '4 x Week',
  week5: '5 x Week',
  eow: 'Every Other Week',
};

export const residentialServiceFrequencyEnums = {
  week1: 'week1',
  week2: 'week2',
  week3: 'week3',
  week4: 'week4',
  week5: 'week5',
  eow: 'eow',
};

export const commercialLocationTypes = {
  apartments: 'Apartments/Lodging',
  auto: 'Automotive',
  church: 'Church',
  college: 'College/University',
  entertainment: 'Entertainment Venue',
  grocer: 'Grocery Store',
  gym: 'Gym',
  hotel: 'Hotel',
  k12: 'K-12 School',
  landscape: 'Landscaper',
  manufacturer: 'Manufacturing/Industrial',
  office: 'Office',
  restaurant: 'Restaurant/Bar',
  retail: 'Retail',
  salon: 'Salon/Barber',
  nonProfit: 'Non-Profit',
  other: 'Other - Slack Joe & Put in Notes',
};

export const commercialLocationTypesEnums = {
  apartments: 'apartments',
  auto: 'auto',
  church: 'church',
  college: 'college',
  entertainment: 'entertainment',
  grocer: 'grocer',
  gym: 'gym',
  hotel: 'hotel',
  k12: 'k12',
  landscape: 'landscape',
  manufacturer: 'manufacturer',
  office: 'office',
  restaurant: 'restaurant',
  retail: 'retail',
  salon: 'salon',
  nonProfit: 'nonProfit',
  other: 'other',
};

export const quotedPriceItems = {
  haul: 'QP-Haul',
  dump: 'QP-Dump',
  adj: 'QP-Adj',
  overage: 'Overage',
};

export const haulerChargeItems: { [key: string]: string } = {
  haul: 'Haul',
  dump: 'Dump',
  tripCharge: 'Trip Charge',
  contamination: 'Contamination Fee',
  rentalExtension: 'Rental Extension',
  overfill: 'Overfill Fee',
  liveLoad: 'Live Load',
  surcharge: 'Surcharge',
  extraFeeItem: 'Additional Fee Item(s)',
  dumpsterLiner: 'Dumpster Liner',
  frozenLoad: 'Frozen Load',
  emergency: 'Emergency Work',
  labor: 'Labor',
  portaPotty: 'Porta Potty',
  refund: 'Refund',
};

export const haulerBillPaymentMethods = {
  billPay: 'Bill Pay',
  ach: 'ACH',
  creditCard: 'Credit Card',
  thirdParty: '3rd Party Service',
  virtualCreditCard: 'Virtual Credit Card',
};

export const zoneTypes = {
  county: 'Entire County',
  kml: 'KML File',
};

export const zoneTypesEnums = {
  county: 'county',
  kml: 'kml',
};

export const needsAttentionStrings = ['Marked as Needs Attention', 'No longer Needs Attention'];

export const officeTel = '+17323669355';
export const mainSite = 'www.sourgum.com';
export const supportEmail = 'support@sourgum.com';
export const dispatchEmail = 'dispatch@sourgum.com';
export const billingEmail = 'billing@sourgum.com';
export const salesEmail = 'sales@sourgum.com';
export const noReplyEmail = 'no-reply@sourgum.com';
export const billingAddress = '30 Montgomery St, Suite 1330, Jersey City, NJ 07302';
export const allianceHaulerID = 'd1e8de6d-6f05-4de5-ba48-dfd42d7bb1a5';
export const emailOptions = [salesEmail, dispatchEmail, supportEmail, billingEmail];

// LINKS FOR REVIEW EMAILS BY STATE
export const reviewStateLinks = {
  PA: 'https://g.page/r/CX3a5tygCPuiEB0/review',
  NJ: 'https://g.page/r/CfTjL0zjLkbqEB0/review',
  NY: 'https://g.page/r/CfTjL0zjLkbqEB0/review',
  DE: 'https://g.page/r/CY4Y7npqdXF0EB0/review',
  MD: 'https://g.page/r/CeEno2UHF1hrEB0/review',
  DC: 'https://g.page/r/CeEno2UHF1hrEB0/review',
  VA: 'https://g.page/r/CeEno2UHF1hrEB0/review',
  NC: 'https://g.page/r/Ce30BwimDGY7EB0/review',
  SC: 'https://g.page/r/Ce30BwimDGY7EB0/review',
  GA: 'https://g.page/r/CfwnpKLC0JLrEB0/review',
  FL: 'https://g.page/r/CS8V6lfhSEUIEB0/review',
  DEFAULT: 'https://g.page/r/CfTjL0zjLkbqEB0/review',
} as const;

export enum SwitchType {
  SWAP = 'SWAP',
  DUMP_AND_RETURN = 'DUMP_AND_RETURN',
}

/**
 * Hard coded list of all employees with roles being used
 * to determine if a user is an account manager or account executive
 * or neither.
 */
export const repList = [
  {
    id: '995bab51-ae60-462a-b1dc-b1d0a58b7f7b',
    active: true,
    firstName: 'Jonathan',
    lastName: 'Sandidge',
    roles: [],
  },
  {
    id: '12539b96-bb87-4ddf-a6c9-67c5011a376e',
    active: true,
    firstName: 'Jason',
    lastName: 'Depeaux',
    roles: [],
  },
  {
    id: 'da23a246-f8f7-4080-8a49-fca5c98db7cc',
    active: true,
    firstName: 'Steven',
    lastName: 'Branagan',
    roles: [],
  },
  {
    id: 'e0dd4894-5442-4d41-96c6-a325a1395bb5',
    active: true,
    firstName: 'Kelley',
    lastName: 'Kirkland',
    roles: ['sourgum-account-manager', 'sourgum-account-executive'],
  },
  {
    id: 'c876c203-aa38-4b37-b0c8-2bcd5e26ee7d',
    active: true,
    firstName: 'John',
    lastName: 'Ramos',
    roles: [],
  },
  {
    id: '63ccbd62-45fd-4914-890e-9b0f6ba15262',
    active: false,
    firstName: 'Ryan',
    lastName: 'Baginski',
    roles: [],
  },
  {
    id: '51ef65e8-486d-4441-a456-ac70de989f67',
    active: false,
    firstName: 'Kyle',
    lastName: 'Sandor',
    roles: [],
  },
  {
    id: '4820472a-3b24-406c-b509-48a556da1623',
    active: true,
    firstName: 'Marie',
    lastName: 'Wickham',
    roles: [],
  },
  {
    id: 'f2deeae8-8f5e-49d3-b6e4-a20b879697d9',
    active: true,
    firstName: 'Shawn',
    lastName: 'McCord',
    roles: ['sourgum-account-executive'],
  },
  {
    id: '70e4d6b2-e937-4cb0-9820-b20171789257',
    active: true,
    firstName: 'Joe',
    lastName: 'DiNardi',
    roles: [],
  },
  {
    id: 'efa72666-9dbe-4780-a6d9-41d0eacab5ed',
    active: false,
    firstName: 'Ben',
    lastName: 'Blue',
    roles: [],
  },
  {
    id: 'acfb2434-4c42-458a-a1f4-3bdb78d111fb',
    active: false,
    firstName: 'GinaMarie',
    lastName: 'Chilelli',
    roles: [],
  },
  {
    id: 'f94ed306-5758-414c-a323-58744e4f086e',
    active: true,
    firstName: 'Lou',
    lastName: 'Smith',
    roles: ['sourgum-account-executive'],
  },
  {
    id: '4a989588-b61e-4b0f-a237-da5f407d00be',
    active: false,
    firstName: 'Lataysha',
    lastName: 'Reese',
    roles: [],
  },
  {
    id: '39e7ca2c-4d8f-4c10-8e51-7d9e0079dad9',
    active: false,
    firstName: 'Tatum',
    lastName: 'Pied',
    roles: [],
  },
  {
    id: '45666d2f-058a-42b6-9c85-3a086acbba63',
    active: true,
    firstName: 'Luc',
    lastName: 'Anis',
    roles: [],
  },
  {
    id: '34a654da-6f1a-4f53-81c3-023c208c9ea4',
    active: true,
    firstName: 'Alison',
    lastName: 'McKessy',
    roles: [],
  },
  {
    id: '577ccde3-a1c0-46cb-9e80-fcc1dd8e3072',
    active: true,
    firstName: 'Ricardo',
    lastName: 'Huggins',
    roles: ['sourgum-account-executive'],
  },
  {
    id: '252a96ea-f58b-48a1-971c-2295c1b925ef',
    active: false,
    firstName: 'Manuel',
    lastName: 'Diaz',
    roles: [],
  },
  {
    id: 'd0385c50-9313-4c7d-9a0e-b38a537d0813',
    active: true,
    firstName: 'Mike',
    lastName: 'Behar',
    roles: [],
  },
  {
    id: '9c98a0ad-6611-4cf3-b63a-02e557de923e',
    active: true,
    firstName: 'Fernando',
    lastName: 'Sandoval',
    roles: ['sourgum-account-executive'],
  },
  {
    id: 'c2677a0c-489c-4b0d-bbad-43e8472c7e00',
    active: true,
    firstName: 'Shawn',
    lastName: 'Louie',
    roles: [],
  },
  {
    id: 'd4871980-849b-49ed-b0cc-07d596d92133',
    active: true,
    firstName: 'Tim',
    lastName: 'Houston',
    roles: [],
  },
  {
    id: '0b8c4767-1ba0-409f-9d8e-4f904a54a8be',
    active: true,
    firstName: 'Michaela',
    lastName: 'Pecot',
    roles: [],
  },
  {
    id: 'f1d78112-85db-4cb5-b003-a4caf695a288',
    active: true,
    firstName: 'Rudy',
    lastName: 'Vitale',
    roles: [],
  },
  {
    id: '005c33a9-07f2-4e88-af19-7574b76e4c72',
    active: true,
    firstName: 'Dane',
    lastName: 'Gannon',
    roles: [],
  },
  {
    id: '3fd3d61d-bbed-4d85-977c-6940fe9b2349',
    active: true,
    firstName: 'Claudia',
    lastName: 'Rubino',
    roles: [],
  },
  {
    id: '39273ccf-7d69-4984-bc10-7d1fd7ba783b',
    active: true,
    firstName: 'Raheem',
    lastName: 'Caleb',
    roles: [],
  },
  {
    id: 'c56d8dbc-e987-4671-884d-61cd8c88b162',
    active: true,
    firstName: 'Tomasz',
    lastName: 'Turek',
    roles: [],
  },
  {
    id: '5b68bfa3-9632-4351-9ec3-c52c6c841640',
    active: true,
    firstName: 'Michael',
    lastName: 'Cavanaugh',
    roles: ['sourgum-account-executive'],
  },
  {
    id: 'b2cdff51-def5-4c00-b9ec-92224fa3c350',
    active: true,
    firstName: 'Joshua',
    lastName: 'Olazo',
    roles: [],
  },
  {
    id: '5499c702-4850-4922-a861-6c7490d0c9cf',
    active: true,
    firstName: 'Gene',
    lastName: 'Leyzerov',
    roles: [],
  },
  {
    id: 'd30a3474-cda7-4df2-a15e-e11affddd90a',
    active: true,
    firstName: 'Jackie',
    lastName: 'Eke',
    roles: [],
  },
  {
    id: 'c45248ba-c150-4905-beae-4fc39aed2983',
    active: true,
    firstName: 'Nir',
    lastName: 'Alishahian',
    roles: [],
  },
];

export const accountManagers = repList.filter((rep) => rep.roles.includes('sourgum-account-manager'));
export const accountExecutives = repList.filter((rep) => rep.roles.includes('sourgum-account-executive'));
