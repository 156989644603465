import { useEffect, useState } from 'react';
import { Customer, Hauler } from '@alliance-disposal/transport-types';
import { SelectOption as SelectOptionSchemaType } from '@alliance-disposal/wql';
import { Button, Checkbox, Select, SelectOption, TextField, phoneNumberMasker } from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber, formatUSPhoneNumberToE164 } from '@wayste/utils';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Controller, get, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { departmentTypes as departments } from '../../utils';

interface ContactSubFormProps {
  fieldName?: string;
  hideButtons?: boolean;
  staticPrimaryDisable?: boolean;
  isCustomer?: boolean;
  isEditing?: boolean;
  locationOptions?: SelectOptionSchemaType[];
}

export const ContactSubForm = ({
  fieldName,
  hideButtons,
  staticPrimaryDisable,
  isCustomer,
  isEditing,
  locationOptions,
}: ContactSubFormProps) => {
  if (!fieldName) {
    fieldName = '';
  }
  const [primaryContactIndex, setPrimaryContactIndex] = useState<number | undefined>(undefined);
  const [disableNonePrimary, setDisableNonePrimary] = useState<boolean>(true);
  const [originalData, setOriginalData] = useState<
    Array<Hauler.HaulerContact | Customer.AllianceCustomerContactTransport>
  >([]);

  ////////////////////////////////////////////////
  // CONSTANTS
  ////////////////////////////////////////////////

  const departmentTypes = !isCustomer
    ? {
        primary: 'Primary',
        dispatch: 'Dispatch',
        billing: 'Billing',
        sales: 'Sales',
        owner: 'Owner',
        other: 'Other (specify in notes)',
      }
    : {
        ...departments,
      };

  const blankContact = !isCustomer
    ? {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        department: '',
        notes: '',
        sendBillingEmails: false,
        sendDispatchEmails: false,
        primaryContact: false,
        sendMarketingEmails: false,
      }
    : {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        department: '',
        notes: '',
        sendBillingEmails: false,
        sendDispatchEmails: false,
        primaryContact: false,
        locationPreferences: [],
      };

  // HOOKS FORM CONTEXT
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: `${fieldName}contacts`,
  });

  // WATCH CONTACTS FIELD ARRAY
  const data = useWatch({
    control,
    name: `${fieldName}contacts`,
  });

  // CONTROL PRIMARY CONTACT DISABLE
  useEffect(() => {
    if (data) {
      const shouldDisable = hasPrimaryContact(data);
      setDisableNonePrimary(shouldDisable);
    }
  }, [data]);

  //////////////////////////////////////////////////
  // FUNCTIONS
  //////////////////////////////////////////////////
  const handleAddContact = () => {
    append({ ...blankContact });
  };

  const handleRemoveContact = (index: number) => {
    remove(index);
  };

  const hasPrimaryContact = (data: Array<Hauler.HaulerContact | Customer.AllianceCustomerContactTransport>) => {
    let exists = false;
    data.forEach((item: Hauler.HaulerContact | Customer.AllianceCustomerContactTransport, index: number) => {
      if (item.primaryContact) {
        setPrimaryContactIndex(index);
        exists = true;
      }
    });
    return exists;
  };

  const disablePrimary = (index: number) => {
    if (originalData[index] && staticPrimaryDisable) {
      if (originalData[index].primaryContact === true) {
        return true;
      }
      return false;
    }
    return primaryContactIndex !== index && disableNonePrimary;
  };

  useEffect(() => {
    if (data) {
      hasPrimaryContact(data);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const clonedData = JSON.parse(JSON.stringify(data)); // Clone the data object
      setOriginalData(clonedData);
    }
  }, []);

  return (
    <>
      {fields.map((item, index) => (
        <div className="space-y-6" key={item.id + index}>
          <div className="flex flex-row space-x-2" key={item.id + index + 'name'}>
            <Controller
              key={item.id + index + 'firstName'}
              control={control}
              name={`${fieldName}contacts.${index}.firstName`}
              defaultValue={''}
              rules={{
                required: {
                  value: true,
                  message: 'Contact name is required',
                },
              }}
              render={({ field }) => (
                <TextField
                  type={'string'}
                  label="First name"
                  error={get(errors, `contacts.${index}.firstName`)}
                  required
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />

            <Controller
              key={item.id + index + 'lastName'}
              control={control}
              name={`${fieldName}contacts.${index}.lastName`}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  type={'string'}
                  label="Last name"
                  error={get(errors, `contacts.${index}.lastName`)}
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />
          </div>

          <div className="flex flex-row space-x-2 ">
            <Controller
              key={item.id + index + 'email'}
              control={control}
              name={`${fieldName}contacts.${index}.email`}
              rules={{
                required: {
                  value: primaryContactIndex === index ? true : false,
                  message: 'Email is required',
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
              render={({ field }) => (
                <TextField
                  type={'string'}
                  label="Email"
                  required={primaryContactIndex === index ? true : false}
                  error={get(errors, `contacts.${index}.email`)}
                  inputProps={{
                    ...field,
                    disabled: isEditing,
                  }}
                />
              )}
            />

            <Controller
              key={item.id + index + 'phoneNumber'}
              control={control}
              name={`${fieldName}contacts.${index}.phoneNumber`}
              defaultValue={''}
              rules={{
                required: {
                  value: primaryContactIndex === index ? true : false,
                  message: 'Phone number is required',
                },
              }}
              render={({ field }) => (
                <TextField
                  startAdornment="+1"
                  type={'string'}
                  label="Phone number"
                  error={get(errors, `contacts.${index}.phoneNumber`)}
                  required={primaryContactIndex === index ? true : false}
                  inputProps={{
                    ...field,
                    value: formatE164ToUSPhoneNumber(field.value),
                    onChange: (e) => {
                      setValue(
                        `${fieldName}contacts.${index}.phoneNumber`,
                        formatUSPhoneNumberToE164(phoneNumberMasker(e.target.value)),
                      );
                    },
                  }}
                />
              )}
            />
          </div>
          <div className={`${isCustomer ? 'flex flex-row space-x-2' : ''}`}>
            <Controller
              key={item.id + index + 'department'}
              name={`${fieldName}contacts.${index}.department`}
              control={control}
              render={({ field }) => (
                <Select
                  error={get(errors, `contacts.${index}.department`)}
                  label={'Department'}
                  onSelect={field.onChange}
                  defaultValue={''}
                  value={field.value}
                >
                  {Object.entries(departmentTypes).map((option: [string, string], index: number) => (
                    <SelectOption key={`contacts.${index}.department` + index} value={option[0]}>
                      {option[1]}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
            {isCustomer && locationOptions ? (
              <Controller
                key={item.id + index + 'locationPreferences'}
                name={`${fieldName}contacts.${index}.locationPreferences`}
                control={control}
                render={({ field }) => (
                  <Select
                    multiple
                    error={get(errors, `contacts.${index}.locationPreferences`)}
                    label={'Notify Locations'}
                    onSelect={(value: string[]) => {
                      if (value.includes('All')) {
                        setValue(`contacts.${index}.locationPreferences`, []);
                      } else {
                        setValue(`contacts.${index}.locationPreferences`, value);
                      }
                    }}
                    defaultValue={''}
                    value={field.value}
                  >
                    {locationOptions?.map((option: SelectOptionSchemaType, index: number) => (
                      <SelectOption key={`contacts.${index}.locationPreferences` + index} value={option.label}>
                        {option.value}
                      </SelectOption>
                    ))}
                  </Select>
                )}
              />
            ) : (
              <></>
            )}
          </div>

          <div className="flex flex-row space-x-2 ml-1 ">
            <Controller
              key={item.id + index + 'primaryContact'}
              name={`${fieldName}contacts.${index}.primaryContact` as string}
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  label={'Primary Contact'}
                  inputProps={{
                    disabled: disablePrimary(index),
                    value: 'type',
                    checked: field.value,
                    onChange: () => {
                      field.onChange;
                      if (field.value === false) {
                        setValue(`${fieldName}contacts.${index}.sendBillingEmails`, true);
                        setValue(`${fieldName}contacts.${index}.sendDispatchEmails`, true);
                        setValue(`${fieldName}contacts.${index}.primaryContact`, true);
                        setDisableNonePrimary(true);
                      } else {
                        setValue(`${fieldName}contacts.${index}.primaryContact`, false);
                        setDisableNonePrimary(false);
                      }
                    },
                  }}
                />
              )}
            />
            <Controller
              key={item.id + index + 'sendDispatchEmails'}
              name={`${fieldName}contacts.${index}.sendDispatchEmails` as string}
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  label={'Send Dispatch Emails'}
                  inputProps={{ value: 'type', checked: field.value, onChange: field.onChange }}
                />
              )}
            />
          </div>
          <div className="flex flex-row space-x-2 ml-1">
            <Controller
              key={item.id + index + 'sendBillingEmails'}
              name={`${fieldName}contacts.${index}.sendBillingEmails` as string}
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  label={'Send Billing Emails'}
                  inputProps={{ value: 'type', checked: field.value, onChange: field.onChange }}
                />
              )}
            />
            {!isCustomer ? (
              <Controller
                key={item.id + index + 'sendMarketingEmails'}
                name={`${fieldName}contacts.${index}.sendMarketingEmails` as string}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    label={'Send Marketing Emails'}
                    inputProps={{ value: 'type', checked: field.value, onChange: field.onChange }}
                  />
                )}
              />
            ) : (
              <></>
            )}
          </div>

          <div className="flex flex-row space-x-2  ">
            <Controller
              key={item.id + index + 'notes'}
              control={control}
              name={`${fieldName}contacts.${index}.notes`}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  type={'string'}
                  label="Notes"
                  error={get(errors, `contacts.${index}.notes`)}
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />
          </div>
          {fields.length !== 0 && !hideButtons && (
            <div className="w-full text-right">
              <Button
                className="btn-delete-outlined"
                onClick={() => handleRemoveContact(index)}
                startIcon={<XMarkIcon />}
                disabled={primaryContactIndex === index}
              >
                Remove
              </Button>
            </div>
          )}
          {index !== fields.length - 1 && <hr className="py-2" />}
        </div>
      ))}
      {!hideButtons && (
        <div className="flex flex-row justify-center items-center">
          <hr className="w-full" />
          <div className="px-2 flex flex-row justify-center items-center space-x-2">
            <Button
              className="btn-primary-text-only whitespace-nowrap"
              onClick={handleAddContact}
              startIcon={<PlusCircleIcon />}
            >
              Add contact
            </Button>
          </div>
          <hr className="w-full" />
        </div>
      )}
    </>
  );
};
