import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, InternalTicket, Order, Profile } from '@alliance-disposal/transport-types';
import { Button, Tooltip } from '@wayste/sour-ui';
import { format } from 'date-fns';
import { ManageTags } from '../InternalTicket';
import type { Entities } from './InternalTicketWorkflow';

const extractTicketEntityInfo = (
  ticket: InternalTicket.InternalTicketTransport,
  entity?: Order.AllianceOrderTransport | Customer.AllianceCustomerTransport | object,
) => {
  if (!entity) return {};
  switch (ticket.entityType) {
    case 'sourgum-order':
      return {
        title: `Order - ${(entity as Order.AllianceOrderTransport).orderNumber}`,
        vendorName: (entity as Order.AllianceOrderTransport).vendorName,
      };
    case 'sourgum-customer':
      return {
        title: `Customer - ${(entity as Customer.AllianceCustomerTransport).customerNumber}`,
        vendorName: null,
      };
    case 'sourgum-vendor':
      return {
        title: '',
        vendorName: null,
      };
    case 'sourgum-service-grouping':
      return {
        title: '',
        vendorName: null,
      };
    default:
      return {
        title: '',
        vendorName: null,
      };
  }
};

type TicketItemProps = {
  ticket: InternalTicket.InternalTicketTransport;
  entities: Entities;
  onAddEntity?: (entityID: string, entityType: InternalTicket.InternalTicketRelatedEntity) => void;
  onTicketItemClick?: (ticket: InternalTicket.InternalTicketTransport) => void;
  rosterMap: { [key: string]: Profile.ProfileTransport };
  onRefreshTickets: () => void;
};

export const TicketItem = ({
  ticket,
  entities,
  onAddEntity,
  onTicketItemClick,
  rosterMap,
  onRefreshTickets,
}: TicketItemProps) => {
  const client = useWaysteClient();
  const currentUser = client.user().get();
  const [assignedToMeLoading, setAssignedToMeLoading] = useState(false);

  useEffect(() => {
    if (onAddEntity && !entities[ticket.entityID]) {
      onAddEntity(ticket.entityID, ticket.entityType);
    }
  }, []);

  const handleAssignToMe = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAssignedToMeLoading(true);
    e.stopPropagation();
    await client.internalTicket().adminPortal.update(ticket.id, { assignedToProfileID: currentUser.id });
    setAssignedToMeLoading(false);
    onRefreshTickets();
  };

  return (
    <div
      className="bg-white rounded p-2 mb-2 flex flex-col w-full gap-y-2"
      onClick={() => {
        if (onTicketItemClick) onTicketItemClick(ticket);
      }}
    >
      <div className="flex justify-between">
        <div>{extractTicketEntityInfo(ticket, entities[ticket.entityID])?.title}</div>
        {ticket.assignedToProfileID && (
          <Tooltip
            text={`${rosterMap[ticket.assignedToProfileID]?.firstName} ${
              rosterMap[ticket.assignedToProfileID]?.lastName
            }`}
          >
            <div className="rounded-full bg-slate-600 text-white w-6 h-6 flex items-center justify-center text-xs uppercase">
              {rosterMap[ticket.assignedToProfileID]?.firstName?.charAt(0)}
              {rosterMap[ticket.assignedToProfileID]?.lastName?.charAt(0)}
            </div>
          </Tooltip>
        )}
      </div>
      <div className="text-sm">Created: {format(new Date(ticket.metadata.createdAt), 'MM/dd/yy hh:mm a')}</div>
      <div className="text-sm">Updated: {format(new Date(ticket.metadata.lastUpdatedAt), 'MM/dd/yy hh:mm a')}</div>
      <ManageTags readOnly tags={ticket.tags} />
      <div className="flex justify-between">
        {extractTicketEntityInfo(ticket, entities[ticket.entityID])?.vendorName ? (
          <div className="text-sm">
            Vendor: {extractTicketEntityInfo(ticket, entities[ticket.entityID])?.vendorName}
          </div>
        ) : (
          <div />
        )}
        {currentUser.id !== ticket.assignedToProfileID ? (
          <Button className="btn-secondary-text-only" onClick={handleAssignToMe} loading={assignedToMeLoading}>
            Assign to me
          </Button>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
