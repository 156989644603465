import React from 'react';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { useHistory } from 'react-router-dom';
import routes from '../../utils/routes';
import OrderBillingDetailsLists from '../OrderBillingDetailsLists/OrderBillingDetailsLists';
import DetailsCardWrapper from '../ui/DetailsCardWrapper';

/**
 * Displays bulk of Billing data with button to go to invoice
 * @param {*} order
 */

export interface OrderBillingDetailsCardProps {
  order: Order.AllianceOrderTransport;
  receivables?: Invoice.ReceivableTransport[];
  payables?: Invoice.PayableTransport[];
}
const OrderBillingDetailsCard = ({ order, receivables, payables }: OrderBillingDetailsCardProps) => {
  const history = useHistory();

  return (
    <DetailsCardWrapper
      heading="Billing Details"
      buttons={[
        {
          label: (
            <>
              <ArrowTopRightOnSquareIcon className="h-5 w-5 mr-1" /> Open Invoice
            </>
          ),
          onClick: () =>
            history.push({
              pathname: routes.billing.list,
              search: `?id=${order.id}`,
            }),
        },
      ]}
    >
      <OrderBillingDetailsLists
        order={order}
        receivables={receivables || []}
        payables={payables || []}
        break3={false}
      />
    </DetailsCardWrapper>
  );
};

export default OrderBillingDetailsCard;
