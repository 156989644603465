import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Invoice, Order } from '@alliance-disposal/transport-types';
import { Pricing } from '@alliance-disposal/transport-types';
import { buildAddressString, formatUSPhoneNumberToE164, getDateFormat, getPrimaryContact, round } from '@wayste/utils';
import structuredClone from '@ungap/structured-clone';
import { addDays, format, isSameDay, isTomorrow } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';
import { sendDynamicEmail } from '../../axios/sendgrid';
import { getPublicLinkShortCode } from '../../axios/wayste-backend';
import { UIContext } from '../../contexts';
import { useAuthToken } from '../../hooks/authhook';
import {
  OrderStatus,
  ccRate,
  channelTypesEnums,
  createOrderConfirmationEmailSendGrid,
  generateOrderFirstInvoice,
  getCustomerCCAddresses,
  leadStatusTypesEnums,
  paymentMethodsEnums,
  paymentTermsEnums,
  routes,
} from '../../utils';
import { accountExecutives, accountManagers } from '../../utils/shared-types';
import Loading from '../Loading';
import OrderCreate from './OrderCreate';

const OrderCreateContainer = () => {
  const client = useWaysteClient();
  const userProfile = client.user().get();
  const { showFlash } = useContext(UIContext);
  const history = useHistory();
  const location: any = useLocation();
  const [zonePricing, setZonePricing] = useState<Pricing.PricingTransport | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchingForCustomer, setIsSearchingForCustomer] = useState<boolean>(false);
  const [startingValues, setStartingValues] = useState<any>(null);
  const [fromLead, setFromLead] = useState<string | null>(null);
  const [oldCustomer, setOldCustomer] = useState<Customer.AllianceCustomerTransport | null>(null);
  const { token } = useAuthToken();

  const handleFromLead = async (leadID: string, passedValues: any) => {
    setFromLead(leadID);
    if (passedValues && passedValues.contacts?.length > 0) {
      setIsSearchingForCustomer(true);
      let customerFound = null;
      if (passedValues.contacts[0]?.email) {
        const customerEmailResponse = await client
          .customer()
          .adminPortal.query({ email: passedValues.contacts[0].email });

        if (customerEmailResponse) {
          const customerSearch = customerEmailResponse;
          if (customerSearch !== null) {
            if (customerSearch.length > 0) {
              customerFound = customerSearch[0];
            }
          }
        }
      }
      if (!customerFound && passedValues.contacts[0]?.phoneNumber) {
        const customerPhoneResponse = await client.customer().adminPortal.query({
          phoneNumber: formatUSPhoneNumberToE164(passedValues.contacts[0].phoneNumber),
        });
        if (customerPhoneResponse) {
          const customerSearch = customerPhoneResponse;
          if (customerSearch !== null) {
            if (customerSearch.length > 0) {
              customerFound = customerSearch[0];
            }
          }
        }
      }
      if (customerFound) {
        setStartingValues({
          ...passedValues,
          ...customerFound,
        });
        setOldCustomer(customerFound);
      }
      setIsSearchingForCustomer(false);
    }
  };

  useEffect(() => {
    if (location.state) {
      if (location?.state?.startingValues) setStartingValues(location.state.startingValues);
      if (location.state.fromLead) {
        const address = location.state.startingValues.serviceLocation.address.addressLine1
          ? location.state.startingValues.serviceLocation.address
          : {
              addressLine1: '',
              addressLine2: '',
              city: '',
              state: '',
              zip: '',
            };
        const passedValues = {
          ...location.state.startingValues,
          serviceLocation: {
            ...location.state.startingValues.serviceLocation,
            address,
          },
        };

        handleFromLead(location.state.fromLead, passedValues);
      }
    }
  }, []);

  const handleResetLead = () => {
    if (fromLead === 'create') setFromLead(null);
  };

  const handleCreateLead = async (
    values: {
      expectedSize: string | number;
      material: any;
      serviceLocation: { address: { city: any } };
      websiteSale: any;
    },
    customerID?: string | undefined,
    confirmedChannel?: undefined,
  ) => {
    const dataCopy = JSON.parse(JSON.stringify(values));
    const leadObj = {
      firstName: dataCopy.contacts[0].firstName,
      email: dataCopy.contacts[0].email,
      phoneNumber: dataCopy.contacts[0].phoneNumber
        ? formatUSPhoneNumberToE164(dataCopy.contacts[0].phoneNumber)
        : null,
      customerNotes: '',
      requestedSize: {
        size: +values.expectedSize,
        type: '',
      },
      requestedMaterial: values.material,
      type: 'rollOff',
      searchParams: dataCopy.searchParams || startingValues?.searchParams || '',
      referringUrl: '',
      formUrl: '',
      channel: confirmedChannel || startingValues?.channel || '',
      allianceCustomerID: customerID || null,
      status: customerID ? leadStatusTypesEnums.won : leadStatusTypesEnums.open,
      needsAttention: false,
      serviceLocation: values?.serviceLocation?.address?.city ? values.serviceLocation : startingValues.serviceLocation,
      requestedDeliveryDate: null,
    };
    const leadPayload = { ...leadObj };
    //@ts-expect-error i is not optional on the Lead model
    delete leadPayload.id;

    const leadResponse = await client.customer().adminPortal.leads.create(leadPayload);

    if (leadResponse) {
      const leadID = leadResponse?.id;
      //???
      const payload: Customer.AllianceLeadQuoteCreateTransport = {
        ...values,
        size: {
          size: +values.expectedSize,
          type: '',
        },
        cc: false,
      };

      await client.customer().adminPortal.leads.quote.create(leadID, payload);

      await client.customer().adminPortal.leads.contactEvent.create(leadID, {
        date: new Date().toISOString(),
        medium: values.websiteSale ? 'email' : 'phone',
      });

      return;
    }
  };

  const handleUpdateLead = async (customerID: string, confirmedChannel: any, values: any) => {
    if (fromLead === 'create') {
      return await handleCreateLead(values, customerID, confirmedChannel);
    }
    if (fromLead && fromLead !== 'create') {
      return await client.customer().adminPortal.leads.update(fromLead, {
        allianceCustomerID: customerID,
        status: leadStatusTypesEnums.won,
      });
    }
    if (values.primaryContact?.email) {
      const emailQuery = await client.customer().adminPortal.leads.query({
        email: values.primaryContact?.email?.trim()?.toLowerCase(),
        status: 'open',
      });

      if (emailQuery.results.length >= 1) {
        for (const emailItem of emailQuery.results) {
          await client.customer().adminPortal.leads.update(emailItem.id, {
            status: leadStatusTypesEnums.won,
            allianceCustomerID: customerID,
            serviceLocation: values.serviceLocation,
          });
        }
        return true;
      }
    }
    const phoneNumber = formatUSPhoneNumberToE164(values.primaryContact?.phoneNumber);

    const phoneQuery = await client.customer().adminPortal.leads.query({
      phoneNumber: phoneNumber,
      status: 'open',
    });

    if (phoneQuery.results.length >= 1) {
      for (const phoneItem of phoneQuery.results) {
        await client.customer().adminPortal.leads.update(phoneItem.id, {
          status: leadStatusTypesEnums.won,
          allianceCustomerID: customerID,
          serviceLocation: values.serviceLocation,
        });
      }
      return true;
    }
    return null;
  };

  const handleBackButtonClick = (values: any) => {
    if (Boolean(fromLead)) {
      if (
        fromLead === 'create' &&
        (values?.serviceLocation?.address?.city || startingValues?.serviceLocation?.address?.city) &&
        (values?.primaryContact?.phoneNumber || values?.primaryContact?.email)
      ) {
        handleCreateLead(values);
      }
      history.go(-2);
      return;
    }
    history.goBack();
  };

  const getOrderTotal = (values: any) => {
    let total = values.price;
    values.otherLineItems.forEach((item: any) => {
      total = total + item.totalPriceDollars;
    });
    return round(total);
  };

  const handleGetPricing = async (
    location: {
      lat: number;
      lng: number;
      zip?: string;
      state: string;
    },
    customerID?: string,
  ) => {
    try {
      const results = await client.pricing().adminPortal.location.query(location, customerID);

      if (!results) {
        console.warn('handleGetPricing Error: ', results);
        showFlash('An Error Occurred Getting Pricing', 'warning');
        return;
      }

      const publicPricing = results.filter((item: any) => item.public === true);

      console.log('publicPricing', publicPricing);
      if (publicPricing.length === 1) {
        setZonePricing(publicPricing[0]);
      } else {
        if (publicPricing.length > 1) alert('Get an AAP Dev pricing overlap');
        setZonePricing(null);
      }
    } catch (error) {
      console.warn('handleGetPricing Error: ', error);
      alert('An error occurred, get an AAP dev, touch nothing');
    }
  };

  const generatePaymentLink = async (values: any, receivableID: string) => {
    if (!values.paymentInfo && values.paymentMethod === paymentMethodsEnums.creditCard) {
      const response = await getPublicLinkShortCode(receivableID, token);
      if (response.status === 'error') return null;
      return response?.data?.key;
    }
    return null;
  };

  const pdfToBase64 = (blob: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const handleSendEmail = async (values: any, receivableID: string, orderNumber: string) => {
    const ccResponse = getCustomerCCAddresses(values, values.serviceLocation.address);
    const emailData = createOrderConfirmationEmailSendGrid(
      values,
      getOrderTotal(values),
      userProfile,
      null, //stripeInvoiceLink,
      zonePricing?.prohibitedItems ? zonePricing.prohibitedItems.join(', ') : '',
      ccResponse.serviceCCAddresses,
    );
    const payment_link = await generatePaymentLink(values, receivableID);
    emailData.payment_link = payment_link;
    const response = await sendDynamicEmail(emailData as any);
    if (values.paymentTerm !== paymentTermsEnums.onCharge && !values.paymentInfo && values.issueInvoice === 'NOW') {
      const invoicePDFResponse = await client.invoice().adminPortal.pdf.fetch(receivableID);
      const blob = new Blob([invoicePDFResponse], { type: 'application/pdf' });
      const invoiceBlob: any = await pdfToBase64(blob);
      if (!invoiceBlob) {
        console.warn('handleSendEmail error !invoiceBlob: ', invoiceBlob, blob, invoicePDFResponse, receivableID);
        alert('An error occurred sending the Invoice. Touch nothing and get a SAP dev');
      } else {
        const invoiceEmailData = {
          emailTemplate: 'INVOICE_INTERMEDIARY',
          toEmail: values.primaryContact?.email,
          first_name: values.contacts[0].firstName,
          receipt_invoice: 'Invoice',
          order_number: `${orderNumber} - 1`,
          ccEmails: ccResponse.billingCCAddresses || [],
          attachments: [
            {
              content: invoiceBlob.split(',')[1],
              type: 'application/pdf',
              filename: `Sourgum Invoice.pdf`,
              disposition: 'attachment',
            },
          ],
          invoice_number: '1',
          payment_link: payment_link,
        };
        if (invoiceBlob) {
          await sendDynamicEmail(invoiceEmailData as any);
        } else {
          console.warn('invoiceBlob did not create. Debug why');
          alert('Touch nothing and get a SAP dev to inspect console');
        }
      }
    }
    if (
      isSameDay(new Date(), values.expectedDeliveryDate) ||
      (isTomorrow(values.expectedDeliveryDate) && new Date().getHours() >= 9)
    ) {
      const deliveryTomorrowEmailData = {
        emailTemplate: 'RO_DELIVERY_TOMORROW',
        toEmail: values.primaryContact?.email,
        first_name: values.contacts[0].firstName,
        delivery_date: format(new Date(values.expectedDeliveryDate), 'EEEE MM/dd/yy'),
        service_location: buildAddressString(values.serviceLocation.address),
        csa_name: userProfile.firstName,
        ccEmails: ccResponse.serviceCCAddresses || [],
        today: isSameDay(new Date(), values.expectedDeliveryDate) ? 'Today' : null,
      };
      await sendDynamicEmail(deliveryTomorrowEmailData as any);
    }
    return response;
  };

  const createCustomer = async (data: any) => {
    const contacts = data.contacts;

    const payload: Customer.AllianceCustomerCreateInput = {
      ...data,
      notes: '',
      reviewed: false,
      doNotService: false,
      credit: data.credit * 100 || 0,
    };

    try {
      const response = await client.customer().adminPortal.create(payload);
      const customer = response;
      const promises = contacts.map(async (contact: Customer.AllianceCustomerContactTransport) => {
        return await client.customer().adminPortal.contact.create(customer.id, contact);
      });

      const contactsResponse = await Promise.all(promises);

      // find the the customer with the most contacts
      const customerWithMostContacts = contactsResponse.reduce((accumulator, customer) => {
        if (customer.contacts.length > accumulator.contacts.length) {
          return customer;
        }

        return accumulator;
      }, contactsResponse[0]);

      return { status: 'success', data: customerWithMostContacts };
    } catch (error) {
      console.error('error: ', error);
      alert('An error occurred get an AAP Dev');
      return { status: 'error', data: null };
    }
  };

  const updateCustomer = async (data: any, oldCustomer: any) => {
    const payload: Customer.AllianceCustomerInput = {
      ...data,
      credit: data.credit * 100 || 0,
    };
    const oldContacts = oldCustomer?.contacts;

    const contacts = data.contacts;

    try {
      const response = await client.customer().adminPortal.update(data.id, payload);

      const customer = response;
      if (oldContacts) {
        const status = await updateAllContacts(contacts, oldContacts, customer.id);

        if (status === 'error') {
          return { status: 'error', data: null };
        }
      }
      // customer will not have updated contacts
      return { status: 'success', data: customer };
    } catch (error) {
      console.error('error: ', error);
      alert('An error occurred get an AAP Dev');
      return { status: 'error', data: null };
    }
  };

  // Recursive update of contacts
  const updateAllContacts = async (
    contacts: Customer.AllianceCustomerContactTransport[],
    oldContacts: Customer.AllianceCustomerContactTransport[],
    customerID: string,
  ) => {
    // categorizes contacts into add, remove, and update
    const getContactsToUpdate = (
      contacts: Customer.AllianceCustomerContactTransport[],
      oldContacts: Customer.AllianceCustomerContactTransport[],
    ) => {
      const addContacts = contacts.filter((contact: Customer.AllianceCustomerContactTransport) => !contact.id);
      const updateContacts = contacts.filter((contact: Customer.AllianceCustomerContactTransport) => {
        const oldContact = oldContacts.find(
          (oldContact: Customer.AllianceCustomerContactTransport) => oldContact.id === contact.id,
        );
        return oldContact && JSON.stringify(oldContact) !== JSON.stringify(contact);
      });
      const removeContacts = oldContacts.filter(
        (oldContact: Customer.AllianceCustomerContactTransport) =>
          !contacts.find((contact: Customer.AllianceCustomerContactTransport) => contact.id === oldContact.id),
      );
      return { addContacts, removeContacts, updateContacts };
    };
    const { addContacts, removeContacts, updateContacts } = getContactsToUpdate(contacts, oldContacts);

    const addContactsPromises = addContacts.map((contact: Customer.AllianceCustomerContactTransport) => {
      try {
        return client.customer().adminPortal.contact.create(customerID, contact);
      } catch (error) {
        console.error('error in: ', error);
      }
    });
    const updateContactsPromises = updateContacts.map((contact: Customer.AllianceCustomerContactTransport) => {
      try {
        return client.customer().adminPortal.contact.update(customerID, contact.id, contact);
      } catch (error) {
        console.error('error in: ', error);
      }
    });
    const removeContactsPromises = removeContacts.map((contact: Customer.AllianceCustomerContactTransport) =>
      client.customer().adminPortal.contact.delete(customerID, contact.id),
    );

    await Promise.all([addContactsPromises, updateContactsPromises, removeContactsPromises]);

    return 'success';
  };

  const handleDBUpdate = async (
    order: any,
    invoice: any, // has to be any because we incrementally build invoice
    customer: Customer.AllianceCustomerTransport,
    values: any,
  ) => {
    let customerID = customer.id;
    let confirmedChannel = values.channel;
    if (values.searchParams) {
      if (values.searchParams.includes('utm_medium=organic')) confirmedChannel = channelTypesEnums.internet;
      if (values.searchParams.includes('msclkid=')) confirmedChannel = channelTypesEnums.bingAds;
      if (values.searchParams.includes('gclid=')) confirmedChannel = channelTypesEnums.adwords;
    }

    const customerCopy = structuredClone(customer);

    try {
      await handleUpdateLead(customerID, confirmedChannel, values);
    } catch (error) {
      showFlash('An Error Occurred Updating the lead', 'warning');
    }

    if (customerCopy.accountRepID && !customerCopy.accountRepName) {
      const ae = accountExecutives.find((item) => item.id === customerCopy.accountRepID);
      if (ae) customerCopy.accountRepName = `${ae?.firstName} ${ae?.lastName}`;
    }

    if (customerCopy.accountManagerID && !customerCopy.accountManagerName) {
      const am = accountManagers.find((item) => item.id === customerCopy.accountManagerID);
      if (am) customerCopy.accountManagerName = `${am?.firstName} ${am?.lastName}`;
    }

    if (customerID) {
      const customerUpdateResponse = await updateCustomer(customerCopy, oldCustomer);
      console.log('updated customer response: ', customerUpdateResponse);
      if (customerUpdateResponse?.status === 'error') {
        alert('Something went wrong. Touch Nothing. Get an AAP Dev');
        setIsLoading(false);
        return;
      }
    } else {
      customerCopy.overallExperience = 'Positive';
      customerCopy.channel = confirmedChannel;
      customerCopy.defaultPaymentSettings = {
        paymentMethod: values.paymentMethod || paymentMethodsEnums.creditCard,
        paymentTerm: values.paymentTerm || paymentTermsEnums.onCharge,
        allowedPaymentMethod: [paymentMethodsEnums.creditCard],
      };

      const customerCreateResponse = await createCustomer(customerCopy);
      if (customerCreateResponse?.status === 'error') {
        alert('Something went wrong. Touch Nothing. Get an AAP Dev');
        setIsLoading(false);
        return;
      }
      if (customerCreateResponse?.data?.id) {
        customerID = customerCreateResponse?.data?.id;
        if (customerID) {
          const customerResponseWithCustomerNumber = await client.customer().adminPortal.fetch(customerID);

          if (customerResponseWithCustomerNumber) {
            customer = customerResponseWithCustomerNumber;
          }
        }
      }
    }
    invoice.customerID = customerID;

    // Preprocess Dates from the Form values
    order.expectedDeliveryDate = getDateFormat(order.expectedDeliveryDate);
    order.expectedPickupDate =
      order.expectedPickupDate || order.expectedPickupDate === null
        ? getDateFormat(order.expectedPickupDate)
        : undefined;
    order.rentalEndDate =
      order.rentalEndDate || order.rentalEndDate === null
        ? getDateFormat(order.rentalEndDate)
        : order.expectedPickupDate || order.expectedPickupDate === null
        ? getDateFormat(order.expectedPickupDate)
        : undefined; // rentalEndDate
    order.expirationDate = addDays(new Date(order.expectedDeliveryDate), order.adjustedRentalPeriod.value);

    // Construct order for DB
    const orderObj: Order.AllianceOrderCreateInput = {
      customerCompanyName: customer.companyName || undefined,
      //@ts-expect-error this type was not on the order modal or transport type
      customerAccountNumber: customer?.accountNumber || undefined,
      customerName: (() => {
        const { firstName, lastName } =
          customerCopy.contacts.find((contact: any) => contact.isPrimary) || customerCopy.contacts[0];
        return `${firstName} ${lastName}`;
      })(),
      switchFromOrderID: order.switchFromOrderID || undefined,
      switchType: order.switchType || undefined,
      haulerID: order.haulerID,
      poNumber: order.poNumber,
      status: OrderStatus.UNASSIGNED,
      allianceCustomerID: customerID,
      requestedDeliveryDate: order.expectedDeliveryDate ? order.expectedDeliveryDate.toISOString() : undefined,
      requestedPickupDate: order.expectedPickupDate ? order.expectedPickupDate.toISOString() : undefined,
      rentalEndDate: order.rentalEndDate ? order.rentalEndDate.toISOString() : undefined,
      originalRentalPeriod: {
        value: order.adjustedRentalPeriod.value,
        unit: 'DAYS',
      },

      expirationDate: addDays(new Date(order.expectedDeliveryDate), order.adjustedRentalPeriod.value).toISOString(),
      sharedDeliveryNotes: order.sharedDeliveryNotes,
      sharedPickupNotes: order.sharedPickupNotes,
      sharedDeliveryNotesPrivate: order.sharedDeliveryNotesPrivate || '',
      sharedPickupNotesPrivate: order.sharedPickupNotesPrivate || '',
      material: order.material,
      recurringOnCall: order.recurringOnCall || false,
      ccRate: order.ccRate || ccRate,
      haulerDumpRateDollars: order.haulerDumpRateDollars || 0,
      haulerHaulRateDollars: order.haulerHaulRateDollars || 0,
      overageDollars: order.overageDollars || 0,
      dumpRateDollars: order.dumpRateDollars / 100 || 0,
      rentExtensionFeeDollars: order.rentExtensionFeeDollars || 0,
      taxRate: order.taxRate,
      priceType: order.priceType,
      paymentMethod: order.paymentMethod,
      haulerPricingSnapshot: order.haulerPricingSnapshot || undefined,
      paymentTerm: order.paymentTerm,
      serviceLocation: {
        address: {
          addressLine1: order.serviceLocation.address.addressLine1,
          addressLine2: order.serviceLocation.address.addressLine2,
          city: order.serviceLocation.address.city,
          state: order.serviceLocation.address.state,
          zip: order.serviceLocation.address.zip,
        },
        county: order.serviceLocation.county,
        coordinates: order.serviceLocation.coordinates,
      },
      requestedSize: { size: order.expectedSize, type: 'OPEN_TOP' },
      weightLimit:
        order.weightLimit === null || order.weightLimit === '' ? null : { value: order.weightLimit, unit: 'TONS' },
    };

    console.log('order payload: ', orderObj);
    console.log('customer copy: ', customerCopy);
    console.log('primary cont', getPrimaryContact(customerCopy.contacts));
    console.log('values: ', values);
    let orderResponseID = null;
    let orderResponseOrderNumber = null;
    let orderResponseServiceLocation = undefined;
    try {
      const orderResponse = await client.order().adminPortal.create(orderObj);

      if (!orderResponse) {
        console.warn('create Order orderError: ', orderResponse);
        showFlash('An Error Occurred Creating Order', 'warning');
        setIsLoading(false);
        return;
      }
      orderResponseID = orderResponse.id;
      orderResponseOrderNumber = orderResponse.orderNumber;
      orderResponseServiceLocation = orderResponse.serviceLocation;
    } catch (orderError) {
      console.warn('create Order orderError: ', orderError);
      showFlash('An Error Occurred Creating Order', 'warning');
      setIsLoading(false);
      return;
    }
    if (values.noteOrder)
      await client.order().adminPortal.notes.create(orderResponseID, { note: values.noteOrder, isIssue: false });

    let receivableID = null;

    // Have to refetch because backend isn't sending orderNumber back with creation
    const newOrderRefetchResponse = await client.order().adminPortal.fetch(orderResponseID);
    const newOrderNumber = newOrderRefetchResponse.orderNumber;
    if (!newOrderNumber) {
      showFlash('An Error Occurred Creating Invoice due to missing order number ', 'warning');
      console.error('newOrderNumber: ', newOrderNumber);
      return;
    }

    const shouldIssueInvoiceNow = customer.invoiceIssueTerm === 'IMMEDIATE' && !values.paymentInfo;

    const createObj: Invoice.ReceivableCreateTransport = {
      customerID,
      customerName:
        (() => {
          const { firstName, lastName } =
            customerCopy.contacts.find((contact: any) => contact.isPrimary) || customerCopy.contacts[0];
          return `${firstName} ${lastName}`;
        })() || undefined,
      customerCompanyName: customerCopy.companyName || undefined,
      invoiceDetails: {
        ...invoice,
        void: invoice.void || false,
        orderID: orderResponseID,
        issueDate: shouldIssueInvoiceNow && invoice.issueDate ? invoice.issueDate.toISOString() : undefined,
        dueDate: shouldIssueInvoiceNow && invoice.dueDate ? invoice.dueDate.toISOString() : undefined,
        orderNumber: newOrderNumber.toString(),
        orderServiceLocation: orderResponseServiceLocation,
      },
    };
    console.log('invoice Payload:', createObj);

    let receivableResponse;
    try {
      receivableResponse = await client.invoice().adminPortal.receivable.create(createObj);
    } catch (error) {
      showFlash('An Error Occurred Creating Invoice', 'warning');
      alert('An error occurred get an AAP dev');
      setIsLoading(false);
      return;
    }

    if (!receivableResponse) {
      alert('An error occurred get dev. No Invoice created');
    }

    console.log('receivableResponse: ', receivableResponse);

    receivableID = receivableResponse?.id;
    if (values.paymentInfo) {
      try {
        const res = await client
          .invoice()
          .adminPortal.payment.create(receivableResponse?.invoiceDetails.id, { ...values.paymentInfo });

        console.log('paymentResponse: ', res);
      } catch (error) {
        console.warn('paymentResponse error: ', error);
        showFlash('An Error Occurred Creating Payment', 'warning');
      }
    }
    if (values.sendEmail) {
      const emailResponse = await handleSendEmail(
        values,
        receivableID,
        orderResponseOrderNumber ? orderResponseOrderNumber.toString() : '',
      );
      if (emailResponse.status === 'error') {
        showFlash('An Error Occurred Sending the Confirmation Email', 'warning');
      }
    }
    showFlash('Order Successfully Created', 'success');
    history.push(routes.orders.details(orderResponseID));
  };

  const handleSubmit = (values: any) => {
    setIsLoading(true);

    const invoiceObj = generateOrderFirstInvoice(
      values.price,
      values.priceType,
      values.weightLimit,
      values.expectedSize,
      values.dumpRateDollars / 100,
      values.tax,
      values.taxExempt ? 0 : values.taxRate,
      values.cc,
      values.paymentTerm,
      values.material,
      values.otherLineItems,
      values.issueInvoice,
    );

    const orderObj = {
      ...values,
      ccRate: ccRate,
      adjustedRentalPeriod: {
        value: values.adjustedRentalPeriod,
      },
      noteOrder: null,
      sharedDeliveryNotesPrivate: values.sharedDeliveryNotesPrivate,
      customerID: values.id,
      created: new Date().toISOString(),
      id: null,
    };

    let serviceLocations = values.serviceLocations
      ? values.serviceLocations
      : [
          {
            address: values.serviceLocation.address,
            county: values.serviceLocation.county,
            coordinates: values.serviceLocation.coordinates,
          },
        ];
    if (
      values.serviceLocations &&
      (values.serviceLocations.length === 0 ||
        !values.serviceLocations.find(
          (item: ServiceAddressesType) =>
            buildAddressString(item.address) === buildAddressString(values.serviceLocation.address),
        ))
    ) {
      serviceLocations = [
        ...values.serviceLocations,
        {
          address: values.serviceLocation.address,
          county: values.serviceLocation.county,
          coordinates: values.serviceLocation.coordinates,
        },
      ];
    }
    const customerObj = {
      ...values,
      contacts: values.contacts.map((contact: Customer.AllianceCustomerContactTransport) => ({
        ...contact,
        phoneNumber: contact.phoneNumber ? formatUSPhoneNumberToE164(contact?.phoneNumber) : null,
      })),
      serviceLocations,
      creditIsFloat: true,
    };

    handleDBUpdate(orderObj, invoiceObj, customerObj, values);
    setIsLoading(false);
  };

  if (isSearchingForCustomer) return <Loading />;

  return (
    <OrderCreate
      open={true}
      onBackButtonClick={handleBackButtonClick}
      user={userProfile}
      onSubmit={handleSubmit}
      zonePricing={zonePricing}
      onGetPricing={handleGetPricing}
      isLoading={isLoading}
      startingValues={startingValues}
      onResetLead={handleResetLead}
      getOrderTotal={getOrderTotal}
      setOldCustomer={setOldCustomer}
    />
  );
};

export default OrderCreateContainer;
