import { InternalTicket } from '@alliance-disposal/transport-types';
import CustomerDetails from '../CustomerDetails';
import OrderDetails from '../OrderDetails';

type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  ticket?: InternalTicket.InternalTicketTransport;
};

export const DetailsDrawer: React.FC<DrawerProps> = ({ isOpen, onClose, ticket }) => {
  const getContent = (ticketInfo?: InternalTicket.InternalTicketTransport) => {
    if (!ticketInfo) return null;
    switch (ticketInfo.entityType) {
      case 'sourgum-order':
        return <OrderDetails id={ticketInfo.entityID} />;
      case 'sourgum-customer':
        return <CustomerDetails id={ticketInfo.entityID} />;
      default:
        return <div>Missing entity type</div>;
    }
  };

  return (
    <>
      {/* Overlay */}
      <div className={`fixed inset-0 bg-black opacity-50 z-40 ${isOpen ? 'block' : 'hidden'}`} onClick={onClose}></div>

      {/* Drawer */}
      <div
        className={`fixed top-0 right-0 w-4/5 h-full bg-slate-50 p-3 z-50 transform transition-transform ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        } overflow-auto`}
      >
        {getContent(ticket)}
      </div>
    </>
  );
};
