import { useState } from 'react';
import { Pricing } from '@alliance-disposal/transport-types';
import { SourFilters, SourSearch } from '@wayste/sour-search';
import { ButtonGroup } from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber, getPrimaryContact } from '@wayste/utils';
import { PlusIcon } from '@heroicons/react/24/solid';
import { materials, statesDetails } from '../../utils';
import ButtonSelect from '../ButtonSelect';
import Loading from '../Loading';
import HaulersPricingTable from './HaulersPricingTable';

type Props = {
  data: Pricing.PricingTransport[];
  onGetPricing: (newState: string) => void;
  view: number;
  onToggleClick: (index: number | string) => void;
  onHaulerClicked: (id: string) => void;
  onCreateNewClick: () => void;
  isLoading: boolean;
  isDefaultFilter?: boolean;
};

const haulerHeaders = ['Name', 'Office phone', 'Office email'];

const stateOptions = Object.values(statesDetails).map((item) => ({
  ...item,
  label: item.abb,
  value: item.abb,
}));

const materialOptions = Object.entries(materials).map((item) => ({
  label: item[1],
  value: item[0],
}));

const HaulerList = ({
  data,
  onGetPricing,
  view,
  onToggleClick,
  onHaulerClicked,
  onCreateNewClick,
  isLoading,
  isDefaultFilter,
}: Props) => {
  const [state, setState] = useState('');
  const [material, setMaterial] = useState<MaterialEnums | ''>('');

  const handleStateChange = (newState: string) => {
    onGetPricing(newState);
    setState(newState);
  };

  if (isLoading) return <Loading />;

  return (
    <div className="container pt-5 pb-2 px-6 flex-1 flex flex-col mx-auto">
      <div className="flex justify-center items-center pb-5">
        <div className="flex justify-end items-end w-full">
          {view === 0 ? (
            <div className="flex flex- flex-shrink-0 w-full">
              <div className=" w-full flex flex-row justify-between item-center">
                <div className="flex flex-row w-full mr-4 ">
                  <div className="flex flex-row space-x-4 w-full">
                    <SourSearch
                      options={{
                        searchPopoverFixed: true,
                        showTips: !isDefaultFilter,
                        showMessages: !isDefaultFilter,
                        showResults: !isDefaultFilter,
                        placeholder: 'Search Haulers',
                      }}
                    />
                  </div>
                </div>
                <div className="flex">
                  <SourFilters />
                  <button className="btn-primary ml-4 h-10 mr-2" onClick={() => onCreateNewClick()}>
                    <PlusIcon className="pr-1 h-5 w-5" /> New
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-1 h-10">
              <ButtonSelect
                label="State"
                menuItems={stateOptions}
                value={state}
                onSelect={handleStateChange}
                wrapperClass="mr-3"
              />
              <ButtonSelect
                label="Material"
                menuItems={materialOptions}
                value={material}
                onSelect={setMaterial}
                noneOption
              />
            </div>
          )}
        </div>

        <div className="flex h-10">
          <ButtonGroup
            buttons={[{ label: 'By Hauler' }, { label: 'By Zone' }]}
            activeBtn={view}
            onClick={onToggleClick}
          />
        </div>
      </div>

      <div className="flex flex-col flex-1 overflow-auto bg-white rounded-md shadow-dark">
        {view === 0 ? (
          <ByHaulerTable haulers={data} onHaulerClicked={onHaulerClicked} />
        ) : (
          <HaulersPricingTable
            haulersPricing={data}
            material={material}
            onRowClick={(pricing: any) => onHaulerClicked(pricing.haulerID)}
            showZoneName={true}
          />
        )}
      </div>
    </div>
  );
};

export default HaulerList;

const ByHaulerTable = ({ haulers, onHaulerClicked }: { haulers: any[]; onHaulerClicked: (id: string) => void }) => (
  <div className="flex-1 w-full overflow-x-auto">
    <table className="w-full border-collapse">
      <thead>
        <tr>
          {haulerHeaders.map((header) => (
            <td className="px-4 py-1.5 border-0 border-b border-solid border-b-gray-200 text-sm" key={header}>
              {header}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {haulers.map((hauler: any) => {
          const primaryContact = getPrimaryContact(hauler);
          return (
            <tr
              key={hauler.id}
              onClick={() => onHaulerClicked(hauler.id)}
              className={`${
                !hauler.active ? 'bg-red-300' : ''
              } hover:bg-slate-100 cursor-pointer text-sm [&>*]:px-4 [&>*]:py-1.5 [&>*]:border-0 [&>*]:border-b [&>*]:border-solid [&>*]:border-b-gray-200`}
            >
              <td>{hauler.name}</td>
              <td>{primaryContact?.phoneNumber ? formatE164ToUSPhoneNumber(primaryContact?.phoneNumber) : ''}</td>
              <td>{primaryContact?.email || ''}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
);
