import { Hauler, Pricing } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import { HaulerPricingForm } from '../HaulerPricingForm/HaulerPricingForm';
import { RollOffPricingFormProps } from '../HaulerPricingForm/HaulerPricingForm';
import { HaulerPricingFormUniversal } from '../HaulerPricingForm/HaulerPricingFormUniversal';
import { CreateUniversalPricingFormProps } from './HaulerPricingCreateContainer';

export interface HaulerPricingCreateDialogProps {
  open: boolean;
  onBackButtonClick: (data: { status: string | number }) => void;
  onSubmit: (data: RollOffPricingFormProps | CreateUniversalPricingFormProps) => Promise<void>;
  isLoading: boolean;
  hauler: Hauler.HaulerWithAapTransport;
  haulerPricingZones: Pricing.PricingTransport[];
  mode: 'Rolloff' | 'Universal';
}

const HaulerPricingCreate = ({
  open,
  onBackButtonClick,
  onSubmit,
  isLoading,
  hauler,
  mode,
  haulerPricingZones,
}: HaulerPricingCreateDialogProps) => {
  return (
    <Dialog
      styledTitle={`Create Vendor Pricing (${mode})`}
      open={open}
      className="max-w-xl"
      onClose={(e) => {
        onBackButtonClick({ status: 'cancel' });
      }}
    >
      {mode === 'Rolloff' ? (
        <HaulerPricingForm
          haulerPricingZones={haulerPricingZones}
          onSubmit={onSubmit as (data: RollOffPricingFormProps) => Promise<void>}
          onCancel={onBackButtonClick}
          isLoading={isLoading}
          hauler={hauler}
          mode={mode}
        />
      ) : (
        <HaulerPricingFormUniversal
          onSubmit={onSubmit as (data: CreateUniversalPricingFormProps) => Promise<void>}
          onCancel={onBackButtonClick}
          isLoading={isLoading}
          hauler={hauler}
          mode={mode}
        />
      )}
    </Dialog>
  );
};

export default HaulerPricingCreate;
