import React, { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { InternalTicket, Profile } from '@alliance-disposal/transport-types';
import { Button, DataGrid, DataGridProps } from '@wayste/sour-ui';
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import Loading from '../../../../components/Loading';
import CreateUpdateDialog from './CreateUpdateDialog';
import DeleteDialog from './DeleteDialog';

const TagManagement = () => {
  const client = useWaysteClient();
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState<InternalTicket.TagTransport[]>([]);
  const [showTagCreateUpdate, setShowTagCreateUpdate] = useState<{
    open: boolean;
    tag?: InternalTicket.TagTransport;
  }>({ open: false, tag: undefined });
  const [showTagDelete, setShowTagDelete] = useState<{ open: boolean; tag?: InternalTicket.TagTransport }>({
    open: false,
    tag: undefined,
  });
  const columns: DataGridProps<InternalTicket.TagTransport>['columns'] = [
    {
      key: 'name',
      name: 'Name',
    },
    {
      key: 'category',
      name: 'Category',
      formatter: ({ row }) => InternalTicket.TagCategoryLabels[row.category],
    },
    {
      key: 'description',
      name: 'Description',
      // make this take up the most space
    },
    {
      key: 'defaultAssignedTeam',
      name: 'Default Team',
      formatter: ({ row }) => (row.defaultAssignedTeam ? Profile.SourgumTeamLabels[row.defaultAssignedTeam] : ''),
    },
    {
      key: 'colors',
      name: 'Colors',
      formatter: ({ row }) => {
        if (row.backgroundColor || row.textColor) {
          return (
            <div className="flex flex-col gap-y-2">
              {row.backgroundColor && (
                <div className="flex gap-2 items-center justify-between">
                  Background
                  <div className="h-6 w-6 border border-black" style={{ backgroundColor: row.backgroundColor }} />
                </div>
              )}
              {row.textColor && (
                <div className="flex gap-2 items-center justify-between">
                  Text
                  <div className="h-6 w-6 border border-black" style={{ backgroundColor: row.textColor }} />
                </div>
              )}
            </div>
          );
        }
        return '';
      },
    },
    {
      key: 'actions',
      name: 'Actions',
      formatter: ({ row }) => {
        if (row.deletedAt) return null;
        return (
          <div className="flex gap-2">
            <button type="button" className="btn-icon">
              <PencilIcon
                className="h-6 w-6 text-edit"
                onClick={() => setShowTagCreateUpdate({ open: true, tag: row })}
              />
            </button>
            <button type="button" className="btn-icon">
              <TrashIcon className="h-6 w-6 text-delete" onClick={() => setShowTagDelete({ open: true, tag: row })} />
            </button>
          </div>
        );
      },
    },
  ];

  const handleFetchTags = async () => {
    setIsLoading(true);
    const response = await client.internalTicket().adminPortal.tag.query();
    console.log('query response: ', response);
    setTags(
      response.results.sort((a, b) =>
        Boolean(a.deletedAt) === Boolean(b.deletedAt)
          ? a.name > b.name
            ? 1
            : -1
          : Boolean(a.deletedAt) && !Boolean(b.deletedAt)
          ? 1
          : -1,
      ),
    );
    setIsLoading(false);
  };

  useEffect(() => {
    handleFetchTags();
  }, []);

  return (
    <div className="container mx-auto px-3 pt-7 pb-10 flex flex-col">
      {isLoading && <Loading fullScreen />}
      <DeleteDialog
        open={showTagDelete.open}
        tag={showTagDelete.tag as InternalTicket.TagTransport}
        onClose={() => setShowTagDelete({ open: false, tag: undefined })}
        onSuccess={handleFetchTags}
      />
      <CreateUpdateDialog
        open={showTagCreateUpdate.open}
        onClose={() => setShowTagCreateUpdate({ open: false, tag: undefined })}
        tag={showTagCreateUpdate.tag}
        onSuccess={handleFetchTags}
      />
      <h1 className="text-xl font-semibold">Manage Internal Ticket Tags</h1>
      <div className="flex justify-end mt-10 mb-5">
        <Button
          className="btn-primary"
          startIcon={<PlusIcon className="h-6 w-6" />}
          onClick={() => setShowTagCreateUpdate({ open: true, tag: undefined })}
        >
          Create New tag
        </Button>
      </div>
      <DataGrid
        rows={tags}
        columns={columns}
        loading={isLoading}
        rowHeight={65}
        makeRow={(rowData) => ({
          className: rowData.deletedAt ? 'bg-red-100' : undefined,
        })}
      />
    </div>
  );
};

export default TagManagement;
