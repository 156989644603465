import { Pricing } from '@alliance-disposal/transport-types';
import Dialog from '../Dialog';
import PricingForm from '../PricingForm';

/**
 * Component to Update Pricing for a county
 * @param {Object} pricing Pricing object being updated
 * @param {Function} onBackButtonClick Function on modal close
 * @param {Boolean} open Controls if modal is visible
 * @param {Function} onSubmit Function on update
 * @param {Boolean} isLoading Replaces submit and cancel with a loading indicator
 */
export interface PricingUpdateProps {
  pricing: Pricing.PricingTransport;
  onBackButtonClick: () => void;
  open: boolean;
  onSubmit: (data: Pricing.PricingUpdateTransport) => void;
  isLoading: boolean;
}

const PricingUpdate = ({ pricing, open, onBackButtonClick, onSubmit, isLoading }: PricingUpdateProps) => {
  return (
    <Dialog
      open={open}
      styledTitle={`Update ${pricing.zoneName} Pricing`}
      onClose={onBackButtonClick}
      className="!max-w-3xl overflow-scroll"
    >
      <PricingForm
        mode="UPDATE"
        pricing={pricing}
        onSubmit={onSubmit}
        onCancel={onBackButtonClick}
        isLoading={isLoading}
      />
    </Dialog>
  );
};

export default PricingUpdate;
