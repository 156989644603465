import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Invoice, Order } from '@alliance-disposal/transport-types';
import { Loading, Tooltip } from '@wayste/sour-ui';
import { formatServiceAddress, formatUSD, getPrimaryCustomerContact, moneyFormatter, round } from '@wayste/utils';
import { calculateOrderTotal, sortByKey } from '@wayste/utils';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import format from 'date-fns/format';
import { useHistory, useParams } from 'react-router-dom';
import CustomPricing from '../../../components/CustomPricing/CustomPricing';
import CustomerDetailsPartial from '../../../components/CustomerDetails';
import UniversalServicesDetails from '../../../components/UniversalServices/UniversalServicesDetails';
import DetailsCardWrapper from '../../../components/ui/DetailsCardWrapper';
import { OrderStatus, paymentMethods, useErrorHandlingSubscription } from '../../../utils';
import CustomerCards from './customer-cards';
import CustomerInvoices from './customer-invoices';
import CustomerOrders from './customer-orders';
import CustomerPayments from './customer-payments';

// import CustomerDetails from './CustomerDetails';

export type AllianceOrderWithReceivables = Order.AllianceOrderTransport & {
  receivables: Invoice.ReceivableTransport[];
  payables: Invoice.PayableTransport[];
  orderTotal?: number;
  quoted?: number;
};

export type PaymentRowType = {
  amount: number;
  paymentReceivedDate: string;
  paymentIdentifierString: string;
  orderNumber?: number;
  invoiceNumber: string;
  address: string;
};

const CustomerDetailsContainer = () => {
  const client = useWaysteClient();
  const history = useHistory();
  const { id }: { id: string } = useParams();

  // STATE
  const [paymentRowsData, setPaymentRowsData] = useState<PaymentRowType[]>([]);

  const [customer, setCustomer] = useState<Customer.AllianceCustomerTransport | null>(null);
  const [customerOrders, setCustomerOrders] = useState<AllianceOrderWithReceivables[]>([]);
  const [tonSpread, setTonSpread] = useState<number>(0);
  const [customerBalance, setCustomerBalance] = useState<number>(0);
  const { subscriptionData: customerData, subscribed: customerSubscribed } = useErrorHandlingSubscription(
    client.customer().adminPortal.subscriptions.subscribeToCustomer,
    'customer',
    [id],
  );

  ///////////////////////////////////////////////////////
  // FUNCTION SECTION
  ///////////////////////////////////////////////////////

  const getOrdersWithInvoice = async () => {
    const orderData = await client.order().adminPortal.query({
      allianceCustomerID: customerData.id,
      limit: 1000,
    });

    if (!orderData.results.length) return [];

    const orderIds = orderData.results.map((order) => order.id);

    const receivables = await client.invoice().adminPortal.receivable.query({
      orderID: orderIds.join(','),
    });

    const payables = await client.invoice().adminPortal.payable.query({
      orderID: orderIds.join(','),
    });

    const mapped = orderData.results.flatMap((order) => {
      const orderReceivables = receivables.filter((receivable) => receivable.invoiceDetails.orderID === order.id);
      const orderPayables = payables.filter((payable) => payable.invoiceDetails.orderID === order.id);
      return {
        ...order,
        receivables: orderReceivables,
        payables: orderPayables,
      };
    });
    return mapped as AllianceOrderWithReceivables[];
  };

  const setupOrderCustomer = async () => {
    getOrdersWithInvoice();
    const newCustomer = customerData;
    const ordersResponse = await getOrdersWithInvoice();

    let spread = 0;
    let spreadCount = 0;
    const orders: AllianceOrderWithReceivables[] = [];
    ordersResponse.forEach((order: AllianceOrderWithReceivables) => {
      if (order.actualWeightDumped) {
        const actualWeightDumped = order.actualWeightDumped.value > 0 ? order.actualWeightDumped.value : 0;
        const weightLimitValue = order.weightLimit?.value;

        if (actualWeightDumped !== undefined && weightLimitValue !== undefined) {
          spread = round(spread + (actualWeightDumped - weightLimitValue));
          spreadCount++;
        }
      }
      const orderTotal = calculateOrderTotal(order.receivables);
      const quoted = order.receivables?.find((invoice) =>
        invoice.invoiceDetails.lineItems?.find(
          (lineItem: Invoice.LineItemTransport) => lineItem.itemName === 'QP-Haul',
        ),
      )?.invoiceDetails.total;
      orders.push({ ...order, orderTotal, quoted });
    });

    if (spreadCount > 0) {
      setTonSpread(parseFloat((spread / spreadCount).toFixed(2)));
    }

    const sortedOrders = sortByKey('orderNumber', orders, true);

    setCustomerOrders(sortedOrders);
    setNewCustomer(newCustomer, sortedOrders);
  };

  const setNewCustomer = (
    newCustomer: Customer.AllianceCustomerTransport,
    customerOrders: AllianceOrderWithReceivables[],
  ) => {
    const paymentRows: PaymentRowType[] = [];
    let remainingCustomerBalance = 0;
    customerOrders.forEach((order: AllianceOrderWithReceivables) => {
      Object.values(order.receivables).forEach((invoice: Invoice.ReceivableTransport) => {
        if (order.status !== OrderStatus.CANCELLED && !invoice.invoiceDetails.void) {
          remainingCustomerBalance = Math.round(remainingCustomerBalance + invoice.invoiceDetails.remainingBalance);
        }
        invoice.invoiceDetails.payments?.forEach((payment: Invoice.PaymentTransport) => {
          paymentRows.push({
            orderNumber: order.orderNumber,
            invoiceNumber: invoice.invoiceDetails.invoiceNumber || '',
            amount: payment.amount,
            paymentReceivedDate: payment.paymentReceivedDate || payment.date,
            paymentIdentifierString: payment.paymentIdentifier
              ? `${paymentMethods[payment.paymentMethod as keyof typeof paymentMethods]} ${payment.paymentIdentifier}`
              : 'N/A',
            address: formatServiceAddress(order.serviceLocation.address),
          });
        });
      });
    });

    setPaymentRowsData(sortByKey('orderNumber', paymentRows, true));
    setCustomer(newCustomer);
    setCustomerBalance(remainingCustomerBalance);
  };

  const handleBackButtonClick = () => {
    history.goBack();
  };

  ///////////////////////////////////////////////////////
  // HOOKS SECTION
  ///////////////////////////////////////////////////////

  useEffect(() => {
    if (customerData) {
      setupOrderCustomer();
    } else {
      setCustomer(null);
    }
  }, [customerData, customerSubscribed]);

  ///////////////////////////////////////////////////////
  // RENDER SECTION
  ///////////////////////////////////////////////////////

  if (!customer) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Loading size="h-12 w-12" />
      </div>
    );
  }

  const topCardDetails = [
    { label: 'Account #', value: customer.customerNumber },
    { label: 'Balance', value: formatUSD(customerBalance) },
    { label: 'Credit', value: moneyFormatter(customer.credit) },
    { label: 'Order Count', value: customerOrders.length },
    {
      label: (
        <Tooltip text="Only orders with tons dumped and ton limit">
          <span>Avg Ton Spread</span>
        </Tooltip>
      ),
      value: (
        <span className={tonSpread > 0 ? 'text-red-500' : tonSpread < 0 ? 'text-success' : ''}>
          {tonSpread > 0 ? '+' : ''}
          {tonSpread}
        </span>
      ),
    },
    {
      label: 'Last Ordered',
      value: customerOrders[0] ? format(new Date(customerOrders[0].expectedDeliveryDate), 'MM/dd/yy') : 'N/A',
    },
  ];

  return (
    <div className="bg-gray-50 h-full">
      <div className="container mx-auto p-6 pt-5">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button className="btn-icon" type="button" onClick={handleBackButtonClick}>
            <ChevronLeftIcon className="h-5 w-5" />
          </button>
          <h5 className="text-xl">Customer Details</h5>
        </div>
        <DetailsCardWrapper
          heading={
            customer.companyName ||
            getPrimaryCustomerContact(customer)?.firstName + ' ' + getPrimaryCustomerContact(customer)?.lastName
          }
          chips={
            customer.doNotService
              ? [{ label: 'Do Not Service', primaryColor: 'error-light', textColor: 'error-dark', size: 'small' }]
              : []
          }
        >
          <div className="flex gap-2">
            {topCardDetails.map((item, index) => (
              <div key={item.label + String(index)} className="border-r border-r-gray-200 text-center px-4">
                <div>
                  <div className="text-gray-700 mb-2">{item.label}</div>
                  <div>{item.value}</div>
                </div>
              </div>
            ))}
          </div>
        </DetailsCardWrapper>
        <CustomerDetailsPartial customer={customer} edit={true} />
        <CustomPricing customer={customer} />
        <CustomerCards customer={customer} />
        <CustomerInvoices customer={customer} />
        <CustomerOrders customer={customer} orders={customerOrders} />
        <CustomerPayments customer={customer} payments={paymentRowsData} />
        {customer.id && <UniversalServicesDetails customerID={customer.id} />}
      </div>
    </div>
  );
};

export default CustomerDetailsContainer;
