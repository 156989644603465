import { useState } from 'react';
import { Pricing } from '@alliance-disposal/transport-types';
import { formatUSD, moneyFormatter, sortByKey } from '@wayste/utils';
import { MapIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import { materials, priceTypes, priceTypesEnums } from '../../utils';
import AuditLogTable from '../AuditLogTable';
import Dialog from '../Dialog';
import Loading from '../Loading';
import MapEditorModal from '../MapEditor/MapEditorModal';
import DetailsCardWrapper from '../ui/DetailsCardWrapper';
import ApprovePricingChangeDialog from './ApprovePricingChangeDialog';

type Props = {
  isLoading: boolean;
  pricing: Pricing.PricingTransport[];
  onEditClicked: (pricing: Pricing.PricingTransport) => void;
  onDeleteClicked: (pricing: Pricing.PricingTransport) => void;
  onAddMaterialClicked: (pricing: Pricing.PricingTransport) => void;
  onEditMaterialClicked: (pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => void;
  onServiceAreaSubmit: (data: any, zone: any) => void;
  onRefreshPricing: () => void;
};

const materialHeadings = ['Material', 'Price Type', 'Haul', 'Dump', 'Sizes', 'Last Updated', ''];

/**
 * Displays all pricing for a hauler broken down by county
 * @param {[Object]} pricing Array of pricing objects which have all material in an [Object]
 * @param {Boolean} isLoading When true replaces card with loading spinner
 * @param {Function} onEditClicked Function to run when edit pricing button clicked
 * @param {Function} onDeleteClicked Function to run when delete pricing button clicked
 * @param {Function} onAddMaterialClicked Run function on Add Material Pricing clicked
 * @param {Function} onEditMaterialClicked Run function on edit material icon clicked
 */
const HaulerPricingList = ({
  pricing,
  isLoading,
  onEditClicked,
  onDeleteClicked,
  onAddMaterialClicked,
  onEditMaterialClicked,
  onServiceAreaSubmit,
  onRefreshPricing,
}: Props) => {
  const [showPricingChangesID, setShowPricingChangesID] = useState<string | null>(null);
  const [showPricingChangeDialog, setShowPricingChangeDialog] = useState<{
    zoneID: string;
    materialID: string;
  }>({ zoneID: '', materialID: '' });

  const handleViewPricingChanges = async (id: string) => {
    setShowPricingChangesID(id);
  };

  if (isLoading) return <Loading />;

  return (
    <>
      {/* Page Content */}
      {pricing.map((zone) => (
        <MaterialPricingCard
          key={zone.id}
          zone={zone}
          zones={pricing}
          onEditClicked={onEditClicked}
          onDeleteClicked={onDeleteClicked}
          onAddMaterialClicked={onAddMaterialClicked}
          onEditMaterialClicked={onEditMaterialClicked}
          onServiceAreaSubmit={onServiceAreaSubmit}
          onViewPricingChanges={handleViewPricingChanges}
          onViewAcceptPricingChange={setShowPricingChangeDialog}
        />
      ))}
      <Dialog
        styledTitle="Pricing Change Log"
        open={Boolean(showPricingChangesID)}
        className="max-w-screen-2xl"
        onClose={() => setShowPricingChangesID(null)}
        showX
      >
        {showPricingChangesID ? <AuditLogTable entityID={showPricingChangesID} entityType="Pricing" /> : null}
      </Dialog>
      <ApprovePricingChangeDialog
        open={Boolean(showPricingChangeDialog.zoneID)}
        onClose={() => setShowPricingChangeDialog({ zoneID: '', materialID: '' })}
        onSuccess={() => {
          setShowPricingChangeDialog({ zoneID: '', materialID: '' });
          onRefreshPricing();
        }}
        zoneID={showPricingChangeDialog.zoneID}
        materialID={showPricingChangeDialog.materialID}
      />
    </>
  );
};

export default HaulerPricingList;

type MaterialCardProps = {
  zone: Pricing.PricingTransport;
  zones: Pricing.PricingTransport[];
  onEditClicked: (pricing: Pricing.PricingTransport) => void;
  onDeleteClicked: (pricing: Pricing.PricingTransport) => void;
  onAddMaterialClicked: (pricing: Pricing.PricingTransport) => void;
  onEditMaterialClicked: (pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => void;
  onServiceAreaSubmit: (data: any, zone: any) => void;
  onViewPricingChanges: (id: string) => void;
  onViewAcceptPricingChange: React.Dispatch<
    React.SetStateAction<{
      zoneID: string;
      materialID: string;
    }>
  >;
};

const MaterialPricingCard = ({
  zone,
  zones,
  onEditClicked,
  onDeleteClicked,
  onAddMaterialClicked,
  onEditMaterialClicked,
  onServiceAreaSubmit,
  onViewPricingChanges,
  onViewAcceptPricingChange,
}: MaterialCardProps) => {
  const [editServiceArea, setEditServiceArea] = useState(false);

  return (
    <DetailsCardWrapper
      heading={zone.zoneName || ''}
      buttons={[
        {
          label: (
            <>
              <PlusIcon className="h-5 w-5 mr-1.5" />
              Add Material Pricing
            </>
          ),
          onClick: () => onAddMaterialClicked(zone),
        },
        {
          label: (
            <>
              <PencilIcon className="h-4 w-4 mr-1.5" />
              Edit Zone Details
            </>
          ),
          onClick: () => onEditClicked(zone),
        },
        {
          label: (
            <>
              <MapIcon className="h-5 w-5 mr-1.5" />
              Edit Pricing Zone
            </>
          ),
          onClick: () => setEditServiceArea(true),
        },
        {
          label: (
            <>
              <TrashIcon className="h-5 w-5 mr-1.5" />
              Delete Pricing Zone
            </>
          ),
          onClick: () => onDeleteClicked(zone),
          delete: true,
        },
        {
          label: 'View Change History',
          onClick: () => onViewPricingChanges(zone.id),
        },
      ]}
    >
      {/* Modals */}
      <MapEditorModal
        open={editServiceArea}
        serviceAreas={zone?.serviceAreas}
        zones={zones}
        onClose={() => {
          setEditServiceArea(false);
        }}
        onSubmit={(data) => onServiceAreaSubmit(data, zone)}
      />
      <div style={{ marginBottom: 16 }}>
        <Grid container spacing={3}>
          <Grid item>
            <span
              style={{
                marginRight: 10,
                color: 'rgba(0, 0, 0, 0.54)',
                marginBottom: 16,
              }}
            >
              State:
            </span>
            {zone.state}
          </Grid>
          <Grid item>
            <span
              style={{
                marginRight: 10,
                color: 'rgba(0, 0, 0, 0.54)',
                marginBottom: 16,
              }}
            >
              Trip Charge:
            </span>
            {zone.tripCharge ? moneyFormatter(zone.tripCharge) : ''}
          </Grid>
          <Grid item>
            <span
              style={{
                marginRight: 10,
                color: 'rgba(0, 0, 0, 0.54)',
                marginBottom: 16,
              }}
            >
              Last Updated:
            </span>
            {/* {format(new Date(zone.updated), 'MM/dd/yy')} */}
          </Grid>
          {!zone.sourgumApproved && (
            <Grid item>
              <IconButton
                onClick={() =>
                  onViewAcceptPricingChange({
                    zoneID: zone.id,
                    materialID: '',
                  })
                }
              >
                <Tooltip title="Pricing has not been approved by Sourgum">
                  <ExclamationTriangleIcon className="h-6 w-6 text-warning" />
                </Tooltip>
              </IconButton>
            </Grid>
          )}
          <Grid item xs={12}>
            <span
              style={{
                marginRight: 10,
                color: 'rgba(0, 0, 0, 0.54)',
                marginBottom: 16,
              }}
            >
              Notes:
            </span>
            {zone.notes}
          </Grid>
        </Grid>
      </div>
      <div style={{ margin: '0 -20px -20px' }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {materialHeadings.map((heading) => (
                  <TableCell key={heading} style={{ backgroundColor: '#F9F9F9', whiteSpace: 'nowrap' }}>
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortByKey('material', zone.pricingData).map((material: Pricing.PricingDataTransport, index: number) => {
                if (material.sizes.length === 0) {
                  return (
                    <TableRow key={index}>
                      <TableCell>{materials[material.material as keyof typeof materials]}</TableCell>
                      <TableCell colSpan={5}>
                        <span>No sizes for this material</span>
                      </TableCell>
                      <TableCell>
                        <IconButton size="small" edge="end" onClick={() => onEditMaterialClicked(zone, material)}>
                          <PencilIcon className="h-5 w-5 text-edit" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                }
                if (material.doesNotService) {
                  return (
                    <TableRow key={index}>
                      <TableCell>{materials[material.material as keyof typeof materials]}</TableCell>
                      <TableCell colSpan={5}>
                        <span>Does not service</span>
                      </TableCell>
                      <TableCell>
                        <IconButton size="small" edge="end" onClick={() => onEditMaterialClicked(zone, material)}>
                          <PencilIcon className="h-5 w-5 text-edit" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                }
                return (
                  <TableRow key={index}>
                    <TableCell>{materials[material.material as keyof typeof materials]}</TableCell>
                    <TableCell>{priceTypes[material.type as keyof typeof priceTypes]}</TableCell>
                    <TableCell>
                      {material.type !== priceTypesEnums.flat &&
                      material.sizes.every((size: any) => size.haul === material.sizes[0].haulDollars) &&
                      material.sizes.every((size: any) => size.tonLimit === 0)
                        ? `$${material.sizes[0].haul}`
                        : Object.values(material.sizes).map((item: any) => (
                            <div key={item.size}>
                              <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}>{item.size}:</span> ${item.haulDollars}
                              {material.type === priceTypesEnums.ton &&
                              material.sizes.some((size: any) => size.tonLimit) ? (
                                <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}> TL: {item.tonLimit}</span>
                              ) : null}
                              {', '}
                            </div>
                          ))}
                    </TableCell>
                    {material.type !== priceTypesEnums.flat ? (
                      <TableCell>
                        {material.sizes.every((item: any) => item.dump === material.sizes[0].dump) &&
                        material.sizes[0].dumpDollars
                          ? formatUSD(material.sizes[0].dumpDollars)
                          : material.sizes.map((item: any) => (
                              <div key={item.size}>
                                <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}>{item.size}:</span> ${item.dumpDollars}
                              </div>
                            ))}
                      </TableCell>
                    ) : (
                      <TableCell />
                    )}
                    {
                      <TableCell>
                        {material.sizes.map((size: any, index: number) => (
                          <span key={size.size}>
                            {size.size}
                            {index === material.sizes.length - 1 ? '' : ', '}
                          </span>
                        ))}
                      </TableCell>
                    }
                    <TableCell>
                      {material.lastUpdatedAt ? format(new Date(material.lastUpdatedAt), 'MM/dd/yy') : ''}
                    </TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton size="small" edge="end" onClick={() => onEditMaterialClicked(zone, material)}>
                          <PencilIcon className="h-5 w-5 text-edit" />
                        </IconButton>
                        {!material.sourgumApproved && (
                          <IconButton
                            style={{ marginLeft: 15 }}
                            onClick={() =>
                              onViewAcceptPricingChange({
                                zoneID: zone.id,
                                materialID: material.id || '',
                              })
                            }
                          >
                            <Tooltip title="Pricing has not been approved by Sourgum">
                              <ExclamationTriangleIcon className="h-6 w-6 text-warning" />
                            </Tooltip>
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </DetailsCardWrapper>
  );
};
