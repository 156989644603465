import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice } from '@alliance-disposal/transport-types';
import { UIContext } from '../../contexts';
import { OrderStatus, allianceHaulerID } from '../../utils';
import Dialog from '../Dialog';
import Loading from '../Loading';
import ReviewPayables from './ReviewPayables';

type Props = {
  open: boolean;
  onClose: () => void;
};

const PayablesReview = ({ open, onClose }: Props) => {
  const client = useWaysteClient();
  const { showFlash } = useContext<any>(UIContext);
  const [isLoading, setIsLoading] = useState(false);
  const [payables, setPayables] = useState<Invoice.PayableTransport[]>([]);

  const handleGetPayables = async () => {
    setIsLoading(true);

    const response = await client.invoice().adminPortal.payable.query({
      void: false,
      paidInFull: false,
      readyForPayment: false,
    });
    const payableData = response;

    console.log('payableData: ', payableData);
    const filteredPayableData = payableData.filter((item: Invoice.PayableTransport) => item.invoiceDetails.total !== 0);

    const orderIDs: Array<string | undefined> = filteredPayableData.map(
      (item: Invoice.PayableTransport) => item.invoiceDetails.orderID,
    );

    const uniqueOrderIDs = [...Array.from(new Set(orderIDs))];

    const uniqueOrders: any = {};
    for (const orderID of uniqueOrderIDs) {
      if (!orderID) continue;

      const orderResponse = await client.order().adminPortal.fetch(orderID);

      if (orderResponse) uniqueOrders[orderResponse.id] = orderResponse;
    }

    const payablesWithOrders = filteredPayableData.map((item: any) => ({
      ...item,
      order: uniqueOrders[item.invoiceDetails.orderID],
    }));

    const filteredPayables = payablesWithOrders
      .filter(
        (payable: any) =>
          payable.order &&
          payable.haulerID !== allianceHaulerID &&
          !payable.order.open &&
          !payable.invoiceDetails.readyForPayment &&
          (payable.order.status === OrderStatus.COMPLETED || payable.order.status === OrderStatus.CANCELLED),
      )
      .sort((a: any, b: any) => {
        if (a.order.orderNumber > b.order.orderNumber) return 1;
        if (b.order.orderNumber > a.order.orderNumber) return -1;
        if (a.invoiceNumber > b.invoiceNumber) return 1;
        return -1;
      });
    setPayables(filteredPayables);
    setIsLoading(false);
  };

  useEffect(() => {
    if (open) handleGetPayables();
  }, [open]);

  const onBillUpdate = async (payable: any, onSuccess: () => boolean) => {
    try {
      await client.invoice().adminPortal.payable.update(payable.id, payable);
      onSuccess();
      showFlash('Payables Successfully Updated', 'success');
    } catch (error) {
      console.warn('PayablesReview update payable error: ', error);
      showFlash('An error occurred updating payable', 'warning');
    }
  };

  if (!open) return null;
  if (isLoading) return <Loading fullScreen />;

  return (
    <Dialog styledTitle="Bills Review" open={open} fullScreen onClose={onClose}>
      <div>
        <ReviewPayables payables={payables} onBackButtonClick={onClose} onBillUpdate={onBillUpdate} />
      </div>
    </Dialog>
  );
};

export default PayablesReview;
