import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Pricing } from '@alliance-disposal/transport-types';
import { UIContext } from '../../contexts';
import PricingUpdate from './PricingUpdate';

/**
 * Container to Update a county/material pricing
 * @param {Object} pricing Pricing object to be updated
 * @param {Boolean} open Controls if modal is open
 * @param {Function} onBackButtonClick Function on modal closing or submit
 */

export interface PricingUpdateContainerProps {
  pricing: Pricing.PricingTransport;
  open: boolean;
  onBackButtonClick: any;
}

const PricingUpdateContainer = ({ pricing, open, onBackButtonClick }: PricingUpdateContainerProps) => {
  const { showFlash } = useContext(UIContext);
  const client = useWaysteClient();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (data: Pricing.PricingUpdateTransport) => {
    if (!data.id) {
      showFlash('An Error Occurred', 'warning');
      return;
    }
    setIsLoading(true);
    console.log('PRICING DATA  ', data);
    try {
      await client.pricing().adminPortal.pricing.update(data.id, data);
      showFlash('Pricing Successfully Updated', 'success');
      onBackButtonClick({ status: 'success' });
    } catch (error) {
      showFlash('An Error Occurred', 'warning');
    }
    setIsLoading(false);
  };

  return (
    <PricingUpdate
      pricing={pricing}
      onBackButtonClick={onBackButtonClick}
      open={open}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
};

export default PricingUpdateContainer;
