import React from 'react';

export interface CardInfoListData {
  label: string;
  value: React.ReactNode;
}

interface CardInfoListProps {
  data: Array<CardInfoListData>;
  heading?: string;
  border?: boolean;
  containerSize?: number;
}

const CardInfoList = ({ data, heading, border }: CardInfoListProps) => {
  return (
    <div className={`h-full ${border ? 'lg:border-r lg:pr-4' : ''}`}>
      <div className={`grid grid-cols-3 gap-2 h-fit`}>
        {heading ? <div className="font-medium pb-2 col-span-3">{heading}</div> : null}
        {data.map((item) => (
          <React.Fragment key={item.label}>
            <div>
              <span className="text-gray-500">{item.label}:</span>
            </div>
            <div className="col-span-2 whitespace-pre-line">{item.value}</div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CardInfoList;
