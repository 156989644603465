import { Invoice, Order } from '@alliance-disposal/transport-types';
import { pricingBreakdownTotal, quotedPriceBreakdown, round } from '@wayste/utils';
import { addDays, isBefore, startOfDay } from 'date-fns';
import { ccRate } from './pricing-utils';
import {
  OrderStatus,
  allianceHaulerID,
  materialEnums,
  materials,
  paymentMethodsEnums,
  paymentTermsEnums,
  priceTypesEnums,
  quotedPriceItems,
} from './shared-types';

export const invoiceStatusTypes = {
  paid: 'paid',
  invoiced: 'invoiced',
  overdue: 'overdue',
  draft: 'draft',
  none: 'none',
  PAST_DUE: 'PAST_DUE',
  null: null,
};

export const receivableLineItemOptions = [
  'Contamination Fee',
  'Discount',
  'New Customer Promotion',
  'Military Discount',
  'Loyalty Discount',
  'Overage',
  'Overfill Fee',
  'Porta Potty Rental',
  'Rental Extension',
  'Trip Charge',
  'Upgrade Fee',
  'QP-Haul',
  'QP-Dump',
  'QP-Adj',
];

export const allReceivableLineItemOptions = [
  'Contamination Fee',
  'Discount',
  'New Customer Promotion',
  'Military Discount',
  'Loyalty Discount',
  'Overage',
  'Overfill Fee',
  'Porta Potty Rental',
  'Rental Extension',
  'Pre-Paid Rental Extension',
  'Trip Charge',
  'Upgrade Fee',
  'QP-Haul',
  'QP-Dump',
  'QP-Adj',
  'CC Fee',
];

// interface InvoiceItemTypeExtended extends InvoiceItemType {
// 	total: number
// }

/**
 * Builds an array of QP Invoice Items for a quoted price
 * @param {Number} price Quoted Price (total)
 * @param {priceTypesEnums} priceType Price Type
 * @param {Number} tonLimit Ton limit
 * @param {Number} size Size of the dumpster
 * @param {Number} dumpRate Dump rate
 * @param {Boolean} tax Should tax be charged on the order
 * @param {Number} taxRate Tax rate
 * @param {Boolean} cc Should credit card be charged on the order
 * @param {String} paymentTerm Payment Term Type
 * @param {String} material Material for order
 * @return Returns [{...LineItemModel}]
 */

export const generateOrderFirstInvoice = (
  price: number,
  priceType: string,
  tonLimit: number,
  size: number,
  dumpRate: number,
  tax: boolean,
  taxRate: number,
  cc: boolean,
  paymentTerm: string,
  material: string,
  otherLineItems: Invoice.LineItemInputTransport[],
  issueInvoice?: 'NOW' | 'DRAFT',
) => {
  const quotedBreakdown = quotedPriceBreakdown(
    +price,
    priceType === priceTypesEnums.ton ? +tonLimit : priceType === priceTypesEnums.yard ? +size : 0,
    +dumpRate || 0,
    tax ? taxRate : 0,
    cc ? ccRate : 0,
  );

  console.log('quotedBreakdown: ', quotedBreakdown);

  let invoiceItems = [];

  const haulItem: Invoice.LineItemInputTransport = {
    itemName: quotedPriceItems.haul,
    quantity: 1,
    unitPriceDollars: +quotedBreakdown.haul,
    taxable: tax ? true : false,
    description: material === materialEnums.transportation ? materials.transportation : '',
  };
  if (haulItem.unitPriceDollars) {
    haulItem.totalPriceDollars = round(haulItem.unitPriceDollars * haulItem.quantity);
  } else {
    haulItem.totalPriceDollars = 0;
  }

  const dumpItem: Invoice.LineItemInputTransport = {
    itemName: quotedPriceItems.dump,
    quantity: priceType === priceTypesEnums.ton ? +tonLimit : priceType === priceTypesEnums.yard ? +size : 0,
    unitPriceDollars: +dumpRate || 0,
    taxable: false,
    description: '',
  };
  if (dumpItem.unitPriceDollars) {
    dumpItem.totalPriceDollars = round(dumpItem.unitPriceDollars * dumpItem.quantity);
  } else {
    dumpItem.totalPriceDollars = 0;
  }

  invoiceItems = [haulItem, dumpItem];

  if (quotedBreakdown.adj) {
    const adjItem: Invoice.LineItemInputTransport = {
      itemName: quotedPriceItems.adj,
      quantity: 1,
      unitPriceDollars: quotedBreakdown.adj,
      taxable: false,
      description: '',
    };
    if (adjItem.unitPriceDollars) {
      adjItem.totalPriceDollars = round(adjItem.unitPriceDollars * adjItem.quantity);
    } else {
      adjItem.totalPriceDollars = 0;
    }
    invoiceItems.push(adjItem);
  }

  let ccTotal = quotedBreakdown.cc;

  if (otherLineItems && otherLineItems.length > 0) {
    otherLineItems.forEach((item) => {
      const otherBreakdown = pricingBreakdownTotal(
        item.totalPriceDollars || 0,
        item.quantity,
        tax && item.taxable ? taxRate : 0,
        cc ? ccRate : 0,
      );
      ccTotal = round(ccTotal + otherBreakdown.ccFee);
      const otherLineItemsItem: Invoice.LineItemInputTransport = {
        ...item,
        unitPriceDollars: item.unitPriceDollars || otherBreakdown.unitPriceDollars,
      };
      if (otherLineItemsItem.unitPriceDollars) {
        otherLineItemsItem.totalPriceDollars = round(otherLineItemsItem.unitPriceDollars * otherLineItemsItem.quantity);
      }
      invoiceItems.push(otherLineItemsItem);
    });
  }

  if (ccTotal) {
    const ccItem: Invoice.LineItemInputTransport = {
      itemName: 'CC Fee',
      quantity: 1,
      unitPriceDollars: ccTotal,
      taxable: false,
      description: '',
    };
    if (ccItem.unitPriceDollars) {
      ccItem.totalPriceDollars = round(ccItem.unitPriceDollars * ccItem.quantity);
    } else {
      ccItem.totalPriceDollars = 0;
    }

    invoiceItems.push(ccItem);
  }

  const taxAmountDollars = invoiceItems.reduce(
    (total: number, item: any) => round(total + (item.taxable ? item.totalPriceDollars * (tax ? taxRate : 0) : 0)),
    0,
  );

  let dueDate = issueInvoice === 'NOW' ? new Date() : null;
  if (paymentTerm.includes('net') && issueInvoice === 'NOW') {
    const netTerm = +paymentTerm.replace(/^\D+/g, '');
    const issueDate = new Date();
    dueDate = addDays(issueDate, netTerm);
  }

  const invoiceObj = {
    taxRate: tax ? taxRate : 0,
    lineItems: invoiceItems,
    invoiceNumber: '1',
    issueDate: issueInvoice === 'NOW' ? new Date() : null,
    paymentTerm: paymentTerm,
    dueDate: dueDate,
    taxAmountDollars,
  };

  console.log('invoiceObj ins: ', invoiceObj);

  return invoiceObj;
};

/**
 * Returns the invoice/payment status of an order and it's invoices
 * @param {Object} order An order object
 * @returns enum: paid || invoiced || overdue || null
 */
export const getOrderInvoiceStatus = (
  order: Order.AllianceOrderTransport & {
    receivables: Invoice.ReceivableTransport[];
    payables: Invoice.PayableTransport[];
  },
): Invoice.InvoiceStatus | 'NONE' => {
  if (!order.receivables || order.receivables.length <= 0) return 'NONE';
  const paid =
    order.receivables.length > 0 &&
    order.receivables.every((invoice) => invoice?.invoiceDetails?.paidInFull || invoice?.invoiceDetails?.void);
  if (paid) return 'PAID';
  if (order.receivables.every((item) => item?.invoiceDetails?.status === 'DRAFT')) return 'DRAFT';
  if (order.paymentTerm === paymentTermsEnums.onCharge) return 'PAST_DUE';

  let earliestIssueDate: string | null = null;
  if (order.receivables.some((item) => item?.invoiceDetails?.issueDate)) {
    earliestIssueDate = order.receivables?.reduce<null | string>((min, current) => {
      if (!current?.invoiceDetails?.issueDate) return min;
      if (!min) return current.invoiceDetails.issueDate;

      return min < current.invoiceDetails.issueDate ? min : current.invoiceDetails.issueDate;
    }, null);
  }

  if (order.paymentTerm === paymentTermsEnums.onDump || order?.paymentTerm?.includes('net')) {
    if (order.status !== OrderStatus.DUMPED) return 'PENDING';

    if (earliestIssueDate) {
      if (
        order.paymentTerm === paymentTermsEnums.onDump &&
        ((order.paymentMethod === paymentMethodsEnums.creditCard &&
          isBefore(new Date(), startOfDay(addDays(new Date(earliestIssueDate), 2)))) ||
          (order.paymentMethod === paymentMethodsEnums.check &&
            isBefore(new Date(), startOfDay(addDays(new Date(earliestIssueDate), 7)))))
      ) {
        return 'PENDING';
      }
      if (order.paymentTerm.includes('net')) {
        const netTerm = +order.paymentTerm.replace(/^\D+/g, '') + 7;
        if (isBefore(new Date(), startOfDay(addDays(new Date(earliestIssueDate), netTerm)))) {
          return 'PENDING';
        }
      }
    }

    return 'PAST_DUE';
  }

  if (!paid) return 'PAST_DUE';
  return 'PENDING';
};

/**
 * Returns the invoice/payment status of an order and it's invoices
 * @param {Object} order An order object
 * @returns enum: paid || invoiced || overdue || null
 */
export const getStatusFromReceivables = (
  receivables?: Invoice.ReceivableTransport[],
): Invoice.InvoiceStatus | 'NONE' => {
  if (!receivables || receivables.length <= 0) return 'NONE';
  const paid =
    receivables.length > 0 &&
    receivables.every((invoice) => invoice?.invoiceDetails?.paidInFull || invoice?.invoiceDetails?.void);
  if (paid) return 'PAID';
  if (receivables.every((item) => item?.invoiceDetails?.status === 'DRAFT')) return 'DRAFT';
  if (receivables.some((item) => item?.invoiceDetails?.status === 'PAST_DUE')) return 'PAST_DUE';
  return 'PENDING';
};

// /**
//  * Returns the bill payment status of an order and it's invoices
//  * @param {Object} order An order object
//  * @returns enum: paid || invoiced || overdue || null
//  */
// export const getOrderBillStatus = (order: OrderModel) => {
//   if (order.bills.length === 0) {
//     return invoiceStatusTypes.none;
//   }

//   const paid =
//     order.bills.length > 0 &&
//     order.bills.every((invoice) => {
//       return invoice.haulerID === allianceHaulerID ? true : invoice.paidInFull;
//     });

//   // if there is no issue date on the invoice, it is a draft
//   if (order.bills.some((bill) => !bill.issueDate)) {
//     // console.log("bill is draft " + order.orderNumber);
//     return invoiceStatusTypes.draft;
//   }

//   return paid ? invoiceStatusTypes.paid : invoiceStatusTypes.overdue;
// };

/**
 * Returns the bill payment status of an order and it's invoices
 * @param {Object} order An order object
 * @returns enum: paid || invoiced || overdue || null
 */
export const getOrderBillStatus2 = (
  order:
    | Order.AllianceOrderTransport & {
        receivables: Invoice.ReceivableTransport[];
        payables: Invoice.PayableTransport[];
      },
): Invoice.InvoiceStatus | 'NONE' => {
  if (order?.payables?.length === 0 || !order.payables) {
    return 'NONE';
  }

  const paid =
    order.payables.length > 0 &&
    order.payables.every(
      (invoice) =>
        invoice.invoiceDetails.paidInFull || invoice.invoiceDetails.void || invoice.haulerID === allianceHaulerID,
    );
  if (paid) return 'PAID';

  // if there is no issue date on the invoice, it is a draft
  if (order.payables.some((bill) => !bill?.invoiceDetails?.issueDate)) {
    return 'DRAFT';
  }

  return 'PAST_DUE';
};
export const getOrderBillStatusFromPayables = (
  payables?: Invoice.PayableTransport[],
): Invoice.InvoiceStatus | 'NONE' => {
  if (payables?.length === 0 || !payables) {
    return 'NONE';
  }

  const paid =
    payables.length > 0 &&
    payables.every(
      (invoice) =>
        invoice.invoiceDetails.paidInFull || invoice.invoiceDetails.void || invoice.haulerID === allianceHaulerID,
    );
  if (paid) return 'PAID';

  // if there is no issue date on the invoice, it is a draft
  if (payables.some((bill) => !bill?.invoiceDetails?.issueDate)) {
    return 'DRAFT';
  }

  return 'PAST_DUE';
};

// /**
//  * Returns the bill payment status of an order and it's invoices
//  * @param {Object} order  object
//  * @returns enum: paid || invoiced || overdue || null
//  */
export const getOrderBillStatusServiceOrders = (payables: Invoice.PayableTransport[]) => {
  if (payables?.length === 0 || !payables) {
    return invoiceStatusTypes.none;
  }
  const paid =
    payables.length > 0 &&
    payables.every((invoice) => invoice.invoiceDetails.paidInFull || invoice.invoiceDetails.void);
  if (paid) return invoiceStatusTypes.paid;

  // if there is no issue date on the invoice, it is a draft
  if (payables.some((bill) => !bill?.invoiceDetails?.issueDate)) {
    return invoiceStatusTypes.draft;
  }

  return invoiceStatusTypes.overdue;
};

// export const getInvoicedDate = (invoice: InvoiceModel, order: OrderType) => {
//   if (invoice.issueDate) return new Date(invoice.issueDate);
//   if (order.paymentTerm.includes('net') && !invoice.issueDate) return new Date();
//   if (order.status === 'dumped') return new Date(order.expectedPickupDate);
//   return new Date(order.expectedDeliveryDate);
// };

// export const getInvoiceDueDate = (invoice: InvoiceModel, order: OrderType) => {
//   let dueDate = order.status === 'dumped' ? new Date(order.expectedPickupDate) : new Date(order.expectedDeliveryDate);
//   if (order.paymentTerm.includes('net')) {
//     const netTerm = +order.paymentTerm.replace(/^\D+/g, '');
//     const issueDate = invoice.issueDate ? new Date(invoice.issueDate) : new Date();
//     dueDate = addDays(issueDate, netTerm);
//   }
//   return dueDate;
// };

// export const calculateActualOrderProfit = (order: OrderType | OrderModel) => {
//   let invoicesTotal = 0;
//   let billsTotal = 0;
//   let totalCCFees = 0;
//   order.invoices.forEach((invoice: InvoiceModel) => {
//     let hasCCFee = false;
//     invoice.lineItems.forEach((lineItem: LineItemModel) => {
//       if (lineItem.itemName === 'CC Fee') hasCCFee = true;
//       invoicesTotal = invoicesTotal + lineItem.totalPriceDollars;
//     });
//     if (hasCCFee) {
//       const ccFee = invoice.totalDollars * 0.029 + 0.3;
//       totalCCFees = totalCCFees + ccFee;
//     }
//   });
//   order.bills.forEach((bill: InvoiceModel) => {
//     bill.lineItems.forEach((lineItem: LineItemModel) => {
//       billsTotal = billsTotal + lineItem.totalPriceDollars;
//     });
//   });
//   billsTotal = billsTotal + totalCCFees;
//   return round(invoicesTotal - billsTotal);
// };
