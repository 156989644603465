import React, { useEffect, useRef, useState } from 'react';
import { Customer, Pricing } from '@alliance-disposal/transport-types';
import { Chip } from '@wayste/sour-ui';
import { US_PHONE_NUMBER_REGEXP } from '@wayste/utils';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import useMediaQuery from '@mui/material/useMediaQuery';
import structuredClone from '@ungap/structured-clone';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  channelTypes,
  commercialLocationTypes,
  customerMotivations,
  customerTypes,
  departmentTypes,
  paymentMethodsEnums,
  paymentTermsEnums,
  priceTypesEnums,
} from '../../utils/shared-types';
import { statesAbb } from '../../utils/state-details';
import { prepareFormData } from '../CustomerForm';
import Dialog from '../Dialog';
import CustomerForm from './CustomerForm';
import DispatchForm from './DispatchForm';
import OrderForm from './OrderForm';
import PaymentSummary from './PaymentSummary';
import SelectCustomer from './SelectCustomer';

const initialContact = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  department: '',
  notes: '',
  sendBillingEmails: true,
  sendDispatchEmails: true,
  primaryContact: true,
};

const customerStartData = {
  billingAddress: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
  },
  channel: '',
  companyName: '',
  contacts: [initialContact],
  primaryContact: initialContact,
  taxExempt: false,
  type: '',
  sameAsService: false,
  id: null,
  customerNumber: null,
  motivation: '',
  serviceLocations: [],
  stripeId: '',
  metadata: {},
  credit: 0,
  leadID: '',
  accountManagerID: '',
  accountRepID: '',
};

const CustomerSelectSchema = Yup.object().shape({});

const OrderSchema = Yup.object().shape({
  serviceLocation: Yup.object().shape({
    address: Yup.object().shape({
      addressLine1: Yup.string().required('Address 1 is required'),
      addressLine2: Yup.string(),
      city: Yup.string().required('City is required'),
      state: Yup.string().oneOf(statesAbb).required('State is required'),
      zip: Yup.string()
        .matches(/^\d{5}(-\d{4})?$/, 'Must be a valid zip code')
        .required('Zip is required'),
    }),
    county: Yup.string().required('County is required'),
  }),
  material: Yup.string().required('Material is required'),
  priceType: Yup.string().required('Price type is required'),
  expectedSize: Yup.string().required('Size is required'),
  weightLimit: Yup.number().when('priceType', {
    is: priceTypesEnums.ton,
    then: Yup.number().required('Ton limit is required'),
  }),
  adjustedRentalPeriod: Yup.number().required('Rental period is required'),
  rentExtensionFeeDollars: Yup.number().required('Rental extension fee is required'),
  tax: Yup.boolean(),
  taxRate: Yup.number(),
  cc: Yup.boolean(),
  price: Yup.number().required('Quoted Price is required'),
  overageDollars: Yup.number().when('priceType', {
    is: priceTypesEnums.ton,
    then: Yup.number().required('Overage is required'),
  }),
});

const DispatchSchema = Yup.object().shape({
  expectedDeliveryDate: Yup.date().required('A delivery date is required'),
  sharedDeliveryNotes: Yup.string().required('Delivery notes are required'),
  expectedPickupDate: Yup.date().nullable().default(null),
  sharedPickupNotes: Yup.string(),
  noteOrder: Yup.string(),
  sharedDeliveryNotesPrivate: Yup.string(),
  recurringOnCall: Yup.boolean(),
  poNumber: Yup.string(),
});

const CustomerSchema = Yup.object().shape({
  companyName: Yup.string().nullable(),
  notes: Yup.string(),
  billingAddress: Yup.object().shape({
    addressLine1: Yup.string().required('Address 1 is required'),
    addressLine2: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().oneOf(statesAbb).required('State is required'),
    zip: Yup.string()
      .matches(/^\d{5}(-\d{4})?$/, 'Must be a valid zip code')
      .required('Zip is required'),
  }),
  credit: Yup.number().moreThan(-1),
  taxExempt: Yup.bool(),
  channel: Yup.string().oneOf(Object.keys(channelTypes)).required('A sales channel is required'),
  type: Yup.string()
    .oneOf(customerTypes)
    .required('A customer type, customerMotivations, commercialLocationTypes is required'),
  motivation: Yup.string()
    //@ts-expect-error this one needs a fix in the future when we remake form Joe
    .oneOf([...Object.keys(customerMotivations), '', null])
    .nullable(),
  businessType: Yup.string()
    //@ts-expect-error this one needs a fix in the future when we remake form Joe
    .oneOf([...Object.keys(commercialLocationTypes), null])
    .nullable(),
  overallExperience: Yup.string(),
  contacts: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().when('primaryContact', {
        is: true,
        then: Yup.string().required('Last name is required on primary contact'),
      }),
      phoneNumber: Yup.string()
        .when('primaryContact', {
          is: true,
          then: Yup.string().required('A phone number is required on primary contact'),
        })
        .matches(US_PHONE_NUMBER_REGEXP, 'Must be a valid phone number, e.g. (555) 867-5309'),
      email: Yup.string()
        .email()
        .when('primaryContact', {
          is: true,
          then: Yup.string().required('An email is required on primary contact'),
        }),
      department: Yup.string()
        //@ts-expect-error this one needs a fix in the future when we remake form Joe
        .oneOf([...Object.keys(departmentTypes), '', null])
        .nullable(),
      primaryContact: Yup.bool().test('oneOfRequired', 'One of the contacts must be the primary contact', function () {
        //@ts-expect-error this one needs a fix in the future
        return this.from[1]?.value.contacts.filter((contact: any) => contact.primaryContact).length === 1;
      }),
      sendDispatchEmails: Yup.bool(),
      sendBillingEmails: Yup.bool(),
      notes: Yup.string(),
    }),
  ),
  reviewed: Yup.bool().nullable(),
});

const PaymentSchema = Yup.object().shape({});

const schemaArray = [CustomerSelectSchema, OrderSchema, DispatchSchema, CustomerSchema, PaymentSchema];

export interface OrderCreateProps {
  open: boolean;
  onBackButtonClick: (values: any) => void;
  onSubmit: (values: any) => void;
  user: any;
  onGetPricing: (
    coords: {
      lat: number;
      lng: number;
      zip?: string;
      state: string;
    },
    customerID?: string,
  ) => void;
  onResetLead: () => void;
  zonePricing: Pricing.PricingTransport | null;
  isLoading: boolean;
  startingValues: any;
  getOrderTotal: (values: any) => number;
  setOldCustomer: (customer: Customer.AllianceCustomerTransport) => void;
}

const OrderCreate = ({
  open,
  onBackButtonClick,
  onSubmit,
  user,
  zonePricing,
  onGetPricing,
  isLoading,
  startingValues,
  onResetLead,
  getOrderTotal,
  setOldCustomer,
}: OrderCreateProps) => {
  const matchesMD = useMediaQuery('(min-width: 960px)');
  const [activeStep, setActiveStep] = useState(0);
  const [validationSchema, setValidationSchema] = useState(CustomerSelectSchema);
  const formRef = useRef<any>();
  //const modalRef = useRef<any>();

  const handleNext = () => {
    const nextStep = Math.min(activeStep + 1, 4);
    setValidationSchema(validationSchema.concat(schemaArray[nextStep]));
    setActiveStep(nextStep);
  };

  const handleSubmit = async (values: any) => {
    return onSubmit(values);
  };

  const handleBackButtonClick = (xClicked: any) => {
    if (!xClicked || (formRef.current && formRef.current.values.paymentInfo)) return;
    const values = formRef.current && formRef.current.values ? formRef.current.values : null;
    onBackButtonClick(values);
  };

  return (
    <Dialog
      open={open}
      onClose={handleBackButtonClick}
      styledTitle="Create Dispatch Ticket"
      fullScreen
      // ref={modalRef as unknown as any}
      // innerRef={formRef}
    >
      <div className={`p-5 flex-1 flex flex-col ${matchesMD ? 'max-w-[960px]' : 'max-w-[500px]'} mx-auto`}>
        <Formik
          initialValues={{
            serviceLocation: {
              address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: '',
              },
              county: '',
              coordinates: null,
            },
            material: '',
            priceType: priceTypesEnums.ton,
            expectedSize: '',
            weightLimit: '',
            adjustedRentalPeriod: '',
            rentExtensionFeeDollars: '',
            tax: true,
            taxRate: 0,
            cc: true,
            price: '',
            otherLineItems: [],
            coords: null,
            dumpRateDollars: null,
            overageDollars: '',
            expectedDeliveryDate: null,
            sharedDeliveryNotes: '',
            expectedPickupDate: null,
            sharedPickupNotes: '',
            noteOrder: '',
            sharedDeliveryNotesPrivate: '',
            recurringOnCall: false,
            poNumber: '',
            paymentTerm: paymentTermsEnums.onCharge,
            paymentMethod: paymentMethodsEnums.creditCard,
            issueInvoice: 'NOW',
            websiteSale: false,
            searchParams: '',
            paymentInfo: null,
            sendEmail: false,
            includeCCForm: false,
            emailMessage: '',
            emailStaticHtml: '',
            emailStaticText: '',
            maxRentalDaysAllowed: null,
            ...customerStartData,
          }}
          validationSchema={validationSchema}
          innerRef={formRef}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <FormContent
                formik={formik}
                handleNext={handleNext}
                activeStep={activeStep}
                zonePricing={zonePricing}
                onGetPricing={onGetPricing}
                setOldCustomer={setOldCustomer}
                user={user}
                startingValues={startingValues}
                isLoading={isLoading}
                onResetLead={onResetLead}
                getOrderTotal={() => getOrderTotal(formik.values)}
                onFormSubmit={() => handleSubmit(formik.values)}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default OrderCreate;

export interface FormContentProps {
  formik: any;
  handleNext: () => void;
  activeStep: number;
  zonePricing: Pricing.PricingTransport | null;
  onGetPricing: (
    loc: {
      lat: number;
      lng: number;
      zip?: string;
      state: string;
    },
    customerID?: string,
  ) => void;
  user: any;
  startingValues: any;
  isLoading: boolean;
  onResetLead: any;
  getOrderTotal: any;
  setOldCustomer: any;
  onFormSubmit: any;
}

const FormContent = ({
  formik,
  handleNext,
  activeStep,
  zonePricing,
  onGetPricing,
  user,
  startingValues,
  isLoading,
  onResetLead,
  getOrderTotal,
  setOldCustomer,
  onFormSubmit,
}: FormContentProps) => {
  const [existingCustomer, setExistingCustomer] = useState<Customer.AllianceCustomerTransport | null>(null);

  const handleCustomerSelect = (customer: Customer.AllianceCustomerTransport | null, keepValues?: any) => {
    setOldCustomer(structuredClone(customer));
    setExistingCustomer(customer);
    let newValues = { ...formik.values, ...customerStartData };
    if (customer) {
      console.log('customer: ', customer);
      newValues = {
        ...formik.values,
        paymentTerm: customer?.defaultPaymentSettings?.paymentTerm || paymentTermsEnums.onCharge,
        paymentMethod: customer?.defaultPaymentSettings?.paymentMethod || paymentMethodsEnums.creditCard,
        cc:
          !customer?.defaultPaymentSettings?.paymentMethod ||
          customer?.defaultPaymentSettings?.paymentMethod === 'creditCard'
            ? true
            : false,
        ...prepareFormData(customer),
      };
      if (customer.taxExempt) newValues.tax = false;
      onResetLead();
    }
    if (!customer && startingValues) {
      newValues = { ...formik.values, ...startingValues };
    }
    if (keepValues) {
      newValues = { ...newValues, ...keepValues };
    }
    formik.setValues(newValues);
    if (activeStep === 0) handleNext();
  };

  useEffect(() => {
    if (startingValues) {
      formik.setValues({ ...formik.values, ...startingValues });
      if (startingValues.id && startingValues.customerNumber) {
        handleCustomerSelect(startingValues);
      }
      if (!startingValues.id && !startingValues.customerNumber && startingValues.contacts) {
        startingValues.contacts = startingValues.contacts.map((contact: any) => ({
          ...initialContact,
          ...contact,
        }));
        formik.setValues({
          ...formik.values,
          ...prepareFormData(startingValues),
        });
        handleNext();
      }
    }
  }, [startingValues]);

  useEffect(() => {
    if (formik.isValid && activeStep !== 0) {
      handleNext();
    }
  }, [formik.isValid]);

  useEffect(() => {
    if (activeStep > 0 && activeStep < 4) {
      handleValidateForm();
    }
  }, [activeStep]);

  const handleValidateForm = () => {
    formik.validateForm().then((res: any) => {
      if (Object.keys(res).length === 0) {
        handleNext();
      }
    });
  };

  return (
    <div className="flex">
      <Stepper activeStep={activeStep} orientation="vertical" connector={null}>
        <Step expanded>
          <StepLabel
            sx={{
              padding: 2,
              '& .MuiSvgIcon-root': {
                width: 32,
                height: 32,
              },
              '& .MuiStepLabel-label': {
                fontSize: 22,
                marginBottom: 1,
                //marginLeft: theme.spacing(2),
              },
            }}
          >
            Select Customer
          </StepLabel>
          <StepContent
            sx={{
              border: 0,

              //marginBottom: theme.spacing(6),
              '& .MuiFormControl-root': {
                // marginTop: theme.spacing(1),
                // marginRight: theme.spacing(3),
                // marginBottom: theme.spacing(2),
              },
            }}
          >
            <div className={`flex`}>
              <SelectCustomer
                // validationSchema={CustomerSelectSchema}
                onSelect={handleCustomerSelect}
                formik={formik}
              />
              <div className={`ml-2`}>
                {existingCustomer?.doNotService ? (
                  <Chip label="Do Not Service" primaryColor="error-light" textColor="error-dark" size="medium" />
                ) : null}
              </div>
            </div>
          </StepContent>
        </Step>
        <Step expanded>
          <StepLabel
            sx={{
              padding: 2,
              '& .MuiSvgIcon-root': {
                width: 32,
                height: 32,
              },
              '& .MuiStepLabel-label': {
                fontSize: 22,
                marginBottom: 1,
                //marginLeft: theme.spacing(2),
              },
            }}
          >
            Order Info
          </StepLabel>
          <StepContent
            sx={{
              border: 0,

              //marginBottom: theme.spacing(6),
              '& .MuiFormControl-root': {
                // marginTop: theme.spacing(1),
                // marginRight: theme.spacing(3),
                // marginBottom: theme.spacing(2),
              },
            }}
          >
            <OrderForm
              //validationSchema={OrderSchema}
              zonePricing={zonePricing}
              onGetPricing={onGetPricing}
              values={formik.values}
              setFieldValue={formik.setFieldValue}
              setValues={formik.setValues}
              handleBlur={formik.handleBlur}
              disabled={activeStep < 1 || formik.values.paymentInfo}
              existingCustomer={existingCustomer}
            />
          </StepContent>
        </Step>
        <Step expanded>
          <StepLabel
            sx={{
              padding: 2,
              '& .MuiSvgIcon-root': {
                width: 32,
                height: 32,
              },
              '& .MuiStepLabel-label': {
                fontSize: 22,
                marginBottom: 1,
                //marginLeft: theme.spacing(2),
              },
            }}
          >
            Dispatch Info
          </StepLabel>
          <StepContent
            sx={{
              border: 0,

              //marginBottom: theme.spacing(6),
              '& .MuiFormControl-root': {
                // marginTop: theme.spacing(1),
                // marginRight: theme.spacing(3),
                // marginBottom: theme.spacing(2),
              },
            }}
          >
            <DispatchForm
              //validationSchema={DispatchSchema}
              values={formik.values}
              setFieldValue={formik.setFieldValue}
              //setValues={formik.setValues}
              disabled={activeStep < 2}
            />
          </StepContent>
        </Step>
        <Step expanded>
          <StepLabel
            sx={{
              padding: 2,
              '& .MuiSvgIcon-root': {
                width: 32,
                height: 32,
              },
              '& .MuiStepLabel-label': {
                fontSize: 22,
                marginBottom: 1,
                //marginLeft: theme.spacing(2),
              },
            }}
          >
            Customer Info
          </StepLabel>
          <StepContent
            sx={{
              border: 0,

              //marginBottom: theme.spacing(6),
              '& .MuiFormControl-root': {
                // marginTop: theme.spacing(1),
                // marginRight: theme.spacing(3),
                // marginBottom: theme.spacing(2),
              },
            }}
          >
            <CustomerForm
              //validationSchema={CustomerSchema}
              disabled={activeStep < 3}
              formik={formik}
              onUseExistingCustomer={handleCustomerSelect}
              existingCustomer={existingCustomer}
            />
          </StepContent>
        </Step>
        <Step expanded>
          <StepLabel
            sx={{
              padding: 2,
              '& .MuiSvgIcon-root': {
                width: 32,
                height: 32,
              },
              '& .MuiStepLabel-label': {
                fontSize: 22,
                marginBottom: 1,
                //marginLeft: theme.spacing(2),
              },
            }}
          >
            Payment &amp; Summary
          </StepLabel>
          <StepContent
            sx={{
              border: 0,

              //marginBottom: theme.spacing(6),
              '& .MuiFormControl-root': {
                // marginTop: theme.spacing(1),
                // marginRight: theme.spacing(3),
                // marginBottom: theme.spacing(2),
              },
            }}
          >
            <PaymentSummary
              user={user}
              existingCustomer={existingCustomer}
              isLoading={isLoading}
              onSubmit={onFormSubmit}
              disabled={activeStep < 4 || existingCustomer?.doNotService || false}
              formik={formik}
              getOrderTotal={getOrderTotal}
              zonePricing={zonePricing}
            />
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
};
