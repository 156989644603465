import React from 'react';
import { DialogProps } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface Props extends DialogProps {
  title: string;
  onClose: any;
  onDelete: any;
  children: React.ReactNode;
  deleteText: string;
}

const defaultProps = {
  deleteText: 'Delete',
};

const ConfirmDeleteDialog = ({ title, onClose, onDelete, deleteText, children, ...props }: Props) => (
  <Dialog onClose={onClose} {...props}>
    <DialogTitle className="bg-delete text-white">{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <button className="btn-dark-grey-outlined" onClick={onClose}>
        Cancel
      </button>
      <button className="btn-delete" onClick={onDelete}>
        {deleteText}
      </button>
    </DialogActions>
  </Dialog>
);

ConfirmDeleteDialog.defaultProps = defaultProps;

export default ConfirmDeleteDialog;
