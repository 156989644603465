import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button, Dialog, Select, SelectOption } from '@wayste/sour-ui';
import { UIContext } from '../../contexts/UIContext';

interface DeleteStatusModalProps {
  status: UniversalService.ServiceOrderStatus;
  serviceFamilyID: string;
  show: boolean;
  setShow: (show: boolean) => void;
  handleDelete: (status: string) => void;
}

export const DeleteStatusModal = ({ status, serviceFamilyID, show, setShow, handleDelete }: DeleteStatusModalProps) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);

  //STATES
  const [resetTo, setResetTo] = useState<(typeof UniversalService.ServiceOrderStatuses)[number] | undefined>(undefined);

  // HANDLE DELETE STATUS AND REASSIGN STATUS
  const handleDeleteAndReassign = async (reset: UniversalService.ServiceOrderStatus | undefined) => {
    if (!reset) {
      return;
    }
    try {
      const payload: UniversalService.ServiceTypeChangeAllowedStatusesSafely = {
        id: serviceFamilyID,
        remove: [
          {
            status: status,
            moveExistingto: reset,
          },
        ],
        add: [],
      };
      await client.universalService().serviceFamily.deleteStatus(serviceFamilyID, payload);
      showFlash('Service Status Successfully Deleted', 'success');
      handleDelete(status);
    } catch (error) {
      showFlash('An Error Occurred', 'warning');
    } finally {
      setShow(false);
    }
  };

  return (
    <Dialog
      variant={'delete'}
      open={show}
      onClose={() => setShow(false)}
      styledTitle="Delete Service Status"
      className="max-w-2xl"
    >
      <div className="flex flex-col items-center justify-center">
        Are you sure you want to delete this Service Status? You'll need to transfer all of the existing Service Orders
        with the status of {status} to another status. Select the status below you'd like to transfer them to.
      </div>

      <div className="py-4 w-[50%]">
        <Select
          label={'Transfer status to'}
          onSelect={(value: string) =>
            setResetTo(value as (typeof UniversalService.ServiceOrderStatuses)[number] | undefined)
          }
          defaultValue={''}
          value={resetTo}
          required
        >
          {[...UniversalService.ServiceOrderStatuses].map((option: string, index: number) => (
            <SelectOption key={index} value={option}>
              {option}
            </SelectOption>
          ))}
        </Select>
      </div>

      <div className="flex justify-end gap-4">
        <Button className="mr-5 btn-dark-grey-outlined" onClick={() => setShow(false)} type="button">
          Cancel
        </Button>
        <Button
          className="btn-delete"
          onClick={() => {
            if (status) {
              handleDeleteAndReassign(resetTo);
            }
          }}
        >
          Delete
        </Button>
      </div>
    </Dialog>
  );
};
