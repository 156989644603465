import React, { useContext, useEffect, useState } from 'react';
import type { InternalTicket as InternalTicketTypes } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { Disclosure } from '@headlessui/react';
import Loading from '../Loading';
import InternalTicketCreateUpdateDialog from './InternalTicketCreateUpdateDialog';
import { InternalTicketList } from './InternalTicketList';
import { RedactTicketDialog } from './RedactDialog';
import { InternalTicketContext, InternalTicketProvider } from './context';

type InternalTicketProps = {
  entityID: string;
  entityType: InternalTicketTypes.InternalTicketRelatedEntity;
};

const InternalTicketContent = ({ entityID, entityType }: InternalTicketProps) => {
  const { isLoading, setEntityID, ticketStatusCounts } = useContext(InternalTicketContext);
  const [showCreateUpdateTicket, setShowCreateUpdateTicket] = useState<{
    open: boolean;
    replyToID?: string;
    ticket?: InternalTicketTypes.InternalTicketTransport;
  }>({
    open: false,
    replyToID: undefined,
    ticket: undefined,
  });
  const [showRedactDialog, setShowRedactDialog] = useState<string | undefined>(undefined);

  useEffect(() => {
    setEntityID(entityID);
  }, [entityID]);

  const handleReplyClick = (ticketID: string) => {
    setShowCreateUpdateTicket({ open: true, replyToID: ticketID, ticket: undefined });
  };

  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => (
        <div className="bg-white rounded shadow-dark p-5 mb-3 relative">
          {isLoading && (
            <div className="z-10 bg-white absolute top-0 right-0 bottom-0 left-0">
              <Loading />
            </div>
          )}
          <div className="flex gap-4">
            <div className="text-xl items-center inline-flex">Internal Tickets</div>
            <div className="flex gap-2">
              <div className="py-1 px-2 bg-gray-600 text-white rounded">Open: {ticketStatusCounts.OPEN}</div>
              <div className="py-1 px-2 bg-blue-600 text-white rounded">
                In Progress: {ticketStatusCounts.IN_PROGRESS}
              </div>
              <div className="py-1 px-2 bg-green-600 text-white rounded">Solved: {ticketStatusCounts.SOLVED}</div>
            </div>
            <Disclosure.Button className="btn-secondary-text-only ml-auto">{open ? 'Hide' : 'Show'}</Disclosure.Button>
          </div>
          <hr className="my-4" />
          <Disclosure.Panel>
            <InternalTicketList
              onReplyClick={handleReplyClick}
              onEditClick={(ticket) => setShowCreateUpdateTicket({ open: true, replyToID: undefined, ticket })}
              onRedactClick={(ticketID) => setShowRedactDialog(ticketID)}
            />
          </Disclosure.Panel>
          <div className="flex justify-end mt-2">
            <Button
              onClick={() => setShowCreateUpdateTicket({ open: true, replyToID: undefined, ticket: undefined })}
              className="btn-primary-text-only"
            >
              Add New Ticket
            </Button>
          </div>
          {/* Dialogs */}
          <InternalTicketCreateUpdateDialog
            open={showCreateUpdateTicket.open}
            onClose={() => setShowCreateUpdateTicket({ open: false, replyToID: undefined, ticket: undefined })}
            entityID={entityID}
            entityType={entityType}
            replyToID={showCreateUpdateTicket.replyToID}
            ticket={showCreateUpdateTicket.ticket}
          />
          <RedactTicketDialog ticketID={showRedactDialog} onClose={() => setShowRedactDialog(undefined)} />
        </div>
      )}
    </Disclosure>
  );
};

export const InternalTicket = ({ entityID, entityType }: InternalTicketProps) => {
  return (
    <InternalTicketProvider>
      <InternalTicketContent entityID={entityID} entityType={entityType} />
    </InternalTicketProvider>
  );
};
