import { useEffect, useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { SelectOption } from '@alliance-disposal/wql';
import { Dialog } from '@wayste/sour-ui';
import { formatServiceAddress } from '@wayste/utils';
import { StarIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { ContactSubForm } from '../ContactForm/ContactSubForm';
import UpdatePasswordDialog from './UpdatePasswordDialog';

const contactDefault: CustomerContactType = {
  notes: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  locationPreferences: [],
};
const contactsDefault = {
  contacts: [contactDefault],
};

const ContactUpdateCreateDialog = ({
  customer,
  contact,
  open,
  onCancel,
  onDelete,
  onSubmit,
}: {
  customer: Customer.AllianceCustomerTransport;
  contact: Customer.AllianceCustomerContactTransport | null;
  open: boolean;
  onCancel: () => void;
  onDelete: () => void;
  onSubmit: (newContact: any) => void;
}) => {
  const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const submitForm = (data: FieldValues) => {
    onSubmit(data.contacts[0]);
  };

  // SET DEFAULT VALUES
  useEffect(() => {
    if (contact) {
      reset({
        contacts: [{ ...contact }],
      });
      return;
    }
    contactsDefault.contacts = [contactDefault];
    reset({
      ...contactsDefault,
    });
  }, [contact, customer]);

  useEffect(() => {
    console.log('ERRORS', errors);
  }, [errors]);

  // formatServiceAddress takes in an address with addressLine1 as required while ServiceLocation.addressLine1 is not required.
  const serviceLocationOptions: SelectOption[] = customer.serviceLocations?.map((location) => {
    const address = {
      addressLine1: location.address.addressLine1 ? location.address.addressLine1 : '',
      addressLine2: location.address.addressLine2,
      city: location.address.city,
      zip: location.address.zip,
      state: location.address.state,
    };
    const formattedAddress = formatServiceAddress(address);
    const selectOption: SelectOption = {
      label: formattedAddress,
      value: formattedAddress,
    };
    return selectOption;
  });
  serviceLocationOptions.unshift({ label: 'All', value: 'All' });
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      styledTitle={`${contact ? 'Update Customer' : 'Create Customer'}`}
      className="overflow-scroll !max-w-2xl"
    >
      {contact && (
        <UpdatePasswordDialog
          open={openUpdatePassword}
          onClose={() => setOpenUpdatePassword(false)}
          contact={contact}
          customer={customer}
        />
      )}
      <FormProvider {...methods}>
        <input type="hidden" {...register(`active`, { value: true })} />
        <form onSubmit={handleSubmit(submitForm)}>
          <div>
            <div className="flex justify-between items-center pb-7">
              <div className="flex text-xl">{customer.companyName}</div>
              {contact?.primaryContact ? <StarIcon className="h-5 w-5 flex float-right" color={'#EEDD00'} /> : <></>}
            </div>
            <div className="pb-6">
              <ContactSubForm
                hideButtons
                isCustomer
                staticPrimaryDisable
                isEditing={contact !== null}
                locationOptions={serviceLocationOptions}
              />
            </div>
            {contact && (
              <button
                className="mr-5 px-5 btn-delete-outlined"
                disabled={contact?.primaryContact ? true : false}
                onClick={onDelete}
                type="button"
              >
                <XMarkIcon className="h-5 w-5 mr-1" color={'red-600'} />
                Remove
              </button>
            )}
            {contact && (
              <button
                className="mr-5 px-5 btn-dark-grey-outlined "
                type="button"
                onClick={() => setOpenUpdatePassword(true)}
              >
                Update Password
              </button>
            )}
            <button className="px-10 btn-primary float-right" type="submit">
              {contact ? 'Update' : 'Create'}
            </button>
            <button className="mr-5 px-10 btn-dark-grey-outlined float-right" onClick={onCancel} type="button">
              Cancel
            </button>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ContactUpdateCreateDialog;
