import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Loading, TanDataGrid } from '@wayste/sour-ui';
import { formatISODateString, formatServiceAddress } from '@wayste/utils';
import { Transition } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useHistory } from 'react-router-dom';
import { routes } from '../../utils';
import DetailsCardWrapper from '../ui/DetailsCardWrapper';

interface SubscriptionsOrderSubTableProps {
  orders: UniversalService.ServiceOrder[];
}
const ServiceOrderSubTable = ({ orders }: SubscriptionsOrderSubTableProps) => {
  const history = useHistory();
  type UniversalService = {
    order: UniversalService.ServiceOrder;
    orderNumber: string;
    servicePeriod: string;
    subStatus: string;
    address: string;
    serviceType: string;
    SKU: string;
    status: string;
    frequency: string;
    vendor: string;
    recurringRate: number;
    actions: undefined;
  };
  const client = useWaysteClient();
  const getServiceOrderFromServiceOrderTransport = (order: UniversalService.ServiceOrder) => {
    let vendor = '';
    if (order.vendorID) {
      client
        .vendorService()
        .fetchById(order.vendorID)
        .then((res) => (vendor = res.data.name))
        .catch((err) => console.log(err));
    }
    const newServiceOrder: UniversalService = {
      order: order,
      orderNumber: order.fullOrderNumber,
      servicePeriod: `${formatISODateString(order.startDate)} - ${
        order.subscriptionDetails ? 'Forever' : formatISODateString(order.endDate)
      }`,
      subStatus: '', // order.subscriptionDetails.active to be added
      address: formatServiceAddress(order.serviceLocation?.address),
      serviceType: order.serviceType.family.name, // Set Correctly
      SKU: order.serviceType.name,
      status: order.status,
      frequency: order?.serviceFrequency + 'x' + order?.serviceFrequencyUnit,
      recurringRate: (order.subscriptionDetails?.periodicEvents[0].unitPrice || 0) / 100,
      vendor: vendor,
      actions: undefined,
    };

    return newServiceOrder;
  };

  const [data, setData] = useState<UniversalService[]>(orders.map(getServiceOrderFromServiceOrderTransport));
  useEffect(() => {
    setData(orders.map(getServiceOrderFromServiceOrderTransport));
  }, [orders]);
  const columnHelper = createColumnHelper<UniversalService>();
  const columns: ColumnDef<UniversalService, any>[] = [
    columnHelper.accessor('orderNumber', {
      header: 'Order #',
      cell: (info) => <div className="min-w-fit whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('servicePeriod', {
      header: 'Service Period',
      cell: (info) => <div className="min-w-fit whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('address', {
      header: 'Address',
      cell: (info) => <div className="min-w-fit whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('serviceType', {
      header: 'Service Type',
      cell: (info) => <div className="min-w-fit whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('SKU', {
      header: 'SKU',
      cell: (info) => <div className="min-w-fit whitespace-nowrap">{info.getValue()}</div>,
    }),

    columnHelper.accessor('status', {
      header: 'Status',
      cell: (info) => <div className="min-w-fit whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('vendor', {
      header: 'Vendor',
      cell: (info) => <div className="min-w-fit whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('recurringRate', {
      header: 'Recurring Rate',
      cell: (info) => (
        <div className="min-w-fit whitespace-nowrap">{info.getValue() !== 0 ? `$ ${info.getValue()}` : ''}</div>
      ),
    }),
    columnHelper.accessor('actions', {
      header: '',
      cell: (info) => (
        <div>
          <ArrowTopRightOnSquareIcon
            className="h-5 w-5 text-wayste-blue-400"
            onClick={() => {
              history.push(
                routes.universalServices.serviceGrouping.serviceOrder.details(
                  info.row.original.order.serviceGroupingID,
                  info.row.original.order.id,
                ),
              );
            }}
          />
        </div>
      ),
    }),
  ];

  return (
    <>
      <TanDataGrid data={data} columns={columns} className="-mt-4 -mb-5 -ml-5 -mr-5" />
    </>
  );
};

interface SubscriptionsTableProps {
  serviceGroupings: UniversalService.ServiceGrouping[];
}
const ServiceGroupingTable = ({ serviceGroupings }: SubscriptionsTableProps) => {
  const history = useHistory();
  const [openTable, setOpenTable] = useState<Map<string, boolean>>(
    new Map<string, boolean>(serviceGroupings.map((group) => [group.id, false])),
  );
  const handleRowClick = (id: string) => {
    setOpenTable((prevOpenTable) => {
      const updatedOpenTable = new Map(prevOpenTable);
      updatedOpenTable.set(id, !prevOpenTable.get(id));
      return updatedOpenTable;
    });
  };
  return (
    <div className="-mx-5 -my-5">
      {serviceGroupings.map((serviceGrouping, index) => (
        <div className="flex-col" key={serviceGrouping.id} onClick={() => handleRowClick(serviceGrouping.id)}>
          <div
            className={`grid grid-cols-3 justify-between align-middle pt-3 pb-2 border-b hover:bg-gray-100 cursor-pointer
            ${index !== 0 ? 'border-t ' : ''} ${openTable.get(serviceGrouping.id) ? '' : 'bg-[#F9F9F9]'}`}
          >
            <div className="ml-4">{serviceGrouping.name}</div>
            <div className="flex">
              {(() => {
                const countMap = serviceGrouping.serviceOrders.reduce<{ [key: string]: number }>((countMap, order) => {
                  const shortCode = order.serviceType.family.shortCode;
                  countMap[shortCode] = (countMap[shortCode] || 0) + 1;
                  return countMap;
                }, {});
                return Object.entries(countMap).map(([shortCode, count]) => (
                  <div key={shortCode} className="align-middle mx-4">{`${shortCode} x ${count}`}</div>
                ));
              })()}
            </div>
            <div className="flex justify-end mr-4 align-middle">
              <ArrowTopRightOnSquareIcon
                className="h-5 w-5 mr-4 text-wayste-blue-400 cursor-pointer"
                onClick={() => {
                  history.push(routes.universalServices.serviceGrouping.details(serviceGrouping.id));
                }}
              />
              <ChevronUpIcon
                className={`h-5 w-5 transition-transform ${
                  openTable.get(serviceGrouping.id) ? 'transform rotate-180' : ''
                }`}
              />
            </div>
          </div>

          {
            <Transition
              show={openTable.get(serviceGrouping.id) || false}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-50"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-50"
            >
              <div className="mx-5 my-4">
                <ServiceOrderSubTable orders={serviceGrouping.serviceOrders} />
              </div>
            </Transition>
          }
        </div>
      ))}
    </div>
  );
};

interface SubscriptionsTableCardProps {
  serviceGroupings: UniversalService.ServiceGrouping[];
  isLoading: boolean;
}
const ServiceGroupingTableCard = ({ serviceGroupings, isLoading }: SubscriptionsTableCardProps) => {
  return (
    <DetailsCardWrapper heading="Universal Service Groupings">
      {isLoading ? <Loading /> : <ServiceGroupingTable serviceGroupings={serviceGroupings} />}
      {serviceGroupings.length === 0 && !isLoading && (
        <div className="text-center pt-5">No service groupings found</div>
      )}
    </DetailsCardWrapper>
  );
};
export default ServiceGroupingTableCard;
