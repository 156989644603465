import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Pricing } from '@alliance-disposal/transport-types';
import { Loading, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { dollarsToCents, moneyFormatter } from '@wayste/utils';
import { useForm } from 'react-hook-form';
import { statesAbb } from '../../../../utils';
import Dialog from '../../../Dialog';

export type AddPricingZoneProps = {
  open: boolean;
  existingCustomerDiscounts: Pricing.CustomerDiscountTransport[];
  selectedCustomerDiscount?: Pricing.CustomerDiscountTransport;
};

interface FormProps {
  state: string;
  pricingZone: Pricing.PricingTransport | undefined | null;
  rentalPeriod: number | '';
  rentalExtensionRate: number | '';
}

const CustomPricingZoneModal = ({
  open,
  existingCustomerDiscounts,
  onClose,
  handleSubmit,
  selectedCustomerDiscount,
}: {
  open?: boolean;
  existingCustomerDiscounts?: Pricing.CustomerDiscountTransport[];
  selectedCustomerDiscount?: Pricing.CustomerDiscountTransport;
  onClose: () => void;
  handleSubmit: (opts: {
    pricingZoneID: string;
    extraRentalPeriod: number;
    rentalExtensionFee: number;
    customerDiscountID?: string;
  }) => Promise<void>;
}) => {
  const client = useWaysteClient();
  const [loading, setLoading] = useState(false);
  const [pricingZoneOptions, setPricingZoneOptions] = useState<Pricing.PricingTransport[]>();

  const {
    setValue,
    handleSubmit: handleRHFSubmit,
    register,
    watch,
    reset,
  } = useForm<FormProps>({
    mode: 'all',
    shouldFocusError: false,
    defaultValues: {
      state: '',
      pricingZone: undefined,
      rentalPeriod: '',
      rentalExtensionRate: '',
    },
  });

  const watchState = watch('state');
  const watchPricingZone = watch('pricingZone');
  const watchRentalPeriod = watch('rentalPeriod');
  const watchRentalExtensionRate = watch('rentalExtensionRate');

  useEffect(() => {
    if (selectedCustomerDiscount) {
      setValue('pricingZone', selectedCustomerDiscount.pricingZone);
      setValue('rentalExtensionRate', selectedCustomerDiscount.rentExtensionFee || '');
      setValue(
        'rentalPeriod',
        selectedCustomerDiscount.pricingZone?.rentalPeriod?.value && selectedCustomerDiscount.extraRentalPeriod
          ? selectedCustomerDiscount.pricingZone?.rentalPeriod.value + selectedCustomerDiscount.extraRentalPeriod
          : '',
      );
    }
  }, [selectedCustomerDiscount]);

  const handleClose = () => {
    reset();
    setPricingZoneOptions(undefined);
    onClose();
  };

  const onFormSubmit = (data: FormProps) => {
    if (!data.pricingZone?.id) {
      reset();
      return alert('An error has occurred, please try again');
    }
    handleSubmit({
      pricingZoneID: data.pricingZone?.id,
      // replace 0 with standard rate
      extraRentalPeriod: (+data.rentalPeriod ?? 0) - (data?.pricingZone?.rentalPeriod?.value ?? 0),
      // replace 0 with standard rate
      rentalExtensionFee: +data.rentalExtensionRate ?? 0,
      // If update set this
      customerDiscountID: selectedCustomerDiscount?.id || undefined,
    });
    handleClose();
  };

  const fetchPricingZones = async () => {
    try {
      setLoading(true);

      const pricing = await client.pricing().adminPortal.pricing.query({ state: watchState as any });

      if (pricing.length === 0) {
        setLoading(false);
        return alert(
          'No pricing zones are available for the selected state. If you think this is an error please contact a dev.',
        );
      }
      const pricingZoneData = pricing as Pricing.PricingTransport[];
      const existingPricingZones: Record<string, boolean> = {};
      existingCustomerDiscounts?.forEach((x) => {
        if (x.pricingZoneID) {
          existingPricingZones[x.pricingZoneID] = true;
        }
      });
      const filtered = pricingZoneData
        .filter(
          (x) =>
            x.pricingData.length !== 0 && !existingPricingZones[x.id as keyof typeof existingPricingZones] && x.public,
        )
        .sort((a, b) => ((a?.zoneName || '') > (b?.zoneName || '') ? 1 : -1));
      setPricingZoneOptions(filtered);
      setLoading(false);
    } catch (error) {
      setValue('state', '');
      alert('An error has occurred while fetching pricing zones, please try again or contact dev.');
    }
    setLoading(false);
  };

  useEffect(() => {
    setPricingZoneOptions(undefined);
    setValue('pricingZone', null);
    setValue('rentalPeriod', '');
    setValue('rentalExtensionRate', '');
    if (!!watchState && statesAbb.includes(watchState)) {
      fetchPricingZones();
    }
  }, [watchState]);

  return (
    <Dialog
      open={Boolean(open)}
      onClose={handleClose}
      styledTitle={`${selectedCustomerDiscount ? 'Update' : 'Add New'} Custom Pricing Zone`}
      className="!max-w-4xl"
    >
      <form onSubmit={handleRHFSubmit(onFormSubmit)}>
        {!selectedCustomerDiscount && (
          <div className="flex flex-col gap-2 my-2.5 mx-20">
            <div>
              <Select label="State" required onSelect={(value) => setValue('state', value)} value={watchState}>
                {statesAbb.map((item, index) => (
                  <SelectOption key={item + index} value={item}>
                    {item}
                  </SelectOption>
                ))}
              </Select>
            </div>
            {!loading ? (
              <Select
                label="Pricing zone"
                required
                onSelect={(value) =>
                  setValue(
                    'pricingZone',
                    pricingZoneOptions?.find((item) => item.zoneName === value),
                  )
                }
                value={watchPricingZone?.zoneName || undefined}
              >
                {pricingZoneOptions?.map((item, index) => (
                  <SelectOption key={index} value={item.zoneName || ''}>
                    {item.zoneName}
                  </SelectOption>
                ))}
              </Select>
            ) : (
              <div className="flex flex-col gap-1 mt-1 items-center">
                <Loading size="h-8 w-8" />
                <p>Fetching Zones..</p>
              </div>
            )}
          </div>
        )}
        {watchPricingZone && (
          <div className="flex flex-col gap-4 justify-center items-center">
            <p className="italic text-sm text-blue-gray-400">
              If you do not add a custom rate, it will not override the standard rate.
            </p>
            <div className="grid gap-4 grid-cols-3 w-full items-center">
              <p className="mb-2 font-bold underline">Standard rate</p>
              <p className="mb-2 font-bold underline">Custom rate</p>
              <p className="mb-2 font-bold underline">Discount amount</p>
              {/* Rental Period */}
              <p>{(watchPricingZone?.rentalPeriod?.value || 0) + ' days'}</p>
              <div className="w-40">
                <TextField
                  label={'Rental Period'}
                  type="number"
                  inputProps={{
                    ...register('rentalPeriod'),
                  }}
                />
              </div>
              <p>
                {watchRentalPeriod
                  ? `${watchRentalPeriod - (watchPricingZone?.rentalPeriod?.value ?? -99999)} day`
                  : '0 days'}
              </p>
              {/* Rental Extension Fee */}
              <p>{'$' + watchPricingZone?.rentExtensionFeeDollars?.toLocaleString() + ' / day'}</p>
              <div className="w-40">
                <TextField
                  label={'Rental Extension Fee'}
                  type="number"
                  inputProps={{
                    ...register('rentalExtensionRate'),
                  }}
                  startAdornment={
                    !!watchRentalExtensionRate ? <p className="mr-1 text-sourgum-greyblue-400">$</p> : undefined
                  }
                />
              </div>
              <p>
                {watchRentalExtensionRate
                  ? moneyFormatter(dollarsToCents(watchRentalExtensionRate) - (watchPricingZone?.rentExtensionFee ?? 0))
                  : '$0'}
              </p>
            </div>
          </div>
        )}
        <hr className="mt-4" />
        <div className="flex justify-end gap-4 mt-4">
          <button className="btn-dark-grey-outlined" onClick={handleClose} type="button">
            Cancel
          </button>
          <button className="btn-primary" type="submit">
            Save
          </button>
        </div>
      </form>
    </Dialog>
  );
};

export default CustomPricingZoneModal;
