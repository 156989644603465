import { useContext, useEffect, useRef, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { SourFilters, SourSearch, SourSearchResponse, SourSearchWrapper } from '@wayste/sour-search';
import { formatServiceAddress } from '@wayste/utils';
import { getRouterPath } from '@wayste/utils';
import { estypes } from '@elastic/elasticsearch';
import { ShieldCheckIcon } from '@heroicons/react/24/solid';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { addDays, format, isAfter, subDays } from 'date-fns';
import { useHistory } from 'react-router-dom';
import ButtonSelect from '../../components/ButtonSelect';
import { UIContext } from '../../contexts';
import { leadTypesEnums, routes } from '../../utils';
import AdminPanel from './AdminPanel';

const RedPill = ({ text }: { text: string }) => (
  <div className="inline-flex items-center h-6 px-2.5 rounded-full text-xs font-medium bg-error-light text-error-dark">
    {text}
  </div>
);

const OrangePill = ({ text }: { text: string }) => (
  <div className="inline-flex items-center h-6 px-2.5 rounded-full text-xs font-medium bg-[#fef08a] text-[#ea580c]">
    {text}
  </div>
);

const GreenPill = ({ text }: { text: string }) => (
  <div className="inline-flex items-center h-6 px-2.5 rounded-full text-xs font-medium bg-sourgum-green-200 text-sourgum-green-700">
    {text}
  </div>
);

const pipelineOptions = [
  { value: leadTypesEnums.rollOff, label: 'Roll Off Pipeline' },
  { value: leadTypesEnums.commercial, label: 'Commercial Pipeline' },
  { value: leadTypesEnums.residential, label: 'Residential Pipeline' },
  { value: leadTypesEnums.portableToilet, label: 'Portable Toilet Pipeline' },
];

const rollOffColumns: GridColDef[] = [
  {
    field: 'metadata',
    headerName: 'Created',
    type: 'date',
    align: 'center',
    valueGetter: ({ value }) => format(new Date(value.createdAt), 'MM/dd/yy hh:mm aaa'),
    width: 150,
  },
  {
    field: 'followUpDate',
    headerName: 'Follow Up',
    type: 'date',
    renderCell: ({ value }) =>
      value && isAfter(new Date(), subDays(new Date(value), 1)) ? (
        <OrangePill text={format(new Date(value), 'MM/dd/yy')} />
      ) : value ? (
        format(new Date(value), 'MM/dd/yy')
      ) : (
        ''
      ),
    width: 90,
  },
  {
    field: 'contactEvents',
    headerName: 'Contacted',
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ value }) => (value.length > 0 ? 'Yes' : <RedPill text="No" />),
    width: 90,
  },
  {
    field: 'quotes',
    headerName: 'Quoted',
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ value }) => (value.length > 0 ? 'Yes' : <RedPill text="No" />),
    width: 70,
  },
  {
    field: 'lastContacted',
    headerName: 'Last Contacted',
    type: 'date',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) =>
      !row.followUpDate &&
      row.contactEvents.length > 0 &&
      isAfter(new Date(), addDays(new Date(row.contactEvents[row.contactEvents.length - 1].date), 2)) ? (
        <OrangePill
          text={format(new Date(row.contactEvents[row.contactEvents.length - 1].date), 'MM/dd/yy hh:mm aaa')}
        />
      ) : row.contactEvents.length > 0 ? (
        format(new Date(row.contactEvents[row.contactEvents.length - 1].date), 'MM/dd/yy hh:mm aaa')
      ) : (
        ''
      ),
    width: 120,
  },
  {
    field: 'requestedDeliveryDate',
    headerName: 'Delivery Date',
    type: 'date',
    width: 120,
  },
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    renderCell: ({ row }) =>
      row.allianceCustomer ? (
        <GreenPill text={`${row.firstName} ${row.lastName}`} />
      ) : (
        <span>
          {row.firstName} {row.lastName}
        </span>
      ),
    width: 200,
  },
  {
    field: 'serviceLocation',
    headerName: 'Location',
    type: 'string',
    renderCell: ({ value }) => (
      <div style={{ whiteSpace: 'pre', width: '100%' }}>{formatServiceAddress(value.address)}</div>
    ),
    flex: 1,
  },
];

const commercialColumns: GridColDef[] = [
  {
    field: 'metadata',
    headerName: 'Created',
    type: 'date',
    align: 'center',
    valueGetter: ({ value }) => format(new Date(value.createdAt), 'MM/dd/yy hh:mm aaa'),
    width: 150,
  },
  {
    field: 'requestedStartDate',
    headerName: 'Requested Start',
    type: 'date',
    valueGetter: ({ value }) => (value ? format(new Date(value), 'MM/dd/yy') : null),
    width: 120,
  },
  {
    field: 'followUpDate',
    headerName: 'Follow Up',
    type: 'date',
    renderCell: ({ value }) =>
      value && isAfter(new Date(), subDays(new Date(value), 1)) ? (
        <OrangePill text={format(new Date(value), 'MM/dd/yy')} />
      ) : value ? (
        format(new Date(value), 'MM/dd/yy')
      ) : (
        ''
      ),
    width: 90,
  },
  {
    field: 'contactEvents',
    headerName: 'Contacted',
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ value }) => (value.length > 0 ? 'Yes' : <RedPill text="No" />),
    width: 90,
  },
  {
    field: 'quotes',
    headerName: 'Quoted',
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ value }) =>
      value.length > 0 && value.some((item: any) => item.price) ? 'Yes' : <RedPill text="No" />,
    width: 70,
  },
  {
    field: 'lastContacted',
    headerName: 'Last Contacted',
    type: 'date',
    align: 'center',
    headerAlign: 'center',
    valueGetter: ({ row }) =>
      row.contactEvents.length > 0
        ? format(new Date(row.contactEvents[row.contactEvents.length - 1].date), 'MM/dd/yy')
        : '',
    width: 120,
  },
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    valueGetter: ({ row }) => `${row.firstName} ${row.lastName}`,
    width: 200,
  },
  {
    field: 'serviceLocation',
    headerName: 'Location',
    type: 'string',
    renderCell: ({ value }) => (
      <div style={{ whiteSpace: 'pre', width: '100%' }}>{formatServiceAddress(value.address)}</div>
    ),
    flex: 1,
  },
  {
    field: 'size',
    headerName: 'Size',
    type: 'string',
    valueGetter: ({ row }) =>
      row.quotes.length === 0 ? '' : row.quotes.length > 1 ? 'Multi' : row.quotes[0].size.size,
  },
];

const portableToiletColumns: GridColDef[] = [
  {
    field: 'metadata',
    headerName: 'Created',
    type: 'date',
    align: 'center',
    valueGetter: ({ value }) => format(new Date(value.createdAt), 'MM/dd/yy hh:mm aaa'),
    width: 150,
  },
  {
    field: 'requestedStartDate',
    headerName: 'Requested Start',
    type: 'date',
    valueGetter: ({ value }) => (value ? format(new Date(value), 'MM/dd/yy') : null),
    width: 120,
  },
  {
    field: 'followUpDate',
    headerName: 'Follow Up',
    type: 'date',
    renderCell: ({ value }) =>
      value && isAfter(new Date(), subDays(new Date(value), 1)) ? (
        <OrangePill text={format(new Date(value), 'MM/dd/yy')} />
      ) : value ? (
        format(new Date(value), 'MM/dd/yy')
      ) : (
        ''
      ),
    width: 90,
  },
  {
    field: 'contactEvents',
    headerName: 'Contacted',
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ value }) => (value.length > 0 ? 'Yes' : <RedPill text="No" />),
    width: 90,
  },
  // {
  //   field: 'quotes',
  //   headerName: 'Quoted',
  //   type: 'string',
  //   align: 'center',
  //   headerAlign: 'center',
  //   renderCell: ({ value }) =>
  //     value.length > 0 && value.some((item: any) => item.price) ? 'Yes' : <RedPill text="No" />,
  //   width: 70,
  // },
  {
    field: 'lastContacted',
    headerName: 'Last Contacted',
    type: 'date',
    align: 'center',
    headerAlign: 'center',
    valueGetter: ({ row }) =>
      row.contactEvents.length > 0
        ? format(new Date(row.contactEvents[row.contactEvents.length - 1].date), 'MM/dd/yy')
        : '',
    width: 120,
  },
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    valueGetter: ({ row }) => `${row.firstName} ${row.lastName}`,
    width: 200,
  },
  {
    field: 'serviceLocation',
    headerName: 'Location',
    type: 'string',
    renderCell: ({ value }) => (
      <div style={{ whiteSpace: 'pre', width: '100%' }}>{formatServiceAddress(value.address)}</div>
    ),
    flex: 1,
  },
];

const LeadsPage = () => {
  const client = useWaysteClient();
  const history = useHistory();
  const { showFlash } = useContext<any>(UIContext);
  const [filterData, setFilterData] = useState<Customer.AllianceLeadTransport[]>([]);
  const [page, setPage] = useState(0);
  const [filterDataCount, setFilterDataCount] = useState(0);
  const subscription = useRef<any>(null);
  const [pageSize, setPageSize] = useState<number>(50);

  const [searchLoading, setSearchLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLeadType, setSelectedLeadType] = useState(leadTypesEnums.rollOff);
  const [columns, setColumns] = useState(rollOffColumns);
  const [showAdminPanel, setShowAdminPanel] = useState(false);
  const [leadFilters, setLeadFilters] = useState({
    filters: {
      type: selectedLeadType,
      status: 'open',
    },
    sortBy: undefined,
    sortOrder: undefined,
  });

  ///////////////////////////////////////////////
  // NEW SEARCH
  ///////////////////////////////////////////////
  const defaultFilter = 'sourgum-lead';
  const [isDefaultFilter, setIsDefaultFilter] = useState<boolean | undefined>(true);
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [filterActive, setFilterActive] = useState<boolean>(false);
  const [searchPage, setSearchPage] = useState<number>(0);
  const [searchData, setSearchData] = useState<Array<estypes.SearchHit<any>>>([]);
  const [searchPageSize, setSearchPageSize] = useState<number>(25);
  const [totalHits, setTotalHits] = useState<number>(0);

  const onNavigate = (_: any, recordID: string, name: string) => {
    const path = getRouterPath(name, recordID, routes);
    history.push(path);
  };

  // DEFINE ON RESULTS LOGIC
  const handleSearchResults = async (response: SourSearchResponse) => {
    setSearchLoading(true);
    if (searchPage !== response.page) setSearchPage(response.page);
    response.type === defaultFilter ? setIsDefaultFilter(true) : setIsDefaultFilter(false);
    if (response.type === 'sourgum-lead') {
      const res = await processHits(response.results.hits.hits);
      setTotalHits(response.totalHits);
      setSearchData(res);
    } else {
      setSearchData([]);
    }

    setSearchLoading(false);
  };

  const onActiveSearch = (active: boolean) => {
    setSearchActive(active);
  };
  const onActiveFilter = (active: boolean) => {
    setFilterActive(active);
  };

  // THIS NEEDS TO BE SPEED UP
  const processHits = async (hits: any) => {
    const results: any[] = [];
    hits.map((hit: any) => {
      results.push(hit._source);
    });
    return results;
  };

  ///////////////////////////////////////////////
  // END NEW SEARCH
  ///////////////////////////////////////////////

  useEffect(() => {
    setIsLoading(true);
    setFilterData([]);
    setFilterDataCount(0);
    subscription.current = client.customer().adminPortal.leads.subscription.subscribeToLeads(
      {
        page,
        limit: pageSize,
        ...leadFilters,
      },
      (response: { status: 'success' | 'error'; data: Customer.AllianceLeadTransport[]; count: number }) => {
        if (response.status === 'error') {
          showFlash('An Error Occurred Getting Leads', 'warning');
          setIsLoading(false);
        }
        setFilterData(response.data);
        setFilterDataCount(response.count || 0);
        setIsLoading(false);
      },
    );
    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, [page, pageSize, leadFilters]);

  const handleLeadTypeChange = (value: string) => {
    if (value === leadTypesEnums.rollOff) {
      setColumns(rollOffColumns);
    } else if (value === leadTypesEnums.commercial) {
      setColumns(commercialColumns);
    } else if (value === leadTypesEnums.residential) {
      setColumns(commercialColumns);
    } else if (value === leadTypesEnums.portableToilet) {
      setColumns(portableToiletColumns);
    }
    setSelectedLeadType(value);
    setLeadFilters({
      filters: {
        type: value,
        status: 'open',
      },
      sortBy: undefined,
      sortOrder: undefined,
    });
  };

  const handleFilterSelected = (value: any) => {
    if (value.defaultSort) {
      setLeadFilters({
        filters: {
          type: selectedLeadType,
          status: 'open',
        },
        sortBy: undefined,
        sortOrder: undefined,
      });
    } else {
      setLeadFilters(value);
    }
    setPage(0);
  };

  const handleSortChange = (model: GridSortModel) => {
    if (model.length === 0) {
      handleFilterSelected({ defaultSort: true });
      return;
    }
    if (model.length > 1) {
      alert("I don't know how to handle more than one GridSortModel item. Get a SAP dev.");
      return;
    }
    const item = model[0];
    if (item?.field === 'metadata') {
      handleFilterSelected({
        filters: {
          type: selectedLeadType,
          status: 'open',
        },
        sortBy: 'createdAt',
        sortOrder: item.sort?.toUpperCase(),
      });
    }
    if (item?.field === 'followUpDate') {
      handleFilterSelected({
        filters: {
          type: selectedLeadType,
          status: 'open',
        },
        sortBy: 'followUpDate',
        sortOrder: item.sort?.toUpperCase(),
      });
    }
  };

  return (
    <div className="bg-white shadow-dark rounded-md m-2 flex-1 flex">
      <div className="container mx-auto px-5 py-2 flex-1">
        <div className="flex justify-between items-end">
          <div>
            <h3 className="text-2xl">Leads</h3>
          </div>
          <div className="flex items-center">
            <button className="btn-icon text-gray-600 mr-7" onClick={() => setShowAdminPanel(true)}>
              <ShieldCheckIcon className="h-6 w-6" />
            </button>
            <ButtonSelect
              label="Select A Pipeline"
              menuItems={pipelineOptions}
              value={selectedLeadType}
              onSelect={handleLeadTypeChange}
              wrapperClass="mr-5"
            />
          </div>
        </div>
        <hr className="mt-1" />
        <div className="pt-3 pb-2 flex flex-row">
          <div className=" w-full flex flex-row justify-between item-center">
            <SourSearchWrapper
              options={{
                application: 'aap',
                apiKey: import.meta.env.VITE_ELASTIC_KEY as string,
                environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
              }}
              onNavigate={onNavigate}
              onResults={handleSearchResults}
              onSearch={onActiveSearch}
              onFilter={onActiveFilter}
              createQueryParams={{ method: 'with_filter', removeOn: 'empty_string_inactive_filter' }}
              size={searchPageSize}
              page={searchPage}
              defaultFilters={{
                query: {
                  type: defaultFilter,
                },
              }}
            >
              <div className="flex flex-row justify-between space-x-4 w-full">
                <SourSearch
                  options={{
                    searchPopoverFixed: false,
                    showTips: !isDefaultFilter,
                    showMessages: !isDefaultFilter,
                    showResults: !isDefaultFilter,
                    placeholder: 'Search Leads',
                  }}
                />
                <SourFilters />
              </div>
            </SourSearchWrapper>
          </div>
        </div>
        <div className="w-full h-[85%]">
          <DataGrid
            rows={searchActive || filterActive ? searchData : filterData}
            rowCount={searchActive || filterActive ? totalHits : filterDataCount}
            rowHeight={30}
            columns={columns}
            pageSize={searchActive || filterActive ? searchPageSize : pageSize}
            loading={searchActive || filterActive ? searchLoading : isLoading}
            onPageSizeChange={(newPageSize) => {
              console.log('newPageSize', newPageSize);
              setPageSize(newPageSize);
              setSearchPageSize(newPageSize);
            }}
            onPageChange={(newPage) => {
              console.log('newPage', newPage);
              setPage(newPage);
              setSearchPage(newPage);
            }}
            disableSelectionOnClick
            disableColumnSelector
            rowsPerPageOptions={[25, 50, 100]}
            onRowClick={(e) => {
              history.push(routes.leads.details(e.row.id), { modal: true });
            }}
            page={searchActive || filterActive ? searchPage : page}
            pagination
            paginationMode={searchActive ? 'server' : 'server'}
            onSortModelChange={handleSortChange}
            filterMode="server"
            sortingMode="server"
          />
        </div>
      </div>
      {showAdminPanel ? (
        <AdminPanel open={showAdminPanel} onClose={() => setShowAdminPanel(false)} leadType={selectedLeadType} />
      ) : null}
    </div>
  );
};

export default LeadsPage;
