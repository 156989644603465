import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, UniversalService } from '@alliance-disposal/transport-types';
import { SourFilters, SourSearch, SourSearchWrapper } from '@wayste/sour-search';
import { Button, Loading, Select, SelectOption } from '@wayste/sour-ui';
import { getPrimaryContact } from '@wayste/utils';
import { Controller, FormProvider, get, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import { routes } from '../../utils';
import { paymentMethods, paymentMethodsEnums, paymentTermsEnums } from '../../utils/shared-types';
import ChargeCard2 from '../ChargeCard/ChargeCard2';
import { CustomerForm2Fields } from '../CustomerForm/CustomerForm2';
import OneTimeServiceOrderSubForm from './OneTimeServiceOrderSubForm';
import SubscriptionDetailsSubForm from './SubscriptionDetailsSubForm';
import SubscriptionServiceOrderAddSubForm from './SubscriptionServiceOrderAddSubForm';
import TotalTable from './TotalTable';
import {
  defaultServiceGrouping,
  defaultServiceOrderWithSubscriptionDetails,
  defaultServiceSubscriptionDetails,
} from './site-service-defaults';
import { createServiceGrouping } from './submit-create-site-service';

const orderTypes = {
  oneTime: 'One Time',
  subscription: 'Subscription',
};

export type UniversalServiceFormData = {
  customer: Customer.AllianceCustomerTransport;
  serviceGrouping: UniversalService.ServiceGroupingRecursiveCreate;
  subscriptionDetails: UniversalService.SubscriptionDetailsCreate;
  prorate: boolean;
  quantity: number[]; // Matched to serviceOrders
};

const UniversalServiceCreate: React.FC = () => {
  const history = useHistory();
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState<Customer.AllianceCustomerTransport | undefined>(undefined);
  const [orderType, setOrderType] = useState<'' | 'subscription' | 'oneTime'>('');
  const [total, setTotal] = useState<number>(0);
  const [cardSuccessful, setCardSuccessful] = useState(false);
  const [chargeOption, setChargeOption] = useState<string>('');
  const [stripeChargeID, setStripeChargeID] = useState<string>('');
  const [last4, setLast4] = useState<string>('');
  //FORM CONTEXT
  const methods = useForm<UniversalServiceFormData>();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = methods;

  useEffect(() => {
    console.log('ERRORS', errors);
  }, [errors]);

  const formData = watch();
  console.log('FORM DATA', formData);

  useEffect(() => {
    reset({
      ...formData,
      serviceGrouping: { ...defaultServiceGrouping },
      customer: { contacts: [{ primaryContact: true }], serviceLocations: [] },
    });
  }, []);
  useEffect(() => {
    console.log('isLoading', isLoading);
  }, [isLoading]);

  const onCustomerSelect = async (_: unknown, recordID: string) => {
    setIsLoading(true);
    client
      .customer()
      .adminPortal.fetch(recordID)
      .then((response) => {
        setCustomer(response);
        reset({
          ...formData,
          customer: response,
          serviceGrouping: {
            ...formData.serviceGrouping,
            paymentTerm: response.defaultPaymentSettings.paymentTerm,
            paymentMethod: response.defaultPaymentSettings.paymentMethod,
          },
        });
      })
      .catch((error) => {
        console.log('Error fetching customer', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onOrderTypeSelect = (value: string) => {
    console.log('ORDER TYPE', value);
    setOrderType(value as keyof typeof orderTypes);
    if (value === 'oneTime') {
      reset({
        ...formData,
        serviceGrouping: { ...defaultServiceGrouping },
      });
    } else {
      reset({
        ...formData,
        serviceGrouping: {
          ...defaultServiceGrouping,
          type: 'open-ended',
          contractDetails: {
            active: true,
          },
          serviceOrders: [{ ...defaultServiceOrderWithSubscriptionDetails }],
        },
        subscriptionDetails: defaultServiceSubscriptionDetails,
      });
    }
  };

  const onSubmit = async (data: UniversalServiceFormData) => {
    try {
      await createServiceGrouping(data, {
        cardSuccessful,
        customer,
        last4,
        stripeChargeID,
        total,
        showFlash,
        client,
        onCompleted: (grouping) => history.push(routes.universalServices.serviceGrouping.details(grouping.id)),
      });
    } catch (error) {
      console.error('Error creating service grouping', error);
      showFlash('Something has gone very very wrong. This should be impossible.', 'warning');
    }
  };

  const handleChargeSuccessful = (stripeChargeID: string, last4: string, stripeId: string) => {
    setCardSuccessful(true);
    setStripeChargeID(stripeChargeID);
    setLast4(last4);
    setValue('customer.stripeId', stripeId);
  };

  const handleSaveSuccessful = (stripeId: string) => {
    setCardSuccessful(true);
    setValue('customer.stripeId', stripeId);
  };

  return (
    <div className="p-16 mx-auto flex-1 flex flex-col w-full max-w-5xl">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pb-2 mb-6 text-2xl">Create Service Grouping</div>
          <div className="pb-2 mb-6 text-xl border-b border-gray-300">Customer Info</div>
          <div className="flex flex-col items-end md:flex-row md:items-center">
            <div className="w-[75%]">
              {isLoading ? (
                <div className="mt-5 md:ml-7 md:mt-0 ">
                  <Loading />
                </div>
              ) : (
                <SourSearchWrapper
                  onNavigate={onCustomerSelect}
                  defaultFilters={{
                    query: {
                      type: 'sourgum-customer',
                    },
                  }}
                  options={{
                    application: 'aap',
                    apiKey: import.meta.env.VITE_ELASTIC_KEY,
                    environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                  }}
                >
                  <div className="flex flex-row justify-between space-x-4 w-full">
                    <SourSearch
                      options={{
                        searchPopoverFixed: true,
                        showTips: false,
                        showMessages: false,
                        resultSize: 'w-[600px]',
                        placeholder: 'Search for a customer',
                      }}
                    />
                    <div className="hidden">
                      <SourFilters />
                    </div>
                  </div>
                </SourSearchWrapper>
              )}
            </div>
            <Button
              className="btn-dark-grey-outlined mt-5 md:ml-7 md:mt-0"
              onClick={() => setCustomer(undefined)}
              type="button"
            >
              Create New Customer
            </Button>
          </div>

          {!customer ? (
            <CustomerForm2Fields
              customer={undefined}
              fieldName={'customer.'}
              isLoading={isLoading}
              subForm
              onCancel={() => undefined}
              onUseExistingCustomer={setCustomer}
            />
          ) : (
            <div className="my-6">
              <div className="pb-2 text-xl border-b border-gray-300">
                Customer:{' '}
                {customer.companyName
                  ? customer.companyName
                  : getPrimaryContact(customer)?.firstName + ' ' + getPrimaryContact(customer)?.lastName}
              </div>
            </div>
          )}

          <div className="pb-2 mb-6 text-xl border-b border-gray-300">Service Info</div>
          <div className="space-y-6">
            <div className="w-1/2">
              <div className="mb-6">
                <Select
                  label={'One time or Subscription'}
                  onSelect={onOrderTypeSelect}
                  defaultValue={''}
                  required
                  value={orderType}
                >
                  {Object.entries(orderTypes).map((option: [string, string], index: number) => (
                    <SelectOption key={'orderType' + index} value={option[0]}>
                      {option[1]}
                    </SelectOption>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          {orderType === 'subscription' && (
            <div className="space-y-6">
              <SubscriptionDetailsSubForm methods={methods} />
              <SubscriptionServiceOrderAddSubForm methods={methods} />
            </div>
          )}
          {orderType === 'oneTime' && <OneTimeServiceOrderSubForm methods={methods} mode="create" />}
          <TotalTable
            className="w-full my-4"
            formData={formData}
            customer={customer}
            total={total}
            setTotal={setTotal}
          />
          <div className="space-y-6">
            <div className="pb-2 mb-6 text-xl border-b border-gray-300">Payment Info</div>
            <div className="flex space-x-2">
              <div className="w-1/2">
                <Controller
                  name="serviceGrouping.paymentTerm"
                  control={control}
                  render={({ field }) => (
                    <Select
                      error={get(errors, 'serviceGrouping.paymentTerm')}
                      label={'Payment Term'}
                      onSelect={field.onChange}
                      defaultValue={''}
                      required
                      value={field.value}
                    >
                      {Object.entries(paymentTermsEnums).map((option: [string, string], index: number) => (
                        <SelectOption key={'paymentTerm' + index} value={option[0]}>
                          {option[1]}
                        </SelectOption>
                      ))}
                    </Select>
                  )}
                />
              </div>
              <div className="w-1/2">
                <Controller
                  name={'serviceGrouping.paymentMethod'}
                  control={control}
                  render={({ field }) => (
                    <Select
                      error={get(errors, 'serviceGrouping.paymentMethod')}
                      label={'Payment Method'}
                      onSelect={field.onChange}
                      defaultValue={''}
                      required
                      value={field.value}
                    >
                      {Object.entries(paymentMethods).map((option: [string, string], index: number) => (
                        <SelectOption key={'paymentMethods' + index} value={option[0]}>
                          {option[1]}
                        </SelectOption>
                      ))}
                    </Select>
                  )}
                />
              </div>
            </div>
            {watch('serviceGrouping.paymentMethod') === paymentMethodsEnums.creditCard && (
              <ChargeCard2
                total={total}
                showChargeOption
                customer={customer ? customer : formData.customer}
                onChargeSuccessful={handleChargeSuccessful}
                onSaveSuccessful={handleSaveSuccessful}
                onStripeCustomerFetched={(stripeCustomer) => {
                  if (stripeCustomer) setValue('customer.stripeId', stripeCustomer.id);
                }}
                onChargeOptionChange={setChargeOption}
              />
            )}
            <div className="mt-2 float-right">
              <Button className="btn-dark-grey-outlined mr-4" onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button
                className="btn-primary"
                type="submit"
                disabled={
                  isLoading ||
                  (getValues('serviceGrouping.paymentMethod') === paymentMethodsEnums.creditCard &&
                    getValues('serviceGrouping.paymentTerm') === paymentTermsEnums.onCharge &&
                    chargeOption !== 'none' &&
                    !cardSuccessful) ||
                  (chargeOption === 'now' && !cardSuccessful)
                }
              >
                Create
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default UniversalServiceCreate;
