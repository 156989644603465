import { gql } from '@apollo/client';

export const list = gql`
  query GetAllianceRoster {
    getAllianceRoster {
      id
      active
      firstName
      lastName
      roles
    }
  }
`;

export const fetchAvailableAuthorityTokens = gql`
  query GetAvailableAuthorityTokens($cognitoToken: String!) {
    getAvailableAuthorityTokens(cognitoToken: $cognitoToken) {
      haulerID
      # haulerName
      profileID
      application
      roles
      token
    }
  }
`;

export const fetchCurrent = gql`
  query GetCurrentUserProfile {
    getCurrentUserProfile {
      completedTutorialsWeb {
        orderList
        orderSwitch
        scheduleList
        whiteBoard
        userSettings
        accountSettings
      }
      # lastReleaseNotes
      # metadata {
      #   createdAt
      #   createdByUserID
      #   lastUpdatedAt
      #   lastUpdatedByUserID
      # }
      # notificationPreferences {
      #   emailOk
      #   textOk
      # }
      roles
      email
      username
      associatedHaulerIDs
      phoneNumber
      lastName
      firstName
      active
      id
      authorities {
        active
        haulerID
        # haulerName
        applicationName
        roles
      }
    }
  }
`;
