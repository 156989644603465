import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice, UniversalService } from '@alliance-disposal/transport-types';
import { TanDataGrid } from '@wayste/sour-ui';
import { formatISODateString, moneyFormatter } from '@wayste/utils';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import { routes } from '../../utils';
import DetailsCardWrapper from '../ui/DetailsCardWrapper';

type Payable = {
  payable: Invoice.PayableTransport;
  invoiceNumber?: string;
  id?: string;
  servicePeriod?: string;
  vendor?: string;
  total?: string;
  paid?: string;
  actions?: undefined;
  href?: string;
};

interface ServiceOrderPayablesCardProps {
  serviceOrderIds: string[];
  suppressQuery?: boolean;
  serviceGrouping?: UniversalService.ServiceGrouping;
}

const PayablesTableCard = ({ serviceOrderIds, suppressQuery, serviceGrouping }: ServiceOrderPayablesCardProps) => {
  const { showFlash } = useContext(UIContext);
  const client = useWaysteClient();

  const [payables, setPayables] = useState<Invoice.PayableTransport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const fetchPayables = async (serviceOrderIds: string[]) => {
    setLoading(true);
    try {
      if (!serviceOrderIds.length) return;
      const payables = await client.invoice().adminPortal.payable.query({
        serviceOrderID: Array.from(new Set(serviceOrderIds)).join(','),
      });

      setPayables(payables);
    } catch (error) {
      console.error(error);
      showFlash('Error Fetching Payables', 'warning');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading && !suppressQuery) fetchPayables(serviceOrderIds);
  }, [serviceOrderIds, suppressQuery]);

  const getPayableFromTransport = (payable: Invoice.PayableTransport) => {
    let servicePeriod = 'Unknown';
    const serviceOrder = serviceGrouping?.serviceOrders.find(
      (serviceOrder) => serviceOrder.id === payable.invoiceDetails.serviceOrderID,
    );

    if (serviceOrder) {
      servicePeriod = `${formatISODateString(serviceOrder.startDate?.toString())} - ${
        serviceOrder.endDate ? formatISODateString(serviceOrder.endDate?.toString()) : 'Ongoing'
      }`;
    }

    console.log(payable);
    return {
      payable: payable,
      id: payable.id,
      invoiceNumber: payable.invoiceDetails.invoiceNumber,
      servicePeriod,
      vendor: payable.vendorName,
      total: payable.invoiceDetails.total.toString(),
      paid: payable.invoiceDetails.paidInFull ? 'Yes' : 'No',
      href:
        (payable.invoiceDetails.serviceGroupingID || serviceGrouping?.id) && payable.invoiceDetails.serviceOrderID
          ? routes.billingProduct.details(
              payable.invoiceDetails.serviceGroupingID || serviceGrouping?.id || '',
              payable.invoiceDetails.serviceOrderID,
            )
          : undefined,
    };
  };

  const [data, setData] = useState<Payable[]>(payables.map((payable) => getPayableFromTransport(payable)) ?? []);
  useEffect(() => {
    setData(payables.map((payable) => getPayableFromTransport(payable)));
  }, [payables]);

  const columnHelper = createColumnHelper<Payable>();
  const columns: ColumnDef<Payable, any>[] = [
    columnHelper.accessor('invoiceNumber', {
      header: 'Payable #',
      cell: (info) => (
        <div className={`whitespace-nowrap ${info.getValue() ? '' : 'text-red-500'}`}>
          {info.getValue() ?? 'Missing'}
        </div>
      ),
    }),
    columnHelper.accessor('servicePeriod', {
      header: 'Service Period',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('vendor', {
      header: 'Vendor',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('total', {
      header: 'Total',
      cell: (info) => <div className="whitespace-nowrap">{moneyFormatter(info.getValue())}</div>,
    }),
    columnHelper.accessor('paid', {
      header: 'Paid',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('actions', {
      header: '',
      cell: (info) => (
        <div className="flex align-middle float-middle">
          {info.row.original.href && (
            <ArrowTopRightOnSquareIcon
              className="h-5 w-5 mr-4 cursor-pointer"
              color={'#1096DE' /* wayste-blue-400*/}
              onClick={() => {
                if (info.row.original.href) {
                  history.push(info.row.original.href);
                } else {
                  showFlash('Missing Link', 'warning');
                }
              }}
            />
          )}
        </div>
      ),
    }),
  ];

  return (
    <DetailsCardWrapper heading="Payables">
      <TanDataGrid data={data} columns={columns} loading={loading} className="-mt-5 -mb-5 -ml-5 -mr-5" />
    </DetailsCardWrapper>
  );
};

export default PayablesTableCard;
