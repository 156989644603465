import { Customer, Pricing } from '@alliance-disposal/transport-types';
import Dialog from '../../../Dialog';
import CustomMaterialPricingForm from './CustomMaterialPricingForm';

export type AddMaterialProps = {
  selectedCustomerDiscount: Pricing.CustomerDiscountTransport;
  selectedDiscountData?: Pricing.DiscountDataTransport;
  customer?: Customer.AllianceCustomerTransport;
};

const AddMaterial = ({
  selectedCustomerDiscount,
  selectedDiscountData,
  handleClose,
  handleSubmit,
  customer,
}: {
  handleClose: () => void;
  handleSubmit: (opts: {
    pricingZoneID: string;
    materialDiscount: Pricing.DiscountDataTransport;
    discountType: Pricing.CustomerDiscountType.DOLLAR | Pricing.CustomerDiscountType.FIXED;
  }) => Promise<void>;
  selectedCustomerDiscount?: Pricing.CustomerDiscountTransport;
  selectedDiscountData?: Pricing.DiscountDataTransport;
  customer?: Customer.AllianceCustomerTransport;
}) => {
  if (!selectedCustomerDiscount?.id) return null;
  return (
    <CustomMaterialPricingForm
      handleClose={() => handleClose()}
      handleSubmit={async (opts) => handleSubmit(opts)}
      pricingZone={selectedCustomerDiscount?.pricingZone}
      selectedDiscount={selectedCustomerDiscount}
      selectedDiscountData={selectedDiscountData}
      customer={customer}
    />
  );
};

const CustomMaterialPricingModal = ({
  handleClose,
  handleSubmit,
  selectedCustomerDiscount,
  selectedDiscountData,
  customer,
}: {
  handleClose: () => void;
  handleSubmit: (opts: {
    pricingZoneID: string;
    materialDiscount: Pricing.DiscountDataTransport;
    discountType: Pricing.CustomerDiscountType.DOLLAR | Pricing.CustomerDiscountType.FIXED;
  }) => Promise<void>;
  selectedCustomerDiscount?: Pricing.CustomerDiscountTransport;
  selectedDiscountData?: Pricing.DiscountDataTransport;
  customer?: Customer.AllianceCustomerTransport;
}) => {
  return (
    <Dialog
      open={Boolean(selectedCustomerDiscount?.id)}
      onClose={handleClose}
      styledTitle={'Create Material Pricing'}
      className="!max-w-4xl"
    >
      <AddMaterial
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        selectedCustomerDiscount={selectedCustomerDiscount}
        selectedDiscountData={selectedDiscountData}
        customer={customer}
      />
    </Dialog>
  );
};

export default CustomMaterialPricingModal;
