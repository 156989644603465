import {
  BanknotesIcon,
  ChartBarSquareIcon,
  ClipboardDocumentListIcon,
  Cog6ToothIcon,
  CurrencyDollarIcon,
  MagnifyingGlassIcon,
  RectangleStackIcon,
  TicketIcon,
  TruckIcon,
} from '@heroicons/react/24/solid';
import GlobalSearch from '../components/GlobalSearch/GlobalSearch';
import HaulerList from '../components/HaulerList';
import InternalTicketWorkflow from '../components/InternalTicketWorkflow';
import OrderList from '../components/OrderList';
import PricingList from '../components/PricingList';
import Reports from '../components/Reports';
import Settings from '../pages/account-settings/Settings';
import BillingPage from '../pages/billing/Billing';
import LeadList from '../pages/leads/Leads';
import Paths from '../utils/routes';

export const routes = [
  {
    component: LeadList,
    text: 'Sales Funnel',
    path: Paths.leads.list,
    exact: true,
    icon: <RectangleStackIcon />,
  },
  {
    component: InternalTicketWorkflow,
    text: 'Workflow',
    path: Paths.internalTickets.list,
    exact: false,
    icon: <TicketIcon />,
  },
  {
    name: 'Roll Off',
    shortName: 'RO',
    subRoutes: [
      {
        component: OrderList,
        text: 'Orders',
        path: Paths.orders.list,
        exact: false,
        icon: <ClipboardDocumentListIcon />,
      },
      {
        component: BillingPage,
        text: 'Billing',
        path: Paths.billing.list,
        exact: false,
        icon: <BanknotesIcon />,
      },
    ],
  },
  {
    name: 'Universal Services',
    shortName: 'US',
    subRoutes: [
      {
        component: OrderList,
        text: 'Orders',
        path: Paths.universalServices.list,
        exact: false,
        icon: <ClipboardDocumentListIcon />,
      },
      {
        component: BillingPage,
        text: 'Billing',
        path: Paths.billingProduct.list,
        exact: false,
        icon: <BanknotesIcon />,
      },
    ],
  },
  {
    component: HaulerList,
    text: 'Vendors',
    path: Paths.haulers.list,
    exact: false,
    icon: <TruckIcon />,
  },
  {
    component: PricingList,
    text: 'Pricing',
    path: Paths.pricing.list,
    exact: false,
    icon: <CurrencyDollarIcon />,
  },
  {
    component: Reports,
    text: 'Reports',
    path: Paths.reports,
    exact: false,
    icon: <ChartBarSquareIcon />,
  },
  {
    component: GlobalSearch,
    text: 'Search',
    path: Paths.search,
    exact: false,
    icon: <MagnifyingGlassIcon />,
  },
  {
    component: Settings,
    text: 'Settings',
    path: Paths.accountSettings.list,
    exact: false,
    icon: <Cog6ToothIcon />,
  },
];
