import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { Dialog, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useHistory, useParams } from 'react-router-dom';
import { UIContext } from '../../contexts';
import routes from '../../utils/routes';
import {
  leadReasonsLost,
  leadReasonsLostEnums,
  leadStatusTypes,
  leadStatusTypesEnums,
  leadTypes,
  leadTypesEnums,
} from '../../utils/shared-types';
import ButtonSelect from '../ButtonSelect';
import Loading from '../Loading';
import LeadCMDetails from './LeadCMDetails';
import LeadPTDetails from './LeadPTDetails';
import LeadRODetails from './LeadRODetails';
import LeadRSDetails from './LeadRSDetails';

export interface LeadDetailsProps {
  isModal?: boolean;
}

const LeadDetails = ({ isModal }: LeadDetailsProps) => {
  const history = useHistory();
  const client = useWaysteClient();
  const { id }: { id: string } = useParams();
  const { showFlash } = useContext(UIContext);

  // STATE
  const [lead, setLead] = useState<Customer.AllianceLeadTransport | null>(null);
  const [reasonLostOpen, setReasonLostOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //////////////////////////////////////////////
  // HOOKS SECTION
  //////////////////////////////////////////////

  useEffect(() => {
    let subscription: any;
    if (id) {
      setIsLoading(true);
      subscription = client
        .customer()
        .adminPortal.leads.subscription.subscribeToLead(
          id,
          async (response: { status: string; data: Customer.AllianceLeadTransport }) => {
            setLead(response.data);
            setIsLoading(false);
          },
        );
    } else {
      setLead(null);
    }

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [id]);

  //////////////////////////////////////////////
  // FUNCTION SECTION
  //////////////////////////////////////////////

  const handleBackButtonClick = (type: string, status: string) => {
    history.push(routes.leads.list, { pipeline: type, leadStatus: status });
  };

  const handleUpdateSubmit = async (leadObj: any) => {
    if (!lead) {
      showFlash('Error Updating Lead', 'warning');
      return;
    }

    try {
      await client.customer().adminPortal.leads.update(lead?.id, leadObj);

      if (leadObj.quotes) {
        for (const quote of leadObj.quotes) {
          if (!quote.id) {
            if (lead?.id) {
              await client.customer().adminPortal.leads.quote.create(lead?.id, quote);
            }
          }
        }
      }
      if (lead?.quotes.length && lead?.quotes?.length > 0) {
        for (const existingQuote of lead.quotes) {
          if (!leadObj?.quotes?.find((item: any) => item.id === existingQuote.id)) {
            await client.customer().adminPortal.leads.quote.delete(lead.id, existingQuote.id);
          }
        }
      }
      if (leadObj.haulerQuotes) {
        for (const haulerQuote of leadObj.haulerQuotes) {
          if (!haulerQuote.id) {
            if (lead?.id) {
              await client.customer().adminPortal.leads.haulerQuote.create(lead?.id, haulerQuote);
            }
          }
        }
      }
      if (leadObj.contactEvents) {
        for (const event of leadObj.contactEvents) {
          if (!event.id) {
            if (lead?.id) {
              await client.customer().adminPortal.leads.contactEvent.create(lead?.id, event);
            }
          }
        }
      }
      showFlash('Lead Successfully Updated', 'success');
    } catch (error) {
      showFlash('Error Updating Lead', 'warning');
    }
  };

  const handleLeadStatusChange = async (id: string, status: string) => {
    try {
      const response = await client.customer().adminPortal.leads.update(id, { status });
      if (response.status === leadStatusTypesEnums.lost) setReasonLostOpen(true);
      showFlash('Lead Successfully Updated', 'success');
    } catch (error) {
      showFlash('Error Updating Lead', 'warning');
    }
  };

  const handleLeadLostSave = (reason: string, note: string) => {
    setReasonLostOpen(false);
    handleUpdateSubmit({ reasonLost: reason, reasonLostNote: note });
  };

  //////////////////////////////////////////////
  // RENDER SECTION
  //////////////////////////////////////////////

  const getDetailsContent = () => {
    switch (lead?.type) {
      case leadTypesEnums.rollOff:
        return <LeadRODetails rawLead={lead} />;
      case leadTypesEnums.commercial:
        return <LeadCMDetails rawLead={lead} />;
      case leadTypesEnums.residential:
        return <LeadRSDetails rawLead={lead} />;
      case leadTypesEnums.portableToilet:
        return <LeadPTDetails rawLead={lead} />;
      default:
        return <div>Lead Type Missing</div>;
    }
  };

  if (!lead || isLoading) return <Loading />;

  const content = (
    <div className="flex flex-col lg:px-12">
      <div className="py-3 flex justify-between items-center">
        <div className="flex">
          <button className="btn-dark-grey-outlined" onClick={() => handleBackButtonClick(lead.type, lead.status)}>
            <ChevronLeftIcon className="h-5 w-5 mr-1" />
            Leads
          </button>
          <h6 className="text-xl font-medium ml-7">{leadTypes[lead.type as keyof typeof leadTypes]} Lead Details</h6>
        </div>
        <ButtonSelect
          menuItems={Object.keys(leadStatusTypes).map((key) => ({
            label: leadStatusTypes[key as keyof typeof leadStatusTypes],
            value: key,
          }))}
          value={lead.status}
          onSelect={(value) => handleLeadStatusChange(lead.id, value)}
        />
      </div>
      {getDetailsContent()}
      <LeadLostDialog open={reasonLostOpen} onClose={() => setReasonLostOpen(false)} onSave={handleLeadLostSave} />
    </div>
  );

  if (isModal)
    return (
      <Dialog open={true} onClose={() => history.goBack()} className="bg-[#FAFAFA]" fullScreen>
        <div className="h-full px-4 py-2">{content}</div>
      </Dialog>
    );

  return <div className="max-w-7xl xl:mx-auto px-4 pb-5">{content}</div>;
};

LeadDetails.defaultProps = {
  isModal: false,
};

export default LeadDetails;

export interface LeadLostDialogProps {
  onSave: (reason: string, note: string) => void;
  open: boolean;
  onClose: () => void;
}

const LeadLostDialog = ({ onSave, open, onClose }: LeadLostDialogProps) => {
  const [reason, setReason] = useState(leadReasonsLostEnums.noResponse);
  const [note, setNote] = useState('');

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="flex flex-col gap-4">
        <h6 className="text-xl">Reason We Lost Them</h6>
        <Select label="Reason we lost lead" value={reason} onSelect={(value) => setReason(value)}>
          {Object.entries(leadReasonsLost).map((item) => (
            <SelectOption key={`reason-${item[0]}`} value={item[0]}>
              {item[1]}
            </SelectOption>
          ))}
        </Select>
        <TextField
          label="Notes"
          inputProps={{
            value: note,
            onChange: (event) => setNote(event.target.value),
          }}
        />
      </div>
      <div className="flex justify-end items-center gap-4 mt-6">
        <button className="btn-dark-grey-outlined" onClick={onClose} type="button">
          Cancel
        </button>
        <button className="btn-primary ml-2" onClick={() => onSave(reason, note)} type="button">
          Save
        </button>
      </div>
    </Dialog>
  );
};
