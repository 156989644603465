import React, { Fragment, ReactElement } from 'react';
import { Menu, Transition } from '@headlessui/react';

interface MenuButtonProps {
  children: ReactElement<typeof MenuItem>[];
  buttonItem: React.ReactNode;
  className?: string;
}

export const MenuButton = ({ children, className, buttonItem }: MenuButtonProps) => {
  return (
    <Menu as="div" className="relative inline-block">
      <Menu.Button className={className}>{buttonItem}</Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="py-1 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[1300]">
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

interface MenuItemProps {
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}

export const MenuItem = ({ className, onClick, children, disabled }: MenuItemProps) => {
  return (
    <Menu.Item disabled={disabled}>
      {({ active }) => (
        <div
          className={`px-4 py-1.5 cursor-pointer text-base ${active && 'bg-slate-100'} ${className} ${
            disabled ? '!text-gray-300 !cursor-not-allowed' : ''
          }`}
          onClick={onClick}
        >
          {children}
        </div>
      )}
    </Menu.Item>
  );
};
