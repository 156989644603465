import React, { useEffect, useRef, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, InternalTicket, Order, Profile } from '@alliance-disposal/transport-types';
import { AutoComplete, AutoCompleteOption } from '@wayste/sour-ui';
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  defaultDropAnimation,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { BoardSection } from './BoardSections';
import { DetailsDrawer } from './DetailsDrawer';
import { TicketItem } from './TicketItem';

type BoardSectionsType = {
  [name: string]: InternalTicket.InternalTicketTransport[];
};

const BOARD_SECTIONS = { undefined: 'No Status', ...InternalTicket.InternalTicketStatusLabels };

const getTicketByStatus = (
  tickets: InternalTicket.InternalTicketTransport[],
  status: InternalTicket.InternalTicketStatus | 'undefined',
) => {
  return tickets.filter((ticket) => {
    if (status === 'undefined') {
      return ticket.status === undefined;
    }
    return ticket.status === status;
  });
};

const initializeBoard = (tickets: InternalTicket.InternalTicketTransport[]) => {
  const boardSections: BoardSectionsType = {};

  Object.keys(BOARD_SECTIONS).forEach((boardSectionKey) => {
    boardSections[boardSectionKey] = getTicketByStatus(tickets, boardSectionKey as InternalTicket.InternalTicketStatus);
  });

  return boardSections;
};

const findBoardSectionContainer = (boardSections: BoardSectionsType, id: string) => {
  if (id in boardSections) {
    return id;
  }

  const container = Object.keys(boardSections).find((key) => boardSections[key].find((item) => item.id === id));
  return container;
};

const getTicketById = (tickets: InternalTicket.InternalTicketTransport[], id: string) => {
  return tickets.find((ticket) => ticket.id === id);
};

export type Entities = { [key: string]: Order.AllianceOrderTransport | Customer.AllianceCustomerTransport | object };

const InternalTicketWorkflow = () => {
  const client = useWaysteClient();
  const currentUser = client.user().get();
  const [rosterMap, setRosterMap] = useState({});
  const [profileOptions, setProfileOptions] = useState<Profile.ProfileTransport[]>([]);
  const entities = useRef<Entities>({}); // need to useRef to reduce number of entity fetches
  const [allTickets, setAllTickets] = useState<InternalTicket.InternalTicketTransport[]>([]);
  const [tickets, setTickets] = useState<InternalTicket.InternalTicketTransport[]>([]);
  const [boardSections, setBoardSections] = useState<BoardSectionsType>(initializeBoard([]));
  const [activeTicketId, setActiveTicketId] = useState<null | string>(null);
  const [updateSignal, setUpdateSignal] = useState(0); // State to trigger re-renders on entities ref change
  const [drawerIsOpen, setDrawerIsOpen] = useState<{ open: boolean; ticket?: InternalTicket.InternalTicketTransport }>({
    open: false,
    ticket: undefined,
  });
  const [allTags, setAllTags] = useState<InternalTicket.TagTransport[]>([]);
  const allVendorNames = useRef<{ [key: string]: string }>({}); // need to useRef to make it easier
  // FILTERS State Start
  const [selectedTeam, setSelectedTeam] = useState<Profile.SourgumTeam | ''>('');
  const [selectedUser, setSelectedUser] = useState(currentUser.id);
  const [selectedTagID, setSelectedTagID] = useState<string>('');
  const [selectedTagCategory, setSelectedTagCategory] = useState<InternalTicket.TagCategory | ''>('');
  const [selectedVendorName, setSelectedVendorName] = useState<string>('');
  // FILTERS State End

  /** Used to store entities locally to reduce fetches needed */
  const handleAddEntity = async (entityID: string, entityType: InternalTicket.InternalTicketRelatedEntity) => {
    if (!entities.current[entityID]) {
      entities.current[entityID] = {};
      switch (entityType) {
        case 'sourgum-order':
          const orderResponse = await client.order().adminPortal.fetch(entityID);
          entities.current[entityID] = orderResponse;
          if (orderResponse.vendorName) allVendorNames.current[orderResponse.vendorName] = '';
          break;
        case 'sourgum-customer':
          const customerResponse = await client.customer().adminPortal.fetch(entityID);
          entities.current[entityID] = customerResponse;
          break;
        default:
          break;
      }
      setUpdateSignal((prev) => prev + 1); // Trigger re-render
    }
  };

  const handleGetTickets = async () => {
    const response = await client.internalTicket().adminPortal.query({
      assignedTeam: selectedTeam || undefined,
      assignedToProfileID: selectedUser || undefined,
      tags: selectedTagID ? selectedTagID : undefined,
      deletedAt: false,
    });
    setTickets(response.results);
    setAllTickets(response.results);
    setBoardSections(initializeBoard(response.results));
  };

  const handleGetTags = async () => {
    const response = await client.internalTicket().adminPortal.tag.query({ deletedAt: false });
    setAllTags(response.results);
  };

  useEffect(() => {
    const response = client.profile().adminPortal.getRosterFromMemory().idToProfileMap;
    setRosterMap(response);
    const onlyActive = Object.values(response).filter((item) => item.active);
    const sortedProfiles = onlyActive.sort((a, b) => {
      return (a.firstName || '') > (b.firstName || '') ? 1 : -1;
    });
    setProfileOptions(sortedProfiles);
    handleGetTags();
  }, []);

  useEffect(() => {
    if (!selectedUser && !selectedTeam) return;
    if (selectedTagCategory) {
      // const tagIDs = allTags.filter((tag) => tag.category === selectedTagCategory);
    }
    handleGetTickets();
  }, [selectedTeam, selectedUser, selectedTagID, selectedTagCategory]);

  useEffect(() => {
    if (selectedVendorName) {
      const entitiesWithVendorName = Object.values(entities.current as Entities)
        .filter((entity) => (entity as Order.AllianceOrderTransport)?.vendorName === selectedVendorName)
        .map((entity) => (entity as Order.AllianceOrderTransport)?.id);
      const filteredTickets = allTickets.filter((ticket) => entitiesWithVendorName.includes(ticket.entityID));
      setTickets(filteredTickets);
      setBoardSections(initializeBoard(filteredTickets));
    } else if (allTickets.length > 0) {
      setTickets(allTickets);
      setBoardSections(initializeBoard(allTickets));
    }
  }, [selectedVendorName]);

  const handleTicketItemClick = (ticket: InternalTicket.InternalTicketTransport) => {
    setDrawerIsOpen({ open: true, ticket });
  };

  ///////////////////////////////////////////////////////
  // DRAG AND DROP SECTION START
  ///////////////////////////////////////////////////////

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveTicketId(active.id as string);
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    // Find the containers
    const activeContainer = findBoardSectionContainer(boardSections, active.id as string);
    const overContainer = findBoardSectionContainer(boardSections, over?.id as string);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    setBoardSections((boardSection) => {
      const activeItems = boardSection[activeContainer];
      const overItems = boardSection[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex((item) => item.id === active.id);
      const overIndex = overItems.findIndex((item) => item.id !== over?.id);

      return {
        ...boardSection,
        [activeContainer]: [...boardSection[activeContainer].filter((item) => item.id !== active.id)],
        [overContainer]: [
          ...boardSection[overContainer].slice(0, overIndex),
          boardSections[activeContainer][activeIndex],
          ...boardSection[overContainer].slice(overIndex, boardSection[overContainer].length),
        ],
      };
    });
  };

  const handleUpdateStatus = async (ticketID: string, newStatus: InternalTicket.InternalTicketStatus | null) => {
    await client.internalTicket().adminPortal.update(ticketID, { status: newStatus });
    handleGetTickets();
  };

  const handleDragEnd = async ({ active, over }: DragEndEvent) => {
    const activeContainer = findBoardSectionContainer(boardSections, active.id as string);
    const overContainer = findBoardSectionContainer(boardSections, over?.id as string);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = boardSections[activeContainer].findIndex((task) => task.id === active.id);
    const overIndex = boardSections[overContainer].findIndex((task) => task.id === over?.id);

    if (activeIndex !== overIndex) {
      setBoardSections((boardSection) => ({
        ...boardSection,
        [overContainer]: arrayMove(boardSection[overContainer], activeIndex, overIndex),
      }));
      console.log(activeTicketId);
      if (activeTicketId && over?.id) {
        handleUpdateStatus(
          activeTicketId,
          over.id === 'undefined' ? null : (over.id as keyof typeof InternalTicket.InternalTicketStatusLabels),
        );
      }
    }

    setActiveTicketId(null);
  };

  const dropAnimation: DropAnimation = {
    ...defaultDropAnimation,
  };

  const ticket = activeTicketId ? getTicketById(tickets, activeTicketId) : null;

  ///////////////////////////////////////////////////////
  // DRAG AND DROP SECTION START
  ///////////////////////////////////////////////////////

  return (
    <div className="container mx-auto px-3 pt-4 pb-10 flex flex-col flex-1 gap-6">
      <div className="flex gap-4 max-w-fit">
        {/* select user */}
        <AutoComplete
          label="User"
          onSelect={(value) => {
            setSelectedUser(value);
          }}
          value={selectedUser}
          helperText="User or team required"
        >
          {profileOptions.map((profile) => (
            <AutoCompleteOption value={profile.id} key={profile.id}>
              {(profile.firstName || '') + ' ' + (profile.lastName || '')}
            </AutoCompleteOption>
          ))}
        </AutoComplete>
        {/* select team */}
        <AutoComplete
          label="Team"
          onSelect={(value) => {
            setSelectedTeam(value as Profile.SourgumTeam | '');
          }}
          value={selectedTeam}
        >
          {Object.entries(Profile.SourgumTeamLabels).map((item) => (
            <AutoCompleteOption value={item[0]} key={item[0]}>
              {item[1]}
            </AutoCompleteOption>
          ))}
        </AutoComplete>
        <div className="flex gap-4 items-center">
          <AutoComplete
            label="Tag name"
            onSelect={(value) => {
              setSelectedTagCategory('');
              setSelectedTagID(value);
            }}
            value={selectedTagID}
          >
            {allTags.map((tag) => (
              <AutoCompleteOption value={tag.id} key={tag.name}>
                {tag.name}
              </AutoCompleteOption>
            ))}
          </AutoComplete>
          <AutoComplete
            label="Tag category"
            onSelect={(value) => {
              setSelectedTagID('');
              setSelectedTagCategory(value as keyof typeof InternalTicket.TagCategoryLabels);
            }}
            value={selectedTagCategory}
          >
            {Object.entries(InternalTicket.TagCategoryLabels).map((item) => (
              <AutoCompleteOption value={item[0]} key={item[0]}>
                {item[1]}
              </AutoCompleteOption>
            ))}
          </AutoComplete>
          <AutoComplete
            onSelect={(value) => setSelectedVendorName(value)}
            label="Vendor name"
            value={selectedVendorName}
          >
            {Object.keys(allVendorNames.current).map((vendorName) => (
              <AutoCompleteOption value={vendorName} key={vendorName}>
                {vendorName}
              </AutoCompleteOption>
            ))}
          </AutoComplete>
        </div>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <div className="flex gap-8 flex-1">
          {Object.keys(boardSections).map((boardSectionKey) => (
            <React.Fragment key={boardSectionKey}>
              <BoardSection
                id={boardSectionKey}
                title={BOARD_SECTIONS[boardSectionKey as keyof typeof BOARD_SECTIONS]}
                tickets={boardSections[boardSectionKey]}
                entities={entities.current}
                onAddEntity={handleAddEntity}
                key={updateSignal}
                onTicketItemClick={handleTicketItemClick}
                rosterMap={rosterMap}
                onRefreshTickets={handleGetTickets}
              />
            </React.Fragment>
          ))}
          <DragOverlay dropAnimation={dropAnimation}>
            {ticket ? (
              <TicketItem
                ticket={ticket}
                entities={entities.current}
                key={updateSignal}
                rosterMap={rosterMap}
                onRefreshTickets={handleGetTickets}
              />
            ) : null}
          </DragOverlay>
        </div>
      </DndContext>
      <DetailsDrawer
        isOpen={drawerIsOpen.open}
        onClose={() => {
          handleGetTickets();
          setDrawerIsOpen({ open: false, ticket: undefined });
        }}
        ticket={drawerIsOpen.ticket}
      />
    </div>
  );
};

export default InternalTicketWorkflow;
