import React, { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import {
  ArrowLeftOnRectangleIcon,
  BellIcon,
  LockClosedIcon,
  LockOpenIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { SourGumLeaf } from '../assets/icons/sourgum-leaf';
import routeLinks from '../utils/routes';
import { routes } from './MenuRoutes';

const lockStorageVar = 'adminLockNav';

/**
 * Side navigation bar on desktop ( > 1024px) displays
 * @param {*} onLogout Function to run when logout clicked
 */
const DesktopToolbar = ({ onLogout }: { onLogout: () => void }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [locked, setLocked] = useState(false);
  const [changeRequestCount, setChangeRequestCount] = useState(0);

  const client = useWaysteClient();

  useEffect(() => {
    const sub = client
      .adminPortal()
      .order.changes.subscribe({
        status: ['PENDING'],
      })
      .subscribe((changes) => {
        setChangeRequestCount(changes.total);
      });

    // sub to orders with status: NEEDS_REVIEW

    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const lockVar = window.localStorage.getItem(lockStorageVar);
    setLocked(lockVar === 'true');
  }, []);

  function drawerOpenHandler() {
    if (!locked) setOpen(true);
  }

  function drawerCloseHandler() {
    setOpen(false);
  }

  const onToggleLock = () => {
    setLocked(!locked);
    window.localStorage.setItem(lockStorageVar, !locked + '');
    locked ? setOpen(true) : drawerCloseHandler();
  };

  return (
    <nav
      onMouseEnter={drawerOpenHandler}
      onMouseLeave={drawerCloseHandler}
      className={`${
        open ? 'w-60' : 'w-16'
      } transition-all duration-200 ease-in flex-none bg-sourgum-greyblue-900 overflow-hidden flex flex-col`}
    >
      <div className="flex flex-col justify-center items-center px-2 h-16">
        <NavLink to={'/'} exact={true} className="no-underline mt-2">
          <SourGumLeaf height="2.5rem" />
        </NavLink>
      </div>
      <div className="px-2 mt-2">
        <hr className="w-full px-2" />
      </div>
      <ul className="w-full flex flex-col overflow-y-auto bg-sourgum-greyblue-900 m-0 px-0 py-2 whitespace-nowrap overflow-hidden flex-1">
        <li className="flex items-center py-2 px-4 min-h-[48px] cursor-pointer">
          <div className="flex min-w-[56px] text-left relative">
            <span className="inline-flex items-center justify-center p-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full absolute z-1 -top-2.5 right-5 h-5.5 w-5.5">
              {changeRequestCount}
            </span>
            <BellIcon className="text-sourgum-greyblue-50 h-6 w-6" />
          </div>
          <span className="text-sourgum-greyblue-50">Notifications</span>
        </li>

        <li
          className="flex items-center py-2 px-4 min-h-[48px] cursor-pointer"
          onClick={() => {
            history.push(routeLinks.leads.create, { modal: true });
          }}
        >
          <div className="flex min-w-[56px] text-left">
            <PlusCircleIcon className="text-sourgum-greyblue-50 h-6 w-6" />
          </div>
          <span className="text-sourgum-greyblue-50">Create Lead</span>
        </li>
        {routes.map((route, index) => {
          const nextRouteIsGroup = routes[index + 1]?.subRoutes;

          if ('subRoutes' in route) {
            return (
              <div className={`flex flex-col`} key={index}>
                <span
                  className={`mx-2 text-sourgum-greyblue-50 px-4 py-2 text-sm font-bold uppercase tracking-wider border-b`}
                >
                  {open ? route.name : route.shortName}
                </span>
                {route.subRoutes?.map((subRoute) => {
                  return (
                    <NavLink
                      key={route.name + subRoute.text}
                      to={subRoute.path}
                      exact={subRoute.exact}
                      className={`text-sourgum-greyblue-50 no-underline ${open && 'ml-4'} transition-all`}
                      activeClassName="!text-sourgum-green-300"
                    >
                      <li className="flex items-center py-2 px-4 min-h-[48px] cursor-pointer">
                        <div className="flex min-w-[56px] text-left">
                          <div className="h-6 w-6">{subRoute.icon}</div>
                        </div>
                        <span className="text-inherit">{subRoute.text}</span>
                      </li>
                    </NavLink>
                  );
                })}
                {!nextRouteIsGroup && <div className={`border-b mx-2 transition-all`} />}
              </div>
            );
          } else {
            return (
              <NavLink
                key={route.text}
                to={route.path}
                exact={route.exact}
                className="text-sourgum-greyblue-50 no-underline"
                activeClassName="!text-sourgum-green-300"
              >
                <li className="flex items-center py-2 px-4 min-h-[48px] cursor-pointer">
                  <div className="flex min-w-[56px] text-left">
                    <div className="h-6 w-6">{route.icon}</div>
                  </div>
                  <span className="text-inherit">{route.text}</span>
                </li>
              </NavLink>
            );
          }
        })}
        <li className="flex items-center py-2 px-4 min-h-[48px] cursor-pointer mt-auto" onClick={onToggleLock}>
          <div className="flex min-w-[56px] text-left">
            {locked ? (
              <LockClosedIcon className="text-sourgum-greyblue-50 h-6 w-6" />
            ) : (
              <LockOpenIcon className="text-sourgum-greyblue-50 h-6 w-6" />
            )}
          </div>
          <span className="text-sourgum-greyblue-50">Lock Closed</span>
        </li>
        <li className="flex items-center py-2 px-4 min-h-[48px] cursor-pointer" onClick={onLogout}>
          <div className="flex min-w-[56px] text-left">
            <ArrowLeftOnRectangleIcon className="text-sourgum-greyblue-50 h-6 w-6" />
          </div>
          <span className="text-sourgum-greyblue-50">Sign Out</span>
        </li>
      </ul>
    </nav>
  );
};

export default DesktopToolbar;
