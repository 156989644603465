import { UniversalService } from '@alliance-disposal/transport-types';
import { moneyFormatter } from '@wayste/utils';
import { PencilIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../utils';
import CardInfoList, { CardInfoListData } from '../../ui/CardInfoList';
import DetailsCardWrapper from '../../ui/DetailsCardWrapper';

interface SubscriptionBillingDetailsCardProps {
  serviceOrder: UniversalService.ServiceOrder;
}

const SubscriptionBillingDetailsCard = ({ serviceOrder }: SubscriptionBillingDetailsCardProps) => {
  const history = useHistory();
  const recurring = serviceOrder.events.find(
    (event) => event.lineItemType.description === 'recurring' && event.unitPrice !== 0,
  );
  const delivery = serviceOrder.events.find(
    (event) => event.lineItemType.description === 'delivery' && event.unitPrice !== 0,
  );
  const removal = serviceOrder.events.find(
    (event) => event.lineItemType.description === 'removal' && event.unitPrice !== 0,
  );
  const otherRecurringCharges = serviceOrder.subscriptionDetails?.periodicEvents
    .slice(1)
    .filter((event) => event.unitPrice !== 0)
    .reduce((total, event) => total + event.unitPrice * event.priceQuantity, 0); //without first event
  const otherFirstTimeCharges = serviceOrder.events
    .filter(
      (event) =>
        event.lineItemType.description !== 'delivery' &&
        event.lineItemType.description !== 'removal' &&
        event.lineItemType.description !== 'recurring' &&
        event.unitPrice !== 0,
    )
    .reduce((total, event) => total + event.unitPrice * event.priceQuantity, 0);
  const colOneRowOne: CardInfoListData[] = [
    {
      label: 'Recurring Rate',
      value: moneyFormatter((recurring?.unitPrice || 0) * (recurring?.priceQuantity || 1)) || '',
    },
    {
      label: 'Other Recurring Charges',
      value: moneyFormatter(otherRecurringCharges) || '',
    },
  ];
  const colTwoRowOne: CardInfoListData[] = [
    {
      label: 'Delivery Fee',
      value: moneyFormatter((delivery?.unitPrice || 0) * (delivery?.priceQuantity || 1)) || '',
    },
    {
      label: 'Removal Fee',
      value: moneyFormatter((removal?.unitPrice || 0) * (removal?.priceQuantity || 1)) || '',
    },
    {
      label: 'Other First Time Charges',
      value: moneyFormatter(otherFirstTimeCharges) || '',
    },
  ];
  const colThreeRowOne: CardInfoListData[] = [
    {
      label: 'Cancelation Fee',
      value: moneyFormatter(serviceOrder.cancellationDetails?.customerFee) || '',
    },
  ];

  // Row 2

  const colOneRowTwo: CardInfoListData[] = [
    {
      label: 'Vendor Recurring Rate',
      value: '',
    },
    {
      label: 'Vendor Recurring Charges',
      value: '',
    },
  ];
  const colTwoRowTwo: CardInfoListData[] = [
    {
      label: 'Vendor Delivery Fee',
      value: '',
    },
    {
      label: 'Vendor Removal Fee',
      value: '',
    },
    {
      label: 'Vendor Other First Time Charges',
      value: '',
    },
  ];
  const colThreeRowTwo: CardInfoListData[] = [
    {
      label: 'Vendor Cancelation Fee',
      value: '',
    },
  ];

  return (
    <DetailsCardWrapper
      heading="Order Billing Details"
      buttons={[
        {
          label: (
            <>
              <PencilIcon className="w-5 h-5 mr-1" /> Edit Pricing
            </>
          ),
          onClick: () =>
            history.push(
              routes.universalServices.serviceGrouping.subscription.pricing(
                serviceOrder.serviceGroupingID,
                serviceOrder.id,
              ),
            ),
        },
      ]}
    >
      <div className={'grid grid-cols-1 gap-3 lg:grid-cols-3'}>
        <CardInfoList data={colOneRowOne} border />
        <CardInfoList data={colTwoRowOne} border />
        <CardInfoList data={colThreeRowOne} border />
        <CardInfoList data={colOneRowTwo} border />
        <CardInfoList data={colTwoRowTwo} border />
        <CardInfoList data={colThreeRowTwo} border />
      </div>
    </DetailsCardWrapper>
  );
};

export default SubscriptionBillingDetailsCard;
