import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { DayOfWeek, UniversalService } from '@alliance-disposal/transport-types';
import { DatePicker, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { Controller, FieldPath, UseFormReturn, get } from 'react-hook-form';
import { UIContext } from '../../contexts';
import { UniversalServiceFormData } from './UniversalServiceCreate';

interface SubscriptionServiceOrderSubFormProps {
  methods: UseFormReturn<UniversalServiceFormData, unknown>;
  selectedServiceTypeFamily?: UniversalService.ServiceTypeFamily;
  setSelectedServiceTypeFamily?: React.Dispatch<React.SetStateAction<UniversalService.ServiceTypeFamily | undefined>>;
  index: number;
  mode: 'create' | 'update';
}

const defaultLineItemTypes = ['delivery', 'removal', 'cancellation', 'misc. fee', 'recurring'] as const;
type DefaultLineItemType = (typeof defaultLineItemTypes)[number];
const SubscriptionServiceOrderSubForm = ({
  methods,
  index,
  mode,
  selectedServiceTypeFamily,
  setSelectedServiceTypeFamily,
}: SubscriptionServiceOrderSubFormProps) => {
  const { showFlash } = useContext(UIContext);
  const {
    control,
    setValue,
    formState: { errors },
  } = methods;

  const client = useWaysteClient();
  const [serviceTypeFamilies, setServiceTypeFamilies] = useState<UniversalService.ServiceTypeFamily[]>([]);

  // Define the fetch logic in a separate function
  async function fetchServiceTypeFamilies() {
    try {
      const data = await client.universalService().serviceFamily.query({ orderBy: 'name' });
      setServiceTypeFamilies(data.results);
    } catch (err) {
      console.error('error fetching service types', err);
      return [];
    }
  }

  // Use the function inside useEffect
  useEffect(() => {
    const fetchData = async () => {
      await fetchServiceTypeFamilies();
    };

    fetchData();
  }, []);

  const onServiceFamilySelect = async (id: string) => {
    await fetchServiceTypeFamilies();

    const family = serviceTypeFamilies.find((item) => item.id === id);

    const lineItemMap = new Map<string, UniversalService.ServiceLineItemType>();

    family?.lineItemTypes.forEach((item) => {
      if (defaultLineItemTypes.includes(item.description as DefaultLineItemType)) {
        lineItemMap.set(item.description, item);
      }
    });

    const recurring = lineItemMap.get('recurring');
    const delivery = lineItemMap.get('delivery');
    const removal = lineItemMap.get('removal');

    if (!recurring || !delivery || !removal) {
      showFlash(
        'This Service type is not set up correctly and cannot be used. Please contact an administrator.',
        'warning',
      );
      if (setSelectedServiceTypeFamily) setSelectedServiceTypeFamily(undefined);
      return;
    }
    setValue(
      `serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${0}.lineItemTypeID`,
      recurring.id,
    );

    if (recurring.minQuantity === 0) {
      setValue(`serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${0}.priceQuantity`, 1);
    } else if (recurring.minQuantity > 0) {
      setValue(
        `serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${0}.priceQuantity`,
        recurring.minQuantity,
      );
    }

    setValue(`serviceGrouping.serviceOrders.${index}.serviceEvents.${0}.lineItemTypeID`, delivery.id);
    setValue(`serviceGrouping.serviceOrders.${index}.serviceEvents.${1}.lineItemTypeID`, removal.id);

    if (setSelectedServiceTypeFamily) setSelectedServiceTypeFamily(family);
  };

  return (
    <>
      <div className="flex space-x-2">
        <div className="w-1/2">
          <Select
            label="Select Service Type"
            onSelect={onServiceFamilySelect}
            defaultValue={''}
            disabled={mode === 'update'}
            required
            value={selectedServiceTypeFamily?.id}
          >
            {serviceTypeFamilies.map((ServiceTypeFamily, index) => (
              <SelectOption key={'serviceTypeFamily' + index} value={ServiceTypeFamily.id}>
                {ServiceTypeFamily.name}
              </SelectOption>
            ))}
          </Select>
        </div>
        <div className="w-1/2">
          <Controller
            name={
              mode === 'create'
                ? `serviceGrouping.serviceOrders.${index}.serviceTypeID`
                : (`serviceGrouping.serviceOrders.${index}.serviceType.id` as FieldPath<UniversalServiceFormData>)
            }
            control={control}
            render={({ field }) => (
              <Select
                error={get(errors, '')}
                label="Select SKU"
                onSelect={field.onChange}
                defaultValue={''}
                disabled={!selectedServiceTypeFamily || mode === 'update'}
                required
                // @ts-expect-error - some weirdness where this thing thinks it's getting the entire object
                value={field.value ?? ''}
              >
                {selectedServiceTypeFamily?.serviceTypes.map((serviceType) => (
                  <SelectOption key={serviceType.id} value={serviceType.id}>
                    {serviceType.name}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
        </div>
      </div>
      <div className="flex space-x-2">
        <div className="w-1/2">
          <Controller
            control={control}
            name={`serviceGrouping.serviceOrders.${index}.startDate`}
            render={({ field }) => (
              <DatePicker
                value={field.value ? new Date(field.value) : ''}
                onChange={(date) => field.onChange(date)}
                // minDate={mode === 'update' ? undefined : startOfToday()}
                required
                label="Start Date"
                closeOnSelect
                clearable
              />
            )}
          />
        </div>
        <div className="w-1/2">
          <Controller
            control={control}
            name={`serviceGrouping.serviceOrders.${index}.endDate`}
            render={({ field }) => (
              <DatePicker
                value={field.value ? new Date(field.value) : ''}
                onChange={(date) => field.onChange(date)}
                // minDate={mode === 'update' ? undefined : startOfToday()}
                label="End Date"
                inputProps={{ disabled: mode !== 'update' }}
                closeOnSelect
                clearable
              />
            )}
          />
        </div>
      </div>
      <div className="flex space-x-2">
        <div className="w-1/2">
          <Controller
            control={control}
            name={`serviceGrouping.serviceOrders.${index}.deliveryDate`}
            render={({ field }) => (
              <DatePicker
                value={field.value ? new Date(field.value) : ''}
                onChange={(date) => field.onChange(date)}
                // minDate={mode === 'update' ? undefined : startOfToday()}
                label="Delivery Date"
                closeOnSelect
                clearable
              />
            )}
          />
        </div>
        <div className="w-1/2">
          <Controller
            control={control}
            name={`serviceGrouping.serviceOrders.${index}.deliveryNotes`}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                type={'string'}
                label="Delivery Notes"
                error={get(errors, `serviceGrouping.serviceOrders.${index}.deliveryNotes`)}
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="flex space-x-2">
        <div className="w-1/2">
          <Controller
            control={control}
            name={`serviceGrouping.serviceOrders.${index}.removalDate`}
            render={({ field }) => (
              <DatePicker
                value={field.value ? new Date(field.value) : ''}
                onChange={(date) => field.onChange(date)}
                /* minDate={
                  watch(`serviceGrouping.serviceOrders.${index}.deliveryDate`)
                    ? // @ts-expect-error - this is a date not a string and any other value it could be would be falsey
                      new Date(watch(`serviceGrouping.serviceOrders.${index}.deliveryDate`))
                    : undefined
                } */
                label="Removal Date"
                closeOnSelect
                clearable
              />
            )}
          />
        </div>
        <div className="w-1/2">
          <Controller
            control={control}
            name={`serviceGrouping.serviceOrders.${index}.removalNotes`}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                type={'string'}
                label="Removal Notes"
                error={get(errors, `serviceGrouping.serviceOrders.${index}.removalNotes`)}
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
        </div>
      </div>
      <Controller
        control={control}
        name={`serviceGrouping.serviceOrders.${index}.vendorOnlyNotes`}
        defaultValue={''}
        render={({ field }) => (
          <TextField
            type={'string'}
            label="Vendor only Notes"
            helperText="Notes only the Hauler can see"
            error={get(errors, `serviceGrouping.serviceOrders.${index}.vendorOnlyNotes`)}
            inputProps={{
              ...field,
            }}
          />
        )}
      />
      <div className="flex space-x-2">
        <div className="w-1/2">
          <Controller
            name={`serviceGrouping.serviceOrders.${index}.serviceFrequency`}
            control={control}
            render={({ field }) => (
              <TextField
                type={'number'}
                label="Service Frequency"
                error={get(errors, `serviceGrouping.serviceOrders.${index}.serviceFrequency`)}
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
        </div>
        <div className="w-1/2">
          <Controller
            name={`serviceGrouping.serviceOrders.${index}.serviceFrequencyUnit`}
            control={control}
            render={({ field }) => (
              <Select
                error={get(errors, `serviceGrouping.serviceOrders.${index}.serviceFrequencyUnit`)}
                label={'Service Frequency Unit'}
                onSelect={field.onChange}
                defaultValue={''}
                required
                value={field.value}
              >
                {UniversalService.ServiceFrequencyUnits.map((unit: string, index: number) => (
                  <SelectOption key={'serviceFrequencyUnit' + index} value={unit}>
                    {unit}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
        </div>
      </div>
      <div className="flex space-x-2">
        <div className="w-1/2">
          <Controller
            name={`serviceGrouping.serviceOrders.${index}.serviceDays`}
            control={control}
            render={({ field }) => (
              <Select
                multiple
                error={get(errors, `serviceGrouping.serviceOrders.${index}.servicedDays`)}
                label="Serviced on which day(s) of the week"
                onSelect={field.onChange}
                defaultValue={''}
                value={field.value}
              >
                {Object.entries(DayOfWeek).map((option: [string, string], index: number) => (
                  <SelectOption key={'servicedDays' + index} value={option[0]}>
                    {option[1]}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="w-1/2">
          <Controller
            control={control}
            name={`serviceGrouping.serviceOrders.${index}.poNumber`}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                type={'string'}
                label="PO Number"
                helperText="If the customer has a Purchase Order (PO) number."
                error={get(errors, `serviceGrouping.serviceOrders.${index}.poNumber`)}
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default SubscriptionServiceOrderSubForm;
