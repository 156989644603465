import React from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { PencilIcon } from '@heroicons/react/24/solid';
import { channelTypes, customerTypes } from '../../utils/shared-types';
import CardInfoList from '../ui/CardInfoList';

export interface MarketingDetailsProps {
  lead: Customer.AllianceLeadTransport;
  onActionClick: (action: string) => void;
}

const MarketingDetails = ({ lead, onActionClick }: MarketingDetailsProps) => {
  const marketingCol = [
    { label: 'Form URL', value: lead.formUrl },
    { label: 'Referring URL', value: lead.referringUrl },
    { label: 'Channel', value: channelTypes[lead.channel as keyof typeof channelTypes] },
    { label: 'Customer Type', value: lead.customerType },
    { label: 'Search Params', value: lead.searchParams },
  ];

  return (
    <div className="bg-white rounded-md shadow-dark px-4 py-1.5 mb-5">
      <div className="flex justify-between items-center">
        <div className="text-xl">Marketing Details</div>
        <button className="btn-primary" onClick={() => onActionClick('marketing')}>
          <PencilIcon className="h-4 w-4 mr-1.5" />
          Edit
        </button>
      </div>
      <hr className="my-2 -mx-4" />
      <div className="grid grid-cols-1">
        <CardInfoList data={marketingCol} containerSize={6} />
      </div>
    </div>
  );
};

export default MarketingDetails;
