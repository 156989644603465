import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import type { Invoice, UniversalService } from '@alliance-disposal/transport-types';
import { SourFilters, SourSearch, SourSearchResponse, SourSearchWrapper } from '@wayste/sour-search';
import { formatISODateString, formatServiceAddress, getRouterPath } from '@wayste/utils';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { Alert, Drawer, Snackbar } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuButton, MenuItem } from '../../components/Menu';
import PayablesMakePayment from '../../components/PayablesMakePayment';
import PayablesReview from '../../components/PayablesReview';
import { routes } from '../../utils';
import {
  getOrderBillStatus2,
  getOrderBillStatusFromPayables,
  getOrderInvoiceStatus,
  getStatusFromReceivables,
} from '../../utils/invoice-utils';
import { GridCellStatus } from '../billing/components/renderCellReceivablesStatus';
import InvoicesDetails from './components/InvoicesDetails';
import { OrderStatusBadge } from './components/OrdersStatus/OrderStatusBadge';

export interface ExtendedServiceOrder extends UniversalService.ServiceOrder {
  receivables: Invoice.ReceivableTransport[];
  payables: Invoice.PayableTransport[];
  receivablesStatus: string;
  payablesStatus: string;
  customerName: string;
}

function ProductBillingPage() {
  const client = useWaysteClient();
  const location = useLocation();
  const history = useHistory();
  const [showInvoiceDrawer, setShowInvoiceDrawer] = useState(false);

  // SERVICE ORDERS
  const [serviceOrders, setServiceOrders] = useState<UniversalService.ServiceOrder[]>([]);

  // PAGINATION STATE
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [selectedOrderId, setSelectedOrderId] = useState<{
    serviceGroupingID: string;
    serviceOrderID: string;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // const [openBulkInvoice, setOpenBulkInvoice] = useState(false);
  // const [openInvoiceSummary, setOpenInvoiceSummary] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [snackConfig, setSnackConfig] = useState<{
    text: string;
    style: 'success' | 'error';
    open: boolean;
  }>({
    text: '',
    style: 'success',
    open: false,
  });
  const [showPayablesPay, setShowPayablesPay] = useState(false);
  const [showPayablesReview, setShowPayablesReview] = useState(false);
  const [showPayoutSummary, setShowPayoutSummary] = useState(false);

  ///////////////////////////////////////////////
  // SOUR SEARCH
  ///////////////////////////////////////////////

  // SEARCH STATE
  const [searchActive, setSearchActive] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [searchResults, setSearchResults] = useState<UniversalService.ServiceOrder[]>([]);
  const [searchResultsCount, setSearchResultsCount] = useState<number>(0);
  const defaultFilter = 'sourgum-order';
  const [isDefaultFilter, setIsDefaultFilter] = useState<boolean | undefined>(true);
  const [searchPage, setSearchPage] = useState<number>(0);
  const [searchPageSize, setSearchPageSize] = useState<number>(25);

  // NAVIGATE TO APPROPRIATE PAGE
  const onNavigate = (entity: any, recordID: string, name: string) => {
    const path = getRouterPath(name, recordID, routes);
    history.push(path);
  };

  // DEFINE ON RESULTS LOGIC
  const handleSearchResults = async (response: SourSearchResponse) => {
    setSearchLoading(true);
    if (searchPage !== response.page) setSearchPage(response.page);
    response.type === defaultFilter ? setIsDefaultFilter(true) : setIsDefaultFilter(false);
    const hits = response.results.hits.hits;
    const results: SourSearchResponse['results']['hits']['hits'][0]['_source'][] = hits.map((hit: any) => hit._source);
    const ids = results.map((result: any) => result.id);

    const processedOrders: ExtendedServiceOrder[] = [];
    const receivablesMap: Record<string, Invoice.ReceivableTransport[]> = {};

    const receivable = await client.invoice().adminPortal.receivable.query({
      orderID: ids.join(','),
    });

    // receivable.forEach((receivable: Invoice.ReceivableTransport) => {
    //   //@ts-ignore
    //   if (receivablesMap[receivable.invoiceDetails.orderID]) {
    //     //@ts-ignore
    //     receivablesMap[receivable.invoiceDetails.orderID].push(receivable);
    //   } else {
    //     //@ts-ignore
    //     receivablesMap[receivable.invoiceDetails.orderID] = [receivable];
    //   }
    // });

    const payablesMap: Record<string, Invoice.PayableTransport[]> = {};

    const Payables = await client.invoice().adminPortal.payable.query({
      serviceOrderID: ids.join(','),
    });

    // Payables.forEach((payable: Invoice.PayableTransport) => {
    //   //@ts-ignore
    //   if (payablesMap[payable.invoiceDetails.orderID]) {
    //     //@ts-ignore
    //     payablesMap[payable.invoiceDetails.orderID].push(payable);
    //   } else {
    //     //@ts-ignore
    //     payablesMap[payable.invoiceDetails.orderID] = [payable];
    //   }
    // });

    results.forEach((order: any) => {
      const orderExtended = {
        ...order,
        receivables: receivablesMap[order.id] || [],
        payables: payablesMap[order.id] || [],
      };

      processedOrders.push({
        ...order,
        receivables: receivablesMap[order.id] || [],
        payables: payablesMap[order.id] || [],
        receivablesStatus: getOrderInvoiceStatus(orderExtended),
        payablesStatus: getOrderBillStatus2(orderExtended),
      });
    });

    setSearchResultsCount(response.totalHits);
    setSearchResults(processedOrders);
    setSearchLoading(false);
  };

  const onActiveSearch = (active: boolean) => {
    setSearchActive(active);
  };

  const onActiveFilter = (active: boolean) => {
    setFilterActive(active);
  };

  const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackConfig({ open: false, text: '', style: 'success' });
  };

  const checkQueryParams = () => {
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (parsed.serviceGroupingID && parsed.serviceOrderID) {
      setSelectedOrderId({
        serviceGroupingID: parsed.serviceGroupingID.toString(),
        serviceOrderID: parsed.serviceOrderID.toString(),
      });
      setShowInvoiceDrawer(true);
    }
  };

  useEffect(() => {
    checkQueryParams();
  }, [location.search]);

  ///////////////////////////////////////////////
  // SERVICE ORDERS FETCH AND PROCESSING
  ///////////////////////////////////////////////

  const getServiceOrders = async (query: UniversalService.ServiceOrderFilterQuery) => {
    const serviceOrders = await client.universalService().serviceOrder.query(query);
    setTotalPages(serviceOrders.total);

    const processedOrders: ExtendedServiceOrder[] = [];
    const ids = serviceOrders.results.map((result: any) => result.id);
    const groupingIds = [...new Set(serviceOrders.results.map((result) => result.serviceGroupingID))];

    if (ids.length === 0 || groupingIds.length === 0) {
      setLoading(false);
      return;
    }

    const [receivables, payables, groupings] = await Promise.all([
      client.invoice().adminPortal.receivable.query({
        receivableWithLineItemWithServiceOrderID: ids.join(','),
      }),
      client.invoice().adminPortal.payable.query({
        serviceOrderID: ids.join(','),
      }),
      await client.universalService().serviceGrouping.query({
        id: groupingIds.join(','),
      }),
    ]);

    const receivablesMap: Record<string, Invoice.ReceivableTransport[]> = {};

    receivables.forEach((receivable: Invoice.ReceivableTransport) => {
      // check the serviceOrderID on the line items
      new Set(receivable?.invoiceDetails.lineItems.map((lineItem) => lineItem.serviceOrderID)).forEach(
        (serviceOrderID) => {
          if (serviceOrderID) {
            if (receivablesMap[serviceOrderID]) {
              receivablesMap[serviceOrderID].push(receivable);
            } else {
              receivablesMap[serviceOrderID] = [receivable];
            }
          }
        },
      );
    });

    // fetch associated service groupings

    // console.log('groupings', groupings);
    // const invoiceGroupingIds = await client.universalService().invoice.queryByServiceOrderId(ids);
    // console.log('invoiceGroupingIds', invoiceGroupingIds);
    const groupingsMap: Record<string, UniversalService.ServiceGrouping> = {};

    groupings.results.forEach((grouping: UniversalService.ServiceGrouping) => {
      groupingsMap[grouping.id] = grouping;
    });

    // await groupings.forEach((grouping: UniversalService.ServiceGrouping) => {

    //   const fetchReceivables = async (receivableQuery: Invoice.GetReceivablesQuery) => {
    //     try {
    //       const receivables = await client.invoice().adminPortal.receivable.query(receivableQuery);
    //       groupingsMap[grouping.id] = receivables;
    //       console.log('receivables', receivables);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };

    //   const receivableQuery: Invoice.GetReceivablesQuery = {
    //     productInvoiceGroupingID: grouping?.invoiceGroupings.map((invoiceGrouping) => invoiceGrouping.id).join(','),
    //   };

    //   console.log('receivableQuery', receivableQuery);

    //   fetchReceivables(receivableQuery);
    // });

    //console.log('groupingsMap', groupingsMap);

    // const fetchReceivables = async (receivableQuery: Invoice.GetReceivablesQuery) => {
    //   setLoading(true);
    //   try {
    //     const receivables = await client.invoice().adminPortal.receivable.query(receivableQuery);
    //     setReceivables(receivables);
    //   } catch (error) {
    //     console.error(error);
    //     showFlash('Error Fetching Receivables', 'warning');
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    // const receivable = await client.invoice().adminPortal.receivable.query({
    //   // orderID: ids.join(','),
    //   receivableWithLineItemWithServiceOrderID: ids.join(','),
    // });

    // const receivablesMap: Record<string, Invoice.ReceivableTransport[]> = {};

    // receivable.forEach((receivable: Invoice.ReceivableTransport) => {
    //   if (receivable?.invoiceDetails?.serviceOrderID) {
    //     if (receivablesMap[receivable.invoiceDetails.orderID]) {
    //       receivablesMap[receivable.invoiceDetails.orderID].push(receivable);
    //     } else {
    //       receivablesMap[receivable.invoiceDetails.orderID] = [receivable];
    //     }
    //   }
    // });

    //console.log('receivablesMap', receivablesMap);

    const payablesMap: Record<string, Invoice.PayableTransport[]> = {};

    // Process Payables

    payables.forEach((payable: Invoice.PayableTransport) => {
      if (payable?.invoiceDetails?.serviceOrderID) {
        if (payablesMap[payable?.invoiceDetails?.serviceOrderID]) {
          payablesMap[payable.invoiceDetails.serviceOrderID].push(payable);
        } else {
          payablesMap[payable.invoiceDetails.serviceOrderID] = [payable];
        }
      }
    });

    serviceOrders.results.forEach((order: any) => {
      processedOrders.push({
        ...order,
        receivables: receivablesMap[order.id] || [],
        payables: payablesMap[order.id] || [],
        receivablesStatus: getStatusFromReceivables(receivablesMap[order.id] || []),
        payablesStatus: getOrderBillStatusFromPayables(payablesMap[order.id] || []),
        customerName: groupingsMap[order.serviceGroupingID]?.customerCompanyName,
      });
    });

    console.log('======================================');
    // console.log('payablesMap', payablesMap);
    //console.log('receivablesMap', receivablesMap);
    console.log('processedOrders', processedOrders);
    console.log('======================================');

    setLoading(false);
    setServiceOrders(processedOrders);
  };

  // FETCH SERVICE ORDERS ON PAGE CHANGE
  useEffect(() => {
    setLoading(true);
    const query: UniversalService.ServiceOrderFilterQuery = {
      limit: pageSize,
      offset: page * pageSize,
    };
    getServiceOrders(query);
  }, [page, pageSize]);

  ///////////////////////////////////////////////
  // TABLE SCHEMA
  ///////////////////////////////////////////////

  const columns: GridColDef[] = [
    {
      field: 'fullOrderNumber',
      headerName: 'Order #',
      type: 'string',
      align: 'center',
      width: 100,
    },
    { field: 'qb', headerName: 'QB', width: 20, type: 'boolean', hide: true },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      renderCell: (value) => <OrderStatusBadge status={value.row.status} />,
      width: 125,
    },

    {
      field: 'receivablesStatus',
      headerName: 'Receivables',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      renderCell: (value) => <GridCellStatus value={value.row.receivablesStatus} />,
      width: 110,
    },
    {
      field: 'payablesStatus',
      headerName: 'Payables',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      renderCell: (value) => <GridCellStatus value={value.row.payablesStatus} />,
      width: 110,
    },
    {
      field: 'customerName',
      headerName: 'Customer',
      type: 'string',
      width: 300,
    },
    {
      field: 'serviceLocation',
      headerName: 'Address',
      type: 'string',
      width: 300,
      renderCell: (value) => <>{formatServiceAddress(value.row.serviceLocation?.address)}</>,
    },
    {
      field: 'vendorName',
      headerName: 'Vendor',
      type: 'string',
      width: 200,
    },

    {
      field: 'servicePeriod',
      headerName: 'Service Period',
      type: 'date',
      align: 'center',
      headerAlign: 'center',
      renderCell: (value) => (
        <>
          {formatISODateString(value.row.startDate)} - {formatISODateString(value.row.endDate)}
        </>
      ),
      width: 150,
    },
    {
      field: 'sku',
      headerName: 'SKUS',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      renderCell: (value) => (
        <>
          {value.row.serviceType.family.shortCode} - {value.row.serviceType.name}
        </>
      ),

      width: 150,
    },
  ];

  ///////////////////////////////////////////////
  // RENDER
  ///////////////////////////////////////////////

  return (
    <div className="bg-white shadow-dark rounded m-2 flex-1 flex">
      <PayablesReview open={showPayablesReview} onClose={() => setShowPayablesReview(false)} />
      <PayablesMakePayment open={showPayablesPay} onClose={() => setShowPayablesPay(false)} />
      <div className="container mx-auto p-2 flex-1">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: 0,
            padding: 0,
            alignItems: 'end',
          }}
        >
          <h1 className="text-2xl">Billing Product</h1>
          <MenuButton className="btn-icon !p-0 h-6 w-6" buttonItem={<EllipsisHorizontalIcon className="h-6 w-6" />}>
            <MenuItem onClick={() => setShowPayablesReview(true)}>Review All Bills</MenuItem>
            <MenuItem onClick={() => setShowPayablesPay(true)}>Pay All Bills</MenuItem>
          </MenuButton>
        </div>
        <br />
        <div className="py-2 w-full flex flex-row justify-between item-center">
          <SourSearchWrapper
            options={{
              application: 'aap',
              apiKey: import.meta.env.VITE_ELASTIC_KEY as string,
              environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
            }}
            onNavigate={onNavigate}
            onResults={handleSearchResults}
            onSearch={onActiveSearch}
            onFilter={onActiveFilter}
            defaultFilters={{
              query: {
                type: undefined,
              },
            }}
            size={searchPageSize}
            page={searchPage}
            createQueryParams={{ method: 'with_filter', removeOn: 'empty_string_inactive_filter' }}
          >
            <div className="flex flex-row justify-between space-x-4 w-full">
              <SourSearch
                options={{
                  searchPopoverFixed: false,
                  showTips: !isDefaultFilter,
                  showMessages: !isDefaultFilter,
                  showResults: !isDefaultFilter,
                  placeholder: 'Search Universal Service Orders',
                }}
              />
              <SourFilters />
            </div>
          </SourSearchWrapper>
        </div>
        <div className="w-full h-[85%]">
          <DataGrid
            //rows={searchActive || filterActive ? searchResults : serviceOrders}
            rows={serviceOrders}
            //rowCount={searchActive ? searchResultsCount : invoicesCount}
            rowCount={totalPages}
            rowHeight={30}
            columns={columns}
            pageSize={searchActive || filterActive ? searchPageSize : pageSize}
            loading={searchActive || filterActive ? searchLoading : loading}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
              setSearchPageSize(newPageSize);
            }}
            onPageChange={(newPage) => {
              setPage(newPage);
              setSearchPage(newPage);
            }}
            disableSelectionOnClick
            disableColumnSelector
            rowsPerPageOptions={[25, 50, 100]}
            onCellClick={(p, e) => {
              if (p.field === 'status') {
                e.stopPropagation();
                return;
              }

              setSelectedOrderId({
                serviceGroupingID: p.row.serviceGroupingID,
                serviceOrderID: p.row.id,
              });

              history.push({
                pathname: routes.billingProduct.list,
                search: qs.stringify({
                  serviceGroupingID: p.row.serviceGroupingID,
                  serviceOrderID: p.row.id,
                }),
              });

              setShowInvoiceDrawer(true);
            }}
            page={searchActive || filterActive ? searchPage : page}
            pagination
            paginationMode={'server'}
          />
        </div>
      </div>
      <Drawer
        sx={{
          width: '90%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '90%',
            boxSizing: 'border-box',
          },
          zIndex: 40,
        }}
        variant="temporary"
        open={showInvoiceDrawer}
        anchor="right"
        onClose={() => {
          setShowInvoiceDrawer(!showInvoiceDrawer);
          history.push({ pathname: routes.billingProduct.list });
        }}
        disableEnforceFocus
      >
        <InvoicesDetails
          serviceGroupingID={selectedOrderId?.serviceGroupingID || ''}
          serviceOrderID={selectedOrderId?.serviceOrderID || ''}
        />
      </Drawer>
      <Snackbar open={snackConfig.open} autoHideDuration={3000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={snackConfig.style} sx={{ width: '100%' }}>
          {snackConfig.text}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ProductBillingPage;
