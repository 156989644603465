import { ReactNode } from 'react';
import { Chip, ChipProps } from '@wayste/sour-ui';
import { Button } from '@wayste/sour-ui';

type DetailsCardProps = {
  heading: string | ReactNode;
  subheading?: string;
  subheadingStatus?: string;
  chips?: ChipProps[];
  buttons?: DetailsCardWrapperButtonProps[];
  children?: React.ReactNode | React.ReactNode[];
};

type DetailsCardWrapperButtonProps = {
  label?: ReactNode;
  delete?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  hide?: boolean;
  badgeAmount?: number;
  props?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  customButton?: React.ReactNode;
};

const DetailsCardWrapper = ({ heading, subheading, subheadingStatus, buttons, chips, children }: DetailsCardProps) => {
  return (
    <div className="p-5 mb-3 bg-white shadow-dark rounded-md overflow-auto">
      <div className="flex justify-between">
        <div className="flex">
          <div className="text-xl items-center inline-flex">{heading}</div>
          <div className="text-sm text-slate-400 items-center inline-flex mx-4">{subheading}</div>
          <div className="text-l items-center inline-flex">{subheadingStatus}</div>
          <div className="ml-2 items-center inline-flex ">
            {chips
              ? chips.map((chipProps: ChipProps, index: number) => (
                  <Chip
                    key={'chip' + index + chipProps.label}
                    label={chipProps.label}
                    primaryColor={chipProps.primaryColor}
                    textColor={chipProps.textColor}
                    outlined={chipProps.outlined}
                    size={chipProps.size}
                  />
                ))
              : null}
          </div>
        </div>
        <div className="flex flex-row-reverse float-right">
          {buttons &&
            buttons.map((props: DetailsCardWrapperButtonProps, index: number) => {
              if (props.hide) return;
              if (Boolean(props.customButton)) {
                return props.customButton;
              }
              if (props.badgeAmount) {
                return (
                  <div key={'button' + index} className="relative flex">
                    <span className="inline-flex items-center justify-center p-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full absolute z-1 -top-3 left-0.5 h-6 w-6">
                      {props.badgeAmount}
                    </span>
                    <Button
                      {...props.props}
                      onClick={props.onClick}
                      disabled={props.disabled}
                      className={`${index > 0 ? 'mx-3' : 'ml-3'} ${
                        props.delete ? 'btn-delete' : index === 0 ? 'btn-primary' : 'btn-dark-grey-outlined'
                      } ${props?.props?.className || ''}`}
                    >
                      <div className="flex items-center">{props.label}</div>
                    </Button>
                  </div>
                );
              }
              return (
                <Button
                  {...props.props}
                  key={'button' + index}
                  onClick={props.onClick}
                  disabled={props.disabled}
                  className={`${index > 0 ? 'mx-3' : 'ml-3'} ${
                    props.delete ? 'btn-delete' : index === 0 ? 'btn-primary' : 'btn-dark-grey-outlined'
                  } ${props?.props?.className || ''}`}
                >
                  <div className="flex items-center">{props.label}</div>
                </Button>
              );
            })}
        </div>
      </div>
      {children ? <div className="bg-[#D8D8D8] h-px my-4 -mx-5 w-[calc(100% + 40px)]" /> : null}
      {children}
    </div>
  );
};

export default DetailsCardWrapper;
