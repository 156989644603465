import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Pricing } from '@alliance-disposal/transport-types';
import { UIContext } from '../../contexts';
import { RollOffPricingFormProps } from '../HaulerPricingForm/HaulerPricingForm';
import HaulerPricingCreate from './HaulerPricingCreate';

export enum DaysEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  LIMITED = 'LIMITED',
  UNKNOWN = 'UNKNOWN',
}

export interface HaulerPricingCreateContainerProps {
  open: boolean;
  onBackButtonClick: (data: { status: string | number }) => void;
  hauler: Hauler.HaulerWithAapTransport;
  haulerPricingZones: Pricing.PricingTransport[];
  mode: 'Rolloff' | 'Universal';
}

export interface CreateUniversalPricingFormProps {
  state: Pricing.UsStateAbbreviation;
  zoneName: string;
  tripCharge: number;
  tripChargeDollars: number;
  notes: string;
  haulerID: string;
  haulerName: string;
}

const HaulerPricingCreateContainer = ({
  open,
  onBackButtonClick,
  hauler,
  mode,
  haulerPricingZones,
}: HaulerPricingCreateContainerProps) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  // STATE
  const [isLoading, setIsLoading] = useState(false);

  ////////////////////////////////////////////////////////
  // FUNCTIONS
  ///////////////////////////////////////////////////////

  // HANDLE COPY PRICING FROM EXISTING ZONE
  const handleCopyPricing = async (newZoneID: string, zoneToCopyID: string) => {
    const existingZone = haulerPricingZones?.find((zone) => zone.id === zoneToCopyID);

    const payload: (Pricing.SGMutateMaterial & { expirationDate?: string | null | undefined })[] | undefined =
      existingZone?.pricingData.map((material) => {
        return {
          expirationDate: undefined,
          effectiveDate: new Date().toISOString(),
          details: {
            allowOnlineCheckout: material.allowOnlineCheckout,
            allowForLowerTonLimit: material.allowForLowerTonLimit,
            doesNotService: material.doesNotService,
            lastUpdatedAt: new Date().toISOString(),
            haulerApproved: undefined,
            sourgumApproved: true,
            effectiveDate: undefined,
            expirationDate: undefined,
            material: material.material,
            sizes: material.sizes,
            tax: material.tax,
            type: material.type,
          },
        };
      });

    if (!payload) {
      return;
    }

    try {
      await client.pricing().adminPortal.material.createMany(newZoneID, payload || []);
    } catch (error) {
      console.log('error', error);
      showFlash('Error Copying Material Pricing', 'warning');
    }
  };

  // CREATE PRICING FOR ROLLOFF SERVICE
  const handleSubmitRollOf = async (data: RollOffPricingFormProps) => {
    setIsLoading(true);

    const payload: Pricing.PricingCreateTransport = {
      ...data,
      pricingData: [],
      prohibitedItems: [],
      days: {
        mon: DaysEnum.UNKNOWN,
        tue: DaysEnum.UNKNOWN,
        wed: DaysEnum.UNKNOWN,
        thu: DaysEnum.UNKNOWN,
        fri: DaysEnum.UNKNOWN,
        sat: DaysEnum.UNKNOWN,
        sun: DaysEnum.UNKNOWN,
      },
      public: false,
    };

    let newPricingZone;
    try {
      newPricingZone = await client.pricing().adminPortal.pricing.create(payload);
      showFlash('Pricing Successfully Created', 'success');
    } catch (error) {
      showFlash('An Error Occurred', 'warning');
    }

    if (newPricingZone && data.copyExistingZone) {
      await handleCopyPricing(newPricingZone.id, data.copyExistingZone);
    }
    onBackButtonClick({ status: 'success' });
    setIsLoading(false);
  };

  // CREATE PRICING FOR UNIVERSAL SERVICE
  const handleSubmitUniversal = async (data: CreateUniversalPricingFormProps) => {
    setIsLoading(true);

    const payload: Pricing.ProductPricingZoneCreateTransport = {
      vendorID: data.haulerID,
      vendorName: data.haulerName,
      state: data.state,
      notes: data.notes,
      zoneName: data.zoneName,
      type: 'SOURGUM_INTERNAL',
      skuIDs: [],
    };

    try {
      await client.universalService().pricingZone.create(payload);
      showFlash('Pricing Successfully Created', 'success');
      onBackButtonClick({ status: 200 });
    } catch (error) {
      showFlash('An Error Occurred', 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  ////////////////////////////////////////
  // RENDER SECTION
  ////////////////////////////////////////

  return (
    <>
      <HaulerPricingCreate
        haulerPricingZones={haulerPricingZones}
        onBackButtonClick={onBackButtonClick}
        mode={mode}
        open={open}
        onSubmit={mode == 'Rolloff' ? handleSubmitRollOf : handleSubmitUniversal}
        isLoading={isLoading}
        hauler={hauler}
      />
    </>
  );
};

export default HaulerPricingCreateContainer;
