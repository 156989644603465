import React from 'react';
import type { Customer, Pricing } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import { materials } from '../../../../utils';

export type DeleteMaterialProps = {
  material: string;
  customerDiscount: Pricing.CustomerDiscountTransport;
  customer?: Customer.AllianceCustomerTransport;
};

const DeleteCustomMaterialPricingModal = ({
  handleClose,
  handleDeleteMaterial,
  material,
  customerDiscount,
  customer,
}: {
  handleClose: () => void;
  handleDeleteMaterial: (args: { material: string; customerDiscountID: string }) => Promise<void>;
  material?: string;
  customerDiscount?: Pricing.CustomerDiscountTransport;
  customer?: Customer.AllianceCustomerTransport;
}) => {
  const onConfirmDelete = () => {
    if (!material) return;
    handleDeleteMaterial({
      material: material,
      customerDiscountID: customerDiscount?.id || '',
    });
    handleClose();
  };

  const RenderDialogContent = () => {
    if (!material) return null;
    return (
      <div>
        <span>
          Are you sure you want to delete the custom{' '}
          <span style={{ fontWeight: 'bold' }}>{materials[material as keyof typeof materials]}</span> pricing in the{' '}
          <span style={{ fontWeight: 'bold' }}>{customerDiscount?.pricingZone?.zoneName} </span>
          {customer && (
            <>
              custom pricing zone for{' '}
              <span style={{ fontWeight: 'bold' }}>
                {customer?.contacts[0].firstName + ' ' + customer?.contacts[0].lastName}
              </span>
            </>
          )}
          ?
        </span>
      </div>
    );
  };
  return (
    <Dialog open={Boolean(material)} onClose={handleClose} showX variant="delete" styledTitle="Delete Material">
      <RenderDialogContent />
      <div className="flex justify-end gap-4 mt-4">
        <button className="btn-dark-grey-outlined" onClick={handleClose}>
          Cancel
        </button>
        <button onClick={onConfirmDelete} className="btn-delete">
          Delete Pricing
        </button>
      </div>
    </Dialog>
  );
};

export default DeleteCustomMaterialPricingModal;
