import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { buildAddressString, formatE164ToUSPhoneNumber, formatUSD } from '@wayste/utils';
import { PencilIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { IconButton } from '@mui/material';
import { format } from 'date-fns';
import { UIContext } from '../../contexts';
import {
  mediumTypesEnums,
  residentialBillingFrequencies,
  residentialMaterials,
  residentialServiceFrequency,
  salesEmail,
} from '../../utils/shared-types';
import Dialog from '../Dialog';
import { LeadRSForms } from '../LeadForms';
import CardInfoList from '../ui/CardInfoList';
import ContactedDetails from './ContactedDetails';
import EmailSendDialog from './EmailSendDialog';
import InternalNotes from './InternalNotes';
import MarketingDetails from './MarketingDetails';

const quoteHeadings = ['Size', 'Material', 'Service Frequency', 'Quantity', 'Billing Frequency', 'Price Quoted', ''];

export interface LeadRSDetailsProps {
  rawLead: Customer.AllianceLeadTransport;
}
const LeadRSDetails = ({ rawLead }: LeadRSDetailsProps) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);

  // STATE
  const [lead, setLead] = useState<Customer.AllianceLeadTransport>(rawLead);
  const [updateForm, setUpdateForm] = useState<any>(null);
  const [sendingRejection, setSendingRejection] = useState<boolean>(false);
  const [quoteSend, setQuoteSend] = useState<any>({
    open: false,
    to: '',
    from: salesEmail,
    subject: 'Sourgum Waste Quote Request',
    message: '',
  });

  //////////////////////////////////////////////
  // HOOKS SECTION
  //////////////////////////////////////////////

  useEffect(() => {
    if (!rawLead) return;
    setLead(rawLead);
  }, [rawLead]);

  //////////////////////////////////////////////
  // FUNCTION SECTION
  //////////////////////////////////////////////

  const handleUpdateSubmit = async (leadObj: any) => {
    const form = updateForm;

    setUpdateForm(null);
    switch (form) {
      case 'quote':
        for (const quote of leadObj.quotes) {
          if (!quote.id) {
            try {
              await client.customer().adminPortal.leads.quote.create(lead.id, quote);
              showFlash('Lead Successfully Updated', 'success');
            } catch (error) {
              showFlash('Error Updating Lead', 'warning');
            }
          }
        }
        break;
      case 'quote-hauler':
        for (const haulerQuote of leadObj.haulerQuotes) {
          if (!haulerQuote.id) {
            try {
              await client.customer().adminPortal.leads.haulerQuote.create(lead.id, haulerQuote);
              showFlash('Lead Successfully Updated', 'success');
            } catch (error) {
              showFlash('Error Updating Lead', 'warning');
            }
          }
        }
        break;
      case 'contacted':
        for (const event of leadObj.contactEvents) {
          if (!event.id) {
            try {
              await client.customer().adminPortal.leads.contactEvent.create(lead.id, event);
              showFlash('Event Successfully Updated', 'success');
            } catch (error) {
              showFlash('Error Updating Event', 'warning');
            }
          }
        }
        break;
      default:
        try {
          await client.customer().adminPortal.leads.update(lead.id, leadObj);
          showFlash('Lead Successfully Updated', 'success');
        } catch (error) {
          showFlash('Error Updating Lead', 'warning');
        }

        break;
    }

    // onSubmit(leadObj);
  };

  const handleRemoveQuote = async (quoteID: string) => {
    try {
      await client.customer().adminPortal.leads.quote.delete(lead.id, quoteID);
      showFlash('Quote Successfully Deleted', 'success');
    } catch (error) {
      console.error(error);
      showFlash('Error Deleting Quote', 'warning');
    }
  };

  const handleRemoveHaulerQuote = async (quoteID: string) => {
    try {
      await client.customer().adminPortal.leads.haulerQuote.delete(lead.id, quoteID);
      showFlash('Quote Successfully Deleted', 'success');
    } catch (error) {
      console.error(error);
      showFlash('Error Deleting Quote', 'warning');
    }
  };

  const handleEmailSent = async () => {
    handleSendQuoteClose();
    setUpdateForm(null);

    if (sendingRejection) {
      try {
        await client.customer().adminPortal.leads.update(lead.id, {
          status: 'lost',
          reasonLost: 'outOfArea',
          reasonLostNote: '',
        });

        showFlash('Lead Successfully Updated', 'success');
      } catch (error) {
        showFlash('Error Updating Lead', 'warning');
      }
    }
    const event = {
      date: new Date().toISOString(),
      medium: mediumTypesEnums.email,
    };

    try {
      await client.customer().adminPortal.leads.contactEvent.create(lead.id, event);
      showFlash('Lead Successfully Updated', 'success');
    } catch (error) {
      showFlash('Error Updating Lead', 'warning');
    }

    setSendingRejection(false);
  };

  const handleSendQuoteClose = () => {
    setQuoteSend({
      open: false,
      to: '',
      from: salesEmail,
      subject: 'Sourgum Waste Quote Request',
      message: '',
    });
  };

  const handleSendDoNotService = () => {
    const areaText = lead?.serviceLocation?.address.city
      ? `${lead.serviceLocation.address.city}${
          lead.serviceLocation.address.state ? `, ${lead.serviceLocation.address.state}` : ''
        }`
      : 'your area';
    const data = {
      emailTemplate: 'DO_NOT_SERVICE_AREA',
      first_name: lead.firstName,
      toEmail: lead.email,
      service_location: areaText,
      service_type: 'residential recurring pickup service',
    };
    setQuoteSend({
      ...quoteSend,
      open: true,
      to: lead.email || '',
      data,
      preText: (
        <span>
          You can enter a custom message below. It is optional to add, you don't need to add one. If you enter a custom
          message it will be inserted as below.
          <br />
          <br />
          Hi {data.first_name},<br />
          Unfortunately, we do not currently service {data.service_location} with {data.service_type}, but it is
          definitely in our growth road map!
          <br />
          <br />
          ENTER CUSTOM MESSAGE HERE.
          <br />
          <br />
          If you want to be the first to know when we launch in your area, make sure to sign up to our (short and sweet)
          newsletters.
          <br />
          <br />
          ...more stuff.
        </span>
      ),
    });
  };

  const onDoNotServiceArea = () => {
    setSendingRejection(true);
    handleSendDoNotService();
  };

  //////////////////////////////////////////////
  // RENDER SECTION
  //////////////////////////////////////////////

  if (!lead) return <div>Lead does not exist</div>;

  const leadColOne = [
    { label: 'Name', value: `${lead.firstName} ${lead.lastName}` },
    { label: 'Company', value: lead.company },
    { label: 'Email', value: lead.email },
    {
      label: 'Phone',
      value: lead.phoneNumber ? formatE164ToUSPhoneNumber(lead.phoneNumber) : null,
    },
  ];

  const leadColTwo = [
    {
      label: 'Location',
      value: lead?.serviceLocation?.address ? buildAddressString(lead?.serviceLocation?.address) : null,
    },
    { label: 'County', value: lead?.serviceLocation?.county },
    { label: 'Current Hauler', value: lead.currentHauler || '' },
  ];

  const leadColThree = [
    {
      label: 'Start Date',
      value: lead.requestedStartDate ? format(new Date(lead.requestedStartDate), 'MM/dd/yy') : '',
    },
    { label: 'Cus Notes', value: lead.customerNotes },
  ];

  return (
    <div className="w-full">
      <div className="bg-white rounded shadow-dark px-4 py-1.5 mb-5">
        <div className="flex justify-between items-center">
          <h6 className="font-medium text-xl">Lead Details</h6>
          <button className="btn-primary" onClick={() => setUpdateForm('lead')}>
            <PencilIcon className="h-4 w-4 mr-1" />
            Edit
          </button>
        </div>
        <hr className="my-2 -mx-4" />
        <div className={`gap-4 grid grid-cols-1 lg:grid-cols-3`}>
          <CardInfoList data={leadColOne} border />
          <CardInfoList data={leadColTwo} border />
          <CardInfoList data={leadColThree} />
          <div className="border-t flex lg:col-span-3 pt-4">
            <div className="text-gray-500 mr-5">Notes</div>
            <div>
              <InternalNotes notesInternal={lead.notesInternal} collection={'leads'} docId={lead.id} />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white rounded shadow-dark py-1.5 mb-5">
        <div className="px-4">
          <div className="flex justify-between items-center">
            <h6 className="font-medium text-xl">Quote Details for Customer</h6>
            <button className="ml-auto mr-5 btn-dark-grey-outlined" onClick={() => onDoNotServiceArea()}>
              Don't Service Area
            </button>
            <button className="btn-primary" onClick={() => setUpdateForm('quote')}>
              <PlusIcon className="h-5 w-5 mr-1" />
              Add
            </button>
          </div>
        </div>
        <hr className="mt-2" />
        <div className="w-full overflow-x-auto -mb-1.5">
          <table className="w-full border-collapse border-spacing-0 text-sm">
            <thead>
              <tr className="[&>*]:py-1.5 [&>*]:px-4 [&>*]:whitespace-nowrap [&>*]:font-normal bg-gray-100 border-b">
                {quoteHeadings.map((item) => (
                  <th key={item}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {lead.quotes.map((item: any) => (
                <tr key={item.id} className={`[&>*]:py-1.5 [&>*]:px-4 border-b ${item.active ? '' : 'bg-disabled'}`}>
                  <td>{`${item.size?.size} GL`}</td>
                  <td>{residentialMaterials[item.material as keyof typeof residentialMaterials]}</td>
                  <td>{residentialServiceFrequency[item.frequency as keyof typeof residentialServiceFrequency]}</td>
                  <td>{item.quantity}</td>
                  <td>
                    {residentialBillingFrequencies[item.billingFrequency as keyof typeof residentialBillingFrequencies]}
                  </td>
                  <td>{formatUSD(item.priceDollars)}</td>
                  <td>
                    {item.active ? (
                      <button type="button" className="btn-icon" onClick={() => handleRemoveQuote(item.id)}>
                        <XMarkIcon className="h-6 w-6 text-delete" />
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
              <tr className="[&>*]:py-1.5 [&>*]:px-4">
                <td className="text-right" colSpan={5}>
                  <b>Total</b>
                </td>
                <td>{formatUSD(lead.quotes.reduce((a: number, b: any) => a + (b.priceDollars || 0), 0))}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="bg-white rounded shadow-dark py-1.5 mb-5">
        <div className="px-4">
          <div className="flex justify-between items-center">
            <h6 className="font-medium text-xl">Bid Details from Hauler</h6>
            <button className="btn-primary" onClick={() => setUpdateForm('quote-hauler')}>
              <PlusIcon className="h-5 w-5 mr-1" />
              Add
            </button>
          </div>
        </div>
        <hr className="mt-2" />
        <div className="w-full overflow-x-auto -mb-1.5">
          <table className="w-full border-collapse border-spacing-0 text-sm">
            <thead>
              <tr className="[&>*]:py-1.5 [&>*]:px-4 [&>*]:whitespace-nowrap bg-gray-100 border-b font-normal">
                <td>Hauler</td>
                {quoteHeadings.map((item) => (
                  <td key={item}>{item}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {lead.haulerQuotes.map((item: any) => (
                <tr key={item.id} className={`[&>*]:py-1.5 [&>*]:px-4 border-b ${item.active ? '' : 'bg-disabled'}`}>
                  <td>{item.hauler}</td>
                  <td>{`${item.size?.size} GL`}</td>
                  <td>{residentialMaterials[item.material as keyof typeof residentialMaterials]}</td>
                  <td>{residentialServiceFrequency[item.frequency as keyof typeof residentialServiceFrequency]}</td>
                  <td>{item.quantity}</td>
                  <td>
                    {residentialBillingFrequencies[item.billingFrequency as keyof typeof residentialBillingFrequencies]}
                  </td>
                  <td>{formatUSD(item.priceDollars)}</td>
                  <td>
                    {item.active ? (
                      <IconButton onClick={() => handleRemoveHaulerQuote(item.id)}>
                        <XMarkIcon className="w-6 h-6 text-delete" />
                      </IconButton>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ContactedDetails onActionClick={setUpdateForm} lead={lead} />
      <MarketingDetails onActionClick={setUpdateForm} lead={lead} />
      <Dialog
        open={Boolean(updateForm)}
        className="max-w-6xl"
        styledTitle="Update Lead"
        onClose={() => setUpdateForm(null)}
      >
        {updateForm ? (
          <LeadRSForms
            onCancel={() => setUpdateForm(null)}
            onSubmit={handleUpdateSubmit}
            lead={lead}
            form={updateForm}
          />
        ) : null}
      </Dialog>
      {/* <EmailSendDialog
        open={quoteSend.open}
        from={quoteSend.from}
        to={quoteSend.to}
        subject={quoteSend.subject}
        message={quoteSend.message}
        onClose={handleSendQuoteClose}
        onEmailSent={handleEmailSent}
      /> */}
      <EmailSendDialog
        open={quoteSend.open}
        message={quoteSend.message}
        onClose={handleSendQuoteClose}
        onEmailSent={handleEmailSent}
        preText={quoteSend.preText}
        emailData={quoteSend.data}
      />
    </div>
  );
};

export default LeadRSDetails;
