import React, { useEffect, useState } from 'react';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { formatUSD, moneyFormatter, round } from '@wayste/utils';
import { paymentMethods, paymentTerms } from '../../utils';
import CardInfoList from '../ui/CardInfoList';

const calcOrderTotal = (receivables?: Invoice.ReceivableTransport[]) => {
  let total = 0;
  receivables?.forEach((invoice: Invoice.ReceivableTransport) => {
    if (!invoice.invoiceDetails.void) {
      total += invoice.invoiceDetails.total;
    }
  });
  return round(total / 100);
};

const calcRemainingBalance = (receivables?: Invoice.ReceivableTransport[]) => {
  let total = 0;
  receivables?.forEach((invoice: Invoice.ReceivableTransport) => {
    if (!invoice.invoiceDetails.void) {
      total += invoice.invoiceDetails.remainingBalance;
    }
  });
  return round(total / 100);
};

const calcPaidBalance = (receivables?: Invoice.ReceivableTransport[]) => {
  let total = 0;
  receivables?.forEach((invoice: Invoice.ReceivableTransport) => {
    if (!invoice.invoiceDetails.void) {
      total += invoice.invoiceDetails.total - invoice.invoiceDetails.remainingBalance;
    }
  });
  return round(total / 100);
};

const payablesTotal = (payables?: Invoice.PayableTransport[]) => {
  return payables?.reduce((total, invoice) => {
    if (!invoice.invoiceDetails.void) {
      total += invoice.invoiceDetails.total;
    }
    return total;
  }, 0);
};

const getHaulDumpCosts = (order: Order.AllianceOrderTransport, payables?: Invoice.PayableTransport[]) => {
  let results = {
    haul: '',
    dump: '',
    perTon: '',
  };
  if (payables && payables?.length > 0) {
    let haul: any = null;
    let dump: any = null;
    payables.forEach((bill) => {
      if (bill.invoiceDetails.lineItems) {
        bill.invoiceDetails.lineItems.forEach((item) => {
          if (item.itemName?.toLowerCase() === 'haul') {
            haul += item.totalPriceDollars ?? 0;
          }
          if (item.itemName?.toLowerCase() === 'dump') {
            dump += item.totalPriceDollars ?? 0;
          }
        });
      }
    });
    results = {
      haul: haul !== null ? formatUSD(haul) : '',
      dump: dump !== null ? formatUSD(dump) : '',
      perTon:
        dump !== null && order?.actualWeightDumped?.value
          ? `${formatUSD(dump / order?.actualWeightDumped.value)} / ton`
          : '',
    };
  }
  return results;
};

/**
 * Displays bulk of Billing data in lists using CardInfoList
 * @param {*} order Order schema object
 * @param {Boolean} break3 Moves the 3rd column below the first 2
 */

export interface OrderBillingDetailsListsProps {
  order: Order.AllianceOrderTransport;
  receivables: Invoice.ReceivableTransport[];
  payables: Invoice.PayableTransport[];
  break3: boolean;
}
const OrderBillingDetailsLists = ({ order, receivables, payables, break3 }: OrderBillingDetailsListsProps) => {
  const [orderQuoted, setOrderQuoted] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderPaid, setOrderPaid] = useState(0);
  const [discountLineItems, setDiscountLineItems] = useState<Invoice.LineItemTransport[]>([]);

  useEffect(() => {
    if (!order || !receivables) {
      return;
    }
    console.log('order', order);
    console.log('receivables', receivables);
    console.log('payables', payables);
    console.log('order snapshot', order.haulerPricingSnapshot);
  }, [order, receivables, payables]);

  useEffect(() => {
    if (!order || !receivables) {
      return;
    }

    const paid = 0;

    const quoteInvoice = receivables.find((invoice) =>
      invoice.invoiceDetails.lineItems.find((lineItem) => lineItem.itemName === 'QP-Haul'),
    );

    if (!quoteInvoice) {
      console.error('No quote invoice found');
      return;
    }

    setDiscountLineItems(
      quoteInvoice.invoiceDetails.lineItems.filter((lineItem: any) => lineItem?.itemName === 'Discount') || [],
    );

    setDiscountLineItems(
      quoteInvoice.invoiceDetails.lineItems.filter((lineItem: any) => lineItem?.unitPriceDollars < 0) || [],
    );

    receivables.forEach((invoice) => {
      let paid = 0;
      if (!invoice.invoiceDetails.void) {
        console.log('invoice', invoice.invoiceDetails.totalDollars);
        paid = paid + round(paid + invoice.invoiceDetails.totalDollars);
      }
      console.log('paid', paid);
    });

    setOrderTotal(calcOrderTotal(receivables));
    setOrderPaid(paid);
    setOrderQuoted(quoteInvoice.invoiceDetails.totalDollars);
  }, [order, receivables]);

  const colOne = [
    {
      label: 'Pricing Type',
      value: <span style={{ textTransform: 'capitalize' }}>{order.priceType}</span>,
    },
    { label: 'Price Quoted', value: `$${orderQuoted.toFixed(2)}` },
    { label: 'Order Total', value: `$${orderTotal.toFixed(2)}` },
    { label: 'Paid', value: `$${calcPaidBalance(receivables).toFixed(2)}` },
    { label: 'Due', value: `$${calcRemainingBalance(receivables).toFixed(2)}` },
  ];

  discountLineItems.forEach((item) => {
    colOne.splice(2, 0, {
      label: item.itemName,
      value: `$(${Math.ceil(-(item?.totalPriceDollars ?? 0)).toFixed(2)})`,
    });
  });

  const colTwo = [
    { label: 'Payment Terms', value: paymentTerms[order.paymentTerm as keyof typeof paymentTerms] },
    { label: 'Payment Method', value: paymentMethods[order.paymentMethod as keyof typeof paymentMethods] },
    {
      label: 'Expected Haul Rate',
      value: moneyFormatter(order.haulerPricingSnapshot?.haul),
    },
    {
      label: 'Expected Dump Rate',
      value: moneyFormatter(order.haulerPricingSnapshot?.dump),
    },
  ];

  const colThree = [
    { label: 'Actual Haul Total', value: getHaulDumpCosts(order, payables).haul },
    { label: 'Actual Dump Total', value: getHaulDumpCosts(order, payables).dump },
    { label: 'Actual Cost / Ton', value: getHaulDumpCosts(order, payables).perTon },
    { label: 'Actual Payables Total', value: moneyFormatter(payablesTotal(payables)) },
  ];

  return (
    <div className={`grid grid-cols-1 gap-4 ${break3 ? 'lg:grid-cols-2' : 'lg:grid-cols-3'}`}>
      <CardInfoList data={colOne} border />
      <CardInfoList data={colTwo} border={!break3} />
      {break3 ? (
        <div className="lg:col-span-2">
          <hr />
        </div>
      ) : null}

      <CardInfoList data={colThree} />
    </div>
  );
};

export default OrderBillingDetailsLists;
