//////////////////////////////////////////////
// MANAGE FILE UPLOAD, DELETE, VIEW
//////////////////////////////////////////////
import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { S3ItemReference } from '@alliance-disposal/transport-types';
import { FileDropZone } from '@wayste/sour-components';
import { Dialog, Loading, Select, SelectOption, TextField } from '@wayste/sour-ui';
import {
  ArrowDownTrayIcon,
  ArrowPathRoundedSquareIcon,
  PencilIcon,
  PhotoIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useFlash } from '../../hooks/useFlash';

// BUCKET CONFIGS
const s3Region = import.meta.env.VITE_AWS_USER_FILES_S3_BUCKET_REGION;
const s3Bucket = import.meta.env.VITE_AWS_USER_FILES_S3_BUCKET;

type CustomerFileHandlerProps = {
  files: S3ItemReference[];
  folderName: string;
  types: Record<string, string>;
  inline?: boolean;
  open: boolean;
  showUploadDropZones?: boolean;
  onCancel: () => void;
  onFileUpload: (file: S3ItemReference) => Promise<void>;
  onFilesEdit: (files: S3ItemReference[]) => Promise<void>;
};

interface S3ItemReferenceWithFile extends S3ItemReference {
  file?: File;
  href?: string;
}
const FileHandler = ({
  files,
  folderName,
  types,
  inline,
  open,
  showUploadDropZones,
  onCancel,
  onFileUpload,
  onFilesEdit,
}: CustomerFileHandlerProps) => {
  const client = useWaysteClient();
  const { showFlash } = useFlash();

  //////////////////////////////////////////////
  // STATE
  //////////////////////////////////////////////
  const [isLoading, setIsLoading] = useState(false);
  const [allFiles, setAllFiles] = useState<S3ItemReferenceWithFile[]>([]);
  const [zoomFile, setZoomFile] = useState<string | null>(null);
  const [rotate, setRotate] = useState<{
    fileName: string | null;
    deg: number;
  }>({ fileName: null, deg: 0 });
  const [showEditFileNote, setShowEditFileNote] = useState(false);
  const [editFile, setEditFile] = useState<S3ItemReferenceWithFile | null>(null);
  const [editNote, setEditNote] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [fileType, setFileType] = useState<keyof typeof types>('');

  //////////////////////////////////////////////
  // HOOKS SECTION
  //////////////////////////////////////////////

  useEffect(() => {
    getFiles();
  }, [files]);

  // useEffect(() => {
  //   console.log(allFiles);
  // }, [allFiles]);

  //////////////////////////////////////////////
  // FUNCTIONS SECTION
  //////////////////////////////////////////////

  const getFiles = async () => {
    setIsLoading(true);
    try {
      if (!files || files.length === 0) return;
      const filesPromises = files.map(async (item) => {
        const href = await client.upload().adminPortal.image.fetchFile(item.key);
        const withRef: S3ItemReferenceWithFile = { ...item, href };
        return withRef;
      });
      const res = await Promise.all(filesPromises);
      const parseFileTypeFromExtension = (fileName: string) => {
        const extension = fileName.split('.').pop();
        if (extension === 'pdf') return 'application/pdf';
        if (extension === 'jpg') return 'image/jpeg';
        if (extension === 'png') return 'image/png';

        return 'application/octet-stream';
      };

      const withBlobs = res.map(async (S3WithURL: S3ItemReference & { href?: string }) => {
        try {
          if (!S3WithURL.href) return S3WithURL;
          const blob = await axios.get<Blob>(S3WithURL.href, {
            responseType: 'blob',
          });

          // convert the blob to a file
          return {
            ...S3WithURL,
            file: new File([blob.data], S3WithURL.key.split('/').pop() || '', {
              type: parseFileTypeFromExtension(S3WithURL.key),
            }),
          };
        } catch {
          return S3WithURL;
        }
      });

      const createObjectUrl = (file: S3ItemReferenceWithFile) => {
        try {
          if (!file.file) return undefined;
          return window.URL.createObjectURL(file.file);
        } catch (error) {
          console.warn('KEY error: ', error);
          return undefined;
        }
      };

      try {
        const newFiles = await Promise.all(withBlobs);
        const withLocalUrl = newFiles.map((item) => {
          const localUrl = createObjectUrl(item);
          const fileWithUrl: S3ItemReferenceWithFile = {
            ...item,
            href: localUrl || '',
          };
          return fileWithUrl;
        });
        setAllFiles(withLocalUrl);
      } catch (error) {
        console.warn('Errors in Promise.all: ', error);
        setAllFiles([]);
      }
      return;
    } catch (error) {
      showFlash('There was an error getting file.', 'warning');
      console.warn('S3 Get File Error: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (file: File | null, imageType: keyof typeof types) => {
    if (!file) return;
    setIsLoading(true);

    const ext = file.name.split('.').pop();
    const key = `${folderName}${uuidv4()}.${ext}`;
    try {
      const s3Object = await client
        .upload()
        .adminPortal.image.upload({ file, key, imageType, s3Bucket, s3Region, note });
      await onFileUpload(s3Object);
      showFlash('File uploaded successfully', 'success');
      setNote('');
      setFileType('');
      getFiles();
    } catch (error) {
      showFlash('There was an error uploading file.', 'warning');
      console.warn('uploadS3FilesToFolder Error: ', error);
    } finally {
      setNote('');
      setIsLoading(false);
    }
  };

  const handleNoteEditOpen = async (file: S3ItemReferenceWithFile | null) => {
    if (!file) return;
    setEditNote(file.note || '');
    setEditFile(file);
    setShowEditFileNote(true);
  };

  const handleNoteEdit = async () => {
    if (!editFile) return;
    try {
      editFile.note = editNote;
      files?.forEach((item) => {
        if (item.key === editFile.key) {
          item.note = editNote;
        }
      });
      await onFilesEdit(files);
      getFiles();
    } catch (error) {
      console.warn('uploadS3FilesToFolder Error: ', error);
    } finally {
      setEditNote('');
      setIsLoading(false);
      setShowEditFileNote(false);
    }
  };

  const deleteFileHandler = async (file: S3ItemReferenceWithFile) => {
    try {
      setIsLoading(true);
      const payload = files?.find((item: S3ItemReference) => item.key === file.key);
      if (!payload) return;
      files = files?.filter((item) => item.key !== file.key);
      await onFilesEdit(files);
      const newArray = allFiles.filter((item) => item.key !== file.key);
      setAllFiles(newArray);
    } catch (error) {
      console.warn('error: ', error);
      console.warn('deleteS3FileFromFolder Error: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRotate = (fileName: string) => {
    if (rotate.fileName === fileName) {
      const newDeg = rotate.deg >= 360 ? 0 : rotate.deg + 90;
      setRotate({ fileName: fileName, deg: newDeg });
    } else {
      setRotate({ fileName, deg: 90 });
    }
  };

  const zoomHandler = (fileName: string) => {
    if (zoomFile === fileName) {
      setZoomFile(null);
    } else {
      setZoomFile(fileName);
    }
  };

  //////////////////////////////////////////////
  // RENDER SECTION
  //////////////////////////////////////////////

  const body = () => {
    return (
      <>
        <div className="flex flex-col overflow-auto">
          <div className="flex mt-4 overflow-auto">
            {allFiles.map((file: S3ItemReferenceWithFile, index) => (
              <div
                key={file?.file?.name ? file?.file?.name + index : index}
                className={`relative overflow-auto min-h-[505px] min-w-[505px] max-h-[505px] max-w-[505px] mr-2 ${
                  allFiles.length - 1 !== index ? 'mr-5' : ''
                }]`}
              >
                <TrashIcon
                  className="h-6 w-6 absolute top-2 z-10 bg-black opacity-70 rounded text-white left-2"
                  onClick={() => deleteFileHandler(file)}
                />
                <a href={file.href} download className="absolute top-2 right-12 h-6 w-6 z-[3]">
                  <ArrowDownTrayIcon className="absolute z-10 bg-black opacity-70 rounded text-white top-0 h-6 w-6" />
                </a>
                <ArrowPathRoundedSquareIcon
                  className="absolute top-2 z-10 bg-black opacity-70 rounded text-white right-2 h-6 w-6"
                  onClick={() => handleRotate(file?.file?.name || '')}
                />
                {file?.file?.type === 'application/pdf' ? (
                  <object className="w-full h-full" data={file.href} type="application/pdf">
                    <param name="width" value="100%" />
                    test
                  </object>
                ) : file?.file?.name ? (
                  <div className="relative">
                    <img
                      src={file.href}
                      className={`${
                        zoomFile === file?.file?.name
                          ? 'cursor-zoom-out h-[600px] w-[600px]'
                          : 'cursor-zoom-in h-[300px] w-[300px]'
                      }`}
                      style={{
                        transform: rotate.fileName === file?.file?.name ? `rotate(${rotate.deg}deg)` : 'rotate(0deg)',
                      }}
                      alt="Error With SRC"
                      onClick={() => zoomHandler(file?.file?.name || '')}
                    />
                    <div className="absolute bottom-0 left-0 w-full p-2 bg-gradient-to-t z-10 from-black to-transparent text-white">
                      <PencilIcon
                        className="h-6 w-6 inline-block mr-1  bg-black opacity-70 rounded text-white top-0"
                        onClick={() => handleNoteEditOpen(file)}
                      />
                      Note: {file.note || ''}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>
          {isLoading && (
            <div className="w-full flex items-center justify-center">
              <Loading />
            </div>
          )}
        </div>
        {/* File Drop Zone */}

        {showUploadDropZones && (
          <div className="grid grid-cols-2 gap-2 mt-2">
            <FileDropZone
              handleFileUpload={(event) => handleFileUpload(event.dataTransfer.files[0], 'haulerPayable')}
              text="Invoice from Hauler"
            />
            <FileDropZone
              handleFileUpload={(event) => handleFileUpload(event.dataTransfer.files[0], 'haulerPayable')}
              text="Dump Ticket from Hauler"
            />
          </div>
        )}
        <div className="flex mt-4 mb-4 space-y-4 flex-col">
          <Select label={'File Type'} onSelect={setFileType} defaultValue="" value={fileType}>
            {Object.entries(types).map((item) => (
              <SelectOption key={item[0]} value={item[0]}>
                {item[1]}
              </SelectOption>
            ))}
          </Select>
          <TextField
            type={'string'}
            label="Note for New File"
            inputProps={{
              value: note,
              onChange: (event) => setNote(event.target.value),
            }}
          />
          <div
            className={`flex flex-1 p-2 border border-solid  border-[#979797] rounded-[4px] justify-center items-center text-center relative cursor-pointer ${
              isLoading || fileType === '' ? 'text-black/[.68] bg-black/[.28]' : 'text-[#FE3058] bg-[#F8F7FC]'
            }`}
          >
            <PhotoIcon className={`h-6 w-6 mr-2 ${isLoading ? 'text-black opacity-70' : 'text-[#FE3058]'}`} />
            <div>Upload file</div>
            <input
              className="blankFileInput"
              type="file"
              onChange={(event) => {
                handleFileUpload(event.target.files ? event.target.files[0] : null, fileType);
              }}
              disabled={isLoading || fileType === ''}
            />
          </div>
        </div>
      </>
    );
  };

  if (inline) return <div>{body()}</div>;

  return (
    <>
      <Dialog showX open={open} className="max-w-screen-2xl" onClose={onCancel} styledTitle="Files">
        {body()}
        <Dialog
          open={showEditFileNote}
          className="max-w-screen-2xl"
          onClose={() => setShowEditFileNote(false)}
          styledTitle="File Note"
        >
          <TextField
            type={'string'}
            label="Note"
            inputProps={{
              value: editNote,
              onChange: (event) => setEditNote(event.target.value),
              disabled: isLoading || fileType === '',
            }}
          />
          <div className="flex justify-end mt-4 space-x-2">
            <button className="btn-dark-grey-outlined px-12" onClick={() => setShowEditFileNote(false)} type="button">
              Cancel
            </button>
            <button className="btn-primary px-12" type="submit" onClick={() => handleNoteEdit()}>
              {'Save'}
            </button>
          </div>
        </Dialog>
      </Dialog>
    </>
  );
};

export default FileHandler;
