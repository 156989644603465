import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { buildAddressString, formatUSD } from '@wayste/utils';
import { formatE164ToUSPhoneNumber } from '@wayste/utils';
import { PencilIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { UIContext } from '../../contexts';
import {
  commercialBillingFrequencies,
  commercialLocationTypes,
  commercialMaterials,
  commercialServiceFrequency,
} from '../../utils/shared-types';
import Dialog from '../Dialog';
import { LeadCMForms } from '../LeadForms';
import CardInfoList from '../ui/CardInfoList';
import ContactedDetails from './ContactedDetails';
import InternalNotes from './InternalNotes';
import MarketingDetails from './MarketingDetails';

const quoteHeadings = ['Size', 'Material', 'Service Frequency', 'Quantity', 'Billing Frequency', 'Price Quoted', ''];

export interface LeadCMDetailsProps {
  rawLead: Customer.AllianceLeadTransport;
}

const LeadCMDetails = ({ rawLead }: LeadCMDetailsProps) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);

  // STATE
  const [lead, setLead] = useState<Customer.AllianceLeadTransport>(rawLead);
  const [updateForm, setUpdateForm] = useState<any>(null);

  //////////////////////////////////////////////
  // HOOKS SECTION
  //////////////////////////////////////////////

  useEffect(() => {
    if (!rawLead) return;
    setLead(rawLead);
  }, [rawLead]);

  //////////////////////////////////////////////
  // FUNCTION SECTION
  //////////////////////////////////////////////

  const handleUpdateSubmit = async (leadObj: any) => {
    const form = updateForm;
    setUpdateForm(null);
    switch (form) {
      case 'quote':
        for (const quote of leadObj.quotes) {
          if (!quote.id) {
            try {
              await client.customer().adminPortal.leads.quote.create(lead.id, quote);
              showFlash('Lead Successfully Updated', 'success');
            } catch (error) {
              showFlash('Error Updating Lead', 'warning');
            }
          }
        }
        break;
      case 'quote-hauler':
        for (const haulerQuote of leadObj.haulerQuotes) {
          if (!haulerQuote.id) {
            try {
              await client.customer().adminPortal.leads.haulerQuote.create(lead.id, haulerQuote);
              showFlash('Lead Successfully Updated', 'success');
            } catch (error) {
              showFlash('Error Updating Lead', 'warning');
            }
          }
        }
        break;
      case 'contacted':
        for (const event of leadObj.contactEvents) {
          if (!event.id) {
            try {
              await client.customer().adminPortal.leads.contactEvent.create(lead.id, event);
              showFlash('Event Successfully Updated', 'success');
            } catch (error) {
              showFlash('Error Updating Event', 'warning');
            }
          }
        }
        break;
      default:
        try {
          await client.customer().adminPortal.leads.update(lead.id, leadObj);
          showFlash('Lead Successfully Updated', 'success');
        } catch (error) {
          showFlash('Error Updating Lead', 'warning');
        }
        break;
    }
  };

  const handleRemoveQuote = async (quoteID: string) => {
    try {
      await client.customer().adminPortal.leads.quote.delete(lead.id, quoteID);
      showFlash('Quote Successfully Deleted', 'success');
    } catch (error) {
      console.log(error);
      showFlash('Error Deleting Quote', 'warning');
    }
  };

  const handleRemoveHaulerQuote = async (quoteID: string) => {
    try {
      await client.customer().adminPortal.leads.haulerQuote.delete(lead.id, quoteID);
      showFlash('Quote Successfully Deleted', 'success');
    } catch (error) {
      console.log(error);
      showFlash('Error Deleting Quote', 'warning');
    }
  };

  //////////////////////////////////////////////
  // RENDER SECTION
  //////////////////////////////////////////////

  if (!lead) return <div>Lead does not exist</div>;

  const leadColOne = [
    { label: 'Name', value: `${lead.firstName} ${lead.lastName}` },
    { label: 'Company', value: lead.company },
    { label: 'Email', value: lead.email },
    {
      label: 'Phone',
      value: lead.phoneNumber ? formatE164ToUSPhoneNumber(lead.phoneNumber) : null,
    },
  ];

  const leadColTwo = [
    {
      label: 'Location',
      value: lead?.serviceLocation?.address ? buildAddressString(lead?.serviceLocation?.address) : null,
    },
    { label: 'County', value: lead?.serviceLocation?.county },
    {
      label: 'Location Type',
      value: commercialLocationTypes[lead.locationType as keyof typeof commercialLocationTypes],
    },
    { label: 'Current Hauler', value: lead.currentHauler || '' },
  ];

  const leadColThree = [
    {
      label: 'Start Date',
      value: lead.requestedStartDate ? format(new Date(lead.requestedStartDate), 'MM/dd/yy') : '',
    },
    {
      label: 'Follow Up Date',
      value: lead.followUpDate ? format(new Date(lead.followUpDate), 'MM/dd/yy') : '',
    },
    { label: 'Cus Notes', value: lead.customerNotes },
  ];

  return (
    <div className="container mx-auto">
      <div className="bg-white rounded shadow-dark px-4 py-1.5 mb-5">
        <div className="flex justify-between items-center">
          <div className="text-xl">Lead Details</div>
          <button className="btn-primary" onClick={() => setUpdateForm('lead')}>
            <PencilIcon className="h-4 w-4 mr-1.5" />
            Edit
          </button>
        </div>
        <hr className="my-2 -mx-4" />
        <div className={`gap-4 grid grid-cols-1 lg:grid-cols-3`}>
          <CardInfoList data={leadColOne} containerSize={4} border />
          <CardInfoList data={leadColTwo} containerSize={4} border />
          <CardInfoList data={leadColThree} containerSize={4} />
          <div className="border-t flex lg:col-span-3 pt-4">
            <div className="text-gray-500 mr-5">Notes</div>
            <div>
              <InternalNotes notesInternal={lead.notesInternal} collection={'leads'} docId={lead.id} />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white rounded shadow-dark py-1.5 mb-5">
        <div className="px-4">
          <div className="flex justify-between">
            <div className="text-xl">Quote Details for Customer</div>
            <button className="btn-primary" onClick={() => setUpdateForm('quote')}>
              <PlusIcon className="h-5 w-5 mr-1.5" />
              Add
            </button>
          </div>
        </div>
        <hr className="mt-2" />
        <div className="w-full overflow-x-auto -mb-1.5">
          <table className="w-full border-collapse border-spacing-0 text-sm">
            <thead>
              <tr className="[&>*]:px-4 [&>*]:py-1.5 [&>*]:whitespace-nowrap [&>*]:font-normal text-left bg-gray-100">
                {quoteHeadings.map((item) => (
                  <th key={item}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {lead.quotes.map((item: any) => (
                <tr
                  key={item.id}
                  className={`[&>*]:border-b [&>*]:px-4 [&>*]:py-1.5 ${item.active ? '' : 'bg-disabled'}`}
                >
                  <td>{`${item.size?.size} ${item.size?.size === '96' || item.size?.size === '64' ? 'Gal' : 'YD'}`}</td>
                  <td>{commercialMaterials[item.material as keyof typeof commercialMaterials]}</td>
                  <td>{commercialServiceFrequency[item.frequency as keyof typeof commercialServiceFrequency]}</td>
                  <td>{item.quantity}</td>
                  <td>
                    {commercialBillingFrequencies[item.billingFrequency as keyof typeof commercialBillingFrequencies]}
                  </td>
                  <td>{formatUSD(item.priceDollars)}</td>
                  <td>
                    {item.active ? (
                      <button className="btn-icon" type="button" onClick={() => handleRemoveQuote(item.id)}>
                        <XMarkIcon className="h-6 w-6 text-delete" />
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
              <tr className="[&>*]:px-4 [&>*]:py-1.5">
                <td colSpan={5} className="text-right">
                  <b>Total</b>
                </td>
                <td>{formatUSD(lead.quotes.reduce((a: number, b: any) => a + (b.priceDollars || 0), 0))}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="bg-white rounded shadow-dark py-1.5 mb-5">
        <div className="px-4">
          <div className="flex justify-between">
            <div className="text-xl">Bid Details from Hauler</div>
            <button className="btn-primary" onClick={() => setUpdateForm('quote-hauler')}>
              <PlusIcon className="h-5 w-5 mr-1.5" />
              Add
            </button>
          </div>
        </div>
        <hr className="mt-2" />
        <div className="w-full overflow-x-auto -mb-1.5">
          <table className="w-full border-collapse border-spacing-0 text-sm">
            <thead>
              <tr className="[&>*]:px-4 [&>*]:py-1.5 [&>*]:whitespace-nowrap [&>*]:font-normal text-left font-normal bg-gray-100">
                <th>Hauler</th>
                {quoteHeadings.map((item) => (
                  <th key={item}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {lead.haulerQuotes.map((item: any) => (
                <tr
                  key={item.id}
                  className={`[&>*]:border-b [&>*]:px-4 [&>*]:py-1.5 ${item.active ? '' : 'bg-disabled'}`}
                >
                  <td>{item.haulerName}</td>
                  <td>{`${item.size?.size} YD`}</td>
                  <td>{commercialMaterials[item.material as keyof typeof commercialMaterials]}</td>
                  <td>{commercialServiceFrequency[item.frequency as keyof typeof commercialServiceFrequency]}</td>
                  <td>{item.quantity}</td>
                  <td>
                    {commercialBillingFrequencies[item.billingFrequency as keyof typeof commercialBillingFrequencies]}
                  </td>
                  <td>{formatUSD(item.priceDollars)}</td>
                  <td>
                    {item.active ? (
                      <button type="button" className="btn-icon" onClick={() => handleRemoveHaulerQuote(item.id)}>
                        <XMarkIcon className="h-6 w-6 text-delete" />
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ContactedDetails onActionClick={setUpdateForm} lead={lead} />
      <MarketingDetails onActionClick={setUpdateForm} lead={lead} />
      <Dialog
        open={Boolean(updateForm)}
        className="max-w-6xl"
        styledTitle="Update Lead"
        onClose={() => setUpdateForm(null)}
      >
        {updateForm ? (
          <LeadCMForms
            onCancel={() => setUpdateForm(null)}
            onSubmit={handleUpdateSubmit}
            lead={lead}
            form={updateForm}
          />
        ) : null}
      </Dialog>
    </div>
  );
};

export default LeadCMDetails;
