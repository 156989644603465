import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

/**
 * Preformatted Material UI RadioGroup component
 * @param {Function} onChange Function returns the selected options value
 * @param {Array} options Array of objects of options that must include a value and label field
 * @param {Boolean} [disabled] Should field be disabled
 * @param {String} [label] Label for radios
 * @param {Object} [styles] Additional styles to apply to the FormControl wrapper
 */

export interface RadiosProps {
  onChange: any;
  options: { value: string; label: string; disabled?: boolean }[];
  disabled?: boolean;
  label?: string;
  styles?: object;
}

const Radios = ({ onChange, options, disabled, label, styles, ...props }: RadiosProps) => (
  <FormControl style={{ maxWidth: 500, ...styles }}>
    {label ? <FormLabel style={{ marginBottom: 8 }}>{label}</FormLabel> : null}
    <RadioGroup {...props} onChange={(event) => onChange(event.target.value)}>
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          disabled={disabled || option.disabled}
          value={option.value}
          control={<Radio />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

export default Radios;
