import { round } from '@wayste/utils';
import { materialEnums } from './shared-types';

export const ccRate = 0.03;

// IF CHANGING STRIPE RATE HAVE TO RECORD WHAT IT WAS UP TO DATE CHANGE IN ORDER TO PRESERVE OLDER ORDERS
export const stripeRate = {
  percent: 0.029,
  fixed: 0.3,
};

export const salesTaxExemptMaterials = [
  materialEnums.bNB,
  materialEnums.bNL,
  materialEnums.bNS,
  materialEnums.concrete,
  materialEnums.comix,
  materialEnums.concreteRebar,
  materialEnums.asphalt,
  materialEnums.dirt,
  materialEnums.scrap,
  materialEnums.cardboard,
];

/**
 * Build invoice item pricing (breaks out tax and cc)
 * @param {Number} qty - Quantity
 * @param {Number} rate - Unedited rate inclusive of tax and cc
 * @param {Number} ccRate - Credit card processing rate
 * @param {Boolean} taxable - Is the item taxable
 * @param {Number} taxRate - Sales tax rate
 * @return {Object} {total, cc, taxAmount, rate, qty}
 */
export const pricingBuild = (qty: number, rate: number, ccRate: number, taxable: boolean, taxRate: number) => {
  const tax = taxable ? round(rate * qty * taxRate, 5) : 0;
  const total = round((rate * qty + tax) / (1 - ccRate));
  const cc = round(total * ccRate, 5);

  return {
    total: total,
    cc: cc,
    taxAmount: tax,
    rate: rate,
    qty: qty,
  };
};

// /**
//  * Calculates invoice total based on invoice items from InvoiceModel
//  * @param {Object} invoice InvoiceModel
//  * @return {Object} {rawTotal, taxTotal, total}
//  */
// export const calcInvoiceTotal = (invoice: InvoiceModel | { lineItems: any[]; cc: number; taxRate: number }) => {
//   let rawTotal = 0;
//   let taxTotal = 0;
//   let qpAdj: any = null;
//   console.log('invoiceTotal invoice: ', invoice);
//   const invoicecc = invoice.lineItems.find((lineItem: LineItemModel) => lineItem.itemName === 'CC Fee') ? ccRate : 0;
//   invoice.lineItems.forEach((item: LineItemModel) => {
//     if (item.itemName === 'CC Fee') return;
//     if (item.itemName === 'QP-Adj') {
//       qpAdj = item;
//       return;
//     }
//     const priceItem = pricingBuild(item.quantity, item.unitPriceDollars, invoicecc, item.taxable, invoice.taxRate);
//     console.log('priceItem: ', priceItem);
//     rawTotal = rawTotal + round(priceItem.rate * priceItem.qty);
//     taxTotal = taxTotal + priceItem.taxAmount;
//   });
//   rawTotal = round(rawTotal);
//   taxTotal = round(taxTotal);
//   const total = round((rawTotal + taxTotal) / (1 - invoicecc));
//   if (qpAdj) return total + +qpAdj.rate;
//   return total;
// };

// /**
//  * Finds the haul and dump costs and separates it from the rest
//  * @param {Object} order OrderModel
//  * @return haul, dump, other, and total costs
//  */
// export const getHaulDumpCosts = (order: OrderType) => {
//   let haul = 0;
//   let dump = 0;
//   let other = 0;
//   if (order.bills && order.bills.length > 0) {
//     order.bills.forEach((bill: InvoiceModel) => {
//       if (bill.lineItems) {
//         bill.lineItems.forEach((item) => {
//           if (item.itemName === 'haul') {
//             haul = round(haul + item.totalPriceDollars);
//           } else if (item.itemName === 'dump') {
//             dump = round(dump + item.totalPriceDollars);
//           } else {
//             other = round(other + item.totalPriceDollars);
//           }
//         });
//       }
//     });
//   }
//   return {
//     haul,
//     dump,
//     other,
//     total: round(haul + dump + other),
//   };
// };

// /**
//  * Calculates the total due on an order
//  * @param {Object} order OrderModel
//  * @return Total due on order
//  */
// export const calcOrderTotal = (order: OrderType | OrderModel) => {
//   let total = 0;
//   order.invoices.forEach((invoice: InvoiceModel) => {
//     if (!invoice.void) total = total + invoice.totalDollars * 100;
//   });
//   return round(total / 100);
// };
