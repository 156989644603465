import { useState } from 'react';
import { Customer, Invoice, Order } from '@alliance-disposal/transport-types';
import { Loading } from '@wayste/sour-ui';
import { formatServiceAddress, moneyFormatter } from '@wayste/utils';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import OrderImageHandler from '../../../../components/OrderImageHandler';
import { ccRate } from '../../../../utils';
import BillItemDialog from './BillItemDialog';
import DetailsBar from './DetailsBar';
//import DetailsBar from './DetailsBar';
import EditModal, { EditInvoiceFormProps } from './EditModal';
import EditPayableModal, { EditPayableFormProps } from './EditPayableModal';
import LineItemModal from './LineItemModal';
import PayableCard from './PayableCard';
import ReceivableCard from './ReceivableCard';
import RefundDialog from './RefundDialog';
import TopBar from './TopBar';

const InvoiceTypes = ['invoice', 'bill'] as const;

export type InvoiceType = (typeof InvoiceTypes)[number];

interface InvoiceUpdateProps {
  customer: Customer.AllianceCustomerTransport | null;
  ordersMatch: boolean;
  order: Order.AllianceOrderTransport | null;
  isLoading: boolean;
  onCancel: () => void;
  onShowOrderDetails: () => void;
  handleOnRefund: (invoice: Invoice.ReceivableUpdateTransport) => void;
  onUploadQB: () => void;
  onChangeTax: (value: number, key: number) => void;
  onChangeCC: (value: number, key: number) => void;
  onSave: (view: InvoiceType) => void;
  onSaveClose: (view: InvoiceType) => void;
  onAddPayment: (view: InvoiceType, haulerID?: string) => void;

  payables: Invoice.PayableTransport[];
  receivables: Invoice.ReceivableTransport[];

  // create
  handleCreateNewReceivable: () => void;
  handleCreateNewPayable: (payable: {
    haulerID: string;
    invoiceNumber: string;
    haulerName: string;
    lineItems?: Invoice.LineItemInputTransport[];
  }) => void;

  // delete
  handleDeleteReceivable: (receivableIndex: number) => void;
  handleDeletePayable: (payableIndex: number) => void;

  onCreateReceipt: (key: number) => void; // <--- what does this do?
  onEditModalSave: (obj: EditInvoiceFormProps, invoiceIndex: number) => void;
  onReceivableLineItemSave: (
    selectedReceivableNumber: number,
    newItems: Partial<Invoice.LineItemTransport>[],
    selectedItem: number | undefined,
  ) => void;
  onReceivableDeleteLineItem: (selectedReceivableNumber: number, index: number) => void;

  onUpdateBill: (
    payableIndex: number,
    data: {
      invoiceNumber?: string;
      haulerID?: string;
      haulerName?: string;
      readyForPayment?: boolean;
      internalNotes?: string;
    },
  ) => void;
  onBillLineItemSave: (billIndex: number, item: Partial<Invoice.LineItemTransport>, index: number) => void;
  onBillDeleteLineItem: (payable: number, index: number) => void;

  onNeedsAttention: () => void;
  onGetHaulerName: (payableIndex: number) => Promise<string>;

  // handleSaveReceivable: (receivable: Invoice.ReceivableTransport) => void;
}

const InvoiceUpdate = ({
  customer,
  ordersMatch,
  order,
  onSave,
  onAddPayment,

  payables,
  receivables,

  onSaveClose,
  onCancel,
  isLoading,
  onCreateReceipt,
  handleCreateNewReceivable,
  handleCreateNewPayable,
  handleDeleteReceivable,
  handleDeletePayable,

  onChangeTax,
  // handleSaveReceivable,
  onShowOrderDetails,
  onChangeCC,
  onEditModalSave,
  onReceivableLineItemSave,
  onReceivableDeleteLineItem,
  // onUploadQB,
  // onRefund,
  onUpdateBill,
  handleOnRefund,
  onBillLineItemSave,
  onBillDeleteLineItem,
  onNeedsAttention,
  onGetHaulerName,
}: InvoiceUpdateProps) => {
  // MODAL STATE
  const [showOrderImages, setShowOrderImages] = useState<boolean>(false);
  const [showEditInvoice, setShowEditInvoice] = useState<boolean>(false);
  const [showAddEditItem, setShowAddEditItem] = useState<boolean>(false);
  const [showRefundDialog, setShowRefundDialog] = useState<boolean>(false);
  const [showEditPayableModel, setShowEditPayableModel] = useState<boolean>(false);
  const [showBillItem, setShowBillItem] = useState<boolean>(false);

  // VIEW MODE
  const [view, setView] = useState<InvoiceType>('invoice');

  // RECEIVABLE STATE
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice.ReceivableTransport | undefined>(undefined);
  const [selectedInvoiceIndex, setSelectedInvoiceIndex] = useState<number | null>(null);

  // PAYABLE STATE
  const [selectedBill, setSelectedBill] = useState<Invoice.PayableTransport | undefined>(undefined);
  const [selectedBillIndex, setSelectedBillIndex] = useState<number | null>(null);

  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedBillItem, setSelectedBillItem] = useState<any>(null);

  ///////////////////////////////////////
  // MODAL HANDLERS
  ///////////////////////////////////////

  const handleRefundButtonClick = (invoiceNumber: number) => {
    if (!order) return;
    setSelectedInvoice(receivables[invoiceNumber]);
    if (!ordersMatch) {
      alert('Save your current changes before issuing a refund');
      return;
    }
    setShowRefundDialog(true);
  };

  // const handleSyncedWithAccountingClose = (saved: boolean) => {
  //   setSelectedInvoice(undefined);
  //   setSelectedBill(undefined);
  //   if (saved) onUploadQB();
  // };

  const handleCloseRefundDialog = () => {
    setShowRefundDialog(false);
    setSelectedInvoice(undefined);
  };

  const handleChangeView = (value: InvoiceType) => {
    setView(value);
  };

  const handleCreateNewCard = () => {
    if (view === 'invoice') handleCreateNewReceivable();
    if (view === 'bill') setShowEditPayableModel(true);
  };

  const handleBillDetailsSubmit = (data: EditPayableFormProps) => {
    console.log('handleBillDetailsSubmit', data);
    if (!selectedBill) {
      handleCreateNewPayable(data);
    } else {
      if (selectedBillIndex !== null)
        onUpdateBill(selectedBillIndex, {
          haulerName: data.haulerName,
          haulerID: data.haulerID,
          invoiceNumber: data.invoiceNumber,
          internalNotes: data.internalNotes,
        });
    }
    setShowEditPayableModel(false);
    setSelectedBill(undefined);
  };

  const handleBillEditClick = (index: number) => {
    if (!order) return;
    setSelectedBill(payables[index]);
    setSelectedBillIndex(index);
    setShowEditPayableModel(true);
  };

  const handleLineItemClicked = (
    item: { item: Partial<Invoice.LineItemTransport>; index: number } | null,
    key: number,
  ) => {
    if (!order) return;
    if (view === 'invoice') {
      setSelectedItem(item);
      setSelectedInvoice(receivables[key]);
      setShowAddEditItem(true);
      setSelectedInvoiceIndex(key);
    }
    if (view === 'bill') {
      setSelectedBillItem(item);
      setSelectedBill(payables[key]);
      setShowBillItem(true);
      setSelectedBillIndex(key);
    }
  };

  // const handleTaxChange = (value: number, key: number) => {
  //   if (!order) return;
  //   if (order.invoices[key].syncedWithAccounting) alert('Any changes made need to be manually updated in QuickBooks');
  //   onChangeTax(value, key);
  // };

  // const handleCCChange = (value: number, key: number) => {
  //   if (!order) return;
  //   if (order.invoices[key].syncedWithAccounting) alert('Any changes made need to be manually updated in QuickBooks');
  //   onChangeCC(value, key);
  // };

  const getView = () => {
    if (!order) return <div />;
    if (view === 'invoice') {
      return receivables.map((receivable, index) => {
        if ((receivable.invoiceDetails.void && !receivable.invoiceDetails.issueDate) || !customer) return null;
        return (
          <ReceivableCard
            key={receivable.invoiceDetails.invoiceNumber + '-' + index}
            order={order}
            receivable={receivable}
            ordersMatch={ordersMatch}
            onCreateReceipt={() => onCreateReceipt(index)}
            onDeleteInvoice={() => handleDeleteReceivable(index)}
            onChangeTax={(value) => {
              onChangeTax(value, index);
            }} //handleTaxChange(value, index)
            onChangeCC={(value) => {
              onChangeCC(value, index);
            }} //handleCCChange(value, index)
            onOrderImageClicked={() => setShowOrderImages(true)}
            onEditInvoiceClicked={() => {
              setSelectedInvoice(receivable);
              setShowEditInvoice(true);
              setSelectedInvoiceIndex(index);
            }}
            onLineItemClicked={(item) => handleLineItemClicked(item, index)}
            onRefundClick={() => {
              handleRefundButtonClick(index);
            }}
            customer={customer}
          />
        );
      });
    }
    if (view === 'bill') {
      return (
        <>
          {order.haulerPricingSnapshot && (
            <div className="w-full bg-white border-2 border-wayste-blue-400 border-dashed rounded-md shadow-md p-3">
              <div className="flex justify-between items-center">
                <div className="flex justify-start gap-2">
                  <span className="font-semibold">Expected Costs</span>
                  <span className="font-light">|</span>
                  <span>{order.vendorName}</span>
                  <span className="font-light">|</span>
                  <span>{formatServiceAddress(order.serviceLocation?.address)}</span>
                </div>
                <button
                  className="btn-primary gap-2"
                  type="button"
                  onClick={() => {
                    setShowEditPayableModel(true);
                    setSelectedBill(undefined);
                    setSelectedBillIndex(null);
                  }}
                >
                  <ClipboardDocumentListIcon className="w-5 h-5" />
                  <span>Create Bill</span>
                </button>
              </div>
              <div className="w-full grid grid-cols-5 px-4">
                <span className="font-semibold">Item</span>
                <span className="font-semibold">Description</span>
                <span className="font-semibold">Unit Cost</span>
                <span className="font-semibold">Quantity</span>
                <span className="font-semibold">Total</span>
                <span>Haul</span>
                <span></span>
                <span>{moneyFormatter(order.haulerPricingSnapshot.haul)}</span>
                <span>{1}</span>
                <span>{moneyFormatter(order.haulerPricingSnapshot.haul)}</span>
                <span>Dump</span>
                <span></span>
                <span>
                  {moneyFormatter(
                    order.haulerPricingSnapshot.dump / (order.haulerPricingSnapshot?.tonLimit.value || 1),
                  )}
                </span>
                <span>{order.haulerPricingSnapshot?.tonLimit?.value || 0}</span>
                <span>{moneyFormatter(order.haulerPricingSnapshot.dump)}</span>

                {/* {order.events
                  .filter((event) => event.unitCost)
                  .map((event) => {
                    return (
                      <>
                        <span>{toTitleCase(event.lineItemType.description)}</span>
                        <span> {event.description}</span>
                        <span>{moneyFormatter(event.unitCost)}</span>
                        <span>{event.costQuantity}</span>
                        <span>{moneyFormatter(event.unitCost * event.costQuantity)}</span>
                      </>
                    );
                  })} */}
              </div>
              <div className="w-full grid grid-cols-5 px-4 border-t">
                <span className="col-start-4">Total:</span>
                <span className="col-start-5">
                  {moneyFormatter(order.haulerPricingSnapshot.haul + order.haulerPricingSnapshot.dump)}
                </span>
              </div>
            </div>
          )}
          {payables.map((payable, index) => {
            if (payable.invoiceDetails.void) return null;
            return (
              <PayableCard
                key={payable.invoiceDetails.invoiceNumber + '-' + index}
                order={order}
                payable={payables[index]}
                onDeleteBill={() => handleDeletePayable(index)}
                onOrderImageClicked={() => setShowOrderImages(true)}
                onReadyForPayment={(value) => onUpdateBill(index, { readyForPayment: value })}
                onEditBillClicked={() => handleBillEditClick(index)}
                onLineItemClicked={(item) => handleLineItemClicked(item, index)}
                ordersMatch={ordersMatch}
                onGetHaulerName={() => onGetHaulerName(index)}
              />
            );
          })}
        </>
      );
    }
    return <div />;
  };

  if (isLoading) {
    return (
      <div className="box-shadow w-full items-center justify-center inset-0 bg-gray-100 flex flex-col relative h-screen overflow-auto">
        <Loading />
      </div>
    );
  }

  return (
    <div className="bg-gray-100 inset-0 flex flex-col relative overflow-auto w-full">
      <TopBar
        order={order}
        customer={customer}
        ordersMatch={ordersMatch}
        onSave={() => onSave(view)}
        onAddPayment={(haulerID) => onAddPayment(view, haulerID)}
        onCreateNewCard={handleCreateNewCard}
        onSaveClose={() => onSaveClose(view)}
        onCancel={onCancel}
        isLoading={isLoading}
        view={view}
        onChangeView={handleChangeView}
        onNeedsAttention={onNeedsAttention}
        payables={payables}
      />
      <div className="p-2 pb-5 flex flex-col flex-grow">{getView()}</div>

      <DetailsBar
        order={order}
        receivables={receivables}
        payables={payables}
        customer={customer}
        onShowDetails={onShowOrderDetails}
      />
      {/* Dialogs */}
      {showRefundDialog ? (
        <RefundDialog
          open={showRefundDialog}
          onClose={handleCloseRefundDialog}
          handleOnRefund={handleOnRefund}
          invoice={selectedInvoice || null}
        />
      ) : null}
      {showOrderImages && order ? (
        <OrderImageHandler
          order={order}
          open={showOrderImages}
          onCancel={() => setShowOrderImages(false)}
          showUploadDropZones
        />
      ) : null}
      {order && (
        <EditModal
          open={showEditInvoice}
          invoice={selectedInvoice || null}
          order={order}
          onCancel={() => {
            setShowEditInvoice(false);
            setSelectedInvoice(undefined);
          }}
          onSave={(obj) => {
            if (selectedInvoiceIndex !== null) {
              onEditModalSave(obj, selectedInvoiceIndex);
            }
            setShowEditInvoice(false);
            setSelectedInvoice(undefined);
          }}
        />
      )}
      {order && (
        <LineItemModal
          open={Boolean(showAddEditItem && selectedInvoice)}
          taxRate={showAddEditItem && selectedInvoice ? selectedInvoice.invoiceDetails.taxRate : 0}
          ccRate={
            showAddEditItem &&
            selectedInvoice &&
            selectedInvoice.invoiceDetails.lineItems.find((item) => item.itemName === 'CC Fee')
              ? ccRate
              : 0
          }
          lineItem={showAddEditItem && selectedItem ? selectedItem.item : null}
          selectedInvoice={showAddEditItem ? selectedInvoice : null}
          order={order}
          onCancel={() => {
            setShowAddEditItem(false);
            setSelectedItem(null);
            setSelectedInvoice(undefined);
            setSelectedInvoiceIndex(null);
          }}
          onSave={(newItems) => {
            if (!selectedInvoiceIndex && selectedInvoiceIndex !== 0) return;
            onReceivableLineItemSave(selectedInvoiceIndex, newItems, selectedItem?.index);
            setShowAddEditItem(false);
            setSelectedItem(null);
            setSelectedInvoice(undefined);
            setSelectedInvoiceIndex(null);
          }}
          onDeleteItem={() => {
            if (!selectedInvoiceIndex && selectedInvoiceIndex !== 0) return;
            onReceivableDeleteLineItem(selectedInvoiceIndex, selectedItem.index);
            setShowAddEditItem(false);
            setSelectedItem(null);
            setSelectedInvoice(undefined);
            setSelectedInvoiceIndex(null);
          }}
        />
      )}
      {/* <SyncedWithAccountingModal
        open={showSyncedWithAccountingModal}
        invoice={view === 'invoice' ? selectedInvoice : selectedBill}
        onClose={() => handleSyncedWithAccountingClose(false)}
        onSave={() => handleSyncedWithAccountingClose(true)}
      /> */}
      <EditPayableModal
        open={showEditPayableModel}
        payable={selectedBill}
        order={order || undefined}
        onClose={() => {
          setShowEditPayableModel(false);
          setSelectedBill(undefined);
          setSelectedBillIndex(null);
        }}
        onSubmit={(data) => handleBillDetailsSubmit(data)}
      />
      <BillItemDialog
        open={Boolean(showBillItem && selectedBill)}
        lineItem={selectedBillItem ? selectedBillItem.item : null}
        onCancel={() => {
          setShowBillItem(false);
          setSelectedBillItem(null);
          setSelectedBill(undefined);
          setSelectedBillIndex(null);
        }}
        onSave={(newItem) => {
          if (selectedBillIndex !== null) {
            onBillLineItemSave(selectedBillIndex, newItem, selectedBillItem?.index);
          }
          setShowBillItem(false);
          setSelectedBillItem(null);
          setSelectedBill(undefined);
          setSelectedBillIndex(null);
        }}
        onDeleteItem={() => {
          if (!selectedBillIndex && selectedBillIndex !== 0) throw new Error('No bill selected');

          onBillDeleteLineItem(selectedBillIndex, selectedBillItem.index);
          setShowBillItem(false);
          setSelectedBillItem(null);
          setSelectedBill(undefined);
          setSelectedBillIndex(null);
        }}
      />
    </div>
  );
};

export default InvoiceUpdate;
