import React from 'react';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { DatePicker, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { Controller, useForm } from 'react-hook-form';
import Dialog from '../../../../components/Dialog';
import { paymentMethods, paymentTerms } from '../../../../utils/shared-types';

export interface EditInvoiceFormProps {
  paymentMethod: string;
  paymentTerm: string;
  issueDate: Date | '';
  memo?: string;
  internalNotes?: string;
}

interface Props {
  invoice: Invoice.ReceivableTransport | null;
  order: Order.AllianceOrderTransport;
  onSave: (values: EditInvoiceFormProps) => void;
  open: boolean;
  onCancel: () => void;
}

const EditModal = ({ invoice, order, onSave, open, onCancel }: Props) => {
  const onSubmit = (data: EditInvoiceFormProps) => {
    onSave({
      paymentMethod: data.paymentMethod,
      paymentTerm: data.paymentTerm,
      issueDate: data.issueDate,
      internalNotes: data.internalNotes,
      memo: data.memo,
    });
  };

  if (!open) return null;

  const {
    control,
    handleSubmit,
    register,
    formState: { isDirty, isValid },
  } = useForm<EditInvoiceFormProps>({
    mode: 'all',
    defaultValues: {
      issueDate: invoice?.invoiceDetails.issueDate ? new Date(invoice.invoiceDetails.issueDate) : '',
      paymentMethod: order.paymentMethod || undefined,
      paymentTerm: order.paymentTerm || undefined,
    },
  });

  return (
    <Dialog onClose={onCancel} styledTitle="Edit Invoice" open={open}>
      <form className="overflow-y-auto flex flex-col gap-4 pt-2.5" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full">
          <Controller
            name="paymentTerm"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Payment Term is Required',
              },
            }}
            render={({ field }) => (
              <Select
                label="Payment Term"
                value={field.value}
                onSelect={(value) => field.onChange(value)}
                required={true}
              >
                {Object.entries(paymentTerms).map((item) => (
                  <SelectOption key={`paymentTerm-${item[0]}`} value={item[0]}>
                    {item[1]}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="w-full">
          <Controller
            name="paymentMethod"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Payment Method is Required',
              },
            }}
            render={({ field }) => (
              <Select
                label="Payment Method"
                value={field.value}
                onSelect={(value) => field.onChange(value)}
                required={true}
              >
                {Object.entries(paymentMethods).map((item) => (
                  <SelectOption key={`paymentMethod-${item[0]}`} value={item[0]}>
                    {item[1]}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="w-full">
          <Controller
            name="issueDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="Invoice date"
                value={field.value}
                onChange={(value) => field.onChange(value)}
                format="keyboardDate"
              />
            )}
          />
        </div>
        <div className="w-full">
          <TextField label="Memo" inputProps={{ ...register('memo') }} />
        </div>
        <div className="w-full">
          <TextField label="Internal Notes" inputProps={{ ...register('internalNotes') }} />
        </div>

        <div className="flex justify-end gap-4 pt-4">
          <button className="btn-dark-grey-outlined" onClick={onCancel} type="button">
            Cancel
          </button>
          <button className="btn-primary" type="submit" disabled={!isValid || !isDirty}>
            Update
          </button>
        </div>
      </form>
    </Dialog>
  );
};

export default EditModal;
