//////////////////////////////////////////////
// MANAGE ORDER IMAGES UPLOAD, DELETE, VIEW
//////////////////////////////////////////////
import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { S3ItemReference } from '@alliance-disposal/transport-types';
import { AllianceOrderTransport } from '@alliance-disposal/transport-types/dist/order';
import { FileDropZone } from '@wayste/sour-components';
import { Loading } from '@wayste/sour-ui';
import { ArrowDownTrayIcon, ArrowPathRoundedSquareIcon, PhotoIcon, TrashIcon } from '@heroicons/react/24/solid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { orderImageTypes } from '../utils';
import Dialog from './Dialog';

// const imgOptions = {
//   maxSizeMB: 1,
//   maxWidthOrHeight: 1000,
// };

// BUCKET CONFIGS
const s3Region = import.meta.env.VITE_AWS_USER_FILES_S3_BUCKET_REGION;
const s3Bucket = import.meta.env.VITE_AWS_USER_FILES_S3_BUCKET;

type Props = {
  order: AllianceOrderTransport;
  inline?: boolean;
  open: boolean;
  showUploadDropZones?: boolean;
  onCancel?: any;
};

const defaultProps = {
  inline: false,
  open: false,
  onCancel: () => undefined,
};

const OrderImageHandler = ({ order, inline, open, onCancel, showUploadDropZones }: Props) => {
  const client = useWaysteClient();

  //////////////////////////////////////////////
  // STATE
  //////////////////////////////////////////////
  const [isLoading, setIsLoading] = useState(false);
  const [allFiles, setAllFiles] = useState<
    (S3ItemReference & {
      blob?: Blob;
      href: string;
    })[]
  >([]);
  const [zoomFile, setZoomFile] = useState<string | null>(null);
  const [rotate, setRotate] = useState<{
    fileName: string | null;
    deg: number;
  }>({ fileName: null, deg: 0 });
  const [anchorEl, setAnchorEl] = useState<any>(null);

  //////////////////////////////////////////////
  // HOOKS SECTION
  //////////////////////////////////////////////

  useEffect(() => {
    getImages();
  }, [order.images]);

  //////////////////////////////////////////////
  // FUNCTIONS SECTION
  //////////////////////////////////////////////

  const getImages = async () => {
    setIsLoading(true);
    try {
      // we have order.images, where image is { bucket, key, region, string }
      // are we trying to fetch by
      const images = order.images;
      const folderName = 'order' + (order.orderNumber?.toString() ?? '') + '/';

      const res = await client.upload().adminPortal.image.fetch(folderName, images);

      const parseFileTypeFromExtension = (fileName: string) => {
        const extension = fileName.split('.').pop()?.toLowerCase() || '';
        switch (extension) {
          case 'pdf':
            return 'application/pdf';
          case 'jpg': // fallthrough intended
          case 'jpeg':
            return 'image/jpeg';
          case 'png':
            return 'image/png';
          case 'gif':
            return 'image/gif';
          case 'svg':
            return 'image/svg+xml';
          case 'webp':
            return 'image/webp';
          case 'tiff': // fallthrough intended
          case 'tif':
            return 'image/tiff';
        }

        return 'application/octet-stream';
      };

      const withBlobs = res.map(async (S3WithURL) => {
        if (!S3WithURL.href || !S3WithURL.key) return;
        try {
          const blob = await axios.get<Blob>(S3WithURL.href, {
            responseType: 'blob',
          });

          // convert the blob to a file
          return {
            ...S3WithURL,
            blob: new File([blob.data], S3WithURL.key.split('/')?.pop() || 'file', {
              type: parseFileTypeFromExtension(S3WithURL.key),
            }),
          };
        } catch {
          return S3WithURL;
        }
      });

      const createObjectUrl = (file: any) => {
        try {
          return window.URL.createObjectURL(file.blob);
        } catch (error) {
          console.warn('KEY error: ', error);
          return undefined;
        }
      };

      try {
        const newFiles = await Promise.all(withBlobs);
        const withLocalUrl = newFiles.map((item: any) => {
          const localUrl = createObjectUrl(item);
          return {
            ...item,
            localUrl,
          };
        });
        setAllFiles(withLocalUrl);
      } catch (error) {
        console.warn('Errors in Promise.all: ', error);
        setAllFiles([]);
      }

      setIsLoading(false);

      //return await Promise.all(withBlobs);
      return;
    } catch (error) {
      console.warn('S3 Get Image Error: ', error);
      setIsLoading(false);
    }
  };

  const handleUploadImageClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUploadMenu = () => {
    setAnchorEl(null);
  };

  const handleFileUpload = async (event: any, imageType: keyof typeof orderImageTypes) => {
    setIsLoading(true);
    handleCloseUploadMenu();
    let file;
    if (event.target.files) {
      // Regular file input upload
      file = event.target.files[0];
    } else if (event.dataTransfer.files) {
      // Drag-and-drop file upload
      file = event.dataTransfer.files[0];
    }

    if (!file) return;

    const folderName = 'order' + (order.orderNumber?.toString() ?? '') + '/';
    const ext = file.name.split('.').pop();
    const key = `${folderName}/${uuidv4()}.${ext}`;

    try {
      const s3Object = await client.upload().adminPortal.image.upload({ file, key, imageType, s3Bucket, s3Region });
      await client.order().adminPortal.addImageToOrder(order.id, s3Object);

      // TODO update order with s3Object
      getImages();
      setIsLoading(false);
    } catch (error) {
      console.warn('uploadS3FilesToFolder Error: ', error);
      setIsLoading(false);
    }
  };

  const deleteFileHandler = async (
    file: S3ItemReference & {
      blob?: Blob;
      href: string;
    },
  ) => {
    try {
      setIsLoading(true);
      const payload = order.images.find((item: S3ItemReference) => item.key === file.key);

      if (!payload) return;

      const res = await client.order().adminPortal.deleteOrderImage(order.id, payload);

      if (res.status === 200) {
        setIsLoading(false);
        const newArray = allFiles.filter((item: any) => item.key !== file.key);
        setAllFiles(newArray);
        return;
      }
    } catch (error) {
      setIsLoading(false);
      console.warn('error: ', error);
      console.warn('deleteS3FileFromFolder Error: ', error);
    }
  };

  const handleRotate = (fileName: string) => {
    if (rotate.fileName === fileName) {
      const newDeg = rotate.deg >= 360 ? 0 : rotate.deg + 90;
      setRotate({ fileName: fileName, deg: newDeg });
    } else {
      setRotate({ fileName, deg: 90 });
    }
  };

  const zoomHandler = (fileName: string) => {
    if (zoomFile === fileName) {
      setZoomFile(null);
    } else {
      setZoomFile(fileName);
    }
  };

  //////////////////////////////////////////////
  // RENDER SECTION
  //////////////////////////////////////////////

  const body = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
          <div style={{ marginTop: 17, display: 'flex', overflow: 'auto' }}>
            {allFiles.map((file: any, index) => (
              <div
                key={file?.blob?.name + index || index}
                style={{
                  position: 'relative',
                  overflow: 'auto',
                  minHeight: 505,
                  minWidth: 505,
                  maxHeight: 505,
                  maxWidth: 505,
                  marginRight: allFiles.length - 1 !== index ? 20 : 0,
                }}
              >
                <TrashIcon
                  className="h-6 w-6 absolute top-2 z-10 bg-black opacity-70 rounded text-white left-2"
                  onClick={() => deleteFileHandler(file)}
                />
                <a
                  href={file.localUrl}
                  download
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: 50,
                    height: 24,
                    width: 24,
                    zIndex: 3,
                  }}
                >
                  <ArrowDownTrayIcon className="absolute z-10 bg-black opacity-70 rounded text-white top-0 h-6 w-6" />
                </a>
                <ArrowPathRoundedSquareIcon
                  className="absolute top-2 z-10 bg-black opacity-70 rounded text-white right-2 h-6 w-6"
                  onClick={() => handleRotate(file.blob.name)}
                />
                {file?.blob?.type === 'application/pdf' ? (
                  <object style={{ width: '100%', height: '100%' }} data={file.localUrl} type="application/pdf">
                    <param name="width" value="100%" />
                    test
                  </object>
                ) : file?.blob?.name ? (
                  <img
                    src={file.localUrl}
                    style={{
                      cursor: zoomFile === file?.blob?.name ? 'zoom-out' : 'zoom-in',
                      height: zoomFile === file.blob.name ? 600 : 300,
                      width: zoomFile === file.blob.name ? 600 : 300,
                      transform: rotate.fileName === file.blob.name ? `rotate(${rotate.deg}deg)` : 'rotate(0deg)',
                    }}
                    alt="Error With SRC"
                    onClick={() => zoomHandler(file.blob.name)}
                  />
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>
          {isLoading && (
            <div className="w-full flex items-center justify-center">
              <Loading />
            </div>
          )}
        </div>
        {/* File Drop Zone */}

        {showUploadDropZones && (
          <div className="grid grid-cols-2 gap-2 mt-2">
            <FileDropZone
              handleFileUpload={(event) => handleFileUpload(event, 'haulerPayable')}
              text="Invoice from Hauler"
            />
            <FileDropZone
              handleFileUpload={(event) => handleFileUpload(event, 'haulerPayable')}
              text="Dump Ticket from Hauler"
            />
          </div>
        )}
        <div style={{ display: 'flex', marginTop: 17, marginBottom: 22 }}>
          <div
            style={{
              ...styles.uploadBox,
              backgroundColor: isLoading ? 'rgba(0, 0, 0, 0.28)' : '#F8F7FC',
              color: isLoading ? 'rgba(0, 0, 0, 0.68)' : '#FE3058',
            }}
            onClick={handleUploadImageClick}
          >
            <PhotoIcon className={`h-6 w-6 mr-2 ${isLoading ? 'text-black opacity-70' : 'text-[#FE3058]'}`} />
            <div>Upload image</div>
          </div>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseUploadMenu}>
            {Object.entries(orderImageTypes).map((item) => (
              <MenuItem key={item[0]}>
                {item[1]}
                <input
                  className="blankFileInput"
                  type="file"
                  onChange={(event) => handleFileUpload(event, item[0] as keyof typeof orderImageTypes)}
                  disabled={isLoading}
                />
              </MenuItem>
            ))}
          </Menu>
        </div>
      </>
    );
  };

  if (inline) return <div>{body()}</div>;

  return (
    <Dialog open={open} className="max-w-screen-2xl" onClose={onCancel} styledTitle="Order Images" showX>
      {body()}
    </Dialog>
  );
};

const styles: any = {
  uploadBox: {
    flex: 1,
    border: 'solid 1px #979797',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 10,
    position: 'relative',
    cursor: 'pointer',
  },
  imgIcon: {
    color: '#FFFFFF',
    position: 'absolute',
    top: 10,
    zIndex: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.68)',
    borderRadius: 4,
  },
};

OrderImageHandler.defaultProps = defaultProps;

export default OrderImageHandler;
