import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Order } from '@alliance-disposal/transport-types';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns';
import { UserContext } from '../contexts';
import { needsAttentionStrings } from '../utils/shared-types';

const dateFormat = 'EEE MM/dd/yy hh:mm';
const partyOptions = ['Sourgum', 'Hauler', 'Customer'];

const PartyMenuItems = partyOptions.map((item) => (
  <MenuItem key={`party-${item}`} value={item}>
    {item}
  </MenuItem>
));

type Props = {
  order: Order.AllianceOrderTransport;
  hideAddButton?: boolean;
  collapsible?: boolean;
};

const defaultProps = {
  hideAddButton: false,
};

const InternalOrderNotes = ({ order, hideAddButton, collapsible }: Props) => {
  const client = useWaysteClient();
  const { userIDToNameMap, activeUserIDToNameMap } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [needsAttention, setNeedsAttention] = useState(order.needsAttention);
  const [isIssue, setIsIssue] = useState(false);
  const [issueParty, setIssueParty] = useState('');
  const [responsibleUserID, setResponsibleUserID] = useState('');
  const [monetaryLoss, setMonetaryLoss] = useState<string | number>('');
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [order]);

  const handleClose = () => {
    setOpen(false);
    setNewNote('');
    setShowAdd(false);
    setIsIssue(false);
    setIssueParty('');
    setResponsibleUserID('');
    setMonetaryLoss('');
  };

  const handleIsIssueToggle = (event: any) => {
    setIsIssue(event.target.checked);
    setIssueParty('');
    setResponsibleUserID('');
    setMonetaryLoss('');
  };

  const handleSetIssueParty = (event: any) => {
    setIssueParty(event.target.value);
    if (event.target.value !== 'Sourgum') {
      setResponsibleUserID('');
      setMonetaryLoss('');
    }
  };

  const handleNewNoteChange = (event: any) => {
    setNewNote(event.target.value);
  };

  const handleCollapseToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleSubmit = async () => {
    if (needsAttention !== order.needsAttention) {
      const needsAttentionItem = {
        note: needsAttention ? needsAttentionStrings[0] : needsAttentionStrings[1],
        isIssue: false,
        issueParty: '',
      };

      await client.order().adminPortal.update(order.id, { needsAttention });

      await client.order().adminPortal.notes.create(order.id, needsAttentionItem);

      if (!needsAttention && !newNote) {
        handleClose();
        return;
      }
    }
    const newItem: Order.AllianceOrderInternalNoteInput = {
      note: newNote,
      isIssue: isIssue,
      issueParty: isIssue ? issueParty : '',
      responsibleUserID: responsibleUserID || undefined,
      monetaryLoss: Number(monetaryLoss) || undefined,
    };

    try {
      await client.order().adminPortal.notes.create(order.id, newItem);
    } catch (error) {
      console.error('Error creating internal note', error);
    }

    handleClose();
  };

  const handleNeedsAttentionChange = (value: any) => {
    if (value !== order.needsAttention) {
      setNeedsAttention(value);
    } else {
      setNeedsAttention(order.needsAttention);
    }
  };

  if (collapsed)
    return (
      <button className="btn-primary-text-only" onClick={handleCollapseToggle} type="button">
        Expand Internal Notes
      </button>
    );

  return (
    <>
      {collapsible ? (
        <button className="btn-primary-text-only" onClick={handleCollapseToggle} type="button">
          Hide Internal Notes
        </button>
      ) : null}
      <div>
        {order.internalNotes.map((note, index) => (
          <div
            key={`${note.timestamp}`}
            style={{
              marginBottom: index !== order.internalNotes.length - 1 ? 7 : 0,
            }}
          >
            <div style={{ marginBottom: 3 }}>
              {note.timestamp ? format(new Date(note.timestamp), dateFormat) : null}
              {' - '}
              {userIDToNameMap[note.userID]}
            </div>
            {note.isIssue ? (
              <div style={{ marginBottom: 3 }}>
                <span style={{ fontWeight: 'bold', color: 'red' }}>Issue</span>
                {' - '}
                {note.issueParty}
                {Boolean(note.responsibleUserID)
                  ? ` ${userIDToNameMap[note?.responsibleUserID ? note?.responsibleUserID : '']} $${
                      note.monetaryLoss || '0'
                    }`
                  : null}
              </div>
            ) : null}
            <span
              style={{
                color: needsAttentionStrings.includes(note.note) ? '#CC5500' : undefined,
              }}
            >
              {note.note}
            </span>
          </div>
        ))}
        {hideAddButton ? null : (
          <button className="btn-primary-text-only py-1 px-2.5" onClick={() => setOpen(true)}>
            Add New Note
          </button>
        )}
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>Internal Order Notes</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 180 }}>Date</TableCell>
                  <TableCell style={{ width: 200 }}>User</TableCell>
                  <TableCell>Note</TableCell>
                  <TableCell style={{ width: 100 }}>Is Issue</TableCell>
                  <TableCell style={{ width: 200 }}>Issue Party</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.internalNotes.map((note) => (
                  <TableRow key={`${note.timestamp}`}>
                    <TableCell style={{ width: 180 }}>
                      {note.timestamp ? format(new Date(note.timestamp), dateFormat) : null}
                    </TableCell>
                    <TableCell style={{ width: 200 }}>{userIDToNameMap[note.userID]}</TableCell>
                    <TableCell>{note.note}</TableCell>
                    <TableCell style={{ width: 100 }}>{note.isIssue ? 'Yes' : ''}</TableCell>
                    <TableCell style={{ width: 200 }}>
                      {note.issueParty}
                      {Boolean(note.responsibleUserID)
                        ? ` ${userIDToNameMap[note?.responsibleUserID ? note?.responsibleUserID : '']} $${
                            note.monetaryLoss || '0'
                          }`
                        : null}
                    </TableCell>
                  </TableRow>
                ))}
                {showAdd ? (
                  <>
                    <TableRow>
                      <TableCell style={{ borderBottom: 'none', textAlign: 'center' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={needsAttention}
                              onChange={(event) => handleNeedsAttentionChange(event.target.checked)}
                            />
                          }
                          label="Needs Attention"
                          labelPlacement="top"
                        />
                      </TableCell>
                      <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
                        <TextField
                          label="New Note"
                          multiline
                          value={newNote}
                          onChange={handleNewNoteChange}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell style={{ width: 100, borderBottom: 'none' }}>
                        <Checkbox checked={isIssue} onChange={handleIsIssueToggle} />
                      </TableCell>
                      <TableCell style={{ width: 200, borderBottom: 'none' }}>
                        {isIssue ? (
                          <>
                            <FormControl style={{ width: '100%' }}>
                              <InputLabel id="internal-order-notes-issue-party-label">Issue Party</InputLabel>
                              <Select
                                labelId="internal-order-notes-issue-party-label"
                                value={issueParty}
                                onChange={handleSetIssueParty}
                              >
                                {PartyMenuItems}
                              </Select>
                            </FormControl>
                            <TextField
                              label="Monetary Loss"
                              style={{ marginTop: 15 }}
                              value={monetaryLoss}
                              onChange={(event) => setMonetaryLoss(+event.target.value)}
                              fullWidth
                              type="number"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                            />
                          </>
                        ) : null}
                        {issueParty === 'Sourgum' ? (
                          <FormControl style={{ width: '100%' }} margin="normal">
                            <InputLabel id="internal-order-notes-issue-user-label">Responsible Agent</InputLabel>
                            <Select
                              labelId="internal-order-notes-issue-user-label"
                              value={responsibleUserID}
                              onChange={(event: any) => setResponsibleUserID(event.target.value)}
                            >
                              {Object.entries(activeUserIDToNameMap).map((item: any) => (
                                <MenuItem key={`agent-${item[0]}`} value={item[0]}>
                                  {item[1]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : null}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={5} style={{ textAlign: 'right', paddingTop: 0 }}>
                        <button
                          className="btn-primary-text-only"
                          onClick={handleSubmit}
                          disabled={
                            (needsAttention === order.needsAttention && !newNote) ||
                            (needsAttention !== order.needsAttention && needsAttention && !newNote) ||
                            (isIssue && !issueParty) ||
                            (issueParty === 'Sourgum' && !responsibleUserID)
                          }
                        >
                          Save
                        </button>
                      </TableCell>
                    </TableRow>
                  </>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
          {loading ? (
            <CircularProgress />
          ) : !showAdd ? (
            <button className="btn-primary mt-4" onClick={() => setShowAdd(true)}>
              Add New Note
            </button>
          ) : null}
        </DialogContent>
        <DialogActions>
          <button className="btn-dark-grey-outlined" onClick={handleClose}>
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

InternalOrderNotes.defaultProps = defaultProps;

export default InternalOrderNotes;
