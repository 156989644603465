import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Profile } from '@alliance-disposal/transport-types';
import { PasswordValidation, generatePassword } from '@wayste/sour-components';
import { Button, Dialog, Loading, TextField } from '@wayste/sour-ui';
import { useFlash } from '../../hooks/useFlash';

interface UpdatePasswordDialogProps {
  open: boolean;
  onClose: () => void;
  customer: Customer.AllianceCustomerTransport;
  contact: Customer.AllianceCustomerContactTransport;
}
const UpdatePasswordDialog = ({ open, onClose, customer, contact }: UpdatePasswordDialogProps) => {
  const client = useWaysteClient();
  const { showFlash } = useFlash();
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState<Profile.ProfileTransport | null>(null);
  const [password, setPassword] = useState<string>('');
  const [passwordVerify, setPasswordVerify] = useState<string>('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const fetchProfile = async () => {
    setIsLoading(true);
    try {
      const profiles = await client.profile().fetchProfilesByCustomerId(customer.id);
      const profile = profiles.find((profile) => profile.email === contact.email);
      console.log('profile', profile);
      if (!profile) {
        showFlash('Profile not found', 'warning');
        return;
      }
      setProfile(profile);
    } catch (error) {
      showFlash('Error fetching profiles', 'warning');
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    if (!isPasswordValid) {
      showFlash('Password does not meet requirements', 'warning');
      return;
    }
    if (password !== passwordVerify) {
      showFlash('Passwords do not match', 'warning');
      return;
    }
    if (!profile) {
      showFlash('Profile not found', 'warning');
      return;
    }
    try {
      await client.profile().adminResetPassword(profile.username, password);
      showFlash('Password updated', 'success');
    } catch (error) {
      showFlash('Error updating password', 'warning');
      console.warn(error);
    } finally {
      setPassword('');
      setPasswordVerify('');
      onClose();
    }
  };

  const autoGeneratePassword = () => {
    const randomPassword = generatePassword();
    return () => {
      setPassword(randomPassword);
      setPasswordVerify(randomPassword);
      setShowPassword(true);
    };
  };

  useEffect(() => {
    if (contact) {
      fetchProfile();
    }
  }, [contact]);

  return (
    <Dialog open={open} onClose={onClose} styledTitle="Update Password">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col space-y-3 md:space-y-5">
          <PasswordValidation
            password={password || ''}
            confirmPassword={passwordVerify}
            onValidation={(isValid) => setIsPasswordValid(isValid)}
          />
          <Button className="btn-dark-grey-outlined text-base" loading={isLoading} onClick={autoGeneratePassword()}>
            Auto Generate Password
          </Button>
          <Button
            className="btn-dark-grey-outlined text-base"
            loading={isLoading}
            onClick={() => setShowPassword(!showPassword)}
          >
            Show Password
          </Button>
          <TextField
            required
            inputProps={{
              value: password,
              onChange: (e) => setPassword(e.target.value),
              name: 'password',
              id: 'password',
            }}
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter new password"
          />
          <TextField
            required
            inputProps={{
              value: passwordVerify,
              onChange: (e) => setPasswordVerify(e.target.value),
              name: 'passwordVerify',
              id: 'passwordVerify',
            }}
            type={showPassword ? 'text' : 'password'}
            placeholder="Confirm new password"
          />
          <Button
            disabled={!isPasswordValid || !password || !passwordVerify || password !== passwordVerify}
            className="btn-primary text-base py-3"
            onClick={onSubmit}
            loading={isLoading}
          >
            Reset Password
          </Button>
        </div>
      )}
    </Dialog>
  );
};
export default UpdatePasswordDialog;
