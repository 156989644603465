import { useEffect, useRef, useState } from 'react';
import { useAlertDialog } from '../../contexts';

function useErrorHandlingSubscription(
  subscription: any,
  category: string,
  variables: any[] = [],
  loadingInput = false,
) {
  const { getAlert } = useAlertDialog();
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState<any>(null);
  const [subOrderErrorCount, setSubOrderErrorCount] = useState(0);
  const [subscribed, setSubscribed] = useState(false);
  const subErrorCount = useRef(0);
  const subLoadingRef = useRef(false);

  const handleSubscription = () => {
    console.log('initial');
    const unsubscribe = subscription(
      ...variables,
      (response: any) => {
        if (!subscribed) setSubscribed(true);
        if (response.status === 'error') {
          const newErrorCount = ++subErrorCount.current;
          subErrorCount.current = newErrorCount;
          setSubOrderErrorCount(newErrorCount);
        } else {
          if (subOrderErrorCount !== 0) {
            subErrorCount.current = 0;
            setSubOrderErrorCount(0);
          }
          setSubscriptionData((oldData: any) =>
            JSON.stringify(oldData) === JSON.stringify(response.data) ? oldData : response.data,
          );
        }
        if (subLoadingRef.current) setSubscriptionLoading(false);
      },
      (error: any) => {
        console.warn(
          `useErrorHandlingSubscription handleSubscription error for ${category}: `,
          error,
          ' variables ',
          ...variables,
        );
        setSubscriptionData(null);
        setSubscriptionLoading(false);
        subLoadingRef.current = false;
      },
    );
    return unsubscribe;
  };

  useEffect(() => {
    console.log(loadingInput);
    if (loadingInput) return;
    let unsubscribe: any;
    if (subOrderErrorCount < 5) {
      unsubscribe = handleSubscription();
    } else {
      getAlert({
        message: `An error occurred getting your ${category}. Please get an AAP Dev.`,
        severity: 'error',
      });
    }

    return () => {
      console.log(unsubscribe);
      if (unsubscribe?.unsubscribe) {
        console.log('unsub hit');
        const response = unsubscribe.unsubscribe();
        console.log(response);
        setSubscribed(false);
      }
    };
  }, [subOrderErrorCount, ...variables]);

  return { subscriptionLoading, subscriptionData, subscribed };
}

export default useErrorHandlingSubscription;
