import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { V1 } from '@alliance-disposal/pricing';
import { Customer, Invoice, Order } from '@alliance-disposal/transport-types';
import { Pricing } from '@alliance-disposal/transport-types';
import { Checkbox, DatePicker, Loading, RadioButton, Toggle } from '@wayste/sour-ui';
import {
  extractSizesFromPricingData,
  formatServiceAddress,
  formatUSD,
  getDateFormat,
  getDispatchEmailsString,
  roundToClosestAllowedSize,
} from '@wayste/utils';
import Grid from '@mui/material/Grid';
import { addDays, format, subDays } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import { sendDynamicEmail } from '../../axios/sendgrid';
import { sendEmail } from '../../axios/ses';
import { getPublicLinkShortCode } from '../../axios/wayste-backend';
import { UIContext } from '../../contexts';
import { useAuthToken } from '../../hooks/authhook';
import {
  OrderStatus,
  aggregateMaterialKeys,
  createProhibitedItemList,
  generateOrderFirstInvoice,
  getCustomerCCAddresses,
  getOrderCurrentSwitch,
  materials,
  noReplyEmail,
  paymentMethodsEnums,
  paymentTermsEnums,
  priceTypes,
  priceTypesEnums,
} from '../../utils';
import { fieldNameMap } from '../ChangeRequestDialog/ChangeRequestResponse';
import ChargeCard2 from '../ChargeCard/ChargeCard2';
import Dialog from '../Dialog';
import InternalOrderNotes from '../InternalOrderNotes';
import OrderPricingFields from '../OrderPricingFields';

const OrderSchema = Yup.object().shape({
  expectedPickupDate: Yup.date().required('A pickup date is required'),
  rentalEndDate: Yup.date().required('A rental end date is required'),
  sharedPickupNotes: Yup.string(),
  dnrToggle: Yup.bool(),
  sendCustomerEmail: Yup.bool(),
  sendHaulerEmail: Yup.bool(),
  newNoteDel: Yup.string().when('dnrToggle', {
    is: true,
    then: Yup.string().required('Delivery notes are required'),
  }),
  sharedPickupNotesPrivate: Yup.string(),
  newHaulerOnlyNote: Yup.string(),
  samePricing: Yup.bool(),
  serviceLocation: Yup.object().shape({
    county: Yup.string().required('County is required'),
  }),
  material: Yup.string().required('Material is required'),
  priceType: Yup.string().required('Price type is required'),
  expectedSize: Yup.string().required('Size is required'),
  weightLimit: Yup.number()
    .when(['dnrToggle', 'priceType'], {
      is: (dnrToggle, priceType) => dnrToggle && priceType === priceTypesEnums.ton,
      then: Yup.number().required('Ton limit is required'),
    })
    .nullable(),
  adjustedRentalPeriod: Yup.number().required('Rental period is required'),
  rentExtensionFeeDollars: Yup.number().required('Rental extension fee is required'),
  tax: Yup.boolean(),
  taxRate: Yup.number(),
  cc: Yup.boolean(),
  price: Yup.number().required('Quoted Price is required'),
  oldPrice: Yup.number().required('Quoted Price is required'),
  overageDollars: Yup.number().when(['dnrToggle', 'priceType'], {
    is: (dnrToggle, priceType) => dnrToggle && priceType === priceTypesEnums.ton,
    then: Yup.number().required('Overage is required'),
    otherwise: Yup.number().nullable(),
  }),
  paymentInfo: Yup.object().nullable(),
});

interface Props {
  open: boolean;
  order: Order.AllianceOrderTransport;
  onCancel: () => void;
  user: any;
  changeRequest?: any;
  onChangeRequestUpdated?: any;
}

const OrderReadyPickUp = ({ open, order, onCancel, user, changeRequest, onChangeRequestUpdated }: Props) => {
  const waysteClient = useWaysteClient();
  const { token } = useAuthToken();
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const [receivables, setReceivables] = useState<Invoice.ReceivableTransport[]>([]);
  const [zonePricing, setZonePricing] = useState<Pricing.PricingTransport | null>(null);
  const [zonePricingHauler, setZonePricingHauler] = useState<Pricing.PricingTransport | null>(null);
  const [haulerPricing, setHaulerPricing] = useState<Pricing.PricingTransport | null>(null);
  const [customerInfo, setCustomerInfo] = useState<Customer.AllianceCustomerTransport | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(true);
  const [currentSwitch, setCurrentSwitch] = useState<any>(null);
  const [swapConstraints, setSwapConstraints] = useState({
    size: {},
    material: {},
  });

  const pdfToBase64 = (blob: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const handleGetCustomer = async (id: string) => {
    try {
      const customerResponse = await waysteClient.customer().adminPortal.fetch(id);
      const customer = customerResponse;
      if (customer) {
        setCustomerInfo(customer);
      }

      setIsLoading(false);
    } catch (error) {
      console.warn('handleGetCustomer OrderReadyPickUp Error: ', error);
      showFlash('An Error Occurred fetching customer information.', 'warning');
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setCurrentSwitch(getOrderCurrentSwitch(order));
    handleGetCustomer(order.allianceCustomerID);
  }, []);

  useEffect(() => {
    const fetchReceivables = async () => {
      try {
        const data = await client.invoice().adminPortal.receivable.query({
          orderID: order.id,
        });
        setReceivables(data);
      } catch (error) {
        console.error('error: ', error);
      } finally {
        setIsLoadingInvoices(false);
      }
    };

    fetchReceivables();
  }, [order]);

  const handleSwapConstraints = (haulerPricing: Pricing.PricingTransport) => {
    const materials: any[] = [];
    haulerPricing.pricingData.forEach((pricing: any) => {
      materials.push(pricing.material);
    });

    const pairs: any = {};
    materials.forEach((material) => {
      const haulerSizes = extractSizesFromPricingData(haulerPricing.pricingData, material);
      const allowedSizes: any[] = [];
      haulerSizes.forEach((size) => {
        const acceptableSize = roundToClosestAllowedSize(size, haulerSizes);
        if (acceptableSize) {
          allowedSizes.push(parseInt(acceptableSize));
        }
      });
      pairs[material] = allowedSizes;
    });

    const constraints = {
      material: materials,
      size: pairs,
    };

    setSwapConstraints(constraints);
    return constraints;
  };

  useEffect(() => {
    (async () => {
      const results = await client.pricing().adminPortal.location.query(
        {
          ...order.serviceLocation.coordinates,
          zip: order.serviceLocation.address.zip,
          state: order.serviceLocation.address.state,
        },
        order.allianceCustomerID,
      );

      const HaulerId = order.haulerID;
      const haulerPricing = results.find((pricing: any) => pricing.haulerID === HaulerId);

      if (haulerPricing) {
        setHaulerPricing(haulerPricing);
        handleSwapConstraints(haulerPricing);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const results = await client.pricing().adminPortal.location.query({
        ...order.serviceLocation.coordinates,
        zip: order.serviceLocation.address.zip,
        state: order.serviceLocation.address.state,
      });
      const HaulerId = order.haulerID;
      const haulerPricing = results.find((pricing: Pricing.PricingTransport) => pricing.haulerID === HaulerId);
      if (haulerPricing) {
        setZonePricingHauler(haulerPricing);
        handleSwapConstraints(haulerPricing);
      }
    })();
  }, []);

  if (isLoadingInvoices) {
    return <Loading />;
  }

  const prepareFormData = (data: any) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData.expectedPickupDate = getDateFormat(newData.expectedPickupDate)
      ? new Date(getDateFormat(newData.expectedPickupDate))
      : '';
    newData.rentalEndDate = newData.rentalEndDate ? new Date(newData.rentalEndDate) : '';
    newData.adjustedRentalPeriod = data.adjustedRentalPeriod.value;
    newData.sendHaulerEmail = true;
    newData.sendCustomerEmail = true;
    newData.dnrToggle = false;
    newData.issueInvoice = 'NOW';
    newData.samePricing = true;
    newData.price = 0;
    newData.oldPrice =
      receivables?.find((invoice: Invoice.ReceivableTransport) =>
        invoice.invoiceDetails.lineItems.find(
          (lineItem: Invoice.LineItemTransport) => lineItem?.itemName === 'QP-Haul',
        ),
      )?.invoiceDetails.totalDollars || 0;
    newData.tax = receivables[0].invoiceDetails.taxAmountDollars > 0 ? true : false;
    newData.cc = receivables[0].invoiceDetails.lineItems.find((lineItem: any) => lineItem.itemName === 'CC Fee')
      ? true
      : false;
    newData.paymentInfo = null;
    newData.otherLineItems = [];
    newData.sharedPickupNotesPrivate = newData.sharedPickupNotesPrivate || '';
    newData.newHaulerOnlyNote = '';
    newData.weightLimit = data.weightLimit?.value || 0;
    newData.expectedSize = data.expectedSize.size;

    return newData;
  };

  const handleGetPricing = async (location: { lat: number; lng: number; zip: string; state: string }, values: any) => {
    try {
      setIsLoading(true);

      try {
        const results = await waysteClient.pricing().adminPortal.location.query(location, order.allianceCustomerID);

        const haulerPricing = results.filter((item: any) => item.haulerID === values.haulerID && item.public === false);

        if (!haulerPricing.length) {
          showFlash('No pricing found for this hauler', 'warning');
        }

        if (haulerPricing) {
          setHaulerPricing(haulerPricing[0]);
        }

        const publicPricing = results.filter((item: any) => item.public === true);

        if (publicPricing.length === 1) {
          setZonePricing(publicPricing[0]);
          return publicPricing[0];
        } else {
          if (publicPricing.length > 1) alert('Get an AAP Dev pricing overlap');
          setZonePricing(null);
        }
      } catch (error) {
        showFlash('No pricing found for this hauler', 'warning');
      }
    } catch (error) {
      console.warn('handleGetPricing Error: ', error);
      alert('An error occurred, get AAP Dev, touch nothing');
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetPricingReturn = async (location: { lat: number; lng: number; zip: string; state: string }) => {
    try {
      const results = await waysteClient.pricing().adminPortal.location.query(location, order.allianceCustomerID);

      if (!results) {
        showFlash('No pricing found for this hauler', 'warning');
      }

      const publicPricing = results.filter((item: any) => item.public === true);

      if (publicPricing.length === 1) {
        return publicPricing[0];
      } else {
        if (publicPricing.length > 1) alert('Get an AAP Dev pricing overlap');
        return null;
      }
    } catch (error) {
      console.warn('handleGetPricing Error: ', error);
      alert('An error occurred, get AAP Dev, touch nothing');
      return null;
    }
  };

  const handleGetHauler = async (id: string) => {
    try {
      const haulerFound = await waysteClient.vendorService().fetchById(id);
      return haulerFound.data;
    } catch (error) {
      console.warn('handleGetHauler Error: ', error);
      showFlash('An Error Occurred fetching vendor information.', 'warning');
    }
  };

  const generatePaymentLink = async (values: any, receivableID: string) => {
    if (
      !values.paymentInfo &&
      (customerInfo?.defaultPaymentSettings?.paymentMethod || order.paymentMethod) !== paymentMethodsEnums.check
    ) {
      const response = await getPublicLinkShortCode(receivableID, token);
      if (response.status === 'error') return null;
      return response?.data?.key;
    }
    return null;
  };

  const createWeightLimitString = (values: Order.AllianceOrderTransport) => {
    if (values.weightLimit?.value || values.weightLimit?.value === 0) {
      return `${values.weightLimit.value} tons`;
    } else if (aggregateMaterialKeys.indexOf(values.material) > -1 && +values.expectedSize >= 20) {
      return 'You may only fill the dumpster 3/4 of the way. If filled to the top we may refuse pickup or charge an overfilled fee.';
    } else {
      return 'N/A';
    }
  };

  const createEmail = async (
    values: any,
    haulerEmail: any,
    newOrderNumber: any,
    newOrderID: string,
    receivableID: string,
  ) => {
    let prohibitedItemsList = null;
    if (!zonePricing) {
      const pricingResponse = await handleGetPricingReturn({
        ...order.serviceLocation.coordinates,
        zip: order.serviceLocation.address.zip,
        state: order.serviceLocation.address.state,
      });
      prohibitedItemsList = pricingResponse?.prohibitedItems;
    } else {
      prohibitedItemsList = zonePricing?.prohibitedItems;
    }

    // FOR HAULER EMAILS
    const haulerEmailItems = [
      { label: 'Pickup Order Number', value: order.orderNumber },
      { label: 'Service Address', value: formatServiceAddress(order.serviceLocation.address) },
      {
        label: `${values.dnrToggle ? 'Dump and Return' : 'Pickup'} Date`,
        value: format(new Date(getDateFormat(values.expectedPickupDate)), 'EEEE MM/dd/yy'),
      },
      {
        label: `${values.dnrToggle ? 'Pickup ' : ''}Size`,
        value: `${order.expectedSize.size} yard dumpster`,
      },
      {
        label: `${values.dnrToggle ? 'Pickup ' : ''}Material`,
        value: materials[order.material as keyof typeof materials],
      },
      { label: 'County', value: order.serviceLocation.county },
      {
        label: 'Pickup Instructions',
        value: `${values.sharedPickupNotes}. If there are any issues call (732) 366-9355 while still on site.`,
      },
      { label: 'New Delivery Order Number', value: newOrderNumber },
      {
        label: 'Delivery Size',
        value: values.dnrToggle ? `${values.expectedSize} yard dumpster` : '',
      },
      {
        label: 'Delivery Instructions',
        value: values.dnrToggle ? values.newNoteDel : '',
      },
      {
        label: 'Delivery Material',
        value: values.dnrToggle ? materials[values.material as keyof typeof materials] : '',
      },
    ];
    if (values.sharedPickupNotesPrivate || values.newHaulerOnlyNote) {
      haulerEmailItems.push({
        label: 'Additional Notes',
        value: values.sharedPickupNotesPrivate + ' ' + values.newHaulerOnlyNote,
      });
    }
    const confirmationUrl = 'https://app.wayste.com/update-alliance/';
    const confirmationId = newOrderID ? `id=${order.id}&switch=${newOrderID}` : `id=${order.id}`;
    const haulerEmailHtml = `
			<p>Sourgum Waste dumpster rental ${
        values.dnrToggle ? 'DUMP AND RETURN' : 'PICKUP FINAL REMOVAL'
      } confirmation details:</p>
			${haulerEmailItems
        .map((item) => {
          if (
            (!values.dnrToggle && item.label === 'Delivery Size') ||
            (!values.dnrToggle && item.label === 'Delivery Instructions') ||
            (!values.dnrToggle && item.label === 'Delivery Material') ||
            (!values.dnrToggle && item.label === 'New Delivery Order Number')
          )
            return '';
          return `<p><b>${item.label}:</b> ${item.value}</p>`;
        })
        .join('')}
			<p style="margin: 20px 0">
				<a href="${confirmationUrl}?category=pickup_confirmed&${confirmationId}" style="text-decoration: none;color: white;background: #007AFF;padding: 7px 15px;border-radius: 4px;box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);">Click Here to Confirm ${
      newOrderID ? 'Switch' : 'Pickup'
    }</a>
			</p>
			<p>Please click above on the Confirm ${newOrderID ? 'Switch' : 'Pickup'} button if you are accepting this order.</p>
			<p>If the button does not work, please go to ${confirmationUrl}?category=pickup_confirmed&${confirmationId} or reply Confirmed to this email.</p>
		`;

    const haulerEmailText = `Sourgum Waste dumpster rental ${
      values.dnrToggle ? 'DUMP AND RETURN' : 'PICKUP FINAL REMOVAL'
    } confirmation details:
		${haulerEmailItems
      .map((item) => {
        if (
          (!values.dnrToggle && item.label === 'Delivery Size') ||
          (!values.dnrToggle && item.label === 'Delivery Instructions') ||
          (!values.dnrToggle && item.label === 'Delivery Material') ||
          (!values.dnrToggle && item.label === 'New Delivery Order Number')
        )
          return '';
        return `\n\n${item.label}: ${item.value}`;
      })
      .join('')}
		Please go to ${confirmationUrl}?category=pickup_confirmed&${confirmationId} or reply Confirmed to confirm this ${
      newOrderID ? 'Switch' : 'Pickup'
    }.`;

    // FOR CUSTOMER EMAILS
    let customerEmailData: any = {
      emailTemplate: 'RO_READY_PICKUP',
      toEmail: customerInfo?.contacts?.find((contact) => contact.primaryContact)?.email,
      ccEmails: getCustomerCCAddresses(customerInfo as any, order.serviceLocation.address).serviceCCAddresses,
      first_name: customerInfo?.contacts?.find((contact) => contact.primaryContact)?.firstName,
      csa_name: user.firstName,
      prohibited_items_list: createProhibitedItemList(values.material, prohibitedItemsList?.join(', ')),
      order_number: order.orderNumber,
      service_location: formatServiceAddress(order.serviceLocation.address),
      pickup_date: format(new Date(getDateFormat(values.expectedPickupDate)), 'EEEE MM/dd/yy'),
      pickup_notes: values.sharedPickupNotes,
    };

    if (values.dnrToggle) {
      const payment_link = await generatePaymentLink(values, receivableID);
      customerEmailData = {
        ...customerEmailData,
        emailTemplate: 'RO_READY_PICKUP_SWAP',
        delivery_notes: `${values.newNoteDel}${
          values.sharedPickupNotes ? `. On pickup ${values.sharedPickupNotes}` : ''
        }`,
        dumpster_size: values.expectedSize,
        material: materials[values.material as keyof typeof materials],
        weight_limit: createWeightLimitString(values),
        order_total: formatUSD(getOrderTotal(values), true),
        rental_period: values.adjustedRentalPeriod,
        overage_fee: values.overageDollars || null,
        price_type: values.priceType,
        rental_extension_fee: formatUSD(values.rentExtensionFeeDollars, true),
        payment_link,
      };
    }

    const haulerEmailData =
      values.sendHaulerEmail && haulerEmail
        ? {
            htmlMessage: haulerEmailHtml,
            textMessage: haulerEmailText,
            toAddress: haulerEmail,
            subject: `Sourgum Waste Dumpster ${values.dnrToggle ? 'Swap' : 'Pickup Final Removal'} - ${
              order.orderNumber
            }`,
            bcc: noReplyEmail,
          }
        : null;

    if (!customerInfo || !customerInfo?.contacts?.find((contact) => contact.primaryContact)?.email) {
      console.warn('No customer email found');
      showFlash('No customer email found', 'warning');
    }

    return {
      hauler: haulerEmailData,
      customer: customerEmailData,
    };
  };

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    const orderUpdates = {
      expectedPickupDate: getDateFormat(values.expectedPickupDate),
      requestedPickupDate: getDateFormat(order.requestedPickupDate) || getDateFormat(values.expectedPickupDate),
      rentalEndDate: new Date(values.rentalEndDate),
      sharedPickupNotes: values.sharedPickupNotes,
      status: OrderStatus.READY_FULL_PICKUP,
      haulerConfirmedPickup: false,
      sharedPickupNotesPrivate: values.sharedPickupNotesPrivate,
    };
    let newOrder = null;
    let newInvoice = null;
    let newOrderID = null;

    if (values.dnrToggle) {
      if (customerInfo) {
        const paymentSetting = customerInfo?.defaultPaymentSettings.paymentTerm || order.paymentTerm;
        if (paymentSetting) {
          newInvoice = generateOrderFirstInvoice(
            values.price,
            values.priceType,
            values.weightLimit,
            values.expectedSize,
            values.dumpRateDollars / 100,
            values.tax,
            customerInfo?.taxExempt ? 0 : values.taxRate,
            values.cc,
            paymentSetting,
            values.material,
            values.otherLineItems,
            values.issueInvoice,
          );
        }
      }

      const orderCopy = JSON.parse(JSON.stringify(order));
      newOrder = {
        ...orderCopy,
        ...values,
        sharedPickupNotes: '',
        sharedDeliveryNotes: values.newNoteDel,
        sharedDeliveryNotesPrivate: values.newHaulerOnlyNote,
        expectedDeliveryDate: getDateFormat(values.expectedPickupDate),
        expectedPickupDate: null,
        rentalEndDate: null,
        id: null,
        order: null,
        status: OrderStatus.ASSIGNED,
        actualWeightDumped: null,
        extendingRental: false,
        needsAttention: false,
        haulerConfirmedDelivery: false,
        rentalPeriod: values.adjustedRentalPeriod,
        expectedSize: {
          size: values.expectedSize,
          unit: 'OPEN_TOP',
        },
        weightLimit: values.priceType === priceTypesEnums.ton ? +values.weightLimit : null,
        overageDollars: values.priceType === priceTypesEnums.ton ? +values.overageDollars : null,
        paymentMethod: customerInfo?.defaultPaymentSettings?.paymentMethod || order.paymentMethod,
        paymentTerm: customerInfo?.defaultPaymentSettings?.paymentTerm || order.paymentTerm,
      };
    }

    let newOrderNumber = null;
    let newOrderServiceLocation = undefined;
    if (newOrder) {
      // find pricing that matches the size and material
      const foundPricing = haulerPricing?.pricingData.find((pricing) => pricing.material === order.material);
      const pricing = foundPricing ? foundPricing : null;
      const sizePricing = pricing?.sizes
        ? pricing.sizes.find((item: any) => +item.size === +order.expectedSize.size)
        : null;

      if (!haulerPricing || !sizePricing || !pricing) {
        showFlash('No hauler pricing found is area. Payables will have to be manually entered.', 'warning');
      }

      const haulerId = haulerPricing?.haulerID || order.haulerID;

      if (!haulerId) {
        setIsLoading(false);
        showFlash('No hauler found for this order. Please check with engineering.', 'warning');
        return;
      }

      const hauler = await waysteClient.vendorService().fetchById(haulerId);

      const haulerPricingSnapshot: Order.HaulerPricingSnapshotTransport = {
        dump: Math.round(sizePricing?.dump || 0),
        over: Math.round(sizePricing?.over || 0),
        haul: Math.round(sizePricing?.haul || 0),
        size: Number(sizePricing?.size) || newOrder.expectedSize.size,
        tonLimit: { value: sizePricing?.tonLimit || 0, unit: 'TONS' },
        rentalPeriod: haulerPricing?.rentalPeriod ? haulerPricing?.rentalPeriod : { value: 0, unit: 'DAYS' },
        rentalExtensionFee: Math.round((haulerPricing?.rentExtensionFeeDollars || 0) * 100),
        priceType: pricing?.type || priceTypes.ton,
        paymentTerm: paymentTermsEnums.net30,
        paymentMethod: hauler?.data?.defaultPaymentMethod || paymentMethodsEnums.check,
      };

      newOrder.switchFromOrderID = order.id;
      newOrder.switchType = 'DUMP_AND_RETURN';
      newOrder.haulerPricingSnapshot = haulerPricingSnapshot;

      const customer = await waysteClient.customer().adminPortal.fetch(order.allianceCustomerID);

      const orderObj: Order.AllianceOrderCreateInput = {
        customerCompanyName: customer.companyName || undefined,
        vendorName: hauler.data.name,
        customerAccountNumber: customer?.customerNumber.toString() || undefined,
        customerName: (() => {
          const { firstName, lastName } =
            customer.contacts.find((contact: any) => contact.isPrimary) || customer.contacts[0];
          return `${firstName} ${lastName}`;
        })(),
        switchFromOrderID: newOrder.switchFromOrderID || undefined,
        switchType: newOrder.switchType || undefined,
        haulerID: newOrder.haulerID,
        poNumber: newOrder.poNumber,
        status: OrderStatus.UNASSIGNED,
        allianceCustomerID: newOrder.allianceCustomerID,
        requestedDeliveryDate: newOrder.expectedDeliveryDate ? newOrder.expectedDeliveryDate.toISOString() : undefined,
        requestedPickupDate: newOrder.expectedPickupDate ? newOrder.expectedPickupDate.toISOString() : undefined,
        rentalEndDate: newOrder.rentalEndDate ? newOrder.rentalEndDate.toISOString() : undefined,
        originalRentalPeriod: {
          value: newOrder.adjustedRentalPeriod,
          unit: 'DAYS',
        },
        expirationDate: addDays(new Date(newOrder.expectedDeliveryDate), newOrder.adjustedRentalPeriod).toISOString(),
        sharedDeliveryNotes: newOrder.sharedDeliveryNotes,
        sharedPickupNotes: newOrder.sharedPickupNotes,
        sharedDeliveryNotesPrivate: newOrder.sharedDeliveryNotesPrivate || '',
        sharedPickupNotesPrivate: newOrder.sharedPickupNotesPrivate || '',
        material: newOrder.material,
        recurringOnCall: newOrder.recurringOnCall || false,
        ccRate: newOrder.ccRate,
        haulerDumpRateDollars: newOrder.haulerDumpRateDollars || 0,
        haulerHaulRateDollars: newOrder.haulerHaulRateDollars || 0,
        overageDollars: newOrder.overageDollars || 0,
        dumpRateDollars: newOrder.dumpRateDollars || 0,
        rentExtensionFeeDollars: newOrder.rentExtensionFeeDollars || 0,
        taxRate: newOrder.taxRate,
        priceType: newOrder.priceType,
        paymentMethod: newOrder.paymentMethod,
        haulerPricingSnapshot: newOrder.haulerPricingSnapshot || undefined,
        paymentTerm: newOrder.paymentTerm,

        serviceLocation: {
          address: {
            addressLine1: newOrder.serviceLocation.address.addressLine1,
            addressLine2: newOrder.serviceLocation.address.addressLine2,
            city: newOrder.serviceLocation.address.city,
            state: newOrder.serviceLocation.address.state,
            zip: newOrder.serviceLocation.address.zip,
          },
          county: newOrder.serviceLocation.county,
          coordinates: newOrder.serviceLocation.coordinates,
        },
        requestedSize: { size: newOrder.expectedSize.size, type: 'OPEN_TOP' },
        weightLimit: newOrder.weightLimit === null ? null : { value: newOrder.weightLimit, unit: 'TONS' },
      };

      let newOrderResponse = null;

      console.log('order payload: ', orderObj);

      try {
        newOrderResponse = await client.order().adminPortal.create(orderObj);
        console.log('newOrderResponse: ', newOrderResponse);
      } catch (error) {
        console.warn('createOrder orderError: ', error);
        showFlash('An Error Occurred Creating Order', 'warning');
        setIsLoading(false);
        return;
      }

      newOrderID = newOrderResponse.id;
      // Have to refetch because backend isn't sending orderNumber back with creation
      const newOrderRefetchResponse = await client.order().adminPortal.fetch(newOrderID);

      console.log('newOrderRefetchResponse: ', newOrderRefetchResponse);
      newOrderNumber = newOrderRefetchResponse.orderNumber;
      newOrderServiceLocation = newOrderRefetchResponse.serviceLocation;
    }

    const updatedOrder: Order.AllianceOrderUpdateInput = {
      ...order,
      ...orderUpdates,
      expectedPickupDate: new Date(getDateFormat(values.expectedPickupDate)).toISOString(),
      expectedDeliveryDate: new Date(getDateFormat(values.expectedDeliveryDate)).toISOString(),
      rentalEndDate: new Date(getDateFormat(values.rentalEndDate)).toISOString(),
    };

    try {
      await client.order().adminPortal.update(values.id, updatedOrder);
    } catch (error) {
      console.warn('orderResponse error: ', error);
      showFlash('An Error Occurred while Updating Order', 'warning');
      return;
    }
    console.log('------------- precondition checks --------------');
    console.log('updatedOrder: ', updatedOrder);
    console.log('order: ', order);
    console.log('newInvoice: ', newInvoice);
    console.log('newOrderID: ', newOrderID);
    console.log('newOrderNumber: ', newOrderNumber);
    console.log('newOrderServiceLocation: ', newOrderServiceLocation);
    console.log('------------- precondition checks --------------');

    let receivableID = null;
    if (newInvoice && newOrderID && newOrderNumber && newOrderServiceLocation) {
      const createObj: Invoice.ReceivableCreateTransport = {
        customerID: order?.allianceCustomerID,
        customerName: order.customerName || undefined,
        customerCompanyName: order.customerCompanyName || undefined,
        invoiceDetails: {
          ...newInvoice,
          orderID: newOrderID,
          issueDate: newInvoice.issueDate ? newInvoice.issueDate.toISOString() : undefined,
          dueDate: newInvoice.dueDate ? newInvoice.dueDate.toISOString() : undefined,
          orderNumber: newOrderNumber.toString(),
          orderServiceLocation: newOrderServiceLocation,
        },
      };
      console.log('Invoice payload new', createObj);

      let invoiceResponse = null;
      try {
        invoiceResponse = await client.invoice().adminPortal.receivable.create(createObj);
        console.log('Invoice response new', invoiceResponse);
      } catch (error) {
        console.warn('createReceivable error: ', error);
        showFlash('An Error Occurred while Creating Receivable', 'warning');
        return;
      }

      receivableID = invoiceResponse?.id;
      if (values.paymentInfo) {
        try {
          const paymentResponse = await client
            .invoice()
            .adminPortal.payment.create(invoiceResponse?.invoiceDetails.id, values.paymentInfo);
          console.log('paymentResponse: ', paymentResponse);
        } catch (error) {
          console.warn('paymentResponse error: ', error);
          showFlash('An Error Occurred while Creating Payment', 'warning');
        }
      }
    } else {
      showFlash('An error occurred get Thromasz. No Invoice created');
    }

    // if (!receivableID) {
    //   alert('An error occurred get Dev. No Invoice created');
    // }

    if (!order.haulerID) {
      showFlash('Order does not have a hauler assigned. Please assign a hauler before proceeding.', 'warning');
      return;
    }

    const hauler = await handleGetHauler(order?.haulerID);
    const dispatchEmailsString = getDispatchEmailsString(hauler?.contacts);
    if (!dispatchEmailsString && values.sendHaulerEmail) {
      showFlash('No dispatch email found for hauler', 'warning');
      return;
    }
    //@ts-expect-error this is correct
    const emailSend = await createEmail(values, dispatchEmailsString, newOrderNumber, newOrderID, receivableID);

    if (Boolean(emailSend.hauler) && values.sendHaulerEmail) {
      try {
        await sendEmail('send-email', emailSend.hauler);
      } catch (error) {
        showFlash('Error Sending Hauler Email', 'warning');
      }
    }
    if (Boolean(emailSend.customer) && values.sendCustomerEmail) {
      const customerEmailResponse = await sendDynamicEmail(emailSend.customer as any);
      if (customerEmailResponse.status === 'error') showFlash('Error Sending Customer Email', 'warning');
      if (values.issueInvoice === 'NOW' && receivableID) {
        const invoicePDFResponse = await client.invoice().adminPortal.pdf.fetch(receivableID);
        const blob = new Blob([invoicePDFResponse], { type: 'application/pdf' });
        const invoiceBlob = ((await pdfToBase64(blob)) as any).split(',')[1];
        const invoiceEmailData = {
          emailTemplate: 'INVOICE_INTERMEDIARY',
          toEmail: customerInfo?.contacts.find((contact) => contact.primaryContact)?.email,
          first_name: customerInfo?.contacts[0].firstName,
          receipt_invoice: 'Invoice',
          ccEmails: customerInfo ? getCustomerCCAddresses(customerInfo).billingCCAddresses : [],
          attachments: [
            {
              content: invoiceBlob,
              type: 'application/pdf',
              filename: `Sourgum Invoice.pdf`,
              disposition: 'attachment',
            },
          ],
          invoice_number: '1',
        };

        if (invoiceBlob) {
          await sendDynamicEmail(invoiceEmailData as any);
        } else {
          console.warn('invoiceBlob did not create. Debug why');
          alert('Touch nothing and get a SAP dev to inspect console');
        }
      }
    }
    showFlash('Order Successfully Updated', 'success');

    if (changeRequest) {
      const update = {
        id: changeRequest.id,
        changes: changeRequest.changes.map((change: any) => {
          change.amendment = updatedOrder[change.field as keyof typeof updatedOrder];
          change.status = 'APPROVED';

          // convert date to string
          if (change.field === 'expectedPickupDate' || change.field === 'expectedDeliveryDate') {
            change.amendment = format(new Date(change.amendment), 'yyyy-MM-dd');
          }

          // make sure the amendment is different from the original value
          if (change.newValue === change.amendment) {
            delete change.amendment;
          } else {
            change.status = 'APPROVED_WITH_CHANGES';
          }

          if (change.notes === '') {
            delete change.notes;
          }

          // delete change.newValue;
          // delete change.oldValue;
          // delete change.field;

          // if (change.__typename) {
          //   delete change.__typename;
          // }

          return {
            id: change.id,
            status: change.status,
            amendment: change.amendment,
          };
        }),
      };
      try {
        await client.adminPortal().order.changes.update(update);

        if (onChangeRequestUpdated) {
          onChangeRequestUpdated(update.id);
        }
      } catch (e) {
        console.warn('Error updating change request', e);
      }
    }

    onCancel();
  };

  const getOrderTotal = (values: any) => {
    let total = values.price;
    values.otherLineItems.forEach((item: any) => {
      total = total + item.totalPriceDollars;
    });
    return total;
  };

  return (
    <Dialog open={open} onClose={onCancel} className="max-w-screen-2xl" styledTitle="Order Ready For Pick Up">
      {receivables ? (
        <Formik initialValues={prepareFormData(order)} validationSchema={OrderSchema} onSubmit={handleSubmit}>
          {(formik) => {
            const { setValues, setFieldValue, values } = formik;

            return (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div>Pick up information for:</div>
                    <div style={{ marginBottom: 32, marginTop: 7 }}>
                      {formatServiceAddress(order.serviceLocation.address)}
                    </div>
                    {changeRequest ? (
                      <>
                        <div>Customer Request:</div>
                        <div style={{ marginBottom: 32, marginTop: 7 }}>
                          {changeRequest.changes
                            .filter((change: any) => change.field !== 'status')
                            .map((change: any) => {
                              return (
                                <>
                                  {fieldNameMap[change.field as keyof typeof fieldNameMap]}:{' '}
                                  {String(change.field).includes('Date')
                                    ? format(new Date(change.newValue), 'MM/dd/yyyy')
                                    : change.newValue}{' '}
                                  <br />
                                </>
                              );
                            })}
                        </div>
                      </>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="Pickup date"
                      required
                      minDate={subDays(new Date(), 1)}
                      value={values.expectedPickupDate}
                      closeOnSelect
                      onChange={(value: any) => {
                        values.rentalEndDate
                          ? setFieldValue('expectedPickupDate', value)
                          : setValues({
                              ...values,
                              expectedPickupDate: value,
                              rentalEndDate: value,
                            });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="Rental extension end date"
                      closeOnSelect
                      required
                      helperText="No days will be added to the rental extension fee after this date. This date can be within the order's rental period."
                      value={values.rentalEndDate}
                      onChange={(value: any) => setFieldValue('rentalEndDate', value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="sharedPickupNotesPrivate"
                      label="Pickup hauler only notes"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      multiline
                      helperText={`Notes only the hauler will see when we send emails. Previous hauler only note: ${order.sharedDeliveryNotesPrivate}`}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      id="sharedPickupNotes"
                      name="sharedPickupNotes"
                      label="Pickup notes"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InternalOrderNotes order={order} hideAddButton />
                  </Grid>
                  {customerInfo?.notes ? (
                    <Grid item xs={12}>
                      <b>Customer Notes:</b> {customerInfo.notes}
                    </Grid>
                  ) : null}
                  <Grid item xs={6}>
                    <Toggle
                      value={values.sendHaulerEmail}
                      label="Send hauler email"
                      onChange={(value: any) => setFieldValue('sendHaulerEmail', value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Toggle
                      value={values.sendCustomerEmail}
                      label="Send customer email"
                      onChange={(value: any) => setFieldValue('sendCustomerEmail', value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Toggle
                      value={values.dnrToggle}
                      label="Dump and return"
                      onChange={async (value: any) => {
                        setValues({
                          ...values,
                          dnrToggle: value,
                        });

                        const zone = await handleGetPricing(
                          {
                            ...order.serviceLocation.coordinates,
                            zip: order.serviceLocation.address.zip,
                            state: order.serviceLocation.address.state,
                          },
                          values,
                        );

                        if (zone) {
                          const price = V1.calculatePricing(zone, {
                            material: order.material,
                            size: order.expectedSize.size,
                            weightLimit: order?.weightLimit?.value,
                            taxExempt: customerInfo?.taxExempt,
                            taxRate: customerInfo?.taxExempt ? 0 : values.taxRate,
                            creditCardFee: values.cc ? values.ccRate : 0,
                          });

                          setValues({
                            ...values,
                            dnrToggle: value,
                            price: price.total / 100,
                          });
                        }
                      }}
                      disabled={currentSwitch && order.status !== OrderStatus.DELIVERED}
                    />
                    {currentSwitch && order.status !== OrderStatus.DELIVERED ? (
                      <div>A switch already exists for this order.</div>
                    ) : null}
                  </Grid>
                  {values.dnrToggle ? (
                    <>
                      <Grid item xs={12}>
                        <Field
                          name="newHaulerOnlyNote"
                          label="Delivery hauler only notes"
                          component={TextField}
                          margin="dense"
                          fullWidth
                          multiline
                          helperText={`Notes only the hauler will see when we send emails. Previous delivery hauler notes: ${order.sharedDeliveryNotesPrivate}`}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          id="newNoteDel"
                          name="newNoteDel"
                          label="New delivery notes"
                          component={TextField}
                          margin="dense"
                          fullWidth
                          multiline
                          helperText={`Old delivery note: ${order.sharedDeliveryNotes}`}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Checkbox
                          label="Same size and material"
                          inputProps={{
                            checked: values.samePricing,
                            onChange: (e: any) => {
                              setFieldValue('samePricing', e.currentTarget.checked);
                              setFieldValue('otherLineItems', []);
                              if (!e.currentTarget.checked && !zonePricing) {
                                handleGetPricing(
                                  {
                                    ...order.serviceLocation.coordinates,
                                    zip: order.serviceLocation.address.zip,
                                    state: order.serviceLocation.address.state,
                                  },
                                  values,
                                );
                              }
                            },
                          }}
                        />
                        <div>
                          ({order.expectedSize.size} YD, {order.material}, Old Price: ${values.oldPrice}, Current Price:
                          ${values.price})
                        </div>
                      </Grid>
                      {zonePricingHauler &&
                      (!zonePricingHauler?.sourgumApproved ||
                        !zonePricingHauler.pricingData.find((material: any) => material === order.material)
                          ?.sourgumApproved) ? (
                        <Grid item xs={12}>
                          <div
                            style={{
                              background: '#FFE7F2',
                              color: '#B3093C',
                              padding: 10,
                              borderRadius: 4,
                            }}
                          >
                            Warning hauler's pricing has not been approved by Sourgum.
                          </div>
                        </Grid>
                      ) : null}
                    </>
                  ) : null}
                </Grid>
                {values.dnrToggle && !values.samePricing ? (
                  <OrderPricingFields
                    setFieldValue={setFieldValue}
                    values={{ ...values }}
                    setValues={setValues}
                    handleBlur={formik.handleBlur}
                    zonePricing={zonePricing}
                    existingCustomer={customerInfo}
                    passedSetOnce={true}
                    constraints={true}
                    swapConstraints={swapConstraints}
                    showSwitchText={true}
                    disabled={false}
                    disableFields={[]}
                  />
                ) : null}
                {values.dnrToggle &&
                  customerInfo?.defaultPaymentSettings?.paymentTerm !== paymentTermsEnums.onCharge && (
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div className="mt-4">
                            <b>Issue Invoice</b>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <i>
                            If you want to make edits to this orders invoice before sending it to the customer, click{' '}
                            <b>Draft</b>
                          </i>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="flex">
                            <RadioButton
                              options={[
                                { value: 'NOW', label: 'Now', inputProps: { checked: values.issueInvoice === 'NOW' } },
                                {
                                  value: 'DRAFT',
                                  label: 'Draft',
                                  inputProps: { checked: values.issueInvoice === 'DRAFT' },
                                },
                              ]}
                              wrapperClass="mr-4"
                              inputProps={{
                                onChange: (e: any) => {
                                  setFieldValue('issueInvoice', e.target._wrapperState.initialValue);
                                },
                                name: 'issueInvoice',
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                {values.dnrToggle && customerInfo ? (
                  (customerInfo?.defaultPaymentSettings?.paymentMethod || order.paymentMethod) ===
                  paymentMethodsEnums.creditCard ? (
                    <div style={{ marginTop: 32 }}>
                      <ChargeCard2
                        total={+getOrderTotal(values) * 100}
                        showChargeOption={true}
                        customer={customerInfo}
                        onChargeSuccessful={(stripeChargeID, last4) => {
                          const paymentInfo = {
                            paymentReceivedDate: new Date(),
                            amountDollars: +getOrderTotal(values),
                            paymentMethod: customerInfo?.defaultPaymentSettings?.paymentMethod || order.paymentMethod,
                            paymentIdentifier: last4,
                            stripeChargeID,
                          };
                          setValues({
                            ...values,
                            paymentInfo: paymentInfo,
                          });
                        }}
                        onSaveSuccessful={() => {
                          alert('Card saved successfully');
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ marginTop: 32 }}>Payment method is Check</div>
                  )
                ) : null}
                <div className="flex justify-end">
                  <button className="btn-dark-grey-outlined mr-5" onClick={onCancel} disabled={isLoading} type="button">
                    Cancel
                  </button>
                  <button
                    className="btn-primary"
                    disabled={!formik.isValid || !formik.values.expectedPickupDate || isLoading}
                    type="submit"
                  >
                    Save
                    {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <>loading...</>
      )}
    </Dialog>
  );
};

export default OrderReadyPickUp;
