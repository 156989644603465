import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Invoice, Pricing, S3ItemReference } from '@alliance-disposal/transport-types';
import { Tab, TabPanel, Tabs } from '@wayste/sour-ui';
import { Tooltip } from '@wayste/sour-ui';
import {
  formatE164ToUSPhoneNumber,
  formatServiceAddress,
  formatUSD,
  getPrimaryContact,
  moneyFormatter,
} from '@wayste/utils';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { DocumentDuplicateIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/solid';
import Collapse from '@mui/material/Collapse';
import { format, startOfYear } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { UIContext } from '../../contexts';
import { durationUnitTypes, haulerBillPaymentMethods } from '../../utils';
import { dayAbbOrder } from '../../utils';
import { haulerFileTypes } from '../../utils/shared-types';
import Dialog from '../Dialog';
import FileHandler from '../FileHandler/FileHandler';
import HaulerPricingCreate from '../HaulerPricingCreate';
import HaulerPricingList from '../HaulerPricingList';
import DeletePricingZoneModal from '../HaulerPricingList/DeletePricingZoneDialog';
import HaulerPricingUpdate from '../HaulerPricingUpdate';
import HaulerUpdate from '../HaulerUpdate';
import { InternalTicket } from '../InternalTicket';
import Loading from '../Loading';
import MaterialPricingDialog from '../MaterialPricingDialog';
import ServiceAreasMap from '../ServiceAreaMap';
import StarRating from '../StarRating';
import UniversalServicePricingList from '../UniversalServicePricingList';
import CardInfoList from '../ui/CardInfoList';
import DetailsCardWrapper from '../ui/DetailsCardWrapper';

const additionalContactHeadings = [
  'Name',
  'Phone',
  'Email',
  'Department',
  'Note',
  'Primary Contact',
  'Receives Dispatch',
  'Receives Billing',
  'Receives Marketing',
];

export interface HaulerDetailsProps {
  isModal?: boolean;
}

const HaulerDetails = ({ isModal }: HaulerDetailsProps) => {
  const waysteClient = useWaysteClient();
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const { showFlash } = useContext(UIContext);
  const [showUpdateHauler, setShowUpdateHauler] = useState<boolean>(false);
  const [showCreatePricing, setShowCreatePricing] = useState<boolean>(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showMaterialPricing, setShowMaterialPricing] = useState<boolean>(false);
  const [selectedPricing, setSelectedPricing] = useState<Pricing.PricingTransport | null>(null);
  const [selectedMaterial, setSelectedMaterial] = useState<Pricing.PricingDataTransport | null>(null);
  const [showHoursOfOperation, setShowHoursOfOperation] = useState<boolean>(false);
  const [hauler, setHauler] = useState<Hauler.HaulerWithAapTransport | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pricingLoading, setPricingLoading] = useState<boolean>(false);
  const [haulerPricing, setHaulerPricing] = useState<Pricing.PricingTransport[]>([]);
  const [vendorServices, setVendorServices] = useState<any>([]);
  const [rawPriceData, setRawPriceData] = useState<any>([]);
  const [rawServiceAreaData, setRawServiceAreaData] = useState<any>([]);
  const [showFiles, setShowFiles] = useState(false);
  const [files, setFiles] = useState<S3ItemReference[] | undefined>(hauler?.files);
  const [GMV, setGMV] = useState<Invoice.GMVSummary[]>([]);

  const getHauler = async (id: string) => {
    setIsLoading(true);
    const response = await waysteClient.vendorService().adminPortal.fetch(id);
    getHaulerPricing();
    getVendorServices();
    getGMVSummary();
    setHauler(response);
    setIsLoading(false);
    setFiles(response.files);
  };

  const getGMVSummary = async () => {
    //start of year
    const YTD = startOfYear(new Date());

    if (!id) return;
    const data = await waysteClient
      .invoice()
      .adminPortal.gmvSummary({ vendorID: id, orderDateAfter: YTD.toISOString() });
    setGMV(data);
  };

  /////////////////////////////////////
  // PRICING SECTION
  /////////////////////////////////////

  // GET VENDOR PRICING FOR ROLLOFF
  const getHaulerPricing = async () => {
    if (!id) return;
    setPricingLoading(true);
    const data = await waysteClient.pricing().adminPortal.pricing.query({ haulerID: id });
    const serviceAreas = data.flatMap((pricingZone: any) => pricingZone.serviceAreas);
    setRawPriceData(serviceAreas);

    console.log('serviceAreas', serviceAreas);
    console.log('data', data);
    setHaulerPricing(data);
    setPricingLoading(false);
  };

  // HANDLE CREATE PRICING ZONE ACTION
  const handleCreatePricingClose = (data: { status: string | number }) => {
    setShowCreatePricing(false);
    if (data?.status === 'success') {
      getHaulerPricing();
    }
    if (data?.status === 200) {
      getVendorServices();
    }
  };

  // HANDLE UPDATE PRICING ZONE ACTION
  const handleUpdatePricingClose = (response: { status: string | number }) => {
    setShowUpdateDialog(false);
    setSelectedPricing(null);
    if (response?.status === 'success') getHaulerPricing();
  };

  // HANDLE UPDATE MATERIAL PRICING ZONE ACTION
  const handleMaterialPricingClose = (response: any) => {
    setShowMaterialPricing(false);
    setSelectedPricing(null);
    setSelectedMaterial(null);
    if (response?.status === 'success') {
      getHaulerPricing();
      getVendorServices();
    }
  };

  const handleDeletePricing = async () => {
    if (!selectedPricing) return;
    setPricingLoading(true);
    try {
      await waysteClient.pricing().adminPortal.pricing.delete(selectedPricing.id);
      showFlash('Pricing Deleted', 'success');
      await getHaulerPricing();
    } catch (error) {
      showFlash('Error deleting pricing', 'error');
    } finally {
      setPricingLoading(false);
    }
  };

  // HANDLE UPDATE SERVICE AREA
  const handleServiceAreaSubmit = async (data: any, zone: any) => {
    const addServiceAreaPayload: Pricing.ServiceAreaTransport[] = data?.map((x: any) => {
      const serviceArea = {
        boundary: x.serviceArea,
        boundaryFormat: x.serviceAreaFormat,
        areaName: x.areaName,
        taxJurisdiction: x.taxJurisdiction,
      };
      return serviceArea;
    });

    if (zone) {
      try {
        await waysteClient.pricing().adminPortal.serviceArea.update(zone.id, addServiceAreaPayload);
        await getHaulerPricing();
        showFlash('Service Area Updated', 'success');
      } catch (error) {
        showFlash('Error updating service area', 'error');
      }
    }
  };

  /////////////////////////////////////
  // UNIVERSAL SERVICE SECTION
  /////////////////////////////////////

  // GET VENDOR PRICING FOR UNIVERSAL SERVICE
  const getVendorServices = async () => {
    if (hauler?.id) {
      const response = await waysteClient.universalService().pricingZone.query({
        vendorID: hauler?.id,
      });
      setPricingLoading(true);
      const serviceAreas = response.results?.flatMap((zones) => zones.serviceAreas);
      setRawServiceAreaData(serviceAreas);
      setVendorServices(response?.results);
      setPricingLoading(false);
    }
  };

  // HANDLE UPDATE SERVICE AREA
  const handleUniversalServiceAreaSubmit = async (data: any, zone: any) => {
    const addServiceAreaPayload = data?.map((x: any) => {
      const serviceArea = {
        boundary: x.serviceArea,
        boundaryFormat: x.serviceAreaFormat,
        areaName: x.areaName,
        taxJurisdiction: x.taxJurisdiction,
      };
      return serviceArea;
    });

    if (zone) {
      try {
        await waysteClient.universalService().pricingZone.updateServiceArea(zone, addServiceAreaPayload);
        showFlash('Service Area Updated', 'success');
        await getVendorServices();
      } catch (error) {
        showFlash('Error updating service area', 'error');
        console.log('error', error);
      }
    }
  };

  const handleServicesUpdate = () => {
    getVendorServices();
  };

  const onFileUpload = async (file: S3ItemReference) => {
    if (!hauler) return;
    const haulerUpdate: Hauler.HaulerWithAapDataUpdate = {
      files: [...(files || []), file],
    };
    await waysteClient.vendorService().adminPortal.update(hauler.id, haulerUpdate);
    setFiles(haulerUpdate.files);
  };

  const onFilesEdit = async (editFiles: S3ItemReference[]) => {
    if (!hauler) return;
    const haulerUpdate: Hauler.HaulerWithAapDataUpdate = {
      files: editFiles,
    };
    await waysteClient.vendorService().adminPortal.update(hauler.id, haulerUpdate);
    setFiles(haulerUpdate.files);
  };
  /////////////////////////////////////
  // HOOKS SECTION
  /////////////////////////////////////

  useEffect(() => {
    getHauler(id);
  }, [id]);

  useEffect(() => {
    getVendorServices();
  }, [hauler]);

  const handleBackButtonClick = () => {
    history.goBack();
  };

  /////////////////////////////////////
  // RENDER SECTION
  /////////////////////////////////////

  if (isLoading) return <Loading />;

  if (!hauler) return <div>No hauler exists</div>;

  const colOne = [
    {
      label: 'Rating',
      value: <StarRating rating={hauler.rating} />,
    },
    {
      label: 'Office Phone',
      value: (
        <a href={`tel:${getPrimaryContact(hauler)?.phoneNumber || ''}`} className="no-underline	text-wayste-blue-400">
          {formatE164ToUSPhoneNumber(getPrimaryContact(hauler)?.phoneNumber || '')}
        </a>
      ),
    },
    { label: 'Office Email', value: getPrimaryContact(hauler)?.email || '' },
    {
      label: 'Billing Address',
      value: formatServiceAddress(hauler.billingAddress),
    },
    // { label: 'SW Number', value: hauler.swNumber }, TODO changes over to licenses
    { label: 'Notes', value: hauler.notes },
  ];

  const colTwo = [
    {
      label: 'Default Payment Method',
      value: haulerBillPaymentMethods[hauler.defaultPaymentMethod as keyof typeof haulerBillPaymentMethods],
    },
    {
      label: 'Rental Period Units',
      value: hauler.defaultRentPeriod?.unit
        ? durationUnitTypes[hauler.defaultRentPeriod.unit as keyof typeof durationUnitTypes]
        : '',
    },
    { label: 'Base Rent Period', value: hauler.defaultRentPeriod?.value || '' },
    {
      label: 'Rental Extension Fee',
      value: hauler.rentExtensionFee ? formatUSD(hauler.rentExtensionFee / 100) : '',
    },
    {
      label: 'Hours of Operation',
      value: (
        <div>
          <div className="text-wayste-blue-400" onClick={() => setShowHoursOfOperation(!showHoursOfOperation)}>
            {showHoursOfOperation ? 'Hide' : 'Show'}
          </div>
          <Collapse in={showHoursOfOperation}>
            {dayAbbOrder.map((key: string) => {
              const hours = hauler.hoursOfOperation[key as keyof typeof hauler.hoursOfOperation];

              if (!hours.open)
                return (
                  <div key={key}>
                    <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{key}: </span>
                    Closed
                  </div>
                );

              return (
                <div key={key}>
                  <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{key}: </span>
                  {hours.start ? format(new Date(hours.start), 'hh:mm aaa') : ''} -{' '}
                  {hours.end ? format(new Date(hours.end), 'hh:mm aaa') : ''}
                </div>
              );
            })}
          </Collapse>
        </div>
      ),
    },
    {
      label: 'Order Count YTD',
      value: GMV.length,
    },
    {
      label: 'Sourgum Revenue YTD',
      value: GMV.length > 0 ? moneyFormatter(GMV.reduce((a, b) => a + (b?.revenue || 0), 0)) : '',
    },
    {
      label: 'Vendor Costs YTD',
      value: GMV.length > 0 ? moneyFormatter(GMV.reduce((a, b) => a + (b?.cost || 0), 0)) : '',
    },
    {
      label: 'Average Take Rate YTD',
      value:
        GMV.length > 0
          ? `${Math.round((GMV.reduce((a, b) => a + (b?.takeRate || 0), 0) / GMV.length) * 10000) / 100}%`
          : 'No Data',
    },
  ];

  const content = (
    <div className="container mx-auto">
      <HaulerUpdate
        open={showUpdateHauler}
        onBackButtonClick={() => setShowUpdateHauler(false)}
        onSubmit={() => {
          setShowUpdateHauler(false);
          getHauler(id);
        }}
        hauler={hauler}
      />
      <div
        style={{
          paddingBottom: 20,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <button className="btn-dark-grey-outlined" onClick={handleBackButtonClick}>
          <ChevronLeftIcon className="h-5 w-5 mr-1" />
          Vendors
        </button>
        <button className="btn-primary" onClick={() => setShowCreatePricing(true)}>
          <PlusIcon className="h-5 w-5 mr-1" />
          New Zone Pricing
        </button>
      </div>
      <DetailsCardWrapper
        heading={
          <span className={!hauler.active ? 'text-red-500' : ''}>
            {hauler.name}
            {!hauler.active ? ' - Inactive' : ''}
          </span>
        }
        buttons={[
          {
            label: (
              <>
                <PencilIcon className="h-4 w-4 mr-1.5" />
                Edit Vendor
              </>
            ),
            onClick: () => setShowUpdateHauler(true),
          },
          {
            label: (
              <Tooltip
                text={
                  <p className="text-lg">
                    <b>WARNING</b>: This will only work for Vendors who have <b>NEVER</b> signed into wayste and the
                    user that uses this link will become the the administrator of the Vendors account. <br />
                    <br />
                    <span>Please be careful who you send this link to.</span>
                  </p>
                }
                variant="light"
                floatProps={{
                  offset: 10,
                }}
              >
                <div className="flex">
                  <DocumentDuplicateIcon className="h-4 w-4 mr-1.5" />
                  Copy Sign Up Link
                </div>
              </Tooltip>
            ),
            onClick: () => {
              const link = `https://app.wayste.com/sign-up?token=${hauler.id}`;
              navigator.clipboard.writeText(link);
              showFlash('Copied sign up link to clipboard.', 'success');
            },
          },
          {
            label: (
              <>
                <DocumentIcon className="h-5 w-5 mr-1" /> Add Files
              </>
            ),
            onClick: () => setShowFiles(true),
          },
        ]}
      >
        <FileHandler
          files={files || []}
          folderName={`hauler${hauler.id?.toString() ?? ''}/`}
          types={haulerFileTypes}
          open={showFiles}
          onCancel={() => setShowFiles(false)}
          onFileUpload={onFileUpload}
          onFilesEdit={onFilesEdit}
        />
        <div className={`grid grid-cols-1 gap-4 lg:grid-cols-2`}>
          <CardInfoList data={colOne} border />
          <CardInfoList data={colTwo} />
        </div>
        <div style={{ fontWeight: 500, marginTop: 20 }}>Additional Contacts</div>
        <div style={{ margin: '0 -20px -20px' }}>
          <div className="w-full overflow-x-auto">
            <table className="w-full border-collapse border-spacing-0 text-sm">
              <thead>
                <tr>
                  {additionalContactHeadings.map((header) => (
                    <th key={header} className="bg-slate-100 whitespace-nowrap py-1.5 px-4 font-normal">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {hauler.contacts.map((contact, index) => (
                  <tr key={'contact' + index} className="[&>*]:py-1.5 [&>*]:px-4 [&>*]:border-b">
                    <td>{`${contact.firstName} ${contact.lastName}`}</td>
                    <td>
                      <a href={`tel:${contact.phoneNumber}`} className="no-underline text-wayste-blue-400">
                        {formatE164ToUSPhoneNumber(contact?.phoneNumber ? contact.phoneNumber : '')}
                      </a>
                    </td>
                    <td>{contact.email}</td>
                    <td>{contact.department}</td>
                    <td>{contact.notes}</td>
                    <td>{contact.primaryContact ? 'Yes' : ''}</td>
                    <td>{contact.sendDispatchEmails ? 'Yes' : ''}</td>
                    <td>{contact.sendBillingEmails ? 'Yes' : ''}</td>
                    <td>{contact.sendMarketingEmails ? 'Yes' : ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </DetailsCardWrapper>

      <InternalTicket entityID={hauler.id} entityType="sourgum-vendor" />

      <Tabs>
        <Tab>Roll Off</Tab>
        <Tab>Universal Services</Tab>

        {/* Roll of Panel */}
        <TabPanel>
          <div className="mb-4">
            <ServiceAreasMap pricingZones={rawPriceData} disableScrollZoom={true} />
          </div>
          <HaulerPricingList
            isLoading={pricingLoading}
            pricing={haulerPricing}
            onEditClicked={(pricing: Pricing.PricingTransport) => {
              console.log('pricing', pricing);
              setSelectedPricing(pricing);
              setShowUpdateDialog(true);
            }}
            onDeleteClicked={(pricing: Pricing.PricingTransport) => {
              setSelectedPricing(pricing);
              setShowDeleteDialog(true);
            }}
            onServiceAreaSubmit={handleServiceAreaSubmit}
            onAddMaterialClicked={(pricing: Pricing.PricingTransport) => {
              setSelectedPricing(pricing);
              setShowMaterialPricing(true);
            }}
            onEditMaterialClicked={(pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => {
              setSelectedPricing(pricing);
              setSelectedMaterial(material);
              setShowMaterialPricing(true);
            }}
            onRefreshPricing={getHaulerPricing}
          />
          {/* Modals */}

          {selectedPricing !== null && (
            <MaterialPricingDialog
              open={showMaterialPricing}
              material={selectedMaterial}
              zone={selectedPricing}
              onBackButtonClick={handleMaterialPricingClose}
              isHauler
            />
          )}
          <HaulerPricingCreate
            open={showCreatePricing}
            mode={'Rolloff'}
            haulerPricingZones={haulerPricing}
            onBackButtonClick={handleCreatePricingClose}
            hauler={hauler}
          />
          {selectedPricing !== null && (
            <DeletePricingZoneModal
              open={showDeleteDialog}
              onClose={() => setShowDeleteDialog(false)}
              handleDelete={handleDeletePricing}
              pricingZone={selectedPricing}
            />
          )}

          {selectedPricing !== null && (
            <HaulerPricingUpdate
              pricing={selectedPricing}
              haulerPricingZones={haulerPricing}
              mode={'Rolloff'}
              open={showUpdateDialog}
              onBackButtonClick={handleUpdatePricingClose}
              hauler={hauler}
            />
          )}
        </TabPanel>

        {/* Universal Services Panel */}
        <TabPanel>
          <div className="mb-4">
            <ServiceAreasMap pricingZones={rawServiceAreaData} disableScrollZoom={true} />
          </div>
          <HaulerPricingCreate
            haulerPricingZones={haulerPricing}
            open={showCreatePricing}
            mode={'Universal'}
            onBackButtonClick={handleCreatePricingClose}
            hauler={hauler}
          />
          <UniversalServicePricingList
            isLoading={pricingLoading}
            pricing={vendorServices}
            rollOffPricing={haulerPricing || []}
            handleServicesUpdate={handleServicesUpdate}
            vendor={hauler}
            onEditClicked={() => {
              setShowUpdateDialog(true);
            }}
            onServiceAreaSubmit={handleUniversalServiceAreaSubmit}
            onRefreshPricing={getVendorServices}
          />
        </TabPanel>
      </Tabs>
    </div>
  );

  if (isModal)
    return (
      <Dialog
        open={true}
        onClose={handleBackButtonClick}
        styledTitle="Hauler Details"
        className="bg-[#F9F9F9]"
        fullScreen
      >
        {content}
      </Dialog>
    );

  return (
    <div className="bg-[#F9F9F9]">
      <div className="container mx-auto p-6 pt-5 pb-12">{content}</div>
    </div>
  );
};

export default HaulerDetails;
