import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Pricing } from '@alliance-disposal/transport-types';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { cloneDeep } from 'lodash';
import DetailsCardWrapper from '../ui/DetailsCardWrapper';
import CustomPricingTable from './components/CustomPricingTable';
import CustomMaterialPricingModal, { AddMaterialProps } from './components/modals/CustomMaterialPricingModal';
import CustomPricingZoneModal, { AddPricingZoneProps } from './components/modals/CustomPricingZoneModal';
import DeleteCustomMaterialPricingModal, {
  DeleteMaterialProps,
} from './components/modals/DeleteCustomMaterialPricingModal';
import DeleteCustomPricingModal, { DeletePricingZoneProps } from './components/modals/DeleteCustomPricingModal';

const CustomPricing = ({ customer }: { customer: Customer.AllianceCustomerTransport }) => {
  const client = useWaysteClient();
  const [customerDiscounts, setCustomerDiscounts] = useState<Pricing.CustomerDiscountTransport[]>();
  const [pricingModalProps, setPricingModalProps] = useState<AddMaterialProps | Record<string, never>>({});
  const [pricingZoneModalProps, setPricingZoneModalProps] = useState<AddPricingZoneProps | Record<string, never>>({});
  const [deletePricingModalProps, setDeletePricingModalProps] = useState<
    DeletePricingZoneProps | Record<string, never>
  >({});
  const [deleteMaterialPricingModalProps, setDeleteMaterialPricingModalProps] = useState<
    DeleteMaterialProps | Record<string, never>
  >({});

  const getCustomerDiscounts = async () => {
    try {
      const customerDiscounts = await client
        .adminPortal()
        .customerDiscount.query({ customerID: customer.id, contractor: false });
      const deep = cloneDeep(customerDiscounts.results);
      deep.sort((a, b) => ((a.pricingZone?.zoneName || '') > (b.pricingZone?.zoneName || '') ? 1 : -1));
      setCustomerDiscounts(deep);
    } catch (error) {
      console.warn('handleSetExpandedSection ERROR', error);
      alert('An error has occurred while fetching customer discounts.');
    }
  };

  const handleCreateUpdatePricingZone = async ({
    pricingZoneID,
    extraRentalPeriod,
    rentalExtensionFee,
    customerDiscountID,
  }: {
    pricingZoneID: string;
    extraRentalPeriod: number;
    rentalExtensionFee: number;
    customerDiscountID?: string;
  }) => {
    try {
      if (customerDiscountID) {
        await client.adminPortal().customerDiscount.changes.update({
          updateCustomerDiscountId: customerDiscountID,
          changes: {
            rentExtensionFee: rentalExtensionFee,
            extraRentalPeriod: extraRentalPeriod,
          },
        });
      } else {
        if (customerDiscounts?.find((x) => x.pricingZoneID === pricingZoneID)) {
          return alert('This pricing zone already exist. Please delete it or edit it instead.');
        }
        const payload: Pricing.CustomerDiscountCreateTransport = {
          customerID: customer.id,
          pricingZoneID: pricingZoneID,
          rentExtensionFee: rentalExtensionFee,
          extraRentalPeriod: extraRentalPeriod,
          discountData: [],
        };
        await client.adminPortal().customerDiscount.create(payload);
      }
      setPricingZoneModalProps({});
      getCustomerDiscounts();
    } catch (error) {
      console.warn('handleCreateUpdatePricingZone error: ', error);
      alert('An error has occurred, please contact a SAP dev.');
    }
  };

  const handleAddNewMaterial = async ({
    materialDiscount,
    pricingZoneID,
    discountType,
  }: {
    pricingZoneID: string;
    materialDiscount: Pricing.DiscountDataTransport;
    discountType: Pricing.CustomerDiscountType.DOLLAR | Pricing.CustomerDiscountType.FIXED;
  }) => {
    try {
      const payload: Pricing.DiscountDataTransport = {
        ...materialDiscount,
      };
      const clonedCustomerDiscounts = cloneDeep(customerDiscounts);
      const findZoneIndex = clonedCustomerDiscounts?.findIndex((x) => x.pricingZoneID === pricingZoneID);
      if (findZoneIndex === undefined || findZoneIndex === -1 || !clonedCustomerDiscounts) return;
      const newPricingDiscount = clonedCustomerDiscounts[findZoneIndex];
      const materialExistIndex = newPricingDiscount.discountData.findIndex(
        (x) => x.material === materialDiscount.material,
      );
      console.log('newPricingDiscount: ', newPricingDiscount);
      // if exist, replace, else push...
      if (materialExistIndex === undefined || materialExistIndex === -1) {
        console.log('hit 1');
        newPricingDiscount.discountData = newPricingDiscount.discountData.map((x) => {
          return {
            material: x.material,
            sizes: x.sizes.map((y) => {
              const sizeMapped: Pricing.DiscountDataTransport['sizes'][0] = {
                haul: y.haul,
                dump: y.dump,
                over: y.over,
                tonLimit: y.tonLimit,
                size: y.size,
              };
              return sizeMapped;
            }),
          };
        });
        newPricingDiscount.discountData.push(payload);
      } else {
        console.log('hit 2');
        newPricingDiscount.discountData = newPricingDiscount.discountData.map((x) => {
          console.log('x: ', x);
          return {
            material: x.material,
            sizes: x.sizes.map((y) => {
              const sizeMapped: Pricing.DiscountDataTransport['sizes'][0] = {
                haul: y.haul,
                dump: y.dump,
                over: y.over,
                tonLimit: y.tonLimit,
                size: y.size,
              };
              return sizeMapped;
            }),
          };
        });
        newPricingDiscount.discountData[materialExistIndex] = payload;
      }
      console.log('newPricingDiscount: ', newPricingDiscount);
      await client.pricing().adminPortal.customerDiscount.update(newPricingDiscount.id, {
        discountData: newPricingDiscount.discountData,
        discountType,
      });
      await getCustomerDiscounts();
    } catch (error) {
      console.warn('handleAddNewMaterial error', error);
      alert('An error has occurred, please contact an AAP dev.');
    }
  };

  const handleDeletePricingZone = async ({ customerDiscountID }: { customerDiscountID: string }) => {
    try {
      await client.adminPortal().customerDiscount.changes.delete(customerDiscountID);
      // Optimistic UI to prevent using setTimeout because refetching sometimes still grabs an expired pricing zone
      const findIndex = customerDiscounts?.findIndex((x) => x.id === customerDiscountID);
      const deep = cloneDeep(customerDiscounts);
      if (findIndex === undefined || findIndex === -1) return;
      deep?.splice(findIndex, 1);
      setCustomerDiscounts(
        deep?.sort((a, b) => ((a.pricingZone?.zoneName || '') > (b.pricingZone?.zoneName || '') ? 1 : -1)),
      );
    } catch (error) {
      console.warn('handleDeletePricingZone error', error);
      alert('An error has occurred. Please contact AAP dev.');
    }
  };

  const handleDeleteMaterial = async ({
    customerDiscountID,
    material,
  }: {
    customerDiscountID: string;
    material: string;
  }) => {
    try {
      const deep = cloneDeep(customerDiscounts);
      const findZoneIndex = deep?.findIndex((x) => x.id === customerDiscountID);
      if (findZoneIndex === undefined || findZoneIndex === -1 || !deep) return;
      const customerDiscount = deep[findZoneIndex];
      const filteredData = customerDiscount.discountData.filter((x) => x.material !== material);
      customerDiscount.discountData = filteredData;
      customerDiscount.discountData = customerDiscount.discountData.map((x) => {
        return {
          material: x.material,

          sizes: x.sizes.map((y) => {
            const sizeMapped: Pricing.DiscountDataTransport['sizes'][0] = {
              haul: y.haul,
              dump: y.dump,
              over: y.over,
              tonLimit: y.tonLimit,
              size: y.size,
            };
            return sizeMapped;
          }),
        };
      });
      await client.adminPortal().customerDiscount.changes.update({
        updateCustomerDiscountId: customerDiscountID,
        changes: { discountData: customerDiscount.discountData },
      });
      await getCustomerDiscounts();
    } catch (error) {
      console.warn('handleDeleteMaterial ERROR', error);
      alert('An error has occurred, please contact AAP dev.');
    }
  };

  useEffect(() => {
    getCustomerDiscounts();
  }, []);
  return (
    <DetailsCardWrapper
      heading="Custom Pricing"
      buttons={[
        {
          label: (
            <>
              <PlusIcon className="h-5 w-5 mr-1" />
              Add Pricing Zone
            </>
          ),
          onClick: () => {
            setPricingZoneModalProps({
              existingCustomerDiscounts: customerDiscounts ?? [],
              open: true,
            });
          },
        },
      ]}
    >
      <DeleteCustomPricingModal
        {...deletePricingModalProps}
        handleClose={() => setDeletePricingModalProps({})}
        handleDeletePricingZone={handleDeletePricingZone}
      />
      <DeleteCustomMaterialPricingModal
        {...deleteMaterialPricingModalProps}
        handleClose={() => setDeleteMaterialPricingModalProps({})}
        handleDeleteMaterial={handleDeleteMaterial}
      />
      <CustomMaterialPricingModal
        handleClose={() => setPricingModalProps({})}
        handleSubmit={handleAddNewMaterial}
        {...pricingModalProps}
      />
      <CustomPricingZoneModal
        onClose={() => setPricingZoneModalProps({})}
        handleSubmit={handleCreateUpdatePricingZone}
        {...pricingZoneModalProps}
      />
      <div style={{ margin: '-16.7px -20px -20px' }}>
        {customerDiscounts?.map((customerDiscount) => (
          <Disclosure key={customerDiscount.id}>
            <Disclosure.Button className="w-full text-left flex justify-between px-4 py-3 bg-blue-gray-50 items-center">
              <p className="font-bold">{customerDiscount?.pricingZone?.zoneName ?? 'No Zone Name'}</p>
              <div className="flex items-center">
                <button
                  onClick={() => {
                    setPricingModalProps({
                      customer: customer,
                      selectedCustomerDiscount: customerDiscount,
                    });
                  }}
                  className={`btn-primary relative mr-2`}
                >
                  <PlusIcon className="h-5 w-5 mr-1" />
                  Add Material
                </button>
                <button
                  onClick={() => {
                    setPricingZoneModalProps({
                      existingCustomerDiscounts: customerDiscounts ?? [],
                      open: true,
                      selectedCustomerDiscount: customerDiscount,
                    });
                  }}
                  className={`btn-secondary relative mr-2`}
                >
                  <PencilIcon className="h-5 w-5 mr-1" />
                  Edit Pricing Zone
                </button>
                <button
                  onClick={() => {
                    customerDiscount?.pricingZoneID
                      ? setDeletePricingModalProps({
                          customer: customer,
                          customerDiscount: customerDiscount,
                        })
                      : null;
                  }}
                  className={`btn-delete relative mr-4`}
                >
                  <TrashIcon className="h-5 w-5 mr-1" />
                  Delete Zone
                </button>
                <ChevronDownIcon className="h-6 w-6" />
              </div>
            </Disclosure.Button>
            <Disclosure.Panel>
              <CustomPricingTable
                customer={customer}
                customerDiscount={customerDiscount}
                onDeleteClick={(materialDiscountInfo) =>
                  setDeleteMaterialPricingModalProps({
                    customer: customer,
                    material: materialDiscountInfo.material,
                    customerDiscount: customerDiscount,
                  })
                }
                onEditClick={(materialDiscountInfo) =>
                  setPricingModalProps({
                    customer: customer,
                    selectedCustomerDiscount: customerDiscount,
                    selectedDiscountData: materialDiscountInfo,
                  })
                }
              />
            </Disclosure.Panel>
          </Disclosure>
        ))}
      </div>
    </DetailsCardWrapper>
  );
};

export default CustomPricing;
