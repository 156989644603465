import { useEffect, useState } from 'react';
import { TextField } from '@wayste/sour-ui';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { Controller, get, useFormContext, useWatch } from 'react-hook-form';

export interface SortableItemProps {
  index: number;
  field: string;
  status: string;
  id: number;
  onRemove: (status: string) => void;
}
export const PermanentItemComponent = ({ index, status, field }: SortableItemProps) => {
  const [number, setNumber] = useState<number>(0);

  // REACT HOOK FORM HOOKS
  const {
    getValues,
    register,
    control,
    formState: { errors },
  } = useFormContext();

  // REACT HOOK WATCH
  const pre = useWatch({
    control,
    name: `statuses`,
  });
  const post = useWatch({
    control,
    name: `preStatuses`,
  });

  // CALCULATE INDEX OF PERMANENT STATUS ITEMS
  useEffect(() => {
    if (status === 'UNASSIGNED') {
      setNumber(1);
    }
    if (status === 'ASSIGNED') {
      setNumber(2);
    }
    if (status === 'COMPLETED') {
      let statusLength = getValues('statuses');
      if (statusLength) {
        statusLength = statusLength.length;
      }
      let preStatusLength = getValues('preStatuses');
      if (preStatusLength) {
        preStatusLength = preStatusLength.length;
      }
      const position = statusLength + preStatusLength + 1;
      if (Number.isNaN(position)) {
        setNumber(3);
      } else {
        setNumber(position);
      }
    }
  }, [pre, post]);

  return (
    <div className="px-2 h-14 flex flex-row justify-start items-center w-full border-b border-gray-400 space-x-6">
      <Bars3Icon className="w-6 h-6 text-gray-400" />
      <div className="text-gray-400">{number}</div>
      <div className="text-gray-400">{status}</div>
      <div>
        {status === 'ASSIGNED' && (
          <Controller
            control={control}
            name={`${field}.${index}.VendorSendgridID`}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                type={'number'}
                label="Sendgrid ID to vendor"
                error={get(errors, `${field}.${index}.VendorSendgridID`)}
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
        )}
      </div>
      <input type="hidden" {...register(`${field}.${index}.status`, { value: status })} />
    </div>
  );
};
