import React from 'react';
import { Order } from '@alliance-disposal/transport-types';
import Dialog from '../Dialog';
import OrderForm from '../OrderForm';

/**
 * Component to Update an item in the orders table
 * @param {*} order A order schema object
 * @param {Function} onBackButtonClick Function on modal close
 * @param {Boolean} open Controls if modal is visible
 * @param {Function} onSubmit Function on update
 */

export interface OrderUpdateProps {
  order: Order.AllianceOrderTransport;
  onBackButtonClick: () => void;
  open: boolean;
  onSubmit: (values: Order.AllianceOrderCreateInput | Order.AllianceOrderUpdateInput) => void;
  isLoading: boolean;
}
const OrderUpdate = ({ order, onBackButtonClick, open, onSubmit, isLoading }: OrderUpdateProps) => {
  return (
    <Dialog open={open} onClose={onBackButtonClick} styledTitle="Update Order" className="max-w-2xl">
      <div className="pt-2">
        <OrderForm order={order} onCancel={onBackButtonClick} onSubmit={onSubmit} isLoading={isLoading} />
      </div>
    </Dialog>
  );
};

export default OrderUpdate;
