import React from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Field } from 'formik';
import { FormikProps } from 'formik';
import { Select, TextField } from 'formik-mui';
import PhoneNumberField from '../../utils/components/phonenumber-field';
import { commercialLocationTypes } from '../../utils/shared-types';
import InputAddress from '../InputAddress';
import InputDatePicker from '../InputDatePicker';

const LocationMenuItems = Object.entries(commercialLocationTypes).map((item) => (
  <MenuItem key={`size-${item[0]}`} value={item[0]}>
    {item[1]}
  </MenuItem>
));

export interface CMLeadFormProps {
  formik: FormikProps<any>;
  lead?: Customer.AllianceLeadTransport;
}

const CMLeadForm = ({ formik, lead }: CMLeadFormProps) => {
  return (
    <div className="bg-white rounded shadow-dark p-4 mb-5">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Lead Details</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            name="firstName"
            label="First Name"
            component={TextField}
            margin="dense"
            fullWidth
            required
            autoComplete="no"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field name="lastName" label="Last Name" component={TextField} margin="dense" fullWidth autoComplete="no" />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field name="company" label="Company" component={TextField} margin="dense" fullWidth autoComplete="no" />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            name="phoneNumber"
            label="Phone"
            component={PhoneNumberField}
            margin="dense"
            fullWidth
            required={!formik.values.email?.trim()}
            autoComplete="no"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            name="email"
            label="Email"
            component={TextField}
            margin="dense"
            fullWidth
            required={!formik.values.phoneNumber?.trim()}
            autoComplete="no"
          />
        </Grid>
        <Grid item xs={12} md={6} />
        <Grid item xs={12} md={3}>
          <Field
            name={`locationType`}
            label="Location Type"
            labelId="locationType-label"
            formControl={{ fullWidth: true, size: 'small' }}
            component={Select}
          >
            {LocationMenuItems}
          </Field>
        </Grid>
        <Grid item xs={12} md={9}>
          <Field name="currentHauler" label="Current Hauler" component={TextField} margin="dense" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Field name="customerNotes" label="Customer Notes" component={TextField} margin="dense" fullWidth multiline />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputDatePicker
            label="Requested start date"
            value={formik.values.requestedStartDate}
            onChange={(value: Date) => formik.setFieldValue('requestedStartDate', value)}
            disablePast={false}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputDatePicker
            label="Follow up date"
            value={formik.values.followUpDate}
            onChange={(value: Date) => formik.setFieldValue('followUpDate', value)}
            disablePast={false}
          />
        </Grid>
        <Grid item xs={12}>
          {!lead ? (
            <Field name="notes" label="Internal notes" component={TextField} margin="dense" fullWidth multiline />
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            color: 'rgba(0, 0, 0, 0.58)',
            paddingBottom: 0,
            paddingTop: 15,
          }}
        >
          <Typography variant="h6">Address</Typography>
          <Typography variant="subtitle1">At least a city, state and zip code are required</Typography>
        </Grid>
        <Grid item xs={12}>
          <InputAddress
            name="address"
            google
            partial
            styles={{ maxWidth: 500 }}
            onSelect={(obj: any) => {
              formik.setValues({
                ...formik.values,
                county: obj.county,
                address: {
                  ...formik.values.address,
                  ...obj.address,
                },
              });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CMLeadForm;
