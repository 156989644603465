import React, { useEffect, useRef, useState } from 'react';
import { V1 as sourgumPricingFunctions } from '@alliance-disposal/pricing';
import { Material, Pricing } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { formatUSD, moneyFormatter } from '@wayste/utils';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import * as Yup from 'yup';
import CheckBoxField from '../../utils/components/checkbox-field';
import { ccRate, salesTaxExemptMaterials } from '../../utils/pricing-utils';
import { materials, priceTypes, priceTypesEnums, rollOffSizes } from '../../utils/shared-types';

export type FrontendSize = {
  checked?: boolean;
  tonLimit: number;
  haul: number;
  size: string;
  dump: number;
  over: number;
  overage?: number; // this gets injected when it goes through the normalization process
};

const MaterialSchema = Yup.object().shape({
  material: Yup.string().oneOf(Object.keys(materials)).required('A material is required'),
  type: Yup.string().oneOf(Object.keys(priceTypes)).required('A pricing type is required'),
  editAllHauls: Yup.boolean(),
  editAllDumps: Yup.boolean(),
  editAllOverages: Yup.boolean(),
  tax: Yup.boolean(),
  isHauler: Yup.boolean(),
  allowOnlineCheckout: Yup.boolean().when(['isHauler'], {
    is: (isHauler) => !isHauler,
    then: Yup.boolean().required('Allow online checkout is required'),
  }),
  allowForLowerTonLimit: Yup.boolean().when(['isHauler'], {
    is: (isHauler) => !isHauler,
    then: Yup.boolean().required('Allow lower ton limit is required'),
  }),
  doesNotService: Yup.boolean(),
  haul: Yup.number().when(['editAllHauls'], {
    is: (editAllHauls) => editAllHauls,
    then: Yup.number().required('Price is required'),
  }),
  dump: Yup.number().when(['type', 'editAllDumps'], {
    is: (type, editAllDumps) => type !== priceTypesEnums.flat && editAllDumps,
    then: Yup.number().required('Dump is required'),
  }),
  over: Yup.number().when(['type', 'editAllOverages', 'isHauler'], {
    is: (type, editAllOverages, isHauler) => !isHauler && type === priceTypesEnums.ton && editAllOverages,
    then: Yup.number().required('Overage is required'),
  }),
  sizes: Yup.array().of(
    Yup.object().shape({
      size: Yup.string().required('Size is required'),
      checked: Yup.bool(),
      tonLimit: Yup.number().when('type', {
        is: priceTypesEnums.ton,
        then: Yup.number().required('Ton limit is required'),
        otherwise: Yup.number().nullable(),
      }),
      haul: Yup.number().when(['type', 'editAllHauls'], {
        is: (type, editAllHauls) => type === priceTypesEnums.flat || editAllHauls,
        then: Yup.number().required('Price is required'),
      }),
      dump: Yup.number().when(['type', 'editAllDumps'], {
        is: (type, editAllDumps) => type !== priceTypesEnums.flat && editAllDumps,
        then: Yup.number().required('Dump is required'),
      }),
      over: Yup.number().when(['type', 'editAllOverages', 'isHauler'], {
        is: (type, editAllOverages, isHauler) => !isHauler && type === priceTypesEnums.ton && editAllOverages,
        then: Yup.number().required('Overage is required'),
      }),
    }),
  ),
});

export interface MaterialPricingFormProps {
  zone: Pricing.PricingTransport;
  material?: Pricing.PricingDataTransport;
  onCancel: (data: any) => void;
  onSubmit: (data: any) => void;
  isLoading: boolean;
  isHauler: boolean;
}

const MaterialPricingForm = ({ zone, material, onCancel, onSubmit, isLoading, isHauler }: MaterialPricingFormProps) => {
  const formRef = useRef<any>();
  const [materialsUsed, setMaterialsUsed] = useState<Material[]>([]);

  useEffect(() => {
    console.log('zone', zone);
    console.log('material', material);
    const materialsUsedArray = zone.pricingData.map((item: Pricing.PricingDataTransport) => item.material as Material);
    console.log('materialsUsedArray', zone.pricingData);
    setMaterialsUsed(materialsUsedArray);
  }, []);

  const materialDisabled = (value: string) =>
    Boolean(
      materialsUsed.find((item) => {
        if (material) return item === value && item !== material.material;
        return item === value;
      }),
    );
  const handleMaterialSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (salesTaxExemptMaterials.includes(value as keyof typeof materials)) {
      formRef.current.setValues({
        ...formRef.current.values,
        tax: false,
      });
    }
  };

  const prepareFormData = (data: Pricing.PricingDataTransport) => {
    console.log('data', data);
    const newData: any = {
      ...data,
      isHauler,
    };

    const sizes = data.sizes
      ? Object.values(rollOffSizes).map((size) => {
          const found = data.sizes.find((item: any) => +item.size === +size);
          if (found !== undefined && found !== null) {
            const tonLimit =
              found.tonLimit !== null && found.tonLimit !== undefined && found.tonLimit >= 0 ? found.tonLimit : '';
            const haul = found.haulDollars !== null && found.haulDollars !== undefined ? found.haulDollars : '';
            const dump = found.dumpDollars !== null && found.dumpDollars !== undefined ? found.dumpDollars : '';
            const over = found.overDollars !== null && found.overDollars !== undefined ? found.overDollars : '';

            return {
              size,
              tonLimit,
              haul,
              dump,
              over,
              checked: true,
            } as FrontendSize;
          }
          return {
            size,
            tonLimit: '',
            haul: '',
            dump: '',
            over: '',
            checked: false,
          } as unknown as FrontendSize;
        })
      : [];

    if (data.sizes.length > 0 && data.sizes.every((item: any) => item.haul === data.sizes[0].haul)) {
      newData.editAllHauls = true;
      newData.haul = Number(data.sizes[0].haul || 0) / 100;
    }
    if (data.sizes.length > 0 && data.sizes.every((item: any) => item.dump === data.sizes[0].dump)) {
      newData.editAllDumps = true;
      newData.dump = Number(data.sizes[0].dump || 0) / 100;
    }
    if (data.sizes.length > 0 && data.sizes.every((item: any) => item.over === data.sizes[0].over)) {
      newData.editAllOverages = true;
      newData.over = Number(data.sizes[0].over || 0) / 100;
    }
    newData.sizes = sizes;
    if (isNaN(newData.over)) newData.over = '';
    if (data.sizes.length === 0) {
      newData.editAllHauls = true;
      newData.editAllDumps = true;
      newData.editAllOverages = true;
      newData.haul = 0;
      newData.dump = 0;
      newData.over = 0;
    }
    console.log('newData', newData);

    const test = {
      ...newData,
      sizes: sizes as FrontendSize[],
    };
    return test;
  };

  const handleSubmit = (
    data: Pricing.PricingDataTransport & { editAllHauls: boolean; editAllDumps: boolean; editAllOverages: boolean },
  ) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData.dump = newData.type === priceTypesEnums.flat ? '' : newData.dump;
    newData.over = newData.type === priceTypesEnums.ton ? (newData.over ? newData.over : newData.dump) : '';
    newData.sizes = newData.sizes.reduce((filtered: any, size: any) => {
      if (size.checked) {
        filtered.push({
          size: size.size,
          tonLimit: newData.type === priceTypesEnums.ton ? size.tonLimit : null,
          haul: size.haul,
          dump: size.dump,
          over: size.over,
        });
      }
      return filtered;
    }, []);
    onSubmit(newData);
  };

  const handleDoesNotServiceClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      formRef.current.setValues({
        ...formRef.current.values,
        type: priceTypesEnums.ton,
        dump: 0,
        over: 0,
        tax: true,
        allowOnlineCheckout: false,
        allowForLowerTonLimit: false,
        doesNotService: event.target.checked,
        sizes: Object.values(rollOffSizes).map((size) => ({
          size: size,
          checked: false,
          tonLimit: '',
          haul: '',
        })),
      });
    } else {
      formRef.current.setFieldValue('doNotService', event.target.checked);
    }
  };

  const finalPriceHelper = (values: any, sizeIndex: number, onlyOverage?: boolean) => {
    if (onlyOverage) {
      const overageValue =
        sourgumPricingFunctions.quotedPriceBuild(0, 0, 0, 0, ccRate, false, values.over * 100).overage / 100;
      return (
        <div>
          Overage with CC: <b>{formatUSD(overageValue)}</b>
        </div>
      );
    }
    const subtotalValue =
      sourgumPricingFunctions.quotedPriceBuild(
        values.type === priceTypesEnums.yard ? +values.sizes[sizeIndex].size : values.sizes[sizeIndex].tonLimit,
        values.sizes[sizeIndex].dump * 100,
        values.sizes[sizeIndex].haul * 100,
        0,
        0,
        false,
        0,
      ).total / 100;
    const subtotal = (
      <div>
        Subtotal: <b>{formatUSD(subtotalValue)}</b>
      </div>
    );
    if (isHauler) {
      return <div style={{ marginLeft: 15 }}>{subtotal}</div>;
    }
    const totalValue = sourgumPricingFunctions.quotedPriceBuild(
      values.type === priceTypesEnums.yard ? +values.sizes[sizeIndex].size : values.sizes[sizeIndex].tonLimit,
      values.sizes[sizeIndex].dump * 100,
      values.sizes[sizeIndex].haul * 100,
      values.tax ? zone.actualTaxRate || 0 : 0,
      ccRate,
      true,
      values.sizes[sizeIndex].over * 100,
    );
    const total = (
      <div>
        With{values.tax ? ' taxes & ' : ' '}CC: <b>{formatUSD(totalValue.total / 100)}</b>
      </div>
    );
    return (
      <div style={{ marginLeft: 15 }}>
        {subtotal}
        {total}
        {!values.editAllOverages ? (
          <div>
            Overage with CC: <b>{formatUSD(totalValue.overage / 100)}</b>
          </div>
        ) : null}
      </div>
    );
  };

  const handlePriceTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const newData = { type: newValue };
    if ((isHauler && newValue === priceTypesEnums.ton) || newValue !== priceTypesEnums.ton) {
      const newSizes = formRef.current.values.sizes.map((item: FrontendSize) => ({
        ...item,
        tonLimit: isHauler ? 0 : '',
        over: '',
        dump: '',
      }));

      //@ts-expect-error TODO: fix this
      newData.sizes = newSizes;
    }
    formRef.current.setValues({
      ...formRef.current.values,
      editAllHauls: newValue !== priceTypesEnums.ton ? false : true,
      ...newData,
    });
  };

  const handleSameSizeEveryClick = (e: React.ChangeEvent<HTMLInputElement>, key: any, field: string) => {
    const newValue = e.target.checked;
    const newData = { [field]: newValue };
    if (newValue === true) {
      const firstMatchingSize = formRef.current.values.sizes.find((item: FrontendSize) => item.checked);
      const newSizes = formRef.current.values.sizes.map((item: FrontendSize) => ({
        ...item,
        [key]: item.checked ? firstMatchingSize[key] : '',
      }));
      newData.sizes = newSizes;
      newData[key] = firstMatchingSize[key];
    }
    formRef.current.setValues({
      ...formRef.current.values,
      ...newData,
    });
  };

  const handleSizeRateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    sizeIndex: number,
    key: string,
    field: string,
  ) => {
    const newValue = e.target.value;
    const newSizes = formRef.current.values.sizes.map((size: FrontendSize, index: number) => ({
      ...size,
      [key]:
        (sizeIndex === index || formRef.current.values[field]) && size.checked
          ? newValue === ''
            ? ''
            : +newValue
          : size[key as keyof FrontendSize],
    }));
    const newData = {
      ...formRef.current.values,
      sizes: [...newSizes],
    };
    if (formRef.current.values[field]) newData[key] = newValue;
    formRef.current.setValues(newData);
  };

  const handleSizeClicked = (e: React.ChangeEvent<HTMLInputElement>, sizeIndex: number) => {
    const newValue = e.target.checked;
    const newSizes = formRef.current.values.sizes.map((size: FrontendSize, index: number) => {
      if (index !== sizeIndex) return { ...size };
      return {
        ...size,
        checked: newValue,
        tonLimit: '',
        haul: newValue && formRef.current.values.editAllHauls ? formRef.current.values.haul : '',
        dump: newValue && formRef.current.values.editAllHauls ? formRef.current.values.dump : '',
        over: newValue && formRef.current.values.editAllHauls ? formRef.current.values.over : '',
      };
    });
    formRef.current.setValues({
      ...formRef.current.values,
      sizes: [...newSizes],
    });
  };

  return (
    <Formik
      initialValues={
        material
          ? prepareFormData(material)
          : {
              material: '',
              type: priceTypesEnums.ton,
              isHauler,
              tax: true,
              allowOnlineCheckout: false,
              allowForLowerTonLimit: false,
              doesNotService: false,
              editAllHauls: true,
              editAllDumps: true,
              editAllOverages: true,
              sizes: Object.values(rollOffSizes).map(
                (size) =>
                  ({
                    size: size,
                    checked: true,
                    tonLimit: isHauler ? 0 : '',
                    haul: '',
                    dump: '',
                    over: '',
                  } as unknown as FrontendSize[]),
              ),
            }
      }
      validationSchema={MaterialSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {(formik) => {
        const { values } = formik;
        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  name={`material`}
                  component={Select}
                  label="Material"
                  labelId="material-label"
                  formControl={{ fullWidth: true, size: 'small' }}
                  inputProps={{
                    onChange: handleMaterialSelect,
                  }}
                >
                  {Object.entries(materials).map((item) => (
                    <MenuItem key={`material-${item[0]}`} value={item[0]} disabled={materialDisabled(item[0])}>
                      {item[1]}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name={`type`}
                  component={Select}
                  label="Price Type"
                  labelId="type-label"
                  formControl={{ fullWidth: true, size: 'small' }}
                  onChange={handlePriceTypeChange}
                >
                  {Object.entries(priceTypes).map((item) => (
                    <MenuItem key={`priceType-${item[0]}`} value={item[0]}>
                      {item[1]}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name={`haul`}
                  label="Haul rate"
                  type="number"
                  step="0.01"
                  component={TextField}
                  margin="dense"
                  fullWidth
                  required={values.editAllHauls}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      handleSizeRateChange(e, 0, 'haul', 'editAllHauls'),
                  }}
                />
              </Grid>
              {values.type !== priceTypesEnums.flat ? (
                <Grid item xs={6}>
                  <Field
                    name={`dump`}
                    label="Dump rate"
                    type="number"
                    step="0.01"
                    component={TextField}
                    margin="dense"
                    fullWidth
                    required={values.editAllDumps}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        handleSizeRateChange(e, 0, 'dump', 'editAllDumps'),
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={6} />
              )}
              {!isHauler && values.type === priceTypesEnums.ton ? (
                <>
                  <Grid item xs={6}>
                    <Field
                      name={`over`}
                      label="Overage rate"
                      type="number"
                      step="0.01"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      required={values.editAllOverages}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleSizeRateChange(e, 0, 'over', 'editAllOverages'),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                    {finalPriceHelper(values, 0, true)}
                  </Grid>
                </>
              ) : null}
              {isHauler ? null : (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Field
                        name="tax"
                        label={`Taxable ${
                          zone.actualTaxRate
                            ? ` (${(zone.actualTaxRate * 100).toFixed(3)}% ${zone.state} State Tax)`
                            : ''
                        }`}
                        checked={values.tax}
                        component={CheckBoxField}
                        margin="dense"
                      />
                      <p className="text-xs">
                        Taxes at checkout may be higher than the state tax rate due to local taxes.
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        name="allowOnlineCheckout"
                        label="Allow Online Checkout"
                        checked={values.allowOnlineCheckout}
                        component={CheckBoxField}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        name="allowForLowerTonLimit"
                        label="Allow for Lower Ton Limit"
                        checked={values.allowForLowerTonLimit}
                        component={CheckBoxField}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {isHauler ? (
                <Grid item xs={6}>
                  <Field
                    name="doesNotService"
                    label="Does Not Service"
                    checked={values.doesNotService}
                    component={CheckBoxField}
                    margin="dense"
                    onClick={handleDoesNotServiceClick}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} style={{ color: '#757575', fontSize: 17 }}>
                Available Sizes
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Field
                      name="editAllHauls"
                      label="Haul rate same for every size"
                      checked={values.editAllHauls}
                      component={CheckBoxField}
                      margin="dense"
                      fieldProps={{
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleSameSizeEveryClick(e, 'haul', 'editAllHauls'),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {values.type !== priceTypesEnums.flat ? (
                      <Field
                        name="editAllDumps"
                        label="Dump rate same for every size"
                        checked={values.editAllDumps}
                        component={CheckBoxField}
                        margin="dense"
                        fieldProps={{
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                            handleSameSizeEveryClick(e, 'dump', 'editAllDumps'),
                        }}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {!isHauler && values.type === priceTypesEnums.ton ? (
                      <Field
                        name="editAllOverages"
                        label="Overage same for every size"
                        checked={values.editAllOverages}
                        component={CheckBoxField}
                        margin="dense"
                        fieldProps={{
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                            handleSameSizeEveryClick(e, 'over', 'editAllOverages'),
                        }}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              {values.sizes.map((size: any, sizeIndex: number) => (
                <Grid item xs={12} key={size.size}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Field
                      name={`sizes[${sizeIndex}].checked`}
                      label={size.size}
                      checked={size.checked}
                      component={CheckBoxField}
                      margin="dense"
                      fieldProps={{
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleSizeClicked(e, sizeIndex),
                      }}
                    />
                    <Field
                      name={`sizes[${sizeIndex}].haul`}
                      label="Haul rate"
                      type="number"
                      step="0.01"
                      component={TextField}
                      margin="dense"
                      style={{ maxWidth: 100, marginRight: 10 }}
                      //@ts-expect-error this is correct checked is a property of sizes on front end
                      required={values.sizes[sizeIndex].checked}
                      //@ts-expect-error this is correct checked is a property of sizes on front end
                      disabled={!values.sizes[sizeIndex].checked || values.editAllHauls}
                      InputProps={{
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleSizeRateChange(e, sizeIndex, 'haul', 'editAllHauls'),
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    {values.type !== priceTypesEnums.flat ? (
                      <Field
                        name={`sizes[${sizeIndex}].dump`}
                        label="Dump rate"
                        type="number"
                        step="0.01"
                        component={TextField}
                        margin="dense"
                        style={{ maxWidth: 100, marginRight: 10 }}
                        //@ts-expect-error this is correct checked is a property of sizes on front end
                        required={values.sizes[sizeIndex].checked}
                        //@ts-expect-error this is correct checked is a property of sizes on front end
                        disabled={!values.sizes[sizeIndex].checked || values.editAllDumps}
                        InputProps={{
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                            handleSizeRateChange(e, sizeIndex, 'dump', 'editAllDumps'),
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    ) : null}
                    {!isHauler && values.type === priceTypesEnums.ton ? (
                      <Field
                        name={`sizes[${sizeIndex}].over`}
                        label="Overage rate"
                        type="number"
                        step="0.01"
                        component={TextField}
                        margin="dense"
                        style={{ maxWidth: 100, marginRight: 10 }}
                        //@ts-expect-error this is correct checked is a property of sizes on front end
                        required={values.sizes[sizeIndex].checked}
                        //@ts-expect-error this is correct checked is a property of sizes on front end
                        disabled={!values.sizes[sizeIndex].checked || values.editAllOverages}
                        InputProps={{
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                            handleSizeRateChange(e, sizeIndex, 'over', 'editAllOverages'),
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    ) : null}
                    {values.type === priceTypesEnums.ton ? (
                      <Field
                        name={`sizes[${sizeIndex}].tonLimit`}
                        label="Ton limit"
                        type="number"
                        step="0.01"
                        component={TextField}
                        margin="dense"
                        style={{ maxWidth: 80, marginRight: 10 }}
                        //@ts-expect-error this is correct checked is a property of sizes on front end
                        required={values.sizes[sizeIndex].checked}
                        //@ts-expect-error this is correct checked is a property of sizes on front end
                        disabled={!values.sizes[sizeIndex].checked}
                      />
                    ) : null}
                    {finalPriceHelper(values, sizeIndex)}
                  </div>
                </Grid>
              ))}
            </Grid>
            <div className="flex justify-end pt-4 gap-4">
              <button className="btn-dark-grey-outlined" onClick={onCancel} disabled={isLoading} type="button">
                Cancel
              </button>
              <Button
                className="btn-primary"
                type="submit"
                disabled={!formik.isValid || !formik.dirty}
                loading={isLoading}
              >
                Save
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MaterialPricingForm;
