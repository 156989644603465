import { useEffect, useRef, useState } from 'react';
import { Pricing } from '@alliance-disposal/transport-types';
// or "const mapboxgl = require('mapbox-gl');"
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { HandRaisedIcon, PencilIcon, ScissorsIcon } from '@heroicons/react/24/solid';
import { Divider, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import * as turf from '@turf/turf';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useAuthToken } from '../../hooks/authhook';
import CopyZoneModal from './components/CopyZoneModal';
import NegativeZoneModal from './components/NegativeZoneModal';
import PostalCodesModal from './components/PostalCodesModal';
import RadiusFromAddressModal, { RadiusFromAddressSubmit } from './components/RadiusFromAddressModal';
import SearchOSMModal, { OSM_SearchResult } from './components/SearchForTownModal';
import { addFeatureToMap } from './utils/addFeatureToMap';
import { isPolygonClosed } from './utils/isPolygonClosed';

mapboxgl.accessToken = 'pk.eyJ1IjoibHVjc291cmd1bSIsImEiOiJjbGE5dGc1ZHIwMWJkM25ud3Vrbzd1N2lxIn0.jALyAIrgB-YWlPilyAKq8A';
export type EditModes = null | 'draw' | 'cut' | 'circle';

type Helpers =
  | 'RADIUS_FROM_ADDRESS'
  | 'ZONE_AS_NEGATIVE_SPACE'
  | 'ADD_ZIPCODE'
  | 'ADD_CITY'
  | 'ADD_COUNTY'
  | 'COPY_ZONE';

/**
 *  Returns an array of Service Areas with an optional `ID` if it
 *  is an edit to an existing service area.
 *
 */
export type MapEditorSubmit = Pricing.HaulerServiceAreaMutate & { id?: string };

const PricingZoneMap = ({
  serviceAreas,
  onSubmit,
  zones,
  auxillaryZones,
}: {
  serviceAreas?: Pricing.ServiceAreaTransport[];
  onSubmit: (serviceAreas: MapEditorSubmit[]) => void;
  zones?: Pricing.PricingTransport[] | Pricing.HaulerPricingZone[] | Pricing.ProductPricingZoneTransport[];
  auxillaryZones?: Pricing.PricingTransport[];
}) => {
  // const { setIsLoading, setShowToast } = useGlobalUi();
  const mapContainer = useRef<any>(null);
  const map = useRef<null | mapboxgl.Map>(null);
  let sourceIDs: string[] = [];
  const [zonesToDelete, setZonesToDelete] = useState<mapboxgl.MapboxGeoJSONFeature[]>([]);
  const featuresRef = useRef<Record<string, GeoJSON.Feature>>({});
  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteMode, setDeleteMode] = useState(false);
  const del = useRef(false);
  const currentEdit = useRef<[number, number][]>([]);
  const [editModeState, setEditModeState] = useState<EditModes>(null);
  const editMode = useRef<EditModes>(null);
  const [helper, setHelper] = useState<Helpers | null>(null);
  const { token } = useAuthToken();

  const handleFeatureClick = (
    e: mapboxgl.MapMouseEvent & {
      features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
    } & mapboxgl.EventData,
  ) => {
    if (!(editMode.current === null && del.current)) return;
    console.log('features', e.features);
    if (!e.features?.[0]?.source) return;
    map?.current?.removeSource(e.features?.[0].source);
    map?.current?.removeLayer('fill' + e.features?.[0].source);
    map?.current?.removeLayer('outline' + e.features?.[0].source);
    delete featuresRef.current?.[e.features?.[0].source];
    setZonesToDelete([...zonesToDelete, e.features?.[0]]);
    //console.log("aaa", featuresRef, zonesToDelete);
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-70.9, 42.35],
      zoom: 5,
    });
    //console.log("MAP", map);
  }, []);
  useEffect(() => {
    const tempFeatures: GeoJSON.Feature[] = [];

    serviceAreas?.forEach((serviceArea: any) => {
      const geoJSON: { features: GeoJSON.Feature[] } = JSON.parse(serviceArea.boundary.geoJSON);

      if (geoJSON.features.length > 0) {
        geoJSON.features.forEach((feature) => {
          if (feature.geometry.type === 'GeometryCollection') {
            feature.geometry.geometries.forEach((geometry: any, index) => {
              const polygon = turf.polygon(geometry.coordinates);
              tempFeatures.push(polygon);
              featuresRef.current[serviceArea.id + index] = polygon;
            });
          } else if (feature.geometry.type === 'Polygon') {
            tempFeatures.push(feature);
            featuresRef.current[serviceArea.id] = feature;
          }
        });
      }
    });
  }, [map.current, serviceAreas]);

  // add geojson to map
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    if (!serviceAreas) return; // wait for pricingZone to initialize

    sourceIDs.forEach((sourceID) => {
      if (map.current) {
        map.current.removeLayer('fill' + sourceID);
        map.current.removeLayer('outline' + sourceID);
        map.current.removeSource(sourceID);
      }
    });

    sourceIDs.splice(0, sourceIDs.length);

    map.current.on('load', () => {
      // in theory the source would be the service area id

      Object.keys(featuresRef.current).forEach((key) => {
        const sourceID = key;
        const feature = featuresRef.current[key];

        if (!map.current) return;
        if (map.current.getSource(sourceID)) return;
        addFeatureToMap(map.current, sourceID, feature, {
          click: handleFeatureClick,
          // mousemove: handleMouseMoveWithinFill,
        });
        sourceIDs.push(sourceID);
      });

      // ----------------- OUTSIDE EXISTING ZONES -----------------
      if (!map.current) return;
      map.current.on('click', handleMapClick);

      map.current.on('mousemove', handleMapMouseMove);

      zoomToFitFeatures();
    });

    return () => {
      map.current?.off('click', handleMapClick);
      map.current?.off('mousemove', handleMapMouseMove);
    };
  }, [featuresRef.current]);

  const zoomToFitFeatures = () => {
    if (!map.current || Object.keys(featuresRef.current).length < 1) return;
    const bounds = new mapboxgl.LngLatBounds();
    Object.keys(featuresRef.current).forEach((key) => {
      const feature = featuresRef.current[key];
      if (feature.geometry.type === 'Polygon') {
        feature.geometry.coordinates[0].forEach((coordinate) => {
          bounds.extend([coordinate[0], coordinate[1]]);
        });
      }
    });
    map.current.fitBounds(bounds, {
      padding: 100,
    });
  };

  const handleMapClick = (
    e: mapboxgl.MapMouseEvent & {
      features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
    } & mapboxgl.EventData,
  ) => {
    //console.log("click", e.lngLat, e);
    handleNextPoint(e.lngLat);
  };

  const handleNextPoint = (coordinates: { lng: number; lat: number }) => {
    if (!map.current) return;
    if (!editMode.current) return;

    // if it's the first click, just add the point otherwise check for snapping and add the point
    if (currentEdit.current.length === 0) {
      currentEdit.current.push([coordinates.lng, coordinates.lat]);
    } else {
      const closestPoint: [number, number] = currentEdit.current.reduce((prev, curr) => {
        if (!map.current) return prev;
        const prevDistance = map.current.project(prev).dist(map.current.project(coordinates));
        const currDistance = map.current.project(curr).dist(map.current.project(coordinates));
        return prevDistance < currDistance ? prev : curr;
      }, currentEdit.current[0]);

      const distance = map.current.project(closestPoint).dist(map.current.project(coordinates));

      if (distance < 10) {
        coordinates.lng = closestPoint[0];
        coordinates.lat = closestPoint[1];
      }
      currentEdit.current.push([coordinates.lng, coordinates.lat]);
    }

    if (currentEdit.current.length >= 2 && map.current) {
      // if the current edit has more than 2 points, draw a line string
      const lineString: GeoJSON.Feature = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: currentEdit.current,
        },
      };

      const source = map.current.getSource('currentEdit');
      if (source && source.type === 'geojson') {
        source.setData(lineString);
      } else {
        map.current.addSource('currentEdit', {
          type: 'geojson',
          data: lineString,
        });
        map.current.addLayer({
          id: 'currentEdit-lines',
          type: 'line',
          source: 'currentEdit',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#000',
            'line-width': 2,
            'line-dasharray': [2, 2],
          },
        });
      }
      // if the last point is the same as the first point, close the polygon
      if (isPolygonClosed(currentEdit.current) || (currentEdit.current.length >= 2 && editMode.current === 'circle')) {
        //console.log("polygon closed", currentEdit.current);

        // if we are in draw mode, add the polygon to the map
        if (editMode.current === 'draw' || editMode.current === 'circle') {
          let polygon: GeoJSON.Feature = {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [currentEdit.current],
            },
          };

          if (editMode.current === 'circle') {
            const center = turf.point([currentEdit.current[0][0], currentEdit.current[0][1]]);

            const distanceFromCenter = turf.distance(center, turf.point([coordinates.lng, coordinates.lat]));

            polygon = turf.circle(center, distanceFromCenter, {
              units: 'kilometers',
            });
          }

          // find which service areas the edit polygon intersects with
          const intersectingServiceAreas = Object.keys(featuresRef.current).filter((key) => {
            const feature = featuresRef.current[key];
            if (feature.geometry.type === 'Polygon') {
              return turf.booleanOverlap(polygon, feature);
            }
            return false;
          });

          // const serviceAreaID = Object.keys(featuresRef.current).find((key) => {
          //   const feature = featuresRef.current[key];
          //   if (feature.geometry.type === 'Polygon') {
          //     return turf.booleanOverlap(polygon, feature);
          //   }
          //   return false;
          // });

          if (intersectingServiceAreas.length === 0) {
            const sourceID = 'newPolygon' + Math.random();
            // add the polygon to the map
            addFeatureToMap(map.current, sourceID, polygon, {
              click: handleFeatureClick,
            });
            featuresRef.current[sourceID] = polygon;
            sourceIDs.push(sourceID);
            //console.log("added new polygon", sourceID, sourceIDs);
          }

          // combine the polygons if they are in the same service area

          if (intersectingServiceAreas.length > 0 && polygon.geometry.type === 'Polygon') {
            let editPolygon: turf.helpers.Feature<turf.helpers.Polygon, turf.helpers.Properties>;

            if (editMode.current === 'circle') {
              const center = turf.point([currentEdit.current[0][0], currentEdit.current[0][1]]);

              const distanceFromCenter = turf.distance(center, turf.point([coordinates.lng, coordinates.lat]));

              editPolygon = turf.circle(center, distanceFromCenter, {
                units: 'kilometers',
              });
            } else {
              editPolygon = turf.polygon([currentEdit.current]);
            }

            // combine the polygons
            const combinedPolygon = intersectingServiceAreas
              .map((key) => {
                return featuresRef.current[key];
              })
              .reduce((prev, curr) => {
                if (curr.geometry.type === 'Polygon' && prev.geometry.type === 'Polygon') {
                  const tempPrev = turf.polygon(prev.geometry.coordinates);
                  const tempCurr = turf.polygon(curr.geometry.coordinates);
                  const result = turf.union(tempPrev, tempCurr);

                  if (result) {
                    return result;
                  }

                  return prev;
                }
                return prev;
              }, editPolygon);

            if (combinedPolygon) {
              const sourceID = intersectingServiceAreas[0];
              // add the polygon to the map
              // remove the old polygons
              //console.log("combined polygon", intersectingServiceAreas);

              intersectingServiceAreas
                .filter((key) => key !== sourceID)
                .forEach((key) => {
                  delete featuresRef.current[key];
                  if (!map.current) return;
                  //console.log("removing", key);
                  removeFeature(map.current, key);
                });

              // set the new polygon
              featuresRef.current[sourceID] = combinedPolygon;
              const source = map.current.getSource(sourceID);
              if (source && source.type === 'geojson') {
                source.setData(featuresRef.current[sourceID]);
              }

              // remove source ids that are in intersecting service areas but not the first one
              sourceIDs = sourceIDs.filter((id) => {
                return !intersectingServiceAreas.includes(id);
              });

              // add the new source id
              sourceIDs.push(sourceID);
            }
          }
        }

        if (editMode.current === 'cut') {
          // there are 2 cases here
          // 1. the edit polygon completely contained within a service area (polygon) - cut a hole in the service area
          // 2. the edit polygon partially overlaps a service area (polygon) - subtract the edit polygon from the service area
          // 3. the edit polygon completely overlaps a service area (polygon) - remove the service area

          // create a turf polygon from the edit polygon
          const editPolygon = turf.polygon([currentEdit.current]);

          // check if any of the polygons are completely contained within the edit polygon, if so we return its id
          const featuresContainedByEditID = Object.keys(featuresRef.current).filter((key) => {
            const feature = featuresRef.current[key];
            if (feature.geometry.type === 'Polygon') {
              return turf.booleanContains(editPolygon, feature);
            }
            return false;
          });

          for (const featureContainedByEditID of featuresContainedByEditID) {
            // delete the feature
            const source = map.current.getSource(featureContainedByEditID);
            if (source && source.type === 'geojson') {
              removeFeature(map.current, featureContainedByEditID);
            }

            delete featuresRef.current[featureContainedByEditID];
          }
          // check if the edit polygon overlaps any of the service areas (polygons)
          const featuresOverlappedByEditID = Object.keys(featuresRef.current).filter((key) => {
            const feature = featuresRef.current[key];
            if (feature.geometry.type === 'Polygon') {
              return turf.booleanOverlap(editPolygon, feature);
            }
            return false;
          });

          for (const featureOverlappedByEditID of featuresOverlappedByEditID) {
            // subtract the edit polygon from the service area
            const feature = featuresRef.current[featureOverlappedByEditID];
            if (feature.geometry.type === 'Polygon') {
              const original = turf.polygon(feature.geometry.coordinates);
              const combined = turf.difference(original, editPolygon);
              if (!combined) return;
              if (combined.geometry.type === 'Polygon') {
                featuresRef.current[featureOverlappedByEditID] = combined;
              } else {
                combined.geometry.coordinates.forEach((x, index) => {
                  const id = (featureOverlappedByEditID || '') + index + `${Math.random()}`;
                  featuresRef.current[id] = {
                    ...combined,
                    geometry: { coordinates: x, type: 'Polygon' },
                  };

                  if (!map.current) return;
                  addFeatureToMap(map.current, id, featuresRef.current[id]);
                  sourceIDs.push(id);
                });
                removeFeature(map.current, featureOverlappedByEditID);
                delete featuresRef.current[featureOverlappedByEditID];
              }

              const source = map.current.getSource(featureOverlappedByEditID);
              if (source && source.type === 'geojson') {
                source.setData(featuresRef.current[featureOverlappedByEditID]);
              }
            }
          }

          // check if any of the service area polygons are completely contain the edit polygon
          const featuresContainsEditID = Object.keys(featuresRef.current).filter((key) => {
            const feature = featuresRef.current[key];
            if (feature.geometry.type === 'Polygon') {
              return turf.booleanContains(feature, editPolygon);
            }
            return false;
          });

          for (const featureContainsEditID of featuresContainsEditID) {
            // subtract the edit polygon from the service area
            const feature = featuresRef.current[featureContainsEditID];
            if (feature.geometry.type === 'Polygon') {
              const original = turf.polygon(feature.geometry.coordinates);
              const combined = turf.difference(original, editPolygon);
              if (!combined) return;
              if (combined.geometry.type === 'Polygon') {
                featuresRef.current[featureContainsEditID] = combined;
              } else {
                combined.geometry.coordinates.forEach((x, index) => {
                  const id = (featuresOverlappedByEditID.join('-') || '') + index + `${Math.random()}`;
                  featuresRef.current[id] = {
                    ...combined,
                    geometry: { coordinates: x, type: 'Polygon' },
                  };
                  if (!map.current) return;
                  addFeatureToMap(map.current, id, featuresRef.current[id]);
                  sourceIDs.push(id);
                });
                removeFeature(map.current, featureContainsEditID);
                delete featuresRef.current[featureContainsEditID];
              }

              const source = map.current.getSource(featureContainsEditID);
              if (source && source.type === 'geojson') {
                source.setData(featuresRef.current[featureContainsEditID]);
              }
            }
          }
        }

        editOver();
        /*  Redundant code
        // reset the current edit
        currentEdit.current = [];
        editMode.current = null;
        setEditModeState(null);

        // remove the current edit source
        if (map.current.getSource('currentEdit')) {
          map.current.removeLayer('currentEdit-lines');
          map.current.removeLayer('currentEdit-points');
          map.current.removeSource('currentEdit');
        } */
      }
    }
  };

  const handleMapMouseMove = (
    e: mapboxgl.MapMouseEvent & {
      features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
    } & mapboxgl.EventData,
  ) => {
    if (!map.current) return;
    if (!editMode.current) {
      map.current.getCanvas().style.cursor = '';
      return;
    }

    map.current.getCanvas().style.cursor = 'crosshair';

    // if the length of the current edit is greater than 0, draw a line from the last point to the mouse
    if (currentEdit.current.length > 0 && map.current) {
      const coordinates = e.lngLat;

      const closestPoint: [number, number] = currentEdit.current.reduce((prev, curr) => {
        if (!map.current) return prev;
        const prevDistance = map.current.project(prev).dist(map.current.project(coordinates));
        const currDistance = map.current.project(curr).dist(map.current.project(coordinates));
        return prevDistance < currDistance ? prev : curr;
      }, currentEdit.current[0]);

      const distance = map.current.project(closestPoint).dist(map.current.project(coordinates));

      if (distance < 10) {
        coordinates.lng = closestPoint[0];
        coordinates.lat = closestPoint[1];
      }

      let currentEditFeature: GeoJSON.Feature = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [...currentEdit.current, [e.lngLat.lng, e.lngLat.lat]],
        },
      };

      if (editMode.current === 'circle') {
        const center = turf.point([currentEdit.current[0][0], currentEdit.current[0][1]]);

        const distanceFromCenter = turf.distance(center, turf.point([e.lngLat.lng, e.lngLat.lat]));

        currentEditFeature = turf.circle(center, distanceFromCenter, {
          units: 'kilometers',
        });
      }

      const source = map.current.getSource('currentEdit');
      if (source && source.type === 'geojson') {
        source.setData(currentEditFeature);
      } else {
        map.current.addSource('currentEdit', {
          type: 'geojson',
          data: currentEditFeature,
        });

        map.current.addLayer({
          id: 'currentEdit-lines',
          type: 'line',
          source: 'currentEdit',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#000',
            'line-width': 2,
            'line-dasharray': [2, 2],
          },
        });

        // add a circle to the last point
        map.current.addLayer({
          id: 'currentEdit-points',
          type: 'circle',
          source: 'currentEdit',
          paint: {
            'circle-radius': 5,
            'circle-color': '#fff',
            'circle-stroke-width': 2,
            'circle-stroke-color': '#000',
          },
        });
      }
    }
  };

  const removeFeature = (map: mapboxgl.Map, id: string) => {
    if (map.getSource(id)) {
      map.removeLayer('fill' + id);
      map.removeLayer('outline' + id);
      map.removeSource(id);
      sourceIDs = sourceIDs.filter((sourceID) => sourceID !== id);
    }
  };

  const editOver = () => {
    if (map.current) {
      const source = map.current.getSource('currentEdit');
      if (source && source.type === 'geojson') {
        if (map.current.getLayer('currentEdit-lines')) {
          map.current.removeLayer('currentEdit-lines');
        }

        if (map.current.getLayer('currentEdit-points')) {
          map.current.removeLayer('currentEdit-points');
        }

        map.current.removeSource('currentEdit');
      }
    }
    currentEdit.current = [];
    editMode.current = null;
    setEditModeState(null);
  };

  const handleHelperSelect = (helper: Helpers) => setHelper(helper);

  const handleRadiusFromAddressSubmit = (res: RadiusFromAddressSubmit) => {
    // set the edit mode to circle
    editMode.current = 'circle';

    // add the first point to the current edit
    currentEdit.current = [[res.coordinates.lng, res.coordinates.lat]];

    // find any point that would be on the circle and call handle click
    const point = turf.point([res.coordinates.lng, res.coordinates.lat]);
    const circle = turf.circle(point, res.radius, {
      units: res.unit,
    });

    const circlePoints = turf.explode(circle);

    // grab the first point
    const firstPoint = circlePoints.features[0];

    // call handle click
    handleNextPoint({
      lng: firstPoint.geometry.coordinates[0],
      lat: firstPoint.geometry.coordinates[1],
    });
    setHelper(null);
  };

  // const handleNegativeZone = () => {
  //   console.log('handleNegativeZone');
  //   setHelper(null);
  // };

  const handleSubmit = () => {
    const features: MapEditorSubmit[] = Object.keys(featuresRef.current).map((key) => {
      const feature = featuresRef.current[key];

      const serviceArea: MapEditorSubmit = {
        serviceArea: JSON.stringify(feature),
        serviceAreaFormat: 'GEOJSON',
      };

      if (key.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/)) {
        serviceArea.id = key;
      }

      return serviceArea;
    });

    onSubmit(features);
  };

  const handleSearchOSM = async (searchString: string): Promise<OSM_SearchResult[]> => {
    const res = await axios.get(`https://nominatim.openstreetmap.org/search`, {
      params: {
        format: 'json',
        q: searchString,
        limit: 5,
        countrycodes: 'us',
        polygon_geojson: 1,
        addressdetails: 1,
      },
    });

    return res.data;
  };

  const handleAddZipcode = async (postalCodes: string[]) => {
    // verify that the zipcode is valid
    const validZipcodes = postalCodes.filter((zipcode) => zipcode.match(/^\d{5}$/));

    const res = await axios.get(`${import.meta.env.VITE_BASE_API_URL}/pricing/zip`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        zips: validZipcodes.join(','),
      },
    });
    // setIsLoading(false);
    const featureCollection: {
      geoJSON: Pricing.HaulerPricingZone['serviceAreas'][0]['serviceArea']['geoJSON'];
      kmlFragment: Pricing.HaulerPricingZone['serviceAreas'][0]['serviceArea']['kml'];
      zip: string;
    }[] = res.data;
    if (featureCollection.length === 0) {
      // setShowToast({ severity: 'error', message: 'Zipcode not found.' });
      alert('Zip code not found');
      return;
    }

    featureCollection.forEach((feature) => {
      const geoJSON: { features: any } = JSON.parse(feature.geoJSON);
      console.log(geoJSON);
      geoJSON.features.forEach((feature: GeoJSON.Feature) => {
        if (feature.geometry.type === 'Polygon') {
          feature.geometry.coordinates.forEach((coordinateSet: any, index: number) => {
            editMode.current = index === 0 ? 'draw' : 'cut';
            currentEdit.current = coordinateSet.map((coordinate: any) => [coordinate[0], coordinate[1]]);
            handleNextPoint({
              lng: coordinateSet[0][0],
              lat: coordinateSet[0][1],
            });
          });
        } else if (feature.geometry.type === 'GeometryCollection') {
          feature.geometry.geometries.forEach((geometry: any) => {
            if (geometry.type === 'Polygon') {
              geometry.coordinates.forEach((coordinateSet: any, index: number) => {
                editMode.current = index === 0 ? 'draw' : 'cut';
                currentEdit.current = coordinateSet.map((coordinate: any) => [coordinate[0], coordinate[1]]);
                handleNextPoint({
                  lng: coordinateSet[0][0],
                  lat: coordinateSet[0][1],
                });
              });
            }
          });
        }
      });
    });
  };

  const handleSubtractZone = (
    zone: Array<Pricing.PricingTransport[] | Pricing.HaulerPricingZone | Pricing.ProductPricingZoneTransport>,
  ) => {
    if (zone) {
      const featureCollection: {
        geoJSON: Pricing.HaulerPricingZone['serviceAreas'][0]['serviceArea']['geoJSON'];
        kmlFragment: Pricing.HaulerPricingZone['serviceAreas'][0]['serviceArea']['kml'];
        zip: string;
        // @ts-ignore
      }[] = zone?.serviceAreas.map((serviceArea: any) => {
        return {
          geoJSON: serviceArea.boundary.geoJSON,
          kmlFragment: serviceArea.boundary.kml,
          zip: serviceArea.zip,
        };
      });

      featureCollection.forEach((feature) => {
        const geoJSON: { features: any } = JSON.parse(feature.geoJSON);
        geoJSON.features.forEach((feature: any) => {
          feature.geometry.coordinates.forEach((coordinateSet: any) => {
            editMode.current = 'cut';
            currentEdit.current = coordinateSet.map((coordinate: any) => [coordinate[0], coordinate[1]]);
            handleNextPoint({
              lng: coordinateSet[0][0],
              lat: coordinateSet[0][1],
            });
          });
        });
      });
    }
  };

  const handleCopyZone = (
    zone: Array<Pricing.PricingTransport[] | Pricing.HaulerPricingZone | Pricing.ProductPricingZoneTransport>,
  ) => {
    if (zone) {
      const featureCollection: {
        geoJSON: Pricing.HaulerPricingZone['serviceAreas'][0]['serviceArea']['geoJSON'];
        kmlFragment: Pricing.HaulerPricingZone['serviceAreas'][0]['serviceArea']['kml'];
        zip: string;
        // @ts-ignore
      }[] = zone?.serviceAreas.map((serviceArea: any) => {
        return {
          geoJSON: serviceArea.boundary.geoJSON,
          kmlFragment: serviceArea.boundary.kml,
          zip: serviceArea.zip,
        };
      });

      featureCollection.forEach((feature) => {
        const geoJSON: { features: any } = JSON.parse(feature.geoJSON);
        geoJSON.features.forEach((feature: any) => {
          feature.geometry.coordinates.forEach((coordinateSet: any) => {
            editMode.current = 'draw';
            currentEdit.current = coordinateSet.map((coordinate: any) => [coordinate[0], coordinate[1]]);
            handleNextPoint({
              lng: coordinateSet[0][0],
              lat: coordinateSet[0][1],
            });
          });
        });
      });
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <RadiusFromAddressModal
        open={helper === 'RADIUS_FROM_ADDRESS'}
        // open={true}
        onCancel={() => {
          setHelper(null);
        }}
        onSubmit={handleRadiusFromAddressSubmit}
      />

      {zones && (
        <NegativeZoneModal
          open={helper === 'ZONE_AS_NEGATIVE_SPACE'}
          onCancel={() => setHelper(null)}
          zones={zones}
          onSubmit={(zone) => {
            console.log(zone, 'zone zoneeeeeee');
            handleSubtractZone(zone as any);
            setHelper(null);
          }}
        />
      )}

      {auxillaryZones && (
        <CopyZoneModal
          open={helper === 'COPY_ZONE'}
          zones={auxillaryZones as any}
          onCancel={() => setHelper(null)}
          onSubmit={(zone) => {
            handleCopyZone(zone as any);
            setHelper(null);
          }}
        />
      )}

      <PostalCodesModal open={helper === 'ADD_ZIPCODE'} onClose={() => setHelper(null)} onSubmit={handleAddZipcode} />
      <SearchOSMModal
        open={helper === 'ADD_CITY'}
        onCancel={() => setHelper(null)}
        onSearch={handleSearchOSM}
        onSubmit={(res, mode) => {
          res.coordinates.forEach((coordinateSet) => {
            editMode.current = mode || 'draw';
            currentEdit.current = coordinateSet.map((coordinate) => [coordinate[0], coordinate[1]]);
            handleNextPoint({
              lng: coordinateSet[0][0],
              lat: coordinateSet[0][1],
            });
            setHelper(null);
          });

          setHelper(null);
        }}
      />
      <div className="bg-white rounded shadow-dark overflow-hidden flex flex-row justify-start items-center gap-2 p-2">
        <Tooltip title="Move around the map">
          <IconButton
            onClick={() => {
              editMode.current = null;
              setEditModeState(null);
              del.current = false;
              setDeleteMode(false);
            }}
            color={!editModeState && !deleteMode ? 'primary' : 'default'}
            size="small"
          >
            <HandRaisedIcon className="h-6 w-6" />
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" flexItem />
        {/* Draw button with pencil icon */}
        <IconButton
          size="small"
          color={editModeState === 'draw' ? 'primary' : 'default'}
          onClick={() => {
            setEditModeState('draw');
            editMode.current = 'draw';

            currentEdit.current = [];
          }}
        >
          <PencilIcon className="w-6 h-6" />
        </IconButton>
        <Divider orientation="vertical" flexItem />
        {/* Cut button with icon   */}
        <IconButton
          size="small"
          color={editModeState === 'cut' ? 'primary' : 'default'}
          onClick={() => {
            setEditModeState('cut');
            editMode.current = 'cut';
            currentEdit.current = [];
          }}
        >
          <ScissorsIcon className="h-6 w-6" />
        </IconButton>

        <Divider orientation="vertical" flexItem />
        <IconButton
          size="small"
          color={editModeState === 'circle' ? 'primary' : 'default'}
          onClick={() => {
            console.log('hit');
            setEditModeState('circle');
            editMode.current = 'circle';
            currentEdit.current = [];
          }}
        >
          {/* <Adjust /> */}
          <div className="relative">
            <div
              className={`h-5 w-5 rounded-full border-2 ${
                editModeState === 'circle' ? 'border-wayste-blue-400' : 'opacity-50 border-black'
              }`}
            />
            <div
              className={`h-2 w-2 rounded-full absolute top-1/2 left-1/2 ${
                editModeState === 'circle' ? 'bg-wayste-blue-400' : 'opacity-50 bg-black'
              }`}
              style={{ transform: 'translate(-50%, -50%)' }}
            />
          </div>
        </IconButton>

        {/* Helpers Menu */}
        <button
          className="btn-secondary-text-only"
          aria-haspopup="true"
          onClick={(e) => {
            editOver();
            setHelpMenuAnchorEl(helpMenuAnchorEl ? null : e.currentTarget);
          }}
        >
          Helpers
          <ChevronDownIcon className="h-4 w-4 ml-1" />
        </button>
        <Menu
          anchorEl={helpMenuAnchorEl}
          keepMounted
          open={Boolean(helpMenuAnchorEl)}
          title="Helpers"
          onClose={() => setHelpMenuAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              setHelpMenuAnchorEl(null);
              handleHelperSelect('COPY_ZONE');
            }}
          >
            Copy From Existing Zones
          </MenuItem>
          <MenuItem
            onClick={() => {
              setHelpMenuAnchorEl(null);
              handleHelperSelect('RADIUS_FROM_ADDRESS');
            }}
          >
            Radius from Address
          </MenuItem>

          <MenuItem
            onClick={() => {
              setHelpMenuAnchorEl(null);
              handleHelperSelect('ZONE_AS_NEGATIVE_SPACE');
            }}
            disabled={!zones}
          >
            Use Zone as Negative
          </MenuItem>
          <MenuItem
            onClick={() => {
              setHelpMenuAnchorEl(null);
              handleHelperSelect('ADD_ZIPCODE');
            }}
          >
            Add Zip Codes
          </MenuItem>
          <MenuItem
            onClick={() => {
              setHelpMenuAnchorEl(null);
              handleHelperSelect('ADD_CITY');
            }}
          >
            Add/Cut City or County
          </MenuItem>
          {/*           <MenuItem
            onClick={() => {
              setHelpMenuAnchorEl(null);
              handleHelperSelect('ADD_COUNTY');
            }}
            disabled
          >
            Add County
          </MenuItem> */}
        </Menu>
        {/* Save Button */}
        <button type="button" className="btn-primary ml-auto" onClick={handleSubmit}>
          Save
        </button>
      </div>
      <div className="bg-white shadow-dark rounded overflow-hidden relative">
        <div style={{ width: '100%', height: '75vh' }} ref={mapContainer} />
      </div>
    </div>
  );
};

export default PricingZoneMap;
