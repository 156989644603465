import React, { useEffect, useRef, useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { US_PHONE_NUMBER_REGEXP, formatE164ToUSPhoneNumber, formatUSPhoneNumberToE164 } from '@wayste/utils';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CMLeadForm from './CMLeadForm';
import QuoteForm from './CMQuoteForm';
import ContactedForm from './ContactedForm';
import MarketingForm from './MarketingForm';

const leadSchema = Yup.object().shape(
  {
    firstName: Yup.string().required('A first name is required'),
    lastName: Yup.string(),
    company: Yup.string(),
    email: Yup.string()
      .email()
      .when('phoneNumber', {
        is: (phoneNumber) => !phoneNumber || phoneNumber.length === 0,
        then: Yup.string().email().required('A phone number or email is required'),
        otherwise: Yup.string().email(),
      }),
    phoneNumber: Yup.string().when('email', {
      is: (email) => !email || email.length === 0,
      then: Yup.string()
        .matches(US_PHONE_NUMBER_REGEXP, 'Must be a valid phone number, e.g. (555) 867-5309')
        .required('A phone number or email is required'),
      otherwise: Yup.string().matches(US_PHONE_NUMBER_REGEXP, 'Must be a valid phone number, e.g. (555) 867-5309'),
    }),
    requestedStartDate: Yup.date().nullable(),
    customerNotes: Yup.string(),
    notes: Yup.string(),
    address: Yup.object().shape({
      addressLine1: Yup.string(),
      addressLine2: Yup.string(),
      city: Yup.string().required('A city is required'),
      state: Yup.string().required('A state is required'),
      zip: Yup.string().matches(/^\d{5}(-\d{4})?$/, 'Must be a valid zip code'),
    }),
    locationType: Yup.string(),
    currentHauler: Yup.string(),
  },
  [['email', 'phoneNumber']],
);

const contactedSchema = Yup.object().shape({
  contactEvents: Yup.array().of(
    Yup.object().shape({
      date: Yup.date().required('A contacted date is required'),
      medium: Yup.string().required('A contacted medium is required'),
    }),
  ),
});

const marketingSchema = Yup.object().shape({
  channel: Yup.string(),
  customerType: Yup.string(),
  searchParams: Yup.string(),
  formUrl: Yup.string(),
});

const quoteSchema = Yup.object().shape({
  quotes: Yup.array().of(
    Yup.object().shape({
      size: Yup.number().required('A size is required'),
      material: Yup.string().required('A material is required'),
      quantity: Yup.number().positive().required('A number is required'),
      frequency: Yup.string().required('Frequency is required'),
      billingFrequency: Yup.string().required('Billing frequency is required'),
      priceDollars: Yup.number(),
    }),
  ),
});

const haulerQuoteSchema = Yup.object().shape({
  haulerQuotes: Yup.array().of(
    Yup.object().shape({
      haulerName: Yup.string().required('A hauler is required'),
      size: Yup.number().required('A size is required'),
      material: Yup.string().required('A material is required'),
      quantity: Yup.number().positive().required('A number is required'),
      frequency: Yup.string().required('Frequency is required'),
      billingFrequency: Yup.string().required('Billing frequency is required'),
      priceDollars: Yup.number(),
    }),
  ),
});

const startingQuote = {
  size: '',
  material: '',
  frequency: '',
  quantity: 1,
  billingFrequency: 'monthly',
  priceDollars: '',
  cc: true,
  ccRate: 0,
};

export interface LeadCMFormsProps {
  onCancel: () => void;
  onSubmit: (lead: Customer.AllianceLeadTransport) => void;
  lead?: Customer.AllianceLeadTransport;
  form?: 'all' | 'lead' | 'quote' | 'quote-hauler' | 'contacted' | 'marketing';
  leadPhone?: string;
}

const LeadCMForms = ({ onCancel, onSubmit, lead, form, leadPhone }: LeadCMFormsProps) => {
  const formRef = useRef<any>();
  const [schema, setSchema] = useState<any>([]);
  const [newQuoteIndex, setNewQuoteIndex] = useState(0);

  const handleGetNewQuoteIndex = (hauler: any) => {
    const quoteStarting = hauler ? { ...startingQuote, haulerName: '' } : { ...startingQuote };
    const existingQuotes = hauler ? formRef.current.values.haulerQuotes : formRef.current.values.quotes;
    const newIndex = existingQuotes.length;
    formRef.current.setValues({
      ...formRef.current.values,
      [hauler ? 'haulerQuotes' : 'quotes']: [...existingQuotes, quoteStarting],
    });
    setNewQuoteIndex(newIndex);
  };

  useEffect(() => {
    if (schema.length !== 0 && Boolean(lead)) {
      handleGetNewQuoteIndex(form && form === 'quote-hauler');
    }
  }, [schema]);

  useEffect(() => {
    let schemaArray;
    switch (form) {
      case 'quote':
        schemaArray = quoteSchema;
        break;
      case 'quote-hauler':
        schemaArray = haulerQuoteSchema;
        break;
      case 'lead':
        schemaArray = leadSchema;
        break;
      case 'contacted':
        schemaArray = contactedSchema;
        break;
      case 'marketing':
        schemaArray = marketingSchema;
        break;
      default:
        //@ts-expect-error this needs to be fixed
        schemaArray = leadSchema.concat(contactedSchema).concat(marketingSchema).concat(quoteSchema);
        break;
    }
    setSchema(schemaArray);
  }, [form]);

  useEffect(() => {
    if (leadPhone && formRef.current) {
      formRef.current.setValues({
        ...formRef.current.values,
        phoneNumber: leadPhone,
      });
    }
  }, [leadPhone]);

  const handleSubmit = (data: Customer.AllianceLeadTransport) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    if (!lead) {
      dataCopy.type = 'commercial';
      if (dataCopy.notes) {
        dataCopy.notesInternal = [
          {
            note: dataCopy.notes,
          },
        ];
      }
    }
    dataCopy.serviceLocation = {
      address: dataCopy.address,
      county: dataCopy.county,
      coordinates: dataCopy.coords || null,
    };
    if (dataCopy.phoneNumber) {
      dataCopy.phoneNumber = formatUSPhoneNumberToE164(dataCopy.phoneNumber);
    } else {
      dataCopy.phoneNumber = null;
    }
    dataCopy.quotes = dataCopy.quotes.map((item: any) => ({
      ...item,
      cc: true,
      priceDollars: item.priceDollars ? item.priceDollars : 0,
      size: {
        size: +item.size,
        type: '',
      },
    }));
    dataCopy.haulerQuotes = dataCopy.haulerQuotes.map((item: any) => {
      const cleanItem = {
        ...item,
        size: {
          size: +item.size,
          type: '',
        },
      };
      delete cleanItem.cc;
      delete cleanItem.ccRate;
      return cleanItem;
    });
    onSubmit(dataCopy);
  };

  const prepareFormData = (data: Customer.AllianceLeadTransport) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.phoneNumber = dataCopy.phoneNumber ? formatE164ToUSPhoneNumber(dataCopy.phoneNumber) : '';
    dataCopy.address = dataCopy.serviceLocation.address;
    dataCopy.county = dataCopy.serviceLocation.county;
    dataCopy.coords = dataCopy.serviceLocation.coordinates;
    if (form === 'contacted') dataCopy.contactEvents.push({ date: null, medium: '' });
    if (form === 'quote-hauler' && dataCopy.haulerQuotes.length > 0) {
      dataCopy.haulerQuotes = dataCopy.haulerQuotes.map((item: any) => ({
        ...item,
        size: item.size.size,
      }));
    }
    if (form === 'quote') {
      dataCopy.quotes = dataCopy.quotes.map((item: any) => ({
        ...item,
        size: item.size.size,
      }));
    }
    return dataCopy;
  };

  return (
    <Formik
      initialValues={
        lead
          ? prepareFormData(lead)
          : {
              firstName: '',
              lastName: '',
              phoneNumber: '',
              email: '',
              notes: '',
              locationType: '',
              currentHauler: '',
              address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: '',
              },
              county: '',
              requestedStartDate: null,
              customerNotes: '',
              channel: '',
              customerType: '',
              contactEvents: [
                {
                  date: new Date(),
                  medium: '',
                },
              ],
              searchParams: '',
              quotes: [{ ...startingQuote }],
              haulerQuotes: [],
            }
      }
      validationSchema={schema}
      onSubmit={handleSubmit}
      innerRef={formRef as any}
    >
      {(formik) => {
        return (
          <Form>
            {form === 'all' || form === 'lead' ? <CMLeadForm formik={formik} lead={lead as any} /> : null}
            {form === 'all' || form === 'quote' || form === 'quote-hauler' ? (
              <QuoteForm hauler={form && form === 'quote-hauler'} activeIndex={newQuoteIndex} />
            ) : null}
            {form === 'all' || form === 'contacted' ? <ContactedForm formik={formik} /> : null}
            {form === 'all' || form === 'marketing' ? <MarketingForm formik={formik} /> : null}
            <div
              style={{
                display: 'flex',
                padding: '25px 0 55px',
                justifyContent: 'flex-end',
              }}
            >
              <button className="mr-5 btn-dark-grey-outlined" onClick={onCancel} type="button">
                Cancel
              </button>
              <button
                className="btn-primary"
                onClick={() => handleSubmit(formik.values)}
                disabled={!formik.isValid || !formik.dirty}
                type="button"
              >
                Save
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LeadCMForms;
