import React, { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Pricing } from '@alliance-disposal/transport-types';
import { UIContext } from '../../contexts';
import { RollOffPricingFormProps } from '../HaulerPricingForm/HaulerPricingForm';
import HaulerPricingUpdate from './HaulerPricingUpdate';

interface HaulerPricingUpdateContainerProps {
  mode: 'Rolloff' | 'Universal';
  pricing: Pricing.PricingTransport;
  open: boolean;
  onBackButtonClick: (data: { status: string | number }) => void;
  hauler: Hauler.HaulerWithAapTransport;
  haulerPricingZones: Pricing.PricingTransport[];
}

export interface UpdateRollOffPricingZoneFormProps {
  id?: string;
  haulerID: string;
  state: Pricing.UsStateAbbreviation;
  zoneName: string;
  tripCharge: number;
  tripChargeDollars: number;
  haulerName: string;
  notes: string;
  copyExistingZone?: string;
}

const HaulerPricingUpdateContainer = ({
  pricing,
  open,
  onBackButtonClick,
  hauler,
  haulerPricingZones,
}: HaulerPricingUpdateContainerProps) => {
  const { showFlash } = useContext(UIContext);
  const [isLoading, setIsLoading] = useState(false);
  const client = useWaysteClient();

  // HANDLE COPY PRICING FROM EXISTING ZONE
  const handleCopyPricing = async (newZoneID: string, zoneToCopyID: string) => {
    const existingZone = haulerPricingZones?.find((zone) => zone.id === zoneToCopyID);

    const payload: Array<Pricing.SGMutateMaterial & { expirationDate?: string | null | undefined }> | undefined =
      existingZone?.pricingData.map((material) => {
        return {
          expirationDate: undefined,
          effectiveDate: new Date().toISOString(),
          details: {
            allowOnlineCheckout: material.allowOnlineCheckout,
            allowForLowerTonLimit: material.allowForLowerTonLimit,
            doesNotService: material.doesNotService,
            lastUpdatedAt: new Date().toISOString(),
            haulerApproved: undefined,
            sourgumApproved: true,
            effectiveDate: undefined,
            expirationDate: undefined,
            material: material.material,
            sizes: material.sizes,
            tax: material.tax,
            type: material.type,
          },
        };
      });

    if (!payload) {
      return;
    }

    try {
      await client.pricing().adminPortal.material.createMany(newZoneID, payload || []);
    } catch (error) {
      console.log('error', error);
      showFlash('Error Copying Material Pricing', 'warning');
    }
  };

  const handleSubmit = async (data: RollOffPricingFormProps) => {
    if (!data?.id) {
      return;
    }
    const payload: Pricing.PricingUpdateTransport = {
      ...data,
    };
    delete payload.currentGrowthStage;

    delete payload.pricingData;
    delete payload.rentalPeriod;
    setIsLoading(true);

    let updatedPricingZone;
    try {
      updatedPricingZone = await client.pricing().adminPortal.pricing.update(data.id, payload);
      showFlash('Pricing Successfully Updated', 'success');
    } catch (error) {
      showFlash('An Error Occurred', 'warning');
    }

    if (updatedPricingZone && data.copyExistingZone) {
      await handleCopyPricing(updatedPricingZone.id, data.copyExistingZone);
    }
    onBackButtonClick({ status: 'success' });
    setIsLoading(false);
  };

  const handleDelete = async () => {
    alert('not implemented');
  };

  return (
    <HaulerPricingUpdate
      haulerPricingZones={haulerPricingZones}
      pricing={pricing}
      onBackButtonClick={onBackButtonClick}
      open={open}
      mode={'Rolloff'}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      hauler={hauler}
      onDelete={handleDelete}
    />
  );
};

export default HaulerPricingUpdateContainer;
