import { useEffect } from 'react';
import { UniversalService } from '@alliance-disposal/transport-types';

interface OrderStatusBadgeProps {
  status: UniversalService.ServiceOrderStatus;
}

export const OrderStatusBadge = ({ status }: OrderStatusBadgeProps) => {
  useEffect(() => {
    console.log('status', status);
  }, [status]);

  switch (status) {
    case 'UNASSIGNED':
      return (
        <div className="min-w-[125px] h-6 flex flex-row justify-center items-center whitespace-nowrap bg-statuses-UNASSIGNED-primary text-statuses-UNASSIGNED-text  rounded">
          <span className="min-w-[125px] text-center"> Unassigned </span>
        </div>
      );
    case 'ASSIGNED':
      return (
        <div className="min-w-[125px] h-6 flex flex-row justify-center items-center whitespace-nowrap bg-statuses-ASSIGNED-primary text-statuses-ASSIGNED-text  rounded">
          <span className="min-w-[125px] text-center"> Assigned </span>
        </div>
      );
    case 'ON_HOLD':
      return (
        <div className="min-w-[125px] h-6 flex flex-row justify-center items-center whitespace-nowrap bg-statuses-ON_HOLD-primary text-statuses-ON_HOLD-text  rounded">
          <span className="min-w-[125px] text-center"> On Hold </span>
        </div>
      );
    case 'DELIVERED':
      return (
        <div className="min-w-[125px] h-6 flex flex-row justify-center items-center whitespace-nowrap bg-statuses-DELIVERED-primary text-statuses-DELIVERED-text  rounded">
          <span className="min-w-[125px] text-center"> Delivered </span>
        </div>
      );
    case 'READY_FULL_PICKUP':
      return (
        <div className="min-w-[125px] h-6 flex flex-row justify-center items-center whitespace-nowrap bg-statuses-READY_FULL_PICKUP-primary text-statuses-READY_FULL_PICKUP-text  rounded">
          <span className="min-w-[125px] text-center"> Ready Full Pickup </span>
        </div>
      );
    case 'READY_PICKUP':
      return (
        <div className="min-w-[125px] h-6 flex flex-row justify-center items-center whitespace-nowrap bg-statuses-READY_PICKUP-primary text-statuses-READY_PICKUP-text  rounded">
          <span className="min-w-[125px] text-center"> Ready Pickup </span>
        </div>
      );
    case 'PICKED_UP_FULL':
      return (
        <div className="min-w-[125px] h-6 flex flex-row justify-center items-center whitespace-nowrap bg-statuses-PICKED_UP_FULL-primary text-statuses-PICKED_UP_FULL-text  rounded">
          <span className="min-w-[125px] text-center"> Picked Up Full </span>
        </div>
      );
    case 'PICKED_UP_EMPTY':
      return (
        <div className="min-w-[125px] h-6 flex flex-row justify-center items-center whitespace-nowrap bg-statuses-PICKED_UP_EMPTY-primary text-statuses-PICKED_UP_EMPTY-text  rounded">
          <span className="min-w-[125px] text-center"> Picked Up Empty </span>
        </div>
      );
    case 'PICKED_UP':
      return (
        <div className="min-w-[125px] h-6 flex flex-row justify-center items-center whitespace-nowrap bg-statuses-PICKED_UP-primary text-statuses-PICKED_UP-text  rounded">
          <span className="min-w-[125px] text-center"> Picked Up </span>
        </div>
      );
    case 'DUMPED':
      return (
        <div className="min-w-[125px] h-6 flex flex-row justify-center items-center whitespace-nowrap bg-statuses-DUMPED-primary text-statuses-DUMPED-text  rounded">
          <span className="min-w-[125px] text-center"> Dumped </span>
        </div>
      );
    case 'ON_SITE':
      return (
        <div className="min-w-[125px] h-6 flex flex-row justify-center items-center whitespace-nowrap bg-statuses-ON_SITE-primary text-statuses-ON_SITE-text  rounded">
          <span> On Site </span>
        </div>
      );
    case 'IN_PROGRESS':
      return (
        <div className="min-w-[125px] h-6 flex flex-row justify-center items-center whitespace-nowrap bg-statuses-IN_PROGRESS-primary text-statuses-IN_PROGRESS-text  rounded">
          <span className="min-w-[125px] text-center"> In Progress </span>
        </div>
      );
    case 'COMPLETED':
      return (
        <div className="min-w-[125px] h-6 flex flex-row justify-center items-center whitespace-nowrap bg-statuses-COMPLETED-primary text-statuses-COMPLETED-text  rounded">
          <span className="min-w-[125px] text-center"> Completed </span>
        </div>
      );

    default:
      return <span className="min-w-[125px] text-center">{'status not found'}</span>;
  }
};
