import React, { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler } from '@alliance-disposal/transport-types';
import { UIContext } from '../../contexts';
import HaulerUpdate from './HaulerUpdate';

/**
 * Container to Create an items in the customers table
 * @param {Boolean} open Controls if modal is open
 * @param {Function} onBackButtonClick Function on modal closing. Returns true if customer was updated
 * @param {Object} hauler Starting hauler data
 */

export interface HaulerUpdateContainerProps {
  open: boolean;
  onBackButtonClick: () => void;
  onSubmit: () => void;
  hauler: Hauler.HaulerWithAapTransport;
}
const HaulerUpdateContainer = ({ open, hauler, onBackButtonClick, onSubmit }: HaulerUpdateContainerProps) => {
  const waysteClient = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async (data: Hauler.HaulerWithAapDataUpdate) => {
    setIsLoading(true);

    try {
      await waysteClient.vendorService().adminPortal.update(hauler.id, data);
      showFlash('Hauler Successfully Updated', 'success');
      setIsLoading(false);
    } catch (error) {
      console.warn('Vendor update error', error);
      showFlash('An Error Occurred', 'warning');
      setIsLoading(false);
    }
    onSubmit();
  };

  const handleSubmit = async (data: Hauler.HaulerWithAapDataUpdate) => {
    handleUpdate(data);
  };

  const handleDeactivate = async (id: string) => {
    handleUpdate({ active: false, id } as Hauler.HaulerWithAapDataUpdate);
  };

  const handleReactivate = async (id: string) => {
    handleUpdate({ active: true, id: id } as Hauler.HaulerWithAapDataUpdate);
  };

  return (
    <HaulerUpdate
      onBackButtonClick={onBackButtonClick}
      open={open}
      onSubmit={handleSubmit}
      hauler={hauler}
      isLoading={isLoading}
      onDeactivate={handleDeactivate}
      onReactivate={handleReactivate}
    />
  );
};

export default HaulerUpdateContainer;
