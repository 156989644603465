import { useContext, useEffect, useMemo, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Invoice, UniversalService } from '@alliance-disposal/transport-types';
import { Checkbox, Loading, TextField, Textarea, Toggle } from '@wayste/sour-ui';
import { asyncForEach } from '@wayste/utils';
import { ChevronLeftIcon, ChevronRightIcon, PaperClipIcon, TrashIcon } from '@heroicons/react/24/solid';
import { INVOICE_RECEIPT, sendDynamicEmail } from '../../../axios/sendgrid';
import Dialog from '../../../components/Dialog';
import { UIContext, useConfirmationDialog } from '../../../contexts';
import { paymentMethodsEnums } from '../../../utils/shared-types';

/**
 * Converts file into base64 URI
 * @param {*} file
 */
const fileToBase64 = (file: any) => {
  return new Promise((resolve) => {
    if (file.size) {
      const reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function (event: any) {
        resolve(event.target?.result?.split(',')[1]);
      };
      // Convert data to base64
      reader.readAsDataURL(file);
    } else {
      resolve(file.split(',')[1]);
    }
  });
};

// convert pdf blob to base64 string
const pdfToBase64 = (blob: any) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

interface Props {
  order: UniversalService.ServiceOrder;
  customer: Customer.AllianceCustomerTransport;
  open: boolean;
  onCancel: () => void;
  receivable: Invoice.ReceivableTransport;
  onSend: (isInvoice: boolean) => void;
}

const InvoiceSend = ({ order, customer, onSend, open, onCancel, receivable }: Props) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);

  const { getConfirmation } = useConfirmationDialog();
  const [containerHeight, setContainerHeight] = useState(0);
  const [images, setImages] = useState<any>([]);
  const [customerEmail, setCustomerEmail] = useState(
    (() => {
      return customer.contacts.find((contact) => contact.primaryContact)?.email || customer.contacts[0]?.email || '';
    })(),
  );
  const [pdf, setPdf] = useState<any>();
  const [editEmail, setEditEmail] = useState(false);
  const [askReview, setAskReview] = useState(false);
  const [consolidate, setConsolidate] = useState(true);
  const [emailText, setEmailText] = useState('');
  const [isInvoice, setIsInvoice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeImageStep, setActiveImageStep] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const invoiceContainer = document.getElementById('container');

  useEffect(() => {
    if (invoiceContainer) setContainerHeight(invoiceContainer.clientHeight);
  }, [invoiceContainer]);

  useEffect(() => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailPattern.test(customerEmail || ''));
  }, [customerEmail]);

  useEffect(() => {
    if (receivable) {
      setIsInvoice(!receivable.invoiceDetails.paidInFull);

      (async () => {
        const response = await await client.invoice().adminPortal.pdf.fetch(receivable?.id || ' ', consolidate);
        const blob = new Blob([response], { type: 'application/pdf' });
        setPdf(blob);
      })();
    }
    console.log('invoice', receivable);
  }, [receivable, consolidate]);

  const file = useMemo(() => {
    if (pdf) {
      return URL.createObjectURL(pdf);
    }
  }, [pdf]);

  const verifyCustomerExperience = async () => {
    setTimeout(async () => {
      if (!isInvoice && customer.overallExperience === 'Positive' && !customer.reviewed) {
        const confirmed = await getConfirmation({
          title: 'Verify User Experience',
          message:
            'Are you sure the customer had a good experience? If so click CONFIRM otherwise click CANCEL and update the customer experience via Update Customer Details.',
        });
        if (!confirmed) onCancel();
        setAskReview(true);
      }
    }, 1000);
  };

  useEffect(() => {
    verifyCustomerExperience();
  }, [isInvoice]);

  const handleNextImage = () => {
    setActiveImageStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackImage = () => {
    setActiveImageStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleImageUpload = (file: any) => {
    const imagesCopy = [...images, file];
    setImages(imagesCopy);
  };

  useEffect(() => {
    console.log('images', images);
  }, [images]);

  const handleImageDelete = (index: number) => {
    const imagesCopy = [...images];
    setImages(imagesCopy.filter((item, i) => i !== index));
    setActiveImageStep(0);
  };

  const prepareImage = async (image: any) => {
    if (image.type === 'application/pdf') {
      try {
        const res = await fileToBase64(image);
        return res;
      } catch (error) {
        console.warn('InvoiceSend ImageCompression Error: ', error);
        alert('There was an error compressing the image');
      }
    } else {
      try {
        // const res = await ImageCompression(image, {
        //   maxSizeMB: 1,
        //   maxWidthOrHeight: 1000,
        // });
        // const result = await fileToBase64(res);
        return image;
      } catch (error) {
        console.warn('InvoiceSend ImageCompression Error: ', error);
        alert('There was an error compressing the image');
      }
    }
  };

  const prepareAttachments = async () => {
    const receiptInvoiceBlob = ((await pdfToBase64(pdf)) as any).split(',')[1];

    const attachments = [
      {
        content: receiptInvoiceBlob,
        type: 'application/pdf',
        filename: `${!receivable.invoiceDetails.paidInFull ? 'Invoice' : 'Receipt'} ${`order.orderNumber`} - ${
          receivable.invoiceDetails.invoiceNumber
        }.pdf`,
        disposition: 'attachment',
      },
    ];
    if (images.length > 0) {
      await asyncForEach(images, async (image: any) => {
        const attachment = await prepareImage(image);
        attachments.push({
          content: attachment,
          type: image.type,
          filename: image.name,
          disposition: 'attachment',
        });
      });
    }

    return attachments;
  };

  const generatePaymentLink = async () => {
    console.log(
      !receivable.invoiceDetails.paidInFull,
      customer.defaultPaymentSettings?.paymentMethod !== paymentMethodsEnums.check,
    );
    if (
      !receivable.invoiceDetails.paidInFull &&
      // invoice.payments.length === 0 &&
      customer.defaultPaymentSettings?.paymentMethod !== paymentMethodsEnums.check
    ) {
      try {
      } catch (error) {
        console.error('InvoiceSend generatePaymentLink Error: ', error);
        showFlash('An error occurred generating the payment link', 'warning');
      }
    }
  };

  const handleSend = async () => {
    setIsLoading(true);
    if (!receivable.invoiceDetails.issueDate) {
      try {
        await client.invoice().adminPortal.receivable.update(receivable.id, {
          invoiceDetails: {
            issueDate: new Date().toISOString(),
          },
        });
      } catch (error) {
        console.warn('InvoiceSend Error: ', error);
        showFlash('An error occurred issuing the invoice. Please try again.', 'warning');
        return;
      }
    }

    const payment_link = await generatePaymentLink();
    const data = {
      /*       toEmail: customerEmail,
      emailTemplate: 'INVOICE_RECEIPT',
      receipt_invoice: isInvoice ? 'Invoice' : 'Receipt',
      ccEmails: getCustomerCCAddresses(customer, order.serviceLocation.address).billingCCAddresses,
      over_weight_limit_by:
        order.weightLimit && order.actualWeightDumped > order.weightLimit
          ? (Number(order.actualWeightDumped) - Number(order.weightLimit)).toFixed(2)
          : null,
      order_number: order.orderNumber,
      invoice_number: `${order.orderNumber} - ${receivable.invoiceNumber}`,
      first_name: customer.primaryContact?.firstName,
      ask_for_review: isInvoice ? false : askReview,
      attachments: attachments,
      custom_message: emailText,
      payment_link, */
    };

    console.log('payment_link', payment_link);

    try {
      const res = await sendDynamicEmail(data as INVOICE_RECEIPT);
      setIsLoading(false);
      if (res) onSend(isInvoice);
    } catch (error) {
      console.warn('InvoiceSend Error: ', error);
      alert('An error occurred');
    }
  };

  const loadPdf = () => {
    const file = images[activeImageStep];
    const fileUrl = URL.createObjectURL(file);
    return fileUrl;
  };

  return (
    <Dialog open={open} onClose={onCancel} styledTitle="Create Invoice" fullScreen>
      <div
        style={{
          display: 'flex',
          height: 'calc(100% - 96px)',
          justifyContent: 'space-between',
        }}
        id="container"
      >
        <div
          style={{
            paddingRight: 10,
            borderRight: 'solid 1px #D8D8D8',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              fontSize: 16,
              color: 'rgba(0, 0, 0, 0.68)',
              marginBottom: 17,
            }}
          >
            Email Details
          </div>
          <div className="w-60 mb-4">
            <TextField
              label="Email to"
              inputProps={{ value: customerEmail, onChange: (e) => setCustomerEmail(e.target.value) }}
            />
          </div>
          <Checkbox
            label="Include review link in email"
            inputProps={{
              checked: askReview,
              onChange: () => setAskReview(!askReview),
            }}
          />
          <Checkbox
            label="Consolidate Line Items"
            inputProps={{
              checked: consolidate,
              onChange: () => setConsolidate(!consolidate),
            }}
          />
          <div className="mt-4">
            <Toggle label="Edit email" value={editEmail} onChange={setEditEmail} />
          </div>
          <div style={{ maxWidth: 240 }}>
            Thank you for choosing Sourgum Waste! The {isInvoice ? 'Invoice' : 'Receipt'} for your order{' '}
            {order.fullOrderNumber} is ready. Find it attached to this email, or log in to your customer dashboard to
            access it at any time.
          </div>
          <div className="flex-1 mb-0">
            <Textarea
              label="Optional custom message"
              height="h-full"
              inputProps={{
                disabled: !editEmail,
                value: emailText,
                onChange: (e) => setEmailText(e.target.value),
              }}
            />
          </div>
          <div style={{ maxWidth: 240 }}>
            {askReview ? (
              <span>
                We'd love to hear about your experience! Please take a moment to leave us a review (it just takes a
                minute) and help us continue to provide the best modern waste & recycling services
              </span>
            ) : (
              <span>
                If you have any questions or feedback about how things went, please reply to this email and let us know.
                We value your business and are committed to providing the best modern waste & recycling services.
              </span>
            )}
          </div>
        </div>

        <div
          className="text-wayste-corral-400"
          style={{
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 0.75,
            position: 'relative',
          }}
        >
          {images.length > 0 ? (
            <div style={{ position: 'relative' }}>
              <TrashIcon
                className="top-10 right-5 absolute text-delete h-6 w-6 cursor-pointer"
                onClick={() => handleImageDelete(activeImageStep)}
              />
              {images[activeImageStep].type === 'application/pdf' ? (
                <object
                  data={loadPdf()}
                  style={{
                    height: containerHeight * 0.85,
                    width: containerHeight * 0.77273,
                    border: 'solid 1px #D8D8D8',
                  }}
                  type="application/pdf"
                ></object>
              ) : (
                <img
                  src={window.URL.createObjectURL(images[activeImageStep])}
                  alt="Dump ticket"
                  style={{
                    height: containerHeight * 0.85,
                    width: containerHeight * 0.77273,
                    border: 'solid 1px #D8D8D8',
                  }}
                />
              )}
              <div className="flex justify-between items-center py-2">
                <button
                  className={`btn-primary-text-only py-1 px-2 ${
                    activeImageStep === 0 ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  onClick={handleBackImage}
                  disabled={activeImageStep === 0}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                  Back
                </button>
                <p className="text-sm font-medium text-gray-500">{`${activeImageStep + 1} / ${images.length}`}</p>
                <button
                  className={`btn-primary-text-only py-1 px-2 ${
                    activeImageStep === images.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  onClick={handleNextImage}
                  disabled={activeImageStep === images.length - 1}
                >
                  Next
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          ) : (
            <div className="text-center relative flex flex-col items-center">
              <PaperClipIcon className="w-10 h-10" />
              <div>Attach Dump Ticket</div>
              <input
                className="blankFileInput"
                type="file"
                onChange={(event) => {
                  handleImageUpload(event?.target?.files?.[0]);
                }}
              />
            </div>
          )}
        </div>
        <div
          style={{
            height: '100%',
            overflow: 'auto',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {file ? (
            <object data={file} type="application/pdf" width="100%" height="100%">
              <param name="width" value="100%" />

              <p>
                It appears your Web browser is not configured to display PDF files. No worries, just
                <a style={{ color: '#7fba00' }} href={file}>
                  {' '}
                  click here{' '}
                </a>{' '}
                to download the PDF file.
              </p>
            </object>
          ) : (
            <div className="h-full w-full flex flex-row justify-center items-center  ">
              <Loading />
            </div>
          )}
        </div>
      </div>
      <div className="pt-6 flex gap-4">
        <button type="button" className="btn-dark-grey-outlined" onClick={onCancel} disabled={isLoading}>
          Cancel
        </button>
        <button
          type="button"
          className="btn-primary"
          disabled={isLoading || !isValid || !file}
          onClick={() => handleSend()}
        >
          Email Invoice
          {isLoading && <Loading className="text-sourgum-greyblue-900 ml-1" size="h-4 w-4" />}
        </button>
        {images.length > 0 && (
          <button className="btn-secondary-white relative" disabled={isLoading} type="button">
            Upload Another Image
            <input
              className="blankFileInput"
              type="file"
              onChange={(event) => {
                handleImageUpload(event?.target?.files?.[0]);
              }}
            />
          </button>
        )}
      </div>
    </Dialog>
  );
};
export default InvoiceSend;
