import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { DatePicker, Loading } from '@wayste/sour-ui';
import Dialog from '../../components/Dialog';
import { leadTypes } from '../../utils';

const AdminPanel = ({ open, onClose, leadType }: { open: boolean; onClose: Function; leadType: string }) => {
  const client = useWaysteClient();

  const [createdBeforeDate, setCreatedBeforeDate] = useState<Date | ''>('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseLeads = async () => {
    setIsLoading(true);

    if (!createdBeforeDate) {
      onClose();
      return;
    }

    const leadResponse = await client.customer().adminPortal.leads.query({
      type: leadType,
      status: 'open',
      createdBefore: createdBeforeDate?.toISOString(),
    });

    console.log(leadResponse);
    for (const lead of leadResponse.results) {
      await client.customer().adminPortal.leads.update(lead.id, {
        status: 'lost',
        reasonLost: 'unknown',
        reasonLostNote: 'admin date range close',
      });
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={() => onClose()} styledTitle="Lead Admin Controls" className="max-w-xl">
      <div className="flex flex-col gap-2">
        <p>DO NOT USE THIS UNLESS YOU KNOW WHAT YOU'RE DOING</p>
        <p className="text-gray-500">
          Select the date that you want to mark all {leadTypes[leadType as keyof typeof leadTypes]} leads created on or
          before that date as closed with reason lost being Unknown.
        </p>
        <div>
          <DatePicker
            label="Created before"
            value={createdBeforeDate}
            onChange={(date) => setCreatedBeforeDate(date)}
            closeOnSelect={true}
            clearable={true}
          />
        </div>
      </div>
      <div className="flex justify-end px-2 pt-6">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <button className="btn-dark-grey-outlined mr-5" onClick={() => onClose()}>
              Cancel
            </button>
            <button className="btn-primary" onClick={handleCloseLeads}>
              Mark leads as lost
            </button>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default AdminPanel;
