import { useEffect, useRef } from 'react';
import { Pricing } from '@alliance-disposal/transport-types';
import { PencilIcon } from '@heroicons/react/24/solid';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { addFeatureToMap } from './MapEditor/utils/addFeatureToMap';

mapboxgl.accessToken = 'pk.eyJ1IjoibHVjc291cmd1bSIsImEiOiJjbGE5dGc1ZHIwMWJkM25ud3Vrbzd1N2lxIn0.jALyAIrgB-YWlPilyAKq8A';

const ServiceAreasMap = ({
  pricingZones,
  zoneClicked,
  editButtonClicked,
  disableScrollZoom,
}: {
  pricingZones?: Pricing.ServiceAreaTransport[];
  zoneClicked?: (zoneID: string) => void;
  editButtonClicked?: () => void;
  disableScrollZoom?: boolean;
}) => {
  //eslint-disable-next-line
  const mapContainer = useRef<any>(null);
  const map = useRef<null | mapboxgl.Map>(null);
  const sourceIDs = useRef<string[]>([]);
  const featuresRef = useRef<Record<string, GeoJSON.Feature>>({});

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-70.9, 42.35],
      zoom: 5,
      scrollZoom: disableScrollZoom ? false : true,
    });
    if (disableScrollZoom) {
      const mapNav = new mapboxgl.NavigationControl();
      map.current.addControl(mapNav, 'top-right');
    }
    featuresRef.current = {};
    const tempFeatures: GeoJSON.Feature[] = [];
    const serviceAreas = pricingZones;
    if (serviceAreas != null) {
      // set the features
      serviceAreas?.forEach((serviceArea: any) => {
        const geoJSON: { features: GeoJSON.Feature[] } = JSON.parse(serviceArea.boundary.geoJSON);

        if (geoJSON.features.length > 0) {
          geoJSON.features.forEach((feature) => {
            if (feature.geometry.type === 'Polygon') {
              tempFeatures.push(feature);
            }
            featuresRef.current[serviceArea.id] = feature;
          });
        }
      });
    }
  }, [map.current]);

  //when ever the pricing zones change, update the features
  useEffect(() => {
    featuresRef.current = {};
    const tempFeatures: GeoJSON.Feature[] = [];
    const serviceAreas = pricingZones;
    // set the features
    if (serviceAreas != null) {
      serviceAreas.forEach((serviceArea) => {
        const geoJSON: { features: GeoJSON.Feature[] } = JSON.parse(serviceArea?.boundary.geoJSON);
        if (geoJSON.features.length > 0) {
          geoJSON.features.forEach((feature) => {
            if (feature.geometry.type === 'Polygon') {
              tempFeatures.push(feature);
            }
            featuresRef.current[serviceArea.id] = feature;
          });
        }
      });
    }

    sourceIDs.current.forEach((sourceID) => {
      if (map.current) {
        map.current.removeLayer('fill' + sourceID);
        map.current.removeLayer('outline' + sourceID);
        map.current.removeSource(sourceID);
      }
    });

    sourceIDs.current.splice(0, sourceIDs.current.length);

    if (map.current) {
      map.current.on('load', () => {
        // in theory the source would be the service area id

        Object.keys(featuresRef.current).forEach((key) => {
          const sourceID = key;
          const feature = featuresRef.current[key];

          if (!map.current) return;
          if (map.current.getSource(sourceID)) return;
          addFeatureToMap(map.current, sourceID, feature, {
            click: () => {
              // find the pricing zone that contains this service area
              const pricingZone = serviceAreas?.find((serviceArea) => serviceArea.id === sourceID);

              if (pricingZone) {
                zoneClicked?.(pricingZone.id);
              }
            },
            mouseenter: () => {
              if (map.current) {
                map.current.getCanvas().style.cursor = 'pointer';
              }
            },
            mouseleave: () => {
              if (map.current) {
                map.current.getCanvas().style.cursor = '';
              }
            },
          });
          sourceIDs.current.push(sourceID);
        });

        // ----------------- OUTSIDE EXISTING ZONES -----------------
        if (!map.current) return;

        zoomToFitFeatures();
      });
    }
  }, [pricingZones]);

  console.log(map.current);
  const zoomToFitFeatures = () => {
    if (!map.current || Object.keys(featuresRef.current).length < 1) return;
    const bounds = new mapboxgl.LngLatBounds();
    Object.keys(featuresRef.current).forEach((key) => {
      const feature = featuresRef.current[key];
      if (feature.geometry.type === 'Polygon') {
        feature.geometry.coordinates[0].forEach((coordinate) => {
          bounds.extend([coordinate[0], coordinate[1]]);
        });
      }
    });
    map.current.fitBounds(bounds, {
      padding: 100,
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
      <div className="bg-white shadow-dark rounded-md overflow-hidden relative">
        <button
          className={`btn-primary px-1.5 min-w-[2.5rem] w-10 h-10 rounded-full absolute top-2 right-2 z-50 shadow-dark ${
            !editButtonClicked && 'hidden'
          }`}
          aria-label="edit"
          onClick={() => {
            editButtonClicked?.();
          }}
        >
          <PencilIcon className="h-5 w-5" />
        </button>
        <div style={{ width: '100%', height: '50vh' }} ref={mapContainer} />
      </div>
    </div>
  );
};

export default ServiceAreasMap;
