import React, { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Order } from '@alliance-disposal/transport-types';
import { formatServiceAddress, formatServiceDate, getDateFormat } from '@wayste/utils';
import { format } from 'date-fns';
import { addDays } from 'date-fns';
import { useConfirmationDialog } from '../../contexts';
import { getOrderCurrentSwitch, materials } from '../../utils';
import OrderUpdate from './OrderUpdate';
import SendEmailDialog from './SendEmailDialog';

const emailCheckKeys = {
  addressString: { label: 'Address', value: (value: string) => value },
  material: { label: 'Material', value: (value: keyof typeof materials) => materials[value as keyof typeof materials] },
  expectedSize: { label: 'Dumpster Size', value: (value: string) => `${value} YD` },
  expectedDeliveryDate: {
    label: 'Delivery Date',
    value: (value: string) => format(new Date(getDateFormat(value)), 'EEEE MM/dd/yy'),
  },
  expectedPickupDate: {
    label: 'Pickup Date',
    value: (value: string) => (value ? format(new Date(getDateFormat(value)), 'EEEE MM/dd/yy') : ''),
  },
  sharedDeliveryNotes: {
    label: 'Delivery Instructions',
    value: (value: string) => value,
  },
  sharedPickupNotes: { label: 'Pickup Instructions', value: (value: string) => value },
  sharedDeliveryNotesPrivate: {
    label: 'Additional Delivery Notes',
    value: (value: string) => value,
  },
  sharedPickupNotesPrivate: {
    label: 'Additional Pickup Notes',
    value: (value: string) => value,
  },
};

/**
 * Container to Update an item in the orders table
 * @param {*} order A order schema object
 * * @param {*} customer A customer schema object
 * @param {Boolean} open Controls if modal is open
 * @param {Function} onBackButtonClick Function on modal closing. Returns true if customer was updated
 */

export interface OrderUpdateContainerProps {
  order: Order.AllianceOrderTransport;
  open: boolean;
  onBackButtonClick: () => void;
  customer: Customer.AllianceCustomerTransport;
}

const OrderUpdateContainer = ({ order, open, onBackButtonClick, customer }: OrderUpdateContainerProps) => {
  const client = useWaysteClient();
  const { getConfirmation } = useConfirmationDialog();
  const [showSendUpdateEmail, setShowSendUpdateEmail] = useState<boolean>(false);
  const [emailChanges, setEmailChanges] = useState<any>([]);
  const [currentSwitch, setCurrentSwitch] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setCurrentSwitch(getOrderCurrentSwitch(order));
  }, [order]);

  const checkForEmailChanges = (data: Order.AllianceOrderUpdateInput & { addressString: string }) => {
    const currentOrder = {
      ...order,

      addressString: formatServiceAddress(order.serviceLocation.address),
      weightLimit: order?.weightLimit?.value,
      expectedSize: order?.expectedSize?.size,
    };

    const changes: { label: string; oldValue: any; newValue: any }[] = [];

    Object.keys(emailCheckKeys).forEach((key) => {
      if (
        //@ts-expect-error typscript doesn't like this TODO
        emailCheckKeys[key as keyof typeof emailCheckKeys].value(currentOrder[key]) !==
        //@ts-expect-error typscript doesn't like this TODO
        emailCheckKeys[key as keyof typeof emailCheckKeys].value(data[key])
      ) {
        const change = {
          label:
            (emailCheckKeys[key as keyof typeof emailCheckKeys].label === 'Delivery Date' ||
              emailCheckKeys[key as keyof typeof emailCheckKeys].label === 'Pickup Date') &&
            currentSwitch
              ? 'Dump & Return Date'
              : emailCheckKeys[key as keyof typeof emailCheckKeys].label,
          //@ts-expect-error typscript doesn't like this TODO
          oldValue: emailCheckKeys[key as keyof typeof emailCheckKeys].value(currentOrder[key]),
          //@ts-expect-error typscript doesn't like this TODO
          newValue: emailCheckKeys[key as keyof typeof emailCheckKeys].value(data[key]),
        };
        changes.push(change);
      }
    });
    return changes;
  };

  const submitHandler = async (data: Order.AllianceOrderUpdateInput) => {
    setIsLoading(true);
    if (currentSwitch) {
      if (
        data.expectedDeliveryDate &&
        format(new Date(data.expectedDeliveryDate), 'yyyy-MM-dd') !==
          format(new Date(order.expectedDeliveryDate), 'yyyy-MM-dd') &&
        currentSwitch.relationship === 'CHILD'
      ) {
        const confirmed = await getConfirmation({
          title: 'Handle the Switch',
          message:
            "This order is part of a switch. Do you want to update the other order's Pickup Date? Or do you want to Split the Switch?",
          cancelText: 'Split Switch',
          confirmText: 'Update Other Order',
        });
        if (confirmed) {
          // update other order
          await client.order().adminPortal.update(currentSwitch.fromOrder, {
            expectedPickupDate: data.expectedDeliveryDate,
          });
        } else if (!confirmed) {
          await client.order().adminPortal.deleteSwitch(currentSwitch.id);
        }
      }
      if (data.expectedPickupDate && order.expectedPickupDate) {
        if (
          format(new Date(data?.expectedPickupDate), 'yyyy-MM-dd') !==
            format(new Date(order?.expectedPickupDate), 'yyyy-MM-dd') &&
          currentSwitch.relationship === 'PARENT'
        ) {
          const confirmed = await getConfirmation({
            title: 'Handle the Switch',
            message:
              "This order is part of a switch. Do you want to update the other order's Delivery Date? Or do you want to Split the Switch?",
            cancelText: 'Split Switch',
            confirmText: 'Update Other Order',
          });
          if (confirmed) {
            // update other order
            await client.order().adminPortal.update(currentSwitch.toOrder, {
              expectedDeliveryDate: data.expectedPickupDate,
            });
          } else if (!confirmed) {
            await client.order().adminPortal.deleteSwitch(currentSwitch.id);
          }
        }
      }
    }

    const updatedOrder: Order.AllianceOrderUpdateInput = {
      ...data,
      expirationDate:
        data.expectedDeliveryDate && data.adjustedRentalPeriod?.value
          ? addDays(new Date(data.expectedDeliveryDate), data.adjustedRentalPeriod.value).toISOString()
          : undefined,
    };

    await client.order().adminPortal.update(order.id, updatedOrder);
    const changes = checkForEmailChanges({
      ...data,
      expectedDeliveryDate: format(
        formatServiceDate(data.expectedDeliveryDate as string, 'date') as Date,
        'yyyy-MM-dd',
      ),
      addressString: formatServiceAddress(data?.serviceLocation?.address),
    });

    if (changes.length > 0) {
      setEmailChanges(changes);
      setShowSendUpdateEmail(true);
    }
    onBackButtonClick();
    setIsLoading(false);
  };

  return (
    <>
      <OrderUpdate
        order={order}
        onBackButtonClick={onBackButtonClick}
        open={open}
        onSubmit={submitHandler}
        isLoading={isLoading}
      />
      <SendEmailDialog
        open={showSendUpdateEmail}
        onClose={() => setShowSendUpdateEmail(false)}
        changes={emailChanges}
        customer={customer}
        order={order}
      />
    </>
  );
};

export default OrderUpdateContainer;
