import React from 'react';
import { buildAddressString } from '@wayste/utils';
import { getCity } from '@wayste/utils';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { Field } from 'formik';
import { Select, TextField } from 'formik-mui';
import { statesAbb } from '../utils/state-details';
import InputGooglePlaces from './InputGooglePlaces';

const StateMenuItems = statesAbb.map((item) => (
  <MenuItem key={`state-${item}`} value={item}>
    {item}
  </MenuItem>
));

type Props = {
  name: string;
  disabled: boolean;
  google: boolean;
  latLng: boolean;
  onSelect?: any;
  required: boolean;
  styles: any;
  partial: boolean;
  googleZipCode: boolean;
};

/**
 * Standard Address Inputs with option for Google Places API autofill
 * @param {String} name Name of parent object holding address values
 * @param {Boolean} [disabled] Should all fields be disabled
 * @param {Boolean} [google] Should Google Places Autofill component replace Address 1 input
 * @param {Boolean} [latLng] If true returns {lat, lng} with onSelect Requires google to be true
 * @param {Function} [onSelect] Function on Google API select
 * @param {Boolean} [required] Should all fields except addressLine2 be required
 * @param {Object} [styles] Additional styles to apply to main Grid container wrapper
 * @param {Boolean} [partial] Allows for partial addresses to be added, only applies to onSelect
 */
const InputAddress = ({
  name,
  disabled,
  google,
  latLng,
  onSelect,
  required,
  styles,
  partial,
  googleZipCode,
}: Props) => {
  const googleAPIHandler = (res: any, form: any, coords: any) => {
    const addressComponents = res.address_components;
    const stateRes = addressComponents.find((item: any) => item.types.includes('administrative_area_level_1'));
    const cityRes = getCity(addressComponents);
    const countyRes = addressComponents.find((item: any) => item.types.includes('administrative_area_level_2'));
    let countyCleaned = null;
    if (!countyRes && cityRes === 'Baltimore') {
      countyCleaned = 'Baltimore City';
    } else if (!countyRes && stateRes.short_name === 'DC') {
      countyCleaned = 'District of Columbia';
    } else {
      countyCleaned = countyRes?.long_name?.replace(' County', '') || 'null';
    }
    const streetNumberFind = addressComponents.find((item: any) => item.types.includes('street_number'));
    const streetAddressFind = addressComponents.find((item: any) => item.types.includes('route'));
    const zipCodeFind = addressComponents.find((item: any) => item.types.includes('postal_code'));
    const addressLine1 =
      !partial || (partial && streetNumberFind && streetAddressFind)
        ? `${streetNumberFind.long_name} ${streetAddressFind.long_name}`
        : '';
    const zipCode = !partial || (partial && zipCodeFind) ? zipCodeFind.short_name : '';
    const newAddress = {
      addressLine1: addressLine1,
      city: cityRes,
      state: stateRes.short_name,
      zip: zipCode,
    };
    const addressString = buildAddressString(newAddress);
    form.setFieldValue(name + '.addressLine1', newAddress.addressLine1);
    form.setFieldValue(name + '.city', newAddress.city);
    form.setFieldValue(name + '.state', newAddress.state);
    form.setFieldValue(name + '.zip', newAddress.zip);

    if (onSelect) {
      onSelect({
        county: countyCleaned,
        addressString: addressString,
        address: newAddress,
        coords: coords,
      });
    }
  };

  return (
    <Grid container spacing={3} style={{ ...styles }}>
      <Grid item xs={12}>
        {google ? (
          <Field name={name + '.addressLine1'} id={name + '.addressLine1'} autoComplete="no">
            {({ field, form, meta }: any) => {
              return (
                <InputGooglePlaces
                  label="Address 1"
                  value={field.value}
                  onChange={(text: any) => form.setFieldValue(name + '.addressLine1', text)}
                  onSelect={(res: any, coords: any) => googleAPIHandler(res, form, coords)}
                  latLng={latLng}
                  required={required}
                  disabled={disabled}
                  error={meta.touched && meta.error ? true : false}
                  onBlur={() => {
                    form.setFieldTouched(name + '.addressLine1');
                    if (required) form.setFieldError(name + '.addressLine1', true);
                  }}
                />
              );
            }}
          </Field>
        ) : (
          <Field
            id={name + '.addressLine1'}
            name={name + '.addressLine1'}
            label="Address 1"
            component={TextField}
            margin="dense"
            autoComplete="no"
            fullWidth
            required={required}
            disabled={disabled}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Field
          id={name + '.addressLine2'}
          name={name + '.addressLine2'}
          label="Address 2"
          component={TextField}
          margin="dense"
          autoComplete="no"
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          id={name + '.city'}
          name={name + '.city'}
          label="City"
          component={TextField}
          margin="dense"
          autoComplete="no"
          fullWidth
          required={required}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {/* <InputLabel htmlFor={name + '.state'}>State</InputLabel> */}

        <Field
          id={name + '.state'}
          name={name + '.state'}
          component={Select}
          required={required}
          label="State"
          formControl={{ fullWidth: true, size: 'small' }}
          labelId={name + '.state'}
          disabled={disabled}
          autoComplete="no"
        >
          {StateMenuItems}
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        {googleZipCode ? (
          <Field name={name + '.zip'} id={name + '.zip'} autoComplete="no">
            {({ field, form, meta }: any) => {
              return (
                <InputGooglePlaces
                  label="Zip"
                  value={field.value}
                  onChange={(text: any) => form.setFieldValue(name + '.zip', text)}
                  onSelect={(res: any, coords: any) => googleAPIHandler(res, form, coords)}
                  latLng={latLng}
                  required={required}
                  disabled={disabled}
                  searchOptions={{ types: ['(regions)'] }}
                  error={meta.touched && meta.error ? true : false}
                  onBlur={() => {
                    form.setFieldTouched(name + '.zip');
                    if (required) form.setFieldError(name + '.zip', true);
                  }}
                />
              );
            }}
          </Field>
        ) : (
          <Field
            id={name + '.zip'}
            name={name + '.zip'}
            label="Zip"
            component={TextField}
            // margin="dense"
            autoComplete="no"
            fullWidth
            required={required}
            disabled={disabled}
          />
        )}
      </Grid>
    </Grid>
  );
};

InputAddress.defaultProps = {
  disabled: false,
  google: false,
  required: false,
  styles: {},
  latLng: false,
  partial: false,
  googleZipCode: false,
};

export default InputAddress;
