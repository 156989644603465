import { useState } from 'react';
import { Loading } from '@wayste/sour-ui';
import axios from 'axios';
import { useAuthToken } from '../../hooks/authhook';
import Dialog from '../Dialog';

type Props = {
  open: boolean;
  onClose: () => void;
  zoneID: string;
  materialID: string;
  onSuccess: () => void;
};

const ApprovePricingChangeDialog = ({ open, onClose, zoneID, materialID, onSuccess }: Props) => {
  const { token } = useAuthToken();
  const [isLoading, setIsLoading] = useState(false);

  const handleApprovePricing = async () => {
    setIsLoading(true);
    try {
      if (materialID) {
        await axios({
          method: 'POST',
          url: `${import.meta.env.VITE_BASE_API_URL}/pricing/admin-portal/${zoneID}/material/${materialID}/approve`,
          headers: { Authorization: `${token}` },
        });
      } else {
        await axios({
          method: 'POST',
          url: `${import.meta.env.VITE_BASE_API_URL}/pricing/admin-portal/${zoneID}/approve`,
          headers: { Authorization: `${token}` },
        });
      }
      onSuccess();
    } catch (error) {
      console.warn('Approve pricing error: ', error);
      alert('An error occurred get a SAP dev');
    }
    setIsLoading(false);
  };

  return (
    <Dialog styledTitle="Approve Pricing Change" open={open} onClose={onClose}>
      <div>
        Are you sure you want to approve this hauler's pricing change? Every new order we accept from this hauler will
        be at this new rate.
      </div>
      <div className="flex justify-end gap-4 pt-4">
        <button className="btn-dark-grey-outlined" disabled={isLoading} onClick={onClose}>
          Cancel
        </button>
        <button disabled={isLoading} onClick={handleApprovePricing} className="btn-primary">
          Approve New Pricing
          {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
        </button>
      </div>
    </Dialog>
  );
};

export default ApprovePricingChangeDialog;
