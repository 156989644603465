import React, { useEffect } from 'react';
import { Hauler, Pricing } from '@alliance-disposal/transport-types';
import { Button, Loading, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { Controller, useForm } from 'react-hook-form';
import { statesAbb } from '../../utils';
import { CreateUniversalPricingFormProps } from '../HaulerPricingCreate/HaulerPricingCreateContainer';

export interface HaulerPricingFormProps {
  hauler: Hauler.HaulerWithAapTransport;
  pricing?: Pricing.PricingTransport;
  onSubmit: (data: CreateUniversalPricingFormProps) => void;
  onCancel: (data: { status: string | number }) => void;
  isLoading: boolean;
  onDelete?: (data: any) => void;
  mode: 'Rolloff' | 'Universal';
}

export interface FormProps {
  state: string;
  zoneName: string;
  tripCharge: number | '';
  notes: string;
}

export const HaulerPricingFormUniversal = ({
  pricing,
  onSubmit,
  onCancel,
  isLoading,
  hauler,
}: HaulerPricingFormProps) => {
  // FORM STATE
  const { handleSubmit, reset, control } = useForm<FormProps>({
    defaultValues: {
      state: '',
      zoneName: '',
      tripCharge: '',
      notes: '',
    },
    mode: 'all',
  });

  /////////////////////////////////////////////
  // COMPONENT FUNCTIONS
  /////////////////////////////////////////////

  const handleFormSubmit = async (data: FormProps) => {
    const newData: CreateUniversalPricingFormProps = {
      state: data.state as Pricing.UsStateAbbreviation,
      haulerID: hauler.id,
      tripCharge: data.tripCharge || 0,
      tripChargeDollars: data.tripCharge || 0,
      haulerName: hauler.name,
      zoneName: data.zoneName,
      notes: data.notes,
    };

    onSubmit(newData);
  };

  useEffect(() => {
    if (pricing) {
      reset({
        zoneName: pricing.zoneName ? pricing.zoneName : '',
        tripCharge: pricing.tripCharge ? pricing.tripCharge : '',
        state: pricing.state,
        notes: pricing.notes ? pricing.notes : '',
      });
    }
  }, [pricing]);

  ////////////////////////////////////////////
  // RENDER SECTION
  ////////////////////////////////////////////

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="my-4 space-y-4">
        <div className="w-full">
          <Controller
            name="zoneName"
            control={control}
            render={({ field }) => <TextField label="Zone name" required inputProps={{ ...field }} />}
          />
        </div>

        <div className="w-full md:w-1/2">
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <Select
                label="State"
                required
                onSelect={(value) => {
                  field.onChange(value);
                }}
                value={field.value}
              >
                {statesAbb.map((item) => (
                  <SelectOption key={`state-${item}`} value={item}>
                    {item}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
        </div>

        <div className="w-full">
          <h2 className="pl-2 text-xl mb-4">Zone Pricing</h2>
        </div>

        <div className="w-full">
          <Controller
            name="notes"
            control={control}
            render={({ field }) => <TextField label="Notes" inputProps={{ ...field }} />}
          />
        </div>
      </div>
      <div className="flex justify-end gap-4 pt-4">
        <Button
          className="btn-dark-grey-outlined"
          onClick={() => onCancel({ status: 'cancel' })}
          type="button"
          disabled={isLoading}
        >
          cancel
        </Button>

        <Button className="btn-primary" type="submit" disabled={isLoading}>
          {pricing ? 'Update' : 'Create'}
          {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
        </Button>
      </div>
    </form>
  );
};
