import { InternalTicket, Profile } from '@alliance-disposal/transport-types';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { Entities } from './InternalTicketWorkflow';
import { TicketItem } from './TicketItem';

type BoardSectionProps = {
  id: string;
  title: string;
  tickets: InternalTicket.InternalTicketTransport[];
  entities: Entities;
  onAddEntity: (entityID: string, entityType: InternalTicket.InternalTicketRelatedEntity) => void;
  onTicketItemClick: (ticket: InternalTicket.InternalTicketTransport) => void;
  rosterMap: { [key: string]: Profile.ProfileTransport };
  onRefreshTickets: () => void;
};

export const BoardSection = ({
  id,
  title,
  tickets,
  entities,
  onAddEntity,
  onTicketItemClick,
  rosterMap,
  onRefreshTickets,
}: BoardSectionProps) => {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <div className="bg-slate-100 rounded h-full w-full">
      <div className="p-2 pb-0 font-bold">{title}</div>
      <SortableContext id={id} items={tickets} strategy={verticalListSortingStrategy}>
        <div ref={setNodeRef} className="p-2">
          {tickets.map((ticket) => (
            <div key={ticket.id}>
              <SortableTicketItem id={ticket.id}>
                <TicketItem
                  ticket={ticket}
                  entities={entities}
                  onAddEntity={onAddEntity}
                  onTicketItemClick={onTicketItemClick}
                  rosterMap={rosterMap}
                  onRefreshTickets={onRefreshTickets}
                />
              </SortableTicketItem>
            </div>
          ))}
        </div>
      </SortableContext>
    </div>
  );
};

type SortableTaskItemProps = {
  children: React.ReactNode;
  id: string;
};

const SortableTicketItem = ({ children, id }: SortableTaskItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};
