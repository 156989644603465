import axios from 'axios';

interface SEND_SMS {
  toNumber: string;
  message: string;
}

/**
 * Triggers AWS API Gateway to trigger Lambda function to send SMS message
 */
export const sendSMS = async (data: SEND_SMS) => {
  const url = import.meta.env.VITE_AWS_SES_URL + 'send-sms';
  try {
    const res = await axios({
      method: 'POST',
      url: url,
      data: JSON.stringify(data),
    });
    if (res.status !== 200) {
      console.warn('sendSMS !== 200 status error: ', res);
      alert('There was an error sending the SMS');
      return { status: 'error' };
    }
    return { status: 'success' };
  } catch (error) {
    console.warn('axios sendSMS Error: ', error);
    alert('There was an error sending the SMS');
    return { status: 'error' };
  }
};
