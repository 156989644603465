import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler } from '@alliance-disposal/transport-types';
import { isAxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import { routes } from '../../utils';
import HaulerCreate from './HaulerCreate';

/**
 * Container to Create an items in the customers table
 */
const HaulerCreateContainer = () => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleBackButtonClick = () => {
    history.goBack();
  };

  const handleSubmit = async (data: Hauler.HaulerWithAapDataUpdate) => {
    setIsLoading(true);
    try {
      const vendor = await client.vendorService().adminPortal.create(data);
      showFlash('Hauler Successfully Created', 'success');
      history.push(routes.haulers.details(vendor.id));
    } catch (error) {
      let message = 'An Error Occurred while creating the vendor';

      if (isAxiosError(error) && error.response?.data?.additionalInfo) {
        message = error.response?.data?.additionalInfo;
      }

      console.warn(' createHauler error: ', error);
      showFlash(message, 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  return <HaulerCreate onBackButtonClick={handleBackButtonClick} onSubmit={handleSubmit} isLoading={isLoading} />;
};

export default HaulerCreateContainer;
