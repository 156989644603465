import { useEffect } from 'react';
import { Invoice } from '@alliance-disposal/transport-types';
import { CurrencyTextField, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { moneyFormatter, round } from '@wayste/utils';
import { Controller, useForm } from 'react-hook-form';
import Dialog from '../../../../components/Dialog';
import { haulerChargeItems } from '../../../../utils/shared-types';

interface FormProps {
  itemName: string;
  description?: string;
  quantity: number | '';
  unitPriceDollars: string;
}

interface Props {
  lineItem?: Invoice.LineItemTransport;
  open: boolean;
  onCancel: () => void;
  onSave: (values: Partial<Invoice.LineItemTransport>) => void;
  onDeleteItem: () => void;
}

const BillItemDialog = ({ lineItem, open, onCancel, onSave, onDeleteItem }: Props) => {
  const getTotal = (quantity: number | '', unitPriceDollars: string) => {
    return round(+quantity * +unitPriceDollars);
  };

  const onSubmit = (data: FormProps) => {
    onSave({
      itemName: data.itemName,
      description: data.description || '',
      quantity: +data.quantity,
      unitPrice: +data.unitPriceDollars * 100,
      totalPrice: getTotal(data.quantity, data.unitPriceDollars) * 100,
      taxable: false,
    });
    reset();
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { isValid, isDirty, errors },
    reset,
    watch,
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      itemName: '',
      description: '',
      quantity: 1,
      unitPriceDollars: '',
    },
  });

  const watchQuantity = watch('quantity');
  const watchUnitPriceDollars = watch('unitPriceDollars');

  useEffect(() => {
    if (lineItem) {
      reset({
        itemName: lineItem.itemName,
        description: lineItem.description || '',
        quantity: lineItem.quantity,
        unitPriceDollars: lineItem?.unitPriceDollars?.toString() || '0',
      });
    } else {
      reset({
        itemName: '',
        description: '',
        quantity: 1,
        unitPriceDollars: '',
      });
    }
  }, [lineItem, reset]);

  if (!open) return null;

  return (
    <Dialog onClose={onCancel} styledTitle={`${lineItem ? 'Edit' : 'Add'} Charge`} open={open}>
      <form className="flex flex-col gap-4">
        <div>
          {lineItem && !Object.keys(haulerChargeItems).includes(lineItem.itemName) ? (
            <p className="opacity-70">{lineItem.itemName}</p>
          ) : (
            <Controller
              name="itemName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Item name is required',
                },
              }}
              render={({ field }) => (
                <Select
                  required={true}
                  value={field.value}
                  onSelect={(value) => field.onChange(value)}
                  label="Item"
                  error={errors.itemName}
                >
                  {Object.entries(haulerChargeItems).map((item) => (
                    <SelectOption key={item[0]} value={item[0]}>
                      {item[1]}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          )}
        </div>
        <div>
          <TextField label="Description" inputProps={{ ...register('description') }} />
        </div>
        <div>
          <TextField
            label="Quantity"
            inputProps={{ ...register('quantity', { required: 'Quantity is required' }), step: '0.01' }}
            type="number"
            required={true}
            error={errors.quantity}
          />
        </div>
        <div>
          <Controller
            name="unitPriceDollars"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Rate is required',
              },
            }}
            render={({ field }) => (
              <CurrencyTextField
                value={field.value}
                onChange={(value) => field.onChange(value)}
                label="Rate"
                required={true}
                error={errors.unitPriceDollars}
              />
            )}
          />
        </div>
        <div style={{ fontSize: 18 }}>
          Total:{' '}
          {moneyFormatter(getTotal(watchQuantity, watchUnitPriceDollars), {
            isDollars: true,
          })}
        </div>
      </form>
      <div className="flex justify-end gap-4 border-t pt-4">
        <button className="btn-delete mr-auto" onClick={onDeleteItem} type="button">
          delete
        </button>
        <button className="btn-dark-grey-outlined" onClick={onCancel} type="button">
          Cancel
        </button>
        <button className="btn-primary" type="button" disabled={!isValid || !isDirty} onClick={handleSubmit(onSubmit)}>
          {lineItem ? 'Update' : 'Add'}
        </button>
      </div>
    </Dialog>
  );
};

export default BillItemDialog;
