import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { formatDateToYYYYMMDD } from '@wayste/utils/dist/formatter/date-formatter';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { UIContext } from '../../contexts';
import SubscriptionDetailsSubForm from './SubscriptionDetailsSubForm';
import { UniversalServiceFormData } from './UniversalServiceCreate';
import { defaultServiceSubscriptionDetails } from './site-service-defaults';

const SubscriptionDetailsUpdate = () => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const history = useHistory();
  const params: { serviceGroupingId: string; serviceOrderId: string } = useParams();
  const methods = useForm<UniversalServiceFormData>();
  const { handleSubmit, reset } = methods;
  const [serviceGrouping, setServiceGrouping] = useState<UniversalService.ServiceGrouping | undefined>(undefined);
  const [serviceOrder, setServiceOrder] = useState<UniversalService.ServiceOrder | undefined>(undefined);
  const [index, setIndex] = useState<number>(0);

  const fetchServiceGrouping = async () => {
    try {
      const serviceGrouping = await client.universalService().serviceGrouping.fetch(params.serviceGroupingId);
      console.log('data', serviceGrouping);
      setServiceGrouping(serviceGrouping);
      serviceGrouping.serviceOrders.map((order, index) => {
        if (order.id === params.serviceOrderId) {
          setIndex(index);
          setServiceOrder(order);
        }
      });
    } catch (error) {
      console.warn('fetchServiceOrder apolloError for ID: ', error);
      return { status: 'error', data: null };
    }
  };

  useEffect(() => {
    fetchServiceGrouping();
  }, []);

  useEffect(() => {
    if (!serviceGrouping) {
      reset({
        subscriptionDetails: defaultServiceSubscriptionDetails,
      });
    } else {
      reset({
        serviceGrouping: serviceGrouping,
        subscriptionDetails: serviceGrouping.serviceOrders[index].subscriptionDetails,
      });
    }
  }, [serviceGrouping]);

  const onSubmit = async (data: UniversalServiceFormData) => {
    try {
      // UPDATE SERVICE GROUPING
      const serviceGroupingUpdatePayload: UniversalService.ServiceGroupingUpdate = {
        ...data.serviceGrouping,
      };
      await client.universalService().serviceGrouping.update(params.serviceGroupingId, serviceGroupingUpdatePayload);

      // UPDATE SERVICE ORDER
      const serviceOrderUpdatePayload: UniversalService.ServiceOrderUpdate = {
        ...data.serviceGrouping.serviceOrders[index],
        serviceDays: serviceOrder?.serviceDays.map((day) => Number(day)),
        vendorID: serviceOrder?.vendorID || undefined,
        subscriptionDetails: {
          ...data.subscriptionDetails,
          startDate: formatDateToYYYYMMDD(new Date(data.subscriptionDetails.startDate)) ?? '',
          endDate: data.subscriptionDetails.endDate
            ? formatDateToYYYYMMDD(new Date(data.subscriptionDetails.endDate))
            : undefined,
        },
      };

      await client
        .universalService()
        .serviceGrouping.serviceOrder.update(
          params.serviceGroupingId,
          params.serviceOrderId,
          serviceOrderUpdatePayload,
        );

      showFlash('Service Order Successfully Updated', 'success');
      history.goBack();
    } catch (error) {
      console.log('Service Order Update error ', error);
      showFlash('Error Updating Service Order', 'warning');
    }
  };

  return (
    <div className="p-16 flex-1 flex flex-col max-w-[960px]">
      <FormProvider {...methods}>
        <form>
          <div className="pb-2 mb-6 text-2xl">Update Subscription</div>
          <div className="space-y-6">{serviceGrouping && <SubscriptionDetailsSubForm methods={methods} />}</div>
          <div className="mt-2 float-right">
            <Button className="btn-dark-grey-outlined mr-4" onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button className="btn-primary" onClick={handleSubmit(onSubmit)}>
              Update
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
export default SubscriptionDetailsUpdate;
