import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { Toggle } from '@wayste/sour-ui';
import { formatServiceAddress, getDispatchEmailsString } from '@wayste/utils';
import { getPrimaryCustomerContact } from '@wayste/utils';
import { CheckCircleIcon, PauseIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import { sendEmail } from '../../axios/ses';
import { UIContext, useConfirmationDialog } from '../../contexts';
import {
  OrderStatus,
  getCustomerCCAddresses,
  getOrderCurrentSwitch,
  noReplyEmail,
  orderCancellationEmailCustomer,
  orderCancellationEmailHauler,
  routes,
} from '../../utils';
import { useErrorHandlingSubscription } from '../../utils';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';
import ContactsListCard from '../ContactsListCard/ContactsListCard';
import CustomerDetailsCard from '../CustomerDetailsCard';
import { InternalTicket } from '../InternalTicket';
import Loading from '../Loading';
import OrderBillingDetailsCard from '../OrderBillingDetailsCard';
import OrderDetailsCard from '../OrderDetailsCard';
import VendorListCard from '../VendorListCard/VendorListCard';
import DetailsCardWrapper from '../ui/DetailsCardWrapper';

interface OrderDetailsProps {
  id: string;
}

const OrderDetails = ({ id }: OrderDetailsProps) => {
  const waysteClient = useWaysteClient();
  const history = useHistory();
  const client = useWaysteClient();
  const { getConfirmation } = useConfirmationDialog();
  const { showFlash, godModeActive } = useContext(UIContext);

  // STATE
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sendHaulerEmail, setSendHaulerEmail] = useState<boolean>(true);
  const [sendCustomerEmail, setSendCustomerEmail] = useState<boolean>(true);
  const [order, setOrder] = useState<Order.AllianceOrderTransport | null>(null);
  const [customer, setCustomer] = useState<Customer.AllianceCustomerTransport | null>(null);
  const [customerID, setCustomerID] = useState<string | null>(null);
  const loadingInput = !customerID;
  const orderLoadingInput = !id;

  const [fetchOrder, setFetchOrder] = useState<Order.AllianceOrderTransport | null>(null);
  const [payables, setPayables] = useState<Invoice.PayableTransport[]>([]);
  const [receivables, setReceivables] = useState<Invoice.ReceivableTransport[]>([]);

  /////////////////////////////////////////////////
  // SUBSCRIPTION SECTION
  /////////////////////////////////////////////////

  const { subscriptionData: orderData, subscribed: orderSubscribed } = useErrorHandlingSubscription(
    client.order().adminPortal.subscription.subscribeToOrder,
    'order',
    [id],
    orderLoadingInput,
  );
  const { subscriptionData: customerData, subscribed: customerSubscribed } = useErrorHandlingSubscription(
    client.customer().adminPortal.subscriptions.subscribeToCustomer,
    'customer',
    [customerID],
    loadingInput,
  );

  /////////////////////////////////////////////////
  //  FUNCTION SECTION
  /////////////////////////////////////////////////

  const fetchOrderDetails = async (orderID: string) => {
    setFetchOrder(null);
    setReceivables([]);
    setPayables([]);
    try {
      const [order, receivablesResponse, payablesResponse] = await Promise.all([
        client.order().adminPortal.fetch(orderID),
        client.invoice().adminPortal.receivable.query({
          orderID,
        }),
        client.invoice().adminPortal.payable.query({
          orderID,
        }),
      ]);

      console.log('receivablesResponse', receivablesResponse);
      console.log('payablesResponse', payablesResponse);
      console.log('orderResponse', order);

      setReceivables(receivablesResponse);
      setPayables(payablesResponse);

      setFetchOrder(order);
    } catch (error) {
      console.error(error, orderID);
      showFlash(
        'Something went wrong loading payables and receivables. Please refresh the page and try again.',
        'warning',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSplitSwitch = async () => {
    const confirmed = await getConfirmation({
      title: 'Split the Switch',
      message:
        "Are you sure you want to split this Switch? Both orders will still be here, they just won't be a Switch.",
    });
    if (confirmed) {
      const currentSwitch = getOrderCurrentSwitch(order);
      await client.order().adminPortal.deleteSwitch(currentSwitch?.id);
    }
  };

  const onCancelOrder = async () => {
    if (
      receivables.some(
        (item: Invoice.ReceivableTransport) =>
          item?.invoiceDetails.totalDollars - item?.invoiceDetails.remainingBalanceDollars > 0,
      )
    ) {
      const confirmed = await getConfirmation({
        title: 'Order has Paid Invoices',
        message:
          'This order has invoices that were paid. If you already issued any necessary refunds and the order is ready to be canceled click Cancel Order. If you did NOT already issue any refunds or are unsure if any should be issued click Go to Invoice',
        confirmText: 'Cancel Order',
        cancelText: 'Go to Invoice',
      });
      if (!confirmed) {
        history.push({ pathname: routes.billing.list, search: `?id=${order?.id}` });
        return;
      }
    } else if (
      receivables.every(
        (item: Invoice.ReceivableTransport) =>
          !(item?.invoiceDetails.totalDollars - item?.invoiceDetails.remainingBalanceDollars) ||
          item?.invoiceDetails.totalDollars - item?.invoiceDetails.remainingBalanceDollars === 0,
      )
    ) {
      const confirmedVoid = await getConfirmation({
        title: 'Void All Order Invoices?',
        message:
          "Do you want to void every invoice for this order? If you are unsure or only want to void some of them go to the order's invoices and confirm before cancelling the order.",
        confirmText: 'Void All & Cancel Order',
        cancelText: 'Cancel & Not Void',
      });
      if (confirmedVoid) {
        for (const receivable of receivables) {
          if (!receivable.invoiceDetails.payments || receivable.invoiceDetails.payments.length === 0) {
            await client.invoice().adminPortal.receivable.update(receivable.id, { invoiceDetails: { void: true } });
          }
        }
      }
    }
    const currentSwitch = getOrderCurrentSwitch(order);
    let switchCancellation: 'delay_pickup' | 'final_removal' | null | undefined = null;
    if (currentSwitch) {
      const confirmed = await getConfirmation({
        title: 'Cancel the Switch',
        message:
          "This order is part of a Switch. Do you want to also cancel the other order's Pickup? Doing so will remove the pickup date and change the order status back to Delivered",
        cancelText: 'Leave Pickup',
        confirmText: 'Cancel Pickup',
      });
      if (confirmed) {
        await client.order().adminPortal.update(currentSwitch?.fromOrder, {
          status: OrderStatus.DELIVERED,
          expectedPickupDate: null,
          haulerConfirmedPickup: false,
        });
        switchCancellation = 'delay_pickup';
      } else {
        switchCancellation = 'final_removal';
      }

      await client.order().adminPortal.deleteSwitch(currentSwitch?.id);
    }

    handleCancelOrder(switchCancellation);
  };

  const handleCancelOrder = async (switchCancellation: 'delay_pickup' | 'final_removal' | null | undefined) => {
    setIsLoading(true);
    if (!order) return;
    try {
      await client.order().adminPortal.update(order?.id, {
        status: OrderStatus.CANCELLED,
        closed: Object.values(receivables).some(
          (invoice) =>
            // @ts-expect-error ts doesn't like this but it's fine
            invoice.invoiceDetails.lineItems.reduce((total, item) => total + item?.totalPriceDollars || 0, 0) > 0,
        )
          ? false
          : true,
      });

      if (sendCustomerEmail && customer && order) {
        const customerEmailData = orderCancellationEmailCustomer(customer, order);
        try {
          await sendEmail('send-email', customerEmailData);
        } catch (error) {
          showFlash('Error Sending Customer Email', 'warning');
        }
      }
      if (sendHaulerEmail && order?.haulerID) {
        try {
          const haulerResponse = await waysteClient.vendorService().fetchById(order?.haulerID);
          const hauler = haulerResponse.data;
          const haulerEmailData = orderCancellationEmailHauler(hauler, order, switchCancellation);
          const dispatchEmailsString = getDispatchEmailsString(hauler.contacts);
          if (Boolean(dispatchEmailsString)) {
            try {
              await sendEmail('send-email', haulerEmailData);
            } catch (error) {
              showFlash('Error Sending Hauler Email', 'warning');
            }
          }
        } catch (error) {
          showFlash('Error Sending Hauler Email', 'warning');
        }
      }
    } catch (error) {
      showFlash('Error Sending Hauler Email', 'warning');
      return;
    } finally {
      setShowDelete(false);
      setIsLoading(false);
    }
  };

  const handleHaulerConfirmedDeliveryClicked = async () => {
    if (!order) return;
    if (order?.haulerConfirmedDelivery) {
      const proceed = await getConfirmation({
        title: 'NOT Confirmed',
        message: 'Are you sure you want to mark this order as NOT confirmed by the hauler?',
      });
      if (proceed) {
        try {
          await client.order().adminPortal.update(order?.id, {
            haulerConfirmedDelivery: false,
          });
        } catch (error) {
          showFlash('Error Sending Hauler Email', 'warning');
        }
      }
    } else {
      try {
        await client.order().adminPortal.update(order?.id, {
          haulerConfirmedDelivery: true,
        });
      } catch (error) {
        showFlash('Error Sending Hauler Email', 'warning');
      }
    }
  };

  const handleHaulerConfirmedPickupClicked = async () => {
    if (!order) return;
    if (order?.haulerConfirmedPickup) {
      const proceed = await getConfirmation({
        title: 'NOT Confirmed',
        message: 'Are you sure you want to mark this order as NOT confirmed by the hauler?',
      });
      if (proceed) {
        try {
          await client.order().adminPortal.update(order?.id, {
            haulerConfirmedPickup: false,
          });
        } catch (error) {
          showFlash('Error Sending Hauler Email', 'warning');
        }
      }
    } else {
      try {
        await client.order().adminPortal.update(order?.id, {
          haulerConfirmedPickup: true,
        });
      } catch (error) {
        showFlash('Error Sending Hauler Email', 'warning');
      }
    }
  };

  const handlePlaceOnHold = async () => {
    setIsLoading(true);
    const oldStatus = order?.status;
    const newStatus = order?.status === OrderStatus.ON_HOLD ? OrderStatus.UNASSIGNED : OrderStatus.ON_HOLD;

    if (order) {
      try {
        await client.order().adminPortal.update(order?.id, {
          status: newStatus,
        });
      } catch (error) {
        showFlash('Error Sending Hauler Email', 'warning');
        return;
      } finally {
        setIsLoading(false);
      }
    }

    if (oldStatus === OrderStatus.ON_HOLD) return;
    let sendToCustomer = true;
    const confirmed = await getConfirmation({
      title: 'Send Email to the Customer',
      message: 'Do you want to send an email to the Customer to let them know the order is being put on hold?',
      confirmText: 'Email Customer',
      cancelText: "Don't Send an Email",
    });
    sendToCustomer = confirmed ? true : false;
    if (sendToCustomer && customer) {
      const customerEmailData = {
        htmlMessage: `
					<p>Hi ${getPrimaryCustomerContact(customer)?.firstName},</p>
					<p>Your dumpster rental order to ${formatServiceAddress(
            order?.serviceLocation.address,
          )} has been successfully placed on hold. If you have any questions please let us know and we'll be happy to help you.</p>
					<p>Thank you!</p>
					<p>The Sourgum Waste Team</p>
				`,
        textMessage: `
					Hi ${getPrimaryCustomerContact(customer)?.firstName},\n\n
					Your dumpster rental order to ${formatServiceAddress(
            order?.serviceLocation.address,
          )} has been successfully placed on hold. If you have any questions please let us know and we'll be happy to help you
					\n\nThank you!
					\n\nThe Sourgum Waste Team
				`,
        toAddress: getPrimaryCustomerContact(customer)?.email,
        subject: 'Sourgum Waste Dumpster Placed On Hold',
        bcc: noReplyEmail,
        ccAddresses: customer && getCustomerCCAddresses(customer, order?.serviceLocation.address).serviceCCAddresses,
      };

      try {
        await sendEmail('send-email', customerEmailData);
      } catch (error) {
        showFlash('Error Sending Customer Email', 'warning');
      }
    }
    setIsLoading(false);
  };

  /////////////////////////////////////////////////
  //  HOOKS SECTION
  /////////////////////////////////////////////////

  useEffect(() => {
    console.log('orderData sad', orderData);
  }, [orderData]);

  useEffect(() => {
    if (customerID && customerData) {
      setCustomer(customerData);
    }
    console.log('customer data', customerData);
  }, [customerID, customerData, customerSubscribed]);

  useEffect(() => {
    if (id) fetchOrderDetails(id);
    if (id && orderData) {
      setCustomerID(orderData.allianceCustomerID);
      setOrder(orderData);

      console.log('orderData', orderData);
    }
  }, [id, orderData, orderSubscribed]);

  /////////////////////////////////////////////////
  //  RENDER SECTION
  /////////////////////////////////////////////////

  if (!order || !customer || isLoading) return <Loading />;

  return (
    <>
      <DetailsCardWrapper
        heading={`Order # ${order.orderNumber}`}
        buttons={[
          order.status !== OrderStatus.READY_FULL_PICKUP && order.status !== OrderStatus.DUMPED
            ? {
                label: (
                  <>
                    {order.haulerConfirmedDelivery && <CheckCircleIcon className="h-5 w-5 mr-1" />}
                    Hauler Confirmed Delivery
                  </>
                ),
                onClick: handleHaulerConfirmedDeliveryClicked,
                props: {
                  className: `${order.haulerConfirmedDelivery ? 'bg-success' : 'btn-secondary'}`,
                },
                // hide: order.status === OrderStatus.NEEDS_REVIEW,
              }
            : {
                label: (
                  <>
                    {order.haulerConfirmedPickup && <CheckCircleIcon className="h-5 w-5 mr-1" />}
                    Hauler Confirmed Pickup
                  </>
                ),
                onClick: handleHaulerConfirmedPickupClicked,
                props: {
                  className: `${order.haulerConfirmedPickup ? 'bg-success' : 'btn-secondary'}`,
                },
              },
          {
            label: 'Cancel Order',
            delete: true,
            onClick: () => setShowDelete(true),
            disabled:
              order.status !== OrderStatus.UNASSIGNED &&
              order.status !== OrderStatus.ASSIGNED &&
              order.status !== OrderStatus.ON_HOLD &&
              !godModeActive,
          },
          {
            label:
              order.status === OrderStatus.ON_HOLD ? (
                <>
                  <PauseIcon className="h-5 w-5 mr-1" />
                  On Hold
                </>
              ) : (
                'Put On Hold'
              ),
            onClick: handlePlaceOnHold,
            disabled: order.status !== OrderStatus.UNASSIGNED && order.status !== OrderStatus.ON_HOLD,
            props: {
              className: `${order.status === OrderStatus.ON_HOLD ? 'bg-[#FEE902] text-black border-0' : ''}`,
            },
          },
        ]}
      />

      <OrderDetailsCard
        order={orderData}
        customer={customer}
        payables={payables}
        receivables={receivables}
        onSplitSwitch={handleSplitSwitch}
      />
      <InternalTicket entityID={order.id} entityType="sourgum-order" />

      {order.status === OrderStatus.NEEDS_REVIEW && <VendorListCard order={order} receivables={receivables} />}
      <CustomerDetailsCard customer={customer} />
      <ContactsListCard customer={customer} />
      {receivables.length > 0 && fetchOrder ? (
        <OrderBillingDetailsCard order={orderData} receivables={receivables} payables={payables} />
      ) : (
        <div>Invoice still loading...</div>
      )}
      <ConfirmDeleteDialog
        open={showDelete}
        onClose={() => setShowDelete(false)}
        onDelete={onCancelOrder}
        title="Cancel Order"
        maxWidth="sm"
        deleteText="Cancel Order"
      >
        <div>Are you sure you want to cancel order {order.orderNumber}?</div>
        <br />
        <div>Once canceled this data can still be retrieved.</div>
        <div className="mt-4">
          <div>Do you want to send a cancellation email to the customer?</div>
          <div className={'mt-4'}>
            <Toggle label="Send email to customer" value={sendCustomerEmail} onChange={setSendCustomerEmail} />
          </div>
        </div>
        {order.status === OrderStatus.ASSIGNED ? (
          <div className="mt-4">
            <div>This order is assigned to a hauler. Do you want to send them an order cancellation email?</div>
            <div className={'mt-4'}>
              <Toggle label="Send email to hauler" value={sendHaulerEmail} onChange={setSendHaulerEmail} />
            </div>
          </div>
        ) : null}
      </ConfirmDeleteDialog>
    </>
  );
};

export default OrderDetails;
