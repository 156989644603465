import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { InternalTicket } from '@alliance-disposal/transport-types';

type InternalTicketContextType = {
  fetchInternalTickets: () => void;
  setEntityID: (entityID: string) => void;
  tickets: InternalTicket.InternalTicketTransport[];
  isLoading: boolean;
  entityID: string;
  ticketStatusCounts: { OPEN: number; IN_PROGRESS: number; SOLVED: number };
};

const initialValue: InternalTicketContextType = {
  fetchInternalTickets: () => null,
  setEntityID: () => null,
  tickets: [],
  isLoading: false,
  entityID: '',
  ticketStatusCounts: { OPEN: 0, IN_PROGRESS: 0, SOLVED: 0 },
};

export const InternalTicketContext = createContext(initialValue);

export const InternalTicketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const client = useWaysteClient();
  const [tickets, setTickets] = useState<InternalTicket.InternalTicketTransport[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [entityID, setEntityID] = useState('');
  const [ticketStatusCounts, setTicketStatusCounts] = useState({ OPEN: 0, IN_PROGRESS: 0, SOLVED: 0 });

  const fetchInternalTickets = async () => {
    if (!entityID) {
      alert('Missing entity ID');
      return;
    }
    setIsLoading(true);
    const response = await client.internalTicket().adminPortal.query({ entityID });
    setTicketStatusCounts({
      OPEN: response.results.filter((ticket) => ticket.status === 'OPEN').length,
      IN_PROGRESS: response.results.filter((ticket) => ticket.status === 'IN_PROGRESS').length,
      SOLVED: response.results.filter((ticket) => ticket.status === 'SOLVED').length,
    });
    setTickets(response.results.filter((ticket) => !ticket.replyToID)); // remove replies because they are in the response already
    setIsLoading(false);
  };

  useEffect(() => {
    if (entityID) fetchInternalTickets();
  }, [entityID]);

  const values = useMemo(
    () => ({
      fetchInternalTickets,
      setEntityID,
      entityID,
      tickets,
      isLoading,
      ticketStatusCounts,
    }),
    [tickets, isLoading, entityID, ticketStatusCounts],
  );

  return <InternalTicketContext.Provider value={values}>{children}</InternalTicketContext.Provider>;
};
