import React, { useEffect, useState } from 'react';
import { Customer, Invoice, Order } from '@alliance-disposal/transport-types';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { Box, Tab, Tabs } from '@mui/material';
import { ContactsListTable } from '../../../../components/ContactsListCard/ContactsListCard';
import CustomerDetailsLists from '../../../../components/CustomerDetailsLists';
import OrderBillingDetailsLists from '../../../../components/OrderBillingDetailsLists';
import OrderDetailsLists from '../../../../components/OrderDetailsLists';

const styles: Record<string, React.CSSProperties> = {
  topBar: {
    padding: '8px 25px 8px 15px',
    borderTop: 'solid 1px rgba(0, 0, 0, 0.48)',
    borderLeft: 'solid 1px rgba(0, 0, 0, 0.48)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
  },
  bottomBarIcon: {
    width: 16,
    height: 16,
    borderRadius: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    padding: 1,
    justifyContent: 'flex-end',
  },
  bottomBarIconActive: {
    backgroundColor: 'rgba(0, 122, 255, 0.68)',
    borderColor: 'rgba(0, 122, 255, 1)',
  },
  bottomBarIconInactive: {
    backgroundColor: 'rgba(0, 0, 0, 0.68)',
    borderColor: 'rgba(0, 0, 0, 1)',
  },
  bottomBarIconInside: {
    background: '#FFFFFF',
    width: '100%',
  },
  bottomBarBody: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    borderLeft: 'solid 1px rgba(0, 0, 0, 0.48)',
    overflow: 'auto',
  },
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const openStateOptions = [0, 1, 2];
const tabOptions = ['Order', 'Customer', 'Billing'];

interface Props {
  order: Order.AllianceOrderTransport | null;
  receivables: Invoice.ReceivableTransport[] | null;
  payables: Invoice.PayableTransport[] | null;
  customer: Customer.AllianceCustomerTransport | null;
  onShowDetails: (click: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const DetailsBar = ({ order, payables, receivables, customer, onShowDetails }: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [openState, setOpenState] = useState(0);

  useEffect(() => {
    console.log('order,DetailsBar', order);
    console.log('customer,DetailsBar', customer);
    console.log('payables,DetailsBar', payables);
    console.log('receivables,DetailsBar', receivables);
  }, [order, customer, payables, receivables]);

  return (
    <div style={{ position: 'sticky', zIndex: 99, bottom: 0, width: '100%' }}>
      {/* Top of bottom bar */}
      <div style={styles.topBar}>
        <div>
          <span>Order Details</span> #
          {order
            ? `${order.orderNumber} - ${
                order.haulerID ? order.vendorName ?? 'Missing Vendor Name' : 'Not Assigned to Vendor'
              }`
            : null}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {openStateOptions.map((item, index) => {
            let wrapperStyle = {
              ...styles.bottomBarIcon,
              margin: '0 .25rem',
            };
            index === openState
              ? // @ts-expect-error idk why this is happening make it tailwind
                (wrapperStyle = {
                  ...wrapperStyle,
                  ...styles.bottomBarIconActive,
                })
              : // @ts-expect-error idk why this is happening make it tailwind
                (wrapperStyle = {
                  ...wrapperStyle,
                  ...styles.bottomBarIconInactive,
                });
            return (
              <div key={item} style={wrapperStyle as React.CSSProperties} onClick={() => setOpenState(item)}>
                <div
                  style={{
                    ...styles.bottomBarIconInside,
                    height: item === 0 ? 3 : item === 1 ? 7 : 11,
                  }}
                />
              </div>
            );
          })}
          {order && customer ? (
            <ArrowTopRightOnSquareIcon
              className="cursor-pointer h-6 w-6 text-wayste-blue-400"
              onClick={onShowDetails}
            />
          ) : null}
        </div>
      </div>
      {/* Body of bottom bar */}
      <div
        style={{
          ...styles.bottomBarBody,
          paddingBottom: openState !== 0 ? 20 : 0,
          height: openState === 2 ? '80vh' : openState === 1 ? '40vh' : 0,
        }}
      >
        <div
          style={{
            borderBottom: 'solid 1px #D8D8D8',
            display: 'flex',
            padding: '0px 0px 2px',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                position: 'relative',
              }}
            >
              <Tabs
                value={activeTab}
                onChange={(_, newValue) => {
                  setActiveTab(newValue);
                }}
                aria-label="basic tabs example"
              >
                {tabOptions.map((item, index) => (
                  <Tab key={item} label={item} {...a11yProps(index)} />
                ))}
              </Tabs>
            </Box>
            <TabPanel value={activeTab} index={0}>
              {order ? <OrderDetailsLists order={order} break3 /> : null}
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              {customer ? (
                <div>
                  <>ds</>
                  <div>
                    <CustomerDetailsLists customer={customer} />
                  </div>
                  <div className="mt-6">
                    <ContactsListTable customer={customer} addContact={false} />
                  </div>
                </div>
              ) : null}
            </TabPanel>
            {/* Removed Per Dane's Request */}

            <TabPanel value={activeTab} index={2}>
              {order && receivables && payables ? (
                <OrderBillingDetailsLists receivables={receivables} payables={payables} order={order} break3 />
              ) : null}
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default DetailsBar;
