import { WaysteClient, WaysteProvider } from '@alliance-disposal/client';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { init as initApm } from '@elastic/apm-rum';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';

Sentry.init({
  enabled: import.meta.env.MODE !== 'development',
  dsn: 'https://360f2700c0c34eb6baf40ffd78defbc6@o251766.ingest.sentry.io/6336124',
  release: 'alliance-admin-portal@' + __APP_VERSION__,
  environment: import.meta.env.MODE,
  allowUrls: [/admin\.alliancedsp\.com/, /admin\.sourgum\.com/],
  integrations: [
    new BrowserTracing(),
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: import.meta.env.MODE === 'development' ? 1.0 : 0.2,
});

initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: __APP_NAME__.replace('@wayste/', ''),

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: 'https://1c478fe7e83a416987ea30a190f8d3a1.apm.us-east-1.aws.cloud.es.io:443',

  // Set service version (required for sourcemap feature)
  serviceVersion: __APP_VERSION__,

  // Set Environment
  environment: import.meta.env.MODE === 'production' ? 'production' : 'staging',

  // Set distributed tracing origin for APM Server
  distributedTracingOrigins:
    import.meta.env.MODE === 'production' ? ['https://api.wayste.com'] : ['https://api-staging.wayste.com'],

  // Set global labels
  logLevel: import.meta.env.MODE === 'production' ? 'warn' : 'warn',

  breakdownMetrics: true,
});

const apiEndPoint = import.meta.env.VITE_GQL_URL;
const webSocketEndPoint = import.meta.env.VITE_API_WEBSOCKET_URL;

const httpLink = new HttpLink({
  uri: apiEndPoint,
});

const wsLink = new WebSocketLink({
  uri: webSocketEndPoint,
  options: {
    reconnect: true,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

const waysteClient = new WaysteClient({
  cognito: {
    UserPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
    ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
  },
  API: {
    gqlEndpoint: import.meta.env.VITE_GQL_URL,
    baseApiUrl: import.meta.env.VITE_BASE_API_URL,
    webSocketUrl: import.meta.env.VITE_API_WEBSOCKET_URL,
  },
  settings: {
    defaultApp: 'aap',
    defaultRole: 'APP_ADMIN',
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ApolloProvider client={client}>
    <WaysteProvider client={waysteClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </WaysteProvider>
  </ApolloProvider>,
);
