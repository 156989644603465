import React, { useEffect } from 'react';
import { buildAddressString } from '@wayste/utils';

interface customWindow extends Window {
  google?: any;
}

declare const window: customWindow;

type Props = {
  address: AddressType;
};

/**
 * Renders an interactive Google Street View
 * @param {Object} address Valid address object
 */
const GoogleStreetView = ({ address }: Props) => {
  useEffect(() => {
    if (address && address.addressLine1) {
      const addressString = buildAddressString(address);
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: addressString }, (results: any, status: any) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          // const latitiude = results[0].geometry.location.lat();
          // const longitude = results[0].geometry.location.lng();

          const svService = new window.google.maps.StreetViewService();
          const panoRequest = {
            location: results[0].geometry.location,
            preference: window.google.maps.StreetViewPreference.NEAREST,
            radius: 50,
            source: window.google.maps.StreetViewSource.OUTDOOR,
          };

          const findPanorama = (radius: number) => {
            panoRequest.radius = radius;
            svService.getPanorama(panoRequest, (panoData: any, status: any) => {
              if (status === window.google.maps.GeocoderStatus.OK) {
                const panorama = new window.google.maps.StreetViewPanorama(document.getElementById('street-view'), {
                  pano: panoData.location.pano,
                });
              } else {
                // Handle other statuses here
                if (radius > 200) {
                  console.warn('Street View is not available');
                } else {
                  findPanorama(radius + 50);
                }
              }
            });
          };

          findPanorama(50);
        }
      });

      // new window.google.maps.StreetViewPanorama(
      //     document.getElementById('street-view'),
      //     {
      //         position: coords,
      //     }
      // );
    }
  }, [address]);

  return (
    <div
      style={{
        flex: 1,
        minHeight: 291,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F9F9F9',
      }}
      id="street-view"
    >
      Add address for Street View
    </div>
  );
};

export default GoogleStreetView;
