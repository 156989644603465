import { UniversalService } from '@alliance-disposal/transport-types';
import { moneyFormatter } from '@wayste/utils';
import { PencilIcon } from '@heroicons/react/24/solid';
import { useHistory, useParams } from 'react-router-dom';
import { routes } from '../../../utils';
import CardInfoList, { CardInfoListData } from '../../ui/CardInfoList';
import DetailsCardWrapper from '../../ui/DetailsCardWrapper';

interface OneTimeDetailsCardProps {
  serviceOrder: UniversalService.ServiceOrder;
}

const OneTimeDetailsCard = ({ serviceOrder }: OneTimeDetailsCardProps) => {
  const history = useHistory();
  const params: { serviceGroupingId: string; serviceOrderId: string } = useParams();

  const getServiceDays = (serviceDays: number[]) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return serviceDays.map((day) => days[day]).join(', ');
  };

  const handleEditServiceClick = () => {
    history.push(
      routes.universalServices.serviceGrouping.serviceOrder.update(params.serviceGroupingId, params.serviceOrderId),
    );
  };

  const rentalPeriodUnitLabel = serviceOrder.rentalPeriodUnit
    ? UniversalService.RentalPeriodUnitLabels[serviceOrder.rentalPeriodUnit]
    : '';

  const colOne: CardInfoListData[] = [
    {
      label: 'Rental Period',
      value: serviceOrder.originalRentalPeriod
        ? `${serviceOrder.originalRentalPeriod} ${rentalPeriodUnitLabel}`
        : 'Not Applicable',
    },
    {
      label: 'Rental Period (Adjusted)',
      value:
        serviceOrder.adjustedRentalPeriod != null
          ? `${serviceOrder.adjustedRentalPeriod} ${rentalPeriodUnitLabel}s`
          : '',
    },
    {
      label: 'Rental Extension Fee',
      value: moneyFormatter(serviceOrder.rentalExtensionFee),
    },
    {
      label: 'Service Frequency',
      value: serviceOrder.serviceFrequency
        ? serviceOrder.serviceFrequency + ' x per ' + serviceOrder.serviceFrequencyUnit
        : 'Not Applicable',
    },
    {
      label: 'Service Days',
      value: serviceOrder.serviceFrequency ? getServiceDays(serviceOrder.serviceDays) : 'Not Applicable',
    },
  ];

  return (
    <DetailsCardWrapper
      heading="One Time Details"
      buttons={[
        {
          label: (
            <>
              <PencilIcon className="w-5 h-5 mr-1" /> Edit Service
            </>
          ),
          onClick: handleEditServiceClick,
        },
      ]}
    >
      <div className={'grid grid-cols-1 lg:grid-cols-1'}>
        <CardInfoList data={colOne} />
      </div>
    </DetailsCardWrapper>
  );
};

export default OneTimeDetailsCard;
