import { useContext, useEffect, useState } from 'react';
import { Invoice } from '@alliance-disposal/transport-types';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Tooltip } from '@wayste/sour-ui';
import { formatServiceAddress, moneyFormatter, round, toTitleCase } from '@wayste/utils';
import { CheckCircleIcon, PencilIcon, PhotoIcon, TrashIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { UIContext } from '../../../../contexts';
import { haulerBillPaymentMethods } from '../../../../utils';
import { GridCellStatus } from '../../../billing/components/renderCellReceivablesStatus';

const tableHeadings = [
  {
    heading: 'Item',
  },
  {
    heading: 'Description',
    className: 'w-full',
  },
  {
    heading: 'Qty',
    className: 'max-w-[62px]',
  },
  {
    heading: 'Rate',
  },
  {
    heading: 'Amount',
  },
];

const tableData = [
  {
    key: 'item',
    value: (item: Invoice.LineItemTransport) => toTitleCase(item.itemName),
    className: 'border-l-0 whitespace-nowrap',
  },
  {
    key: 'description',
    value: (item: Invoice.LineItemTransport) => <div>{item.description}</div>,
    className: 'w-full',
  },
  {
    key: 'quantity',
    value: (item: Invoice.LineItemTransport) => item.quantity,
    className: 'max-w-[62px] text-right',
  },
  {
    key: 'rate',
    value: (item: Invoice.LineItemTransport) => (
      <div className="flex justify-between">
        <div>$</div>
        {moneyFormatter(round(item?.unitPrice || 0, item.quantity > 1 ? 5 : 2), {
          hideDollarSign: true,
        })}
      </div>
    ),
    className: 'text-right pl-1.5 pr-2.5 min-w-[117px] max-w-[117px]',
  },
  {
    key: 'amount',
    value: (item: Invoice.LineItemTransport) => (
      <div className="flex justify-between">
        <div>$</div>
        {moneyFormatter(item?.totalPrice || 0, {
          hideDollarSign: true,
        })}
      </div>
    ),
    className: 'text-right pl-1.5 pr-2.5 min-w-[117px] max-w-[117px]',
  },
];

interface Props {
  order: UniversalService.ServiceOrder;
  payable: Invoice.PayableTransport;
  ordersMatch: boolean;
  onDeleteBill: () => void;
  onOrderImageClicked: (value: boolean) => void;
  onEditBillClicked: () => void;
  onLineItemClicked: (value: { item: Invoice.LineItemTransport; index: number } | null) => void;
  onReadyForPayment: (value: boolean) => void;
  onGetHaulerName: () => Promise<string>;
}

const PayableCard = ({
  order,
  payable,
  onDeleteBill,
  onOrderImageClicked,
  onEditBillClicked,
  onLineItemClicked,
  onReadyForPayment,
  onGetHaulerName,
}: Props) => {
  const { godModeActive } = useContext(UIContext);
  const [haulerName, setHaulerName] = useState(payable.vendorName || '');

  const handleGetHaulerName = async () => {
    if (payable.haulerID && !haulerName) {
      const name = await onGetHaulerName();
      setHaulerName(name);
    }
  };

  useEffect(() => {
    handleGetHaulerName();
  }, []);

  const actionIcons = [
    {
      icon: CheckCircleIcon,
      click: () => onReadyForPayment(!payable.readyForPayment),
      className: payable.readyForPayment ? 'text-success' : 'text-black opacity-60',
      tip: 'Mark as Ready for Payment',
      disabled: !payable?.id,
    },
    // {
    //   icon: ArrowUpTrayIcon,
    //   click: () => onUploadQB(payable),
    //   className: isQbSynced(payable) ? 'text-success' : 'text-black opacity-60',
    //   tip: 'Entered Into QuickBooks',
    //   disabled: !ordersMatch,
    // },
    {
      icon: PhotoIcon,
      click: () => onOrderImageClicked(true),
      tip: 'View images',
    },
    {
      icon: PencilIcon,
      click: () => onEditBillClicked(),
      className: 'text-edit',
      disabled: payable.invoiceDetails.paidInFull,
      tip: 'Edit payable',
    },
    {
      icon: TrashIcon,
      click: onDeleteBill,
      className: 'text-delete',
      disabled: !(payable.invoiceDetails.status === 'DRAFT'),
      tip: 'Delete payable',
    },
  ];
  useEffect(() => {
    console.log('Bill', payable);
  }, [payable]);

  return (
    <div className="bg-white rounded shadow-dark mb-4 my-4">
      <div className="pt-1.5 px-5 pb-4">
        <div className="flex items-center justify-between border-b pb-2">
          <div className="text-lg mr-1.5 flex items-center gap-2">
            <>
              <span>{`${haulerName} - `}</span>
              <span className="opacity-50">INVOICE</span>
              <span>
                #
                {payable?.invoiceDetails?.waysteOrderNumber
                  ? `${payable?.invoiceDetails.waysteOrderNumber} - ${payable?.invoiceDetails.invoiceNumber}`
                  : payable?.invoiceDetails.invoiceNumber}
              </span>
              {payable?.invoiceDetails?.isWaysteInvoice && (
                <Tooltip
                  text={
                    <p>
                      This is a Wayste invoice meaning it was created by Wayste. You can edit the payable but all
                      changes are visible to the vendor. This number before the dash is the Wayste order number.
                      <br />
                      <br />
                      Dev Stuff:
                      <code>
                        <ul>
                          <li>
                            <span className="font-bold">waysteOrderNumber:</span>{' '}
                            {payable?.invoiceDetails?.waysteOrderNumber}
                          </li>
                          <li>
                            <span className="font-bold">invoiceNumber:</span> {payable?.invoiceDetails?.invoiceNumber}
                          </li>
                          <li>
                            <span className="font-bold">waysteOrderID:</span> {payable?.invoiceDetails?.waysteOrderID}
                          </li>
                        </ul>
                      </code>
                    </p>
                  }
                >
                  <svg
                    className="w-5 h-6"
                    width="110"
                    height="110"
                    viewBox="0 0 110 110"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 20.6043C2.20736 19.74 4.39019 19.0918 6.5485 18.6597C8.7068 18.1795 10.7915 17.9395 12.8027 17.9395C16.4816 17.9395 19.0569 18.7797 20.5284 20.4602C22.0491 22.0927 23.1282 24.4454 23.7659 27.5184L28.3278 50.7814C29.3088 55.4388 30.1182 59.7361 30.7559 63.6733C31.4426 67.6105 31.9086 70.8275 32.1538 73.3243C32.301 74.0925 32.6934 74.4766 33.3311 74.4766L41.4248 20.0281C43.2887 19.596 45.6187 19.3079 48.4147 19.1638C51.2107 18.9718 53.2954 18.8757 54.6689 18.8757C56.1895 18.8757 58.3969 18.9718 61.291 19.1638C64.2341 19.3079 66.5641 19.596 68.2809 20.0281L76.5953 73.3243C76.7915 74.0925 77.184 74.4766 77.7726 74.4766C78.3122 71.2116 78.9008 67.5625 79.5385 63.5293C80.1761 59.4961 80.9365 55.2468 81.8194 50.7814L88.3679 19.3079C89.6433 18.9718 91.0658 18.7317 92.6355 18.5876C94.2542 18.4436 95.7503 18.3716 97.1238 18.3716C98.6934 18.3716 100.705 18.4916 103.157 18.7317C105.659 18.9238 107.719 19.4279 109.338 20.2442L110 21.6126L92.8562 91.1137C90.5507 91.4498 87.8283 91.6658 84.689 91.7618C81.5496 91.9059 78.631 91.9779 75.9331 91.9779C71.5184 91.9779 68.1828 91.3777 65.9264 90.1774C63.67 88.929 62.1984 86.1201 61.5117 81.7508L59.01 66.2661C58.6667 63.8654 58.2742 61.2006 57.8328 58.2717C57.3913 55.3428 56.9498 52.2459 56.5084 48.9809C56.0669 45.7159 55.6499 42.4269 55.2575 39.1139H54.3746C54.0312 42.3789 53.6143 45.6199 53.1237 48.8368C52.6823 52.0538 52.2408 55.1267 51.7993 58.0556C51.3579 60.9365 50.9409 63.5773 50.5485 65.978L46.4281 91.1137C44.0245 91.4017 41.204 91.6178 37.9666 91.7618C34.7782 91.9059 31.8105 91.9779 29.0635 91.9779C24.7469 91.9779 21.4359 91.3057 19.1304 89.9613C16.874 88.5689 15.2553 85.736 14.2742 81.4627L0 20.6043Z"
                      fill="#000033"
                    />
                  </svg>
                </Tooltip>
              )}
              <GridCellStatus
                value={
                  payable.invoiceDetails.isWaysteInvoice && payable.invoiceDetails.status === 'DRAFT'
                    ? 'DRAFT'
                    : payable.invoiceDetails.paidInFull
                    ? 'PAID'
                    : 'PAST_DUE'
                }
              />
            </>
          </div>
          <div>({formatServiceAddress(order.serviceLocation?.address)})</div>
          <div className="flex">
            {actionIcons.map((icon) => (
              <Tooltip text={icon.tip} key={icon.tip}>
                <button
                  type="button"
                  className="btn-icon disabled:!pointer-events-none"
                  onClick={icon.click}
                  disabled={icon?.disabled}
                >
                  <icon.icon className={`h-6 w-6 ${icon.className}`} />
                </button>
              </Tooltip>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full overflow-x-auto">
        <table className="w-auto border-collapse border-spacing-0 text-sm">
          <thead>
            <tr className="border-b [&>*]:py-1.5 [&>*]:px-4 [&>*]:font-normal">
              {tableHeadings.map((heading) => (
                <td key={heading.heading} className={`opacity-50 ${heading.className || ''}`}>
                  {heading.heading}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {payable.invoiceDetails.lineItems.map((item, index) => (
              <tr
                key={item.itemName + index}
                className={`border-b [&>*]:py-1.5 [&>*]:px-4 even:bg-gray-100 ${
                  payable.invoiceDetails.status !== 'DRAFT' && !godModeActive ? 'cursor-default' : 'cursor-pointer'
                }`}
                onClick={() => {
                  if (payable.invoiceDetails.status !== 'DRAFT' && !godModeActive) return;
                  onLineItemClicked({ item, index });
                }}
              >
                {tableData.map((cell) => (
                  <td className={`border-l ${cell.className || ''}`} key={cell.key}>
                    {cell.value(item)}
                  </td>
                ))}
              </tr>
            ))}

            {/* Add Charge && Total */}
            <tr className="[&>*]:px-4">
              <td colSpan={3}>
                <button
                  className="btn-primary-text-only whitespace-nowrap"
                  onClick={() => {
                    if (payable.invoiceDetails.status !== 'DRAFT' && !godModeActive) {
                      return;
                    }
                    onLineItemClicked(null);
                  }}
                  disabled={payable.invoiceDetails.status !== 'DRAFT' && !godModeActive}
                >
                  Add Charge
                </button>
              </td>
              <td className="text-right">Total</td>
              <td className="text-right pl-1.5 pr-2.5 min-w-[117px] max-w-[117px]">
                <div className="flex justify-between">
                  <div>$</div>
                  {moneyFormatter(payable.invoiceDetails.total, {
                    hideDollarSign: true,
                  })}
                </div>
              </td>
            </tr>

            {/* Amount Paid && Payment Method */}
            <tr className="[&>*]:px-4">
              <td colSpan={2}>
                <span className="opacity-50">Payment Method:</span>{' '}
                {
                  haulerBillPaymentMethods[
                    payable.invoiceDetails.payments[0]?.paymentMethod as keyof typeof haulerBillPaymentMethods
                  ]
                }
              </td>
              <td className="text-right">
                {payable.invoiceDetails.payments.map((payment) =>
                  payment.paymentSentDate
                    ? format(new Date(payment.paymentSentDate), 'MM/dd/yy') + ' - ' + payment.paymentIdentifier
                    : format(new Date(payment.date), 'MM/dd/yy') + ' - ' + payment.paymentIdentifier,
                )}
              </td>
              <td className="text-right">Paid</td>
              <td className="text-right pl-1.5 pr-2.5 min-w-[117px] max-w-[117px]">
                <div className="flex justify-between">
                  <div>$</div>
                  {moneyFormatter(payable.invoiceDetails.total - payable.invoiceDetails.remainingBalance, {
                    hideDollarSign: true,
                  })}
                </div>
              </td>
            </tr>

            {/* Amount Due */}
            <tr className="[&>*]:px-4">
              <td colSpan={3}>
                {payable.invoiceDetails.paidInFull ? (
                  <span className="text-success">Paid</span>
                ) : (
                  <span className="text-red-500">Overdue</span>
                )}
              </td>
              <td className="text-right">Due</td>
              <td className="text-right pl-1.5 pr-2.5 min-w-[117px] max-w-[117px]">
                <div className="flex justify-between">
                  <div>$</div>
                  {moneyFormatter(payable.invoiceDetails.remainingBalance, {
                    hideDollarSign: true,
                  })}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PayableCard;
