import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Pricing, UniversalService } from '@alliance-disposal/transport-types';
import { CurrencyTextField, DatePicker, Dialog, Select, SelectOption, TextField, Toggle } from '@wayste/sour-ui';
import { formatServiceAddress, moneyFormatter } from '@wayste/utils';
import { createBillingEvent } from '@wayste/utils';
import { isAxiosError } from 'axios';
import { Controller, get, useForm } from 'react-hook-form';
import { useFlash } from '../../hooks/useFlash';

interface AssignToVendorDialogProps {
  serviceOrder: UniversalService.ServiceOrder;
  serviceGrouping?: UniversalService.ServiceGrouping;
  open: boolean;
  onClose: (optimisticUpdate?: Partial<UniversalService.ServiceOrder>) => void;
}

type FormData = {
  baseRate: {
    unitPrice: number;
    quantity: number;
    unit: UniversalService.ServiceLineItemUnitType;
  };
  deliveryRate: number;
  removalRate: number;
  contractDetails?: {
    contractTerm: number;
    contractStartDate: string;
    contractEndDate: string;
  };
};

// TODO: Implement this dialog
const AssignToVendorDialog = ({ serviceOrder, open, onClose }: AssignToVendorDialogProps) => {
  const { showFlash } = useFlash();
  const client = useWaysteClient();
  const [pricingZones, setPricingZones] = useState<Pricing.ProductPricingZoneTransport[] | null>(null);
  const [selectedZone, setSelectedZone] = useState<Pricing.ProductPricingZoneTransport | null>(null);
  const [sendVendorEmail, setSendVendorEmail] = useState<boolean>(false);

  const {
    control,
    formState: { errors, isValid },
    reset,
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      baseRate: {
        unitPrice: 0,
        quantity: 1,
        unit: 'each',
      },
      deliveryRate: 0,
      removalRate: 0,
    },
  });

  const customerPrice = () => {
    const event = serviceOrder.events.find((event) => event.lineItemType.description === 'recurring');

    return {
      price: event?.unitPrice ?? 0,
      otherFees: serviceOrder.events.filter(
        (event) => event.lineItemType.description !== 'recurring' && event.unitPrice,
      ),
    };
  };

  const fetchVendorPricingZone = async ({ lat, lng, skuID }: { lat: number; lng: number; skuID?: string }) => {
    const pricing = await client.universalService().pricingZone.query({
      lat,
      lng,
      skuID,
    });

    setPricingZones(pricing.results);
    setSelectedZone(null);
  };

  useEffect(() => {
    if (serviceOrder.serviceLocation?.coordinates) {
      fetchVendorPricingZone({
        lat: serviceOrder.serviceLocation.coordinates.lat,
        lng: serviceOrder.serviceLocation.coordinates.lng,
        skuID: serviceOrder.serviceType.id,
      });
    }
    reset();
  }, [serviceOrder, open]);

  const closeWithUpdate = () => {
    onClose({
      status: 'ASSIGNED',
    });
  };

  const onSubmit = async (data: FormData) => {
    if (!selectedZone) {
      showFlash('You must select a vendor', 'warning');
      return;
    }

    const eventTypes = serviceOrder.serviceType.family.lineItemTypes;

    const lineItemMap = new Map();

    eventTypes.forEach((item) => {
      if (UniversalService.DefaultLineItemTypes.includes(item.description as UniversalService.DefaultLineItemType)) {
        lineItemMap.set(item.description, item);
      }
    });

    const recurring = lineItemMap.get('recurring');

    if (!lineItemMap.get('recurring') || !lineItemMap.get('delivery') || !lineItemMap.get('removal')) {
      showFlash(
        'This Service type is not set up correctly and cannot be used. Please contact an administrator.',
        'warning',
      );
      return;
    }

    // let's make our service events
    const serviceEvents: UniversalService.ServiceEventCreate[] = [
      createBillingEvent(
        {
          costQuantity: data.baseRate.quantity,
          unitCost: data.baseRate.unitPrice,
          unitType: data.baseRate.unit,
        },
        recurring.id,
      ),
    ];

    if (data.deliveryRate) {
      // add delivery event
      serviceEvents.push(
        createBillingEvent(
          {
            costQuantity: 1,
            unitCost: data.deliveryRate,
            unitType: 'each',
          },
          lineItemMap.get('delivery').id,
        ),
      );
    }

    if (data.removalRate) {
      // add removal event
      serviceEvents.push(
        createBillingEvent(
          {
            costQuantity: 1,
            unitCost: data.removalRate,
            unitType: 'each',
          },
          lineItemMap.get('removal').id,
        ),
      );
    }

    try {
      // add to service order
      await client
        .universalService()
        .serviceGrouping.serviceOrder.serviceOrderEvent.createMany(
          serviceOrder.serviceGroupingID,
          serviceOrder.id,
          serviceEvents,
        );

      await client
        .universalService()
        .serviceGrouping.serviceOrder.update(serviceOrder.serviceGroupingID, serviceOrder.id, {
          status: 'ASSIGNED',
          vendorID: selectedZone.vendorID,
          vendorName: selectedZone.vendorName,
        });
      closeWithUpdate();
    } catch (e) {
      let message = 'Something went wrong. Please try again later.';
      console.error(e);

      if (isAxiosError(e)) {
        message = e.response?.data?.additionalInfo ?? e.response?.data?.message ?? message;
      }

      showFlash(message, 'error');
    } finally {
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      styledTitle="Assign to Vendor"
      className="max-w-screen-xl"
      showX
    >
      <div className="p-2 flex-1 flex flex-col gap-4 w-full">
        <div className="flex flex-col gap-1">
          <span>Assign vendor to:</span>
          <span>{formatServiceAddress(serviceOrder.serviceLocation?.address)}</span>
          <span>
            {serviceOrder.serviceType.family.name} - {serviceOrder.serviceType.name}
            {serviceOrder.subscriptionDetails ? ' - Subscription' : ' - One Time'}
          </span>
          <span>
            Customer Price: {moneyFormatter(customerPrice().price)}{' '}
            {customerPrice()
              .otherFees.map((fee) => moneyFormatter(fee.unitPrice) + ' ' + fee.lineItemType.description)
              .join('| ')}
          </span>
        </div>
        <Toggle label="Send Confirmation Email to Vendor" onChange={setSendVendorEmail} value={sendVendorEmail} />
        <div className="w-full rounded-md border p-2 mt-6">
          <table className="table-fixed w-full">
            <thead className="border-b">
              <tr>
                <th className="px-2 w-12"></th>
                <th className="px-2">Vendor</th>
              </tr>
            </thead>
            <tbody className="divide-y">
              {pricingZones?.map((pricingZone) => (
                <tr
                  key={pricingZone.id}
                  className="py-2 px-2 border-b last:border-b-0 cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    if (selectedZone?.id === pricingZone.id) {
                      setSelectedZone(null);
                      return;
                    }
                    setSelectedZone(pricingZone);
                  }}
                >
                  <td className="px-2 flex justify-center items-center">
                    {selectedZone?.id === pricingZone.id && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    )}
                  </td>
                  <td className="px-2">{pricingZone.vendorName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <section className="grid grid-cols-3 gap-4">
          <Controller
            control={control}
            name={`baseRate.unitPrice`}
            defaultValue={0}
            rules={{
              required: true,
              min: {
                value: 0,
                message: 'Unit Price must be greater than 0',
              },
            }}
            render={({ field }) => (
              <CurrencyTextField
                label="Vendor Unit Price"
                error={get(errors, `baseRate.unitPrice`)}
                value={(+field.value / 100).toString()}
                onChange={(value) => field.onChange(+value * 100)}
                required
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={`baseRate.quantity`}
            defaultValue={1}
            render={({ field }) => (
              <TextField
                type={'number'}
                label="Vendor Quantity"
                required
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
          <Controller
            name={`baseRate.unit`}
            control={control}
            render={({ field }) => (
              <Select
                error={get(errors, 'baseRate.unit')}
                label="Unit"
                onSelect={field.onChange}
                defaultValue={'each'}
                required
                value={field.value}
              >
                {UniversalService.ServiceLineItemUnitTypes.map((unit, index) => (
                  <SelectOption key={'rentalPeriodUnits' + index} value={unit}>
                    {unit}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
          <Controller
            control={control}
            name={`deliveryRate`}
            defaultValue={0}
            render={({ field }) => (
              <CurrencyTextField
                label="Vendor Delivery Fee"
                error={get(errors, `deliveryRate`)}
                value={(+field.value / 100).toString()}
                onChange={(value) => field.onChange(+value * 100)}
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={`removalRate`}
            defaultValue={0}
            render={({ field }) => (
              <CurrencyTextField
                label="Vendor Removal Fee"
                error={get(errors, `removalRate`)}
                value={(+field.value / 100).toString()}
                onChange={(value) => field.onChange(+value * 100)}
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
        </section>
        {serviceOrder.subscriptionDetails && (
          <section className="grid grid-cols-3 gap-4">
            <Controller
              control={control}
              name="contractDetails.contractTerm"
              rules={{
                min: 0,
              }}
              defaultValue={0}
              render={({ field }) => (
                <TextField
                  type={'number'}
                  label="Contract Term in Months"
                  error={get(errors, 'serviceGrouping.contractDetails.contractTerm')}
                  inputProps={{
                    ...field,
                    disabled: !serviceOrder.subscriptionDetails,
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="contractDetails.contractStartDate"
              render={({ field }) => (
                <DatePicker
                  value={field.value ? new Date(field.value) : ''}
                  onChange={(date) => field.onChange(date)}
                  // minDate={new Date()}
                  required
                  label="Contract Start Date"
                  closeOnSelect
                  clearable
                  inputProps={{
                    disabled: !serviceOrder.subscriptionDetails,
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="contractDetails.contractEndDate"
              render={({ field }) => (
                <DatePicker
                  value={field.value ? new Date(field.value) : undefined}
                  onChange={(date) => field.onChange(date)}
                  minDate={new Date()}
                  required
                  label="Contract End Date"
                  inputProps={{
                    disabled: !serviceOrder.subscriptionDetails,
                  }}
                  closeOnSelect
                  clearable
                />
              )}
            />
          </section>
        )}
        <div className="flex justify-end gap-2">
          <button
            type="button"
            className="btn-dark-grey-outlined"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn-primary"
            disabled={!selectedZone && isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </button>
        </div>
      </div>
    </Dialog>
  );
};
export default AssignToVendorDialog;
