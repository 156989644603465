import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice } from '@alliance-disposal/transport-types';
import { Button, CurrencyTextField, Dialog, Select, SelectOption, Textarea } from '@wayste/sour-ui';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { createStripeRefund } from '../../../../axios/stripev2';
import { UIContext } from '../../../../contexts';
import { useAuthToken } from '../../../../hooks/authhook';

const refundMethods = {
  stripe: 'Stripe',
  check: 'Check',
  other: 'Other',
};
const refundReason = {
  duplicate: 'Duplicate',
  requested_by_customer: 'Requested by Customer',
  other: 'Other',
};

interface FormProps {
  paymentRefundOptionID: string;
  amountDollars: string;
  paymentMethod: keyof typeof refundMethods | '';
  reason: keyof typeof refundReason | '';
  notes: string;
  methodNotes: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  invoice: Invoice.ReceivableTransport | null;
  handleOnRefund: (receivable: Invoice.ReceivableTransport) => void;
}

const RefundDialog = ({ open, onClose, invoice, handleOnRefund }: Props) => {
  const client = useWaysteClient();
  const { token } = useAuthToken();
  const { showFlash } = useContext(UIContext);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentRefundOption] = useState<any>(invoice?.invoiceDetails.payments[0]);

  if (!invoice) return null;

  const {
    control,
    reset,
    watch,
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      paymentRefundOptionID: invoice.invoiceDetails.payments[0].id,
      amountDollars: '',
      paymentMethod: '',
      reason: '',
      notes: '',
      methodNotes: '',
    },
  });

  const watchPaymentMethod = watch('paymentMethod');

  const handleStripeRefund = async (values: FormProps) => {
    try {
      return await createStripeRefund(
        paymentRefundOption.stripeChargeID,
        Number(values.amountDollars),
        token,
        values.reason === 'other' ? 'requested_by_customer' : values.reason || undefined,
      );
    } catch (error) {
      console.warn('handleStripeRefund error: ', error);
      return 'error';
    }
  };

  const onSubmit = async (values: FormProps) => {
    setIsLoading(true);
    const notes = values.notes + ' Method Notes: ' + values.methodNotes;
    const transactionObject: Record<string, any> = {
      // TODO needs an invoice id to associate it to
      date: new Date().toISOString(),
      amountDollars: Math.abs(Number(values.amountDollars)),
      paymentMethod: values.paymentMethod,
      refundIdentifier: values.paymentMethod === 'check' ? values.methodNotes : '',
      reason: values.reason,
      notes: notes,
      stripeChargeID: undefined,
      stripeRefundID: undefined,
    };

    if (values.paymentMethod === 'stripe') {
      const stripeResults: any = await handleStripeRefund(values);
      transactionObject.refundIdentifier = paymentRefundOption.last4;
      if (stripeResults === 'error') {
        alert('An error occurred creating the Stripe refund ...  get AAP Dev');
        return;
      }
      transactionObject.stripeChargeID = paymentRefundOption.stripeChargeID;
      transactionObject.stripeRefundID = stripeResults.id;
    }
    // newLineItem is needed to keep invoice total in sync with change...TODO handle edge case of overpayment
    const newLineItem: Invoice.LineItemInputTransport = {
      itemName: 'Refund',
      description: format(new Date(), 'MM/dd/yy'),
      quantity: 1,
      unitPriceDollars: -Math.abs(Number(values.amountDollars)),
      totalPriceDollars: -Math.abs(Number(values.amountDollars)),
      taxable: false,
    };

    try {
      await client
        .invoice()
        .adminPortal.refund.create(invoice.invoiceDetails.id, transactionObject as Invoice.RefundCreateTransport);
      const invoiceToUpdate: Invoice.ReceivableUpdateTransport = { ...invoice };
      if (invoice.invoiceDetails) {
        invoiceToUpdate.invoiceDetails = {
          ...invoice,
          ...invoice.invoiceDetails,
          lineItems: [...invoice.invoiceDetails.lineItems, newLineItem],
        };
      }
      handleOnRefund(invoiceToUpdate as Invoice.ReceivableTransport);
      showFlash('Refund successfully created', 'success');
    } catch (error) {
      showFlash('An error occurred updating the database', 'warning');

      return;
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} styledTitle="Refund Payment">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4">
          <div className="w-full">
            <Controller
              name="paymentRefundOptionID"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'A payment is required',
                },
              }}
              render={({ field }) => (
                <Select
                  label="Payment to Refund"
                  value={field.value}
                  onSelect={(value) => field.onChange(value)}
                  required={true}
                >
                  {invoice.invoiceDetails.payments.map((item) => (
                    <SelectOption key={`paymentRefundOption-${item.id}`} value={item.id}>
                      Payment for ${item.amountDollars}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              name="amountDollars"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'A refund amount is required',
                },
              }}
              render={({ field }) => (
                <CurrencyTextField
                  label="Refund amount"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  required={true}
                />
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              name="paymentMethod"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Refund method is required',
                },
              }}
              render={({ field }) => (
                <Select label="Method" value={field.value} onSelect={(value) => field.onChange(value)} required={true}>
                  {Object.entries(refundMethods).map((item) => (
                    <SelectOption
                      key={`method-${item[0]}`}
                      value={item[0]}
                      disabled={item[0] === 'stripe' && !paymentRefundOption.stripeChargeID}
                    >
                      {item[1]}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
            {watchPaymentMethod === 'stripe' ? (
              <div className="text-xs opacity-50">Refunds take 5-10 days to appear on a customer's statement.</div>
            ) : null}
          </div>
          {watchPaymentMethod === 'check' || watchPaymentMethod === 'other' ? (
            <div className="w-full">
              <Textarea
                placeholder={watchPaymentMethod === 'check' ? 'Check number' : 'How did you issue the refund?'}
                required={true}
                inputProps={{
                  ...register('methodNotes', { required: 'Method notes are required' }),
                }}
              />
            </div>
          ) : null}
          <div className="w-full">
            <Controller
              name="reason"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'A refund reason is required',
                },
              }}
              render={({ field }) => (
                <Select label="Reason" value={field.value} onSelect={(value) => field.onChange(value)} required={true}>
                  {Object.entries(refundReason).map((item) => (
                    <SelectOption key={`reason-${item[0]}`} value={item[0]}>
                      {item[1]}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </div>
          <div className="w-full">
            <Textarea
              placeholder="Add more details about this refund"
              required={watchPaymentMethod === 'other'}
              inputProps={{
                ...register('notes', { required: watchPaymentMethod === 'other' ? 'Notes are required' : false }),
              }}
            />
          </div>
        </div>
        <div className="px-2 pt-4 flex justify-end gap-4 border-t mt-2">
          <button className="btn-dark-grey-outlined mr-2" onClick={handleClose} type="button" disabled={isLoading}>
            Cancel
          </button>
          <Button
            className="btn-primary"
            type="submit"
            disabled={!isValid || !isDirty}
            // onClick={formik.handleSubmit}
            loading={isLoading}
          >
            Refund
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default RefundDialog;
