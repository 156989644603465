import { useEffect, useState } from 'react';
import { Pricing } from '@alliance-disposal/transport-types';
import { Button, Dialog, Select, SelectOption } from '@wayste/sour-ui';

interface NegativeZoneModalProps {
  open: boolean;
  onCancel: () => void;
  onSubmit: (zone: Pricing.HaulerPricingZone | undefined) => void;
  zones: Array<Pricing.HaulerPricingZone | Pricing.ProductPricingZoneTransport>;
}

const CopyZoneModal = ({ open, onCancel, onSubmit, zones }: NegativeZoneModalProps) => {
  const [selected, setSelected] = useState<Pricing.HaulerPricingZone | Pricing.ProductPricingZoneTransport | undefined>(
    undefined,
  );

  const handleSelect = (value: string) => {
    console.log('zones NEG', value);

    if (zones) {
      const zone = zones.find((zone: any) => zone.id === value);
      setSelected(zone);
    }
  };

  useEffect(() => {
    console.log('zones NEG 6', zones);
  }, [zones]);

  return (
    <Dialog open={open} onClose={onCancel} styledTitle="Copy Zone">
      <div className="space-y-4">
        <div>
          <Select label="Select zone" onSelect={handleSelect} defaultValue={''} value={selected?.id}>
            {zones?.map((zone) => (
              <SelectOption value={zone.id}>{zone.zoneName}</SelectOption>
            ))}
          </Select>
        </div>
        <div className="flex flex-row space-x-1 justify-end">
          <Button onClick={onCancel} className="btn-dark-grey-outlined">
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSubmit(selected as any);
            }}
            disabled={!selected ? true : false}
            className="btn-secondary"
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default CopyZoneModal;
