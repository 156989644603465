import { useEffect, useState } from 'react';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Checkbox, Loading } from '@wayste/sour-ui';
import { Button, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { Tooltip } from '@wayste/sour-ui';
import {
  StatusType,
  createCommunicationTriggers,
  parseCommunicationTriggers,
  updateCommunicationTriggers,
} from '@wayste/utils';
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid';
import { Controller, FormProvider, SubmitHandler, get, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { ChangeLineItemTypeStatusModal } from '../ServiceUpdate/ChangeLineItemTypeStatusModal';
import { ChangeServiceTypeStatusModal } from '../ServiceUpdate/ChangeServiceTypeStatusModel';
import SortableList from './StatusesDragAndDrop';

export interface ServiceForm extends UniversalService.ServiceTypeFamilyUpsert {
  statuses: StatusType[];
  preStatuses: StatusType[];
  postStatuses: StatusType[];
}

///////////////////////////////////////////////
// DEFAULT FORM VALUES
///////////////////////////////////////////////

const blankServiceType: UniversalService.ServiceTypeRecursiveUpdate = {
  name: '',
  notes: '',
};

const blankLineItemType: UniversalService.ServiceLineItemTypeUpsert = {
  description: '',
  internalNotes: '',
  active: true,
  baseCost: 0,
  basePrice: 0,
  minQuantity: 0,
  taxable: true,
  unitCost: 0,
  unitPrice: 0,
  unitType: 'each',
};

const defaultForm: ServiceForm = {
  serviceTypes: [
    {
      name: '',
      notes: '',
    },
  ],
  isSubscription: false,
  preStatuses: [
    {
      status: 'UNASSIGNED',
    },
    {
      status: 'ASSIGNED',
    },
  ],
  statuses: [],
  postStatuses: [
    {
      status: 'COMPLETED',
    },
  ],
  shortCode: '',
  name: '',
  allowedStatuses: [],
  initialStatus: 'UNASSIGNED',
  communicationTriggers: [],
  lineItemTypes: [],
};

type ServicesFormProps = {
  serviceFamily?: UniversalService.ServiceTypeFamily;
  serviceFamilyID?: string;
  onSubmit: (data: UniversalService.ServiceTypeFamilyUpsert, id?: string) => void;
  onCancel: () => void;
  isLoading: boolean;
  onModified?: () => void;
};

export const ServicesForm = ({
  onSubmit,
  serviceFamily,
  serviceFamilyID,
  onCancel,
  isLoading,
  onModified,
}: ServicesFormProps) => {
  //LOCAL STATE
  const [mode, setMode] = useState<'create' | 'edit'>('create');
  const [activeStatuses, setActiveStatuses] = useState<string[]>([]);

  // DIALOG
  const [changeServiceTypeStatusDialogOpen, setChangeServiceTypeStatusDialogOpen] = useState<boolean>(false);
  const [changeServiceType, setChangeServiceType] = useState<UniversalService.ServiceTypeRecursiveUpdate | undefined>(
    undefined,
  );
  const [changeLineItemTypeStatusDialogOpen, setChangeLineItemTypeStatusDialogOpen] = useState<boolean>(false);
  const [changeLineItemType, setChangeLineItemType] = useState<UniversalService.ServiceLineItemTypeUpsert | undefined>(
    undefined,
  );

  //FORM CONTEXT
  const methods = useForm<ServiceForm>();

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  //FIELD ARRAYS USED FOR Service Type
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'serviceTypes',
  });

  const {
    fields: lineItemFields,
    append: appendLineItem,
    remove: removeLineItem,
  } = useFieldArray({
    control,
    name: 'lineItemTypes',
  });

  // WATCH IS SUBSCRIPTION FIELD
  const isSubscription = useWatch({
    control,
    name: 'isSubscription',
  });

  ///////////////////////////////////////////////
  // FUNCTIONS
  ///////////////////////////////////////////////

  const handleAddServiceType = () => {
    append({ ...blankServiceType });
  };

  const handleAddLineItemType = () => {
    appendLineItem({ ...blankLineItemType });
  };

  const handleRemoveServiceType = (index: number) => {
    remove(index);
  };

  const handleRemoveLineItemType = (index: number) => {
    removeLineItem(index);
  };

  const isDefaultLineItemType = (description: string) => {
    return UniversalService.DefaultLineItemTypes.includes(
      description as (typeof UniversalService.DefaultLineItemTypes)[number],
    );
  };

  // FORM SUBMISSION HANDLER
  const submitForm: SubmitHandler<ServiceForm> = (data: ServiceForm) => {
    const newData: UniversalService.ServiceTypeFamilyUpsert = {
      ...data,
      communicationTriggers:
        mode === 'edit'
          ? updateCommunicationTriggers([...data.preStatuses, ...data.statuses, ...data.postStatuses])
          : createCommunicationTriggers([...data.preStatuses, ...data.statuses, ...data.postStatuses]),
      allowedStatuses: [...data.preStatuses, ...data.statuses, ...data.postStatuses].map((status: StatusType) => {
        return status.status;
      }),
    };
    onSubmit(newData, serviceFamilyID);
  };

  const parseForm = (data: UniversalService.ServiceTypeFamily) => {
    data.lineItemTypes.sort((a, b) => {
      const isDefaultA = isDefaultLineItemType(a.description);
      const isDefaultB = isDefaultLineItemType(b.description);
      if (isDefaultA !== isDefaultB) {
        // Sort by isDefaultLineItemType
        return isDefaultA ? -1 : 1;
      } else {
        // If both have the same isDefaultLineItemType status, sort alphabetically
        return a.description.localeCompare(b.description);
      }
    });
    data.serviceTypes.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    const formData: ServiceForm = {
      ...data,
      preStatuses: [
        {
          status: 'UNASSIGNED',
        },
        ...(parseCommunicationTriggers(data.communicationTriggers, data.allowedStatuses).filter(
          (status: StatusType) => status.status === 'ASSIGNED',
        ) as StatusType[]),
      ],
      postStatuses: [
        {
          status: 'COMPLETED',
        },
      ],
      statuses: parseCommunicationTriggers(data.communicationTriggers, data.allowedStatuses).filter(
        (status: StatusType) =>
          status.status !== 'UNASSIGNED' && status.status !== 'ASSIGNED' && status.status !== 'COMPLETED',
      ) as StatusType[],
    };
    setActiveStatuses(data.allowedStatuses);
    return formData;
  };

  ///////////////////////////////////////////////
  // HOOKS SECTION
  ///////////////////////////////////////////////

  // SET DEFAULT VALUES WHEN EDITING A SERVICE
  useEffect(() => {
    const newData = defaultForm;
    if (serviceFamily) {
      setMode('edit');
      reset({
        ...(parseForm(serviceFamily) as ServiceForm),
      });
      // SET DEFAULT VALUES FOR FORM
    } else {
      setMode('create');
      reset({ ...(newData as ServiceForm) });
    }
  }, [serviceFamily]);

  ///////////////////////////////////////////////
  // RENDER SECTION
  ///////////////////////////////////////////////

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitForm)}>
        {serviceFamilyID && changeServiceType && (
          <ChangeServiceTypeStatusModal
            serviceType={changeServiceType}
            serviceFamilyID={serviceFamilyID}
            show={changeServiceTypeStatusDialogOpen}
            setShow={(show: boolean) => {
              setChangeServiceTypeStatusDialogOpen(show);
            }}
            onModified={onModified}
          />
        )}
        {serviceFamilyID && changeLineItemType && (
          <ChangeLineItemTypeStatusModal
            lineItemType={changeLineItemType}
            serviceFamilyID={serviceFamilyID}
            show={changeLineItemTypeStatusDialogOpen}
            setShow={(show: boolean) => {
              setChangeLineItemTypeStatusDialogOpen(show);
            }}
            onModified={onModified}
          />
        )}

        <div className="p-2 flex flex-col space-y-6 ">
          {/* Service Type Family NAME FIELD  */}
          <div className="w-1/3 pr-2">
            <Controller
              control={control}
              name={'name'}
              rules={{
                required: {
                  value: true,
                  message: 'Service Type Family name is required',
                },
              }}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  type={'string'}
                  label="Service Type Family name"
                  required
                  error={get(errors, 'name')}
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />
          </div>
          <div className="w-1/3 pr-2">
            <Controller
              control={control}
              name={'shortCode'}
              rules={{
                required: {
                  value: true,
                  message: 'Service shortCode name is required',
                },
                maxLength: {
                  value: 2,
                  message: 'Service shortCode must be 2 characters',
                },
              }}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  type={'string'}
                  label="Service short code"
                  required
                  error={get(errors, 'shortCode')}
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />
          </div>
          <div className="flex-col flex space-y-4  md:flex-row md:space-x-4 md:space-y-0 ">
            {/* SENDGRID FOR CUSTOMER CONFIRMATION FIELD */}
            <Controller
              control={control}
              name={'customerConfirmation'}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  type={'string'}
                  label="Sendgrid ID for customer confirmation"
                  error={get(errors, 'customerConfirmation')}
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />
            {/* SENDGRID FOR CUSTOMER CONFIRMATION FIELD */}
            <Controller
              control={control}
              name={'customerOrderChanges'}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  type={'string'}
                  label="Sendgrid ID for customer order changes"
                  error={get(errors, 'customerOrderChanges')}
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />
            {/* SENDGRID FOR CUSTOMER CONFIRMATION FIELD */}
            <Controller
              control={control}
              name={'vendorOrderChanges'}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  type={'string'}
                  label="Sendgrid ID for vendor order changes"
                  error={get(errors, 'vendorOrderChanges')}
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />
          </div>

          {/* SERVICE STATUSES FIELDS */}
          <div className="text-xl">Service Statuses</div>

          <SortableList serviceFamilyID={serviceFamilyID} mode={mode} activeStatuses={activeStatuses} />

          {/* SERVICE STATUSES FIELDS */}
          <div className="text-xl">Service Type</div>

          {fields.map((item, index) => {
            return (
              <div className="flex flex-row space-x-6 a items-center" key={item.id}>
                <Controller
                  control={control}
                  name={`serviceTypes.${index}.name`}
                  defaultValue={''}
                  rules={{
                    required: {
                      value: true,
                      message: 'Service Type name is required',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      type={'string'}
                      label="Service Type name"
                      required
                      error={get(errors, 'serviceTypes.${index}.name')}
                      inputProps={{
                        ...field,
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`serviceTypes.${index}.notes`}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      type={'string'}
                      label="notes"
                      error={get(errors, 'serviceTypes.${index}.internalNotes')}
                      inputProps={{
                        ...field,
                      }}
                    />
                  )}
                />
                {mode === 'create' || getValues(`serviceTypes.${index}`).id === undefined ? (
                  <Button
                    className="w-[240px]"
                    onClick={() => handleRemoveServiceType(index)}
                    endIcon={<TrashIcon className="w-5 h-5 text-red-600 cursor-pointer" />}
                  >
                    {null}
                  </Button>
                ) : (
                  <>
                    {item.active ? (
                      <Button
                        className="btn-delete w-[200px]"
                        onClick={() => {
                          setChangeServiceTypeStatusDialogOpen(true);
                          setChangeServiceType(getValues(`serviceTypes.${index}`));
                        }}
                        disabled={isLoading}
                        type="button"
                      >
                        Deactivate
                      </Button>
                    ) : (
                      <Button
                        className="btn-primary-outlined w-[200px]"
                        onClick={() => {
                          setChangeServiceTypeStatusDialogOpen(true);
                          setChangeServiceType(getValues(`serviceTypes.${index}`));
                        }}
                        disabled={isLoading}
                        type="button"
                      >
                        Reactivate
                      </Button>
                    )}
                  </>
                )}
              </div>
            );
          })}
          <div className="py-2 cursor-pointer" onClick={handleAddServiceType}>
            <div className="flex flex-row items-center space-x-2 text-sourgum-green-400">
              <PlusIcon className="w-5 h-5" />
              <span> Add another Service Type</span>
            </div>
          </div>

          {/* SERVICE A SUBSCRIPTION */}
          <div className="text-xl">When a Subscription</div>

          <div className="flex flex-row space-x-6 ">
            <Controller
              control={control}
              name={'isSubscription'}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  inputProps={{ value: 'type', checked: field.value, onChange: field.onChange }}
                  label="Reset status for every new Service Order"
                />
              )}
            />
            {isSubscription && (
              <Controller
                name={'initialStatus'}
                control={control}
                render={({ field }) => (
                  <Select
                    error={get(errors, 'initialStatus')}
                    label={'Reset status to'}
                    onSelect={field.onChange}
                    defaultValue={''}
                    value={field.value}
                  >
                    {[...UniversalService.ServiceOrderStatuses].map((option: string, index: number) => (
                      <SelectOption key={index} value={option}>
                        {option}
                      </SelectOption>
                    ))}
                  </Select>
                )}
              />
            )}
          </div>
          {/* SERVICE LINE ITEMS */}
          {mode === 'edit' && (
            <>
              <div className="text-xl">Service Line Items</div>
              {lineItemFields.map((item, index) => {
                return (
                  <div className="flex flex-row space-x-6 a items-center" key={item.id}>
                    <Controller
                      control={control}
                      name={`lineItemTypes.${index}.description`}
                      defaultValue={''}
                      rules={{
                        required: {
                          value: true,
                          message: 'Line Item Type description is required',
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          type={'string'}
                          label="Description"
                          required
                          error={get(errors, `lineItemTypes.${index}.description`)}
                          inputProps={{
                            ...field,
                            disabled: isDefaultLineItemType(item.description),
                          }}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`lineItemTypes.${index}.internalNotes`}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          type={'string'}
                          label="Internal Notes"
                          error={get(errors, `lineItemTypes.${index}.internalNotes`)}
                          inputProps={{
                            ...field,
                            disabled: isDefaultLineItemType(item.description),
                          }}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`lineItemTypes.${index}.minQuantity`}
                      defaultValue={0}
                      render={({ field }) => (
                        <div className="w-full">
                          <Tooltip text="Set to >=1 if multiple of these line items can be ordered in the same order. Otherwise set to 0.">
                            <TextField
                              type={'number'}
                              label="Min Quantity"
                              error={get(errors, 'lineItemTypes.${index}.minQuantity')}
                              inputProps={{
                                ...field,
                              }}
                            />
                          </Tooltip>
                        </div>
                      )}
                    />
                    <Controller
                      control={control}
                      name={`lineItemTypes.${index}.taxable`}
                      defaultValue={false}
                      render={({ field }) => (
                        <Checkbox
                          inputProps={{
                            value: 'type',
                            checked: field.value,
                            onChange: field.onChange,
                            disabled: isDefaultLineItemType(item.description),
                          }}
                          label="Taxable"
                        />
                      )}
                    />
                    {getValues(`lineItemTypes.${index}`).id === undefined ? (
                      <Button
                        className="w-[480px]"
                        onClick={() => handleRemoveLineItemType(index)}
                        endIcon={<TrashIcon className="w-5 h-5 text-red-600 cursor-pointer" />}
                      >
                        {null}
                      </Button>
                    ) : (
                      <>
                        {item.active ? (
                          <Button
                            className="btn-delete w-[400px]"
                            onClick={() => {
                              setChangeLineItemTypeStatusDialogOpen(true);
                              setChangeLineItemType(getValues(`lineItemTypes.${index}`));
                            }}
                            disabled={isLoading || isDefaultLineItemType(item.description)}
                            type="button"
                          >
                            Deactivate
                          </Button>
                        ) : (
                          <Button
                            className="btn-primary-outlined w-[400px]"
                            onClick={() => {
                              setChangeLineItemTypeStatusDialogOpen(true);
                              setChangeLineItemType(getValues(`lineItemTypes.${index}`));
                            }}
                            disabled={isLoading || isDefaultLineItemType(item.description)}
                            type="button"
                          >
                            Reactivate
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
              <div className="py-2 cursor-pointer" onClick={handleAddLineItemType}>
                <div className="flex flex-row items-center space-x-2 text-sourgum-green-400">
                  <PlusIcon className="w-5 h-5" />
                  <span> Add another Line Item Type</span>
                </div>
              </div>
            </>
          )}

          <div className="flex justify-end gap-4">
            <Button className="btn-dark-grey-outlined" onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>
            <Button className="btn-primary" type="submit" disabled={isLoading}>
              {serviceFamily ? 'Update' : 'Create'}
              {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
            </Button>
          </div>
        </div>
        <br />
        <br />
      </form>
    </FormProvider>
  );
};
