import React from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import axios from 'axios';
import DetailsCardWrapper from '../../../components/ui/DetailsCardWrapper';
import { useAuthToken } from '../../../hooks/authhook';

type CustomerInvoicesProps = {
  customer: Customer.AllianceCustomerTransport;
};

const CustomerInvoices = ({ customer }: CustomerInvoicesProps) => {
  const { token } = useAuthToken();

  const handleGetDelinquentInvoices = () => {
    axios({
      url: `${import.meta.env.VITE_BASE_API_URL}/reports/invoice/delinquent/customer/${customer.id}?output=CSV`, //your url
      method: 'GET',
      headers: {
        Authorization: token,
      },
      responseType: 'blob', // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'delinquent-invoices.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch();
  };

  return (
    <DetailsCardWrapper
      heading="Invoices"
      buttons={[
        {
          label: 'Open Invoices Report',
          onClick: () => handleGetDelinquentInvoices(),
        },
      ]}
    >
      Delinquent Report Button Still Works. Need to update component to use SAP invoices. Was using Stripe Invoices,
      which are deprecated.
    </DetailsCardWrapper>
  );
};

export default CustomerInvoices;

// BELOW IS DEPRECATED CODE - DELETE ONCE USING SAP INVOICES
// {stripeLoading ? (
//   <div className="w-full flex justify-center items-center">
//     <SourLoading />
//   </div>
// ) : (
//   <TableContainer>
//     <Table size="small">
//       <TableBody>
//         {stripeData && stripeData.invoices
//           ? stripeData.invoices.data.map((item: any) => (
//               <TableRow key={item.id}>
//                 <TableCell>{getInvoiceOrder(item)}</TableCell>
//                 <TableCell>{formatUSD(item.amount_due / 100)}</TableCell>
//                 <TableCell>{getInvoiceStatusIcon(item)}</TableCell>
//                 <TableCell>{format(new Date(item.due_date), 'MM/dd/yy')}</TableCell>
//                 <TableCell>{format(new Date(item.created), 'MM/dd/yy')}</TableCell>
//                 <TableCell>
//                   <button
//                     className="btn-primary-text-only"
//                     disabled={item.amount_paid > 0 || item.status === 'void'}
//                     onClick={() => onVoidInvoice(item)}
//                   >
//                     Void
//                   </button>
//                 </TableCell>
//               </TableRow>
//             ))
//           : null}
//       </TableBody>
//     </Table>
//   </TableContainer>
// )}
