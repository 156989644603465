const routes = {
  dashboard: '/',
  accountSettings: {
    list: '/account-settings/',
    googleAds: '/account-settings/google-ads/',
    googleAuthCallback: '/account-settings/google-ads/callback/',
    quickBooksMapping: '/account-settings/quickbooks-mapping/',
    quickBooksCallback: '/account-settings/quickbooks-mapping/callback/',
    tagManagement: '/account-settings/tag-management',
  },
  billing: {
    list: '/billing/',
    details: (id: string) => `/billing/?id=${id}/`,
    new: '/billing/new/',
  },
  billingProduct: {
    list: '/billing-product/',
    details: (serviceGroupingID: string, serviceOrderID: string) =>
      `/billing-product/?serviceGroupingID=${serviceGroupingID}&serviceOrderID=${serviceOrderID}`,
    new: '/billing-product/new/',
  },
  haulers: {
    list: '/haulers/',
    details: (id: string) => `/haulers/details/${id}/`,
    new: '/haulers/new/',
  },
  leads: {
    list: '/leads/',
    create: '/leads/new/',
    details: (id: string) => `/leads/details/${id}/`,
  },
  orders: {
    list: '/orders/',
    new: '/orders/new/',
    details: (id: string) => `/orders/details/${id}/`,
    review: (id: string) => `/orders/review/${id}/`,
  },
  pricing: {
    list: '/pricing/',
    new: '/pricing/new/',
    details: (id: string) => `/pricing/details/${id}/`,
  },
  reports: '/reports/',
  customers: {
    details: (id: string) => `/customers/details/${id}/`,
  },
  universalServices: {
    new: '/universal-services/new/',
    list: '/universal-services/',
    serviceGrouping: {
      details: (serviceGroupingId: string) => `/universal-services/service-grouping/details/${serviceGroupingId}/`,
      addOrder: (serviceGroupingId: string) => `/universal-services/service-grouping/${serviceGroupingId}/add-order/`,
      serviceOrder: {
        details: (serviceGroupingId: string, serviceOrderId: string) =>
          `/universal-services/service-grouping/${serviceGroupingId}/service-order/details/${serviceOrderId}/`,
        update: (serviceGroupingId: string, serviceOrderId: string) =>
          `/universal-services/service-grouping/${serviceGroupingId}/service-order/update/${serviceOrderId}/`,
      },
      subscription: {
        pricing: (serviceGroupingId: string, serviceOrderId: string) =>
          `/universal-services/service-grouping/${serviceGroupingId}/service-order/subscription/pricing/${serviceOrderId}/`,
        update: (serviceGroupingId: string, serviceOrderId: string) =>
          `/universal-services/service-grouping/${serviceGroupingId}/service-order/subscription/update/${serviceOrderId}/`,
        subscriptionUpdate: (serviceGroupingId: string, serviceOrderId: string) =>
          `/universal-services/service-grouping/${serviceGroupingId}/service-order/subscription/subscription-update/${serviceOrderId}/`,
        details: (serviceGroupingId: string, serviceOrderId: string) =>
          `/universal-services/service-grouping/${serviceGroupingId}/service-order/subscription/details/${serviceOrderId}/`,
      },
    },
    services: {
      list: '/universal-services/services/list/',
      new: '/universal-services/services/new/',
      details: (id: string) => `/universal-services/services/details/${id}/`,
    },
  },
  internalTickets: {
    list: '/internal-tickets/list/',
  },
  search: '/search/',
  lab: '/development-lab/',
  csaReport: '/csa-report/',
};

export default routes;
