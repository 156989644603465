import React, { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { SourFilters, SourSearch, SourSearchWrapper } from '@wayste/sour-search';
import { Loading } from '@wayste/sour-ui';
import { UIContext } from '../../contexts';

export interface SelectCustomerProps {
  onSelect: (customer: Customer.AllianceCustomerTransport | null) => void;
  formik: any;
}

const SelectCustomer = ({ onSelect, formik }: SelectCustomerProps) => {
  const client = useWaysteClient();
  const { isDesktop } = useContext(UIContext);
  const [isLoading, setIsLoading] = useState(false);
  const { showFlash } = useContext(UIContext);

  const handleSelect = async (customerID: string) => {
    setIsLoading(true);
    try {
      const customerResponse = await client.customer().adminPortal.fetch(customerID);
      onSelect(customerResponse);
    } catch (error) {
      console.log(error);
      showFlash('Error Fetching Customer', 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  // DEFINE ON NAVIGATE LOGIC
  const onNavigate = (_: any, recordID: string) => {
    handleSelect(recordID);
  };

  return (
    <div>
      <div className={`flex ${isDesktop ? 'flex-row items-center' : 'flex-col items-end'}`}>
        {formik.values.id ? (
          <div className="text-xl">
            <span className="opacity-70">Customer: </span>
            {formik.values.companyName ||
              `${formik.values.contacts[0].firstName} ${formik.values.contacts[0].lastName}`}
          </div>
        ) : isLoading ? (
          <Loading />
        ) : (
          <div>
            <SourSearchWrapper
              onNavigate={onNavigate}
              defaultFilters={{
                query: {
                  type: 'sourgum-customer',
                },
              }}
              options={{
                application: 'aap',
                apiKey: import.meta.env.VITE_ELASTIC_KEY,
                environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
              }}
            >
              <div className="flex flex-row justify-between space-x-4 w-full">
                <SourSearch
                  options={{
                    searchPopoverFixed: true,
                    showTips: false,
                    showMessages: false,
                    resultSize: 'w-[600px]',
                    placeholder: 'Search for a customer',
                  }}
                />
                <div className="hidden">
                  <SourFilters />
                </div>
              </div>
            </SourSearchWrapper>
          </div>
        )}
        <button
          className={`btn-dark-grey-outlined ${isDesktop ? 'ml-7' : 'mt-5'}`}
          onClick={() => onSelect(null)}
          type="button"
        >
          Create New Customer
        </button>
      </div>
    </div>
  );
};

export default SelectCustomer;
