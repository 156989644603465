import React, { useContext } from 'react';
import { Order } from '@alliance-disposal/transport-types';
import { AddressSubForm } from '@wayste/sour-components';
import {
  Button,
  Checkbox,
  CurrencyTextField,
  DatePicker,
  RadioGroup,
  Select,
  SelectOption,
  TextField,
  Textarea,
} from '@wayste/sour-ui';
import { formatServiceDate } from '@wayste/utils';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { UIContext } from '../../contexts';
import { OrderStatus, materials, priceTypesEnums, rollOffSizes } from '../../utils/shared-types';

export interface OrderFormProps {
  order: Order.AllianceOrderTransport;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (values: Order.AllianceOrderCreateInput | Order.AllianceOrderUpdateInput) => void;
}

const OrderForm = ({ order, isLoading, onCancel, onSubmit }: OrderFormProps) => {
  const { godModeActive } = useContext(UIContext);

  const prepareFormData = () => {
    const dataCopy = JSON.parse(JSON.stringify(order));
    return dataCopy;
  };

  const onFormSubmit = (values: Order.AllianceOrderUpdateInput) => {
    onSubmit(values);
  };

  const methods = useForm<Order.AllianceOrderUpdateInput>({
    mode: 'all',
    defaultValues: prepareFormData(),
  });

  const {
    control,
    formState: { errors, isDirty, isValid },
    setValue,
    watch,
    handleSubmit,
  } = methods;

  const watchPriceType = watch('priceType');

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="md:col-span-2">
            <AddressSubForm
              apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
              fieldName="serviceLocation"
              required
              showCounty
              type="location"
              handleSelect={(value) => {
                alert(
                  'Changing the address may effect pricing. Pricing is not updated in this form. You must change pricing in the Billing Screen',
                );
                setValue('serviceLocation', value);
              }}
            />
          </div>
          <Controller
            control={control}
            name="expectedDeliveryDate"
            rules={{
              required: {
                value: true,
                message: 'Delivery date is required',
              },
            }}
            render={({ field }) => (
              <DatePicker
                label="Delivery date"
                error={errors.expectedDeliveryDate}
                required
                value={field.value ? (formatServiceDate(field.value, 'date') as Date) : ''}
                onChange={(value) => field.onChange(value ? formatServiceDate(value, 'string') : undefined)}
                closeOnSelect
              />
            )}
          />
          <Controller
            control={control}
            name="expectedPickupDate"
            rules={{
              required: {
                value:
                  order.status === OrderStatus.DUMPED ||
                  order.status === OrderStatus.READY_FULL_PICKUP ||
                  order.status === OrderStatus.COMPLETED,
                message: 'Pickup date is required',
              },
            }}
            render={({ field }) => (
              <DatePicker
                label="Pick up date"
                error={errors.expectedPickupDate}
                required={
                  order.status === OrderStatus.DUMPED ||
                  order.status === OrderStatus.READY_FULL_PICKUP ||
                  order.status === OrderStatus.COMPLETED
                }
                value={field.value ? (formatServiceDate(field.value, 'date') as Date) : ''}
                onChange={(value) => field.onChange(value ? formatServiceDate(value, 'string') : null)}
                closeOnSelect
                clearable={
                  order.status !== OrderStatus.DUMPED &&
                  order.status !== OrderStatus.READY_FULL_PICKUP &&
                  order.status !== OrderStatus.COMPLETED
                }
              />
            )}
          />
          <Controller
            control={control}
            name="rentalEndDate"
            rules={{
              required: {
                value:
                  order.status === OrderStatus.DUMPED ||
                  order.status === OrderStatus.READY_FULL_PICKUP ||
                  order.status === OrderStatus.COMPLETED,
                message: 'Rental end date is required',
              },
            }}
            render={({ field }) => (
              <DatePicker
                label="Rental extension end date"
                error={errors.rentalEndDate}
                required={
                  order.status === OrderStatus.DUMPED ||
                  order.status === OrderStatus.READY_FULL_PICKUP ||
                  order.status === OrderStatus.COMPLETED
                }
                value={field.value ? (formatServiceDate(field.value, 'date') as Date) : ''}
                onChange={(value) => field.onChange(value ? formatServiceDate(value, 'string') : null)}
                closeOnSelect
                clearable={
                  order.status !== OrderStatus.DUMPED &&
                  order.status !== OrderStatus.READY_FULL_PICKUP &&
                  order.status !== OrderStatus.COMPLETED
                }
              />
            )}
          />
          <Controller
            control={control}
            name="adjustedRentalPeriod.value"
            rules={{
              required: {
                value: true,
                message: 'Rental period is required',
              },
            }}
            render={({ field }) => (
              <TextField
                type="number"
                label="Rental period"
                error={errors.adjustedRentalPeriod?.value || undefined}
                required
                endAdornment={' / days'}
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="rentExtensionFee"
            rules={{
              required: {
                value: true,
                message: 'Rental extension fee is required, at least 0',
              },
              min: {
                value: 0,
                message: 'Must be equal to or greater than 0',
              },
            }}
            render={({ field }) => (
              <CurrencyTextField
                label="Rental extension fee"
                error={errors.rentExtensionFee}
                required
                value={field.value || 0}
                onChange={(value) => {
                  setValue('rentExtensionFee', value || 0);
                }}
                useCents
              />
            )}
          />
          <div className="md:col-span-2">
            <Controller
              control={control}
              name="extendingRental"
              render={({ field }) => (
                <Checkbox
                  label="Confirmed Extending Rental"
                  inputProps={{
                    checked: field.value || false,
                    onChange: (e) => {
                      field.onChange(e);
                    },
                  }}
                />
              )}
            />
          </div>
          <div className="md:col-span-2">
            <Controller
              control={control}
              name="recurringOnCall"
              render={({ field }) => (
                <Checkbox
                  label="Recurring service (on call)"
                  inputProps={{
                    checked: field.value || false,
                    onChange: (e) => {
                      field.onChange(e);
                    },
                  }}
                />
              )}
            />
          </div>
          <Controller
            control={control}
            name="material"
            render={({ field }) => (
              <Select label="Material" value={field.value} onSelect={(value) => field.onChange(value)}>
                {Object.entries(materials).map((item) => (
                  <SelectOption key={`material-${item[0]}`} value={item[0]}>
                    {item[1]}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
          <Controller
            control={control}
            name="expectedSize.size"
            render={({ field }) => (
              <Select label="Box size" value={field.value} onSelect={(value) => field.onChange(value)}>
                {Object.values(rollOffSizes).map((item) => (
                  <SelectOption key={`expectedSize-${item}`} value={Number(item)}>
                    {item}
                  </SelectOption>
                ))}
              </Select>
            )}
          />
          {watchPriceType === priceTypesEnums.ton && (
            <>
              <div>
                <Controller
                  control={control}
                  name="overage"
                  rules={{
                    required: {
                      value: true,
                      message: 'Overage fee is required, at least 0',
                    },
                    min: {
                      value: 0,
                      message: 'Must be equal to or greater than 0',
                    },
                  }}
                  render={({ field }) => (
                    <CurrencyTextField
                      label="Overage fee"
                      error={errors.rentExtensionFee}
                      required
                      value={field.value || 0}
                      onChange={(value) => {
                        setValue('overage', value || 0);
                      }}
                      useCents
                    />
                  )}
                />
              </div>
              <div className="hidden md:block" />
            </>
          )}
          {watchPriceType === priceTypesEnums.ton && (
            <Controller
              control={control}
              name="weightLimit.value"
              rules={{
                required: {
                  value: true,
                  message: 'Weight is required, even if it is 0',
                },
              }}
              render={({ field }) => (
                <TextField
                  type="number"
                  label="Ton limit"
                  error={errors.weightLimit?.value || undefined}
                  required
                  endAdornment={' / days'}
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />
          )}
          {(watchPriceType === priceTypesEnums.ton && order.status === OrderStatus.COMPLETED) ||
            (godModeActive && (
              <Controller
                control={control}
                name="actualWeightDumped.value"
                rules={{
                  required: {
                    value: godModeActive ? false : true,
                    message: 'Tons dumped is required, even if it is 0',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="number"
                    label="Tons dumped"
                    error={errors.actualWeightDumped?.value || undefined}
                    required={godModeActive ? false : true}
                    endAdornment={' / days'}
                    inputProps={{
                      ...field,
                    }}
                  />
                )}
              />
            ))}
          <div className="md:col-span-2">
            <Controller
              control={control}
              name="priceType"
              rules={{
                required: {
                  value: true,
                  message: 'A price type is required',
                },
              }}
              render={({ field }) => (
                <RadioGroup
                  name="priceType"
                  required
                  defaultValue={field.value || undefined}
                  options={[
                    { value: priceTypesEnums.ton, label: 'By Ton' },
                    { value: priceTypesEnums.yard, label: 'By Yard' },
                    { value: priceTypesEnums.flat, label: 'Flat Rate' },
                  ]}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  className="flex gap-2"
                />
              )}
            />
          </div>
          <Controller
            control={control}
            name="poNumber"
            render={({ field }) => (
              <TextField
                label="PO number"
                error={errors.poNumber}
                helperText="Customer Purchase Order (PO) Number"
                inputProps={{
                  ...field,
                  value: field.value || '',
                }}
              />
            )}
          />
          <div className="hidden md:block" />
          <div className="col-span-2">
            <Controller
              control={control}
              name="sharedDeliveryNotes"
              render={({ field }) => (
                <Textarea
                  label="Delivery notes"
                  error={errors.sharedDeliveryNotes}
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />
          </div>
          <div className="col-span-2">
            <Controller
              control={control}
              name="sharedPickupNotes"
              render={({ field }) => (
                <Textarea
                  label="Pickup notes"
                  error={errors.sharedPickupNotes}
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />
          </div>
          <div className="col-span-2">
            <Controller
              control={control}
              name="sharedDeliveryNotesPrivate"
              render={({ field }) => (
                <Textarea
                  label="Delivery hauler only notes"
                  error={errors.sharedDeliveryNotesPrivate}
                  helperText="Notes only the hauler will see when we send emails"
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />
          </div>
          <div className="col-span-2">
            <Controller
              control={control}
              name="sharedPickupNotesPrivate"
              render={({ field }) => (
                <Textarea
                  label="Pickup hauler only notes"
                  error={errors.sharedPickupNotesPrivate}
                  helperText="Notes only the hauler will see when we send emails"
                  inputProps={{
                    ...field,
                  }}
                />
              )}
            />
          </div>
          {/* {godModeActive && (
            <Controller
              control={control}
              name="haulerPricingSnapshot.haul"
              rules={{
                required: {
                  value: true,
                  message: 'Hauler price is required, at least 0',
                },
                min: {
                  value: 0,
                  message: 'Must be equal to or greater than 0',
                },
              }}
              render={({ field }) => (
                <CurrencyTextField
                  label="Hauler's haul fee"
                  error={errors.haulerPricingSnapshot?.haul}
                  required
                  value={field.value || 0}
                  onChange={(value) => {
                    setValue('haulerPricingSnapshot.haul', value || 0);
                  }}
                  useCents
                />
              )}
            />
          )} */}
          <div className="md:col-span-2" style={{ marginTop: 14 }}>
            <div className="flex justify-end gap-4">
              <button className="btn-dark-grey-outlined" onClick={onCancel} type="button" disabled={isLoading}>
                Cancel
              </button>
              <Button className="btn-primary" type="submit" disabled={!isValid || isLoading} loading={isLoading}>
                Update
              </Button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default OrderForm;
