import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Field, FormikProps } from 'formik';
import { Select, TextField } from 'formik-mui';
import { channelTypes, channelTypesEnums, customerTypes } from '../../utils/shared-types';

export interface MarketingFormProps {
  formik: FormikProps<any>;
}

const MarketingForm = ({ formik }: MarketingFormProps) => {
  const handleChannelChange = (e: React.ChangeEvent<{ value: string }>) => {
    const value = e.target.value;
    const updateValue: {
      channel?: string;
      formUrl?: string;
    } = { channel: value, formUrl: undefined };
    if (value === channelTypesEnums.yelp) {
      updateValue.formUrl = '?utm_source=YelpAds&utm_campaign=yelpads';
    }
    if (value === channelTypesEnums.thumbtack) {
      updateValue.formUrl = '?utm_source=Thumbtack&utm_campaign=thumbtack';
    }
    if (value === channelTypesEnums.angiLeads) {
      updateValue.formUrl = '?utm_source=AngiLeads&utm_campaign=angileads';
    }
    if (value === channelTypesEnums.craftJack) {
      updateValue.formUrl = '?utm_source=CraftJack&utm_campaign=craftjack';
    }
    formik.setValues({
      ...formik.values,
      ...updateValue,
    });
  };

  return (
    <div className="bg-white rounded shadow-dark p-4 mb-5">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Marketing Details</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            name="channel"
            component={Select}
            label="Marketing Channel"
            labelId="channel-label"
            formControl={{ fullWidth: true, size: 'small' }}
            onChange={handleChannelChange}
          >
            {Object.entries(channelTypes).map((item, index) => (
              <MenuItem key={`channel-${item[0]}-${index}`} value={item[0]}>
                {item[1]}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            name="customerType"
            component={Select}
            label="Customer Type"
            labelId="customerType-label"
            formControl={{ fullWidth: true, size: 'small' }}
          >
            {customerTypes.map((item, index) => (
              <MenuItem key={`customerType-${item[0]}-${index}`} value={item}>
                {item}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="searchParams" label="Search Params" component={TextField} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Field name="formUrl" label="Form Url" component={TextField} fullWidth />
        </Grid>
      </Grid>
    </div>
  );
};

export default MarketingForm;
