import { useEffect, useMemo, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { Button, RadioButton, Select, SelectOption, Tooltip } from '@wayste/sour-ui';
import { getPrimaryCustomerContact, toTitleCase } from '@wayste/utils';
import { ChevronDownIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import debounce from 'lodash/debounce';
import Stripe from 'stripe';
import { twMerge } from 'tailwind-merge';
import { useConfirmationDialog } from '../../contexts';
import { useFlash } from '../../hooks/useFlash';
import { AmexIcon, DiscoverIcon, MastercardIcon, VisaIcon } from '../ui/CreditCardIcons';
import StripeInput2 from './StripeInput2';

const chargeOptions = {
  now: 'Now',
  save: 'Later - save card now',
  none: 'Later - do not enter card now',
};

export type ChargeOption = keyof typeof chargeOptions;

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_CLIENT_KEY);

interface ChargeCard2Props {
  customer: Customer.AllianceCustomerTransport;
  variant?: 'ratio' | 'select';
  /**
   * Total amount to charge in cents
   */
  total: number;
  disabled?: boolean;
  showChargeOption?: boolean;
  onChargeSuccessful?: (stripeChargeID: string, last4: string, stripeId: string) => void;
  onSaveSuccessful?: (stripeId: string) => void;
  onStripeCustomerFetched?: (value: Stripe.Customer | null) => void;
  onChargeOptionChange?: (option: ChargeOption) => void;
  className?: string;
}

const ChargeCard2 = ({
  customer,
  total,
  disabled,
  showChargeOption,
  onChargeSuccessful,
  onSaveSuccessful,
  onStripeCustomerFetched,
  onChargeOptionChange,
  className,
  variant = 'ratio',
}: ChargeCard2Props) => {
  const client = useWaysteClient();
  const { getConfirmation } = useConfirmationDialog();
  const { showFlash } = useFlash();
  const [selectedCardId, setSelectedCardId] = useState<string | undefined>(undefined);
  const [selectedSource, setSelectedSource] = useState<Stripe.Source | null>(null);
  const [showCards, setShowCards] = useState<boolean>(false);
  const [chargeOption, setChargeOption] = useState<ChargeOption>('now');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false);
  const [stripeCustomer, setStripeCustomer] = useState<Stripe.Customer | null>(null);
  const [defaultCardId, setDefaultCardId] = useState<string>('');

  const fetch = useMemo(
    () =>
      debounce(async (request, callback) => {
        const searchEmail = request.input.toLowerCase().replace(/\s+/g, '');
        handleFindingCustomer(searchEmail);
        callback();
      }, 500),
    [],
  );

  useEffect(() => {
    if (stripeCustomer && stripeCustomer.default_source && typeof stripeCustomer.default_source === 'string') {
      setDefaultCardId(stripeCustomer.default_source);
    }
  }, [stripeCustomer]);

  useEffect(() => {
    if (!customer) {
      return;
    }
    if (customer.stripeId) {
      setIsLoading(true);
      handleRetrieveStripeCustomer(customer.stripeId);
      return;
    }
    if (customer.contacts && customer.contacts.length !== 0) {
      const primaryContact = getPrimaryCustomerContact(customer) ?? customer.contacts[0];
      const stripeEmail = primaryContact?.email?.toLowerCase().trim() ?? undefined;

      if (stripeEmail) {
        fetch({ input: stripeEmail }, () => undefined);
      } else {
        handleSetStripeCustomer(null);
      }
    }
    setIsLoading(false);
  }, [customer, fetch]);

  const handleRetrieveStripeCustomer = async (stripeId: string) => {
    try {
      const customer = await client.stripe().adminPortal.customer.fetch(stripeId);
      handleSetStripeCustomer(customer);
    } catch (error) {
      console.warn('retrieveCustomer Error: ', error);
      showFlash('An error occurred while looking fetching Stripe Customer. ', 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFindingCustomer = async (email: string) => {
    try {
      const { data: stripeCustomers } = await client.stripe().adminPortal.customer.query({ email });

      console.log(stripeCustomers);
      if (stripeCustomers.length === 0) {
        handleSetStripeCustomer(null);
      } else if (stripeCustomers.length > 1) {
        showFlash(`Multiple customers with email: ${email}. Please check stripe for duplicates`, 'warning');
      } else {
        handleSetStripeCustomer(stripeCustomers[0]);
      }
    } catch (error) {
      console.warn('findCustomer Error: ', error);
      showFlash('An error occurred while looking for Stripe Customer', 'warning');
    }
    setIsLoading(false);
  };

  const handleSetStripeCustomer = (value: Stripe.Customer | null) => {
    setStripeCustomer(value);
    if (onStripeCustomerFetched) onStripeCustomerFetched(value);
  };

  const onCardSelected = async (id: string) => {
    if (stripeCustomer && id !== stripeCustomer.default_source) {
      const confirmed = await getConfirmation({
        title: 'Change Default Card?',
        message: 'Do you want to change default card to this one?',
        cancelText: 'No',
      });
      if (confirmed) {
        setIsLoading(true);
        try {
          await client.stripe().adminPortal.customer.update(stripeCustomer.id, { default_source: id });
          setDefaultCardId(id);
          showFlash('Default Card Updated', 'success');
          setIsLoading(false);
        } catch (error) {
          console.warn('updateCustomer Error: ', error);
          showFlash('An error occurred while updating default card. ', 'warning');
        }
      }
    }
  };

  const handleChargeOptionChange = (option: ChargeOption) => {
    setChargeOption(option);
    onChargeOptionChange?.(option);
  };

  const getCardIcon = (brand: string) => {
    switch (brand) {
      case 'American Express': {
        return <AmexIcon />;
      }
      case 'Visa': {
        return <VisaIcon />;
      }
      case 'MasterCard': {
        return <MastercardIcon />;
      }
      case 'Discover': {
        return <DiscoverIcon />;
      }
      default: {
        return <div className="flex text-xs">{brand}</div>;
      }
    }
  };

  return (
    <div className={twMerge('w-full', className)}>
      <h5 className="w-full text-lg border-b mb-2">Charge Card</h5>
      <div className="flex flex-col lg:flex-row items-start gap-2 w-full">
        {showChargeOption && (
          <div className="w-full lg:w-auto">
            <Select
              label={'Charge'}
              onSelect={(value) => handleChargeOptionChange(value as ChargeOption)}
              defaultValue={''}
              required
              disabled={disabled}
              value={chargeOption}
            >
              {Object.entries(chargeOptions).map((item, index) => (
                <SelectOption key={'chargeOption' + index} value={item[0]}>
                  {item[1]}
                </SelectOption>
              ))}
            </Select>
          </div>
        )}
        {chargeOption !== 'none' && variant === 'ratio' && (
          <div className="rounded-md border w-full">
            <div className="flex items-center px-2 py-1 w-full">
              <div>
                <RadioButton
                  inputProps={{
                    checked: null === selectedSource,
                    onChange: () => {
                      setSelectedSource(null);
                      setSelectedCardId(undefined);
                    },
                    disabled: disabled,
                  }}
                  options={[{ value: undefined, label: '' }]}
                />
              </div>
              <div className="text-xs">Add new card</div>
            </div>
            {stripeCustomer?.sources?.data?.map((item, index) => {
              if (item.object !== 'source' || item.type !== 'card' || !item.card || !item.owner) return null;
              const cardIsDisabled = disabled || item.card.funding === 'prepaid';

              return (
                <div
                  key={index + 'Stripe-Customer'}
                  className={`flex border-t items-center px-2 py-1 ${cardIsDisabled ? 'bg-gray-100' : ''}`}
                >
                  <RadioButton
                    inputProps={{
                      checked: item.id === selectedSource?.id,
                      onChange: () => {
                        setSelectedSource(item);
                        setSelectedCardId(item.id);
                      },
                      disabled: cardIsDisabled,
                    }}
                    options={[{ value: item.id, label: '' }]}
                  />
                  <div className={'flex flex-row w-full space-x-3 leading-none text-xs items-center'}>
                    {item.status !== 'chargeable' || item.card.funding === 'prepaid' ? (
                      <Tooltip
                        variant="light"
                        text={
                          item.card.funding === 'prepaid'
                            ? 'Prepaid cards cannot be charged through SAP. Please speak with your administrator to charge the card in Stripe directly.'
                            : 'This card is not chargeable.'
                        }
                      >
                        <ExclamationTriangleIcon className="w-5 h-5 fill-red-500" />
                      </Tooltip>
                    ) : (
                      <div className="w-5 h-5" />
                    )}
                    <span className={`${item.status !== 'chargeable' ? 'text-error' : ''}`}>{item.owner.name}</span>
                    <span>
                      {item.card.exp_month &&
                        item.card.exp_year &&
                        `Expires ${item.card.exp_month}/${item.card.exp_year % 100}`}
                    </span>
                    {/* <span>{item.card.funding === 'prepaid' ? 'Prepaid' : ''}</span> */}
                    <div>
                      {defaultCardId === item.id && (
                        <span
                          className={
                            'min-w-[4rem] inline-block rounded-full px-3 py-0.5 ml-1  text-info-dark bg-info-light border-info-light'
                          }
                        >
                          Default
                        </span>
                      )}
                    </div>
                    <div className="flex items-center justify-end gap-1 !ml-auto">
                      <span>{item.card.brand && getCardIcon(item.card.brand)}</span>
                      {item.card.funding && <div className="text-xs">{toTitleCase(item.card.funding + ' ')}</div>}
                      <span>{'•• ' + item.card.last4}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* 



              SELECT CARD OPTION      


        */}

        {chargeOption !== 'none' && variant === 'select' && (
          <div className="w-1/3">
            <Button
              onClick={() => setShowCards(!showCards)}
              disabled={disabled}
              className="disabled:bg-blue-gray-50 rounded-[7px] w-full"
            >
              <div
                className={`relative justify-between p-1.5 h-10 min-w-[250px] peer rounded-[7px] border border-blue-gray-200 bg-transparent text-left align-text-middle font-normal text-blue-gray-700 outline outline-0 ${
                  disabled ? 'border-0 bg-blue-gray-50' : ''
                }`}
              >
                {selectedCardId && selectedSource && selectedSource.card && selectedSource.owner ? (
                  <div className="flex justify-between items-center">
                    <div>
                      <div className={`mb-2 ml-1 ${selectedSource.status !== 'chargeable' && 'text-error'}`}>
                        {selectedSource.owner.name}
                      </div>
                    </div>
                    <div className="mr-6 mb-2 flex items-center">
                      {defaultCardId === selectedSource.id && (
                        <span
                          className={
                            'min-w-[4rem] inline-block rounded-full px-3 py-0.5 ml-1 mb-0.5 text-xs text-info-dark bg-info-light border-info-light'
                          }
                        >
                          Default
                        </span>
                      )}

                      {selectedSource.card.brand && getCardIcon(selectedSource.card.brand)}
                      {'•• ' + selectedSource.card.last4}
                    </div>
                  </div>
                ) : (
                  <span className="w-full px-3 py-2.5 align-middle">Select Card</span>
                )}
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </div>
            </Button>

            {showCards && (
              <div className="p-5 mt-4 mb-3 bg-white shadow-dark rounded-md overflow-auto">
                <div className="flex flex-col justify-between">
                  <div className="py-2 text-sm w-full">
                    <Button
                      onClick={() => {
                        setSelectedCardId(undefined);
                        setSelectedSource(null);
                        setShowCards(false);
                      }}
                      disabled={disabled}
                    >
                      Add New Card
                    </Button>
                  </div>
                  {stripeCustomer &&
                    stripeCustomer.sources &&
                    stripeCustomer.sources.data &&
                    chargeOption === 'now' &&
                    stripeCustomer.sources.data.map((item, index) => {
                      if (item.object !== 'source' || item.type !== 'card' || !item.card || !item.owner) return null;
                      return (
                        <Button
                          id={'card' + index}
                          onClick={() => {
                            onCardSelected(item.id);
                            setSelectedCardId(item.id);
                            setSelectedSource(item);
                            setShowCards(false);
                          }}
                          disabled={disabled || item.card.funding === 'prepaid'}
                        >
                          <div className={'flex flex-col pt-2 mt-3 border-t border-gray-300 w-full '}>
                            <div className="flex justify-between">
                              {(item.status !== 'chargeable' || item.card.funding === 'prepaid') && (
                                <ExclamationTriangleIcon className="w-5 h-5 fill-red-500" />
                              )}
                              <div className={`text-xs mb-2 ${item.status !== 'chargeable' && 'text-error'}`}>
                                {item.owner.name}
                              </div>
                              <div className="text-xs mb-2">
                                {item.card.exp_month &&
                                  item.card.exp_year &&
                                  `Expires ${item.card.exp_month}/${item.card.exp_year % 100}`}
                              </div>
                            </div>
                            <div className="flex flex-row justify-between">
                              <div className="flex items-center space-x-1">
                                <div>{item.card.brand && getCardIcon(item.card.brand)}</div>
                                <div>
                                  {defaultCardId === item.id && (
                                    <span
                                      className={
                                        'min-w-[4rem] inline-block rounded-full px-3 py-0.5 ml-1 mb-0.5 text-xs text-info-dark bg-info-light border-info-light'
                                      }
                                    >
                                      Default
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="text-xs place-self-center">
                                {(item.card.funding === 'prepaid' ? 'PP •• ' : '•• ') + item.card.last4}
                              </div>
                            </div>
                          </div>
                        </Button>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        )}

        {chargeOption !== 'none' && (
          <div className={`flex flex-column md:flex-row`}>
            <div className="align-middle">
              {customer && (
                <div className="flex flex-col text-xs">
                  <span>
                    Customer:{' '}
                    {`${getPrimaryCustomerContact(customer).firstName} ${getPrimaryCustomerContact(customer).lastName}`}
                  </span>
                  <span>Billing Zip: {customer.billingAddress.zip}</span>
                </div>
              )}
              <Elements stripe={stripePromise}>
                <StripeInput2
                  customer={customer}
                  stripeCustomer={stripeCustomer || undefined}
                  saveSuccessful={saveSuccessful}
                  chargeOption={chargeOption}
                  setIsLoading={(loading: boolean) => setIsLoading(loading)}
                  isLoading={isLoading}
                  selectedCard={selectedCardId}
                  total={total}
                  disabled={disabled}
                  onSaveSuccessful={(stripeId: string) => {
                    if (stripeId) onSaveSuccessful?.(stripeId);
                    setIsLoading(false);
                    setSaveSuccessful(true);
                    showFlash('Card Saved', 'success');
                  }}
                  onChargeSuccessful={(stripeChargeID: string, last4: string, newCustId: string) => {
                    setSaveSuccessful(true);
                    setIsLoading(false);
                    showFlash('Charge Successful', 'success');
                    onChargeSuccessful?.(stripeChargeID, last4, stripeCustomer ? stripeCustomer.id : newCustId);
                  }}
                  onChargeFailed={(hideFlash) => {
                    setIsLoading(false);
                    if (!hideFlash) showFlash('Charge was not successful', 'warning');
                  }}
                />
              </Elements>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ChargeCard2;
