import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Order } from '@alliance-disposal/transport-types';
import { sortByKey } from '@wayste/utils';
import { isAxiosError } from 'axios';
import { useFlash } from '../../../../hooks/useFlash';
import { paymentMethodsEnums } from '../../../../utils';
import TransactionCreate, { CleanTransactionPaymentsType, TransactionPaymentsType } from './TransactionCreate';

interface Props {
  open: boolean;
  onCancel: () => void;
  customer: Customer.AllianceCustomerTransport;
  onSave: () => void;
}

const TransactionCreateContainer = ({ open, onCancel, customer, onSave }: Props) => {
  const client = useWaysteClient();
  const { showFlash } = useFlash();
  const [payments, setPayments] = useState<TransactionPaymentsType[]>([]);
  const [paymentMethod, setPaymentMethod] = useState(customer.defaultPaymentSettings?.paymentMethod || 'creditCard');
  const [totalPayment, setTotalPayment] = useState(0);
  const [invoiceTotals, setInvoiceTotals] = useState({
    total: 0,
    totalDue: 0,
    totalCredit: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const prepareTransactions = async () => {
    const receivable = await client.invoice().adminPortal.receivable.query({
      customerID: customer.id,
      paidInFull: false,
      void: false,
    });

    const { results } = await client.order().adminPortal.query({
      allianceCustomerID: customer.id,
    });

    const orderMap = new Map<string, Order.AllianceOrderTransport>();

    results.forEach((order) => {
      orderMap.set(order.id, order);
    });

    const possiblePayments: TransactionPaymentsType[] = receivable
      .filter((receivable) => {
        return receivable.invoiceDetails.remainingBalance > 0;
      })
      .map((receivable) => {
        return {
          orderId: receivable.invoiceDetails.productInvoiceGroupingID || '',
          orderNumber:
            receivable.invoiceDetails.orderNumber ||
            ((receivable.invoiceDetails.orderID &&
              orderMap.get(receivable.invoiceDetails.orderID)?.orderNumber?.toString()) ??
              ''),
          invoice: receivable.invoiceDetails.invoiceNumber || '',
          invoiceID: receivable.invoiceDetails.id,
          total: receivable.invoiceDetails.total,
          paid: receivable.invoiceDetails.total - receivable.invoiceDetails.remainingBalance,
          credit: 0,
          value: '',
        };
      });

    handleSetPayments(possiblePayments);
    setIsLoading(false);
  };

  const handleGetOrders = async () => {
    setIsLoading(true);
    prepareTransactions();
  };

  useEffect(() => {
    handleGetOrders();
  }, [customer]);

  const handleSetPayments = (newPayments: TransactionPaymentsType[]) => {
    let total = 0;
    newPayments.forEach((payment) => {
      total = total + +payment.value;
    });

    setTotalPayment(total);
    getTotalInvoices();
    setPayments(sortByKey('orderNumber', newPayments as any[]));
  };

  const getTotalInvoices = () => {
    let total = 0;
    let totalDue = 0;
    let totalCredit = 0;
    payments.forEach((payment) => {
      total = total + payment.total;
      totalDue = totalDue + (payment.total - payment.paid);
      totalCredit = 0;
    });
    setInvoiceTotals({
      total: total,
      totalDue: totalDue,
      totalCredit: totalCredit,
    });
  };

  const handleUpdatePayment = (value: any, index: number) => {
    const newPayments: TransactionPaymentsType[] = [...payments];
    newPayments[index] = {
      ...newPayments[index],
      value: value,
    };
    handleSetPayments(newPayments);
  };

  const handleSave = async (cleanedPayments: CleanTransactionPaymentsType[], stripeId?: string) => {
    setIsLoading(true);
    try {
      if (customer.id && !customer.stripeId && stripeId) {
        await client.customer().adminPortal.update(customer.id, {
          stripeId: stripeId,
        });
      }

      console.log('cleanedPayments', cleanedPayments);

      const paymentPromises = cleanedPayments.map(async (paymentObject) => {
        await client.invoice().adminPortal.payment.create(paymentObject.invoiceID, {
          stripeChargeID: paymentObject.item.stripeChargeID || undefined,
          paymentMethod: paymentObject.item.paymentMethod,
          amount: paymentObject.item.amount,
          paymentIdentifier: paymentObject.item.paymentIdentifier || undefined,
          paymentReceivedDate: paymentObject.item.paymentReceivedDate?.toISOString() || undefined,
        });

        // If account credit draw down the customer credit balance
        if (paymentObject.item.paymentMethod === paymentMethodsEnums.accountCredit) {
          try {
            await client.customer().adminPortal.update(customer.id, {
              credit: Math.floor(customer.credit - +paymentObject.item.amount),
              stripeId: customer.stripeId || stripeId,
            });
          } catch (error) {
            console.error({
              id: customer.id,
              credit: Math.floor(customer.credit - +paymentObject.item.amount),
              error,
            });
            throw new Error(
              'Updating customer account credit failed. Touch Nothing. Get an AAP Dev and submit an IT ticket with customer number and the credit used amounts.',
            );
          }
        }
      });

      await Promise.all(paymentPromises);

      showFlash('Invoices Successfully Updated', 'success');
      onSave();
    } catch (error) {
      let errorMessage = 'There was an error updating the invoices.';
      if ((error as Error).message) {
        errorMessage = (error as Error).message;
      }

      if (isAxiosError(error)) {
        errorMessage =
          error.response?.data.additionalInfo ||
          error.response?.data.message ||
          'There was an error updating the invoices.';
      }

      showFlash(errorMessage, 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <TransactionCreate
      onUpdatePayment={handleUpdatePayment}
      open={open}
      onCancel={onCancel}
      customer={customer}
      invoiceTotals={invoiceTotals}
      paymentMethod={paymentMethod}
      onSetPaymentMethod={setPaymentMethod}
      payments={payments}
      totalPaymentCents={totalPayment}
      onSave={handleSave}
      isLoading={isLoading}
    />
  );
};

export default TransactionCreateContainer;
