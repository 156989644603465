import { ReactNode, useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Invoice, Order } from '@alliance-disposal/transport-types';
import { Tooltip } from '@wayste/sour-ui';
import { formatServiceAddress, moneyFormatter, round } from '@wayste/utils';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { ArrowPathIcon, EnvelopeIcon, PencilIcon, PhotoIcon, TrashIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import ButtonSelect from '../../../../components/ButtonSelect';
import { UIContext } from '../../../../contexts';
import { useFlash } from '../../../../hooks/useFlash';
import { ccRate } from '../../../../utils/pricing-utils';
import { paymentMethods, paymentMethodsEnums, paymentTerms } from '../../../../utils/shared-types';
import { statesDetails } from '../../../../utils/state-details';
import { PaymentUpdateModal } from '../PaymentUpdateModal';
import { GridCellStatus } from '../renderCellReceivablesStatus';

const tableHeadings = [
  {
    heading: 'Item',
  },
  {
    heading: 'Description',
    className: 'w-full',
  },
  {
    heading: 'Qty',
    className: 'max-w-[62px]',
  },
  {
    heading: 'Rate',
  },
  {
    heading: 'Amount',
  },
  {
    heading: 'Tax',
    className: 'text-center max-w-[45px]',
  },
];

const tableData = [
  {
    key: 'item',
    value: (item: Invoice.LineItemTransport) => item.itemName,
    className: 'border-l-0 whitespace-nowrap',
  },
  {
    key: 'description',
    value: (item: Invoice.LineItemTransport) => <div> {item.description}</div>,
    className: 'w-full',
  },
  {
    key: 'quantity',
    value: (item: Invoice.LineItemTransport) => item.quantity,
    className: 'text-right max-w-[62px]',
  },
  {
    key: 'rate',
    value: (item: Invoice.LineItemTransport) => (
      <div className="flex justify-between">
        <div>$</div>
        {moneyFormatter(round(item.unitPrice || 0, item.quantity > 1 ? 5 : 2), {
          hideDollarSign: true,
        })}
      </div>
    ),
    className: 'pl-1.5 pr-2.5 min-w-[117px] max-w-[117px] text-right',
  },
  {
    key: 'amount',
    value: (item: Invoice.LineItemTransport) => (
      <div className="flex justify-between">
        <div>$</div>
        {moneyFormatter(item.totalPrice || 0, {
          hideDollarSign: true,
        })}
      </div>
    ),
    className: 'pl-1.5 pr-2.5 min-w-[117px] max-w-[117px] text-right',
  },
  {
    key: 'tax',
    value: (item: Invoice.LineItemTransport) => (item.taxable ? 'Y' : null),
    className: 'text-center max-w-[45px]',
  },
];

interface Props {
  order: Order.AllianceOrderTransport;
  receivable: Invoice.ReceivableTransport;
  customer: Customer.AllianceCustomerTransport;
  ordersMatch: boolean;
  onCreateReceipt: () => void;
  onDeleteInvoice: () => void;
  onChangeTax: (value: number) => void;
  onChangeCC: (value: number) => void;
  onOrderImageClicked: (value: boolean) => void;
  onEditInvoiceClicked: (value: boolean) => void;
  onLineItemClicked: (value: { item: Invoice.LineItemTransport; index: number } | null) => void;
  onRefundClick: () => void;
}

const ReceivableCard = ({
  order,
  receivable,
  customer,
  ordersMatch,
  onCreateReceipt,
  onDeleteInvoice,
  onChangeTax,
  onChangeCC,
  onOrderImageClicked,
  onEditInvoiceClicked,
  onLineItemClicked,
  onRefundClick,
}: Props) => {
  const [invoiceStatus, setInvoiceStatus] = useState<Invoice.InvoiceStatus | null>(null);
  const [invoiceCCRate, setInvoiceCCRate] = useState(ccRate);
  const [ccLineItem, setCCLineItem] = useState<Invoice.LineItemTransport | null>(null);
  const [selectedPayment, setSelectedPayment] = useState<Invoice.PaymentTransport | undefined>(undefined);
  const client = useWaysteClient();
  const { godModeActive } = useContext(UIContext);
  const { showFlash } = useFlash();

  useEffect(() => {
    setInvoiceStatus(receivable.invoiceDetails.status);
    const found = receivable.invoiceDetails.lineItems.find((item) => item.itemName === 'CC Fee');
    console.log('found', found);
    setCCLineItem(found || null);
    setInvoiceCCRate(found ? ccRate : 0);
  }, [receivable, order]);

  const getPaymentStatus = () => {
    if (!invoiceStatus) return null;

    const statusDisplay: Record<Invoice.InvoiceStatus, ReactNode> = {
      DRAFT: <span className="text-sourgum-greyblue-400">Draft</span>,
      PAID: <span className="text-success">Paid</span>,
      PENDING: `Invoiced on ${format(new Date(receivable?.invoiceDetails.issueDate || new Date()), 'MM/dd/yy')}`,
      PAST_DUE: (
        <span className="text-red-500">
          Past Due - Invoiced on {format(new Date(receivable?.invoiceDetails.issueDate || new Date()), 'MM/dd/yy')}
        </span>
      ),
      VOID: <span className="text-red-500">Void</span>,
      PARTIALLY_PAID: <span className="text-warning">Partially Paid</span>,
      PARTIALLY_REFUNDED: <span className="text-warning">Partially Refunded</span>,
      PARTIALLY_VOID: <span className="text-warning">Partially Voided</span>,
      REFUNDED: <span className="text-warning">Refunded</span>,
    };

    return statusDisplay[invoiceStatus];
  };

  const paymentLineText = (payment: Invoice.PaymentTransport) => {
    let text = '';
    text += payment.paymentReceivedDate
      ? format(new Date(payment.paymentReceivedDate), 'MM/dd/yy')
      : format(new Date(payment.date), 'MM/dd/yy');
    switch (payment.paymentMethod) {
      case paymentMethodsEnums.creditCard:
        text += ' CC';
        break;
      case paymentMethodsEnums.stripe:
        text += ' CC';
        break;
      case paymentMethodsEnums.check:
        text += ' CHK';
        break;
      case paymentMethodsEnums.accountCredit:
        text += ' ACC CREDIT';
        break;
      case paymentMethodsEnums.ach:
        text += ' ACH';
        break;
      default:
        text += ' Unknown (Please contact a manager)';
        console.error('Error, unknown payment type.', payment);
        break;
    }
    if (payment.paymentIdentifier) {
      text += ' - ' + payment.paymentIdentifier;
    }
    return text;
  };

  const handleCCChange = (value: number) => {
    if (value === invoiceCCRate) return;
    onChangeCC(value);
  };

  // HANDLE INVOICE/RECEIPT PDF DOWNLOAD
  const handleDownload = () => {
    (async () => {
      if (receivable) {
        console.log('handleDownload');
        const response = await client.invoice().adminPortal.receivable.pdf.fetch(receivable.id);
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `receivable-${order?.orderNumber}-${receivable?.invoiceDetails.invoiceNumber}.pdf`;
        a.click();
      }
    })();
  };

  const actionIcons = [
    {
      icon: ArrowDownTrayIcon,
      click: () => handleDownload(),
      className: 'text-black opacity-60',
      tip: 'Download receivable/receipt',
    },
    // {
    //   icon: ArrowUpTrayIcon,
    //   click: () => onUploadQB(receivable),
    //   className: isQbSynced(receivable) ? 'text-success' : 'text-black opacity-60',
    //   tip: 'Enter Into QuickBooks',
    //   disabled: !ordersMatch || receivable.void,
    // },
    {
      icon: EnvelopeIcon,
      click: onCreateReceipt,
      tip: 'Email receivable/receipt',
    },
    {
      icon: PhotoIcon,
      click: () => onOrderImageClicked(true),
      tip: 'View images',
    },
    {
      icon: PencilIcon,
      click: () => onEditInvoiceClicked(true),
      className: 'text-edit',
      tip: 'Edit receivable',
    },
    {
      icon: TrashIcon,
      click: onDeleteInvoice,
      className: 'text-delete',
      disabled:
        receivable.invoiceDetails.total - receivable.invoiceDetails.remainingBalance > 0 ||
        receivable.invoiceDetails.void,
      tip: 'Delete receivable',
    },
  ];

  return (
    <div className="bg-white rounded shadow-dark mb-4 my-4 relative">
      <PaymentUpdateModal
        open={!!selectedPayment}
        onCancel={() => setSelectedPayment(undefined)}
        payment={selectedPayment}
        invoice={receivable.invoiceDetails}
        screen="receivable"
      />
      {receivable.invoiceDetails.void && (
        <div className="absolute text-red-500 text-5xl top-0 bottom-0 right-0 left-0 flex justify-center items-center bg-red-50 bg-opacity-30">
          VOIDED
        </div>
      )}
      <div className="pt-2.5 pb-4 px-5">
        <div className="flex items-center justify-between border-b pb-2">
          <div className="text-lg mr-1.5">
            <span className="opacity-50">ORDER</span>
            <span className="mx-1.5">
              #{receivable.invoiceDetails.orderNumber || receivable.invoiceDetails.orderNumber} -{' '}
              {receivable.invoiceDetails.invoiceNumber}
            </span>
          </div>
          <div>{formatServiceAddress(order?.serviceLocation?.address)}</div>
          <div className="flex gap-1">
            {actionIcons.map((icon) => (
              <Tooltip text={icon.tip} key={icon.tip} floatProps={{ placement: 'left-end' }}>
                <span>
                  <button
                    className="btn-icon"
                    type="button"
                    onClick={icon.click}
                    disabled={icon?.disabled || receivable.invoiceDetails.void}
                  >
                    <icon.icon
                      className={`h-6 w-6 ${icon.className} ${
                        icon?.disabled || receivable.invoiceDetails.void ? '!text-slate-300' : ''
                      }`}
                    />
                  </button>
                </span>
              </Tooltip>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full overflow-x-auto">
        <table className="w-auto border-collapse border-spacing-0 text-sm">
          <thead>
            <tr className="[&>*]:py-1.5 [&>*]:px-4 [&>*]:font-normal border-b">
              {tableHeadings.map((heading) => (
                <th key={heading.heading} className={`opacity-50 text-left ${heading.className || ''}`}>
                  {heading.heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {receivable.invoiceDetails.lineItems.map((item, index) => {
              if (item.itemName === 'CC Fee') return null;
              return (
                <tr
                  key={item.itemName + index}
                  className={`[&>*]:py-1.5 [&>*]:px-4 border-b even:bg-gray-100 ${
                    receivable.invoiceDetails.total - receivable.invoiceDetails.remainingBalance > 0
                      ? 'cursor-default'
                      : 'cursor-pointer'
                  }`}
                  onClick={() => {
                    if (receivable.invoiceDetails.status !== 'DRAFT' && !godModeActive) return;
                    onLineItemClicked({ item: item, index: index });
                  }}
                >
                  {tableData.map((cell) => (
                    <td className={`border-l ${cell.className || ''}`} key={cell.key}>
                      {cell.value(item)}
                    </td>
                  ))}
                </tr>
              );
            })}

            {/* Add Charge && Sales Tax */}
            <tr className="[&>*]:px-4">
              <td>
                <button
                  className="btn-primary-text-only whitespace-nowrap"
                  onClick={() => {
                    onLineItemClicked(null);
                  }}
                  disabled={
                    (receivable.invoiceDetails.status !== 'DRAFT' || receivable.invoiceDetails.void) && !godModeActive
                  }
                >
                  Add Charge
                </button>
              </td>

              <td colSpan={2} className="text-right py-1.5">
                <ButtonSelect
                  value={receivable.invoiceDetails.taxRate}
                  onSelect={onChangeTax}
                  menuItems={[
                    {
                      label: `${order?.serviceLocation?.address?.state} Sales Tax`,
                      value: order?.taxRate || 0,
                    },
                    { label: 'None', value: 0 },
                  ]}
                  btnClassName="text-xs w-[115px] justify-between pl-[5px] pr-0.5"
                  disabled={
                    (receivable.invoiceDetails.status !== 'DRAFT' || receivable.invoiceDetails.void) && !godModeActive
                  }
                />
              </td>

              <td className="text-right">{receivable.invoiceDetails.taxRate * 100}%</td>

              <td className="text-right pl-1.5 pr-2.5 min-w-[117px] max-w-[117px]">
                {receivable.invoiceDetails.taxAmount > 0 ? (
                  <div className="flex justify-between">
                    <div>$</div>
                    {moneyFormatter(receivable.invoiceDetails.taxAmount, {
                      hideDollarSign: true,
                    })}
                  </div>
                ) : statesDetails[order?.serviceLocation?.address.state as keyof typeof statesDetails].salesTaxRate ===
                  0 ? (
                  `$0 in ${order?.serviceLocation?.address.state}`
                ) : customer.taxExempt ? (
                  'EXEMPT'
                ) : (
                  <div className="flex justify-between">
                    <div>$</div>
                    <div>0</div>
                  </div>
                )}
              </td>
            </tr>

            {/* CC Fee */}
            <tr className="[&>*]:px-4">
              <td colSpan={1} className="text-left">
                Memo: {receivable.invoiceDetails.memo}
              </td>
              <td colSpan={2} className="text-right">
                <ButtonSelect
                  value={invoiceCCRate}
                  onSelect={handleCCChange}
                  menuItems={[
                    { label: 'CC Fee', value: ccRate },
                    { label: 'None', value: 0 },
                  ]}
                  btnClassName="text-xs w-[115px] justify-between pl-[5px] pr-0.5"
                  disabled={
                    (receivable.invoiceDetails.status !== 'DRAFT' || receivable.invoiceDetails.void) && !godModeActive
                  }
                />
              </td>
              <td className="text-right">{invoiceCCRate * 100}%</td>
              <td className="text-right pl-1.5 pr-2.5 min-w-[117px] max-w-[117px]">
                <div className="flex justify-between">
                  <div>$</div>
                  {ccLineItem
                    ? moneyFormatter(ccLineItem.totalPrice || 0, {
                        hideDollarSign: true,
                      })
                    : 0}
                </div>
              </td>
            </tr>

            {/* Invoice Total */}
            <tr className="[&>*]:px-4">
              <td colSpan={2} className="text-left">
                Internal Notes: {receivable.invoiceDetails.internalNotes}
              </td>
              <td colSpan={2} className="text-right">
                Total
              </td>
              <td className="text-right pl-1.5 pr-2.5 min-w-[117px] max-w-[117px]">
                <div className="flex justify-between">
                  <div>$</div>
                  {moneyFormatter(receivable.invoiceDetails.total, {
                    hideDollarSign: true,
                  })}
                </div>
              </td>
            </tr>

            {/* Amount Paid && Terms */}
            <tr className="[&>*]:px-4">
              <td colSpan={2}>
                <span className="opacity-50">Terms:</span>{' '}
                {paymentTerms[order.paymentTerm as keyof typeof paymentTerms]} -{' '}
                {paymentMethods[order.paymentMethod as keyof typeof paymentMethods]}
              </td>
              <td className="text-right" colSpan={2}>
                {receivable.invoiceDetails.payments.map((payment) => (
                  <span
                    key={payment.id}
                    className="whitespace-nowrap"
                    onDoubleClick={() => {
                      if (!godModeActive) {
                        showFlash('You must be in god mode to edit payments.', 'warning');
                        return;
                      }
                      setSelectedPayment(payment);
                    }}
                  >
                    {paymentLineText(payment)} <br />
                  </span>
                ))}
                <span>Total Paid</span>
              </td>
              <td className="text-right pl-1.5 pr-2.5 min-w-[117px] max-w-[117px]">
                {receivable.invoiceDetails.payments.map((payment, index) => (
                  <div className="flex justify-between" key={index}>
                    <div>$</div>
                    {moneyFormatter(payment.amount, {
                      hideDollarSign: true,
                    })}
                  </div>
                ))}
                <div className="flex justify-between">
                  <div>$</div>
                  {moneyFormatter(receivable.invoiceDetails.total - receivable.invoiceDetails.remainingBalance, {
                    hideDollarSign: true,
                  })}
                </div>
              </td>
              <td>
                {receivable.invoiceDetails.total - receivable.invoiceDetails.remainingBalance > 0 ? (
                  <Tooltip text={'Refund payment'}>
                    <button
                      type="button"
                      className="btn-icon"
                      onClick={onRefundClick}
                      disabled={!ordersMatch || receivable.invoiceDetails.void}
                    >
                      <ArrowPathIcon className="h-5 w-5" />
                    </button>
                  </Tooltip>
                ) : null}
              </td>
            </tr>

            {/* Amount Due */}
            <tr className="[&>*]:py-0 [&>*]:px-4">
              <td colSpan={3}>
                <div className="flex gap-2 mb-1">
                  {getPaymentStatus()}
                  <GridCellStatus value={receivable.invoiceDetails.status} />
                </div>
              </td>
              <td className="text-right">Due</td>
              <td className="text-right pl-1.5 pr-2.5 min-w-[117px] max-w-[117px]">
                <div className="flex justify-between">
                  <div>$</div>
                  {moneyFormatter(receivable.invoiceDetails.remainingBalance, {
                    hideDollarSign: true,
                  })}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReceivableCard;
