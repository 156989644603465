import React, { useEffect, useState } from 'react';
import { AddressTransport, Customer, Hauler, Invoice, Order } from '@alliance-disposal/transport-types';
import { SourFilters, SourSearch, SourSearchResponse, SourSearchWrapper } from '@wayste/sour-search';
import { formatE164ToUSPhoneNumber, formatISODateString, moneyFormatter } from '@wayste/utils';
import { formatServiceAddress } from '@wayste/utils';
import { getRouterPath } from '@wayste/utils';
import { useHistory } from 'react-router-dom';
import { GridCellStatus } from '../../pages/billing/components/renderCellReceivablesStatus';
import { materials, routes } from '../../utils';
import OrderStatusChanger from '../OrderStatusChanger';

const GlobalSearch = () => {
  const history = useHistory();
  const [searchResults, setSearchResults] = useState<SourSearchResponse['results']['hits']['hits'][0]['_source'][]>([]);

  // DEFINE ON NAVIGATE LOGIC
  const onNavigate = (entity: any, recordID: string, name: string) => {
    const path = getRouterPath(name, recordID, routes);
    history.push(path);
  };

  // DEFINE ON RESULTS LOGIC
  const onResults = (response: SourSearchResponse) => {
    setSearchResults(response?.results?.hits?.hits || []);
  };

  return (
    <div className="pt-5 max-w-7xl px-6 w-full mx-auto">
      <div className="bg-white rounded shadow-dark p-4 mb-6">
        <div className="p-10 w-full">
          <p className="mb-4">Use this search box for ALL searches.</p>
          <div className="w-full flex flex-row justify-between item-center">
            <SourSearchWrapper
              options={{
                application: 'aap',
                apiKey: import.meta.env.VITE_ELASTIC_KEY,
                environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
              }}
              onNavigate={onNavigate}
              onResults={onResults}
              createQueryParams={{ method: 'all' }}
              highlight={true}
            >
              <div className="flex flex-row justify-between space-x-4 w-full">
                <SourSearch
                  options={{
                    searchPopoverFixed: false,
                    showTips: true,
                    showMessages: true,
                    placeholder: 'search for anything',
                    showResults: false,
                  }}
                />
                <SourFilters />
              </div>
            </SourSearchWrapper>
          </div>
        </div>
      </div>
      <NewSearchList results={searchResults} />
    </div>
  );
};

export default GlobalSearch;

const NewSearchList = ({ results }: { results: SourSearchResponse['results']['hits']['hits'][0]['_source'][] }) => {
  const history = useHistory();
  const [orderResults, setOrderResults] = useState<Order.OrderTransport[]>([]);
  const [customerResults, setCustomerResults] = useState<Customer.AllianceCustomerTransport[]>([]);
  const [haulerResults, setHaulerResults] = useState<Hauler.HaulerWithAapTransport[]>([]);
  const [leadResults, setLeadResults] = useState<Customer.AllianceLeadTransport[]>([]);
  const [receivableResults, setReceivableResults] = useState<Invoice.ReceivableTransport[]>([]);
  const [payableResults, setPayableResults] = useState<Invoice.PayableTransport[]>([]);

  useEffect(() => {
    /* The above code is filtering the results from the Algolia call into three different arrays baed on type. */
    if (results) {
      const orders: SourSearchResponse['results']['hits']['hits'][0]['_source'][] = results.filter(
        (item: any) => item._index === 'sourgum-order-production' || item._index === 'sourgum-order-staging',
      );
      const customers: SourSearchResponse['results']['hits']['hits'][0]['_source'][] = results.filter(
        (item: any) => item._index === 'sourgum-customer-production' || item._index === 'sourgum-customer-staging',
      );
      const haulers: SourSearchResponse['results']['hits']['hits'][0]['_source'][] = results.filter(
        (item: any) => item._index === 'sourgum-vendor-production' || item._index === 'sourgum-vendor-staging',
      );
      const leads: SourSearchResponse['results']['hits']['hits'][0]['_source'][] = results.filter(
        (item: any) => item._index === 'sourgum-lead-production' || item._index === 'sourgum-lead-staging',
      );
      const receivables: SourSearchResponse['results']['hits']['hits'][0]['_source'][] = results.filter(
        (item: any) => item._index === 'sourgum-receivable-production' || item._index === 'sourgum-receivable-staging',
      );
      const payables: SourSearchResponse['results']['hits']['hits'][0]['_source'][] = results.filter(
        (item: any) => item._index === 'sourgum-payable-production' || item._index === 'sourgum-payable-staging',
      );

      setOrderResults(orders.map((item: any) => item?._source));
      setCustomerResults(customers.map((item: any) => item._source));
      setHaulerResults(haulers.map((item: any) => item._source));
      setLeadResults(leads.map((item: any) => item._source));
      setReceivableResults(receivables.map((item: any) => item._source));
      setPayableResults(payables.map((item: any) => item._source));
    }
  }, [results]);

  const handleHaulerRowClick = (haulerId: string) => {
    history.push(routes.haulers.details(haulerId));
  };

  const handleCustomerRowClick = (id: string) => {
    history.push(routes.customers.details(id));
  };

  const handleOrderRowClick = (id: string) => {
    history.push(routes.orders.details(id), { modal: true });
  };

  const handleLeadRowClick = (id: string) => {
    history.push(routes.leads.details(id), { modal: true });
  };

  const handleReceivableRowClick = (id: string) => {
    history.push(routes.billing.details(id));
  };

  const findCorrectContactHit = (hit: any, contacts: any) => {
    if (!hit || !hit._highlightResult || !hit._highlightResult.contacts) return '';
    if (hit._highlightResult.contacts.length <= 0) {
      return '';
    }
    if (contacts.length === 0) return null;
    if (contacts.length === 1) return contacts[0];
    let name = null;

    hit._highlightResult.contacts.forEach((contact: any) => {
      if (contact?.firstName?.matchLevel === 'full' || contact?.lastName?.matchLevel === 'full') {
        //search contacts array for matching id
        contacts.forEach((c: any) => {
          if (contact && contact.id && contact.id.value === c.id) {
            name = c;
          }
        });
      }
    });

    if (name === null) {
      name = contacts.find((contact: any) => contact.primaryContact);
    }
    return name;
  };

  if (!results && orderResults.length === 0) return <div />;

  if (results?.length === 0 && orderResults.length === 0) {
    return (
      <div className="bg-white rounded shadow-dark p-4">
        <p>No results found.</p>
      </div>
    );
  }

  ////////////////////////////////////////////
  // ORDER TABLE
  ////////////////////////////////////////////

  const OrderTableFull = ({ orders }: { orders: SourSearchResponse['results']['hits']['hits'][0]['_source'][] }) => (
    <div className="w-full overflow-x-auto mb-6">
      <table className="w-full border-collapse border-spacing-0 text-sm">
        <thead>
          <tr className="[&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal border-b text-left whitespace-nowrap">
            {[
              'Order #',
              'Address',
              'Status',
              'County',
              'Material',
              'Hauler',
              'DEL Date',
              'PU Date',
              'Name',
              'Company',
            ].map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {orders.map((item: any, index: number) => (
            <tr
              key={item.id + index}
              onClick={() => handleOrderRowClick(item.id)}
              className="[&>*]:px-4 [&>*]:py-1.5 odd:bg-gray-100 border-b cursor-pointer"
            >
              <td>{item.orderNumber}</td>
              <td>{formatServiceAddress(item?.serviceLocation?.address)}</td>
              <td>
                <OrderStatusChanger order={item} navigateOnly={true} />
              </td>
              <td>{item.serviceLocation.county}</td>
              <td>{materials[item.material as MaterialEnums]}</td>
              <td>{item.vendorName || 'No Hauler Assiged'}</td>
              <td>{item.expectedDeliveryDate ? formatISODateString(item.expectedDeliveryDate, 'EEE MM/dd/yy') : ''}</td>
              <td>{item.expectedPickupDate ? formatISODateString(item.expectedPickupDate, 'EEE MM/dd/yy') : 'None'}</td>
              <td>{item?.customerName}</td>
              <td>{item?.customerCompanyName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  ////////////////////////////////////////////
  // CUSTOMER TABLE
  ////////////////////////////////////////////

  const CustomerTable = ({ customers }: { customers: Customer.AllianceCustomerTransport[] }) => (
    <div className="w-full overflow-x-auto mb-6">
      <table className="w-full border-collapse border-spacing-0 text-sm">
        <thead>
          <tr className="[&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal border-b text-left">
            {['Customer Name', 'Company', 'Email', 'Phone', 'Account #'].map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {customers.map((item: Customer.AllianceCustomerTransport, index: number) => {
            const matchedContact = findCorrectContactHit(item, item.contacts) || item.contacts[0];
            return (
              <tr
                key={item.id + index}
                className="[&>*]:px-4 [&>*]:py-1.5 odd:bg-gray-100 border-b cursor-pointer"
                onClick={() => handleCustomerRowClick(item.id)}
              >
                <td>{matchedContact?.firstName + ' ' + matchedContact?.lastName}</td>
                <td>{item.companyName}</td>
                <td>{matchedContact?.email}</td>
                <td>{formatE164ToUSPhoneNumber(matchedContact?.phoneNumber)}</td>
                <td>{item?.customerNumber}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  ////////////////////////////////////////////
  // RECEIVABLE TABLE
  ////////////////////////////////////////////

  const ReceivableTable = ({ receivables }: { receivables: Invoice.ReceivableTransport[] }) => (
    <div className="w-full overflow-x-auto mb-6">
      <table className="w-full border-collapse border-spacing-0 text-sm">
        <thead>
          <tr className="[&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal border-b text-left">
            {['Invoice #', 'Amount', 'Status', 'Customer Name', 'Company'].map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {receivables.map((item: Invoice.ReceivableTransport, index: number) => {
            return (
              <tr
                key={item.id + index}
                className="[&>*]:px-4 [&>*]:py-1.5 odd:bg-gray-100 border-b cursor-pointer"
                onClick={() => handleReceivableRowClick(item?.invoiceDetails?.orderID || '')}
              >
                <td>
                  {item.invoiceDetails.orderNumber}-{item.invoiceDetails.invoiceNumber}
                </td>
                <td>{moneyFormatter(item.invoiceDetails.total)}</td>
                <td>
                  <GridCellStatus value={item.invoiceDetails.status} />
                </td>
                <td>{item?.customerName}</td>
                <td>{item?.customerCompanyName}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  ////////////////////////////////////////////
  // PAYABLES TABLE
  ////////////////////////////////////////////

  const PayablesTable = ({ payables }: { payables: Invoice.PayableTransport[] }) => (
    <div className="w-full overflow-x-auto mb-6">
      <table className="w-full border-collapse border-spacing-0 text-sm">
        <thead>
          <tr className="[&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal border-b text-left">
            {['Invoice #', 'Amount', 'Status', 'Vendor Name'].map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {payables.map((item: Invoice.PayableTransport, index: number) => {
            return (
              <tr
                key={item.id + index}
                className="[&>*]:px-4 [&>*]:py-1.5 odd:bg-gray-100 border-b cursor-pointer"
                onClick={() => handleReceivableRowClick(item?.invoiceDetails?.orderID || '')}
              >
                <td>
                  {item.invoiceDetails.orderNumber}-{item.invoiceDetails.invoiceNumber}
                </td>
                <td>{moneyFormatter(item.invoiceDetails.total)}</td>
                <td>
                  <GridCellStatus value={item.invoiceDetails.status} />
                </td>
                <td>{item.vendorName}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  ////////////////////////////////////////////
  // HAULER TABLE
  ////////////////////////////////////////////
  const HaulerTable = ({ haulers }: { haulers: Hauler.HaulerWithAapTransport[] }) => (
    <div className="w-full overflow-x-auto mb-6">
      <table className="w-full border-collapse border-spacing-0 text-sm">
        <thead>
          <tr className="[&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal border-b text-left">
            {['Hauler Name', 'Contact Name', 'Contact Email', 'Contact Phone'].map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {haulers.map((item: Hauler.HaulerWithAapTransport, index: number) => {
            const matchedContact = findCorrectContactHit(item, item.contacts) || item.contacts[0];
            return (
              <tr
                key={item.id + index}
                className="[&>*]:px-4 [&>*]:py-1.5 odd:bg-gray-100 border-b cursor-pointer"
                onClick={() => handleHaulerRowClick(item.id)}
              >
                <td>{item?.name}</td>
                <td>{matchedContact?.firstName + ' ' + matchedContact?.lastName}</td>
                <td>{matchedContact?.email}</td>
                <td>{formatE164ToUSPhoneNumber(matchedContact?.phoneNumber)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  ////////////////////////////////////////////
  // LEAD TABLE
  ////////////////////////////////////////////

  const LeadsTable = ({ leads }: { leads: Customer.AllianceLeadTransport[] }) => (
    <div className="w-full overflow-x-auto mb-6">
      <table className="w-full border-collapse border-spacing-0 text-sm">
        <thead>
          <tr className="[&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal border-b text-left">
            {['Lead Name', 'Company', 'Email', 'Phone', 'Address'].map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {leads.map((item: Customer.AllianceLeadTransport, index: number) => {
            return (
              <tr
                key={item.id + index}
                className="[&>*]:px-4 [&>*]:py-1.5 odd:bg-gray-100 border-b cursor-pointer"
                onClick={() => handleLeadRowClick(item.id)}
              >
                <td>{item?.firstName + ' ' + (item?.lastName ? item.lastName : '')}</td>
                <td>{item.company}</td>
                <td>{item?.email}</td>
                {item.phoneNumber && <td>{formatE164ToUSPhoneNumber(item?.phoneNumber)}</td>}
                {item?.serviceLocation?.address && (
                  <td>{formatServiceAddress(item?.serviceLocation?.address as AddressTransport) || ''}</td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="w-full">
      <p className="mb-2.5 text-xl">Search Results</p>
      <div className="bg-white rounded shadow-dark p-4 mb-4">
        <p className="text-lg mb-1">Orders</p>
        {orderResults.length === 0 ? <p className="mb-1">No Order Results</p> : null}
        {orderResults.length > 0 ? <OrderTableFull orders={orderResults} /> : null}
      </div>
      <div className="bg-white rounded shadow-dark p-4 mb-4">
        <p className="text-lg mb-1">Customers</p>
        {customerResults.length === 0 ? (
          <p className="mb-1">No Customers Results</p>
        ) : (
          <CustomerTable customers={customerResults} />
        )}
      </div>
      <div className="bg-white rounded shadow-dark p-4 mb-4">
        <p className="text-lg mb-1">Leads</p>
        {leadResults.length === 0 ? <p className="mb-1">No Leads Results</p> : <LeadsTable leads={leadResults} />}
      </div>
      <div className="bg-white rounded shadow-dark p-4 mb-4">
        <p className="text-lg mb-1">Haulers</p>
        {haulerResults.length === 0 ? (
          <p className="mb-1">No Hauler Results</p>
        ) : (
          <HaulerTable haulers={haulerResults} />
        )}
      </div>

      <div className="bg-white rounded shadow-dark p-4 mb-4">
        <p className="text-lg mb-1">Receivables</p>
        {receivableResults.length === 0 ? (
          <p className="mb-1">No Receivable Results</p>
        ) : (
          <ReceivableTable receivables={receivableResults} />
        )}
      </div>

      <div className="bg-white rounded shadow-dark p-4 mb-4">
        <p className="text-lg mb-1">Payables</p>
        {payableResults.length === 0 ? (
          <p className="mb-1">No Payables Results</p>
        ) : (
          <PayablesTable payables={payableResults} />
        )}
      </div>
    </div>
  );
};
