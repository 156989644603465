import React, { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { SourFilters, SourSearch, SourSearchWrapper } from '@wayste/sour-search';
import { Button, Checkbox, DatePicker, Loading } from '@wayste/sour-ui';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Dialog from '../../../components/Dialog';
import { UIContext } from '../../../contexts';
import { useAuthToken } from '../../../hooks/authhook';

const BulkInvoiceForm = () => {
  const client = useWaysteClient();
  const { token } = useAuthToken();
  const { showFlash } = useContext(UIContext);
  const [afterDate, setAfterDate] = useState<Date>(new Date());
  const [beforeDate, setBeforeDate] = useState<Date>(new Date());
  const [includePaidInvoices, setIncludePaidInvoices] = useState(false);
  const [customerId, setCustomerId] = useState<string>();
  const [loading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string>();
  const [search, setSearch] = useState<string>('');
  // const [data, setData] = useState<Blob>();

  // HAVE TO USE FETCH BECAUSE AXIOS DOESN'T SUPPORT FILLE STREAMING
  const fetchPdfs = async (ids: string[]) => {
    const response = await fetch(`${import.meta.env.VITE_BASE_API_URL}/pdf/admin-portal/bulk/receivables`, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ receivables: ids }),
    });
    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = 'invoices.zip';
    document.body.appendChild(a);
    a.click();
    setIsLoading(false);
  };

  // DEFINE ON NAVIGATE LOGIC
  const onNavigate = (entity: any, recordID: string) => {
    setSearch(entity?.contacts?.[0]?.firstName + ' ' + entity?.contacts?.[0]?.lastName);
    setCustomerId(recordID);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage(undefined);
    if (!customerId || !afterDate || !afterDate) return;
    console.log(afterDate, afterDate, customerId, includePaidInvoices);
    const ordersQuery: Partial<OrdersQuery> = {
      allianceCustomerID: customerId,
      createdAfter: afterDate.toISOString(),
      createdBefore: beforeDate.toISOString(),
    };
    interface WithReceivables extends Order.AllianceOrderTransport {
      receivables: Invoice.ReceivableTransport[];
    }

    try {
      setIsLoading(true);
      const orderData = await client.order().adminPortal.query(ordersQuery);
      const orderIds = orderData.results.map((order) => order.id);

      if (orderIds.length === 0) {
        setIsLoading(false);
        return [];
      }

      const receivables = await client.invoice().adminPortal.receivable.query({
        orderID: orderIds.join(','),
      });
      const mapped = orderData.results.flatMap((order) => {
        const receivable = receivables.flatMap((receivable) => {
          if (receivable.invoiceDetails.orderID === order.id) {
            return receivable;
          }
        });
        const filtered = receivable.filter((r) => r !== undefined);
        return { ...order, receivables: filtered };
      });

      const orders = mapped as WithReceivables[];

      if (orders.length === 0) {
        setMessage('No invoices found for provided filters.');
        setIsLoading(false);
        return;
      }

      // FILTERED TO ONLY PAID INVOICES
      if (!includePaidInvoices) {
        const filtered = orders.flatMap((order) =>
          order.receivables
            .filter((receivable) => !receivable.invoiceDetails.paidInFull)
            .flatMap((receivable) => receivable.id),
        );

        if (!filtered || filtered.length === 0) {
          setMessage('No unpaid invoices found.');
          setIsLoading(false);
          return;
        }
        console.log({ receivables: filtered });

        await fetchPdfs(filtered);

        // saveAs(blob, "invoices.zip");
      } else {
        const mapped = orders.flatMap((order) => order.receivables.flatMap((r) => r.id));
        console.log('includes paid', mapped);
        console.log({ receivables: mapped });

        await fetchPdfs(mapped);
      }
    } catch (error) {
      console.log(error);
      showFlash('Error while downloading invoices.', 'error');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-3">
      {search ? (
        <div className="p-1 flex flex-row justify-between items-center h-6">
          <div>{search}</div>
          <Button
            onClick={() => {
              setSearch('');
            }}
          >
            <XMarkIcon className="w-4 h-4" />
          </Button>
        </div>
      ) : (
        <div>
          <SourSearchWrapper
            onNavigate={onNavigate}
            highlight={true}
            defaultFilters={{
              query: {
                type: 'sourgum-customer',
              },
            }}
            size={10}
            options={{
              application: 'aap',
              apiKey: import.meta.env.VITE_ELASTIC_KEY,
              environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
            }}
          >
            <div className="flex flex-row justify-between space-x-4 w-full">
              <SourSearch
                options={{
                  searchPopoverFixed: false,
                  showTips: false,
                  showMessages: false,
                  resultSize: 'w-[550px]',
                  searchPositionFixed: true,
                  placeholder: 'Search Customer',
                }}
              />
              <div className="hidden">
                <SourFilters />
              </div>
            </div>
          </SourSearchWrapper>
        </div>
      )}

      <DatePicker
        value={afterDate}
        onChange={(date: '' | Date) => {
          if (!date) return alert('Error while setting date.');
          setAfterDate(date);
        }}
        label="Created after"
        required
      />

      <DatePicker
        value={beforeDate}
        onChange={(date: '' | Date) => {
          if (!date) return alert('Error while setting date.');
          setBeforeDate(date);
        }}
        label="Created before"
        minDate={afterDate}
        required
      />

      <Checkbox
        inputProps={{
          checked: includePaidInvoices,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => setIncludePaidInvoices(event.currentTarget.checked),
        }}
        label="Include Paid Invoices"
      />

      <div>{message}</div>
      <button className="btn-primary w-full" type="submit">
        Download
        {loading && <Loading className="text-white" size="h-4 w-4 ml-2" />}
      </button>
    </form>
  );
};
export type BulkInvoiceModalProp = {
  open: boolean;
  handleClose: () => void;
};
const BulkInvoiceModal = (props: BulkInvoiceModalProp) => {
  return (
    <Dialog
      className="w-[100%] overflow-visible"
      styledTitle="Bulk Download Invoices"
      open={props.open}
      onClose={() => props.handleClose()}
    >
      <BulkInvoiceForm />
    </Dialog>
  );
};

export default BulkInvoiceModal;
