import React, { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { V1 } from '@alliance-disposal/pricing';
import { Customer, Pricing } from '@alliance-disposal/transport-types';
import { Checkbox, Loading, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { dollarsToCents, moneyFormatter } from '@wayste/utils';
import { useForm } from 'react-hook-form';
import { ccRate, materials, priceTypes, priceTypesEnums } from '../../../../utils';

type SizeDiscountInfo = {
  size: number;
  available: boolean;
  haulRateDiscount: number | '';
  tonLimitDiscount: number | '';
  dumpRateDiscount: number | '';
  overageDiscountRate: number | '';
};

interface FormProps {
  material?: string;
  globalHaulRateDiscount: number | '';
  globalDumpRateDiscount: number | '';
  globalOverageRateDiscount: number | '';
  sizeMap: Record<string, SizeDiscountInfo>;
  discountType: Pricing.CustomerDiscountType.DOLLAR | Pricing.CustomerDiscountType.FIXED;
}

const CustomMaterialPricingForm = ({
  pricingZone,
  selectedDiscount,
  selectedDiscountData,
  handleClose,
  handleSubmit,
  customer,
}: {
  selectedDiscount: Pricing.CustomerDiscountTransport;
  pricingZone: Pricing.CustomerDiscountTransport['pricingZone'];
  selectedDiscountData?: Pricing.DiscountDataTransport;
  customer?: Customer.AllianceCustomerTransport;
  handleSubmit: (opts: {
    pricingZoneID: string;
    materialDiscount: Pricing.DiscountDataTransport;
    discountType: Pricing.CustomerDiscountType.DOLLAR | Pricing.CustomerDiscountType.FIXED;
  }) => Promise<void>;
  handleClose: () => void;
}) => {
  const client = useWaysteClient();
  const [selectedStandardMaterialData, setSelectedStandardMaterialData] =
    useState<Pricing.PricingTransport['pricingData'][0]>();
  const [materialOptions, setMaterialOptions] = useState<string[]>([]);
  // standard/ default pricing data of specific pricing zone
  const [pricingDataMaterialsMap, setPricingDataMaterialsMap] =
    useState<Record<string, Pricing.PricingTransport['pricingData'][0]>>();
  const [standardSizeMap, setStandardSizeMap] =
    useState<Record<string, Pricing.PricingTransport['pricingData'][0]['sizes'][0]>>();
  const [editAllHaul, setEditAllHaul] = useState(false);
  const [editAllDump, setEditAllDump] = useState(false);
  const [editAllOverage, setEditAllOverage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [discountTypeLabel, setDiscountTypeLabel] = useState('Discount');

  const {
    setValue,
    handleSubmit: handleRHFSubmit,
    register,
    watch,
    reset,
  } = useForm<FormProps>({
    mode: 'all',
    shouldFocusError: false,
    defaultValues: {
      material: '',
      globalHaulRateDiscount: '',
      globalDumpRateDiscount: '',
      globalOverageRateDiscount: '',
      sizeMap: {},
      discountType: Pricing.CustomerDiscountType.DOLLAR,
    },
  });

  const watchMaterial = watch('material');
  const watchSizeMap = watch('sizeMap');
  const watchDiscountType = watch('discountType');

  const onSubmit = (data: FormProps) => {
    if (!watchMaterial || !pricingZone?.id) {
      return;
    }
    const sizeMap = data.sizeMap;
    const cleanedSize = Object.keys(sizeMap).map((key) => {
      const x = sizeMap[key];
      if (!x.available) {
        const updateSizePayload: Pricing.PricingTransport['pricingData'][0]['sizes'][0] = {
          size: String(x.size),
          dump: 0,
          haul: 0,
          over: 0,
          // -420 === null | Luc said have to pass up -420 because that equals null in this fucked up world we live in
          tonLimit: -420,
        };
        return updateSizePayload;
      }
      const updateSizePayload: Pricing.PricingTransport['pricingData'][0]['sizes'][0] = {
        size: String(x.size),
        dump: dollarsToCents(x.dumpRateDiscount || 0),
        // -420 === null | Luc said have to pass up -420 because that equals null in this fucked up world we live in
        tonLimit: x.tonLimitDiscount || x.tonLimitDiscount === 0 ? x.tonLimitDiscount : -420,
        haul: dollarsToCents(x.haulRateDiscount || 0),
        over: dollarsToCents(x.overageDiscountRate || 0),
      };
      return updateSizePayload;
    });
    console.log(cleanedSize);
    handleSubmit({
      pricingZoneID: pricingZone?.id,
      discountType: watchDiscountType,
      materialDiscount: {
        material: watchMaterial,
        sizes: cleanedSize ?? [],
      },
    });
    handleClose();
  };

  const handleMaterialSelect = (material: string) => {
    if (!!selectedDiscountData) return;
    reset();
    const selectedMat = pricingDataMaterialsMap?.[material as keyof typeof pricingDataMaterialsMap];
    if (!selectedMat) return;
    setSelectedStandardMaterialData(selectedMat);
    setValue('material', material);
    setValue('globalHaulRateDiscount', 0);
    const standardSizeRecord: Record<string, Pricing.PricingTransport['pricingData'][0]['sizes'][0]> = {};
    const sizeDiscountMap: Record<string, SizeDiscountInfo> = {};
    selectedMat.sizes.forEach((x) => {
      standardSizeRecord[x.size] = {
        haulDollars: x?.haulDollars || 0,
        tonLimit: x.tonLimit,
        dumpDollars: x?.dumpDollars || 0,
        size: x.size,
        overDollars: x?.overDollars || 0,
      };
      sizeDiscountMap[x.size] = {
        available: true,
        dumpRateDiscount: '',
        haulRateDiscount: '',
        overageDiscountRate: '',
        size: +x.size,
        tonLimitDiscount: '',
      };
    });
    setStandardSizeMap(standardSizeRecord);
    setValue('sizeMap', sizeDiscountMap);
  };

  const handleDiscountTypeSelect = (
    value: Pricing.CustomerDiscountType.DOLLAR | Pricing.CustomerDiscountType.FIXED,
  ) => {
    value === Pricing.CustomerDiscountType.FIXED ? setDiscountTypeLabel('Fixed') : setDiscountTypeLabel('Discount');
    setValue('discountType', value);
  };

  const fetchPricingDataForZone = async () => {
    if (!pricingZone?.id) {
      console.warn('pricing zone id is null');
      return;
    }
    setIsLoading(true);
    try {
      const pricingData = await client.pricing().adminPortal.pricing.fetch(pricingZone.id);
      const fullPricingDataForZone = pricingData as Pricing.PricingTransport;
      const pricingDataMaterialsMap: Record<string, Pricing.PricingTransport['pricingData'][0]> = {};
      fullPricingDataForZone?.pricingData?.forEach((x) => {
        pricingDataMaterialsMap[x.material] = x;
      });
      setPricingDataMaterialsMap(pricingDataMaterialsMap);

      if (!selectedDiscountData) {
        // adding new material, filter out existing material from selection
        // we get standard size/mat information on material select
        const existingMaterialKeys: Record<string, boolean> = {};
        selectedDiscount.discountData.forEach((x) => {
          existingMaterialKeys[x.material] = true;
        });
        const keys = Object.keys(pricingDataMaterialsMap).filter((x) => !existingMaterialKeys[x]);
        setMaterialOptions(
          keys.sort((a, b) =>
            materials[a as keyof typeof materials] > materials[b as keyof typeof materials] ? 1 : -1,
          ),
        );
      } else {
        const standardPricingForMaterial = pricingDataMaterialsMap[selectedDiscountData.material];
        if (!standardPricingForMaterial) {
          return;
        }
        const discountSizeMap: Record<string, SizeDiscountInfo> = {};
        selectedDiscountData.sizes.forEach((x) => {
          const discountSize: SizeDiscountInfo = {
            haulRateDiscount: x?.haulDollars || '',
            tonLimitDiscount: x.tonLimit || x.tonLimit === 0 ? x.tonLimit : '',
            dumpRateDiscount: x?.dumpDollars || '',
            size: +x.size,
            overageDiscountRate: x?.overDollars || '',
            available: true,
          };
          discountSizeMap[x.size] = discountSize;
        });
        const standardSizeRecord: Record<string, Pricing.PricingTransport['pricingData'][0]['sizes'][0]> = {};
        standardPricingForMaterial.sizes.forEach((x) => {
          standardSizeRecord[x.size] = {
            haulDollars: x?.haulDollars || 0,
            tonLimit: x.tonLimit || 0,
            dumpDollars: x?.dumpDollars || 0,
            size: x.size,
            overDollars: x?.overDollars || 0,
          };
          if (!discountSizeMap[x.size]) {
            discountSizeMap[x.size] = {
              available: true,
              dumpRateDiscount: '',
              haulRateDiscount: '',
              overageDiscountRate: '',
              size: +x.size,
              tonLimitDiscount: '',
            };
          }
        });
        setMaterialOptions(
          Object.keys(pricingDataMaterialsMap).sort((a, b) =>
            materials[a as keyof typeof materials] > materials[b as keyof typeof materials] ? 1 : -1,
          ),
        );
        setSelectedStandardMaterialData(standardPricingForMaterial);
        setStandardSizeMap(standardSizeRecord);
        setValue('material', selectedDiscountData.material);
        setValue(
          'discountType',
          selectedDiscount.discountType as Pricing.CustomerDiscountType.FIXED | Pricing.CustomerDiscountType.DOLLAR,
        );
        setValue('globalHaulRateDiscount', 0);
        setValue('globalDumpRateDiscount', 0);
        setValue('globalOverageRateDiscount', 0);
        setValue('sizeMap', discountSizeMap);
      }
    } catch (error) {
      console.warn('ERROR', error);
      alert('An error has occurred, please contact an AAP dev.');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPricingDataForZone();
  }, []);

  const standardPrice = (standardSizeInfo: Pricing.PricingTransport['pricingData'][0]['sizes'][0]) => {
    const price = V1.quotedPriceBuild(
      selectedStandardMaterialData?.type === priceTypesEnums.yard
        ? +standardSizeInfo.size
        : standardSizeInfo.tonLimit || 0,
      dollarsToCents(standardSizeInfo?.dumpDollars || 0),
      dollarsToCents(standardSizeInfo?.haulDollars || 0),
      selectedStandardMaterialData?.tax && !customer?.taxExempt ? pricingZone?.actualTaxRate || 0 : 0,
      !customer?.defaultPaymentSettings?.paymentMethod ||
        customer?.defaultPaymentSettings?.paymentMethod === 'creditCard'
        ? ccRate
        : 0,
      true,
      0,
    );
    return V1.round(price.total);
  };

  const discountPrice = (
    standardSizeInfo: Pricing.PricingTransport['pricingData'][0]['sizes'][0],
    discountSizeInfo: SizeDiscountInfo,
  ) => {
    const price = V1.quotedPriceBuild(
      selectedStandardMaterialData?.type === priceTypesEnums.yard
        ? +standardSizeInfo.size
        : (standardSizeInfo.tonLimit || 0) - -(discountSizeInfo.tonLimitDiscount || 0),
      dollarsToCents(standardSizeInfo?.dumpDollars || 0) - dollarsToCents(discountSizeInfo.dumpRateDiscount || 0),
      dollarsToCents(standardSizeInfo?.haulDollars || 0) - dollarsToCents(discountSizeInfo.haulRateDiscount || 0),
      selectedStandardMaterialData?.tax && !customer?.taxExempt ? pricingZone?.actualTaxRate || 0 : 0,
      !customer?.defaultPaymentSettings?.paymentMethod ||
        customer?.defaultPaymentSettings?.paymentMethod === 'creditCard'
        ? ccRate
        : 0,
      true,
      0,
    );
    return price;
  };

  const discountPriceFixed = (
    standardSizeInfo: Pricing.PricingTransport['pricingData'][0]['sizes'][0],
    discountSizeInfo: SizeDiscountInfo,
  ) => {
    const price = V1.quotedPriceBuild(
      selectedStandardMaterialData?.type === priceTypesEnums.yard
        ? +standardSizeInfo.size
        : discountSizeInfo.tonLimitDiscount || discountSizeInfo.tonLimitDiscount === 0
        ? discountSizeInfo.tonLimitDiscount
        : standardSizeInfo.tonLimit || 0,
      dollarsToCents(discountSizeInfo.dumpRateDiscount || standardSizeInfo?.dumpDollars || 0),
      dollarsToCents(discountSizeInfo.haulRateDiscount || standardSizeInfo?.haulDollars || 0),
      customer?.taxExempt || !selectedStandardMaterialData?.tax ? 0 : pricingZone?.actualTaxRate || 0,
      !customer?.defaultPaymentSettings?.paymentMethod ||
        customer?.defaultPaymentSettings?.paymentMethod === 'creditCard'
        ? ccRate
        : 0,
      true,
      0,
    );
    return price;
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  const displayHaulRate = (key: string) => {
    if (!standardSizeMap) return undefined;
    if (watchDiscountType === Pricing.CustomerDiscountType.FIXED) {
      return dollarsToCents(watchSizeMap[key]?.haulRateDiscount || standardSizeMap[key]?.haulDollars || 0);
    }
    return dollarsToCents((standardSizeMap[key]?.haulDollars || 0) - (watchSizeMap[key]?.haulRateDiscount || 0));
  };

  const displayDumpRate = (key: string) => {
    if (!standardSizeMap) return undefined;
    if (watchDiscountType === Pricing.CustomerDiscountType.FIXED) {
      return dollarsToCents(watchSizeMap[key]?.dumpRateDiscount || standardSizeMap[key]?.dumpDollars || 0);
    }
    return dollarsToCents((standardSizeMap[key]?.dumpDollars || 0) - (watchSizeMap[key]?.dumpRateDiscount || 0));
  };

  const displayOverageRate = (key: string) => {
    if (!standardSizeMap) return undefined;
    if (watchDiscountType === Pricing.CustomerDiscountType.FIXED) {
      return dollarsToCents(watchSizeMap[key]?.overageDiscountRate || standardSizeMap[key]?.overDollars || 0);
    }
    return dollarsToCents((standardSizeMap?.[key].overDollars || 0) - (watchSizeMap[key]?.overageDiscountRate || 0));
  };

  const displayTons = (key: string) => {
    if (!standardSizeMap) return undefined;
    if (watchDiscountType === Pricing.CustomerDiscountType.FIXED) {
      return watchSizeMap[key]?.tonLimitDiscount || watchSizeMap[key]?.tonLimitDiscount === 0
        ? watchSizeMap[key]?.tonLimitDiscount
        : standardSizeMap[key]?.tonLimit || 0;
    }
    return (
      (standardSizeMap?.[String(standardSizeMap[key].size)].tonLimit ?? 0) - -watchSizeMap?.[key]?.tonLimitDiscount
    );
  };

  const displayNewPrice = (key: string) => {
    if (!standardSizeMap) return undefined;
    if (watchDiscountType === Pricing.CustomerDiscountType.FIXED) {
      return discountPriceFixed(standardSizeMap[key], watchSizeMap[key]).total;
    }
    return discountPrice(standardSizeMap[key], watchSizeMap[key]).total;
  };

  return (
    <form onSubmit={handleRHFSubmit(onSubmit)} className="flex flex-col gap-4 ml-3">
      <div className="flex w-full justify-between px-3 gap-y-16">
        <div className="w-1/2">
          <Select label="Material" onSelect={(value) => handleMaterialSelect(value)} value={watchMaterial}>
            {materialOptions.map((material) => (
              <SelectOption value={material}>{materials[material as keyof typeof materials]}</SelectOption>
            ))}
          </Select>
        </div>
        <div>
          <Select
            label="Discount Type"
            onSelect={(value) =>
              handleDiscountTypeSelect(
                value as Pricing.CustomerDiscountType.DOLLAR | Pricing.CustomerDiscountType.FIXED,
              )
            }
            value={watchDiscountType}
          >
            <SelectOption value={Pricing.CustomerDiscountType.DOLLAR}>Discount off of public</SelectOption>
            <SelectOption value={Pricing.CustomerDiscountType.FIXED}>Fixed price</SelectOption>
          </Select>
        </div>
        <div className="flex flex-col">
          <span className="underline font-bold">Price Type</span>
          <span className="text-blue-gray-500">
            {selectedStandardMaterialData
              ? priceTypes[selectedStandardMaterialData.type as keyof typeof priceTypes] ??
                selectedStandardMaterialData.type
              : 'Select a material'}
          </span>
        </div>
      </div>
      {Boolean(watchMaterial && selectedStandardMaterialData) && (
        <>
          <p className="text-center italic text-sm text-blue-gray-500">
            If you do not add a custom rate, it will not override the standard rate.
            <br />
            Discount off of public subtracts the amount you enter from the standard public pricing to calculate the
            customer's price.
            <br />
            With fixed price the amount you enter is the amount the customer will pay, regardless if public pricing is
            changed.
          </p>
          {(selectedStandardMaterialData?.type === priceTypesEnums.ton ||
            selectedStandardMaterialData?.type === priceTypesEnums.yard) && (
            <div className="flex flex-col w-full">
              <div className="flex">
                <Checkbox
                  label="Edit all hauls"
                  inputProps={{
                    onClick: () => setEditAllHaul(!editAllHaul),
                    checked: editAllHaul,
                  }}
                />
                <Checkbox
                  label="Edit all dumps"
                  inputProps={{
                    onClick: () => setEditAllDump(!editAllDump),
                    checked: editAllDump,
                  }}
                />
                {selectedStandardMaterialData?.type === priceTypesEnums.ton && (
                  <Checkbox
                    label="Edit all overages"
                    inputProps={{
                      onClick: () => setEditAllOverage(!editAllOverage),
                      checked: editAllOverage,
                    }}
                  />
                )}
              </div>
            </div>
          )}
          <div className="grid gap-4 grid-cols-3 w-full items-center">
            <p className="mb-2 font-bold underline">Standard rate</p>
            <p className="mb-2 font-bold underline">{discountTypeLabel} amount</p>
            <p className="mb-2 font-bold underline">Custom rate</p>
            {/* START Edit All Buttons */}
            {editAllHaul && (
              <>
                <div />
                <div className="w-40">
                  <TextField
                    label={`${discountTypeLabel} haul`}
                    placeholder="Haul Rate"
                    type="number"
                    required
                    startAdornment={<p className="mr-1 text-sourgum-greyblue-400">$</p>}
                    inputProps={{
                      ...register('globalHaulRateDiscount', {
                        required: 'Haul rate is required',
                        onChange: (e) => {
                          const sizeMapClone = {
                            ...watchSizeMap,
                          };
                          Object.keys(watchSizeMap).forEach((key) => {
                            const oldValues = sizeMapClone[key];
                            if (!oldValues.available) return;
                            const sizeDiscountInfo: SizeDiscountInfo = {
                              ...oldValues,
                              haulRateDiscount: e.target.value || 0,
                            };
                            sizeMapClone[key] = sizeDiscountInfo;
                          });
                          setValue('sizeMap', sizeMapClone);
                          setValue('globalHaulRateDiscount', e.target.value);
                        },
                      }),
                    }}
                  />
                </div>
                <div />
              </>
            )}
            {editAllDump && (
              <>
                <div />
                <div className="w-40">
                  <TextField
                    label={`${discountTypeLabel} dump`}
                    placeholder="Dump Rate"
                    type="number"
                    required
                    startAdornment={<p className="mr-1 text-sourgum-greyblue-400">$</p>}
                    inputProps={{
                      ...register('globalDumpRateDiscount', {
                        required: 'Dump rate is required',
                        onChange: (e) => {
                          const sizeMapClone = {
                            ...watchSizeMap,
                          };
                          Object.keys(watchSizeMap).forEach((key) => {
                            const oldValues = sizeMapClone[key];
                            if (!oldValues.available) return;
                            const sizeDiscountInfo: SizeDiscountInfo = {
                              ...oldValues,
                              dumpRateDiscount: e.target.value || 0,
                            };
                            sizeMapClone[key] = sizeDiscountInfo;
                          });
                          setValue('sizeMap', sizeMapClone);
                          setValue('globalDumpRateDiscount', e.target.value);
                        },
                      }),
                    }}
                  />
                </div>
                <div />
              </>
            )}
            {editAllOverage && (
              <>
                <div />
                <div className="w-40">
                  <TextField
                    label={`${discountTypeLabel} overage`}
                    placeholder="Overage Rate"
                    type="number"
                    required
                    startAdornment={<p className="mr-1 text-sourgum-greyblue-400">$</p>}
                    inputProps={{
                      ...register('globalOverageRateDiscount', {
                        required: 'Overage rate is required',
                        onChange: (e) => {
                          const sizeMapClone = {
                            ...watchSizeMap,
                          };
                          Object.keys(watchSizeMap).forEach((key) => {
                            const oldValues = sizeMapClone[key];
                            if (!oldValues.available) return;
                            const sizeDiscountInfo: SizeDiscountInfo = {
                              ...oldValues,
                              overageDiscountRate: e.target.value || 0,
                            };
                            sizeMapClone[key] = sizeDiscountInfo;
                          });
                          setValue('sizeMap', sizeMapClone);
                          setValue('globalOverageRateDiscount', e.target.value);
                        },
                      }),
                    }}
                  />
                </div>
                <div />
              </>
            )}
            {/* END Edit All Buttons */}
            <p className="text-left text-blue-gray-500 col-span-3">Available Sizes</p>
            {standardSizeMap &&
              Object.keys(standardSizeMap)?.map((key) => {
                return (
                  <React.Fragment key={key}>
                    <div className="flex items-center gap-2">
                      <div>
                        <Checkbox
                          label={standardSizeMap[key]?.size}
                          inputProps={{
                            onClick: () => setValue(`sizeMap.${key}.available`, !watchSizeMap[key].available),
                            checked: watchSizeMap[key].available,
                          }}
                        />
                      </div>
                      <div>
                        <p>{moneyFormatter(standardPrice(standardSizeMap[key]))}</p>
                        <p>{`${standardSizeMap?.[String(standardSizeMap[key]?.size)]?.tonLimit ?? 0} tons`}</p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <TextField
                          label={`${discountTypeLabel} haul`}
                          type="number"
                          startAdornment={<p className="mr-1 text-sourgum-greyblue-400">$</p>}
                          inputProps={{
                            ...register(`sizeMap.${[key]}.haulRateDiscount`),
                            disabled: !watchSizeMap[key].available || editAllHaul,
                          }}
                        />
                      </div>
                      {(selectedStandardMaterialData?.type === priceTypesEnums.ton ||
                        selectedStandardMaterialData?.type === priceTypesEnums.yard) && (
                        <div>
                          <TextField
                            label={`${discountTypeLabel} dump`}
                            type="number"
                            startAdornment={<p className="mr-1 text-sourgum-greyblue-400">$</p>}
                            inputProps={{
                              ...register(`sizeMap.${[key]}.dumpRateDiscount`),
                              disabled: !watchSizeMap[key].available || editAllDump,
                            }}
                          />
                        </div>
                      )}
                      {selectedStandardMaterialData?.type === priceTypesEnums.ton && (
                        <>
                          <div>
                            <TextField
                              label={`${discountTypeLabel} overage`}
                              type="number"
                              startAdornment={<p className="mr-1 text-sourgum-greyblue-400">$</p>}
                              inputProps={{
                                ...register(`sizeMap.${[key]}.overageDiscountRate`),
                                disabled: !watchSizeMap[key].available || editAllOverage,
                              }}
                            />
                          </div>
                          <div>
                            <TextField
                              label={
                                watchDiscountType === Pricing.CustomerDiscountType.FIXED ? 'Included tons' : 'Add tons'
                              }
                              type="number"
                              inputProps={{
                                ...register(`sizeMap.${[key]}.tonLimitDiscount`),
                                disabled: !watchSizeMap[key].available,
                                step: '0.01',
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <div className="grid grid-cols-2 text-xs">
                        <span>Haul rate:</span>
                        <span>{moneyFormatter(displayHaulRate(key))}</span>
                        {(selectedStandardMaterialData?.type === priceTypesEnums.ton ||
                          selectedStandardMaterialData?.type === priceTypesEnums.yard) && (
                          <>
                            <span>Dump rate:</span>
                            <span>{moneyFormatter(displayDumpRate(key))}</span>
                          </>
                        )}
                        {selectedStandardMaterialData?.type === priceTypesEnums.ton && (
                          <>
                            <span>Overage rate:</span>
                            <span>{moneyFormatter(displayOverageRate(key))}</span>
                            <span>Tons:</span>
                            <span>{displayTons(key)}</span>
                          </>
                        )}
                        <span>New price:</span>
                        <div>{moneyFormatter(displayNewPrice(key))}</div>
                      </div>
                    </div>
                    <hr className="col-span-3 mb-1" />
                  </React.Fragment>
                );
              })}
          </div>
        </>
      )}
      <div className="flex justify-end gap-4">
        <button className="btn-dark-grey-outlined" onClick={handleClose} type="button">
          Cancel
        </button>
        <button className="btn-primary" type="submit">
          Save
        </button>
      </div>
    </form>
  );
};

export default CustomMaterialPricingForm;
