import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import type { UniversalService } from '@alliance-disposal/transport-types';
import { TanDataGrid } from '@wayste/sour-ui';
import { formatISODateString, moneyFormatter } from '@wayste/utils';
import { ArrowTopRightOnSquareIcon, TrashIcon } from '@heroicons/react/24/solid';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { UIContext } from '../../contexts';
import UniversalServicesStatusPopper from '../UniversalServices/UniversalServicesStatusPopper';
import UniversalServicesCancelDialog from '../UniversalServicesCancelDialog/UniversalServicesCancelDialog';

type Order = {
  serviceOrder: UniversalService.ServiceOrder;
  orderNumber?: string;
  servicePeriod?: string;
  serviceType?: string;
  sku?: string;
  status?: UniversalService.ServiceOrder;
  frequency?: string;
  vendor?: string;
  reoccurringRate?: string; // or number?
  actions?: undefined;
  type: 'subscription' | 'oneTime';
  canceled?: boolean;
  subscriptionActive?: boolean | undefined;
};

interface ServiceOrdersListTableProps {
  serviceGrouping?: UniversalService.ServiceGrouping | undefined;
  serviceOrders: UniversalService.ServiceOrder[];
  deletable?: boolean;
  onRowClicked: (order: UniversalService.ServiceOrder) => void;
}

const ServiceOrdersListTable = ({
  serviceGrouping,
  serviceOrders,
  deletable = true,
  onRowClicked,
}: ServiceOrdersListTableProps) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);

  const getRowFromServiceOrder = (serviceOrder: UniversalService.ServiceOrder) => {
    const order: Order = {
      serviceOrder: serviceOrder,
      orderNumber: serviceOrder.fullOrderNumber,
      servicePeriod: `${formatISODateString(serviceOrder.startDate?.toString())} - ${
        serviceOrder.endDate ? formatISODateString(serviceOrder.endDate?.toString()) : ''
      }`,
      serviceType: serviceOrder.serviceType.family.name,
      sku: serviceOrder.serviceType.name,
      status: serviceOrder,
      frequency: serviceOrder?.serviceFrequency + ' per ' + serviceOrder?.serviceFrequencyUnit,
      type: serviceOrder.subscriptionDetails ? 'subscription' : 'oneTime',
      // pertains to one time orders
      canceled: serviceOrder.cancellationDetails ? true : false,
      // pertains to subscriptions
      subscriptionActive: serviceOrder.subscriptionDetails ? serviceOrder.subscriptionDetails.active : undefined, // active in next Transport types update
      reoccurringRate: moneyFormatter(
        // this filter makes sure we don't accidentally get the vendor event
        serviceOrder.events
          .filter((event) => event.unitPrice)
          .find((event) => event.lineItemType.description === 'recurring')?.unitPrice,
        {
          undefinedBehavior: 'Unknown',
        },
      ),
      vendor: serviceOrder.vendorName,
    };

    return order;
  };

  const [cancelOpen, setCancelOpen] = useState<boolean>(false);
  const [cancelOrderID, setCancelOrderID] = useState<string>('');
  const [cancelOrderType, setCancelOrderType] = useState<'subscription' | 'oneTime'>('oneTime');
  const [data, setData] = useState<Order[]>(
    serviceOrders.map((serviceOrder) => getRowFromServiceOrder(serviceOrder)) ?? [],
  );

  useEffect(() => {
    setData(serviceOrders.map((serviceOrder) => getRowFromServiceOrder(serviceOrder)));
  }, [serviceOrders]);

  const columnHelper = createColumnHelper<Order>();
  const columns: ColumnDef<Order, any>[] = [
    columnHelper.accessor('orderNumber', {
      header: 'Order Number',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (info) => <UniversalServicesStatusPopper display="label" serviceOrder={info.getValue()} />,
    }),
    columnHelper.accessor('servicePeriod', {
      header: 'Service Period',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('serviceType', {
      header: 'Service Type',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('sku', {
      header: 'SKU',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('frequency', {
      header: 'Frequency',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('vendor', {
      header: 'Vendor',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('reoccurringRate', {
      header: 'Reoccurring Rate',
      cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.accessor('actions', {
      header: '',
      cell: (info) => (
        <div className="flex align-middle float-middle">
          <ArrowTopRightOnSquareIcon
            className="h-5 w-5 mr-4 cursor-pointer text-wayste-blue-400"
            onClick={() => {
              onRowClicked(info.row.original.serviceOrder);
            }}
          />

          {info.row.original.type === 'subscription' ? (
            <>
              {deletable && info.row.original.subscriptionActive === true && (
                <TrashIcon
                  className="h-5 w-5 mr-1 cursor-pointer text-red-500"
                  onClick={() => {
                    setCancelOpen(true);
                    setCancelOrderID(info.row.original.serviceOrder.id);
                    setCancelOrderType(info.row.original.type);
                  }}
                />
              )}
            </>
          ) : (
            <>
              {deletable && info.row.original.canceled === false && (
                <TrashIcon
                  className="h-5 w-5 mr-1 cursor-pointer text-red-500"
                  onClick={() => {
                    setCancelOpen(true);
                    setCancelOrderID(info.row.original.serviceOrder.id);
                    setCancelOrderType(info.row.original.type);
                  }}
                />
              )}
            </>
          )}
        </div>
      ),
    }),
  ];

  // HANDLE DELETE ONE OFF ORDER OR SUBSCRIPTION
  const handleDelete = async (
    cancelationDate: Date | null,
    customerCancelationFee: number,
    vendorCancelationFee: number,
    cancelationReason: string,
  ) => {
    const serviceOrder = serviceOrders.find((order) => order.id === cancelOrderID);

    if (!serviceOrder) return;
    if (!serviceGrouping) return;

    if (cancelOrderType === 'oneTime') {
      const updatePayload: UniversalService.ServiceOrderUpdate = {
        ...serviceOrder,
        vendorID: serviceOrder?.vendorID || undefined,
        cancellationDetails: {
          date: new Date().toISOString(),
          reason: cancelationReason,
          customerFee: customerCancelationFee,
          vendorFee: vendorCancelationFee,
        },
      };

      try {
        await client
          .universalService()
          .serviceGrouping.serviceOrder.update(serviceGrouping.id, serviceOrder.id, updatePayload);

        showFlash('Service Order Successfully Cancelled', 'success');
        setCancelOpen(false);
      } catch (error) {
        console.log(error);
        showFlash('An Error Occurred Cancelling Order', 'warning');
      }
    } else {
      if (!cancelationDate) return;
      if (!serviceOrder.subscriptionDetails) return;

      const updatePayload: UniversalService.ServiceOrderUpdate = {
        ...serviceOrder,
        vendorID: serviceOrder?.vendorID || undefined,
        cancellationDetails: {
          date: cancelationDate.toISOString(),
          reason: cancelationReason,
          customerFee: customerCancelationFee,
          vendorFee: vendorCancelationFee,
        },
        subscriptionDetails: {
          ...serviceOrder.subscriptionDetails,
          endDate: cancelationDate.toISOString().split('T')[0],
          active: false,
        },
      };

      try {
        await client
          .universalService()
          .serviceGrouping.serviceOrder.update(serviceGrouping.id, serviceOrder.id, updatePayload);
        showFlash('Successfully Cancelled Subscription', 'success');
        setCancelOpen(false);
      } catch (error) {
        console.log(error);
        showFlash('Error Cancelling subscription', 'warning');
      }
    }
  };

  return (
    <>
      <UniversalServicesCancelDialog
        variant="delete"
        type={cancelOrderType}
        cancelOpen={cancelOpen}
        setCancelOpen={setCancelOpen}
        onCancel={handleDelete}
      />
      <TanDataGrid data={data} columns={columns} className="-mt-5 -mb-5 -ml-5 -mr-5" />
    </>
  );
};

export default ServiceOrdersListTable;
