import React from 'react';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Field } from 'formik';
import { Select, TextField } from 'formik-mui';
import {
  commercialBillingFrequencies,
  commercialMaterials,
  commercialServiceFrequency,
  commercialSizes,
} from '../../utils/shared-types';

export interface QuoteFormProps {
  hauler?: any;
  activeIndex: number;
}

const QuoteForm = ({ hauler, activeIndex }: QuoteFormProps) => {
  const quoteItem = hauler ? 'haulerQuotes' : 'quotes';
  return (
    <div className="bg-white rounded shadow-dark p-4 mb-5">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Add New {hauler ? 'Hauler Bid' : 'Quote'}</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            name={`[${quoteItem}][${activeIndex}].size`}
            component={Select}
            label="Size"
            labelId={`[${quoteItem}][${activeIndex}].size-label`}
            formControl={{ fullWidth: true, size: 'small' }}
          >
            {Object.entries(commercialSizes).map((item) => (
              <MenuItem key={`size-${item[0]}`} value={item[0]}>
                {item[1]}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            name={`[${quoteItem}][${activeIndex}].material`}
            component={Select}
            label="Material"
            labelId={`[${quoteItem}][${activeIndex}].Material-label`}
            formControl={{ fullWidth: true, size: 'small' }}
          >
            {Object.entries(commercialMaterials).map((item) => (
              <MenuItem key={`size-${item[0]}`} value={item[0]}>
                {item[1]}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            name={`[${quoteItem}][${activeIndex}].frequency`}
            component={Select}
            label="Service Frequency"
            labelId={`[${quoteItem}][${activeIndex}].frequency-label`}
            formControl={{ fullWidth: true, size: 'small' }}
          >
            {Object.entries(commercialServiceFrequency).map((item) => (
              <MenuItem key={`size-${item[0]}`} value={item[0]}>
                {item[1]}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            name={`[${quoteItem}][${activeIndex}].billingFrequency`}
            component={Select}
            label="Billing Frequency"
            labelId={`[${quoteItem}][${activeIndex}].billingFrequency-label`}
            formControl={{ fullWidth: true, size: 'small' }}
          >
            {Object.entries(commercialBillingFrequencies).map((item) => (
              <MenuItem key={`size-${item[0]}`} value={item[0]}>
                {item[1]}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            name={`[${quoteItem}][${activeIndex}].quantity`}
            label="Quantity"
            component={TextField}
            margin="dense"
            fullWidth
            type="number"
            step="0.01"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            name={`[${quoteItem}][${activeIndex}].priceDollars`}
            label="Quoted Price"
            component={TextField}
            margin="dense"
            fullWidth
            type="number"
            step="0.01"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            helperText="Make 0 if not quoted yet"
          />
        </Grid>
        {hauler ? (
          <Grid item xs={12} md={3}>
            <Field
              name={`[${quoteItem}][${activeIndex}].haulerName`}
              label="Hauler"
              component={TextField}
              margin="dense"
              fullWidth
            />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default QuoteForm;
