import React, { useEffect, useState } from 'react';
import { V1 } from '@alliance-disposal/pricing';
import { Customer, Pricing } from '@alliance-disposal/transport-types';
import { centsToDollars, dollarsToCents, formatUSD, moneyFormatter } from '@wayste/utils';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { ccRate, materials, priceTypes, priceTypesEnums } from '../../../utils';

const CustomPricingTable = ({
  customer,
  customerDiscount,
  onEditClick,
  onDeleteClick,
}: {
  customer?: Customer.AllianceCustomerTransport;
  customerDiscount: Pricing.CustomerDiscountTransport;
  onEditClick: (materialDiscountInfo: Pricing.CustomerDiscountTransport['discountData'][0]) => void;
  onDeleteClick: (materialDiscountInfo: Pricing.CustomerDiscountTransport['discountData'][0]) => void;
}) => {
  const [standardPricingDataMap, setStandardPricingDataMap] = useState<
    Record<
      string,
      {
        materialInfo: Pricing.PricingDataTransport;
        sizeMap: Record<string, Pricing.PricingDataTransport['sizes'][0]>;
      }
    >
  >();

  useEffect(() => {
    const standardPriceMap: Record<
      string,
      {
        materialInfo: Pricing.PricingDataTransport;
        sizeMap: Record<string, Pricing.PricingDataTransport['sizes'][0]>;
      }
    > = {};
    customerDiscount.pricingZone?.pricingData.map((x) => {
      standardPriceMap[x.material] = { materialInfo: x, sizeMap: {} };
      x.sizes.map((size) => {
        standardPriceMap[x.material].sizeMap[size.size] = size;
      });
    });
    setStandardPricingDataMap(standardPriceMap);
  }, []);

  const getPriceType = (customerDiscountInfo: Pricing.CustomerDiscountTransport['discountData'][0]) => {
    const priceType = customerDiscount.pricingZone?.pricingData.find(
      (x) => x.material === customerDiscountInfo.material,
    )?.type as keyof typeof priceTypes;
    return priceTypes[priceType as keyof typeof priceTypes] ?? 'unknown';
  };

  const getDumpRateDisplay = (customerDiscountInfo: Pricing.CustomerDiscountTransport['discountData'][0]) => {
    const standardPriceData = standardPricingDataMap?.[customerDiscountInfo.material];
    if (!standardPriceData?.materialInfo) {
      return null;
    }
    if (
      standardPriceData.materialInfo.type === priceTypesEnums.flat ||
      standardPriceData.materialInfo.sizes.every((size) => !size.dump)
    ) {
      return null;
    }
    if (customerDiscountInfo.sizes.every((size) => size.dump === customerDiscountInfo.sizes[0].dump)) {
      return <div>{formatUSD(centsToDollars(standardPriceData.materialInfo.sizes[0].dump || 0))}</div>;
    }
    return Object.values(customerDiscountInfo.sizes).map((item) => {
      if (!standardPriceData?.sizeMap[item.size]) {
        return null;
      }
      return (
        <div key={item.size} className="flex flex-col justify-center gap-2">
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
            }}
          >
            <span style={{ color: '#94a3b8' }}>{item.size}:</span>
            <span>
              {formatUSD(
                centsToDollars(
                  customerDiscount.discountType === Pricing.CustomerDiscountType.FIXED
                    ? item.dump || standardPriceData.sizeMap[String(item.size)]?.dump || 0
                    : (standardPriceData.sizeMap[String(item.size)]?.dump || 0) - (item.dump || 0),
                ),
              )}
              {', '}
            </span>
          </div>
        </div>
      );
    });
  };

  const RenderHaul = ({
    customerDiscount,
    customerDiscountInfo,
  }: {
    customerDiscount: Pricing.CustomerDiscountTransport;
    customerDiscountInfo: Pricing.CustomerDiscountTransport['discountData'][0];
  }) => {
    const findPricingDataForMaterial = standardPricingDataMap?.[customerDiscountInfo.material];
    const priceType = customerDiscount.pricingZone?.pricingData.find(
      (x) => x.material === customerDiscountInfo.material,
    )?.type as keyof typeof priceTypes;
    if (!priceType) {
      return <>Error Price type not found</>;
    }
    const findStandard = findPricingDataForMaterial?.materialInfo;
    const discountSizeMap: Record<string, Pricing.CustomerDiscountTransport['discountData'][0]['sizes'][0]> = {};
    customerDiscountInfo.sizes.forEach((x) => {
      if (findPricingDataForMaterial?.sizeMap[x.size]) {
        discountSizeMap[x.size] = x;
      }
    });
    findStandard?.sizes.forEach((x) => {
      if (!discountSizeMap[x.size]) {
        discountSizeMap[x.size] = {
          size: x.size,
          dump: 0,
          dumpDollars: 0,
          haul: 0,
          haulDollars: 0,
          over: 0,
          overDollars: 0,
          tonLimit: 0,
        };
      }
    });

    const getPrice = (sizeInfo: Pricing.CustomerDiscountTransport['discountData'][0]['sizes'][0]) => {
      const standardSizeInfo = findPricingDataForMaterial?.sizeMap[sizeInfo.size];
      if (customerDiscount.discountType === Pricing.CustomerDiscountType.FIXED) {
        const price = V1.quotedPriceBuild(
          priceType === priceTypesEnums.yard ? +sizeInfo.size : sizeInfo.tonLimit || standardSizeInfo?.tonLimit || 0,
          dollarsToCents(sizeInfo.dumpDollars || standardSizeInfo?.dumpDollars || 0),
          dollarsToCents(sizeInfo?.haulDollars || standardSizeInfo?.haulDollars || 0),
          findPricingDataForMaterial?.materialInfo.tax && !customer?.taxExempt
            ? V1.findTaxRate(customerDiscount.pricingZone?.state || '')
            : 0,
          !customer?.defaultPaymentSettings?.paymentMethod ||
            customer?.defaultPaymentSettings?.paymentMethod === 'creditCard'
            ? ccRate
            : 0,
          true,
          0,
        );
        return price.total;
      }
      const price = V1.quotedPriceBuild(
        priceType === priceTypesEnums.yard
          ? +sizeInfo.size
          : (standardSizeInfo?.tonLimit || 0) - -(sizeInfo.tonLimit || 0),
        dollarsToCents((standardSizeInfo?.dumpDollars || 0) - (sizeInfo.dumpDollars || 0)),
        dollarsToCents((standardSizeInfo?.haulDollars || 0) - (sizeInfo?.haulDollars || 0)),
        findPricingDataForMaterial?.materialInfo.tax && !customer?.taxExempt
          ? V1.findTaxRate(customerDiscount.pricingZone?.state || '')
          : 0,
        !customer?.defaultPaymentSettings?.paymentMethod ||
          customer?.defaultPaymentSettings?.paymentMethod === 'creditCard'
          ? ccRate
          : 0,
        true,
        0,
      );
      return price.total;
    };

    return (
      <>
        {Object.keys(discountSizeMap).map((key, index) => {
          return (
            <div className="flex flex-col justify-center gap-2" key={key + index}>
              <div className="flex justify-start">
                <span className="text-blue-gray-400">{discountSizeMap[key].size}: </span>
                <span>
                  {moneyFormatter(getPrice(discountSizeMap[key])) +
                    `${index !== customerDiscountInfo.sizes.length - 1 ? ',' : ''}`}
                </span>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="w-full overflow-x-auto">
      <table className="w-full border-collapse border-spacing-0 text-sm">
        <thead className="bg-black bg-opacity-5">
          <tr className="[&>*]:px-4 [&>*]:py-1.5">
            <td>Material</td>
            <td>Price Type</td>
            <td>Discount Type</td>
            <td>Haul</td>
            <td>Dump</td>
            <td>Sizes&nbsp;(YD)</td>
            <td>Last Updated</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {customerDiscount?.discountData.map((customerDiscountInfo, index) => (
            <tr key={index} className="[&>*]:px-4 [&>*]:py-1.5 [&>*]:border-b">
              <td>{materials[customerDiscountInfo.material as keyof typeof materials]}</td>
              <td>{getPriceType(customerDiscountInfo)}</td>
              <td>{customerDiscount.discountType === Pricing.CustomerDiscountType.FIXED ? 'Fixed' : 'Dollars off'}</td>
              <td>
                <RenderHaul customerDiscount={customerDiscount} customerDiscountInfo={customerDiscountInfo} />
              </td>
              <td>{getDumpRateDisplay(customerDiscountInfo)}</td>
              <td>{customerDiscountInfo.sizes.map((x) => x.size).join(',')}</td>
              <td>
                {customerDiscount?.metadata?.lastUpdatedAt
                  ? format(new Date(customerDiscount?.metadata?.lastUpdatedAt), 'MM/dd/yyyy')
                  : 'missing'}
              </td>
              <td>
                <div className="flex justify-end">
                  <button className="btn-icon ml-0" onClick={() => onEditClick(customerDiscountInfo)}>
                    <PencilIcon className="h-6 w-6 text-edit" />
                  </button>
                  <button
                    className="btn-icon ml-0"
                    onClick={() => (customerDiscount?.pricingZoneID ? onDeleteClick(customerDiscountInfo) : null)}
                  >
                    <TrashIcon className="h-6 w-6 text-delete" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomPricingTable;
