// import { InvoiceModel } from '../models/PayableReceivableModel';
// import { OrderStatus, allianceHaulerID, priceTypesEnums } from './shared-types';

// const checkBills = (order: OrderType) =>
//   order.bills &&
//   order.bills.length > 0 &&
//   order.bills.some((bill: InvoiceModel) => bill.lineItems.some((item) => item.itemName === 'haul')) &&
//   order.bills.some((bill: InvoiceModel) => bill.lineItems.some((item) => item.itemName === 'dump')) &&
//   ((order.bills.every((bill) => bill.paidInFull) &&
//     order.bills.every(
//       (bill) =>
//         bill.syncedWithAccounting &&
//         bill.payments.every((payment: any) => payment.syncedWithAccounting) &&
//         bill.refunds.every((refund: any) => refund.syncedWithAccounting),
//     )) ||
//     order.bills.every((item) => item.haulerID === allianceHaulerID));

// /**
//  * Checks to see if order "closed" status is eligible to be changed from false to true
//  * @param {Object} order Valid OrderModel object
//  * @returns true || false
//  */
// export const checkToCloseOrder = (order: OrderType) => {
//   const isInQB = order.invoices.every(
//     (invoice) =>
//       invoice.syncedWithAccounting &&
//       invoice.payments.every((payment: any) => payment.syncedWithAccounting) &&
//       invoice.refunds.every((refund: any) => refund.syncedWithAccounting),
//   );
//   if (
//     isInQB &&
//     order.invoices.length > 0 &&
//     order.invoices.every((invoice) => invoice.paidInFull) &&
//     ((order.status === OrderStatus.COMPLETED &&
//       (order.actualWeightDumped !== null ||
//         order.priceType === priceTypesEnums.flat ||
//         order.priceType === priceTypesEnums.yard) &&
//       checkBills(order)) ||
//       (order.status === OrderStatus.CANCELLED && (!order.bills || order.bills.length === 0 || checkBills(order))))
//   ) {
//     return true;
//   }
//   return false;
// };

export const getOrderCurrentSwitch = (order: any) => {
  const { id, switches, status } = order;
  if (!switches || switches.length === 0) return null;
  const parentFound = switches.find((item: any) => item.fromOrder === id);
  if (parentFound) {
    return {
      ...parentFound,
      relationship: 'PARENT',
    };
  }
  const childFound = switches.find((item: any) => item.toOrder === id);
  if (
    childFound &&
    (status === 'FUTURE' ||
      status === 'NEEDS_REVIEW' ||
      status === 'UNASSIGNED' ||
      status === 'ON_HOLD' ||
      status === 'ASSIGNED' ||
      status === 'DELIVERED')
  ) {
    return {
      ...childFound,
      relationship: 'CHILD',
    };
  }
  return null;
};
