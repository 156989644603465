import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler } from '@alliance-disposal/transport-types';
import { SourSearchResponse, SourSearchWrapper } from '@wayste/sour-search';
import { getRouterPath, sortByKey } from '@wayste/utils';
import { useHistory } from 'react-router-dom';
import routes from '../../utils/routes';
import HaulerList from './HaulerList';

const HaulerListContainer = () => {
  const waysteClient = useWaysteClient();
  const history = useHistory();
  const defaultFilter = 'sourgum-vendor';

  const [data, setData] = useState<any[]>([]);
  const [searchData, setSearchData] = useState<Hauler.HaulerWithAapTransport[]>([]);
  const [view, setView] = useState(0);
  const [lastPricing, setLastPricing] = useState<any>(null);
  const [allHaulers, setAllHaulers] = useState<Hauler.HaulerWithAapTransport[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDefaultFilter, setIsDefaultFilter] = useState<boolean | undefined>(true);
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [filterActive, setFilterActive] = useState<boolean>(false);
  // searchSubmitted IS NEEDED TO PREVENT A RACE CONDITION BETWEEN setSearchActive AND onResults
  const [searchSubmitted, setSearchSubmitted] = useState<boolean>(false);

  const getAllHaulers = async () => {
    setIsLoading(true);
    const haulerResponse = await waysteClient.vendorService().fetch();
    if (!haulerResponse?.data) return;
    setAllHaulers(haulerResponse.data.sort((a: any, b: any) => ((a?.name || '') > (b?.name || '') ? 1 : -1)));
    setIsLoading(false);
  };

  useEffect(() => {
    // TODO does this need to be put back to a subscription???
    getAllHaulers();
  }, []);

  useEffect(() => {
    if (!searchActive && !filterActive) {
      setData(allHaulers);
    }
  }, [searchActive, filterActive]);

  useEffect(() => {
    if (view === 0) setData(allHaulers);
  }, [allHaulers, view]);

  const onActiveSearch = (active: boolean) => {
    setSearchActive(active);

    // if (!active) {
    //   setSearchSubmitted(true);
    // }
  };

  const onFilterActive = (active: boolean) => {
    setFilterActive(active);
  };

  const handleToggleClick = (option: any) => {
    setView(option);
    option === 0 ? setData(allHaulers) : setData(lastPricing ? lastPricing : []);
  };

  const handleGetPricing = async (stateAbb: string) => {
    setIsLoading(true);

    const data = await waysteClient.pricing().adminPortal.pricing.query({ state: stateAbb as any });
    console.log('DATAAAA', data);
    const haulerPricing = data.filter((item: any) => !item.public);
    const pricing = haulerPricing.map((item: any) => ({
      ...item,
      hauler: allHaulers.find((hauler: any) => hauler.id === item.haulerID),
    }));
    const sortedData = sortByKey('zoneName', pricing);
    setData(sortedData);
    setLastPricing(sortedData);
    setIsLoading(false);
  };

  const handleHaulerClicked = (id: string) => {
    const selectedHauler = allHaulers.find((hauler) => hauler.id === id);
    if (selectedHauler) history.push(routes.haulers.details(selectedHauler.id), { modal: true });
  };

  const handleCreateNewClick = () => {
    history.push(routes.haulers.new);
  };

  const onNavigate = (_: unknown, recordID: string, name: string) => {
    const path = getRouterPath(name, recordID, routes);
    history.push(path);
  };

  // DEFINE ON RESULTS LOGIC
  const onResults = async (response: SourSearchResponse) => {
    response.type === defaultFilter ? setIsDefaultFilter(true) : setIsDefaultFilter(false);
    if (response.type === defaultFilter) {
      const filtered = response.results.hits.hits.map((item) => item._source);
      setSearchData(filtered as Hauler.HaulerWithAapTransport[]);
    }
    setSearchSubmitted(false);
  };

  return (
    <SourSearchWrapper
      onNavigate={onNavigate}
      onSearch={onActiveSearch}
      onFilter={onFilterActive}
      onResults={onResults}
      defaultFilters={{
        query: {
          type: defaultFilter,
        },
      }}
      options={{
        application: 'aap',
        apiKey: import.meta.env.VITE_ELASTIC_KEY as string,
        environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
      }}
    >
      <HaulerList
        data={searchActive ? searchData : data}
        onGetPricing={handleGetPricing}
        onToggleClick={handleToggleClick}
        view={view}
        onHaulerClicked={handleHaulerClicked}
        onCreateNewClick={handleCreateNewClick}
        isLoading={isLoading}
        isDefaultFilter={isDefaultFilter}
      />
    </SourSearchWrapper>
  );
};

export default HaulerListContainer;
