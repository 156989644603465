import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import routes from '../../utils/routes';
import { ServicesForm } from '../ServicesForm/ServicesForm';

const ServicesCreate = () => {
  const client = useWaysteClient();
  const history = useHistory();
  const { showFlash } = useContext(UIContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (data: UniversalService.ServiceTypeFamilyUpsert) => {
    setIsLoading(true);
    try {
      const response = await client.universalService().serviceFamily.create(data);
      if (response) {
        showFlash('Service Successfully Created', 'success');
        history.push(routes.universalServices.services.list);
      }
    } catch (error) {
      showFlash('An Error Occurred Creating a New Service', 'warning');
    }
    setIsLoading(false);
  };

  return (
    <div className="container mx-auto px-3 pt-7 pb-10 flex flex-col gap-10">
      <div className="text-xl w-full ">Create Service Type</div>
      <ServicesForm onCancel={() => history.goBack()} onSubmit={handleSubmit} isLoading={isLoading} />
    </div>
  );
};

export default ServicesCreate;
