import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Profile } from '@alliance-disposal/transport-types';
import { TanDataGrid, Tooltip } from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber } from '@wayste/utils';
import { formatISODateString } from '@wayste/utils';
import { nullableFields } from '@wayste/utils';
import { PencilIcon, PlusIcon, StarIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { FieldValues } from 'react-hook-form';
import { useConfirmationDialog } from '../../contexts';
import { useFlash } from '../../hooks/useFlash';
import { departmentTypes } from '../../utils/shared-types';
import DetailsCardWrapper from '../ui/DetailsCardWrapper';
import ContactUpdateCreateDialog from './ContactUpdateCreateDialog';

const getContactFromContactModel = (
  contact: Customer.AllianceCustomerContactTransport,
  serviceLocationsCount: number,
) => {
  let notification = 'None';
  if (contact.sendBillingEmails && contact.sendDispatchEmails) {
    notification = 'All';
  } else if (contact.sendBillingEmails) {
    notification = 'Billing';
  } else if (contact.sendDispatchEmails) {
    notification = 'Dispatch';
  }

  let notifyLocation = '';
  if (contact.locationPreferences) {
    const notifyLocations = contact.locationPreferences.length;
    if (serviceLocationsCount === 0) {
      notifyLocation = '';
    } else if (notifyLocations === serviceLocationsCount || notifyLocations === 0) {
      notifyLocation = 'All';
    } else {
      notifyLocation = `${notifyLocations} / ${serviceLocationsCount}`;
    }
  }

  return {
    contact: contact,
    type: contact.primaryContact,
    name: `${contact.firstName} ${contact.lastName}`,
    email: contact.email,
    phone: contact.phoneNumber,
    department: contact.department,
    notification: notification,
    notifyLocations: notifyLocation,
    notes: contact.notes,
  };
};

type Contact = {
  contact: Customer.AllianceCustomerContactTransport;
  type?: boolean;
  name?: string;
  email?: string;
  phone?: string;
  department?: string;
  notification?: string;
  notifyLocations?: string;
  notes?: string;
  lastLogin?: Date;
  actions?: undefined; // needs no data
};
interface ContactsListTableProps {
  customer: Customer.AllianceCustomerTransport;
  addContact?: boolean;
  onAddingContact?: () => void;
}

export const ContactsListTable = ({ customer, addContact, onAddingContact }: ContactsListTableProps) => {
  const client = useWaysteClient();
  const serviceLocationsCount = customer.serviceLocations?.length ?? 0;
  const [data, setData] = useState<Contact[]>([]);
  const { showFlash } = useFlash();
  const { getConfirmation } = useConfirmationDialog();

  const [showEdit, setShowEdit] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Customer.AllianceCustomerContactTransport | null>(null);
  const [profiles, setProfiles] = useState<Profile.ProfileTransport[]>([]);
  useEffect(() => {
    console.log('customer', customer);
    if (customer.id) {
      client
        .profile()
        .fetchProfilesByCustomerId(customer.id)
        .then((profiles: Profile.ProfileTransport[]) => {
          console.log(profiles);
          setProfiles(profiles);
        })
        .catch((err: Error) => console.log(err));
    }

    console.log('customer', customer);
  }, [customer]);

  const columnHelper = createColumnHelper<Contact>();
  const columns: ColumnDef<Contact, any>[] = [
    columnHelper.accessor('type', {
      header: 'Type',
      cell: (info) => (info.getValue() ? <StarIcon className="h-5 w-5 m-1" /> : <></>),
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      cell: (info) => <div className="max-w-[200px] min-w-[100px] overflow-x-auto">{info.getValue()}</div>,
    }),
    columnHelper.accessor('email', {
      header: 'Email',
      cell: (info) => <div className="min-w-fit">{info.getValue()}</div>,
    }),
    columnHelper.accessor('phone', {
      header: 'Phone',
      cell: (info) => (
        <a href={`tel:${info.getValue() || ''}`} className="no-underline text-wayste-blue-400 min-w-[100px]">
          {formatE164ToUSPhoneNumber(info.getValue())}
        </a>
      ),
    }),
    columnHelper.accessor('department', {
      header: 'Department',
      cell: (info) => departmentTypes[info.getValue() as keyof typeof departmentTypes] ?? '',
    }),

    columnHelper.accessor('notification', {
      header: 'Notification',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('notifyLocations', {
      header: 'Notify Location',
      cell: (info) =>
        //@ts-expect-error this is a weird null check
        info?.row?.original?.contact?.locationPreferences?.length > 0 ? (
          <Tooltip
            variant="light"
            text={
              <div>
                {info.row.original.contact.locationPreferences?.map((location) => {
                  return <div>{location}</div>;
                }) ?? ''}
              </div>
            }
          >
            {info.getValue()}
          </Tooltip>
        ) : (
          info.getValue()
        ),
    }),
    columnHelper.accessor('lastLogin', {
      header: 'Last Login',
      cell: (info) => (
        <div className="w-[75px] overflow-x-auto">
          {info.row.original.contact.id &&
            formatISODateString(
              profiles
                .find((profile) => {
                  return profile.authorities.find((authority) => authority.contactID === info.row.original.contact.id);
                })
                ?.lastLogin?.substring(0, 10),
              'MM/dd/yyyy',
              '',
            )}
        </div>
      ),
    }),
    columnHelper.accessor('notes', {
      header: 'Notes',
      cell: (info) => <div className="overflow-x-auto">{info.getValue()}</div>,
    }),
    columnHelper.accessor('actions', {
      header: 'Actions',
      cell: (info) => (
        <div className="flex gap-1 h-5 overflow-hidden w-full justify-between">
          <button
            className="h-5 w-5"
            onClick={() => {
              setShowEdit(true), setSelectedContact(info.row.original.contact);
            }}
          >
            <PencilIcon />
          </button>
          <button
            className="h-5 w-5"
            onClick={info.row.original.type ? undefined : () => deleteContact(info.row.original.contact)}
          >
            <XMarkIcon color={info.row.original.type ? '#A3A3A5' : '#DC2626'} />
          </button>
        </div>
      ),
    }),
  ];

  const deleteContact = async (contact: Customer.AllianceCustomerContactTransport | null) => {
    if (!contact) {
      return;
    }
    const confirmed = await getConfirmation({
      title: 'Delete Contact?',
      message:
        'Are you sure you want to delete this contact? This action cannot be undone. All associated data will be lost.',
      confirmText: `Delete ${contact.firstName} ${contact.lastName}`,
      cancelText: 'Cancel',
    });
    if (!confirmed) {
      showFlash('Contact was not deleted', 'success');
      return;
    }
    try {
      await client.customer().adminPortal.contact.delete(customer.id, contact.id);
      showFlash('Deleted Contact', 'success');
    } catch (error) {
      showFlash('An Error Occurred Deleting Contact', 'warning');
    } finally {
      setShowEdit(false);
    }
  };

  const updatePrimaryContact = async (customer: any, newPrimaryContact: any) => {
    const oldPrimaryContact = customer.contacts.find((contact: any) => contact.primaryContact);
    if (oldPrimaryContact) {
      console.log('oldPrimaryContact', oldPrimaryContact);
      oldPrimaryContact.primaryContact = false;
      try {
        const response = await client
          .customer()
          .adminPortal.contact.update(customer.id, oldPrimaryContact.id, oldPrimaryContact);
        console.log('response', response);
        showFlash('Updated Contact', 'success');
      } catch (error) {
        console.log('error', error);
        showFlash('An Error Occurred Updating Contact', 'warning');
      }
    }
    try {
      const response = await client.customer().adminPortal.contact.update(customer.id, newPrimaryContact.id, {
        ...newPrimaryContact,
        primaryContact: true,
      });
      console.log('response', response);
      showFlash('Updated Contact', 'success');
    } catch (error) {
      console.log('error', error);
      showFlash('An Error Occurred Updating Contact', 'warning');
    }
  };

  const createNewContact = async (customerId: string, contact: any) => {
    console.log('contact', contact);
    try {
      const response = await client.customer().adminPortal.contact.create(customerId, nullableFields(contact, 'email'));
      console.log('response', response);
      showFlash('Updated Contact', 'success');
    } catch (error) {
      console.log('error', error);
      showFlash('An Error Occurred Updating Contact', 'warning');
    }
  };

  const onEditSubmit = async (contact: FieldValues) => {
    if (contact.id) {
      if (contact.primaryContact) {
        const updatePromise = updatePrimaryContact(customer, nullableFields(contact, 'email'));
        const updateContactPromise = client
          .customer()
          .adminPortal.contact.update(customer.id, contact.id, nullableFields(contact, 'email'));

        try {
          await Promise.all([updatePromise, updateContactPromise]);
          showFlash('Updated Contact', 'success');
          setShowEdit(false);
        } catch (error) {
          console.log('error', error);
          showFlash('An Error Occurred Updating Contact', 'warning');
        }
      } else {
        try {
          const response = await client
            .customer()
            .adminPortal.contact.update(customer.id, contact.id, nullableFields(contact, 'email'));
          console.log('response', response);
          showFlash('Updated Contact', 'success');
          setShowEdit(false);
        } catch (error) {
          console.log('error', error);
          showFlash('An Error Occurred Updating Contact', 'warning');
        }
      }
    } else {
      if (contact.primaryContact) {
        const updatePromise = updatePrimaryContact(customer, nullableFields(contact, 'email'));
        const createPromise = createNewContact(customer.id, nullableFields(contact, 'email'));

        try {
          await Promise.all([updatePromise, createPromise]);
          showFlash('Created Contact', 'success');
          setShowEdit(false);
        } catch (error) {
          console.log('error', error);
          showFlash('An Error Occurred Updating Contact', 'warning');
        }
      } else {
        try {
          console.log('contact', contact);
          const response = await client
            .customer()
            .adminPortal.contact.create(customer.id, nullableFields(contact, 'email'));
          console.log('response', response);
          showFlash('Created Contact', 'success');
          setShowEdit(false);
        } catch (error) {
          console.log('error', error);
          showFlash('An Error Occurred Creating Contact', 'warning');
        }
      }
    }
  };

  useEffect(() => {
    if (addContact) {
      onAddContact();
    }
  }, [addContact]);

  const onAddContact = () => {
    setSelectedContact(null);
    setShowEdit(true);
    if (onAddingContact) {
      onAddingContact();
    }
  };

  const updateData = (updatedCustomer: Customer.AllianceCustomerTransport) => {
    setData(
      (updatedCustomer.contacts?.map((contact) =>
        getContactFromContactModel(contact, serviceLocationsCount),
      ) as Contact[]) ?? [],
    );
  };

  useEffect(() => {
    updateData(customer);
  }, [customer]);

  useEffect(() => undefined, [selectedContact]);
  return (
    <>
      <ContactUpdateCreateDialog
        open={showEdit}
        customer={customer}
        contact={selectedContact}
        onCancel={() => setShowEdit(false)}
        onDelete={() => deleteContact(selectedContact)}
        onSubmit={onEditSubmit}
      />
      <TanDataGrid data={data} columns={columns} className="-mt-4 -mb-5 -ml-5 -mr-5" />
    </>
  );
};

export const ContactsListCard = ({ customer }: { customer: Customer.AllianceCustomerTransport }) => {
  const [addContact, setAddContact] = useState(false);
  return (
    <DetailsCardWrapper
      heading="Contacts"
      buttons={[
        {
          label: (
            <>
              <PlusIcon className="h-5 w-5 mr-1" /> Add Contact
            </>
          ),
          onClick: () => setAddContact(true),
        },
      ]}
    >
      <ContactsListTable customer={customer} addContact={addContact} onAddingContact={() => setAddContact(false)} />
    </DetailsCardWrapper>
  );
};
export default ContactsListCard;
