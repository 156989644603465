///////////////////////////////////////////////////////
// INVOICE SUMMARY MODAL
//////////////////////////////////////////////////////
import React, { useState } from 'react';
import { SourFilters, SourSearch, SourSearchWrapper } from '@wayste/sour-search';
import { Button, Checkbox, Loading } from '@wayste/sour-ui';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Dialog from '../../../components/Dialog';
import { useAuthToken } from '../../../hooks/authhook';

const InvoiceSummaryModal = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
  ///////////////////////////////////////////////////////
  // STATE
  //////////////////////////////////////////////////////

  const { token } = useAuthToken();
  const [includeInvoices, setIncludeInvoices] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<string>();
  const [loading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  const [search, setSearch] = useState<string>('');

  // DEFINE ON NAVIGATE LOGIC
  const onNavigate = (entity: any, recordID: string) => {
    setSearch(entity?.contacts?.[0]?.firstName + ' ' + entity?.contacts?.[0]?.lastName);
    setCustomerId(recordID);
  };

  ////////////////////////////////////////////////////////
  // FUNCTION SECTION
  //////////////////////////////////////////////////////

  const fetchSummary = async (id: string, includeInvoices: boolean) => {
    const response = await fetch(
      `${import.meta.env.VITE_BASE_API_URL}/pdf/admin-portal/invoice-summary`,
      //`http://localhost:3000/pdf/admin-portal/invoice-summary`,
      {
        method: 'POST',
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerID: id,
          includeInvoices: includeInvoices,
        }),
      },
    );
    const blob = await response.blob();

    if (blob) {
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = includeInvoices ? 'invoice-summary.zip' : 'invoice-summary.pdf';
      document.body.appendChild(a);
      a.click();
    }

    setIsLoading(false);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage(undefined);
    if (!customerId) return;

    try {
      setIsLoading(true);
      await fetchSummary(customerId, includeInvoices);
    } catch (error) {
      alert('An error has occurred, please try again or contact dev.');
      setMessage('An error has occurred, please try again or contact dev.');
    }
    setIsLoading(false);
  };

  ///////////////////////////////////////////////////////
  // RENDER SECTION
  //////////////////////////////////////////////////////
  return (
    <Dialog
      className="w-[100%] overflow-visible"
      styledTitle="Download Invoice Summary"
      open={open}
      onClose={() => handleClose()}
    >
      <form onSubmit={onSubmit} className="flex flex-col gap-2 pt-2 justify-between h-[90%]">
        <div className="py-2">
          {search ? (
            <div className="p-1 flex flex-row justify-between items-center h-6">
              <div>{search}</div>
              <Button
                onClick={() => {
                  setSearch('');
                }}
              >
                <XMarkIcon className="w-4 h-4" />
              </Button>
            </div>
          ) : (
            <div>
              <SourSearchWrapper
                onNavigate={onNavigate}
                highlight={true}
                defaultFilters={{
                  query: {
                    type: 'sourgum-customer',
                  },
                }}
                size={10}
                options={{
                  application: 'aap',
                  apiKey: import.meta.env.VITE_ELASTIC_KEY,
                  environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                }}
              >
                <div className="flex flex-row justify-between space-x-4 w-full">
                  <SourSearch
                    options={{
                      searchPopoverFixed: true,
                      showTips: false,
                      showMessages: false,
                      resultSize: 'w-[700px]',
                      searchPositionFixed: false,
                      placeholder: 'Search Customer',
                    }}
                  />
                  <div className="hidden">
                    <SourFilters />
                  </div>
                </div>
              </SourSearchWrapper>
            </div>
          )}
        </div>

        <div>
          <div>
            <Checkbox
              label="Include Individual Invoices"
              inputProps={{
                checked: includeInvoices,
                onChange: (e) => {
                  setIncludeInvoices(e.currentTarget.checked);
                },
              }}
            />
          </div>
          <br />
          <div>{message}</div>
          <div>
            <button className="btn-primary w-full" type="submit">
              Download
              {loading && <Loading className="text-white" size="h-4 w-4 ml-2" />}
            </button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default InvoiceSummaryModal;
