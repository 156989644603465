import axios from 'axios';

export type LEAD_QUOTE_RO = {
  emailTemplate: 'LEAD_QUOTE_RO';
  first_name: string;
  csa_name: string;
  dumpster_size: string | number;
  material: string;
  service_location: string;
  quote_total: string | number;
  rental_period: string | number;
  overage_fee: string | number;
  rental_extension_fee: string | number;
  toEmail: string;
  price_type: string;
  special_material_string?: string;
  custom_message?: string;
  max_rental_days?: string | number;
  ton_limit?: string | number;
  quote_url?: string;
};

export type LEAD_FOLLOW_UP_RO = {
  emailTemplate: 'LEAD_FOLLOW_UP_RO';
  first_name: string;
  dumpster_size: string | number;
  quote_total: string | number;
  csa_name: string;
  toEmail: string;
  quote_url?: string;
  custom_message?: string;
};

export type ORDER_CONFIRMATION_RO = {
  emailTemplate: 'ORDER_CONFIRMATION_RO';
  first_name: string;
  toEmail: string;
  service_location: string;
  delivery_date: string;
  delivery_instructions: string;
  material: string;
  dumpster_size: string | number;
  weight_limit: string;
  rental_period: string | number;
  rental_extension_fee: string | number;
  order_total: string | number;
  csa_name: string;
  prohibited_items_string: string;
  overage_fee?: string | number;
  custom_message?: string;
  stripe_invoice_link?: string;
  pickup_date?: string;
  ccEmails?: string[];
  payment_link?: string;
};

export type DO_NOT_SERVICE_AREA = {
  emailTemplate: 'DO_NOT_SERVICE_AREA';
  first_name: string;
  toEmail: string;
  csa_name: string;
  service_location: string;
  service_type: 'roll-off dumpster rentals' | 'residential recurring pickup service';
};

export type RO_DELIVERY_TOMORROW = {
  emailTemplate: 'RO_DELIVERY_TOMORROW';
  toEmail: string;
  first_name: string;
  delivery_date: string;
  service_location: string;
  csa_name: string;
  ccEmails: string[] | [];
  today?: 'Today' | '' | null;
};

export type RO_READY_PICKUP = {
  emailTemplate: 'RO_READY_PICKUP';
  toEmail: string;
  ccEmails: string[] | [];
  first_name: string;
  csa_name: string;
  prohibited_items_list: string;
  order_number: string | number;
  service_location: string;
  pickup_date: string;
  pickup_notes: string;
};

export type RO_READY_PICKUP_SWAP = {
  emailTemplate: 'RO_READY_PICKUP_SWAP';
  toEmail: string;
  ccEmails: string[] | [];
  first_name: string;
  csa_name: string;
  prohibited_items_list: string;
  service_location: string;
  pickup_date: string;
  delivery_notes: string;
  dumpster_size: string | number;
  material: string;
  weight_limit?: string;
  rental_period: string | number;
  overage_fee?: string | number | null;
  price_type: string | number;
  order_total: string | number;
  rental_extension_fee: string | number;
};

export type RO_CHANGES_EMAIL = {
  emailTemplate: 'RO_CHANGES_EMAIL';
  toEmail: string;
  ccEmails: string[] | [];
  first_name: string;
  csa_name: string;
  order_number: string | number;
  service_location: string;
  changes_block: any;
};

export type INVOICE_RECEIPT = {
  emailTemplate: 'INVOICE_RECEIPT';
  toEmail: string;
  ask_for_review: boolean;
  first_name: string;
  receipt_invoice: 'Receipt' | 'Invoice';
  order_number: string | number;
  ccEmails: string[] | [];
  attachments:
    | {
        content: string;
        type: string;
        filename: string;
        disposition: 'inline' | 'attachment';
        content_id?: string;
      }[]
    | [];
  over_weight_limit_by?: number | string | null;
  invoice_number: string;
  custom_message?: string;
  payment_link?: string | null;
  review_link?: string | null;
};

export type INVOICE_INTERMEDIARY = {
  emailTemplate: 'INVOICE_INTERMEDIARY';
  toEmail: string;
  first_name: string;
  receipt_invoice: 'Receipt' | 'Invoice';
  order_number: string | number;
  ccEmails: string[] | [];
  attachments:
    | {
        content: string;
        type: string;
        filename: string;
        disposition: 'inline' | 'attachment';
        content_id?: string;
      }[]
    | [];
  invoice_number: string;
  payment_link?: string | null;
};

export const sendDynamicEmail = async (
  data:
    | LEAD_QUOTE_RO
    | LEAD_FOLLOW_UP_RO
    | ORDER_CONFIRMATION_RO
    | DO_NOT_SERVICE_AREA
    | RO_DELIVERY_TOMORROW
    | RO_READY_PICKUP
    | RO_READY_PICKUP_SWAP
    | RO_CHANGES_EMAIL
    | INVOICE_RECEIPT
    | INVOICE_INTERMEDIARY,
) => {
  const url = import.meta.env.VITE_SENDGRID_URL + 'sap-to-sendgrid';
  console.log('data: ', data);
  try {
    const res = await axios({
      method: 'POST',
      url: url,
      data: JSON.stringify(data),
    });
    if (res.status !== 200 || !res.data?.statusCode || res.data?.statusCode !== 200) {
      console.warn('sendEmail !== 200 status error: ', res);
      alert('There was an error sending the email');
      return { status: 'error' };
    }
    return { status: 'success' };
  } catch (error) {
    console.warn('axios sendEmail Error: ', error);
    alert('There was an error sending the email');
    return { status: 'error' };
  }
};
