import { useEffect, useState } from 'react';
import { Hauler, Material, Order, Pricing } from '@alliance-disposal/transport-types';
import { WaysteActivity } from '@wayste/utils';
import { HaulerPricingWithHauler } from '../OrderStatusChanger/OrderAssignHauler';
import { OtherVendorDialog } from './OtherVendorDialog';
import { SpreadTableRow } from './SpreadTableRow';

export type VendorPricingRow = Pricing.PricingTransport & {
  hauler: Hauler.HaulerWithAapTransport;
  waysteUsage: WaysteActivity;
  rowRates?: {
    materialData: Pricing.PricingDataTransport;
    sizeData: {
      dump?: number | null | undefined;
      dumpDollars?: number | null | undefined;
      over?: number | null | undefined;
      overDollars?: number | null | undefined;
      haul?: number | undefined;
      haulDollars?: number | undefined;
      tonLimit?: number | null | undefined;
      size: string;
    };
    haul: number | undefined;
    dump: number;
    overage: number;
    estProfit: number;
  };
};

interface Props {
  haulerWithPricing: HaulerPricingWithHauler[];
  sourgumPricing: {
    haul: number;
    dump: number;
    tonLimit: number;
    overage: number;
  };
  selectedMaterial: Material;
  selectedSize?: string | number;
  onRowClick?: (haulerPricing: VendorPricingRow, pricingSnapshot?: Order.VendorPricingSnapshotTransportCreate) => void;
  selectedHauler?: string;
  orderID?: string;
}

const SpreadsTable = ({
  haulerWithPricing,
  sourgumPricing,
  selectedMaterial,
  selectedSize,
  onRowClick,
  selectedHauler,
  orderID,
}: Props) => {
  const [haulerList, setHaulerList] = useState<VendorPricingRow[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedHaulerID, setSelectedHaulerID] = useState<string | undefined>(undefined);
  const getPricingDataFromHaulerPricing = (): Order.VendorPricingRow[] => {
    const pricingData = haulerList.flatMap((vendorPricingRow) => {
      if (vendorPricingRow.rowRates && vendorPricingRow.rowRates.haul) {
        return {
          vendorID: vendorPricingRow.id,
          vendorName: vendorPricingRow.haulerName ? vendorPricingRow.haulerName : '',
          priceType: vendorPricingRow?.rowRates?.materialData?.doesNotService
            ? 'Does not service'
            : vendorPricingRow?.rowRates?.materialData?.type,
          haulRate: vendorPricingRow.rowRates.sizeData.haul || 0,
          haulSpread: sourgumPricing?.haul - vendorPricingRow.rowRates.haul,
          dumpRate: vendorPricingRow.rowRates.sizeData.dump ? vendorPricingRow.rowRates.sizeData.dump : 0,
          dumpSpread: sourgumPricing?.dump - +vendorPricingRow.rowRates.dump,
          overageSpread: sourgumPricing?.overage - vendorPricingRow.rowRates.overage,
          usingWayste: Boolean(vendorPricingRow.waysteUsage),
          waysteLite: vendorPricingRow.hauler.waysteLite,
        } satisfies Order.VendorPricingRow;
      }
      return [];
    });

    return pricingData;
  };

  const calculateEstimatedProfit = (sizeData?: any) => {
    if (!sizeData) return null;
    const haulSpread = sourgumPricing?.haul - sizeData.haul;
    const tonLimitDelta = sourgumPricing?.tonLimit - sizeData.tonLimit;
    const haulerAdjTonLimit = tonLimitDelta >= 0 ? sourgumPricing?.tonLimit : sizeData.tonLimit;
    const estProfit = haulSpread + (sourgumPricing.dump * sourgumPricing.tonLimit - sizeData.dump * haulerAdjTonLimit);
    return estProfit;
  };

  useEffect(() => {
    if (!selectedMaterial || !selectedSize || !haulerWithPricing) {
      setHaulerList(haulerWithPricing?.sort((a, b) => ((a.haulerName || '') > (b.haulerName || '') ? 1 : -1)) || []);
      return;
    }

    const completeHaulerList: VendorPricingRow[] = haulerWithPricing.map((haulerPricing2) => {
      let rowRates = undefined;
      const materialData = haulerPricing2.pricingData.find((item) => item.material === selectedMaterial);
      if (materialData && !materialData?.doesNotService) {
        const sizeData = materialData.sizes.find((size) => +size.size === +selectedSize);
        if (sizeData) {
          const profit = calculateEstimatedProfit(sizeData);
          if (profit !== null) {
            rowRates = {
              materialData,
              sizeData,
              haul: sizeData.haul,
              dump: sizeData.dump || 0,
              overage: sizeData.over || sizeData.dump || 0,
              estProfit: profit,
            };

            // If they are using wayste and are profitable, add $10 to their profit
            if (Boolean(haulerPricing2.waysteUsage) && rowRates.estProfit > 0) {
              rowRates.estProfit += 1000;
            }
          }
        }
      }
      const pricing: VendorPricingRow = { ...haulerPricing2, rowRates };
      return pricing;
    });
    const sortedList = completeHaulerList.sort((a, b) => {
      const estProfitA = a?.rowRates?.estProfit || a?.rowRates?.estProfit === 0 || -1000;
      const estProfitB = b?.rowRates?.estProfit || b?.rowRates?.estProfit === 0 || -1000;
      return estProfitA > estProfitB ? -1 : 1;
    });
    setHaulerList(sortedList);
  }, [haulerWithPricing, selectedMaterial, selectedSize, sourgumPricing]);

  return (
    <div className="w-full overflow-x-auto flex flex-col gap-5">
      {onRowClick && orderID && selectedHaulerID && (
        <OtherVendorDialog
          open={open}
          setOpen={setOpen}
          haulerPricing={haulerList}
          sourgumPricing={sourgumPricing}
          selectedHaulerID={selectedHaulerID}
          orderID={orderID}
          onSubmit={(data) => {
            const selectedHaulerPricing = haulerList.find((haulerPricing) => haulerPricing.id === selectedHaulerID);
            if (!selectedHaulerPricing) return;
            onRowClick(selectedHaulerPricing, data);
            setOpen(false);
          }}
        />
      )}
      {haulerList && haulerList.length > 0 && (
        <div className="border p-4 rounded-md shadow-sm">
          <h2 className="font-bold text-lg">Recommended Vendor:</h2>
          <table className="w-full border-collapse border-spacing-0 text-sm font-semibold">
            <thead>
              <tr className="[&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal text-left border-b">
                <th className="!p-0" />
                <th>Vendor</th>
                <th>Price Type</th>
                <th>Haul Rate</th>
                <th>Dump Rate</th>
                <th>Haul Spread</th>
                <th>Dump Spread</th>
                <th>Overage Spread</th>
                <th>Est Profit</th>
              </tr>
            </thead>
            <tbody>
              <SpreadTableRow
                key={haulerList[0].id}
                haulerPricing={haulerList[0]}
                selectedMaterial={selectedMaterial}
                sourgumPricing={sourgumPricing}
                selectedSize={selectedSize}
                onRowClick={
                  onRowClick
                    ? () => {
                        if (!orderID) return;
                        setSelectedHaulerID(haulerList[0].id);
                        onRowClick(haulerList[0], {
                          pricingData: getPricingDataFromHaulerPricing(),
                          chosenVendorID: haulerList[0].id,
                        });
                      }
                    : undefined
                }
                selectedHauler={selectedHauler}
              />
            </tbody>
          </table>
        </div>
      )}
      <div>
        Other Vendors:
        <table className="w-full border-collapse border-spacing-0 text-sm">
          <thead>
            <tr className="[&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal text-left border-b">
              <th className="!p-0" />
              <th>Vendor</th>
              <th>Price Type</th>
              <th>Haul Rate</th>
              <th>Dump Rate</th>
              <th>Haul Spread</th>
              <th>Dump Spread</th>
              <th>Overage Spread</th>
              <th>Est Profit</th>
            </tr>
          </thead>
          <tbody>
            {haulerList.map((item, index) => (
              <SpreadTableRow
                key={item.id}
                haulerPricing={item}
                selectedMaterial={selectedMaterial}
                sourgumPricing={sourgumPricing}
                selectedSize={selectedSize}
                onRowClick={
                  onRowClick
                    ? () => {
                        // If they select the most profitable vendor, no need to open the dialog
                        setSelectedHaulerID(item.id);
                        onRowClick?.(item, {
                          pricingData: getPricingDataFromHaulerPricing(),
                          chosenVendorID: item.id,
                        });
                        if (index !== 0) {
                          setOpen(true);
                        }
                      }
                    : undefined
                }
                selectedHauler={selectedHauler}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SpreadsTable;
