import React, { useEffect } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { Loading } from '@wayste/sour-ui';
import { buildAddressString } from '@wayste/utils';
import { US_PHONE_NUMBER_REGEXP, formatE164ToUSPhoneNumber, formatUSPhoneNumberToE164 } from '@wayste/utils';
import { FaceFrownIcon, FaceSmileIcon } from '@heroicons/react/24/outline';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import structuredClone from '@ungap/structured-clone';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import * as Yup from 'yup';
import { FaceNeutralIcon } from '../../assets/icons/misc';
import {
  CheckBoxField,
  PhoneNumberField,
  channelTypes,
  commercialLocationTypes,
  customerMotivations,
  customerTypes,
  customerTypesEnums,
  departmentTypes,
  paymentMethods,
  paymentMethodsEnums,
  paymentTerms,
  paymentTermsEnums,
  statesAbb,
} from '../../utils';
import { accountExecutives, accountManagers } from '../../utils/shared-types';
import InputAddress from '../InputAddress';

const CustomerSchema = Yup.object().shape({
  companyName: Yup.string().nullable(),
  notes: Yup.string(),
  billingAddress: Yup.object().shape({
    addressLine1: Yup.string().required('Address 1 is required'),
    addressLine2: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().oneOf(statesAbb).required('State is required'),
    zip: Yup.string()
      .matches(/^\d{5}(-\d{4})?$/, 'Must be a valid zip code')
      .required('Zip is required'),
  }),
  credit: Yup.number().moreThan(-1),
  taxExempt: Yup.bool(),
  channel: Yup.string().oneOf(Object.keys(channelTypes)),
  type: Yup.string()
    .oneOf(customerTypes)
    .required('A customer type, customerMotivations, commercialLocationTypes is required'),
  motivation: Yup.string().oneOf([...Object.keys(customerMotivations), '']),
  businessType: Yup.string().oneOf(Object.keys(commercialLocationTypes)).nullable(),
  overallExperience: Yup.string(),
  contacts: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().when('primaryContact', {
        is: true,
        then: Yup.string().required('Last name is required on primary contact'),
      }),
      phoneNumber: Yup.string()
        .when('primaryContact', {
          is: true,
          then: Yup.string().required('A phone number is required on primary contact'),
        })
        .matches(US_PHONE_NUMBER_REGEXP, 'Must be a valid phone number, e.g. (555) 867-5309'),
      email: Yup.string()
        .email()
        .when('primaryContact', {
          is: true,
          then: Yup.string().required('An email is required on primary contact'),
        }),
      department: Yup.string().oneOf([...Object.keys(departmentTypes), '']),

      locationPreferences: Yup.array(),

      primaryContact: Yup.bool().test('oneOfRequired', 'One of the contacts must be the primary contact', function () {
        //@ts-expect-error this is a test function??
        return this.from[1]?.value.contacts.filter((contact: any) => contact.primaryContact).length === 1;
      }),
      sendDispatchEmails: Yup.bool(),
      sendBillingEmails: Yup.bool(),
      notes: Yup.string(),
    }),
  ),
  reviewed: Yup.bool().nullable(),
  doNotService: Yup.bool().nullable(),
  defaultPaymentSettings: Yup.object().shape({
    paymentTerm: Yup.string().oneOf(Object.keys(paymentTerms)),
    paymentMethod: Yup.string().oneOf(Object.keys(paymentMethods)),
    allowedPaymentMethod: Yup.array().of(Yup.string().oneOf(Object.keys(paymentMethods))),
  }),
});

const blankContact = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  department: '',
  locationPreferences: [],
  primaryContact: false,
  sendBillingEmails: false,
  sendDispatchEmails: false,
  notes: '',
};

export const prepareFormData = (data: Customer.AllianceCustomerTransport) => {
  console.log('prep data: ', data);
  const newData = structuredClone(data);
  newData.credit = newData.credit / 100 || 0;
  if (newData.contacts && newData.contacts.length > 0) {
    newData.contacts = newData.contacts.map((item) => ({
      ...item,
      firstName: item.firstName || '',
      lastName: item.lastName || '',
      email: item.email?.toLowerCase().trim() || '',
      notes: item.notes || '',
      locationPreferences: item.locationPreferences || [],
      department: item.department || '',
      sendBillingEmails: item.sendBillingEmails || false,
      sendDispatchEmails: item.sendDispatchEmails || false,
      primaryContact: item.primaryContact || false,
      phoneNumber: item.phoneNumber ? formatE164ToUSPhoneNumber(item.phoneNumber) : '',
    }));
  }

  return newData;
};

export interface CustomerFormProps {
  customer?: Customer.AllianceCustomerTransport;
  onCancel: () => void;
  onSubmit: (values: Customer.AllianceCustomerTransport) => void;
  isLoading: boolean;
}

const CustomerForm = ({ customer, onCancel, onSubmit, isLoading }: CustomerFormProps) => {
  const handleSubmit = (data: Customer.AllianceCustomerTransport) => {
    const newData = {
      ...data,
      credit: +data.credit,
    };
    if (newData.contacts && newData.contacts.length > 0) {
      newData.contacts = newData.contacts.map((item) => ({
        ...item,
        phoneNumber: item.phoneNumber ? formatUSPhoneNumberToE164(item.phoneNumber) : '',
      }));
    }

    console.log('newData: ', newData);

    onSubmit(newData);
  };

  return (
    <Formik
      initialValues={
        customer
          ? prepareFormData(customer)
          : ({
              companyName: '',
              overallExperience: 'Neutral',
              notes: '',
              billingAddress: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: '',
              },
              dbModal: {} as any,
              taxExempt: false,
              channel: '',
              type: '',
              motivation: '',
              stripeId: '',
              businessType: null,
              contacts: [blankContact],
              reviewed: false,
              credit: 0,
              serviceLocations: [],
              defaultPaymentSettings: {
                allowedPaymentMethod: [paymentMethodsEnums.creditCard],
                paymentMethod: paymentMethodsEnums.creditCard,
                paymentTerm: paymentTermsEnums.onCharge,
              },
            } as any)
      }
      validationSchema={CustomerSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        return (
          <Form>
            <CustomerFormFields isLoading={isLoading} customer={customer as any} onCancel={onCancel} formik={formik} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CustomerForm;

/**
 * All necessary fields to create or update Customer
 * @param {Function} setFieldValue Formik builtin function to update a field value
 * @param {Object} values Formik builtin object for form values
 * @param {Object} [customer] Existing customer object
 * @param {Boolean} [disabled] If true disables all fields
 * @param {Boolean} [isLoading] If subForm is false and isLoading is true replaces submit with loading
 * @param {Function} [onCancel] If subForm is false function for when cancel button is clicked
 * @param {Boolean} [showSameAs] If true shows the object to set billing address the same as service
 * @param {Boolean} [subForm] Is the form within a form (true) or is it standalone (false)
 */

export interface CustomerFormFieldsProps {
  formik: any;
  customer?: Customer.AllianceCustomerTransport;
  disabled?: boolean;
  isLoading?: boolean;
  onCancel?: () => void;
  showSameAs?: boolean;
  subForm?: boolean;
  onEmailBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  lockFields?: boolean;
}

export const CustomerFormFields = ({
  formik,
  customer,
  disabled,
  isLoading,
  onCancel,
  showSameAs,
  subForm,
  onEmailBlur,
  lockFields,
}: CustomerFormFieldsProps) => {
  useEffect(() => {
    formik.values.primaryContact = formik.values.contacts.find(
      (contact: Customer.AllianceCustomerContactTransport) => contact.primaryContact,
    );
  }, [formik.values.contacts]);

  const { setFieldValue, values } = formik;
  const handleExperienceClick = (value: string) => {
    setFieldValue('overallExperience', value);
  };

  useEffect(() => {
    // Access the errors object from formikProps
    const { errors } = formik;

    // Print the errors object
    console.log('Formik Errors:', errors);
  }, [formik.errors]);

  return (
    <Grid container spacing={3}>
      <>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Typography variant="h6">Contact Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            id="companyName"
            name="companyName"
            label="Company"
            component={TextField}
            value={values.companyName || ''}
            margin="dense"
            disabled={disabled || lockFields}
            fullWidth
            autoComplete="no"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            id="notes"
            name="notes"
            label="Notes"
            labelId="Notes-label"
            style={{ height: 'auto' }}
            component={TextField}
            margin="dense"
            disabled={disabled}
            multiline
            fullWidth
          />
        </Grid>
        {/* contacts */}
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Typography variant="h6">Contacts</Typography>
        </Grid>
        <FieldArray
          name="contacts"
          render={(arrayHelpers) => (
            <>
              {values.contacts.map((_: any, index: number) => (
                <Grid item xs={12}>
                  <ContactFields
                    onEmailBlur={onEmailBlur}
                    customer={customer as any}
                    key={index}
                    index={index}
                    onRemove={() => arrayHelpers.remove(index)}
                    disabled={disabled}
                    formik={formik}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <button
                  className="btn-primary-text-only"
                  onClick={() => arrayHelpers.push(blankContact)}
                  disabled={disabled}
                  type="button"
                >
                  <PlusCircleIcon className="h-5 w-5 pr-1" /> Add Contact
                </button>
              </Grid>
            </>
          )}
        />
        {customer ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Customer Experience</Typography>
            </Grid>
            <Grid item xs={12} className="flex gap-4">
              <FaceSmileIcon
                className={`h-6 w-6 cursor-pointer ${
                  values.overallExperience === 'Positive' ? 'text-success' : 'text-[#D8D8D8]'
                }`}
                onClick={() => handleExperienceClick('Positive')}
              />
              <FaceNeutralIcon
                className={`h-6 w-6 cursor-pointer ${
                  values.overallExperience === 'Neutral' ? 'text-[#F0810F]' : 'text-[#D8D8D8]'
                }`}
                onClick={() => handleExperienceClick('Neutral')}
              />
              <FaceFrownIcon
                className={`h-6 w-6 cursor-pointer ${
                  values.overallExperience === 'Negative' ? 'text-delete' : 'text-[#D8D8D8]'
                }`}
                onClick={() => handleExperienceClick('Negative')}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="reviewed"
                label="Reviewed Us"
                checked={values.reviewed}
                component={CheckBoxField}
                margin="dense"
              />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Typography variant="h6">Billing Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="doNotService"
            label="Do Not Service"
            checked={values.doNotService}
            component={CheckBoxField}
            margin="dense"
            disabled={disabled}
            color="success"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="credit"
            label="Account credit"
            component={TextField}
            margin="dense"
            disabled={disabled || !customer}
            type="number"
            min="0"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="taxExempt"
            label="Tax exempt"
            checked={values.taxExempt}
            component={CheckBoxField}
            margin="dense"
            disabled={disabled}
          />
        </Grid>
        {customer ? (
          <>
            <Grid item xs={6}>
              <Field
                name="defaultPaymentSettings.paymentTerm"
                component={Select}
                disabled={disabled}
                label="Default payment term"
                formControl={{ fullWidth: true, size: 'small' }}
              >
                {Object.entries(paymentTerms).map((item) => (
                  <MenuItem key={`paymentTerm-${item[0]}`} value={item[0]}>
                    {item[1]}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="defaultPaymentSettings.paymentMethod"
                component={Select}
                disabled={disabled}
                label="Default payment method"
                formControl={{ fullWidth: true, size: 'small' }}
              >
                {Object.entries(paymentMethods).map((item) => (
                  <MenuItem key={`paymentMethod-${item[0]}`} value={item[0]}>
                    {item[1]}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="defaultPaymentSettings.allowedPaymentMethod"
                component={Select}
                disabled={disabled}
                label="Allowed payment method"
                formControl={{ fullWidth: true, size: 'small' }}
                inputProps={{
                  multiple: true,
                }}
              >
                {Object.entries(paymentMethods).map((item) => (
                  <MenuItem
                    key={`paymentMethod-${item[0]}`}
                    value={item[0]}
                    disabled={item[0] === paymentMethodsEnums.creditCard}
                  >
                    {item[1]}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </>
        ) : null}
        {showSameAs ? (
          <Grid item xs={12}>
            <Field
              name="sameAsService"
              label="Billing address is same as service"
              checked={values.sameAsService}
              component={CheckBoxField}
              margin="dense"
              disabled={disabled}
            />
          </Grid>
        ) : null}
        {!showSameAs || (showSameAs && !values.sameAsService) ? (
          <Grid item xs={12}>
            <InputAddress
              name="billingAddress"
              disabled={Boolean(disabled)}
              google
              onSelect={(obj: any) => {
                setFieldValue('billingAddress', {
                  addressLine1: '',
                  addressLine2: '',
                  city: '',
                  state: '',
                  zip: '',
                  ...obj.address,
                });
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            {buildAddressString(values.billingAddress)}
          </Grid>
        )}
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Typography variant="h6">Marketing Details</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="channel"
            component={Select}
            label="Marketing Channel"
            labelId="channel-select"
            formControl={{ fullWidth: true, size: 'small', required: true }}
            disabled={disabled}
            inputProps={{
              id: 'channel',
            }}
          >
            {Object.entries(channelTypes).map((item) => (
              <MenuItem key={`channel-${item[0]}`} value={item[0]}>
                {item[1]}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="type"
            component={Select}
            disabled={disabled}
            label="Customer Type"
            labelId="type-label"
            formControl={{ fullWidth: true, size: 'small', required: true }}
            inputProps={{
              id: 'type',
            }}
          >
            {customerTypes.map((item) => (
              <MenuItem key={`type-${item}`} value={item}>
                {customerTypesEnums[item]}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="motivation"
            component={Select}
            disabled={disabled}
            label="Motivation"
            labelId="motivation-label"
            formControl={{ fullWidth: true, size: 'small' }}
          >
            {Object.entries(customerMotivations).map((item) => (
              <MenuItem key={`motivation-${item[0]}`} value={item[0]}>
                {item[1]}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          {formik.values.type === customerTypesEnums.business ? (
            <Field
              name="businessType"
              component={Select}
              disabled={disabled}
              value={values.businessType == null ? '' : values.businessType}
              label="businessType"
              labelId="businessType-label"
              formControl={{ fullWidth: true, size: 'small' }}
            >
              {Object.entries(commercialLocationTypes).map((item) => (
                <MenuItem key={`businessType-${item[0]}`} value={item[0]}>
                  {item[1]}
                </MenuItem>
              ))}
            </Field>
          ) : null}
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="accountRepID"
            component={Select}
            disabled={disabled}
            value={values.accountRepID == null ? '' : values.accountRepID}
            label="Account Executive"
            labelId="accountRepID-label"
            formControl={{ fullWidth: true, size: 'small' }}
          >
            {accountExecutives.map((profile) => (
              <MenuItem key={'accountRepID' + profile.id} value={profile.id}>
                {profile.firstName + ' ' + profile.lastName}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="accountManagerID"
            component={Select}
            disabled={disabled}
            value={values.accountManagerID == null ? '' : values.accountManagerID}
            label="Account Manager"
            labelId="accountManagerID-label"
            formControl={{ fullWidth: true, size: 'small' }}
          >
            {accountManagers.map((profile) => (
              <MenuItem key={'accountManagerID' + profile.id} value={profile.id}>
                {profile.firstName + ' ' + profile.lastName}
              </MenuItem>
            ))}
          </Field>
        </Grid>

        {subForm ? null : (
          <Grid item xs={12} style={{ marginTop: 54 }}>
            <div className="flex justify-end gap-4">
              <button className="btn-dark-grey-outlined" onClick={onCancel} disabled={isLoading} type="button">
                Cancel
              </button>
              <button className="btn-primary" type="submit" disabled={isLoading || disabled || !formik.dirty}>
                {customer ? 'Update' : 'Create'}
                {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
              </button>
            </div>
          </Grid>
        )}
      </>
    </Grid>
  );
};

export interface ContactFieldsProps {
  index?: number;
  onRemove?: () => void;
  disabled?: boolean;
  formik: any;
  onEmailBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  customer: Customer.AllianceCustomerTransport;
}

const ContactFields = ({ index = 0, onRemove, disabled, formik, onEmailBlur, customer }: ContactFieldsProps) => {
  const contacts = formik.values.contacts;
  // const serviceLocations = formik.values.serviceLocations;
  const contact = contacts[index];
  const { setFieldValue } = formik;

  const handlePrimaryContactChange = (
    event: React.SyntheticEvent<Element, Event>,
    contact: Customer.CustomerContactTransport,
    contacts: Customer.CustomerContactTransport[],
  ) => {
    for (let index = 0; index < contacts.length; index++) {
      if (contacts[index] === contact) {
        setFieldValue(`contacts[${index}].primaryContact`, true);
        setFieldValue(`contacts[${index}].sendBillingEmails`, true);
        setFieldValue(`contacts[${index}].sendDispatchEmails`, true);
        formik.values.primaryContact = contact;
      } else {
        setFieldValue(`contacts[${index}].primaryContact`, false);
      }
    }
  };

  const handleEmailBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    formik.handleBlur(event);
    if (onEmailBlur) {
      onEmailBlur(event);
    }
  };

  const handleNotifyLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.includes('All')) {
      setFieldValue(`contacts[${index}].locationPreferences`, []);
    } else {
      setFieldValue(`contacts[${index}].locationPreferences`, value);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Field
          id={`contacts[${index}].firstName`}
          name={`contacts[${index}].firstName`}
          label="First name"
          component={TextField}
          margin="dense"
          disabled={disabled}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          id={`contacts[${index}].lastName`}
          name={`contacts[${index}].lastName`}
          label="Last name"
          component={TextField}
          margin="dense"
          disabled={disabled}
          fullWidth
          required={Boolean(contact.primaryContact)}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          id={`contacts[${index}].phoneNumber`}
          name={`contacts[${index}].phoneNumber`}
          label="Phone"
          component={PhoneNumberField}
          margin="dense"
          fullWidth
          disabled={disabled}
          required={Boolean(contact.primaryContact)}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          id={`contacts[${index}].email`}
          name={`contacts[${index}].email`}
          label="Email"
          component={TextField}
          margin="dense"
          disabled={disabled || (Boolean(customer) && contact.id)}
          fullWidth
          required={Boolean(contact.primaryContact)}
          InputProps={{
            onBlur: Boolean(contact.primaryContact) ? handleEmailBlur : undefined,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name={`contacts[${index}].department`}
          component={Select}
          disabled={disabled}
          label="Department"
          labelId={`contacts[${index}].department-label`}
          formControl={{ fullWidth: true, size: 'small' }}
          inputProps={{
            id: `contacts[${index}].department`,
          }}
        >
          {Object.entries(departmentTypes).map((item) => (
            <MenuItem key={`department-${item[0]}`} value={item[0]}>
              {item[1]}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      {/* MULTI SELECT */}
      <Grid item xs={12}>
        <div className="max-w-xl">
          <Field
            component={Select}
            placeholder="All"
            type="text"
            label="Notify Locations"
            name={`contacts[${index}].locationPreferences`}
            formControl={{ fullWidth: true, size: 'small', placeholder: 'all' }}
            disabled={disabled}
            multiple={true}
            value={contacts[index].locationPreferences || []}
            onChange={handleNotifyLocationChange}
          >
            <MenuItem value={'All'}>All</MenuItem>
            {formik.values.serviceLocations.map((location: Customer.ServiceLocationTransport, index: number) => {
              return (
                <MenuItem key={index} value={buildAddressString(location.address)}>
                  {buildAddressString(location.address)}
                </MenuItem>
              );
            })}
          </Field>
        </div>
      </Grid>

      <Grid item xs={6}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Field name={`contacts[${index}].primaryContact`}>
              {({ field }: { field: any }) => (
                <FormControlLabel
                  control={
                    <div>
                      <Checkbox
                        {...field}
                        checked={Boolean(contact.primaryContact)}
                        disabled={disabled || !contact.email}
                      />
                    </div>
                  }
                  disabled={disabled || !contact.email}
                  onChange={(e: React.SyntheticEvent<Element, Event>) =>
                    handlePrimaryContactChange(e, contact, contacts)
                  }
                  label="Primary Contact"
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field
              name={`contacts[${index}].sendDispatchEmails`}
              label="CC Dispatch Emails"
              checked={contact.sendDispatchEmails}
              component={CheckBoxField}
              margin="dense"
              disabled={disabled || !contact.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name={`contacts[${index}].sendBillingEmails`}
              label="CC Billing Emails"
              checked={contact.sendBillingEmails}
              component={CheckBoxField}
              margin="dense"
              disabled={disabled || !contact.email}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Field
          name={`contacts[${index}].notes`}
          label={`Notes for contact ${index + 1}`}
          component={TextField}
          margin="dense"
          style={{ height: 'auto' }}
          disabled={disabled}
          fullWidth
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <button
          className="ml-auto btn-base text-delete"
          type="button"
          onClick={onRemove}
          disabled={disabled || formik.values.contacts.length <= 1 || contact.primaryContact}
        >
          <XMarkIcon className="h-5 w-5 pr-1" /> remove
        </button>
      </Grid>
    </Grid>
  );
};
