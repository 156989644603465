import { Hauler, Pricing } from '@alliance-disposal/transport-types';
import UniversalServicePricingList from './UniversalServicePricingList';

type Props = {
  onEditClicked: () => void;
  onServiceAreaSubmit: (data: any, zone: any) => void;
  handleServicesUpdate: () => void;
  isLoading: boolean;
  pricing: Pricing.ProductPricingZoneTransport[];
  vendor: Hauler.HaulerWithAapTransport;
  rollOffPricing: Pricing.PricingTransport[];
  onRefreshPricing: () => void;
};

/**
 * Gets and displays all pricing for hauler broken down by county
 * @param {String} haulerID ID of the hauler you want pricing for
 * @param {Function} onEditClicked Action to take on edit pricing
 * @param {Function} onAddMaterialClicked Run function on Add Material Pricing clicked
 * @param {Function} onEditMaterialClicked Run function on edit material icon clicked
 * @param {Function} onServiceAreaSubmit Run function on service area submit
 * @param {Function} handleServicesUpdate Run function on service area submit
 * @param {Boolean} isLoading Is the data loading
 * @param {Array} pricing Array of pricing objects
 * @param {Object} vendor Vendor object
 * @param {Function} onRefreshPricing Run function on refresh pricing
 */
const UniversalPricingListContainer = ({
  onEditClicked,
  isLoading,
  pricing,
  vendor,
  onServiceAreaSubmit,
  handleServicesUpdate,
  onRefreshPricing,
  rollOffPricing,
}: Props) => {
  return (
    <UniversalServicePricingList
      vendor={vendor}
      pricing={pricing}
      rollOffPricing={rollOffPricing}
      isLoading={isLoading}
      onEditClicked={onEditClicked}
      onServiceAreaSubmit={onServiceAreaSubmit}
      onRefreshPricing={onRefreshPricing}
      handleServicesUpdate={handleServicesUpdate}
    />
  );
};

export default UniversalPricingListContainer;
