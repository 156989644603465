import { useEffect, useState } from 'react';
import { AddressTransport, ServiceLocationTransport } from '@alliance-disposal/transport-types';
import { AddressSubForm } from '@wayste/sour-components';
import { Button, Radio } from '@wayste/sour-ui';
import { formatServiceAddress } from '@wayste/utils';
import { useFormContext, useWatch } from 'react-hook-form';
import { statesAbb } from '../../utils';
import GoogleStreetView from '../GoogleStreetView';

// Types
type GooglePlacesResponse = {
  address: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    zip: string;
  };
  coordinates: {
    lat: number;
    // lang: number;
    lng: number;
  } | null;
  county?: string;
};

interface NewAddressOptions {
  value: string;
  label: string;
  obj?: GooglePlacesResponse;
}

// Constants
const trimmedAddressListLength = 5;

const newAddressOptions: NewAddressOptions[] = [
  {
    value: 'new',
    label: 'Enter new address',
    obj: {
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
      },
      coordinates: null,
      county: '',
    },
  },
];

const newDisplayAddressOptions: NewAddressOptions[] = [{ value: 'new', label: 'Enter new address', obj: undefined }];

interface ServiceAddressSubFormProps {
  fieldName: string;
  showCounty?: boolean;
}

const ServiceAddressSubForm = ({ fieldName, showCounty }: ServiceAddressSubFormProps) => {
  // State
  const [addressRadio, setAddressRadio] = useState('new');
  const [addressOptions, setAddressOptions] = useState<NewAddressOptions[]>(newAddressOptions);
  const [displayAddressOptions, setDisplayAddressOptions] = useState(newDisplayAddressOptions);

  // Form constants
  const { setValue } = useFormContext();
  const serviceLocations: ServiceLocationTransport[] = useWatch({ name: `customer.serviceLocations` });
  const addressUseWatch: AddressTransport = useWatch({ name: `${fieldName}.address` });

  // Hooks
  useEffect(() => {
    if (serviceLocations) {
      const newAddressOptions: NewAddressOptions[] = [];

      serviceLocations.forEach((location) => {
        const addressString = formatServiceAddress(location.address);
        setAddressRadio('new');

        if (location?.coordinates) {
          newAddressOptions.push({
            value: addressString,
            label: addressString,
            obj: { ...location, coordinates: location.coordinates },
          });
        }
      });
      const sortedAddresses = newAddressOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
      const allOptions = [
        {
          value: 'new',
          label: 'Enter new address',
          obj: {
            address: {
              addressLine1: '',
              addressLine2: '',
              city: '',
              state: '',
              zip: '',
            },
            coordinates: null,
            county: '',
          },
        },
        ...sortedAddresses,
      ];
      setAddressOptions(allOptions);

      if (sortedAddresses.length <= trimmedAddressListLength) {
        setDisplayAddressOptions(allOptions);
      } else {
        setDisplayAddressOptions(allOptions.filter((_, index) => index <= trimmedAddressListLength));
      }
    }
  }, [serviceLocations]);

  // Functions
  const onServiceLocationChange = (option: NewAddressOptions) => {
    setAddressRadio(option.value);
    setValue(`${fieldName}.address`, option.obj?.address);
    setValue(`${fieldName}.coordinates`, option.obj?.coordinates);
    setValue(`${fieldName}.county`, option.obj?.county);
  };

  const handleServiceAddressLength = () => {
    if (displayAddressOptions.length === addressOptions.length) {
      setDisplayAddressOptions(addressOptions.filter((_, index) => index <= trimmedAddressListLength));
    } else {
      setDisplayAddressOptions(addressOptions);
    }
  };

  return (
    <div>
      <div>
        <div className="form-control">
          <div role="group" aria-labelledby="address-radio-options">
            {displayAddressOptions.map((option) => (
              <label key={option.value} className="flex items-center space-x-2 my-2">
                <Radio
                  inputProps={{
                    value: option.value,
                    checked: addressRadio === option.value,
                    onChange: () => {
                      onServiceLocationChange(option);
                    },
                  }}
                />
                <span>{option.label}</span>
              </label>
            ))}
          </div>
          {addressOptions.length >= trimmedAddressListLength && (
            <div className="mt-2">
              <Button className="btn-primary-text-only" onClick={handleServiceAddressLength}>
                Show {displayAddressOptions.length === addressOptions.length ? 'Fewer' : 'All'} Addresses
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="flex space-x-2">
        <div className="w-1/2">
          <AddressSubForm
            statesList={statesAbb}
            apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
            fieldName={fieldName}
            showCounty={showCounty}
            type={'location'}
          />
        </div>
        <div className="w-1/2">
          <GoogleStreetView address={addressUseWatch} />
        </div>
      </div>
    </div>
  );
};

export default ServiceAddressSubForm;
