import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Draggable from 'react-draggable';

interface Props {
  onClose: () => void;
  pricing: any;
}

const ZoneNotes = ({ onClose, pricing }: Props) => {
  return (
    <Draggable cancel=".no-drag">
      <div className="bg-white shadow-dark rounded absolute p-3 z-[99] max-w-[500px] w-screen">
        <div className="flex justify-between items-center">
          <h6 className="text-xl font-medium">Zone Notes</h6>
          <button className="btn-icon no-drag" onClick={onClose} type="button">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <hr className="mb-3" />
        {pricing?.days ? (
          <div>
            <div className="mb-1 text-black opacity-60">Operating Days</div>
            <div className="flex flex-wrap">
              {Object.keys(pricing?.days).map((key) => (
                <div>
                  <div className="mr-2.5 mb-1 capitalize">
                    <b>{key}</b>
                  </div>
                  <div>{pricing?.days[key] || '?'}</div>
                </div>
              ))}
            </div>
            <hr className="mb-3 mt-1" />
          </div>
        ) : null}
        {pricing?.prohibitedItems ? (
          <div
            onClick={() => {
              // copy to clipboard
              navigator.clipboard.writeText(pricing.prohibitedItems.join(', '));
            }}
          >
            <div className="mb-1 text-black opacity-60">
              Prohibited Items <span className="text-xs">(click to copy)</span>
            </div>
            <div className="cursor-pointer flex flex-wrap">{pricing.prohibitedItems.join(', ')}</div>
            <hr className="mb-3 mt-1" />
          </div>
        ) : null}
        {pricing?.notes || 'No Notes for this Zone'}
      </div>
    </Draggable>
  );
};

export default ZoneNotes;
