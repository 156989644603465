import React from 'react';
import type { Customer, Pricing } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';

export type DeletePricingZoneProps = {
  customerDiscount: Pricing.CustomerDiscountTransport;
  customer?: Customer.AllianceCustomerTransport;
};

const DeleteCustomPricingModal = ({
  handleClose,
  handleDeletePricingZone,
  customerDiscount,
  customer,
}: {
  handleClose: () => void;
  handleDeletePricingZone: (args: { customerDiscountID: string }) => Promise<void>;
  customerDiscount?: Pricing.CustomerDiscountTransport;
  customer?: Customer.AllianceCustomerTransport;
}) => {
  const onConfirmDelete = () => {
    if (!customerDiscount?.id) return;
    handleDeletePricingZone({
      customerDiscountID: customerDiscount.id,
    });
    handleClose();
  };
  const RenderDialogContent = () => {
    if (!customerDiscount?.id) return null;
    return (
      <div>
        <span>
          Are you sure you want to delete the{' '}
          <span style={{ fontWeight: 'bold' }}>{customerDiscount.pricingZone?.zoneName}</span> custom pricing zone for{' '}
          <span style={{ fontWeight: 'bold' }}>
            {customer?.contacts[0].firstName + ' ' + customer?.contacts[0].lastName}
          </span>
          ?
        </span>
      </div>
    );
  };
  return (
    <Dialog
      open={Boolean(customerDiscount?.id)}
      onClose={handleClose}
      showX
      variant="delete"
      styledTitle="Delete Pricing Zone"
    >
      <RenderDialogContent />
      <div className="flex justify-end gap-4 mt-4">
        <button className="btn-dark-grey-outlined" onClick={handleClose}>
          Cancel
        </button>
        <button onClick={onConfirmDelete} className="btn-delete">
          Delete Pricing
        </button>
      </div>
    </Dialog>
  );
};

export default DeleteCustomPricingModal;
