import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Pricing } from '@alliance-disposal/transport-types';
import { round } from '@wayste/utils';
import { UIContext } from '../../contexts';
import Dialog from '../Dialog';
import MaterialPricingForm from '../MaterialPricingForm';

export interface MaterialPricingDialogProps {
  open: boolean;
  zone: Pricing.PricingTransport;
  material: any;
  onBackButtonClick: (data: any) => void;
  isHauler?: boolean;
}
const MaterialPricingDialog = ({
  open,
  material,
  zone,
  onBackButtonClick,
  isHauler = false,
}: MaterialPricingDialogProps) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const payload: Pricing.SGMutateMaterial = {
        effectiveDate: new Date().toISOString(),
        expirationDate: undefined,
        details: {
          id: data.id || undefined,
          allowOnlineCheckout: data.allowOnlineCheckout,
          allowForLowerTonLimit: data.allowForLowerTonLimit,
          doesNotService: data.doesNotService,
          lastUpdatedAt: `${new Date()}`,
          haulerApproved: undefined,
          sourgumApproved: true,
          effectiveDate: undefined,
          expirationDate: undefined,
          material: data.material,
          tax: data.tax,
          type: data.type,
          sizes: data.sizes.map((size: any) => {
            return {
              size: size.size,
              tonLimit: +size.tonLimit,
              haul: Math.round(round(size.haul ? +size.haul : 0) * 100),
              dump: Math.round(round(size.dump ? +size.dump : 0) * 100),
              over: Math.round(round(size.over ? +size.over : 0) * 100),
            };
          }),
        },
      };

      await client.pricing().adminPortal.material.update(zone.id, payload);
      showFlash(`Material ${material ? 'Updated' : 'Created'} Successfully`, 'success');
      onBackButtonClick({ status: 'success' });
    } catch (error) {
      showFlash('An error occurred, get an AAP dev', 'warning');
      onBackButtonClick({ status: 'error' });
    }

    setIsLoading(false);
  };

  return (
    <Dialog
      open={open}
      className="max-w-4xl"
      styledTitle={`${material ? 'Update' : 'Create'} Material Pricing`}
      onClose={(e) => onBackButtonClick(e)}
    >
      {zone ? (
        <MaterialPricingForm
          zone={zone}
          material={material}
          onSubmit={handleSubmit}
          onCancel={(e: any) => onBackButtonClick(e)}
          isLoading={isLoading}
          isHauler={isHauler}
        />
      ) : (
        <div />
      )}
    </Dialog>
  );
};

export default MaterialPricingDialog;
