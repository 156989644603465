import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Hauler, Invoice, Order } from '@alliance-disposal/transport-types';
import { recalculateInvoiceTotal } from '@wayste/utils';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../../contexts';
import { routes } from '../../../utils';
import TransactionCreate from '../components/TransactionCreate/';
import BillPayment from './BillPayment';
import InvoiceSend from './InvoiceSend';
import InvoiceUpdate from './InvoiceUpdate';

interface Props {
  orderID: string;
}

const InvoicesDetails = ({ orderID }: Props) => {
  const client = useWaysteClient();
  const history = useHistory();
  const [selectedOrder, setSelectedOrder] = useState<Order.AllianceOrderTransport | null>(null);
  const [customer, setCustomer] = useState<Customer.AllianceCustomerTransport | null>(null);
  const [payables, setPayables] = useState<Invoice.PayableTransport[]>([]);
  const [receivables, setReceivables] = useState<Invoice.ReceivableTransport[]>([]);

  // list of receivable and or payables that have change
  const [payablesChanges, setPayablesChanges] = useState<number[]>([]);
  const [receivablesChanges, setReceivablesChanges] = useState<number[]>([]);

  const hasChanges = payablesChanges.length + receivablesChanges.length > 0;

  const [selectedHauler, setSelectedHauler] = useState<Hauler.HaulerWithAapTransport | null>(null);

  const [selectedInvoice, setSelectedInvoice] = useState<Invoice.ReceivableTransport | null>(null);
  const [showCreateInvoice, setShowCreateInvoice] = useState(false);
  const [showTransactionCreate, setShowTransactionCreate] = useState(false);
  const [showBillPayment, setShowBillPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { showFlash } = useContext(UIContext);

  useEffect(() => {
    if (orderID) {
      fetchServiceOrderDetails(orderID);
    }
  }, [orderID]);

  const fetchServiceOrderDetails = async (orderID: string) => {
    setIsLoading(true);
    setSelectedOrder(null);
    setSelectedInvoice(null);
    setCustomer(null);
    setSelectedHauler(null);
    setReceivables([]);
    setPayables([]);

    const order = await client.order().adminPortal.fetch(orderID);

    if (!order) {
      throw new Error('Service Order not found');
    }

    // Get receivables, payables, and customer
    console.log('serviceOrder', order);
    try {
      const [receivablesResponse, payablesResponse, customerResponse] = await Promise.all([
        client.invoice().adminPortal.receivable.query({
          orderID,
        }),
        client.invoice().adminPortal.payable.query({
          orderID,
        }),
        client.customer().adminPortal.fetch(order.allianceCustomerID),
      ]);

      setReceivables(receivablesResponse);
      setPayables(payablesResponse);
      setCustomer(customerResponse);
      console.log('customerResponse', customerResponse);
      console.log('receivablesResponse', receivablesResponse);
      console.log('payablesResponse', payablesResponse);

      // SORT THEM  BY INVOICE NUMBER
      // order.invoices = order.invoices.sort((a, b) => (+a.invoiceNumber > +b.invoiceNumber ? 1 : -1));
      setSelectedOrder(order);
    } catch (error) {
      console.error(error, orderID);
      showFlash(
        'Something went wrong loading payables and receivables. Please refresh the page and try again.',
        'warning',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddPayment = async (view: 'invoice' | 'bill', haulerID?: string) => {
    if (view === 'invoice') {
      setShowTransactionCreate(true);
    } else if (view === 'bill') {
      if (!haulerID) {
        alert('Missing hauler id');
        return;
      }
      setIsLoading(true);
      const haulerResponse = await client.vendorService().fetchById(haulerID);
      setSelectedHauler(haulerResponse.data);
      setShowBillPayment(true);
      setIsLoading(false);
    }
  };

  const handleSetReceivable = (
    receivable: Invoice.ReceivableTransport,
    index?: number,
    options?: {
      isAPIResponse?: boolean;
    },
  ) => {
    const updatedReceivables = [...receivables];
    if (!options?.isAPIResponse) {
      receivable.invoiceDetails = recalculateInvoiceTotal(receivable.invoiceDetails);
    }
    if (index || index === 0) {
      updatedReceivables[index] = receivable;
    } else {
      // it's new
      index = receivables.length;
      updatedReceivables.push(receivable);
    }

    console.log('updatedReceivables: ', updatedReceivables);
    // return;
    setReceivables(updatedReceivables);

    if (options?.isAPIResponse) {
      // remove the index from the changes array
      setReceivablesChanges(Array.from(new Set([...receivablesChanges].filter((i) => i !== index))));
    } else {
      setReceivablesChanges(Array.from(new Set([...receivablesChanges, index])));
    }
  };

  const setPayable = (
    payable: Invoice.PayableTransport,
    index?: number,
    options?: {
      isAPIResponse?: boolean;
    },
  ) => {
    const updatedPayables = [...payables];

    if (!options?.isAPIResponse) {
      payable.invoiceDetails = recalculateInvoiceTotal(payable.invoiceDetails);
    }

    if (index || index === 0) {
      updatedPayables[index] = payable;
    } else {
      // it's new
      index = payables.length;
      updatedPayables.push(payable);
    }
    setPayables(updatedPayables);
    if (options?.isAPIResponse) {
      // remove the index from the changes array
      setPayablesChanges(Array.from(new Set([...payablesChanges].filter((i) => i !== index))));
    } else {
      setPayablesChanges(Array.from(new Set([...payablesChanges, index])));
    }
  };

  const handleRefresh = () => {
    setSelectedOrder(null);
    setSelectedInvoice(null);
    setCustomer(null);
    fetchServiceOrderDetails(orderID);
  };

  const handleTransactionSave = () => {
    setShowTransactionCreate(false);
    setShowBillPayment(false);
    setShowCreateInvoice(false);
    setSelectedInvoice(null);
    fetchServiceOrderDetails(orderID);
  };

  return (
    <div className="flex flex-1 max-h-screen overflow-y-hidden">
      <InvoiceUpdate
        selectedOrder={selectedOrder}
        customer={customer}
        payables={payables}
        setPayables={(payables) => {
          setPayables(payables);
        }}
        setReceivables={(receivables) => {
          setReceivables(receivables);
        }}
        setReceivable={handleSetReceivable}
        setPayable={setPayable}
        receivables={receivables}
        payablesChanges={payablesChanges}
        receivablesChanges={receivablesChanges}
        isLoading={isLoading}
        onAddPayment={handleAddPayment}
        onShowDetails={() => history.push(routes.orders.details(selectedOrder?.id || ''), { modal: true })}
        hasChanges={hasChanges}
        onCreateReceipt={(invoice) => {
          setSelectedInvoice(invoice);
          setShowCreateInvoice(true);
        }}
        handleRefresh={handleRefresh}
        onCancel={() => {
          setSelectedOrder(null);
          setSelectedInvoice(null);
          setCustomer(null);
          setReceivablesChanges([]);
          setPayablesChanges([]);

          if (orderID) {
            fetchServiceOrderDetails(orderID);
          }
        }}
        // onOrderSave={() => handleTransactionSave()}
      />
      {showCreateInvoice && selectedInvoice && customer && selectedOrder && (
        <InvoiceSend
          open={showCreateInvoice}
          customer={customer}
          onCancel={() => {
            setShowCreateInvoice(false);
            setSelectedInvoice(null);
          }}
          onSend={() => {
            handleTransactionSave();
          }}
          order={selectedOrder}
          receivable={selectedInvoice}
          receivables={receivables}
        />
      )}
      {showTransactionCreate && customer && (
        <TransactionCreate
          open={showTransactionCreate}
          onCancel={() => setShowTransactionCreate(false)}
          customer={customer}
          onSave={() => handleTransactionSave()}
        />
      )}
      <BillPayment
        open={showBillPayment}
        hauler={selectedHauler}
        onCancel={() => setShowBillPayment(false)}
        onSave={() => handleTransactionSave()}
        onOrderSelect={(order) => fetchServiceOrderDetails(order.id)}
      />
    </div>
  );
};

export default InvoicesDetails;
