import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Pricing, UniversalService } from '@alliance-disposal/transport-types';
import { Button, Checkbox, Dialog, Loading } from '@wayste/sour-ui';
import { UIContext } from '../../contexts/UIContext';

interface AddServicesToZoneModalProps {
  open: boolean;
  onClose: (open: boolean) => void;
  zoneID: string;
  handleServicesUpdate: () => void;
  vendorID?: string;
}

export const AddServiceToZoneDialog = ({
  open,
  onClose,
  zoneID,
  handleServicesUpdate,
}: AddServicesToZoneModalProps) => {
  //STATES
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState<UniversalService.ServiceTypeFamily[]>([]);
  const [zoneSkus, setZoneSkus] = useState<string[]>([]);
  const [pricingZone, setPricingZone] = useState<Pricing.ProductPricingZoneTransport | null>(null);

  ////////////////////////////////////////////
  // FUNCTION SECTION
  ////////////////////////////////////////////

  // HANDLE ASSIGN SERVICE TO ZONE
  const handleSave = async () => {
    try {
      setIsLoading(true);
      // Update Pricing zone with new line items that are applicable to the zone
      const updatePayload: Pricing.ProductPricingZoneUpdateTransport = {
        ...pricingZone,
        skuIDs: zoneSkus,
      };
      await client.universalService().pricingZone.update(zoneID, updatePayload);
      showFlash('Service Successfully Added to Zone', 'success');
      handleServicesUpdate();
      onClose(false);
    } catch (error) {
      showFlash('An Error Occurred', 'warning');
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    if (!zoneID) return;
    setIsLoading(true);
    try {
      // FETCH ALL AVAILABLE SERVICES AND SKUS
      const servicesResponse = await client.universalService().serviceFamily.query({ orderBy: 'name' });
      setServices(servicesResponse.results);
      // FETCH ZONE SERVICES
      const zoneServicesResponse = await client.universalService().pricingZone.fetch(zoneID);
      const zoneSkus = zoneServicesResponse.SKUs.flatMap((sku: Pricing.ProductPricingSKUTransport) => sku.skuID);
      setZoneSkus(zoneSkus);
      setPricingZone(zoneServicesResponse);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  ////////////////////////////////////////////
  // HOOKS SECTION
  ////////////////////////////////////////////

  useEffect(() => {
    fetchData();
  }, [zoneID]);

  ////////////////////////////////////////////
  // RENDER SECTION
  ////////////////////////////////////////////

  return (
    <Dialog open={open} onClose={() => onClose(false)} styledTitle="Add Services to Zone" className="max-w-4xl">
      {isLoading ? (
        <div className="p-4 container h-[50vh] flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <div className="my-5 h-[50vh] overflow-auto">
          {services.map((service) => {
            return (
              <div key={service.id}>
                <div className="flex flex-col items-left justify-center text-2xl">{service.name}</div>

                <div className="my-5 grid grid-cols-3 gap-x-10">
                  {service.serviceTypes
                    .filter((type) => type.active)
                    .map((type) => {
                      return (
                        <div key={type.id} className="px-5 py-2 w-full flex flex-row items-center justify-between">
                          <div className="w-full">{type.name}</div>
                          <div className="w-5 flex flex-row items-end justify-end">
                            <Checkbox
                              inputProps={{
                                id: type.id,
                                checked: zoneSkus.find((item) => item === type.id) ? true : false,
                                onChange: (e) => {
                                  if (e.target.checked) {
                                    setZoneSkus([...zoneSkus, type.id]);
                                  } else {
                                    setZoneSkus(zoneSkus.filter((item) => item !== type.id));
                                  }
                                },
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="flex justify-end gap-4">
        <Button className="mr-5 btn-dark-grey-outlined" onClick={() => onClose(false)} type="button">
          Cancel
        </Button>
        <Button
          className="btn-primary"
          disabled={isLoading}
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
};
