import { useEffect, useState } from 'react';
import { V1 as sourgumPricingFunctions } from '@alliance-disposal/pricing';
import { Pricing } from '@alliance-disposal/transport-types';
import { formatUSD, sortByKey } from '@wayste/utils';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { priceTypesEnums, routes } from '../../utils';

type Props = {
  haulersPricing: Pricing.PricingTransport[];
  material?: MaterialEnums | '' | null;
  onRowClick?: (pricing: any) => void;
  showZoneName?: boolean;
};

const headers = ['Zone Name', 'Hauler', 'Material', 'Price Type', 'Haul Rate', 'Dump Rate'];

const HaulersPricingTable = ({ haulersPricing, material = '', onRowClick, showZoneName = false }: Props) => {
  const [sorted, setSorted] = useState([]);

  useEffect(() => {
    const data: any = [];
    haulersPricing.forEach((pricing: any) => {
      if (material) {
        const found = pricing.materials[material];
        let normalizedMaterial = null;
        if (found) {
          normalizedMaterial = sourgumPricingFunctions.normalizeMaterialSize(found);
          normalizedMaterial.sizes = normalizedMaterial.sizes.map((item) => {
            return {
              ...item,
              originalType: item.type,
            };
          });
        }
        data.push({ ...pricing, material: normalizedMaterial });
      } else {
        pricing.pricingData.forEach((mat: any) => {
          const normalizedMat = sourgumPricingFunctions.normalizeMaterialSize({
            ...mat,
          });
          normalizedMat.sizes = normalizedMat.sizes.map((item) => {
            return {
              ...item,
              originalType: item.type,
            };
          });
          data.push({
            ...pricing,
            material: normalizedMat,
          });
        });
      }
    });
    setSorted(sortByKey('material', data));
  }, [haulersPricing, material]);

  return (
    <div className="flex-1 w-full overflow-x-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr>
            {headers.map((heading) => (
              <td className="px-4 py-1.5 border-0 border-b border-solid border-b-gray-200 text-sm" key={heading}>
                {heading}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {sorted.map((pricing: any) => (
            <HaulerRow
              key={pricing.id + (pricing?.material?.material || '')}
              pricing={pricing}
              material={pricing.material}
              onRowClick={onRowClick}
              showZoneName={showZoneName}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HaulersPricingTable;

type HaulerRowProps = {
  pricing: any;
  material: any;
  onRowClick?: (pricing: any) => void;
  showZoneName: any;
};

const HaulerRow = ({ pricing, material, onRowClick, showZoneName }: HaulerRowProps) => {
  const history = useHistory();

  const getHaulRateDisplay = () => {
    if (material?.doesNotService) return '';
    if (!material) return 'N/A';
    return Object.values(material.sizes).map((item: any) => (
      <div key={item.size}>
        <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}>{item.size}:</span> {formatUSD(item.haul / 100)}
        {material.originalType !== priceTypesEnums.flat ? (
          <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}>
            {' '}
            TL: <span style={{ color: 'rgba(0, 0, 0, 0.88)' }}>{item.tonLimit}</span>
          </span>
        ) : null}
        {', '}
      </div>
    ));
  };

  const getDumpRateDisplay = () => {
    if (material?.doesNotService) return '';
    if (!material) return 'N/A';
    return Object.values(material.sizes).map((item: any) => (
      <div key={item.size}>
        <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}>{item.size}:</span> {formatUSD(item.dump / 100)}
        {', '}
      </div>
    ));
  };

  return (
    <tr
      className={`${Boolean(onRowClick) ? 'hover:bg-slate-100 cursor-pointer' : ''} ${
        !pricing.hauler.active || material?.doesNotService ? 'bg-red-300' : ''
      }`}
      onClick={onRowClick ? () => onRowClick(pricing) : () => null}
    >
      {/* Zone Name */}
      {showZoneName ? (
        <td className="px-4 py-1.5 border-0 border-b border-solid border-b-gray-200 text-sm">{pricing.zoneName}</td>
      ) : null}
      {/* Hauler name */}
      <td className="px-4 py-1.5 border-0 border-b border-solid border-b-gray-200 text-sm">
        <Tooltip
          /* interactive AAP-671 tooltips are always interactive */
          placement="top-start"
          title={
            <div onClick={(e) => e.stopPropagation()}>
              <div>Notes: {`${pricing.notes} ${pricing.hauler.notes}`}</div>
              <div>Open Sat: {pricing.hauler?.hoursOfOperation?.sat?.start ? 'Yes' : 'No'}</div>
              <div>Sizes: {material ? material.sizes.map((size: any) => `${size.size}, `) : null}</div>
            </div>
          }
        >
          <span
            onClick={() => {
              if (!onRowClick) {
                history.push(routes.haulers.details(pricing.haulerID), {
                  modal: true,
                });
              }
            }}
          >
            {pricing.haulerName}
          </span>
        </Tooltip>
      </td>
      {/* If applicable material and and material type */}
      <td className="px-4 py-1.5 border-0 border-b border-solid border-b-gray-200 text-sm">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {material?.material || 'N/A'}
          {(!material.sourgumApproved || !pricing.sourgumApproved) && (
            <div style={{ marginLeft: 5 }}>
              <Tooltip title="Pricing has not been approved by Sourgum">
                <ExclamationTriangleIcon className="h-6 w-6 text-warning" />
              </Tooltip>
            </div>
          )}
        </div>
      </td>
      <td className="px-4 py-1.5 border-0 border-b border-solid border-b-gray-200 text-sm">
        {material?.doesNotService ? 'Does not service' : material?.type || 'N/A'}
      </td>
      {/* Haul rate or array of haul rates per size */}
      <td className="px-4 py-1.5 border-0 border-b border-solid border-b-gray-200 text-sm">{getHaulRateDisplay()}</td>
      {/* Dump rate */}
      <td className="px-4 py-1.5 border-0 border-b border-solid border-b-gray-200 text-sm">{getDumpRateDisplay()}</td>
    </tr>
  );
};
