import React, { useState } from 'react';
import { ArrowLeftOnRectangleIcon, Bars3Icon, BellIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import { NavLink, useHistory } from 'react-router-dom';
import { FullLogo } from '../assets/icons/logos';
import Paths from '../utils/routes';
import { routes } from './MenuRoutes';

/**
 * Side navigation top bar on mobile (< 1024px) displays
 * @param {*} onLogout Function to run when logout clicked
 */
const MobileToolbar = ({ onLogout }: { onLogout: () => void }) => {
  const history = useHistory();

  const [open, setOpen] = useState(false);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  return (
    <>
      {/* Toolbar */}
      <div className="flex">
        <div className="w-full flex justify-between items-center bg-sourgum-greyblue-900 text-white min-h-10 px-4">
          <button className="bg-transparent border-0 text-white p-3 leading-[0]" onClick={handleDrawerOpen}>
            <Bars3Icon className="h-6 w-6" />
          </button>
          <div onClick={() => history.push(Paths.dashboard)}>Sourgum Waste</div>
          <div className="text-white p-3 leading-[0]">
            <BellIcon className="h-6 w-6" />
          </div>
        </div>
      </div>
      {/* Drawer */}
      <div
        className={`${
          open ? 'translate-x-0' : '-translate-x-full'
        } transform transition-transform ease-in-out duration-200 fixed top-0 left-0 w-72 bg-sourgum-greyblue-900 text-white h-full z-[90] flex flex-col`}
      >
        <div className="bg-white py-2 px-4">
          <FullLogo size={38} />
        </div>
        <nav className="flex flex-col h-full py-2">
          <li
            className="flex items-center py-2 px-4 min-h-[48px] cursor-pointer"
            onClick={() => {
              history.push(Paths.leads.create, { modal: true });
              handleDrawerClose();
            }}
          >
            <div className="flex min-w-[56px] text-left">
              <PlusCircleIcon className="text-sourgum-greyblue-50 h-6 w-6" />
            </div>
            <span className="text-sourgum-greyblue-50">Create Lead</span>
          </li>
          {routes.map((route, index) => {
            const nextRouteIsGroup = routes[index + 1]?.subRoutes;

            if ('subRoutes' in route) {
              return (
                <div className={`flex flex-col`}>
                  <span
                    className={`mx-2 text-sourgum-greyblue-50 px-4 py-2 text-sm font-bold uppercase tracking-wider border-b`}
                  >
                    {open ? route.name : route.shortName}
                  </span>
                  {route.subRoutes?.map((subRoute) => {
                    return (
                      <NavLink
                        key={route.name + subRoute.text}
                        to={subRoute.path}
                        exact={subRoute.exact}
                        className={`text-sourgum-greyblue-50 no-underline ${open && 'ml-4'} transition-all`}
                        activeClassName="!text-sourgum-green-300"
                      >
                        <li className="flex items-center py-2 px-4 min-h-[48px] cursor-pointer">
                          <div className="flex min-w-[56px] text-left">
                            <div className="h-6 w-6">{subRoute.icon}</div>
                          </div>
                          <span className="text-inherit">{subRoute.text}</span>
                        </li>
                      </NavLink>
                    );
                  })}
                  {!nextRouteIsGroup && <div className={`border-b mx-2 transition-all`} />}
                </div>
              );
            } else {
              return (
                <NavLink
                  key={route.text}
                  to={route.path}
                  exact={route.exact}
                  className="text-sourgum-greyblue-50 no-underline"
                  activeClassName="text-sourgum-green-300"
                  onClick={handleDrawerClose}
                >
                  <li className="flex items-center py-2 px-4 min-h-[48px] cursor-pointer">
                    <div className="flex min-w-[56px] text-left">
                      <div className="h-6 w-6">{route.icon}</div>
                    </div>
                    <span className="text-inherit">{route.text}</span>
                  </li>
                </NavLink>
              );
            }
          })}
          <li
            className="flex items-center py-2 px-4 min-h-[48px] cursor-pointer mt-auto"
            onClick={() => {
              onLogout();
              handleDrawerClose();
            }}
          >
            <div className="flex min-w-[56px] text-left">
              <ArrowLeftOnRectangleIcon className="text-sourgum-greyblue-50 h-6 w-6" />
            </div>
            <span className="text-sourgum-greyblue-50">Sign Out</span>
          </li>
        </nav>
      </div>
      {/* </Drawer> */}
      {/* Overlay */}
      <div
        className={`${open ? 'block' : 'hidden'} fixed inset-0 bg-opacity-50 bg-black z-[80]`}
        onClick={handleDrawerClose}
      />
    </>
  );
};

export default MobileToolbar;
