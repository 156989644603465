import { useEffect, useState } from 'react';
import type { Pricing } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';

export type DeletePricingZoneProps = {
  open: boolean;
  onClose: () => void;
  handleDelete: () => void;
  pricingZone: Pricing.PricingTransport | undefined;
};

const DeletePricingZoneModal = ({ handleDelete, open, onClose, pricingZone }: DeletePricingZoneProps) => {
  // STATE
  const [openLocal, setOpen] = useState(false);

  /////////////////////////////////////////////////////
  // HANDLER SECTION
  /////////////////////////////////////////////////////

  const onConfirmDelete = () => {
    handleDelete();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  /////////////////////////////////////////////////////
  // HOOKS SECTION
  /////////////////////////////////////////////////////

  useEffect(() => {
    setOpen(open);
  }, [open]);

  /////////////////////////////////////////////////////
  // RENDER SECTION
  /////////////////////////////////////////////////////

  return (
    <Dialog open={openLocal} onClose={handleClose} showX variant="delete" styledTitle="Delete Pricing Zone">
      <div>
        <span>
          Are you sure you want to delete this pricing zone{' '}
          <span style={{ fontWeight: 'bold' }}>{pricingZone?.zoneName}</span>? If there are any linked zones they will
          also be deleted.
        </span>
      </div>
      <div className="flex justify-end gap-4 mt-4">
        <button className="btn-dark-grey-outlined" onClick={handleClose}>
          Cancel
        </button>
        <button onClick={onConfirmDelete} className="btn-delete">
          Delete Pricing Zone
        </button>
      </div>
    </Dialog>
  );
};

export default DeletePricingZoneModal;
