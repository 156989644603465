import { Customer, Hauler, Order, Profile, ServiceLocationTransport } from '@alliance-disposal/transport-types';
import { formatServiceAddress, getDateFormat, getDispatchEmailsString, getPrimaryCustomerContact } from '@wayste/utils';
import { formatUSD } from '@wayste/utils';
import { buildAddressString, formatE164ToUSPhoneNumber } from '@wayste/utils';
import { format } from 'date-fns';
import { OrderStatus, aggregateMaterialKeys, materialEnums, materials, noReplyEmail, officeTel } from './shared-types';

export const createWeightLimitString = (values: { [key: string]: any }) => {
  if (values.weightLimit || values.weightLimit === 0) {
    return `${values.weightLimit} tons`;
  } else if (aggregateMaterialKeys.indexOf(values.material) > -1 && +values.expectedSize >= 20) {
    return 'You may only fill the dumpster 3/4 of the way. If filled to the top we may refuse pickup or charge an overfilled fee.';
  } else {
    return 'N/A';
  }
};

export const createAsbestosOnlyString = (values: { [key: string]: any }) => {
  if (values.material === materialEnums.asbestos || values.material === materialEnums.asbestosNonFriable) {
    return 'Asbestos must be sealed in a dumpster liner or in approved bags. ONLY fill the container with asbestos. No other material may be placed into the dumpster. Doing so could result in a denial of pickup or extra fees.';
  }
  return '';
};

export const createProhibitedItemList = (material: MaterialEnums, prohibitedItemString: string | undefined | null) => {
  switch (material) {
    case 'transportation':
      return 'This is a transportation only load.';
    case 'scrap':
      return 'Only scrap metal may be placed into the dumpster. Dirt, trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
    case 'creosote':
      return 'Only creosote may be placed into the dumpster. Dirt, trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
    case 'asbestos':
    case 'asbestosNonFriable':
      return 'Asbestos must be sealed in a dumpster liner or in approved bags. Only asbestos may be placed into the dumpster. Trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
    case 'cardboard':
      return 'Only cardboard may be placed into the dumpster. Trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
    case 'singleStream':
      return 'Only recyclables may be placed into the dumpster. Trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
    case 'dirt':
      return 'Only dirt may be placed into the dumpster. Trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
    case 'asphalt':
      return 'Only asphalt may be placed into the dumpster. Dirt, trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
    case 'comix':
      return 'Only concrete and brick may be placed into the dumpster. Dirt, trash, concrete slurry and other debris may NOT be placed in the dumpster. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
    case 'concrete':
    case 'concreteRebar':
      return 'Only clean concrete may be placed into the dumpster. Dirt, trash, concrete slurry and other debris may NOT be placed in the dumpster. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
    case 'bNB':
    case 'bNL':
    case 'bNS':
      return 'Only organic yard waste may be placed into the dumpster. Dirt, trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
    default:
      return (
        'Placing prohibited items into the dumpster will result in additional fees or refusal of pickup unless otherwise noted in writing. Prohibited items include: ' +
        (prohibitedItemString ||
          'Aerosol cans, Dirt, Fluorescent tubes, Flammable liquids, Animals, Paint (except completely dried or empty), Hazardous waste, Lead paint chips, Appliances containing Freon, Herbicides & pesticides, Propane tanks, Asbestos, Industrial waste, Radioactive material, Lubricating/hydraulic oil, Railroad ties, Batteries, Medical waste, Solvents, Chemicals, Televisions, Monitors, Tires, gasoline and other hazardous objects.')
      );
  }
};

export const createLeadEmailMaterialString = (material: string) => {
  switch (material) {
    case 'scrap':
      return 'Please note only scrap metal can go into the dumpster, no other debris are allowed in.';
    case 'creosote':
      return 'Please note only creosote can go into the dumpster, no other debris are allowed in.';
    case 'asbestos':
    case 'asbestosNonFriable':
      return 'Please note only asbestos may be placed into the dumpster, no other debris are allowed in. Asbestos also must be properly bagged or you must use a dumpster liner.';
    case 'cardboard':
      return 'Please note only cardboard can go into the dumpster, no other debris are allowed in.';
    case 'singleStream':
      return 'Please note only recyclables can go into the dumpster, no other debris are allowed in.';
    case 'dirt':
      return 'Please note only dirt can go into the dumpster, no other debris are allowed in.';
    case 'asphalt':
      return 'Please note only asphalt can go into the dumpster, no other debris are allowed in.';
    case 'comix':
      return 'Please note only concrete and brick can go into the dumpster, no other debris or concrete slurry are allowed in.';
    case 'concrete':
    case 'concreteRebar':
      return 'Please note only concrete can go into the dumpster, no other debris or concrete slurry are allowed in.';
    case 'bNB':
    case 'bNL':
    case 'bNS':
      return 'Please note only organic yard waste can go into the dumpster, no dirt or other debris are allowed in.';
    case 'transport':
    case 'other':
      return '';
    case 'bulky13':
    case 'cND':
    case 'msw':
    case 'roofing':
      return 'If you have concrete, dirt, or brick please let us know, as putting these materials in may alter your final price.';
    default:
      return '';
  }
};

/**
 * Get
 * @param {Customer Object} customer
 * @returns {serviceCCAddresses, billingCCAddresses} Object of two arrays for customer additional contacts service and billing emails
 */
export const getCustomerCCAddresses = (customer: Customer.AllianceCustomerTransport, location?: AddressType) => {
  const serviceCCAddresses: string[] = [];
  const billingCCAddresses: string[] = [];

  if (location) {
    if (
      customer.contacts &&
      customer.contacts.length > 0 &&
      customer.contacts.some((item) => item.sendDispatchEmails || item.sendBillingEmails)
    ) {
      customer.contacts.forEach((item: Customer.AllianceCustomerContactTransport) => {
        const locationPreferences = item?.locationPreferences || [];
        let preference = locationPreferences.some((area) => area === buildAddressString(location));

        if (locationPreferences.length === 0) {
          preference = true;
        }

        if (item.email && item.sendDispatchEmails && !item.primaryContact && preference)
          serviceCCAddresses.push(item.email);
        if (item.email && item.sendBillingEmails && !item.primaryContact && preference)
          billingCCAddresses.push(item.email);
      });
    }
  } else {
    if (
      customer.contacts &&
      customer.contacts.length > 0 &&
      customer.contacts.some((item) => item.sendDispatchEmails || item.sendBillingEmails)
    ) {
      customer.contacts.forEach((item) => {
        if (item.email && item.sendDispatchEmails && !item.primaryContact) serviceCCAddresses.push(item.email);
        if (item.email && item.sendBillingEmails && !item.primaryContact) billingCCAddresses.push(item.email);
      });
    }
  }
  return { serviceCCAddresses, billingCCAddresses };
};

export const getCustomerCCAddressesFromTransport = (
  customer: Customer.AllianceCustomerTransport,
  location?: ServiceLocationTransport['address'],
) => {
  const serviceCCAddresses: string[] = [];
  const billingCCAddresses: string[] = [];

  if (location) {
    if (
      customer.contacts &&
      customer.contacts.length > 0 &&
      customer.contacts.some((item) => item.sendDispatchEmails || item.sendBillingEmails)
    ) {
      customer.contacts.forEach((item) => {
        const locationPreferences = item?.locationPreferences || [];
        let preference = locationPreferences.some((area) => area === buildAddressString(location));

        if (locationPreferences.length === 0) {
          preference = true;
        }

        if (item.email && item.sendDispatchEmails && !item.primaryContact && preference)
          serviceCCAddresses.push(item.email);
        if (item.email && item.sendBillingEmails && !item.primaryContact && preference)
          billingCCAddresses.push(item.email);
      });
    }
  } else {
    if (
      customer.contacts &&
      customer.contacts.length > 0 &&
      customer.contacts.some((item) => item.sendDispatchEmails || item.sendBillingEmails)
    ) {
      customer.contacts.forEach((item) => {
        if (item.email && item.sendDispatchEmails && !item.primaryContact) serviceCCAddresses.push(item.email);
        if (item.email && item.sendBillingEmails && !item.primaryContact) billingCCAddresses.push(item.email);
      });
    }
  }
  return { serviceCCAddresses, billingCCAddresses };
};

// TODO values check to make sure all the fields exist
// TODO should this replace or be merged with notification sent for Swap?
export const createOrderConfirmationEmail = (
  values: { [key: string]: any; material: MaterialEnums },
  orderTotal: number,
  user: UserType,
  stripeInvoiceLink: string | null,
  prohibitedItemString: string | undefined | null,
) => {
  const tosString =
    'By accepting the dumpster delivery you are accepting the dumpster rental terms of service. Which can be found at https://www.sourgum.com/terms-of-service-roll-off/ . ';
  const ccEmailString = `You will also receive another email with a link to pay for your dumpster rental online. In order to confirm your delivery you must click the link and pay for your dumpster rental before your delivery date. If you would prefer to pay over the phone you can by calling ${formatE164ToUSPhoneNumber(
    officeTel,
  )}.`;
  const dashboardString =
    values.status === OrderStatus.NEEDS_REVIEW
      ? `If you need to reorder, or make any changes to your current orders, log in to the Sourgum Dashboard at https://dashboard.sourgum.com`
      : `Make it even easier to reorder, or change your current orders: sign up to the Sourgum Dashboard for full access at https://dashboard.sourgum.com/signup`;
  const emailItems = [
    {
      label: 'Delivery address',
      value: buildAddressString(values.serviceLocation.address),
    },
    {
      label: 'Delivery date',
      value: format(new Date(getDateFormat(values.expectedDeliveryDate)), 'EEEE MM/dd/yy'),
    },
    { label: 'Delivery instructions', value: values.sharedDeliveryNotes },
    { label: 'Size', value: values.expectedSize + ' yard dumpster' },
    { label: 'Material', value: materials[values.material] },
    { label: 'Weight limit', value: createWeightLimitString(values) },
    { label: 'Price', value: `$${orderTotal}` },
    {
      label: 'Overage fee',
      value: values.overageDollars ? `If applicable $${values.overageDollars} per additional ton, prorated` : 'N/A',
    },
    {
      label: 'Rental period',
      value: `${
        values.adjustedRentalPeriod
      } days, you can call or email us any time before for a pick up or extend your rental for ${formatUSD(
        values.rentExtensionFeeDollars,
      )} per day. We do not pickup your dumpster automatically. Please note there is a 30 day maximum on all rental extensions.`,
    },
  ];
  if (values.expectedPickupDate) {
    emailItems.push({
      label: 'Pickup date',
      value: format(new Date(getDateFormat(values.expectedPickupDate)), 'EEEE MM/dd/yy'),
    });
  }
  if (createAsbestosOnlyString(values)) {
    emailItems.push({
      label: 'Asbestos Notice',
      value: createAsbestosOnlyString(values),
    });
  }

  const sharedText =
    'To ensure a successful delivery of the dumpster please make sure that the driveway and surrounding area are clear of cars and any obstructions between the hours of 6:30 am and 6 pm on the day your dumpster is scheduled to be delivered. Please move all cars the night before. Any changes made to your order after 12pm the business day before your service date may not be possible. Please remember that you cannot fill the container above the top rim. You can cancel your order before your delivery date, there is a $25 cancellation fee. If we are unable to deliver the dumpster successfully there will be a trip charge.';

  const emailStaticHtml =
    `<p>${dashboardString}</p><p><u>Important:</u> ${sharedText}</p>` +
    emailItems.map((item) => `<p><b>${item.label}:</b> ${item.value}</p>`).join('') +
    `<p>${createProhibitedItemList(
      values.material,
      prohibitedItemString,
    )}</p><p>If you have any questions please let me know and I'll be happy to help you.</p><p>Thank you,</p><p>${
      user?.firstName
    }</p>`;
  const emailStaticText =
    `\n\n${dashboardString}\n\n Important: ${sharedText}` +
    emailItems.map((item) => `\n\n${item.label}: ${item.value}`).join('') +
    `\n\n${createProhibitedItemList(
      values.material,
      prohibitedItemString,
    )}\n\nIf you have any questions please let me know and I'll be happy to help you.\n\nThank you,\n\n${
      user?.firstName
    }`;

  const stripeInvoiceLinkString = Boolean(stripeInvoiceLink)
    ? `If you did not receive the separate email for online payment, you can access it here ${stripeInvoiceLink}`
    : '';
  const customizableHTMLMessage = `
      ${values.includeTos ? `<p>${tosString}</p>` : ''}
      ${values.includeCCForm ? `<p>${ccEmailString}</p>` : ''}
      ${emailStaticHtml}
      ${Boolean(stripeInvoiceLinkString) ? `<p>${stripeInvoiceLinkString}</p>` : ''}
    `;
  const emailData = {
    htmlMessage: `
				<p>Hi ${values.primaryContact?.firstName},</p>
				<p>${values.emailMessage}</p>
				${customizableHTMLMessage}`,
    textMessage: `
				Hi ${values.primaryContact?.firstName},\n\n
				${values.emailMessage}
				${values.includeTos ? `\n\n${tosString}` : ''}
				${values.includeCCForm ? `\n\n${ccEmailString}` : ''}
				${emailStaticText}
				${Boolean(stripeInvoiceLinkString) ? `\n\n${stripeInvoiceLinkString}` : ''}
			`,
    email: values.primaryContact?.email,
    subject: 'Sourgum Waste Dumpster Confirmation',
    bcc: noReplyEmail,
  };
  return { emailData, customizableHTMLMessage };
};

export const createOrderConfirmationEmailSendGrid = (
  values: {
    primaryContact?: {
      firstName?: string;
      email?: string;
    };
    serviceLocation: { address: AddressType };
    expectedDeliveryDate: string;
    sharedDeliveryNotes: string;
    expectedSize: number;
    adjustedRentalPeriod: number;
    rentExtensionFeeDollars: number;
    overageDollars: number;
    expectedPickupDate?: string;
    emailMessage?: string;
    material: MaterialEnums | string;
  },

  orderTotal: number,
  user: Profile.ProfileTransport,
  stripeInvoiceLink: string | null,
  prohibitedItemString: string | undefined | null,
  ccEmails: string[] | [],
) => {
  const data = {
    emailTemplate: 'ORDER_CONFIRMATION_RO',
    first_name: values.primaryContact?.firstName,
    toEmail: values.primaryContact?.email,
    service_location: buildAddressString(values.serviceLocation.address),
    delivery_date: format(new Date(values.expectedDeliveryDate), 'EEEE MM/dd/yy'),
    delivery_instructions: values.sharedDeliveryNotes,
    material: materials[values.material as keyof typeof materials],
    dumpster_size: values.expectedSize,
    weight_limit: createWeightLimitString(values),
    rental_period: values.adjustedRentalPeriod,
    rental_extension_fee: formatUSD(values.rentExtensionFeeDollars, true),
    overage_fee: values.overageDollars ? formatUSD(values.overageDollars, true) : '',
    order_total: formatUSD(orderTotal, true),
    pickup_date: values.expectedPickupDate ? format(new Date(values.expectedPickupDate), 'EEEE MM/dd/yy') : null,
    csa_name: user.firstName,
    prohibited_items_string: createProhibitedItemList(values.material as MaterialEnums, prohibitedItemString),
    custom_message: values.emailMessage || '',
    stripe_invoice_link: stripeInvoiceLink || '',
    ccEmails: ccEmails || [],
    payment_link: '',
  };
  return data;
};

export const orderCancellationEmailCustomer = (
  customer: Customer.AllianceCustomerTransport,
  order: Order.AllianceOrderTransport,
) => {
  const customerEmailData = {
    htmlMessage: `
      <p>Hi ${getPrimaryCustomerContact(customer)?.firstName},</p>
      <p>Your dumpster rental order to ${buildAddressString(
        order.serviceLocation.address,
      )} has been successfully canceled. If you have any questions please let us know and we'll be happy to help you.</p>
      <p>Thank you!</p>
      <p>The Sourgum Waste Team</p>
    `,
    textMessage: `
      Hi ${getPrimaryCustomerContact(customer)?.firstName},\n\n
      Your dumpster rental order to ${buildAddressString(
        order.serviceLocation.address,
      )} has been successfully canceled. If you have any questions please let us know and we'll be happy to help you
      \n\nThank you!
      \n\nThe Sourgum Waste Team
    `,
    toAddress: getPrimaryCustomerContact(customer)?.email,
    subject: 'Sourgum Waste Dumpster Cancellation',
    sender: 'support@sourgum.com',
    bcc: noReplyEmail,
    ccAddresses: getCustomerCCAddresses(customer, order.serviceLocation.address).serviceCCAddresses,
  };
  return customerEmailData;
};

export const orderCancellationEmailHauler = (
  hauler: Hauler.HaulerWithAapDataUpdate,
  order: Order.AllianceOrderTransport,
  switchCancellation?: 'final_removal' | 'delay_pickup' | null,
) => {
  let openingString = `Please Cancel the dumpster delivery for Sourgum Waste. Order #${order.orderNumber}.`;
  if (switchCancellation === 'final_removal') {
    openingString = `Please Cancel the dump & return for Sourgum Waste and do a final removal instead. Order #${order.orderNumber}.`;
  }
  if (switchCancellation === 'delay_pickup') {
    openingString = `Please Cancel the dump & return for Sourgum Waste and wait for us to let you know a new pickup date. Order #${order.orderNumber}.`;
  }
  const haulerEmailData = {
    htmlMessage: `
      <p>${openingString}</p>
      <p>Cancellation details:</p>
      <p><b>Address:</b> ${formatServiceAddress(order.serviceLocation.address)}</p>
      <p><b>Size:</b> ${order.expectedSize.size}</p>
      <p><b>Date:</b> ${format(new Date(getDateFormat(order.expectedDeliveryDate)), 'EEEE MM/dd/yy')}</p>
      <p>Thank you!</p>
    `,
    textMessage: `
      ${openingString}\n\n
      Cancellation details:\n\n
      Address: ${formatServiceAddress(order.serviceLocation.address)}\n\n
      Size: ${order.expectedSize.size}\n\n
      Date: ${format(new Date(getDateFormat(order.expectedDeliveryDate)), 'EEEE MM/dd/yy')}\n\n
      Thank you!\n\n
    `,
    toAddress: getDispatchEmailsString(hauler.contacts),
    subject: `Sourgum Waste Dumpster Cancellation - ${order.orderNumber}`,
    bcc: noReplyEmail,
  };
  return haulerEmailData;
};
