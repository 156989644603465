import { useContext } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button, Dialog } from '@wayste/sour-ui';
import { UIContext } from '../../contexts/UIContext';

interface ChangeLineItemTypeStatusModalProps {
  lineItemType: UniversalService.ServiceLineItemTypeUpsert;
  serviceFamilyID: string;
  show: boolean;
  setShow: (show: boolean) => void;
  onModified?: () => void;
}

export const ChangeLineItemTypeStatusModal = ({
  lineItemType,
  serviceFamilyID,
  show,
  setShow,
  onModified,
}: ChangeLineItemTypeStatusModalProps) => {
  const client = useWaysteClient();
  const { showFlash } = useContext(UIContext);

  const handleChange = async () => {
    try {
      if (lineItemType.id === undefined) return;
      const updatePayload: UniversalService.ServiceLineItemTypeUpdate = {
        active: !lineItemType.active,
      };
      await client
        .universalService()
        .serviceFamily.lineItemType.update(serviceFamilyID, lineItemType.id, updatePayload);
      showFlash('Line Item Type Status Successfully Changed', 'success');
      if (onModified) onModified();
    } catch (error) {
      showFlash('An Error Occurred', 'warning');
      console.log(error);
    } finally {
      setShow(false);
    }
  };

  return (
    <Dialog
      variant={lineItemType.active ? 'delete' : undefined}
      open={show}
      onClose={() => setShow(false)}
      styledTitle={`${lineItemType.active ? 'Deactivate' : 'Reactivate'} Line Item Type`}
      className="max-w-2xl"
    >
      <div className="my-5 flex flex-col items-start justify-start">
        Are you sure you want to {lineItemType.active ? 'deactivate' : 'reactivate'} {lineItemType?.description}
      </div>

      <div className="flex justify-end gap-4">
        <Button className="mr-5 btn-dark-grey-outlined" onClick={() => setShow(false)} type="button">
          Cancel
        </Button>
        <Button
          className={lineItemType.active ? 'btn-delete' : 'btn-primary'}
          onClick={() => {
            handleChange();
          }}
        >
          {lineItemType?.active ? 'Deactivate' : 'Reactivate'}
        </Button>
      </div>
    </Dialog>
  );
};
