import React, { useEffect, useState } from 'react';
import { Pricing } from '@alliance-disposal/transport-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { statesDetails } from '../../utils/state-details';
import GoogleStreetView from '../GoogleStreetView';
import InputAddress from '../InputAddress';
import OrderPricingFields from '../OrderPricingFields';

export interface OrderFormProps {
  values: any;
  setFieldValue: any;
  setValues: any;
  zonePricing: Pricing.PricingTransport | null;
  onGetPricing: (location: { lat: number; lng: number; zip: string; state: string }) => Promise<void>;
  handleBlur: any;
  disabled: any;
  existingCustomer: any;
}
const OrderForm = ({
  values,
  setFieldValue,
  setValues,
  zonePricing,
  onGetPricing,
  handleBlur,
  disabled,
  existingCustomer,
}: OrderFormProps) => {
  const [streetViewAddress, setStreetViewAddress] = useState(null);

  useEffect(() => {
    setStreetViewAddress(values.serviceLocation?.address || null);
    if (values.serviceLocation?.coordinates)
      onGetPricing({
        ...values.serviceLocation?.coordinates,
        zip: values.serviceLocation?.address.zip,
        state: values.serviceLocation?.address.state,
      });
  }, [values.serviceLocation?.address, values.serviceLocation?.coordinates]);

  const handleAddressChange = (
    county: string,
    address: any,
    taxRate: number,
    coords: { lat: number; long: number },
  ) => {
    const obj = {
      ...values,
      serviceLocation: {
        ...values.serviceLocation,
        county,
        address: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          zip: '',
          ...address,
        },
      },
      taxRate: taxRate,
    };
    if (coords) {
      obj.serviceLocation.coordinates = coords;
    } else {
      alert('No coords get an AAP Dev');
    }
    setValues(obj);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Typography variant="h6">Service Address</Typography>
        </Grid>
        <Grid item xs={12} md={6} spacing={3}>
          <InputAddress
            name="serviceLocation.address"
            google
            required
            latLng
            disabled={Boolean(disabled)}
            onSelect={(obj: any) => {
              handleAddressChange(
                obj.county,
                obj.address,
                +statesDetails[obj.address.state as keyof typeof statesDetails].salesTaxRate,
                obj.coords,
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {streetViewAddress && <GoogleStreetView address={streetViewAddress} />}
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Typography variant="h6">Pricing Info</Typography>
        </Grid>
      </Grid>
      <OrderPricingFields
        setFieldValue={setFieldValue}
        values={values}
        setValues={setValues}
        handleBlur={handleBlur}
        zonePricing={zonePricing}
        existingCustomer={existingCustomer}
        disableFields={['price', 'overage', 'weightLimit', 'rentExtensionFeeDollars', 'priceType', 'cc', 'tax']}
        fromNeedsReview={true}
      />
      <Grid item xs={12} style={{ marginTop: 15 }}>
        <Typography>
          To change Price, Overage fee, Ton limit, or Rental extension fee you must do so in the order details AFTER
          finishing here. Be sure to notify the customer first, and to create a new invoice to make any necessary
          pricing adjustments.
        </Typography>
      </Grid>
    </>
  );
};

export default OrderForm;
