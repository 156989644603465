import { useEffect } from 'react';
import { Order } from '@alliance-disposal/transport-types';
import { Button, Dialog, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { VendorPricingRow } from './SpreadsTable';

interface OtherHaulerDialogProps {
  orderID: string;
  haulerPricing: VendorPricingRow[];
  sourgumPricing: {
    haul: number;
    dump: number;
    tonLimit: number;
    overage: number;
  };
  selectedHaulerID: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (data: Order.VendorPricingSnapshotTransportCreate) => void;
}
/**
 * Shown when a vendor is selected that is not
 * the most profitable vendor
 */
export const OtherVendorDialog = ({
  haulerPricing,
  sourgumPricing,
  selectedHaulerID,
  open,
  setOpen,
  onSubmit,
}: OtherHaulerDialogProps) => {
  const methods = useForm<Order.VendorPricingSnapshotTransportCreate>();
  const { control, handleSubmit, watch, formState, reset } = methods;
  const reasoning = watch('reasoning');

  const getPricingDataFromHaulerPricing = () => {
    const pricingData = haulerPricing.flatMap((haulerPricing) => {
      if (haulerPricing.rowRates && haulerPricing.rowRates.haul) {
        return {
          vendorID: haulerPricing.id,
          vendorName: haulerPricing.haulerName || '',
          priceType: haulerPricing?.rowRates?.materialData?.doesNotService
            ? 'Does not service'
            : haulerPricing?.rowRates?.materialData?.type,
          haulRate: haulerPricing.rowRates.sizeData.haul || 0,
          haulSpread: sourgumPricing?.haul - haulerPricing.rowRates.haul || 0,
          dumpRate: haulerPricing.rowRates.sizeData.dump ? haulerPricing.rowRates.sizeData.dump : 0,
          dumpSpread: sourgumPricing?.dump - +haulerPricing.rowRates.dump || 0,
          overageSpread: sourgumPricing?.overage - haulerPricing.rowRates.dump || 0,
          usingWayste: Boolean(haulerPricing.waysteUsage),
          waysteLite: haulerPricing.hauler.waysteLite,
        } satisfies Order.VendorPricingRow;
      }
      return [];
    });
    return pricingData;
  };

  useEffect(() => {
    reset({
      reasoning: undefined,
      otherReasoning: undefined,
      chosenVendorID: selectedHaulerID,
      pricingData: getPricingDataFromHaulerPricing(),
    });
  }, [haulerPricing, selectedHaulerID]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      styledTitle="Other Hauler Chosen"
      variant="delete"
      className="max-w-3xl"
      disableCloseOnClickOutside
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="gap-2 space-y-4">
            <p>Please select why you are not picking the most profitable vendor.</p>
            <Controller
              control={control}
              name="reasoning"
              defaultValue={undefined}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <Select
                  label="Reasoning"
                  value={field.value}
                  onSelect={field.onChange}
                  error={fieldState.error}
                  required
                >
                  {Object.entries(Order.ReasoningTypeLabels).map((option: [string, string], index: number) => (
                    <SelectOption key={'reasoningLabel' + index} value={option[0]}>
                      {option[1]}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
            {reasoning === 'OTHER' && (
              <Controller
                control={control}
                name="otherReasoning"
                defaultValue={undefined}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Other Reasoning"
                    error={fieldState.error}
                    required
                    inputProps={{
                      ...field,
                    }}
                  />
                )}
              />
            )}
          </div>
          <div className="flex space justify-end space-x-4 mt-4">
            {/* <Button className="btn-dark-grey-outlined mt-2" onClick={() => setOpen(false)} type="button">
              Close
            </Button> */}
            <Button className="btn-primary mt-2" type="submit" disabled={!formState.isValid}>
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
